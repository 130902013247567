import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//Import images
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import notificationRedux, {
  notificationSelector,
} from "src/store/socketNotification";
import moment from "moment";

const NotificationDropdown = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [count, setCount] = useState(0);
  const notifications = useSelector(notificationSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    setCount(notifications.filter(x => !x.Read).length);
  }, [notifications]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <Icon name="bell" className="icon-lg" />
          {count > 0 && (
            <span className="badge bg-danger rounded-pill">{count}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ height: "230px" }}>
            {notifications.map(x => (
              <Link
                key={x.Id}
                to={x.Url}
                className="text-reset notification-item"
                onClick={() => {
                  dispatch(notificationRedux.actions.readNotification(x.Id));
                }}
              >
                <div className="d-flex">
                  <div className="avatar-sm me-3">
                    <span className="avatar-title rounded-circle font-size-16">
                      <i className="bx bx-bell" />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{x.Message}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {moment(x.Date).format("DD/MM/YYYY HH:mm")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(NotificationDropdown));

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
