import { useTranslation } from "react-i18next";
import images from "src/assets/image";
import Whisper from "src/components/Whispers";
import { CustomerCreditCardDto } from "src/store/customers/type";

const CreditCard = ({ card }: { card: CustomerCreditCardDto }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="creditcard">
        <div className="card__front card__part">
          <img
            alt=""
            className="card__front-square card__square  ms-1"
            src={images.cardchip}
          />
          <span className="card__info ms-1">{card.CardName}</span>

          {card.Active && (
            <Whisper speaker={<span className="text-danger ">{"Aktif"}</span>}>
              <img
                alt=""
                className="card__front-logo card__logo"
                src={images.yellowcheck}
              />
            </Whisper>
          )}
          <p className="card_numer">
            {card.CardNumberFirstSix.slice(0, 4)}{" "}
            {card.CardNumberFirstSix.slice(4, 6)}** ****{" "}
            {card.CardNumberLastFour}
          </p>
          <div className="card__space-65 ms-1">
            <span className="card__label">{t("Card holder")}</span>
            <p className="card__info">{card.CardHolderFullName}</p>
          </div>
          <div className="card__space-35 me-1">
            <span className="card__label">{t("Expires")}</span>
            <p className="card__info">
              {card.ExpMonth}/{card.ExpYear}
            </p>
          </div>
        </div>

        <div className="card__back card__part">
          <div className="card__black-line"></div>
          <div className="card__back-content">
            <div className="card__secret">
              <p className="card__secret--last">{"***"}</p>
            </div>
            {/* <img
              className="card__back-square card__square"
              src="https://image.ibb.co/cZeFjx/little_square.png"
            />
            <img
              className="card__back-logo card__logo"
              src="https://www.fireeye.com/partners/strategic-technology-partners/visa-fireeye-cyber-watch-program/_jcr_content/content-par/grid_20_80_full/grid-20-left/image.img.png/1505254557388.png"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default CreditCard;
