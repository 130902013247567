import moment from "moment";
import { Steps } from "rsuite";
import CustomModal from "src/components/Modal/modal";
import {
  PrivilegeCardDetailDto,
  PrivilegeCardStatusEnum,
} from "src/store/privilegecard/type";
import "./style.scss";
const CardHistory = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PrivilegeCardDetailDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const GetIcons = (statusId: number) => {
    switch (statusId) {
      case PrivilegeCardStatusEnum.IslemBekliyor:
        return "mdi mdi-clock";
      case PrivilegeCardStatusEnum.KartBasildi:
        return "mdi mdi-printer-check";
      case PrivilegeCardStatusEnum.Gonderildi:
        return "mdi mdi-semantic-web";
      case PrivilegeCardStatusEnum.Aktif:
        return "mdi mdi-sticker-check";
      case PrivilegeCardStatusEnum.İptal:
        return "mdi mdi-cancel ";
      default:
        return undefined;
    }
  };
  return (
    <CustomModal
      onHide={onHide}
      size="lg"
      open={open}
      title={data.CardNumber + " Geçmiş Hareketler"}
    >
      <div className="py-3 mt-2">
        <Steps
          current={data.PrivilegeCardHistories.length}
          className="cardHistoryStep"
        >
          {data.PrivilegeCardHistories?.map(x => (
            <Steps.Item
              icon={
                <div className="icon-demo-content">
                  <i className={GetIcons(x.PrivilegeCardStatusId)}></i>
                </div>
              }
              key={x.Id}
              title={x.PrivilegeCardStatusName}
              description={
                <>
                  Tarih: {moment(x.Date).format("DD/MM/yyyy HH:mm")}
                  {x.PrivilegeCardStatusId ===
                    PrivilegeCardStatusEnum.Gonderildi && (
                    <>
                      <br />
                      Takip No: {data.TrackingNumber}
                      <br />
                      Kargo: {data.OrderShippingCompanyName}
                    </>
                  )}
                </>
              }
            />
          ))}
        </Steps>
      </div>
    </CustomModal>
  );
};
export default CardHistory;
