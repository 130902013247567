import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivilegeButtonDto } from "src/store/privilegebuttons/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreatePrivilegeButton from "./create";
import { toast } from "react-toastify";
import EditPrivilegeButton from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  changePrivilegeButtonOrder,
  deletePrivilegeButton,
  getPrivilegeButtonDetails,
  getPrivilegeButtonListByPrivilegeId,
} from "src/store/privilegebuttons/saga";
import { useParams } from "react-router";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegeDetails } from "src/store/privilege/saga";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";

const PrivilegeButtonPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PrivilegeButtonDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<PrivilegeButtonDto[]>([]);
  const [showDelete, setShowDelete] = useState<PrivilegeButtonDto>();
  const [privilege, setPrivilege] = useState<PrivilegeDto>();
  useEffect(() => {
    dispatch(
      getPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getPrivilegeButtonListByPrivilegeId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Buton Adı"),
      dataField: "ButtonName",
    },
    {
      text: t("Aksiyon Tipi"),
      dataField: "ButtonActionTypeName",
    },
    {
      text: t("Değer"),
      dataField: "Value",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getPrivilegeButtonDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPrivilegeButtonListByPrivilegeId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditPrivilegeButton
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreatePrivilegeButton
        privilegeId={id}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changePrivilegeButtonOrder}
        title={privilege ? privilege.Title : " " + t("Butonları")}
        create={{
          createType: "drawer",
          onCreateClick: () => setShowCreate(true),
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete privilege button`, {
            name: `${showDelete.ButtonName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePrivilegeButton({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PrivilegeButtonPage;
