import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { FaqsCategoryDto } from "./type";
import { BodyType } from "../base/request/request";

export const getFaqsCategoryList = createAction(
  "GET_FAQSCATEGORY_LIST",
  ({ payload, params }: SagaActionParams<FaqsCategoryDto[]>) => ({
    payload,
    url: "/api/faqcategories" + (params ? params : ""),
  })
);
export const getFaqsCategoryDetails = createAction(
  "GET_FAQSCATEGORY_DETAILS",
  ({ payload, id }: SagaActionParams<FaqsCategoryDto>) => ({
    payload,
    url: "/api/faqcategories/" + id,
  })
);
export const createFaqsCategorySaga = createAction(
  "CREATE_FAQSCATEGORY_DETAILS",
  ({ payload, body }: SagaActionParams<FaqsCategoryDto>) => ({
    payload,
    url: "/api/faqcategories",
    body,
  })
);
export const updateFaqsCategory = createAction(
  "UPDATE_FAQSCATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<FaqsCategoryDto>) => ({
    payload,
    url: "/api/faqcategories/" + id,
    body,
  })
);
export const deleteFaqsCategory = createAction(
  "DELETE_FAQSCATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<FaqsCategoryDto>) => ({
    payload,
    url: "/api/faqcategories/" + id,
    body,
  })
);

export const changeFaqsCategoryOrder = createAction(
  "UPDATE_FAQSCATEGORY_ORDER",
  ({ payload, body }: SagaActionParams<FaqsCategoryDto>) => ({
    payload,
    url: "/api/faqcategories/order",

    bodyType: BodyType.raw,
    body,
  })
);
const faqscategorySaga = [
  takeLatest(
    getFaqsCategoryList.toString(),
    (payload: SagaGenericParams<FaqsCategoryDto[]>) =>
      getListSaga<FaqsCategoryDto[]>(payload)
  ),
  takeLatest(
    getFaqsCategoryDetails.toString(),
    (payload: SagaGenericParams<FaqsCategoryDto>) =>
      getListSaga<FaqsCategoryDto>(payload)
  ),
  takeLatest(
    createFaqsCategorySaga.toString(),
    (payload: PostSagaGenericParams<FaqsCategoryDto>) =>
      postSaga<FaqsCategoryDto>(payload)
  ),
  takeLatest(
    changeFaqsCategoryOrder.toString(),
    (payload: PostSagaGenericParams<FaqsCategoryDto>) =>
      putSaga<FaqsCategoryDto>(payload)
  ),
  takeLatest(
    updateFaqsCategory.toString(),
    (payload: PostSagaGenericParams<FaqsCategoryDto>) =>
      putSaga<FaqsCategoryDto>(payload)
  ),
  takeLatest(
    deleteFaqsCategory.toString(),
    (payload: SagaGenericParams<FaqsCategoryDto>) =>
      deleteSaga<FaqsCategoryDto>(payload)
  ),
];

export default faqscategorySaga;
