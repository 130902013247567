import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import MainPage from "src/components/PageContent/MainPage";
import { useParams } from "react-router";
import { ContactDto } from "src/store/web/type";
import { getContacts, updateContacts } from "src/store/web/saga";
import moment from "moment";

const ContactPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<ContactDto[]>([]);
  const [showDelete, setShowDelete] = useState<ContactDto>();

  const columns = [
    {
      text: t("Kategori"),
      dataField: "ContactCategoryName",
    },
    {
      text: t("Ad Soyad"),
      dataField: "NameSurname",
    },
    {
      text: t("Email"),
      dataField: "Email",
    },
    {
      text: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      text: t("Mesaj"),
      dataField: "Message",
    },
    {
      text: t("Tarih"),
      dataField: "CreateTime",
      formatter: (a: any) => moment(a).format("DD-MM-YY hh:mm"),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: ContactDto) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {!data.Read && (
            <ColumnButton
              buttonType="customicon"
              custombutton={{
                icon: "bx bx-check-circle",
              }}
              onClick={() => {
                return setShowDelete(data);
              }}
            />
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getContacts({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      <MainPage
        title={t("İletişim Talepleri")}
        withoutPag={true}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getContacts,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(
            `İletişim Talebini tamamlandı işaretlemek istediğine emin misin?`
          )}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              updateContacts({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
                body: {
                  id: showDelete.Id,
                  read: true,
                },
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ContactPage;
