import FileSaver from "file-saver";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import DataTableWithPagination from "src/components/PageContent/DataGrid";
import { baseImageUrl } from "src/store/base/types/url";
import { SupplierDto } from "src/store/supplier/type";
import {
  deleteSupplierDocument,
  getSupplierDocumentDetails,
  getSupplierDocumentListBySupplierId,
} from "src/store/supplierdocuments/saga";
import { SupplierDocumentDto } from "src/store/supplierdocuments/type";
import CreatePrivilegeModal from "./createDocument";
import EditPrivilegeModal from "./editDocument";
import moment from "moment";

const SupplierDocumentTab = ({ supplier }: { supplier: SupplierDto }) => {
  const dispatch = useDispatch();
  const { tab }: { tab: string } = useParams();
  const [showDelete, setShowDelete] = useState<SupplierDocumentDto>();
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<SupplierDocumentDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [locations, setDocuments] = useState<SupplierDocumentDto[]>([]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getSupplierDocumentListBySupplierId({
        payload: {
          onSuccess: (msg, payload) => setDocuments(payload),
          onError: () => {},
        },
        id: supplier.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Name"),
      dataField: "Name",
    },
    {
      text: t("Start Date"),
      dataField: "StartDate",
      formatter: (cellContent: any, data: any) =>
        cellContent && moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: t("End Date"),
      dataField: "EndDate",
      formatter: (cellContent: any, data: any) =>
        cellContent && moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customicon"
            custombutton={{ icon: "bx bxs-download" }}
            onClick={() =>
              FileSaver.saveAs(baseImageUrl + data.Document, data.Name)
            }
          />
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getSupplierDocumentDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => setShowDelete(data)}
          />
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditPrivilegeModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreatePrivilegeModal
          supplier={supplier}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        {tab === "3" && (
          <DataTableWithPagination
            title=" "
            HideBreadcrumb={true}
            listData={locations}
            columns={columns}
            create={{
              createType: "drawer",
              onCreateClick: () => setShowCreate(true),
            }}
            setDataList={setDocuments}
            action={getSupplierDocumentListBySupplierId}
            paramsid={supplier.Id}
          />
        )}
      </div>
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete supplierdocuments`, {
            name: `${showDelete.Name} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteSupplierDocument({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default SupplierDocumentTab;
