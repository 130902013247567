import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import { sendEmailConfirmation } from "src/store/customers/saga";
import { CustomerDetailDto } from "src/store/customers/type";

const SendEmailConfirmation = ({
  customerData,
  onSuccessSubmit,
}: {
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const [showHonorary, setShowHonorary] = useState(false);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <button
        className="btn btn-soft-info btn-sm mt-2"
        onClick={() => setShowHonorary(true)}
      >
        Doğrulama Maili Gönder
      </button>
      {customerData && showHonorary && (
        <CancelAlert
          message={`'${customerData.Name} ${customerData.Surname}' üyeye e-posta doğrulama maili göndermek istediğinize emin misiniz?`}
          onConfirm={() => {
            dispatch(
              sendEmailConfirmation({
                payload: {
                  onSuccess: message => {
                    setShowHonorary(false);
                    toast.success(message);
                    onSuccessSubmit();
                  },
                  onError: message => {
                    setShowHonorary(false);
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  CustomerId: customerData.Id,
                },
              })
            );
          }}
          onCancel={() => {
            setShowHonorary(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default SendEmailConfirmation;
