import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { emailConfirm } from "src/store/auth/saga";

//import images
import logo from "../../assets/images/logo-sm.svg";
import CarouselPage from "./CarouselPage";

const ConfirmMail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId }: { userId: string } = useParams();
  const location = useLocation();
  const [success, setSuccess] = useState<number>();

  useEffect(() => {
    const token = location.search;
    dispatch(
      emailConfirm({
        payload: {
          onSuccess: message => {
            setSuccess(1);
          },
          onError: message => {
            setSuccess(2);
          },
        },
        url: "/appuser/email-confirm",
        body: { userId: userId, token: token.replace("?token=", "") },
      })
    );
  }, [dispatch, location.search, userId, history]);
  return (
    <React.Fragment>
      <MetaTags>
        <title></title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <CarouselPage
            component={
              <Row className="g-0">
                <Col lg={12} md={12} className="col-xxl-12">
                  <div className="auth-full-page-content d-flex p-sm-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5 text-center">
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img src={logo} alt="" height="28" />{" "}
                            <span className="logo-txt">Minia</span>
                          </Link>
                        </div>
                        <div className="auth-content my-auto">
                          <div className="text-center">
                            {!success && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-30 align-middle me-2"></i>
                                <div className="p-2 mt-4">
                                  <h4>Verifying...</h4>
                                  <p className="text-muted"></p>
                                </div>
                              </>
                            )}
                            {success && success === 1 && (
                              <>
                                <div className="avatar-lg mx-auto">
                                  <div className="avatar-title rounded-circle bg-light">
                                    <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                                  </div>
                                </div>
                                <div className="p-2 mt-4">
                                  <h4>Success !</h4>
                                  <p className="text-muted">
                                    At vero eos et accusamus et iusto odio
                                    dignissimos ducimus qui blanditiis
                                    praesentium voluptatum deleniti atque
                                    corrupti quos dolores et
                                  </p>
                                  <div className="mt-4">
                                    <Link
                                      to="/login"
                                      className="btn btn-primary w-100"
                                    >
                                      Back to Login
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )}
                            {success && success === 2 && (
                              <>
                                <div className="avatar-lg mx-auto">
                                  <div className="avatar-title rounded-circle bg-light">
                                    <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                                  </div>
                                </div>
                                <div className="p-2 mt-4">
                                  <h4>Üzgünüz !</h4>
                                  <p className="text-muted">
                                    Beklenmedik bir hata oluştu.Lütfen daha
                                    sonra tekrar deneyiniz.
                                  </p>
                                  <div className="mt-4">
                                    <Link
                                      to="/login"
                                      className="btn btn-primary w-100"
                                    >
                                      Back to Login
                                    </Link>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfirmMail;
