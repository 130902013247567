import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SystemReminderUserDto } from "./type";
import { UserDto } from "../user/type";

export const getSystemReminderUsers = createAction(
  "GET_SYSTEM_REMINDER_USER",
  ({ payload, params }: SagaActionParams<SystemReminderUserDto[]>) => ({
    payload,
    url: "/api/systemreminderusers" + (params ? params : ""),
  })
);

export const getSystemReminderUsersDetails = createAction(
  "GET_SYSTEM_REMINDER_USER_DETAILS",
  ({ payload, id }: SagaActionParams<SystemReminderUserDto>) => ({
    payload,
    url: "/api/systemreminderusers/" + id,
  })
);
export const createSystemReminderUsers = createAction(
  "CREATE_SYSTEM_REMINDER_USER",
  ({ payload, body }: SagaActionParams<SystemReminderUserDto>) => ({
    payload,
    url: "/api/systemreminderusers",
    body,
  })
);
export const updateSystemReminderUsers = createAction(
  "UPDATE_SYSTEM_REMINDER_USER",
  ({ payload, id, body }: SagaActionParams<SystemReminderUserDto>) => ({
    payload,
    url: "/api/systemreminderusers/" + id,
    body,
  })
);

export const deleteSystemReminderUsers = createAction(
  "DELETE_SYSTEM_REMINDER_USER",
  ({ payload, id }: SagaActionParams<SystemReminderUserDto>) => ({
    payload,
    url: "/api/systemreminderusers/" + id,
  })
);
export const getUsersLookup = createAction(
  "GET_USER_LOOKUP",
  ({ payload }: SagaActionParams<UserDto[]>) => ({
    payload,
    url: "/api/systemreminderusers/users",
  })
);
const systemreminderusersSaga = [
  /*SYSTEM_REMINDER_USER*/
  takeLatest(
    getSystemReminderUsers.toString(),
    (payload: SagaGenericParams<SystemReminderUserDto[]>) =>
      getListSaga<SystemReminderUserDto[]>(payload)
  ),

  takeLatest(
    getSystemReminderUsersDetails.toString(),
    (payload: SagaGenericParams<SystemReminderUserDto>) =>
      getListSaga<SystemReminderUserDto>(payload)
  ),
  takeLatest(
    createSystemReminderUsers.toString(),
    (payload: PostSagaGenericParams<SystemReminderUserDto>) =>
      postSaga<SystemReminderUserDto>(payload)
  ),
  takeLatest(
    updateSystemReminderUsers.toString(),
    (payload: PostSagaGenericParams<SystemReminderUserDto>) =>
      putSaga<SystemReminderUserDto>(payload)
  ),

  takeLatest(
    deleteSystemReminderUsers.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<SystemReminderUserDto>(payload)
  ),
  takeLatest(
    getUsersLookup.toString(),
    (payload: SagaGenericParams<UserDto[]>) => getListSaga<UserDto[]>(payload)
  ),
];

export default systemreminderusersSaga;
