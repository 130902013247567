import { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { map } from "lodash";
import { withTranslation } from "react-i18next";
import Flag from "react-flagkit";
import i18n from "../../../i18n";
import { useDispatch, useSelector } from "react-redux";
import languageRedux, {
  languageSelector,
  languagesSelector,
} from "src/store/languages";
import { LanguageModel } from "src/store/languages/types";

const LanguageDropdown = () => {
  const language = useSelector(languageSelector);
  const languageList = useSelector(languagesSelector);
  const [menu, setMenu] = useState<boolean>(false);
  const dispatch = useDispatch();

  const changeLanguageAction = async (lang: LanguageModel) => {
    i18n.changeLanguage(lang.Code.split("-")[0]);
    await localStorage.setItem("I18N_LANGUAGE", lang.Code.split("-")[0]);
    await dispatch(languageRedux.actions.setLanguage(lang));
    window.location.reload();
  };

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " tag="button">
          <Flag
            country={language?.Code?.split("-")[1].toUpperCase()}
            size={24}
          />{" "}
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(languageList, lang => (
            <DropdownItem
              key={lang.EnumId}
              onClick={() => changeLanguageAction(lang)}
              className={`notify-item ${
                language.EnumId === lang.EnumId ? "active" : "none"
              }`}
            >
              <Flag
                country={lang?.Code?.split("-")[1].toUpperCase()}
                size={18}
              />{" "}
              <span className="align-middle">{lang.Name}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
