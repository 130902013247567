import React from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import DashboardIsparkCouponComponent from "./isparkCoupons";
import "./style.scss";
import DashboardTicketComponent from "./tickets";
import DashboardCustomerComponent from "./customers";
import { useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import DashboardHistoryReportComponent from "./historyReport";
import TransactionComponent from "./transactions";
import FirstPackageList from "./firstpackage";
import DashboardDailyReports from "./dailyReports";
import { userSelector } from "src/store/auth";
import CustomerServiceDashboard from "./customerService";
const Dashboard = () => {
  const user = useSelector(userSelector);
  return (
    <React.Fragment>
      <div
        className="page-content "
        style={{
          fontFamily: "Poppins,sans-serif",
        }}
      >
        <MetaTags>
          <title>Dashboard | Servis Plan</title>
        </MetaTags>
        {!user?.Roles.includes("customerservice") ? (
          <MainDashboard />
        ) : (
          <CustomerServiceDashboard />
        )}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;

const MainDashboard = () => {
  const loading = useSelector(isLoading);
  return (
    <Container fluid>
      {loading && <Loader />}
      <Row>
        <Col md={4}>
          <DashboardCustomerComponent />
        </Col>
        <Col md={4}>
          <DashboardTicketComponent />
        </Col>
        <Col md={4}>
          <DashboardIsparkCouponComponent />
        </Col>
        <Col md={12}>
          <DashboardDailyReports />
        </Col>
        <Col md={12}>
          <DashboardHistoryReportComponent />
        </Col>
        <Col md={9}>
          <TransactionComponent />
        </Col>
        <Col md={3}>
          <FirstPackageList />
        </Col>
      </Row>
    </Container>
  );
};
