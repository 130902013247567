import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivilegeDto } from "src/store/privilege/type";
import MainPage from "src/components/PageContent/MainPage";
import { useDispatch } from "react-redux";
import CancelAlert from "src/components/CancelAlert";
import { getPrivilegeDetails } from "src/store/privilege/saga";

import { useParams } from "react-router";
import { toast } from "react-toastify";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import {
  deletePrivilegeCoupon,
  getPrivilegeCouponListByPrivilegeId,
} from "src/store/privilegecoupons/saga";
import { PrivilegeCouponDto } from "src/store/privilegecoupons/type";
import EditCoupon from "./edit";
import CreateCoupon from "./create";
import ImportExcelModal from "./importExcelModal";
import { getLocalDate } from "src/helpers/getLocalDate";

const PrivilegeCouponsPage = () => {
  const { id }: { id: string } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDelete, setShowDelete] = useState<PrivilegeCouponDto>(); // eslint-disable-next-line
  const [selected, setSelected] = useState<PrivilegeCouponDto>();
  const [data, setData] = useState<PrivilegeCouponDto[]>([]);
  const [privilege, setPrivilege] = useState<PrivilegeDto>();
  const [fieldText, setFieldText] = useState<string>(" ");
  const [labelText, setLabelText] = useState<string>(" ");

  useEffect(() => {
    dispatch(
      getPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => {
            if (payload.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon) {
              setPrivilege(payload);
              setFieldText(t("Coupons"));
              setLabelText(t("Coupon"));
            } else if (
              payload.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.QR
            ) {
              setPrivilege(payload);
              setFieldText(t("QR Codes"));
              setLabelText(t("QR Code"));
            }
          },
          onError: () => {},
        },
        id: id,
      })
    ); // eslint-disable-next-line
  }, [dispatch, id]);
  const columns = [
    {
      text: t("QRCode"),
      dataField: "QRCode",
    },
    {
      text: t("Customer"),
      dataField: "CustomerName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },

    {
      text: t("Validated"),
      dataField: "Validated",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("ValidatedDate"),
      dataField: "ValidatedDate",
      formatter: (cellContent: any) => getLocalDate(cellContent),
    },

    // {
    //   text: "",
    //   dataField: "Id",
    //   formatter: (cellContent: any, data: any) => (
    //     // <div className="d-flex w-30 justify-content-end gap-1">
    //     //   <ColumnButton
    //     //     buttonType="edit"
    //     //     onClick={() => {
    //     //       dispatch(
    //     //         getPrivilegeCouponDetails({
    //     //           payload: {
    //     //             onSuccess: (message, payload) => {
    //     //               setSelected(payload);
    //     //               return setShowEdit(true);
    //     //             },
    //     //             onError: () => {},
    //     //           },
    //     //           id: cellContent,
    //     //         })
    //     //       );
    //     //     }}
    //     //   />
    //     //   <ColumnButton
    //     //     buttonType="delete"
    //     //     onClick={() => {
    //     //       return setShowDelete(data);
    //     //     }}
    //     //   />
    //     // </div>
    //   ),
    // },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPrivilegeCouponListByPrivilegeId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {privilege && (
        <ImportExcelModal
          outsideClick
          open={showImportModal}
          onHide={() => setShowImportModal(false)}
        />
      )}
      {selected && privilege && (
        <EditCoupon
          privilege={privilege}
          data={selected}
          labelText={labelText}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      {privilege && (
        <CreateCoupon
          labelText={labelText}
          privilege={privilege}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
      )}

      <MainPage
        title={`${privilege ? privilege.Title : " "} ${fieldText}`}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getPrivilegeCouponListByPrivilegeId,
          paramsid: id,
          hideExcel: true,
          // create: {
          //   createType: "drawer",
          //   onCreateClick: () => setShowCreate(true),
          // },
          buttons: (
            <>
              <button className="btn btn-secondary me-1">
                <i className="mdi mdi-microsoft-excel "></i> {t("Export")}
              </button>
              <button
                className="btn btn-success me-1"
                onClick={() => setShowImportModal(true)}
              >
                <i className="mdi mdi-microsoft-excel "></i> {t("Import")}
              </button>
            </>
          ),
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete privilege coupon`, {
            name: `${showDelete.QRCode} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePrivilegeCoupon({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PrivilegeCouponsPage;
