import React, { useState } from "react";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { TicketSourceTypeEnum } from "src/store/ticket/type";
import OpenTicketModal from "./openTicketModal";
import OpenCallLogModal from "../calllog/openCalllog";
import CreateInvalidCallLog from "../calllog/createInvalidCallLog";
const TicketListHeader = ({
  refreshList,
  sourceType,
  setSourceType,
}: {
  setSourceType: (sourceType: TicketSourceTypeEnum) => void;
  sourceType: TicketSourceTypeEnum;
  refreshList: () => void;
}) => {
  const [modal, setmodal] = useState(false);
  const [calllogmodal, setcalllogmodal] = useState(false);
  const [showInvalidCalllog, setShowInvalidCalllog] = useState(false);

  const menu = [
    {
      key: 1,
      title: "Talep",
    },
    {
      key: 2,
      title: "Çağrı",
    },
  ];
  return (
    <React.Fragment>
      <Nav justified className="mb-3 border-bottom" role="tablist">
        {menu.map(y => {
          return (
            <NavItem key={y.key}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={
                  sourceType === y.key
                    ? sourceType === TicketSourceTypeEnum.CallLog
                      ? "bg-soft-info"
                      : "bg-soft-success  "
                    : "bg-white"
                }
                onClick={() => setSourceType(y.key)}
              >
                {y.title}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <div className="px-3 w-100">
        {sourceType === TicketSourceTypeEnum.Mobil ? (
          <>
            <Button
              type="button"
              color="white"
              className="w-100  btn-sm btn btn-success btn-block waves-effect waves-light fw-semibold"
              onClick={() => {
                setmodal(!modal);
              }}
              block
            >
              Yeni Talep
            </Button>

            <OpenTicketModal
              onHide={() => setmodal(false)}
              show={modal}
              onSuccessSubmit={() => refreshList()}
            />
          </>
        ) : (
          <>
            <Button
              type="button"
              color="white"
              className="w-100 btn-sm btn btn-info btn-block waves-effect waves-light fw-semibold"
              onClick={() => {
                setcalllogmodal(!calllogmodal);
              }}
              block
            >
              Yeni Çağrı
            </Button>
            <Button
              type="button"
              className="mt-2 w-100 btn-sm btn btn-soft-danger "
              onClick={() => {
                setShowInvalidCalllog(!showInvalidCalllog);
              }}
            >
              Geçersiz Çağrı
            </Button>
            <OpenCallLogModal
              onHide={() => setcalllogmodal(false)}
              show={calllogmodal}
              onSuccessSubmit={() => refreshList()}
            />
            <CreateInvalidCallLog
              onHide={() => setShowInvalidCalllog(false)}
              show={showInvalidCalllog}
              onSuccessSubmit={() => refreshList()}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default TicketListHeader;
