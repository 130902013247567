import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { VehicleModelDto } from "./type";
import { BodyType } from "src/store/base/request/request";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getVehicleModelList = createAction(
  "GET_VEHICLE_MODEL_LIST",
  ({ payload, params }: SagaActionParams<VehicleModelDto[]>) => ({
    payload,
    url: "/api/vehiclemodels" + (params ? params : ""),
  })
);
export const getVehicleModelLookup = createAction(
  "GET_VEHICLE_MODEL_LOOKUP",
  ({ payload, params }: SagaActionParams<VehicleModelDto[]>) => ({
    payload,
    url: "/api/vehiclemodels/lookup" + (params ? params : ""),
  })
);

export const getVehicleModelListByBrandId = createAction(
  "GET_VEHICLE_MODEL_LIST_BY_BRAND_ID",
  ({ payload, id }: SagaActionParams<VehicleModelDto[]>) => ({
    payload,
    url: "/api/vehiclemodels/bybrandId/" + id,
  })
);
export const getVehicleModelDetails = createAction(
  "GET_VEHICLE_MODEL_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleModelDto>) => ({
    payload,
    url: "/api/vehiclemodels/" + id,
  })
);
export const createVehicleModel = createAction(
  "CREATE_VEHICLE_MODEL_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleModelDto>) => ({
    payload,
    url: "/api/vehiclemodels",
    body,
  })
);
export const updateVehicleModel = createAction(
  "UPDATE_VEHICLE_MODEL_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleModelDto>) => ({
    payload,
    url: "/api/vehiclemodels/" + id,
    body,
  })
);
export const deleteVehicleModel = createAction(
  "DELETE_VEHICLE_MODEL_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleModelDto>) => ({
    payload,
    url: "/api/vehiclemodels/" + id,
    body,
  })
);

export const changeVehicleModelOrder = createAction(
  "UPDATE_VEHICLE_MODEL_ORDER",
  ({ payload, body }: SagaActionParams<VehicleModelDto>) => ({
    payload,
    url: "/api/vehiclemodels/order",
    body,
    bodyType: BodyType.raw,
  })
);
const vehicleModelsSaga = [
  takeLatest(
    getVehicleModelListByBrandId.toString(),
    (payload: SagaGenericParams<VehicleModelDto[]>) =>
      getListSaga<VehicleModelDto[]>(payload)
  ),
  takeLatest(
    getVehicleModelLookup.toString(),
    (payload: SagaGenericParams<VehicleModelDto[]>) =>
      getListSaga<VehicleModelDto[]>(payload)
  ),
  takeLatest(
    getVehicleModelList.toString(),
    (payload: SagaGenericParams<VehicleModelDto[]>) =>
      getListSaga<VehicleModelDto[]>(payload)
  ),
  takeLatest(
    getVehicleModelDetails.toString(),
    (payload: SagaGenericParams<VehicleModelDto>) =>
      getListSaga<VehicleModelDto>(payload)
  ),
  takeLatest(
    createVehicleModel.toString(),
    (payload: PostSagaGenericParams<VehicleModelDto>) =>
      postSaga<VehicleModelDto>(payload)
  ),
  takeLatest(
    updateVehicleModel.toString(),
    (payload: PostSagaGenericParams<VehicleModelDto>) =>
      putSaga<VehicleModelDto>(payload)
  ),
  takeLatest(
    deleteVehicleModel.toString(),
    (payload: SagaGenericParams<VehicleModelDto>) =>
      deleteSaga<VehicleModelDto>(payload)
  ),
];

export default vehicleModelsSaga;
