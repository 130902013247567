import { Cascader } from "rsuite";
import { CustomCascaderProps } from "../type";

const CustomCascader = ({
  label,
  error,
  data,
  field,
  touched,
  ...rest
}: CustomCascaderProps) => {
  return (
    <div className="textOnInput my-2">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
        style={{ background: "white" }}
      >
        {label ? label : ""}
      </label>

      <Cascader
        parentSelectable
        className={
          error && touched
            ? "errorInput defaultInput form-control selectpickerInput form2-control"
            : "defaultInput form-control selectpickerInput form2-control"
        }
        data={data}
        {...rest}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomCascader;
