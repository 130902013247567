import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateCustomerDiscount } from "src/store/customerdiscounts/saga";
import { CustomerDiscountDto } from "src/store/customerdiscounts/type";
import { DiscountValidityPeriodEnum } from "src/store/lookup/enum";
import {
  getDiscountModuleLookupList,
  getDiscountTypeLookupList,
  getDiscountValidityPeriodLookupList,
} from "src/store/lookup/saga";
import {
  DiscountModuleDto,
  DiscountTypeDto,
  DiscountValidityPeriodDto,
} from "src/store/lookup/type";
import * as Yup from "yup";

const EditCustomerAddressModal = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: CustomerDiscountDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [discountModules, setDiscountModules] = useState<DiscountModuleDto[]>(
    []
  );
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const [discountValidityPeriods, setDiscountValidityPeriods] = useState<
    DiscountValidityPeriodDto[]
  >([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getDiscountModuleLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountModules(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getDiscountValidityPeriodLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountValidityPeriods(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getDiscountTypeLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getDiscountTypeLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountTypes(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit Customer Discount"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          CustomerId: data.CustomerId,
          DiscountModuleId: data.DiscountModuleId,
          DiscountValidityPeriodId: data.DiscountValidityPeriodId,
          DiscountTypeId: data.DiscountTypeId,
          DiscounValue: data.DiscounValue,
          StartDate: data.StartDate ?? "",
          EndDate: data.EndDate ?? "",
          Quantity: data.Quantity,
        }}
        onSubmit={values => {
          dispatch(
            updateCustomerDiscount({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(t("Succesfully added"));
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          DiscountModuleId: Yup.number().required(t("Required")),
          DiscountValidityPeriodId: Yup.number().required(t("Required")),
          DiscountTypeId: Yup.number().required(t("Required")),
          DiscounValue: Yup.number().required(t("Required")),
          Quantity: Yup.number().required(t("Required")),
          StartDate: Yup.string().when("DiscountValidityPeriodId", {
            is: (id: number) => id === DiscountValidityPeriodEnum.Süreli,
            then: Yup.string().required(t("Required")),
          }),
          EndDate: Yup.string().when("DiscountValidityPeriodId", {
            is: (id: number) => id === DiscountValidityPeriodEnum.Süreli,
            then: Yup.string().required(t("Required")),
          }),
        })}
        inputs={[
          {
            col: 6,
            field: "DiscountModuleId",
            inputType: InputType.multiselect,
            label: t("Discount Module"),
            lookup: {
              data: discountModules,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose"),
            },
          },
          {
            col: 6,
            field: "DiscountValidityPeriodId",
            inputType: InputType.multiselect,
            label: t("Discount Validity Period"),
            lookup: {
              data: discountValidityPeriods,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose"),
            },
          },
          {
            col: 6,
            field: "DiscountTypeId",
            inputType: InputType.multiselect,
            label: t("Discount Type"),
            lookup: {
              data: discountTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose"),
            },
          },
          {
            field: "DiscounValue",
            label: t("Discount Value"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "Quantity",
            label: t("Quantity"),
            inputType: InputType.number,
          },
          {
            hide: values =>
              values.DiscountValidityPeriodId !==
              DiscountValidityPeriodEnum.Süreli,
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            hide: values =>
              values.DiscountValidityPeriodId !==
              DiscountValidityPeriodEnum.Süreli,
            field: "EndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditCustomerAddressModal;
