import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerCallLogsDto, CustomerCallLogTypeDto } from "./type";
import { BodyType } from "../base/request/request";
import { UserDto } from "../user/type";

export const getCustomerCallLogs = createAction(
  "GET_CUSTOMER_CALLLOGS",
  ({ payload, params }: SagaActionParams<CustomerCallLogsDto[]>) => ({
    payload,
    url: "/api/customercalllogs" + (params ? params : ""),
  })
);
export const getCustomerCallLogsByCustomerId = createAction(
  "GET_CUSTOMER_CALLLOGS_CATEGORY_ID",
  ({ payload, id }: SagaActionParams<CustomerCallLogsDto[]>) => ({
    payload,
    url: "/api/customercalllogs/bycategoryId/" + id,
  })
);
export const getCustomerCallLogsDetails = createAction(
  "GET_CUSTOMER_CALLLOGS_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerCallLogsDto>) => ({
    payload,
    url: "/api/customercalllogs/" + id,
  })
);
export const createCustomerCallLogs = createAction(
  "CREATE_CUSTOMER_CALLLOGS",
  ({ payload, body }: SagaActionParams<CustomerCallLogsDto>) => ({
    payload,
    url: "/api/customercalllogs",
    body,
  })
);
export const updateCustomerCallLogs = createAction(
  "UPDATE_CUSTOMER_CALLLOGS",
  ({ payload, id, body }: SagaActionParams<CustomerCallLogsDto>) => ({
    payload,
    url: "/api/customercalllogs/" + id,
    body,
  })
);
export const changeOrderCustomerCallLogs = createAction(
  "CHANGE_ORDER_CUSTOMER_CALLLOGS",
  ({ payload, body }: SagaActionParams<CustomerCallLogsDto>) => ({
    payload,
    url: "/api/customercalllogs/order",

    bodyType: BodyType.raw,
    body,
  })
);
export const deleteCustomerCallLogs = createAction(
  "DELETE_CUSTOMER_CALLLOGS",
  ({ payload, id }: SagaActionParams<CustomerCallLogsDto>) => ({
    payload,
    url: "/api/customercalllogs/" + id,
  })
);
export const getCustomerCallLogsTypes = createAction(
  "GET_CUSTOMER_CALLLOGS_TYPES",
  ({ payload }: SagaActionParams<CustomerCallLogTypeDto[]>) => ({
    payload,
    url: "/api/system/customer-calllogtypes",
  })
);
export const getCustomerCallLogsTicketUsers = createAction(
  "GET_CUSTOMER_CALLLOGS_TICKET_USERS",
  ({ payload }: SagaActionParams<UserDto[]>) => ({
    payload,
    url: "/api/customercalllogs/users",
  })
);
const customercalllogsSaga = [
  /*CUSTOMER_CALLLOGS*/ takeLatest(
    getCustomerCallLogsTypes.toString(),
    (payload: SagaGenericParams<CustomerCallLogTypeDto[]>) =>
      getListSaga<CustomerCallLogTypeDto[]>(payload)
  ),
  takeLatest(
    getCustomerCallLogsTicketUsers.toString(),
    (payload: SagaGenericParams<UserDto[]>) => getListSaga<UserDto[]>(payload)
  ),
  takeLatest(
    getCustomerCallLogs.toString(),
    (payload: SagaGenericParams<CustomerCallLogsDto[]>) =>
      getListSaga<CustomerCallLogsDto[]>(payload)
  ),
  takeLatest(
    getCustomerCallLogsByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerCallLogsDto[]>) =>
      getListSaga<CustomerCallLogsDto[]>(payload)
  ),
  takeLatest(
    getCustomerCallLogsDetails.toString(),
    (payload: SagaGenericParams<CustomerCallLogsDto>) =>
      getListSaga<CustomerCallLogsDto>(payload)
  ),
  takeLatest(
    createCustomerCallLogs.toString(),
    (payload: PostSagaGenericParams<CustomerCallLogsDto>) =>
      postSaga<CustomerCallLogsDto>(payload)
  ),
  takeLatest(
    updateCustomerCallLogs.toString(),
    (payload: PostSagaGenericParams<CustomerCallLogsDto>) =>
      putSaga<CustomerCallLogsDto>(payload)
  ),
  takeLatest(
    changeOrderCustomerCallLogs.toString(),
    (payload: PostSagaGenericParams<CustomerCallLogsDto>) =>
      putSaga<CustomerCallLogsDto>(payload)
  ),
  takeLatest(
    deleteCustomerCallLogs.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CustomerCallLogsDto>(payload)
  ),
];

export default customercalllogsSaga;
