import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as Type from "./type";
export const getRoles = createAction(
  "GET_ROLES",
  ({ payload }: SagaActionParams<Type.RoleDto[]>) => ({
    payload,
    url: "/api/appuser/assignable-roles",
  })
);
export const getNotificationPage = createAction(
  "GET_NOTIFICATION_PAGE_LIST",
  ({ payload }: SagaActionParams<Type.NotificationPageDto[]>) => ({
    payload,
    url: "/api/system/notificationpagelist",
  })
);
export const getAddressTypes = createAction(
  "GET_ADDRESS_TYPES",
  ({ payload }: SagaActionParams<Type.AddressTypeDto[]>) => ({
    payload,
    url: "/api/system/address-types",
  })
);
export const getUsageTypes = createAction(
  "GET_USAGE_TYPES",
  ({ payload }: SagaActionParams<Type.UsageTypeDto[]>) => ({
    payload,
    url: "/api/system/usagetypes",
  })
);
export const getDiscountModuleLookupList = createAction(
  "GET_DISCOUNT_MODULE_LOOKUP_LIST",
  ({ payload }: SagaActionParams<Type.DiscountModuleDto[]>) => ({
    payload,
    url: "/api/system/discountmodules",
  })
);
export const getDiscountValidityPeriodLookupList = createAction(
  "GET_DISCOUNT_VALIDITY_PERIOD_LOOKUP_LIST",
  ({ payload }: SagaActionParams<Type.DiscountValidityPeriodDto[]>) => ({
    payload,
    url: "/api/system/discountvalidityperiods",
  })
);
export const getprivilegeCategoryTypes = createAction(
  "GET_PRIVILIGE_CATEGORY_TYPES",
  ({ payload }: SagaActionParams<Type.PrivilegeCouponType[]>) => ({
    payload,
    url: "/api/system/privilegecategorytypes",
  })
);
export const getprivilegeCouponTypes = createAction(
  "GET_PRIVILIGE_COUPON_TYPES",
  ({ payload }: SagaActionParams<Type.PrivilegeCouponType[]>) => ({
    payload,
    url: "/api/system/privilegecoupontypes",
  })
);
export const getDiscountTypeLookupList = createAction(
  "GET_DISCOUNT_TYPE_LOOKUP_LIST",
  ({ payload }: SagaActionParams<Type.DiscountTypeDto[]>) => ({
    payload,
    url: "/api/system/discounttypes",
  })
);
export const getCouponValidityTypes = createAction(
  "GET_COUPON_VALIDITY_TYPE_LOOKUP_LIST",
  ({ payload }: SagaActionParams<Type.EnumType[]>) => ({
    payload,
    url: "/api/system/coupon-validity-types",
  })
);
export const getCities = createAction(
  "GET_CITIES",
  ({ payload }: SagaActionParams<Type.CityDto[]>) => ({
    payload,
    url: "/api/system/cities",
  })
);
export const getTowns = createAction(
  "GET_TOWNS",
  ({ payload, id }: SagaActionParams<Type.TownDto[]>) => ({
    payload,
    url: "/api/system/towns?CityId=" + id,
  })
);
export const getPrivilegeUsageTypes = createAction(
  "GET_PRIVILEGE_USAGE_TYPES",
  ({ payload }: SagaActionParams<Type.UsageTypeDto[]>) => ({
    payload,
    url: "/api/system/privilageusagetypes",
  })
);
const lookupSaga = [
  takeLatest(
    getPrivilegeUsageTypes.toString(),
    (payload: SagaGenericParams<Type.UsageTypeDto[]>) =>
      getListSaga<Type.UsageTypeDto[]>(payload)
  ),

  takeLatest(
    getUsageTypes.toString(),
    (payload: SagaGenericParams<Type.UsageTypeDto[]>) =>
      getListSaga<Type.UsageTypeDto[]>(payload)
  ),
  takeLatest(
    getAddressTypes.toString(),
    (payload: SagaGenericParams<Type.AddressTypeDto[]>) =>
      getListSaga<Type.AddressTypeDto[]>(payload)
  ),
  takeLatest(
    getTowns.toString(),
    (payload: SagaGenericParams<Type.TownDto[]>) =>
      getListSaga<Type.TownDto[]>(payload)
  ),
  takeLatest(
    getCouponValidityTypes.toString(),
    (payload: SagaGenericParams<Type.CityDto[]>) =>
      getListSaga<Type.CityDto[]>(payload)
  ),
  takeLatest(
    getCities.toString(),
    (payload: SagaGenericParams<Type.CityDto[]>) =>
      getListSaga<Type.CityDto[]>(payload)
  ),
  takeLatest(
    getDiscountTypeLookupList.toString(),
    (payload: SagaGenericParams<Type.DiscountTypeDto[]>) =>
      getListSaga<Type.DiscountTypeDto[]>(payload)
  ),
  takeLatest(
    getDiscountModuleLookupList.toString(),
    (payload: SagaGenericParams<Type.DiscountModuleDto[]>) =>
      getListSaga<Type.DiscountModuleDto[]>(payload)
  ),
  takeLatest(
    getNotificationPage.toString(),
    (payload: SagaGenericParams<Type.NotificationPageDto[]>) =>
      getListSaga<Type.NotificationPageDto[]>(payload)
  ),
  takeLatest(
    getDiscountValidityPeriodLookupList.toString(),
    (payload: SagaGenericParams<Type.DiscountValidityPeriodDto[]>) =>
      getListSaga<Type.DiscountTypeDto[]>(payload)
  ),
  takeLatest(
    getprivilegeCategoryTypes.toString(),
    (payload: SagaGenericParams<Type.PrivilegeCouponType[]>) =>
      getListSaga<Type.PrivilegeCouponType[]>(payload)
  ),
  takeLatest(
    getprivilegeCouponTypes.toString(),
    (payload: SagaGenericParams<Type.PrivilegeCategoryType[]>) =>
      getListSaga<Type.PrivilegeCategoryType[]>(payload)
  ),
  takeLatest(
    getRoles.toString(),
    (payload: SagaGenericParams<Type.RoleDto[]>) =>
      getListSaga<Type.RoleDto[]>(payload)
  ),
];

export default lookupSaga;
