import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import CustomModal from "src/components/Modal/modal";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import { toast } from "react-toastify";
import {
  OrderDetailDto,
  OrderItem,
  OrderStatusDto,
} from "src/store/orders/orders/type";
import {
  getOrderItemStatuses,
  getOrderStatuses,
  updateOrderStatus,
} from "src/store/orders/orders/saga";

const UpdateOrderStatusModal = ({
  orderItem,
  order,
  onSuccessClick,
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderItem?: OrderItem;
  order?: OrderDetailDto;
  onSuccessClick: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderStatus, setOrderStatus] = useState<OrderStatusDto[]>([]);
  const [orderItemStatus, setOrderItemStatus] = useState<OrderStatusDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getOrderStatuses({
          payload: {
            onSuccess: (m, p) => {
              return setOrderStatus(p);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getOrderItemStatuses({
          payload: {
            onSuccess: (m, p) => {
              return setOrderItemStatus(p);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <CustomModal
        size="xs"
        open={open}
        onHide={() => setOpen(false)}
        title={`Sipariş Durumunu Güncelle`}
      >
        <Formik
          initialValues={{
            orderId: order?.Id ?? "",
            orderStatusId: order?.OrderStatusId,
            orderItemId: orderItem?.Id ?? "",
            orderItemStatusId: orderItem?.OrderItemStatusId,
          }}
          onSubmit={values => {
            dispatch(
              updateOrderStatus({
                payload: {
                  onSuccess: (message, payload) => {
                    setOpen(false);
                    toast.success(message);
                    onSuccessClick();
                  },
                  onError: message => {
                    toast.error(message);
                  },
                },
                body: values,
              })
            );
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({ touched, handleSubmit, setFieldValue, errors, values }) => (
            <>
              <Row style={{ width: "100%", paddingInline: "10px" }}>
                {order && (
                  <Col md={12} className="mb-2">
                    <GetInput
                      inputprop={{
                        col: 12,
                        field: "orderStatusId",
                        label: t("Sipariş Durumu"),
                        inputType: InputType.multiselect,
                        lookup: {
                          data: orderStatus,
                          labelKey: "Name",
                          valueKey: "EnumId",
                        },
                      }}
                    />
                  </Col>
                )}
                {order && orderItem && (
                  <Col md={12} className="mb-2">
                    <GetInput
                      inputprop={{
                        col: 12,
                        field: "orderItemStatusId",
                        label: t("Sipariş Alt Satır Durumu"),
                        inputType: InputType.multiselect,
                        lookup: {
                          data: orderItemStatus,
                          labelKey: "Name",
                          valueKey: "EnumId",
                        },
                      }}
                    />
                  </Col>
                )}
                <Col md={12}>
                  <div className="w-100 d-flex justify-content-between ">
                    <Button
                      color="danger"
                      type="submit"
                      onClick={() => setOpen(false)}
                    >
                      {t("Kapat")}
                    </Button>{" "}
                    <Button
                      color="success"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      {t("Güncelle")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};
export default UpdateOrderStatusModal;
