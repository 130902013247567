import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { map } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TireSaleDetailDto,
  TireSaleItem,
  TireSaleStatusEnum,
} from "src/store/tires/tireSales/type";
import { getTireSaleDetails } from "src/store/tires/tireSales/saga";
import { getCurrency } from "src/helpers/getCurrency";
import UpdateStatusModal from "./update-status";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import StatusDetail from "./statusDetail";
import TireSupplierInfo from "./supplierInfo";
import CancelModal from "./cancel";
import moment from "moment";

const TireSaleDetailsPage = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const loader = useSelector(isLoading);
  const [tireSale, setTireSale] = useState<TireSaleDetailDto>();
  useEffect(() => {
    dispatch(
      getTireSaleDetails({
        payload: {
          onSuccess: (msg, payload) => setTireSale(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  // //Print the Invoice
  // const printInvoice = () => {
  //   window.print();
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>Lastik Sipariş Detay</MetaTags>
        {loader && <Loader />}
        {tireSale && (
          <Container fluid>
            <Breadcrumbs
              title={t("Dashboard")}
              breadcrumbItem={`#${tireSale.Code} Lastik Siparişi`}
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="w-100 d-flex gap-3 justify-content-end mb-4">
                      {tireSale.TireSaleStatusId !==
                        TireSaleStatusEnum.Tamamlandı && (
                        <>
                          {(tireSale.TireSaleStatusId ===
                            TireSaleStatusEnum.YeniSatis ||
                            tireSale.TireSaleStatusId ===
                              TireSaleStatusEnum.TedarikciyeIletildi) && (
                            <CancelModal
                              data={tireSale}
                              onSuccessClick={() =>
                                dispatch(
                                  getTireSaleDetails({
                                    payload: {
                                      onSuccess: (msg, payload) =>
                                        setTireSale(payload),
                                      onError: () => {},
                                    },
                                    id: id,
                                  })
                                )
                              }
                            />
                          )}
                          {tireSale.TireSaleStatusId !==
                            TireSaleStatusEnum.Iptal && (
                            <UpdateStatusModal
                              data={tireSale}
                              onSuccessClick={() =>
                                dispatch(
                                  getTireSaleDetails({
                                    payload: {
                                      onSuccess: (msg, payload) =>
                                        setTireSale(payload),
                                      onError: () => {},
                                    },
                                    id: id,
                                  })
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                    <hr className="my-4 " />
                    <StatusDetail data={tireSale} />
                    <div className="mb-5 " />
                    <Row>
                      <Col sm={6}>
                        <address>
                          <strong>{"Fatura Bilgileri"}</strong>
                          <br />
                          {tireSale.CustomerFullName}
                          <br />
                          {tireSale.InvoiceAddress}
                          <br />
                          {tireSale.CustomerPhoneNumber}
                        </address>
                      </Col>
                      <Col sm={6} className="text-end">
                        <address>
                          <strong>{"Teslimat"}</strong>
                          <br />
                          {tireSale.InvoiceAddress}
                        </address>
                      </Col>
                      <Col sm={6}>
                        <strong>Sipariş Tarihi</strong>
                        <br />
                        {moment().format("DD/MM/yyyy HH:mm")}
                      </Col>
                      <Col sm={6} className="text-end"></Col>
                    </Row>
                    {/* <Row>
                      <Col sm="4">
                        <div>
                          <h5 className="font-size-15 mb-3">Üye Bilgileri</h5>
                          {tireSale.CustomerFullName}
                          <br />
                        </div>
                        <div>
                          <h5 className="font-size-15 mb-3">Fatura Adresi</h5>
                          {tireSale.InvoiceAddress}
                        </div>
                        <div>
                          <h5 className="font-size-15 mb-3">Teslimat Adresi</h5>
                          {tireSale.Address}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div>
                          <div>
                            <h5 className="font-size-15">Sipariş Tarihi</h5>
                            <p>{getLocalDate(tireSale.SalesDate)}</p>
                          </div>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-15">İletişim Bilgileri</h5>
                          <p className="mb-1">{tireSale.CustomerPhoneNumber}</p>
                          <p>{tireSale.CustomerEmail}</p>
                        </div>
                      </Col>
                    </Row> */}
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">Sipariş Özeti</h3>
                    </div>
                    <div className="p-4 border rounded">
                      <div className="table-responsive">
                        <Table className="table-nowrap align-middle mb-0">
                          <thead>
                            <tr>
                              <th>Tedarikçi Bilgileri</th>
                              <th>Lastik</th>
                              <th>Adet</th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Toplam Fiyat
                              </th>{" "}
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Kargo
                              </th>
                              <th
                                className="text-end"
                                style={{ width: "120px" }}
                              >
                                Genel Toplam
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {map(
                              tireSale.TireSaleItems,
                              (item: TireSaleItem, key) => (
                                <tr key={key}>
                                  <td>
                                    <div>
                                      {
                                        item.Tire.TireSupplierInfo
                                          .TireSupplierName
                                      }{" "}
                                      <TireSupplierInfo
                                        data={item.Tire.TireSupplierInfo}
                                      />
                                      <br />
                                      <span className="text-muted font-size-12">
                                        #
                                        {
                                          item.Tire.TireSupplierInfo
                                            .SupplierStockCode
                                        }
                                      </span>
                                    </div>
                                  </td>
                                  <td>{item.Tire.Name}</td>
                                  <td>{item.Count}</td>
                                  <td className="text-end">
                                    {getCurrency(item.TotalPrice)}
                                  </td>
                                  <td className="text-end">
                                    {getCurrency(item.ShippingGrandTotal)}
                                  </td>
                                  <td className="text-end">
                                    {getCurrency(item.GrandTotal)}
                                  </td>
                                </tr>
                              )
                            )}
                            <tr>
                              <td colSpan={5} className="border-0 text-end">
                                <strong>Genel Tutar</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {getCurrency(tireSale.GrandTotal)}
                                </h4>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={5} className="border-0 text-end">
                                <strong>Komisyonlu Genel Tutar</strong>
                              </td>
                              <td className="border-0 text-end">
                                <h4 className="m-0">
                                  {getCurrency(
                                    tireSale.GrandTotalWithCommission
                                  )}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(TireSaleDetailsPage);
