import { CustomDatepickerProps } from "../type";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import React from "react";
import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

const CustomDatePicker = ({
  label,
  error,
  field,
  touched,
  disabled,
  ...rest
}: CustomDatepickerProps) => {
  return (
    <div className="textOnInput my-2">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
        style={{ background: "white" }}
      >
        {label ? label : ""}
      </label>
      <DatePicker
        locale="tr"
        disabled={disabled ? disabled : false}
        dateFormat="dd-MM-yyyy HH:mm"
        className={
          error && touched
            ? " errorInput defaultInput form-control selectpickerInput form2-control customDatePicker"
            : " defaultInput form-control selectpickerInput form2-control customDatePicker"
        }
        //  customInput={!error ? <CustomInput /> : <CustomErrorInput />}
        {...rest}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomDatePicker;
