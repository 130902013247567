import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, postSaga, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as T from "./type";
import { BodyType } from "../base/request/request";

export const getInvoicePackages = createAction(
  "GET_INVOICED_PACKAGES",
  ({ payload, params }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    url: "/api/invoices/getinvoicedpackagelist" + (params ?? ""),
  })
);
export const getUninvoicePackages = createAction(
  "GET_UNINVOICED_PACKAGES",
  ({ payload, params }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    url: "/api/invoices/getwillbeinvoicedpackagelist" + (params ?? ""),
  })
);

export const invoicePackage = createAction(
  "INVOICE_PACKAGE",
  ({ payload, body, id }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    body,
    url: "/api/invoices/updatepackage/" + id,
  })
);

export const invoicePackageWithAccountment = createAction(
  "INVOICE_PACKAGE_WITH_ACCOUNTMENT",
  ({ payload, body }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    url: "/api/invoices/bill-package-sales",
    body,
    bodyType: BodyType.raw,
  })
);

export const invoiceCancellationPayment = createAction(
  "INVOICE_CANCELLATION_PAYMENT",
  ({ payload, body }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/invoices/update-membership-cancellation-payment",
  })
);

export const invoiceCancellationPaymentWithAccountment = createAction(
  "INVOICE_CANCELLATION_PAYMEN_WITH_ACCOUNTMENTT",
  ({ payload, body, id }: SagaActionParams<T.InvoicePackageDto[]>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/invoices/bill-membership-cancellation-payment",
  })
);
const invoicePackageSaga = [
  takeLatest(
    getInvoicePackages.toString(),
    (payload: SagaGenericParams<T.InvoicePackageDto[]>) =>
      getListSaga<T.InvoicePackageDto[]>(payload)
  ),
  takeLatest(
    getUninvoicePackages.toString(),
    (payload: SagaGenericParams<T.InvoicePackageDto[]>) =>
      getListSaga<T.InvoicePackageDto[]>(payload)
  ),
  takeLatest(
    invoicePackage.toString(),
    (payload: PostSagaGenericParams<T.InvoicePackageDto[]>) =>
      putSaga<T.InvoicePackageDto[]>(payload)
  ),
  takeLatest(
    invoicePackageWithAccountment.toString(),
    (payload: PostSagaGenericParams<T.InvoicePackageDto[]>) =>
      postSaga<T.InvoicePackageDto[]>(payload)
  ),
  takeLatest(
    invoiceCancellationPayment.toString(),
    (payload: PostSagaGenericParams<T.InvoicePackageDto[]>) =>
      postSaga<T.InvoicePackageDto[]>(payload)
  ),

  takeLatest(
    invoiceCancellationPayment.toString(),
    (payload: PostSagaGenericParams<T.InvoicePackageDto[]>) =>
      putSaga<T.InvoicePackageDto[]>(payload)
  ),
  takeLatest(
    invoiceCancellationPaymentWithAccountment.toString(),
    (payload: PostSagaGenericParams<T.InvoicePackageDto[]>) =>
      postSaga<T.InvoicePackageDto[]>(payload)
  ),
];

export default invoicePackageSaga;
