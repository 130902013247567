import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CreatePrivilegePrice from "./create";
import { toast } from "react-toastify";
import EditPrivilegePrice from "./edit";
import CancelAlert from "src/components/CancelAlert";
import MainPage from "src/components/PageContent/MainPage";
import { LocalizationDto } from "src/store/localizations/type";
import {
  deleteLocalizations,
  getLocalizationsDetails,
} from "src/store/localizations/saga";

const BELocalizationsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<LocalizationDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<LocalizationDto>();

  // const columns = [
  //   {
  //     text: t("Key"),
  //     dataField: "Key",
  //   },
  //   {
  //     text: t("Value"),
  //     dataField: "Value",
  //   },

  //   {
  //     text: "",
  //     dataField: "Id",
  //     formatter: (cellContent: any, data: any) => (
  //       <div className="d-flex w-30 justify-content-end gap-1">
  //         <ColumnButton
  //           buttonType="edit"
  //           onClick={() => {
  //             dispatch(
  //               getLocalizationsDetails({
  //                 payload: {
  //                   onSuccess: (message, payload) => {
  //                     setSelected(payload);
  //                     return setShowEdit(true);
  //                   },
  //                   onError: () => {},
  //                 },
  //                 id: cellContent,
  //               })
  //             );
  //           }}
  //         />
  //         <ColumnButton
  //           buttonType="delete"
  //           onClick={() => {
  //             return setShowDelete(data);
  //           }}
  //         />
  //       </div>
  //     ),
  //   },
  // ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    // dispatch(
    //   getBackendLocalizations({
    //     payload: {
    //       onSuccess: (message, payload) => {
    //         setData(payload);
    //       },
    //       onError: () => {},
    //     },
    //     id: id,
    //   })
    // );
  };
  const gridOptions: any = {
    url: "/api/localizations/devexp",
    columns: [
      {
        caption: t("Key"),
        dataField: "Key",
      },
      {
        caption: t("Value"),
        dataField: "Value",
      },
      {
        type: "buttons",
        width: 110,
        buttons: [
          {
            hint: "Düzenle",
            icon: "edit",
            onClick: async (e: any) => {
              dispatch(
                getLocalizationsDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: e.row.key,
                })
              );
            },
          },
        ],
      },
    ],
  };
  return (
    <React.Fragment>
      {selected && (
        <EditPrivilegePrice
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreatePrivilegePrice
        sourceType={1}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <MainPage
        devProps={{
          ...gridOptions,
          create: () => setShowCreate(true),
        }}
        useDevGrid={true}
        title={t("Backend Uyarı Mesajları")}
      />
      {/* <MainPage
        title={"Backend Uyarı Mesajları"}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          },
          setDataList: setData,
          action: getBackendLocalizations,
          paramsid: id,
        }}
      /> */}
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteLocalizations({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default BELocalizationsPage;
