import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useParams } from "react-router";
import { getCustomerCallLogsDetails } from "src/store/customer-calllog/saga";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";
import EditCustomerCalllogPage from "./editform";
import CustomerCard from "./card-customer";
import VehicleCard from "./card-vehicle";
import CalllogCard from "./card-calllog";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";

const DetailsCustomerCalllogPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const [active, setActive] = useState(1);
  const [data, setData] = useState<CustomerCallLogsDto>();
  useEffect(() => {
    dispatch(
      getCustomerCallLogsDetails({
        payload: {
          onSuccess: (msg, payload) => setData(payload),
          onError: () => {},
        },
        id: id,
      })
    );
    // eslint-disable-next-line
  }, [dispatch, active]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Arama Kaydı Ekle")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Dashboard")}
            breadcrumbItem={
              data ? data.Code + t(" Arama Kaydı ") : t("Arama Kaydı ")
            }
          />
          {loading && <Loader />}
          <Row>
            {data && (
              <>
                {active === 1 && (
                  <div>
                    <div className="d-flex justify-content-end mb-2 gap-2">
                      {data.CustomerId && (
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() =>
                            history.push(
                              "/customer/details/1/" + data.CustomerId
                            )
                          }
                        >
                          Müşteriye Git
                        </button>
                      )}
                      {data.VehicleId && (
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() =>
                            history.push("/customer-vehicle/" + data.VehicleId)
                          }
                        >
                          Araca Git
                        </button>
                      )}
                      {data.TicketId && (
                        <button
                          className="btn btn-sm btn-secondary"
                          onClick={() =>
                            history.push(
                              "/ticket/all?ticketId=" + data.TicketId
                            )
                          }
                        >
                          Talebe Git
                        </button>
                      )}
                      {/* <button
                        className="btn btn-sm btn-success"
                        onClick={() => setActive(2)}
                      >
                        Düzenle
                      </button> */}
                    </div>
                    <Row>
                      <Col md={4}>
                        <CalllogCard data={data} />
                      </Col>
                      <Col md={4}>
                        <CustomerCard data={data} customer={data.Customer} />
                      </Col>
                      <Col md={4}>
                        <VehicleCard vehicle={data.Vehicle} data={data} />
                      </Col>
                    </Row>
                  </div>
                )}
                {active === 2 && (
                  <EditCustomerCalllogPage
                    active={active}
                    data={data}
                    setActive={setActive}
                  />
                )}
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DetailsCustomerCalllogPage;
