import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { ButtonActionTypeDto, PrivilegeButtonDto } from "./type";
import { BodyType } from "../base/request/request";

export const getPrivilegeButtonActionTypes = createAction(
  "GET_PRIVILEGE_BUTTON_ACTION_TYPE",
  ({ payload, params }: SagaActionParams<ButtonActionTypeDto[]>) => ({
    payload,
    url: "/api/system/button-action-types" + (params ? params : ""),
  })
);
export const getPrivilegeButtonListByPrivilegeId = createAction(
  "GET_PRIVILEGEBUTTONS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeButtonDto[]>) => ({
    payload,
    url:
      "/api/privilegebuttons/getbyprivilegeid/" + id + (params ? params : ""),
  })
);

export const getPrivilegeButtonList = createAction(
  "GET_PRIVILEGEBUTTONS_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeButtonDto[]>) => ({
    payload,
    url: "/api/privilegebuttons" + (params ? params : ""),
  })
);

export const getPrivilegeButtonDetails = createAction(
  "GET_PRIVILEGEBUTTONS_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeButtonDto>) => ({
    payload,
    url: "/api/privilegebuttons/" + id,
  })
);
export const createPrivilegeButton = createAction(
  "CREATE_PRIVILEGEBUTTONS_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeButtonDto>) => ({
    payload,
    url: "/api/privilegebuttons",
    body,
  })
);
export const updatePrivilegeButton = createAction(
  "UPDATE_PRIVILEGEBUTTONS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeButtonDto>) => ({
    payload,
    url: "/api/privilegebuttons/" + id,
    body,
  })
);
export const deletePrivilegeButton = createAction(
  "DELETE_PRIVILEGEBUTTONS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeButtonDto>) => ({
    payload,
    url: "/api/privilegebuttons/" + id,
    body,
  })
);
export const changePrivilegeButtonOrder = createAction(
  "CHANGE_PRIVILEGE_TERMS_ORDER",
  ({ payload, body }: SagaActionParams<PrivilegeButtonDto>) => ({
    payload,
    url: "/api/privilegebuttons/order",
    bodyType: BodyType.raw,
    body,
  })
);
const privilegebuttonsSaga = [
  takeLatest(
    getPrivilegeButtonActionTypes.toString(),
    (payload: SagaGenericParams<ButtonActionTypeDto[]>) =>
      getListSaga<ButtonActionTypeDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeButtonListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeButtonDto[]>) =>
      getListSaga<PrivilegeButtonDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeButtonList.toString(),
    (payload: SagaGenericParams<PrivilegeButtonDto[]>) =>
      getListSaga<PrivilegeButtonDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeButtonDetails.toString(),
    (payload: SagaGenericParams<PrivilegeButtonDto>) =>
      getListSaga<PrivilegeButtonDto>(payload)
  ),
  takeLatest(
    createPrivilegeButton.toString(),
    (payload: PostSagaGenericParams<PrivilegeButtonDto>) =>
      postSaga<PrivilegeButtonDto>(payload)
  ),
  takeLatest(
    updatePrivilegeButton.toString(),
    (payload: PostSagaGenericParams<PrivilegeButtonDto>) =>
      putSaga<PrivilegeButtonDto>(payload)
  ),
  takeLatest(
    changePrivilegeButtonOrder.toString(),
    (payload: PostSagaGenericParams<PrivilegeButtonDto>) =>
      putSaga<PrivilegeButtonDto>(payload)
  ),
  takeLatest(
    deletePrivilegeButton.toString(),
    (payload: SagaGenericParams<PrivilegeButtonDto>) =>
      deleteSaga<PrivilegeButtonDto>(payload)
  ),
];

export default privilegebuttonsSaga;
