import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SupplierServiceTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getSupplierServiceTypeList = createAction(
  "GET_SUPPLIERSERVICETYPE_LIST",
  ({ payload, params }: SagaActionParams<SupplierServiceTypeDto[]>) => ({
    payload,
    url: "/api/supplierservicetypes" + (params ? params : ""),
  })
);
export const getSupplierServiceTypeDetails = createAction(
  "GET_SUPPLIERSERVICETYPE_DETAILS",
  ({ payload, id }: SagaActionParams<SupplierServiceTypeDto>) => ({
    payload,
    url: "/api/supplierservicetypes/" + id,
  })
);
export const createSupplierServiceType = createAction(
  "CREATE_SUPPLIERSERVICETYPE_DETAILS",
  ({ payload, body }: SagaActionParams<SupplierServiceTypeDto>) => ({
    payload,
    url: "/api/supplierservicetypes",
    body,
  })
);
export const updateSupplierServiceType = createAction(
  "UPDATE_SUPPLIERSERVICETYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierServiceTypeDto>) => ({
    payload,
    url: "/api/supplierservicetypes/" + id,
    body,
  })
);
export const deleteSupplierServiceType = createAction(
  "DELETE_SUPPLIERSERVICETYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierServiceTypeDto>) => ({
    payload,
    url: "/api/supplierservicetypes/" + id,
    body,
  })
);

export const changeSupplierServiceTypeOrder = createAction(
  "UPDATE_SUPPLIERSERVICETYPE_ORDER",
  ({ payload, body }: SagaActionParams<SupplierServiceTypeDto>) => ({
    payload,
    url: "/api/supplierservicetypes/order",

    bodyType: BodyType.raw,
    body,
  })
);
const supplierservicetypeSaga = [
  takeLatest(
    getSupplierServiceTypeList.toString(),
    (payload: SagaGenericParams<SupplierServiceTypeDto[]>) =>
      getListSaga<SupplierServiceTypeDto[]>(payload)
  ),
  takeLatest(
    getSupplierServiceTypeDetails.toString(),
    (payload: SagaGenericParams<SupplierServiceTypeDto>) =>
      getListSaga<SupplierServiceTypeDto>(payload)
  ),
  takeLatest(
    createSupplierServiceType.toString(),
    (payload: PostSagaGenericParams<SupplierServiceTypeDto>) =>
      postSaga<SupplierServiceTypeDto>(payload)
  ),
  takeLatest(
    changeSupplierServiceTypeOrder.toString(),
    (payload: PostSagaGenericParams<SupplierServiceTypeDto>) =>
      putSaga<SupplierServiceTypeDto>(payload)
  ),
  takeLatest(
    updateSupplierServiceType.toString(),
    (payload: PostSagaGenericParams<SupplierServiceTypeDto>) =>
      putSaga<SupplierServiceTypeDto>(payload)
  ),
  takeLatest(
    deleteSupplierServiceType.toString(),
    (payload: SagaGenericParams<SupplierServiceTypeDto>) =>
      deleteSaga<SupplierServiceTypeDto>(payload)
  ),
];

export default supplierservicetypeSaga;
