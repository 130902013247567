import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeContainDto } from "./type";

export const getPrivilegeContainListByPrivilegeId = createAction(
  "GET_PRIVILEGECONTAINS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeContainDto[]>) => ({
    payload,
    url:
      "/api/containsprivileges/getbyprivilegeid/" + id + (params ? params : ""),
  })
);

export const getPrivilegeContainList = createAction(
  "GET_PRIVILEGECONTAINS_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeContainDto[]>) => ({
    payload,
    url: "/api/containsprivileges" + (params ? params : ""),
  })
);

export const getPrivilegeContainDetails = createAction(
  "GET_PRIVILEGECONTAINS_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeContainDto>) => ({
    payload,
    url: "/api/containsprivileges/" + id,
  })
);
export const createPrivilegeContain = createAction(
  "CREATE_PRIVILEGECONTAINS_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeContainDto>) => ({
    payload,
    url: "/api/containsprivileges",
    body,
  })
);
export const updatePrivilegeContain = createAction(
  "UPDATE_PRIVILEGECONTAINS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeContainDto>) => ({
    payload,
    url: "/api/containsprivileges/" + id,
    body,
  })
);
export const deletePrivilegeContain = createAction(
  "DELETE_PRIVILEGECONTAINS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeContainDto>) => ({
    payload,
    url: "/api/containsprivileges/" + id,
    body,
  })
);

const containsprivilegesSaga = [
  takeLatest(
    getPrivilegeContainListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeContainDto[]>) =>
      getListSaga<PrivilegeContainDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeContainList.toString(),
    (payload: SagaGenericParams<PrivilegeContainDto[]>) =>
      getListSaga<PrivilegeContainDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeContainDetails.toString(),
    (payload: SagaGenericParams<PrivilegeContainDto>) =>
      getListSaga<PrivilegeContainDto>(payload)
  ),
  takeLatest(
    createPrivilegeContain.toString(),
    (payload: PostSagaGenericParams<PrivilegeContainDto>) =>
      postSaga<PrivilegeContainDto>(payload)
  ),
  takeLatest(
    updatePrivilegeContain.toString(),
    (payload: PostSagaGenericParams<PrivilegeContainDto>) =>
      putSaga<PrivilegeContainDto>(payload)
  ),

  takeLatest(
    deletePrivilegeContain.toString(),
    (payload: SagaGenericParams<PrivilegeContainDto>) =>
      deleteSaga<PrivilegeContainDto>(payload)
  ),
];

export default containsprivilegesSaga;
