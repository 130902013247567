import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import language from "../../store/languages/index";
import CarouselPage from "./CarouselPage";
import images from "src/assets/image";
import { loginAction } from "src/store/auth/saga";
import auth from "src/store/auth";
import { LoginResponseModel } from "src/store/auth/types";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import notificationRedux from "src/store/socketNotification";

interface LoginProps {
  history: object;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();
  const historyPush = useHistory();
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    dispatch(auth.actions.logoutAction());
  }, [dispatch]);
  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    setLoader(true);
    dispatch(
      loginAction({
        payload: {
          onSuccess: (message, payload) => {
            const user: LoginResponseModel = payload.Result;
            dispatch(auth.actions.setUser(user));
            historyPush.push("/dashboard");
            dispatch(notificationRedux.actions.getNotifications());
            dispatch(language.actions.getLanguages());
            setLoader(false);
          },
          onError: message => {
            setError(message);
            setLoader(false);
          },
        },
        body: { UserName: values.email, Password: values.password },
      })
    );
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Login | Servis Plan")}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <CarouselPage
            component={
              <Row className="g-0 bg-light">
                <Col lg={12}>
                  {loader && <Loader />}
                  <div className="auth-full-page-content d-flex p-sm-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="text-center">
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img src={images.logo} alt="" height="140" />{" "}
                          </Link>
                        </div>
                        <div className="auth-content my-auto">
                          {error && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {error}
                            </Alert>
                          )}
                          <AvForm
                            className="custom-form mt-4 pt-2"
                            onValidSubmit={(e: any, v: any) => {
                              handleValidSubmit(e, v);
                            }}
                          >
                            <div className="mb-3">
                              <AvField
                                name="email"
                                label="Email"
                                value={process.env.REACT_APP_DEFAULT_USERNAME}
                                className="form-control"
                                type="email"
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <label className="form-label">
                                    {t("Password")}
                                  </label>
                                </div>
                                <div className="flex-shrink-0">
                                  <div className="">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      {t("Sifreni Unuttun Mu")}
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-3">
                                <AvField
                                  name="password"
                                  value={process.env.REACT_APP_DEFAULT_PASSWORD}
                                  type="password"
                                  className="form-control"
                                  required
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                {t("Login")}
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
