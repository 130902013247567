import DataGrid, {
  Column,
  Editing,
  Lookup,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getMaintenanceCategoryLookup } from "src/store/maintenance/maintenance-categories/saga";
import { MaintenanceCategoryDto } from "src/store/maintenance/maintenance-categories/type";
import { createMaintenanceDraft } from "src/store/maintenance/maintenance-drafts/saga";
import { getServiceMaintenanceCardLookup } from "src/store/maintenance/service-maintenance-cards/saga";
import { ServiceMaintenanceCardDto } from "src/store/maintenance/service-maintenance-cards/type";
import { getVehicleBrandLookup } from "src/store/maintenance/vehicle-brands/saga";
import { VehicleBrandDto } from "src/store/maintenance/vehicle-brands/type";
import { getVehicleModelLookup } from "src/store/maintenance/vehicle-models/saga";
import { VehicleModelDto } from "src/store/maintenance/vehicle-models/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierLookupDto } from "src/store/supplier/type";
import * as Yup from "yup";

const CreateMaintenanceDraft = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [cards, setCards] = useState<ServiceMaintenanceCardDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierLookupDto[]>([]);
  const [brands, setBrands] = useState<VehicleBrandDto[]>([]);
  const [models, setModels] = useState<VehicleModelDto[]>([]);
  const [categories, setCategories] = useState<MaintenanceCategoryDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (msg, py) => setSuppliers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getServiceMaintenanceCardLookup({
          payload: {
            onSuccess: (msg, py) => setCards(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getVehicleBrandLookup({
          payload: {
            onSuccess: (msg, py) => setBrands(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getMaintenanceCategoryLookup({
          payload: {
            onSuccess: (msg, py) => setCategories(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  const dataGridRef = useRef<DataGrid>();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Bakım Taslağı EKle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            createMaintenanceDraft({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: {
                ...values,
                MaintenanceDraftItems:
                  dataGridRef.current?.instance.getDataSource().items() ?? [],
              },
            })
          );
        }}
        initialValues={{
          SupplierId: "",
          VehicleModelId: "",
          VehicleBrandId: "",
          KmLimit: "",
          ValidityDate: "",
          MaintenanceDraftItems: [],
        }}
        validationSchema={Yup.object().shape({
          SupplierId: Yup.string().required(t("Required")),
          VehicleModelId: Yup.string().required(t("Required")),
          VehicleBrandId: Yup.string().required(t("Required")),
          KmLimit: Yup.number().required(t("Required")),
          ValidityDate: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "SupplierId",
            inputType: InputType.multiselect,
            label: t("İş Ortağı"),
            lookup: {
              data: suppliers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("İş Ortağı Seçiniz"),
            },
          },
          {
            field: "VehicleBrandId",
            inputType: InputType.multiselect,
            label: t("Marka"),
            lookup: {
              data: brands,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Marka Seçiniz"),
            },
            onChange(value, item, setFieldValue, values) {
              value &&
                dispatch(
                  getVehicleModelLookup({
                    payload: {
                      onSuccess: (msg, py) => setModels(py),
                      onError: () => {},
                    },
                  })
                );
              value &&
                value !== values.VehicleBrandId &&
                setFieldValue("VehicleModelId", "");
            },
          },
          {
            field: "VehicleModelId",
            inputType: InputType.multiselect,
            label: t("Model"),
            lookup: {
              data: models,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Model Seçiniz"),
            },
          },
          {
            field: "KmLimit",
            label: t("Km Limit"),
          },
          {
            field: "ValidityDate",
            label: t("Geçerlilik Tarihi"),
            inputType: InputType.datetime,
          },
          {
            field: "MaintenanceDraftItems",
            label: t(""),
            inputType: InputType.component,
            component(data, setFieldValue) {
              return (
                <CreateGrid
                  ref={dataGridRef}
                  cards={cards}
                  categories={categories}
                  data={data.MaintenanceDraftItems}
                />
              );
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateMaintenanceDraft;
const CreateGrid = forwardRef<any, any>(({ cards, categories, data }, ref) => {
  return (
    <>
      <div className="pb-1">Bakım Taslak Satırları</div>
      <DataGrid
        dataSource={data ?? []}
        keyExpr="Id"
        showBorders={true}
        allowColumnResizing
        ref={ref}
      >
        <Paging enabled={false} />
        <Editing
          mode="form"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          form={{
            colCount: 1,
          }}
        />
        {/* <Column
          dataField="MaintenanceCategoryId"
          caption="Bakım Kategorisi"
          editorOptions={{}}
        >
          <Lookup dataSource={categories} valueExpr="Id" displayExpr="Name" />
          <RequiredRule />
        </Column> */}
        <Column dataField="ServiceMaintenanceCardId" caption="Bakım Kartı">
          <Lookup dataSource={cards} valueExpr="Id" displayExpr="FullName" />
          <RequiredRule />
        </Column>
        <Column dataField="Quantity" caption="Adet" dataType="number">
          <RequiredRule />
        </Column>
        <Column dataField="TaxRate" caption="KDV" dataType="number">
          <RequiredRule />
        </Column>
        <Column dataField="Price" caption="Fiyat" dataType="number">
          <RequiredRule />
        </Column>
      </DataGrid>
    </>
  );
});
