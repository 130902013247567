import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, postSaga, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as T from "./type";
import { BodyType } from "../base/request/request";

export const getInvoicePrivileges = createAction(
  "GET_INVOICED_PRIVILEGES",
  ({ payload, params }: SagaActionParams<T.InvoicePrivilegeDto[]>) => ({
    payload,
    url: "/api/invoices/getinvoicedprivilegelist" + (params ?? ""),
  })
);
export const getUninvoicePrivileges = createAction(
  "GET_UNINVOICED_PRIVILEGES",
  ({ payload, params }: SagaActionParams<T.InvoicePrivilegeDto[]>) => ({
    payload,
    url: "/api/invoices/getwillbeinvoicedprivilegelist" + (params ?? ""),
  })
);

export const invoicePrivilege = createAction(
  "INVOICE_PRIVILEGE",
  ({ payload, body, id }: SagaActionParams<T.InvoicePrivilegeDto[]>) => ({
    payload,
    body,
    url: "/api/invoices/updateprivilege/" + id,
    lng: "en-US",
  })
);
export const invoicePrivilegeWithAccountment = createAction(
  "INVOICE_PRIVILEGE_WITH_ACCOUNTMENT",
  ({ payload, body }: SagaActionParams<T.InvoicePrivilegeDto[]>) => ({
    payload,
    url: "/api/invoices/bill-privilege-sales",
    body,
    bodyType: BodyType.raw,
  })
);
const invoicePrivilegeSaga = [
  takeLatest(
    getInvoicePrivileges.toString(),
    (payload: SagaGenericParams<T.InvoicePrivilegeDto[]>) =>
      getListSaga<T.InvoicePrivilegeDto[]>(payload)
  ),
  takeLatest(
    getUninvoicePrivileges.toString(),
    (payload: SagaGenericParams<T.InvoicePrivilegeDto[]>) =>
      getListSaga<T.InvoicePrivilegeDto[]>(payload)
  ),
  takeLatest(
    invoicePrivilege.toString(),
    (payload: PostSagaGenericParams<T.InvoicePrivilegeDto[]>) =>
      putSaga<T.InvoicePrivilegeDto[]>(payload)
  ),
  takeLatest(
    invoicePrivilegeWithAccountment.toString(),
    (payload: PostSagaGenericParams<T.InvoicePrivilegeDto[]>) =>
      postSaga<T.InvoicePrivilegeDto[]>(payload)
  ),
];

export default invoicePrivilegeSaga;
