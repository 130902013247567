import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateProductCategory } from "src/store/orders/product-categories/saga";
import { ProductCategoryDto } from "src/store/orders/product-categories/type";

import * as Yup from "yup";

const EditProductCategorys = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: ProductCategoryDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputs = [
    {
      field: "Name",
      label: t("Name"),
    },
    {
      field: "ShortDescription",
      label: t("Açıklama"),
    },
    {
      field: "ImageFile",
      label: t("ImageFile Image"),
      inputType: InputType.fileUpload,
      fileUpload: {
        linkField: "CurrentFile",
        accept: ["image/jpeg", "image/png"],
        multiple: false,
        title: t("Please Upload Image"),
      },
    },
    {
      field: "Active",
      label: t("Active"),
      col: 6,
      checkedLabel: t("Active"),
      uncheckedLabel: t("Pasive"),
      inputType: InputType.toogle,
    },
  ];
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name,
          ShortDescription: data.ShortDescription,
          ParentCategoryId: data.ParentCategoryId,
          ParentName: data.ParentCategories.map(x => x.Name).join(" / "),
          ImageFile: "",
          CurrentFile: data.Image && data.Image != null ? data.Image : "",
          Active: data.Active,
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
        })}
        inputs={
          data.ParentCategoryId
            ? [
                ...inputs,
                {
                  field: "ParentName",
                  label: t("Ana Kategoriler"),
                  disabled(values) {
                    return true;
                  },
                },
              ]
            : inputs
        }
        onSubmit={values => {
          dispatch(
            updateProductCategory({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
      />
    </React.Fragment>
  );
};
export default EditProductCategorys;
