import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerEducationStatusDto } from "./type";

export const getCustomerEducationStatusList = createAction(
  "GET_CUSTOMER_EDUCATIONSTATUS_LIST",
  ({ payload, params }: SagaActionParams<CustomerEducationStatusDto[]>) => ({
    payload,
    url: "/api/customereducationstatuses" + (params ? params : ""),
  })
);
export const getCustomerEducationStatusDetails = createAction(
  "GET_CUSTOMER_EDUCATIONSTATUS_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerEducationStatusDto>) => ({
    payload,
    url: "/api/customereducationstatuses/" + id,
  })
);
export const createCustomerEducationStatus = createAction(
  "CREATE_CUSTOMER_EDUCATIONSTATUS_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerEducationStatusDto>) => ({
    payload,
    url: "/api/customereducationstatuses",
    body,
  })
);
export const updateCustomerEducationStatus = createAction(
  "UPDATE_CUSTOMER_EDUCATIONSTATUS_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerEducationStatusDto>) => ({
    payload,
    url: "/api/customereducationstatuses/" + id,
    body,
  })
);
export const deleteCustomerEducationStatus = createAction(
  "DELETE_CUSTOMER_EDUCATIONSTATUS_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerEducationStatusDto>) => ({
    payload,
    url: "/api/customereducationstatuses/" + id,
    body,
  })
);
const customereducationstatussaga = [
  takeLatest(
    getCustomerEducationStatusList.toString(),
    (payload: SagaGenericParams<CustomerEducationStatusDto[]>) =>
      getListSaga<CustomerEducationStatusDto[]>(payload)
  ),
  takeLatest(
    getCustomerEducationStatusDetails.toString(),
    (payload: SagaGenericParams<CustomerEducationStatusDto>) =>
      getListSaga<CustomerEducationStatusDto>(payload)
  ),
  takeLatest(
    createCustomerEducationStatus.toString(),
    (payload: PostSagaGenericParams<CustomerEducationStatusDto>) =>
      postSaga<CustomerEducationStatusDto>(payload)
  ),
  takeLatest(
    updateCustomerEducationStatus.toString(),
    (payload: PostSagaGenericParams<CustomerEducationStatusDto>) =>
      putSaga<CustomerEducationStatusDto>(payload)
  ),
  takeLatest(
    deleteCustomerEducationStatus.toString(),
    (payload: SagaGenericParams<CustomerEducationStatusDto>) =>
      deleteSaga<CustomerEducationStatusDto>(payload)
  ),
];

export default customereducationstatussaga;
