import { TextBox } from "devextreme-react";
import { Card, Col, Row } from "reactstrap";
import { getLocalDate } from "src/helpers/getLocalDate";
import { MaintenanceDraftDetailDto } from "src/store/maintenance/maintenance-drafts/type";

const MaintenanceDraftDetailCard = ({
  data,
}: {
  data: MaintenanceDraftDetailDto;
}) => {
  const dataFields = [
    {
      label: "İş Ortağı",
      value: data.SupplierName,
    },
    {
      label: "Geçerlilik Tarihi",
      value: getLocalDate(data.ValidityDate),
    },
    {
      label: "Marka",
      value: data.VehicleModelVehicleBrandName,
    },
    {
      label: "Model",
      value: data.VehicleModelName,
    },
    {
      label: "Km Limit",
      value: data.KmLimit,
    },
    {
      label: "Toplam Fiyat",
      value: data.TotalPrice,
      col: 6,
    },
  ];

  return (
    <Card className="border-0 mt-5">
      <Row>
        {dataFields.map(x => (
          <Col key={x.label} md={x.col ?? 6} className="mb-3">
            <TextBox
              label={x.label}
              value={x.value.toString() ?? "-"}
              readOnly
              stylingMode="filled"
              style={{ borderWidth: 0 }}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};
export default MaintenanceDraftDetailCard;
