import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegePriceDto } from "./type";

export const getPrivilegePriceListByPrivilegeId = createAction(
  "GET_PRIVILEGEPRICES_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegePriceDto[]>) => ({
    payload,
    url: "/api/privilegeprices/getbyprivilegeid/" + id + (params ? params : ""),
  })
);

export const getPrivilegePriceList = createAction(
  "GET_PRIVILEGEPRICES_LIST",
  ({ payload, params }: SagaActionParams<PrivilegePriceDto[]>) => ({
    payload,
    url: "/api/privilegeprices" + (params ? params : ""),
  })
);

export const getPrivilegePriceDetails = createAction(
  "GET_PRIVILEGEPRICES_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegePriceDto>) => ({
    payload,
    url: "/api/privilegeprices/" + id,
  })
);
export const createPrivilegePrice = createAction(
  "CREATE_PRIVILEGEPRICES_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegePriceDto>) => ({
    payload,
    url: "/api/privilegeprices",
    body,
    lng: "en-US",
  })
);
export const updatePrivilegePrice = createAction(
  "UPDATE_PRIVILEGEPRICES_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegePriceDto>) => ({
    payload,
    url: "/api/privilegeprices/" + id,
    body,
    lng: "en-US",
  })
);
export const deletePrivilegePrice = createAction(
  "DELETE_PRIVILEGEPRICES_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegePriceDto>) => ({
    payload,
    url: "/api/privilegeprices/" + id,
    body,
  })
);
const privilegepricesSaga = [
  takeLatest(
    getPrivilegePriceListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegePriceDto[]>) =>
      getListSaga<PrivilegePriceDto[]>(payload)
  ),
  takeLatest(
    getPrivilegePriceList.toString(),
    (payload: SagaGenericParams<PrivilegePriceDto[]>) =>
      getListSaga<PrivilegePriceDto[]>(payload)
  ),
  takeLatest(
    getPrivilegePriceDetails.toString(),
    (payload: SagaGenericParams<PrivilegePriceDto>) =>
      getListSaga<PrivilegePriceDto>(payload)
  ),
  takeLatest(
    createPrivilegePrice.toString(),
    (payload: PostSagaGenericParams<PrivilegePriceDto>) =>
      postSaga<PrivilegePriceDto>(payload)
  ),
  takeLatest(
    updatePrivilegePrice.toString(),
    (payload: PostSagaGenericParams<PrivilegePriceDto>) =>
      putSaga<PrivilegePriceDto>(payload)
  ),

  takeLatest(
    deletePrivilegePrice.toString(),
    (payload: SagaGenericParams<PrivilegePriceDto>) =>
      deleteSaga<PrivilegePriceDto>(payload)
  ),
];

export default privilegepricesSaga;
