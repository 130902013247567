import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CorporateDepartmentDto } from "./type";
export const getCorporateDepartments = createAction(
  "GET_CORPORATE_DEPARTMENTS",
  ({ payload, params }: SagaActionParams<CorporateDepartmentDto[]>) => ({
    payload,
    url: "/api/CorporateDepartments" + (params ? params : ""),
  })
);
export const getCorporateDepartmentLookup = createAction(
  "GET_CORPORATE_DEPARTMAN_LOOKUP",
  ({ payload, id }: SagaActionParams<CorporateDepartmentDto[]>) => ({
    payload,
    url: "/api/CorporateDepartments/lookup/" + id,
  })
);
export const getCorporateDepartmentsDetails = createAction(
  "GET_CORPORATE_DEPARTMENTS_DETAILS",
  ({ payload, id }: SagaActionParams<CorporateDepartmentDto>) => ({
    payload,
    url: "/api/CorporateDepartments/" + id,
  })
);
export const createCorporateDepartments = createAction(
  "CREATE_CORPORATE_DEPARTMENTS",
  ({ payload, body }: SagaActionParams<CorporateDepartmentDto>) => ({
    payload,
    url: "/api/CorporateDepartments",
    body,
  })
);
export const updateCorporateDepartments = createAction(
  "UPDATE_CORPORATE_DEPARTMENTS",
  ({ payload, id, body }: SagaActionParams<CorporateDepartmentDto>) => ({
    payload,
    url: "/api/CorporateDepartments/" + id,
    body,
  })
);
export const deleteCorporateDepartments = createAction(
  "DELETE_CORPORATE_DEPARTMENTS",
  ({ payload, id }: SagaActionParams<CorporateDepartmentDto>) => ({
    payload,
    url: "/api/CorporateDepartments/" + id,
  })
);
const CorporateDepartmentsSaga = [
  takeLatest(
    getCorporateDepartments.toString(),
    (payload: SagaGenericParams<CorporateDepartmentDto[]>) =>
      getListSaga<CorporateDepartmentDto[]>(payload)
  ),
  takeLatest(
    getCorporateDepartmentLookup.toString(),
    (payload: SagaGenericParams<CorporateDepartmentDto[]>) =>
      getListSaga<CorporateDepartmentDto[]>(payload)
  ),
  takeLatest(
    getCorporateDepartmentsDetails.toString(),
    (payload: SagaGenericParams<CorporateDepartmentDto>) =>
      getListSaga<CorporateDepartmentDto>(payload)
  ),
  takeLatest(
    createCorporateDepartments.toString(),
    (payload: PostSagaGenericParams<CorporateDepartmentDto>) =>
      postSaga<CorporateDepartmentDto>(payload)
  ),
  takeLatest(
    updateCorporateDepartments.toString(),
    (payload: PostSagaGenericParams<CorporateDepartmentDto>) =>
      putSaga<CorporateDepartmentDto>(payload)
  ),
  takeLatest(
    deleteCorporateDepartments.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CorporateDepartmentDto>(payload)
  ),
];

export default CorporateDepartmentsSaga;
