import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import Loader from "src/components/Loader";
import CustomModal from "src/components/Modal/modal";
import * as Yup from "yup";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import { UserDto } from "src/store/user/type";
import { getCustomerCallLogsTicketUsers } from "src/store/customer-calllog/saga";
import { createCalllogNote } from "src/store/ticket/saga";
import { TicketStatusDto } from "src/store/ticket/type";

const CreateNoteToCalllog = ({
  selectedUserId,
  refreshList,
  selectedId,
  status,
  currentStatus,
}: {
  currentStatus: number;
  selectedUserId?: string;
  refreshList: () => void;
  selectedId: string;
  status: TicketStatusDto[];
}) => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserDto[]>([]);
  useEffect(() => {
    if (show) {
      setLoader(true);
      dispatch(
        getCustomerCallLogsTicketUsers({
          payload: {
            onSuccess: (msg, py) => {
              setUsers(py);
              setLoader(false);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [show, dispatch]);
  return (
    <React.Fragment>
      <Button
        type="button"
        color="success"
        className="btn-sm waves-light waves-effect"
        onClick={() => {
          setShow(true);
        }}
      >
        Not Ekle
      </Button>
      <CustomModal
        open={show}
        size="xs"
        onHide={() => setShow(false)}
        title={"Talep Sorumlu Kişi Güncelle"}
      >
        {loader && <Loader />}
        {!loader && (
          <Formik
            initialValues={{
              TicketId: selectedId,
              CurrentStatus: currentStatus,
              ActionStatus: "",
              Description: "",
              ResponsibleUserId: selectedUserId ? selectedUserId : "",
            }}
            validationSchema={Yup.object().shape({
              Description: Yup.string().required(t("Required")),
              ResponsibleUserId: Yup.string().required(t("Required")),
              CurrentStatus: Yup.string().required(t("Required")),
              ActionStatus: Yup.string().required(t("Required")),
            })}
            onSubmit={values => {
              dispatch(
                createCalllogNote({
                  payload: {
                    onSuccess: message => {
                      setShow(false);
                      toast.success(message);
                      refreshList();
                    },
                    onError: message => {
                      setShow(false);
                      toast.error(message, {
                        theme: "colored",
                      });
                    },
                  },
                  body: values,
                })
              );
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Row className="">
                <Col md={12}>
                  <GetInput
                    inputprop={{
                      field: "CurrentStatus",
                      inputType: InputType.multiselect,
                      label: t("Mevcut Durum"),
                      lookup: {
                        data: status,
                        labelKey: "Name",
                        valueKey: "EnumId",
                        placeholder: t("Mevcut Durum"),
                      },
                    }}
                  />
                </Col>
                <Col md={12}>
                  <GetInput
                    inputprop={{
                      field: "ActionStatus",
                      inputType: InputType.multiselect,
                      label: t("Aksiyon"),
                      lookup: {
                        data: status,
                        labelKey: "Name",
                        valueKey: "EnumId",
                        placeholder: t("Mevcut Durum"),
                      },
                    }}
                  />
                </Col>
                <Col md={12}>
                  <GetInput
                    inputprop={{
                      field: "ResponsibleUserId",
                      inputType: InputType.multiselect,
                      label: t("Sorumlu Kişi"),
                      lookup: {
                        data: users,
                        labelKey: "FullName",
                        valueKey: "Id",
                        placeholder: t("Sorumlu Kişi Seçiniz"),
                      },
                    }}
                  />
                </Col>

                <Col md={12}>
                  <GetInput
                    inputprop={{
                      field: "Description",
                      inputType: InputType.multilinetext,
                      label: t("Note"),
                    }}
                  />
                </Col>
                <div className={"d-flex justify-content-between mt-3 gap-2"}>
                  <Button
                    color="danger"
                    type="submit"
                    style={{ width: 200 }}
                    onClick={() => setShow(false)}
                  >
                    {t("Kapat")}
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    style={{ width: 200 }}
                    onClick={() => handleSubmit()}
                  >
                    {t("Kaydet")}
                  </Button>
                </div>
              </Row>
            )}
          </Formik>
        )}
      </CustomModal>
    </React.Fragment>
  );
};
export default CreateNoteToCalllog;
