import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import * as Yup from "yup";
import {
  sendSmsToAllCustomer,
  sendSmsToCustomer,
} from "src/store/customers/saga";
import { PrivilegeFilterDto } from "./privilegeFilter";

const SendSms = ({
  show,
  onHide,
  customers,
  onSuccessSubmit,
  isAll,
  filterData,
}: {
  filterData?: PrivilegeFilterDto;
  isAll: "select" | "active" | "all";
  show: boolean;
  onHide: () => void;
  customers: string[];
  onSuccessSubmit?: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <CustomModal onHide={() => onHide()} open={show} title={t("SMS Gönder")}>
        <Formik
          onSubmit={values => {
            if (isAll === "select") {
              dispatch(
                sendSmsToCustomer({
                  payload: {
                    onSuccess: (ms, payload) => {
                      toast.success("Sms gönderimi başarılı");
                      onSuccessSubmit && onSuccessSubmit();
                      onHide();
                    },
                    onError: () => {
                      toast.error("Hata oluştu");
                    },
                  },
                  body: values,
                })
              );
            } else {
              dispatch(
                sendSmsToAllCustomer({
                  payload: {
                    onSuccess: (ms, payload) => {
                      toast.success("Sms gönderimi başarılı");
                      onSuccessSubmit && onSuccessSubmit();
                      onHide();
                    },
                    onError: () => {
                      toast.error("Hata oluştu");
                    },
                  },
                  body: values,
                })
              );
            }
          }}
          initialValues={{
            customerIds: customers,
            message: "",
            privilegeId: filterData ? filterData.PrivilegeId : "",
            privilegeFilterTypeId: filterData
              ? filterData.PrivilegeFilterTypeId
              : "",
            active: isAll === "active" ? true : false,
          }}
          validationSchema={Yup.object().shape({
            message: Yup.string().required(t("Required")),
          })}
        >
          {({ values, handleSubmit }) => (
            <Row className="mx-3">
              <GetInput
                inputprop={{
                  field: "message",
                  label: t("Message"),
                  inputType: InputType.multilinetext,
                }}
              />

              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("Send")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};

export default SendSms;
