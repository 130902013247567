import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import * as T from "./type";
import { getListSaga } from "src/store/base/request/get";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { BodyType } from "src/store/base/request/request";
import { PostSagaGenericParams, putSaga } from "src/store/base/request/post";

export const getOrderList = createAction(
  "GET_ORDER_LIST",
  ({ payload, params }: SagaActionParams<T.OrderDto[]>) => ({
    payload,
    url: "/api/supplierorders/orders" + (params ? params : ""),
  })
);
export const getOrderDetail = createAction(
  "GET_ORDER_DETAILS",
  ({ payload, params, id }: SagaActionParams<T.OrderDetailDto>) => ({
    payload,
    url: "/api/orders/" + id + (params ? params : ""),
  })
);
export const getOrderStatuses = createAction(
  "GET_ORDER_STATUS",
  ({ payload }: SagaActionParams<T.OrderStatusDto[]>) => ({
    payload,
    url: "/api/system/order-status",
  })
);
export const getOrderItemStatuses = createAction(
  "GET_ORDER_ITEM_STATUS",
  ({ payload }: SagaActionParams<T.OrderStatusDto[]>) => ({
    payload,
    url: "/api/system/order-item-status",
  })
);

export const updateOrderStatus = createAction(
  "UPDATE_ORDER_STATUS",
  ({ payload, body }: SagaActionParams<T.OrderDetailDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/orders/status",
  })
);
const orderSaga = [
  takeLatest(
    getOrderList.toString(),
    (payload: SagaGenericParams<T.OrderDto[]>) =>
      getListSaga<T.OrderDto[]>(payload)
  ),
  takeLatest(
    getOrderDetail.toString(),
    (payload: SagaGenericParams<T.OrderDto[]>) =>
      getListSaga<T.OrderDto[]>(payload)
  ),
  takeLatest(
    getOrderItemStatuses.toString(),
    (payload: SagaGenericParams<T.OrderStatusDto[]>) =>
      getListSaga<T.OrderStatusDto[]>(payload)
  ),
  takeLatest(
    getOrderStatuses.toString(),
    (payload: SagaGenericParams<T.OrderStatusDto[]>) =>
      getListSaga<T.OrderStatusDto[]>(payload)
  ),
  takeLatest(
    updateOrderStatus.toString(),
    (payload: PostSagaGenericParams<T.OrderStatusDto[]>) =>
      putSaga<T.OrderStatusDto[]>(payload)
  ),
];

export default orderSaga;
