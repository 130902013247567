import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SliderDto } from "src/store/slider/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreateSlider from "./create";
import { toast } from "react-toastify";
import EditSlider from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  changeSliderOrder,
  deleteSlider,
  getSliderDetails,
  getSliderList,
} from "src/store/slider/saga";
import { Avatar } from "rsuite";
import { baseImageUrl } from "src/store/base/types/url";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";

const SliderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<SliderDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<SliderDto[]>([]);
  const [showDelete, setShowDelete] = useState<SliderDto>();
  useEffect(() => {
    dispatch(
      getSliderList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  const columns = [
    {
      text: t("Order"),
      dataField: "Order",
    },
    {
      text: t(""),
      dataField: "Image",
      formatter: (cellContent: any) => (
        <Avatar src={baseImageUrl + cellContent} />
      ),
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Üyeye Özel"),
      dataField: "ForCustomer",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {/* <ColumnButton
            buttonType="password"
            onClick={() => {
              setShowPassword(data);
            }}
          /> */}
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getSliderDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getSliderList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditSlider
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateSlider
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />

      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changeSliderOrder}
        title={t("SliderTitle")}
        create={{
          createType: "drawer",
          onCreateClick: () => setShowCreate(true),
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete slider`, {
            name: `${showDelete.Title} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteSlider({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SliderPage;
