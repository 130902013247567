import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CustomerVehicleDto } from "src/store/customervehicles/type";

const VehicleCard = ({ vehicle }: { vehicle: CustomerVehicleDto }) => {
  // const getIcon = (condition: boolean) => {
  //   return condition ? (
  //     <i className="alert-success icon-container text-success bx bx-check" />
  //   ) : (
  //     <i className="alert-danger icon-container text-danger  bx bx-x      " />
  //   );
  // };

  const { t } = useTranslation();
  const table = [
    {
      label: t("Plate"),
      value: vehicle.Plate,
    },

    {
      label: t("Brand"),
      value: vehicle.make,
    },
    {
      label: t("Model"),
      value: vehicle.model,
    },
    {
      label: t("Registration Date"),
      value: vehicle.RegistrationDate
        ? moment(vehicle.RegistrationDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      label: t("Color"),
      value: vehicle.VehicleColorName,
    },
    {
      label: t("Body"),
      value: vehicle.body,
    },
    {
      label: t("Chassis Number"),
      value: vehicle.ChassisNumber,
    },
    {
      label: t("Body Type"),
      value: vehicle.BodyTypeName,
    },
    {
      label: t("Fuel Type"),
      value: vehicle.FuelTypeName,
    },
    {
      label: t("Gear Type"),
      value: vehicle.GearTypeName,
    },
    {
      label: t("Engine"),
      value: vehicle.engine,
    },
    {
      label: t("Engine Number"),
      value: vehicle.EngineNumber,
    },

    {
      label: t("Kategori"),
      value: vehicle.category,
    },
    {
      label: t("make"),
      value: vehicle.make,
    },
    {
      label: t("model"),
      value: vehicle.model,
    },
    {
      label: t("regdate"),
      value: vehicle.regdate,
    },
    {
      label: t("facelift"),
      value: vehicle.facelift,
    },
    {
      label: t("seats"),
      value: vehicle.seats,
    },
    {
      label: t("engine"),
      value: vehicle.engine,
    },
    {
      label: t("transmission"),
      value: vehicle.transmission,
    },
    {
      label: t("wheeldrive"),
      value: vehicle.wheeldrive,
    },
    {
      label: t("trim"),
      value: vehicle.trim,
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Mevcut Araç Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default VehicleCard;
