import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { DataBankCategoryDto } from "./type";
import { BodyType } from "../base/request/request";

export const getDataBankCategoryList = createAction(
  "GET_DATABANK_CATEGORY_LIST",
  ({ payload, params }: SagaActionParams<DataBankCategoryDto[]>) => ({
    payload,
    url: "/api/databankcategories" + (params ? params : ""),
  })
);

export const getDataBankCategoryDetails = createAction(
  "GET_DATABANK_CATEGORY_DETAILS",
  ({ payload, id }: SagaActionParams<DataBankCategoryDto>) => ({
    payload,
    url: "/api/databankcategories/" + id,
  })
);
export const createDataBankCategorySaga = createAction(
  "CREATE_DATABANK_CATEGORY_DETAILS",
  ({ payload, body }: SagaActionParams<DataBankCategoryDto>) => ({
    payload,
    url: "/api/databankcategories",
    body,
  })
);
export const updateDataBankCategory = createAction(
  "UPDATE_DATABANK_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<DataBankCategoryDto>) => ({
    payload,
    url: "/api/databankcategories/" + id,
    body,
  })
);
export const deleteDataBankCategory = createAction(
  "DELETE_DATABANK_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<DataBankCategoryDto>) => ({
    payload,
    url: "/api/databankcategories/" + id,
    body,
  })
);

export const changeDataBankCategoryOrder = createAction(
  "UPDATE_DATABANK_CATEGORY_ORDER",
  ({ payload, body }: SagaActionParams<DataBankCategoryDto>) => ({
    payload,
    url: "/api/databankcategories/order",
    body,
    bodyType: BodyType.raw,
  })
);
const databankcategorySaga = [
  takeLatest(
    getDataBankCategoryList.toString(),
    (payload: SagaGenericParams<DataBankCategoryDto[]>) =>
      getListSaga<DataBankCategoryDto[]>(payload)
  ),
  takeLatest(
    getDataBankCategoryDetails.toString(),
    (payload: SagaGenericParams<DataBankCategoryDto>) =>
      getListSaga<DataBankCategoryDto>(payload)
  ),
  takeLatest(
    createDataBankCategorySaga.toString(),
    (payload: PostSagaGenericParams<DataBankCategoryDto>) =>
      postSaga<DataBankCategoryDto>(payload)
  ),
  takeLatest(
    changeDataBankCategoryOrder.toString(),
    (payload: PostSagaGenericParams<DataBankCategoryDto>) =>
      putSaga<DataBankCategoryDto>(payload)
  ),
  takeLatest(
    updateDataBankCategory.toString(),
    (payload: PostSagaGenericParams<DataBankCategoryDto>) =>
      putSaga<DataBankCategoryDto>(payload)
  ),
  takeLatest(
    deleteDataBankCategory.toString(),
    (payload: SagaGenericParams<DataBankCategoryDto>) =>
      deleteSaga<DataBankCategoryDto>(payload)
  ),
];

export default databankcategorySaga;
