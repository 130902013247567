import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ProductCompatibleBrandDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getProductCompatibleBrandListByProductId = createAction(
  "GET_PRODUCT_COMPATIBLE_BRAND_LIST_BY_PRODUCT_ID",
  ({ payload, params,id }: SagaActionParams<ProductCompatibleBrandDto[]>) => ({
    payload,
    url: "/api/productcompatiblebrands/byproductid/"+id + (params ? params : ""),
  })
);
export const getProductCompatibleBrandDetails = createAction(
  "GET_PRODUCT_COMPATIBLE_BRAND_DETAILS",
  ({ payload, id }: SagaActionParams<ProductCompatibleBrandDto>) => ({
    payload,
    url: "/api/productcompatiblebrands/" + id,
  })
);
export const createProductCompatibleBrand = createAction(
  "CREATE_PRODUCT_COMPATIBLE_BRAND_DETAILS",
  ({ payload, body }: SagaActionParams<ProductCompatibleBrandDto>) => ({
    payload,
    url: "/api/productcompatiblebrands",
    body,
  })
);
export const updateProductCompatibleBrand = createAction(
  "UPDATE_PRODUCT_COMPATIBLE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCompatibleBrandDto>) => ({
    payload,
    url: "/api/productcompatiblebrands/" + id,
    body,
  })
);
export const deleteProductCompatibleBrand = createAction(
  "DELETE_PRODUCT_COMPATIBLE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCompatibleBrandDto>) => ({
    payload,
    url: "/api/productcompatiblebrands/" + id,
    body,
  })
);
const productCompatibleBrandSaga = [
  takeLatest(
    getProductCompatibleBrandListByProductId.toString(),
    (payload: SagaGenericParams<ProductCompatibleBrandDto[]>) =>
      getListSaga<ProductCompatibleBrandDto[]>(payload)
  ),
  takeLatest(
    getProductCompatibleBrandDetails.toString(),
    (payload: SagaGenericParams<ProductCompatibleBrandDto>) =>
      getListSaga<ProductCompatibleBrandDto>(payload)
  ),
  takeLatest(
    createProductCompatibleBrand.toString(),
    (payload: PostSagaGenericParams<ProductCompatibleBrandDto>) =>
      postSaga<ProductCompatibleBrandDto>(payload)
  ),
  takeLatest(
    updateProductCompatibleBrand.toString(),
    (payload: PostSagaGenericParams<ProductCompatibleBrandDto>) =>
      putSaga<ProductCompatibleBrandDto>(payload)
  ),
  takeLatest(
    deleteProductCompatibleBrand.toString(),
    (payload: SagaGenericParams<ProductCompatibleBrandDto>) =>
      deleteSaga<ProductCompatibleBrandDto>(payload)
  ),
];

export default productCompatibleBrandSaga;
