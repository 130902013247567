import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { getChartTransactions } from "src/store/dashboard/saga";
import {
  DashboardTransactionDto,
  TransactionDto,
} from "src/store/dashboard/type";
import PeriodComp from "./periods";
import classnames from "classnames";
import LineApexChart from "./chart";
import ScrollBar from "react-perfect-scrollbar";
import { getCurrency } from "src/helpers/getCurrency";
import moment from "moment";

const TransactionComponent = () => {
  const dispatch = useDispatch();
  const [currentPeriod, setCurrentPeriod] = useState(2);
  const [data, setData] = React.useState<DashboardTransactionDto>();
  const [active, setActive] = useState(1);

  useEffect(() => {
    dispatch(
      getChartTransactions({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
          },
          onError: () => {},
        },
        params: `?periodId=${currentPeriod}`,
      })
    );
  }, [dispatch, currentPeriod]);

  return (
    <React.Fragment>
      {data ? (
        <>
          <React.Fragment>
            <Card>
              <CardBody>
                <div className="d-flex flex-wrap align-items-center mb-4">
                  <h5 className="card-title me-2">Ödeme Hareketleri</h5>
                  <div className="ms-auto">
                    <PeriodComp
                      currentPeriod={currentPeriod}
                      setCurrentPeriod={setCurrentPeriod}
                    />
                  </div>
                </div>

                <Row className="align-items-center">
                  <Col xl={6}>
                    <div>
                      <div id="market-overview" className="apex-charts">
                        <LineApexChart data={data} />
                      </div>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <Card style={{ height: "450px" }}>
                      <div className="card-header ">
                        <NavMenu active={active} setActive={setActive} />
                      </div>

                      <CardBody className="p-0">
                        <ScrollBar style={{ height: "390px" }}>
                          <table className="table align-middle table-nowraptable table-hover table-bordered responsiveTable">
                            <tbody>
                              {active === 1
                                ? data.SuccessTransactions.map(x => (
                                    <TransactionCard
                                      key={x.Id}
                                      data={x}
                                      active={active}
                                    />
                                  ))
                                : data.ErrorTransactions.map(x => (
                                    <TransactionCard
                                      key={x.Id}
                                      data={x}
                                      active={active}
                                    />
                                  ))}
                            </tbody>
                          </table>
                        </ScrollBar>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        </>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};
export default TransactionComponent;

const NavMenu = ({
  setActive,
  active,
}: {
  setActive: (active: number) => void;
  active: number;
}) => {
  return (
    <Nav
      className="nav-pills py-2 nav-justified rounded card-header-tabs"
      role="tablist"
    >
      <NavItem>
        <NavLink
          to="#"
          className={classnames({
            "text-info": active === 1,
          })}
          onClick={() => setActive(1)}
        >
          Başarılı
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          to="#"
          className={classnames({
            "text-info": active === 2,
          })}
          onClick={() => setActive(2)}
        >
          Hatalı
        </NavLink>
      </NavItem>
    </Nav>
  );
};
const TransactionCard = ({
  data,
  active,
}: {
  data: TransactionDto;
  active: number;
}) => {
  return (
    <tr className="">
      <td>{GetType(data)}</td>
      <td>
        <div>{data.CustomerName}</div>
      </td>
      <td>
        <div>{moment(data.CreateTime).format("DD/MM/yyyy HH:mm:ss")}</div>
      </td>
      <td>
        <div>{getCurrency(data.Amount)}</div>
      </td>
      {data.Error && (
        <td>
          <div>{data.ErrorMessage}</div>
        </td>
      )}
    </tr>
  );
};

function GetType(data: TransactionDto) {
  let type = "";
  let value = "";
  let color = "";
  if (data.PackageId) {
    type = "Paket";
    value = data.PackageName;
    color = "bg-success badge";
  } else if (data.PrivilegePriceId) {
    type = "Hizmet";
    value = data.PrivilegePriceName;
    color = "bg-info badge";
  } else if (data.MembershipCancellationRequestId) {
    type = "Üyelik İptal";
    value = "Üyelik İptal";
    color = "bg-warning badge";
  } else if (data.TireJson) {
    type = "Lastik";
    value = "-";
    color = "bg-secondary badge";
  } else if (data.OrderJson) {
    type = "Sipariş";
    value = "-";
    color = "bg-secondary badge";
  }
  return (
    <div>
      <div className={color}>{type}</div>
      <div className="text-muted font-size-12">{value}</div>
    </div>
  );
}
