import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  getCouponGroups,
  getCouponIncludedPackageList,
  updateCoupon,
} from "src/store/coupons/saga";
import {
  CouponDto,
  CouponGroupDto,
  CouponIncludedPackageDto,
} from "src/store/coupons/type";
import {
  getCouponValidityTypes,
  getDiscountTypeLookupList,
} from "src/store/lookup/saga";
import { DiscountTypeDto, EnumType } from "src/store/lookup/type";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const EditCoupon = ({
  onHide,
  open,
  data,
  onSuccessClick,
}: {
  data: CouponDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const [includedPackages, setIncludedPackages] = useState<
    CouponIncludedPackageDto[]
  >([]);
  const [groups, setGroups] = useState<CouponGroupDto[]>([]);
  const [types, setTypes] = useState<EnumType[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCouponGroups({
          payload: {
            onSuccess: (msg, payload) => setGroups(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getDiscountTypeLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCouponValidityTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
      setLoader(true);
      dispatch(
        getCouponIncludedPackageList({
          payload: {
            onSuccess: (msg, payload) => {
              setIncludedPackages(payload);
              setLoader(false);
            },
            onError: () => {},
          },
          id: data.Id,
        })
      );
      dispatch(
        getDiscountTypeLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (msg, payload) =>
              setPackages(payload.filter(x => !x.System)),
            onError: () => {},
          },
        })
      );
    } else {
      setIncludedPackages([]);
    }
  }, [dispatch, open, data.Id]);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {!loader && (
        <FormModal
          modalProps={{
            open: open,
            onHide: onHide,
            title: t("New Coupon"),
          }}
          onHide={onHide}
          loader
          initialValues={{
            Id: data.Id,
            Name: data.Name,
            Description: data.Description ?? "",
            Code: data.Code ?? "",
            StartDate: data.StartDate ?? "",
            EndDate: data.EndDate ?? "",
            MinAmount: data.MinAmount ?? "",
            MaxAmount: data.MinAmount ?? "",
            Package: data.Package,
            PackageDiscountRate: data.PackageDiscountRate ?? "",
            Privilege: data.Privilege,
            PrivilegeDiscountRate: data.PrivilegeDiscountRate ?? "",
            Limited: data.Limited,
            LimitCount: data.LimitCount ?? "",
            Active: data.Active,
            DiscountTypeId: data.DiscountTypeId ?? "",
            IncludedGift: data.IncludedGift,
            CouponValidityTypeId: data.CouponValidityTypeId ?? "",
            CouponValidityDuration: data.CouponValidityDuration ?? "",
            CouponIncludedPackages: includedPackages.map(x => x.PackageId),
            CouponGroupId: data.CouponGroupId ?? "",
          }}
          onSubmit={values => {
            dispatch(
              updateCoupon({
                payload: {
                  onSuccess: (message, payload) => {
                    onHide();
                    onSuccessClick(message);
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  ...values,
                  CouponIncludedPackages: values.CouponIncludedPackages.map(
                    (x: string) => {
                      return { PackageId: x };
                    }
                  ),
                },
                id: data.Id,
              })
            );
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required(t("Required")),
            Description: Yup.string().required(t("Required")),
            Code: Yup.string().required(t("Required")),
          })}
          inputs={[
            {
              field: "CouponGroupId",
              inputType: InputType.multiselect,
              label: t("Grup"),
              lookup: {
                data: groups,
                labelKey: "Name",
                valueKey: "Id",
              },
            },
            {
              field: "Name",
              label: t("Name"),
              col: 6,
            },
            {
              field: "Code",
              label: t("Code"),
              col: 6,
            },
            {
              field: "Description",
              label: t("Description"),
              inputType: InputType.multilinetext,
            },
            {
              field: "StartDate",
              label: t("Start Date"),
              inputType: InputType.datetime,
            },
            {
              field: "EndDate",
              label: t("End Date"),

              inputType: InputType.datetime,
            },
            {
              field: "MinAmount",
              label: t("Min Amount"),
              inputType: InputType.number,
              col: 6,
            },
            {
              field: "MaxAmount",
              label: t("Max Amount"),
              col: 6,
              inputType: InputType.number,
            },
            {
              field: "DiscountTypeId",
              inputType: InputType.multiselect,
              label: t("Discount Type"),
              lookup: {
                data: discountTypes,
                labelKey: "Name",
                valueKey: "EnumId",
                placeholder: t("Choose Discount Type"),
              },
            },
            {
              field: "Package",
              label: t("Package"),
              col: 6,
              inputType: InputType.checkbox,
            },
            {
              field: "PackageDiscountRate",
              label: t("Package Discount Rate"),
              col: 6,
              inputType: InputType.number,
            },
            {
              field: "Privilege",
              label: t("Privilege"),
              col: 6,
              inputType: InputType.checkbox,
            },
            {
              field: "PrivilegeDiscountRate",
              label: t("Privilege Discount Rate"),
              col: 6,
              inputType: InputType.number,
            },
            {
              hide: values => !values.Package,
              field: "CouponIncludedPackages",
              inputType: InputType.checkpicker,
              label: t("Dahili Paketler"),
              lookup: {
                data: packages,
                labelKey: "PackageName",
                valueKey: "Id",
                placeholder: "Paket Seçiniz",
              },
            },
            {
              field: "CouponValidityTypeId",
              inputType: InputType.multiselect,
              label: t("Aylık/Yıllık Seçimi"),
              lookup: {
                data: types,
                labelKey: "Name",
                valueKey: "EnumId",
                placeholder: t("Choose Discount Type"),
              },
            },
            {
              field: "CouponValidityDuration",
              label: t("Kupon Kaç Ay Geçerli?"),
              col: 12,
              inputType: InputType.number,
            },
            {
              field: "Limited",
              label: t("Limited"),
              col: 6,
              inputType: InputType.checkbox,
            },
            {
              field: "LimitCount",
              label: t("Limit Count"),
              col: 6,
              inputType: InputType.number,
            },
            {
              field: "IncludedGift",
              label: t("Hediyeleri Dahil Et"),
              inputType: InputType.checkbox,
            },
            {
              field: "Active",
              label: t("Active"),
              col: 6,
              checkedLabel: t("Active"),
              uncheckedLabel: t("Pasive"),
              inputType: InputType.toogle,
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};
export default EditCoupon;
