import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SendNotification from "./sendNotificationModal";
import { toast } from "react-toastify";
import { CheckRole } from "src/permissions/checkPermission";
import { useSelector } from "react-redux";
import { userSelector } from "src/store/auth";
import { Container } from "reactstrap";
import DataGrid, { Item } from "devextreme-react/data-grid";
import MetaTags from "react-meta-tags";
import SendEmailModal from "./sendEmailModal";
import CreateCustomer from "./create";
import { Button, DropDownButton } from "devextreme-react";
import PrivilegeFilterComp, { PrivilegeFilterDto } from "./privilegeFilter";
import SendSms from "./sendSms";
import "./style.scss";
import CreateCustomerPrivilegeForMultiple from "./createCampaign";
import { CustomerStatusContainer } from "./Details/detailCard";
import DevextGrid from "src/components/PageContent/DevextGrid";
import ImportCustomerExcel from "./import";
import SendPrivilegeConsumer from "./sendPrivilegeConsumer";
import OpenCallLogModal from "../Email/calllog/openCalllog";
import CreateInvalidCallLog from "../Email/calllog/createInvalidCallLog";
const renderTitleHeader = (data: any) => {
  return (
    <div style={{ height: 40, whiteSpace: "pre-wrap", textAlign: "center" }}>
      {data.column.caption}
    </div>
  );
};
interface ModalVisible {
  customers: string[];
  show: "select" | "active" | "all";
}
interface PrivilegeModalVisible {
  customers: string[];
  show: boolean;
}
const CustomerPage = () => {
  const [showCalllog, setShowCalllog] = useState(false);
  const [showInvalid, setShowInvalid] = useState(false);
  const [customerShow, setCustomerShow] = useState<{
    show: boolean;
    customerId?: string;
  }>();
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid>();
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [showPrivilege, setShowPrivilege] = useState<PrivilegeModalVisible>();
  const [filterData, setFilterData] = useState<PrivilegeFilterDto>();
  const [showExcelImport, setShowExcelImport] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showPrivilegeConsumer, setShowPrivilegeConsumer] =
    useState<ModalVisible>();
  const [showEmailSend, setShowEmailSend] = useState<ModalVisible>();
  const [showNotification, setShowNotification] = useState<ModalVisible>();
  const [showSms, setShowSms] = useState<ModalVisible>();
  const columns = [
    {
      caption: t("Üye Grubu"),
      dataField: "CustomerGroupName",
    },
    {
      caption: t("Üye Durumu"),
      dataField: "StatusName",
      cellRender: (e: any) => {
        return (
          <>
            {CustomerStatusContainer(
              e.row.data.StatusId,
              e.row.data.StatusName
            )}
          </>
        );
      },
    },

    {
      caption: t("Name"),
      dataField: "Name",
    },

    {
      caption: t("Surname"),
      dataField: "Surname",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    // {
    //   caption: t("Email"),
    //   dataField: "Email",
    // },
    {
      caption: t("Paket"),
      dataField: "LastPackage.PackagePackageName",
    },
    {
      caption: t("İlk Kayıt"),
      dataField: "CreateTime",
      dataType: "date",
      sortOrder: "desc",
    },
    {
      caption: t("İlk Satın Alma"),
      dataField: "FirstPackage.StartDate",
      dataType: "date",
    },
    {
      caption: t("Aktif Paket Başlangıç"),
      dataField: "LastPackage.StartDate",
      dataType: "date",
    },
    {
      caption: t("Aktif Paket Bitiş"),
      dataField: "LastPackage.EndDate",
      dataType: "date",
    },
    {
      caption: t("Yıllık"),
      dataField: "LastPackage.Yearly",
      dataType: "boolean",
    },
    {
      caption: t("Kupon"),
      dataField: "LastPackage.CouponCode",
    },
    {
      caption: t("Son Paket Fiyatı"),
      dataField: "LastPackage.Price",
    },

    {
      caption: t("Araç Ekleme"),
      dataField: "HasVehicle",
      dataType: "boolean",
    },
    {
      caption: t("Bekleme"),
      dataField: "Pending",
      dataType: "boolean",
    },
    {
      caption: t("S.P.H Ataması"),
      dataField: "LastPackage.PrivilegeAssign",
      dataType: "boolean",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Çağrı Aç",
          text: "Çağrı Aç",
          cssClass: "text-dark bg-soft-info columnUseButton",
          onClick: async (e: any) => {
            setCustomerShow({
              show: true,
              customerId: e.row.data.Id,
            });
          },
        },
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/1/" + e.row.key);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (filterData) {
    } else {
    }
  }, [filterData]);
  const user = useSelector(userSelector);

  const onOpenModalFunc = (key: string, setShow: any) => {
    let customers: any[] =
      dataGridRef.current?.instance.getSelectedRowKeys() ?? [];
    if (key === "select" && customers.length <= 0) {
      toast.error("Üye seçiniz");
    } else {
      setShow({
        show: key,
        customers: customers,
      });
    }
  };
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{t("Customers")}</title>
        </MetaTags>
        <Container fluid>
          <React.Fragment>
            <div
              className={
                "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              <h5 className="mb-0 font-size-18">{t("Customers")}</h5>
            </div>
            <DevextGrid
              ref={dataGridRef}
              title={t("Customers")}
              rowAlternationEnabled
              stateStoring={{
                enabled: true,
                type: "localStorage",
              }}
              url={"/api/customers/devexp"}
              loadParams={
                filterData
                  ? {
                      privilegeId: filterData.PrivilegeId,
                      privilegeFilterType: filterData.PrivilegeFilterTypeId,
                    }
                  : ""
              }
              columns={columns.map(column => {
                return { ...column, headerCellRender: renderTitleHeader };
              })}
              create={() => setShowCreate(true)}
              gridItems={
                <Item>
                  <div className="d-flex justify-content-start gap-2 customerGridButtons">
                    <Button
                      text={"Geçersiz Çağrı"}
                      stylingMode={"outlined"}
                      type={"normal"}
                      onClick={() => {
                        return setShowInvalid(true);
                      }}
                    />
                    <Button
                      text={"Çağrı Ekle"}
                      stylingMode={"outlined"}
                      type={"success"}
                      onClick={() => {
                        return setShowCalllog(true);
                      }}
                    />
                    {CheckRole(user) && (
                      <>
                        <Button
                          text={"P.H.A RabbitMq"}
                          stylingMode={"outlined"}
                          type={"default"}
                          onClick={() => {
                            return onOpenModalFunc(
                              "select",
                              setShowPrivilegeConsumer
                            );
                          }}
                        />
                        <Button
                          text={"İçe Aktar"}
                          stylingMode={"contained"}
                          type={"default"}
                          onClick={() => setShowExcelImport(true)}
                        />
                      </>
                    )}
                    <Button
                      icon={filterData ? "close" : ""}
                      text={
                        filterData ? "Hizmet Filtre Temizle" : "Hizmet Filtrele"
                      }
                      stylingMode={"contained"}
                      type={filterData ? "danger" : "success"}
                      onClick={() =>
                        !filterData
                          ? setShowFilter(true)
                          : setFilterData(undefined)
                      }
                    />
                    <Button
                      text={"Hizmet Ata"}
                      stylingMode={"contained"}
                      onClick={() => {
                        let customers: any[] =
                          dataGridRef.current?.instance.getSelectedRowKeys() ??
                          [];
                        if (customers.length > 0) {
                          setShowPrivilege({
                            show: true,
                            customers: customers,
                          });
                        } else {
                          toast.error("Lütfen Üye seçiniz");
                        }
                      }}
                    />
                    {CheckRole(user) && (
                      <>
                        <DropDownBox
                          title={"Sms Gönder"}
                          setShow={key => {
                            return onOpenModalFunc(key, setShowSms);
                          }}
                        />
                        <DropDownBox
                          title={"Bildirim Gönder"}
                          setShow={key => {
                            return onOpenModalFunc(key, setShowNotification);
                          }}
                        />
                        <DropDownBox
                          title={"E-posta Gönder"}
                          setShow={key => {
                            return onOpenModalFunc(key, setShowEmailSend);
                          }}
                        />
                      </>
                    )}
                  </div>
                </Item>
              }
              selection={{ mode: "multiple", allowSelectAll: true }}
            />
          </React.Fragment>
        </Container>
      </div>
      <CreateCustomer open={showCreate} onHide={() => setShowCreate(false)} />

      {showPrivilegeConsumer && (
        <SendPrivilegeConsumer
          open={showPrivilegeConsumer ? true : false}
          onHide={() => setShowPrivilegeConsumer(undefined)}
          customers={showPrivilegeConsumer.customers}
        />
      )}
      {showEmailSend && (
        <SendEmailModal
          filterData={filterData}
          show={showEmailSend ? true : false}
          isAll={showEmailSend.show}
          onHide={() => setShowEmailSend(undefined)}
          customers={showEmailSend.customers}
          onSuccessSubmit={() => {}}
        />
      )}
      {showNotification && (
        <SendNotification
          filterData={filterData}
          show={showNotification ? true : false}
          isAll={showNotification.show}
          onHide={() => setShowNotification(undefined)}
          customers={showNotification.customers}
          onSuccessSubmit={() => {}}
        />
      )}
      {showSms && (
        <SendSms
          filterData={filterData}
          show={showSms ? true : false}
          isAll={showSms.show}
          onHide={() => setShowSms(undefined)}
          customers={showSms.customers}
          onSuccessSubmit={() => {}}
        />
      )}
      <PrivilegeFilterComp
        open={showFilter}
        setData={setFilterData}
        onHide={() => setShowFilter(false)}
      />
      {showPrivilege && (
        <CreateCustomerPrivilegeForMultiple
          filterData={filterData}
          open={showPrivilege.show}
          onHide={() => setShowPrivilege(undefined)}
          customers={showPrivilege.customers}
          onSuccessClick={() => {}}
        />
      )}
      <ImportCustomerExcel
        open={showExcelImport}
        onHide={() => setShowExcelImport(false)}
      />

      {customerShow && (
        <OpenCallLogModal
          show={customerShow.show}
          customerId={customerShow.customerId}
          onHide={() => setCustomerShow(undefined)}
          onSuccessSubmit={payload => {
            toast.success("Geçersiz Çağrı başarıyla oluşturuldu");
            window.open("/ticket/all?ticketId=" + payload?.Id, "_blank");
          }}
        />
      )}
      <OpenCallLogModal
        show={showCalllog}
        onHide={() => setShowCalllog(false)}
        onSuccessSubmit={payload => {
          toast.success("Geçersiz Çağrı başarıyla oluşturuldu");
          window.open("/ticket/all?ticketId=" + payload?.Id, "_blank");
        }}
      />
      <CreateInvalidCallLog
        onHide={() => setShowInvalid(false)}
        show={showInvalid}
        onSuccessSubmit={payload => {
          toast.success("Geçersiz Çağrı başarıyla oluşturuldu");
          window.open("/ticket/all?ticketId=" + payload?.Id, "_blank");
        }}
      />
    </React.Fragment>
  );
};

export default CustomerPage;

const DropDownBox = ({
  title,
  setShow,
}: {
  title: string;
  setShow: (key: "select" | "active" | "all") => void;
}) => {
  const list = [
    { key: "select", label: "Seçili olanlara E-Posta Gönder" },
    { key: "all", label: "Aktif Üyelere Gönder" },
    { key: "active", label: "Tümüne Gönder" },
  ];

  return (
    <DropDownButton
      text={title}
      items={list}
      displayExpr="label"
      keyExpr="key"
      onItemClick={e => {
        setShow(e.itemData.key);
      }}
    />
  );
};
