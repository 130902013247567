import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
import { AgreementDto } from "src/store/agreement/type";
import { getAgreementChangeInfoList } from "src/store/agreement/saga";
import ColumnButton from "src/components/Form/Button/ColumnButton";

const AgreementChangeInfoPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<AgreementDto[]>([]);

  const columns = [
    {
      text: t("Title"),
      dataField: "Title",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              return history.push("/agreements/change-infos/" + cellContent);
            }}
          />{" "}
          <ColumnButton
            buttonType="details"
            onClick={() => {
              return history.push(
                "/agreements/change-infos/details/" + cellContent
              );
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        withoutPag
        title={t("Sözleşme Değişiklikleri")}
        gridProps={{
          listData: data,
          columns: columns,

          setDataList: setData,
          action: getAgreementChangeInfoList,
        }}
      />
    </React.Fragment>
  );
};

export default AgreementChangeInfoPage;
