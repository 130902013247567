import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CallLogChannelDto } from "./type";

export const getCallLogChannels = createAction(
  "GET_CALLLOG_CHANNELS",
  ({ payload, params }: SagaActionParams<CallLogChannelDto[]>) => ({
    payload,
    url: "/api/calllogchannels" + (params ? params : ""),
  })
);

export const getCallLogChannelsDetails = createAction(
  "GET_CALLLOG_CHANNELS_DETAILS",
  ({ payload, id }: SagaActionParams<CallLogChannelDto>) => ({
    payload,
    url: "/api/calllogchannels/" + id,
  })
);
export const createCallLogChannels = createAction(
  "CREATE_CALLLOG_CHANNELS",
  ({ payload, body }: SagaActionParams<CallLogChannelDto>) => ({
    payload,
    url: "/api/calllogchannels",
    body,
  })
);
export const updateCallLogChannels = createAction(
  "UPDATE_CALLLOG_CHANNELS",
  ({ payload, id, body }: SagaActionParams<CallLogChannelDto>) => ({
    payload,
    url: "/api/calllogchannels/" + id,
    body,
  })
);

export const deleteCallLogChannels = createAction(
  "DELETE_CALLLOG_CHANNELS",
  ({ payload, id }: SagaActionParams<CallLogChannelDto>) => ({
    payload,
    url: "/api/calllogchannels/" + id,
  })
);

const calllogchannelsSaga = [
  /*CALLLOG_CHANNELS*/
  takeLatest(
    getCallLogChannels.toString(),
    (payload: SagaGenericParams<CallLogChannelDto[]>) =>
      getListSaga<CallLogChannelDto[]>(payload)
  ),

  takeLatest(
    getCallLogChannelsDetails.toString(),
    (payload: SagaGenericParams<CallLogChannelDto>) =>
      getListSaga<CallLogChannelDto>(payload)
  ),
  takeLatest(
    createCallLogChannels.toString(),
    (payload: PostSagaGenericParams<CallLogChannelDto>) =>
      postSaga<CallLogChannelDto>(payload)
  ),
  takeLatest(
    updateCallLogChannels.toString(),
    (payload: PostSagaGenericParams<CallLogChannelDto>) =>
      putSaga<CallLogChannelDto>(payload)
  ),

  takeLatest(
    deleteCallLogChannels.toString(),
    (payload: SagaGenericParams<any>) => deleteSaga<CallLogChannelDto>(payload)
  ),
];

export default calllogchannelsSaga;
