import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { VehicleGearTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getVehicleGearTypeList = createAction(
  "GET_VEHICLE_GEAR_TYPE_LIST",
  ({ payload, params }: SagaActionParams<VehicleGearTypeDto[]>) => ({
    payload,
    url: "/api/vehiclegeartypes" + (params ? params : ""),
  })
);
export const getVehicleGearTypeDetails = createAction(
  "GET_VEHICLE_GEAR_TYPE_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleGearTypeDto>) => ({
    payload,
    url: "/api/vehiclegeartypes/" + id,
  })
);
export const createVehicleGearType = createAction(
  "CREATE_VEHICLE_GEAR_TYPE_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleGearTypeDto>) => ({
    payload,
    url: "/api/vehiclegeartypes",
    body,
  })
);
export const updateVehicleGearType = createAction(
  "UPDATE_VEHICLE_GEAR_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleGearTypeDto>) => ({
    payload,
    url: "/api/vehiclegeartypes/" + id,
    body,
  })
);
export const deleteVehicleGearType = createAction(
  "DELETE_VEHICLE_GEAR_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleGearTypeDto>) => ({
    payload,
    url: "/api/vehiclegeartypes/" + id,
    body,
  })
);

export const changeVehicleGearTypeOrder = createAction(
  "UPDATE_VEHICLE_GEAR_TYPE_ORDER",
  ({ payload, body }: SagaActionParams<VehicleGearTypeDto>) => ({
    payload,
    url: "/api/vehiclegeartypes/order",

    bodyType: BodyType.raw,
    body,
  })
);
const vehiclegeartypeSaga = [
  takeLatest(
    getVehicleGearTypeList.toString(),
    (payload: SagaGenericParams<VehicleGearTypeDto[]>) =>
      getListSaga<VehicleGearTypeDto[]>(payload)
  ),
  takeLatest(
    getVehicleGearTypeDetails.toString(),
    (payload: SagaGenericParams<VehicleGearTypeDto>) =>
      getListSaga<VehicleGearTypeDto>(payload)
  ),
  takeLatest(
    createVehicleGearType.toString(),
    (payload: PostSagaGenericParams<VehicleGearTypeDto>) =>
      postSaga<VehicleGearTypeDto>(payload)
  ),
  takeLatest(
    changeVehicleGearTypeOrder.toString(),
    (payload: PostSagaGenericParams<VehicleGearTypeDto>) =>
      putSaga<VehicleGearTypeDto>(payload)
  ),
  takeLatest(
    updateVehicleGearType.toString(),
    (payload: PostSagaGenericParams<VehicleGearTypeDto>) =>
      putSaga<VehicleGearTypeDto>(payload)
  ),
  takeLatest(
    deleteVehicleGearType.toString(),
    (payload: SagaGenericParams<VehicleGearTypeDto>) =>
      deleteSaga<VehicleGearTypeDto>(payload)
  ),
];

export default vehiclegeartypeSaga;
