import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getprivilegeCategoryTypes } from "src/store/lookup/saga";
import { PrivilegeCategoryType } from "src/store/lookup/type";
import { createPrivilegeCategory } from "src/store/privilegecategory/saga";
import * as Yup from "yup";

const CreatePrivilegeCategory = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [types, setTypes] = useState<PrivilegeCategoryType[]>([]);
  useEffect(() => {
    open &&
      dispatch(
        getprivilegeCategoryTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New PrivilegeCategory"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CategoryName: "",
          ImageFile: "",
          ImagePngFile: "",
          //  IconLibrary: "",
          Description: "",
          Active: false,
          PrivilegeCategoryTypeId: "",
          IsSinglePrivilege: false,
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeCategory({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          CategoryName: Yup.string().required(t("Required")),
          ImageFile: Yup.mixed().required(t("Required")),
          ImagePngFile: Yup.mixed().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeCategoryTypeId",
            inputType: InputType.multiselect,
            label: t("Kategori Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
            },
          },
          {
            field: "CategoryName",
            label: t("Category Name"),
          },
          {
            field: "Description",
            label: t("Description"),
            inputType: InputType.editor,
          },
          // {
          //   field: "IconLibrary",
          //   label: t("Icon Library"),
          // },
          // {
          //   field: "IconName",
          //   label: t("Icon Name"),
          // },
          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
          {
            field: "ImagePngFile",
            label: t("ImageFile Image") + "Png",
            inputType: InputType.fileUpload,
            fileUpload: {
              multiple: false,
              title: t("Please Upload Image") + "(Png)",
            },
          },
          {
            field: "IsSinglePrivilege",
            label: t("Tekli Hizmet"),
            col: 6,
            checkedLabel: t("Tekli Hizmet"),
            uncheckedLabel: t("Tekli Hizmet"),
            inputType: InputType.toogle,
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeCategory;
