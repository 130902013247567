import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { TicketChannelDto } from "./type";

export const getTicketChannels = createAction(
  "GET_TICKET_CHANNELS",
  ({ payload, params }: SagaActionParams<TicketChannelDto[]>) => ({
    payload,
    url: "/api/ticketchannels" + (params ? params : ""),
  })
);

export const getTicketChannelsDetails = createAction(
  "GET_TICKET_CHANNELS_DETAILS",
  ({ payload, id }: SagaActionParams<TicketChannelDto>) => ({
    payload,
    url: "/api/ticketchannels/" + id,
  })
);
export const createTicketChannels = createAction(
  "CREATE_TICKET_CHANNELS",
  ({ payload, body }: SagaActionParams<TicketChannelDto>) => ({
    payload,
    url: "/api/ticketchannels",
    body,
  })
);
export const updateTicketChannels = createAction(
  "UPDATE_TICKET_CHANNELS",
  ({ payload, id, body }: SagaActionParams<TicketChannelDto>) => ({
    payload,
    url: "/api/ticketchannels/" + id,
    body,
  })
);

export const deleteTicketChannels = createAction(
  "DELETE_TICKET_CHANNELS",
  ({ payload, id }: SagaActionParams<TicketChannelDto>) => ({
    payload,
    url: "/api/ticketchannels/" + id,
  })
);

const ticketchannelsSaga = [
  /*TICKET_CHANNELS*/
  takeLatest(
    getTicketChannels.toString(),
    (payload: SagaGenericParams<TicketChannelDto[]>) =>
      getListSaga<TicketChannelDto[]>(payload)
  ),

  takeLatest(
    getTicketChannelsDetails.toString(),
    (payload: SagaGenericParams<TicketChannelDto>) =>
      getListSaga<TicketChannelDto>(payload)
  ),
  takeLatest(
    createTicketChannels.toString(),
    (payload: PostSagaGenericParams<TicketChannelDto>) =>
      postSaga<TicketChannelDto>(payload)
  ),
  takeLatest(
    updateTicketChannels.toString(),
    (payload: PostSagaGenericParams<TicketChannelDto>) =>
      putSaga<TicketChannelDto>(payload)
  ),

  takeLatest(
    deleteTicketChannels.toString(),
    (payload: SagaGenericParams<any>) => deleteSaga<TicketChannelDto>(payload)
  ),
];

export default ticketchannelsSaga;
