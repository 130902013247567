import React from "react";
import ReactApexChart from "react-apexcharts";

const Radial = ({
  percentage,
  total,
  label,
  color,
}: {
  percentage: number;
  total: number;
  label: string;
  color: string;
}) => {
  const series = [percentage];
  const options: Object = {
    chart: {
      height: 370,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            fontSize: 12,
            label: label,
            formatter: function (w: any) {
              // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              return total;
            },
          },
          dropShadow: {
            enabled: false,
          },
        },
      },
    },

    labels: [label],
    colors: [color],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height={180}
      className="apex-charts"
    />
  );
};

export default Radial;
