import { forwardRef, LegacyRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  /*withDirection,*/
  withDirectionPropTypes,
  //@ts-ignore
} from "react-with-direction";
import "./style.css";

const Carousel = forwardRef(
  (
    {
      children,
      direction,
      noArrows,
      responsive = true,
      slideNumber,
      focusOnSelect = false,
      asNavFor,
      className,
    }: withDirectionPropTypes,
    ref: LegacyRef<any> | undefined
  ) => {
    let settings = {
      asNavFor: asNavFor ? asNavFor : false,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: slideNumber ? slideNumber : 8,
      slidesToScroll: slideNumber ? slideNumber : 8,
      initialSlide: 0,
      rtl: false, //direction === DIRECTIONS.LTR ? false : true,
      focusOnSelect: focusOnSelect,
      // responsive: !responsive
      //   ? [
      //       {
      //         breakpoint: 1400,
      //         settings: {
      //           slidesToShow: 1,
      //           slidesToScroll: 1,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //       {
      //         breakpoint: 1006,
      //         settings: {
      //           slidesToShow: 1,
      //           slidesToScroll: 1,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //       {
      //         breakpoint: 660,
      //         settings: {
      //           slidesToShow: 1,
      //           slidesToScroll: 1,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //     ]
      //   : [
      //       {
      //         breakpoint: 1400,
      //         settings: {
      //           slidesToShow: 3,
      //           slidesToScroll: 3,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //       {
      //         breakpoint: 1006,
      //         settings: {
      //           slidesToShow: 2,
      //           slidesToScroll: 2,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //       {
      //         breakpoint: 660,
      //         settings: {
      //           slidesToShow: 1,
      //           slidesToScroll: 1,
      //           infinite: true,
      //           dots: false,
      //         },
      //       },
      //     ],
      prevArrow:
        noArrows === true ? (
          <></>
        ) : (
          <div style={{ width: 38, height: 38 }}>
            <></>
          </div>
        ),
      nextArrow: noArrows === true ? <></> : <></>,
    };

    return (
      <Slider ref={ref} {...settings} className={className}>
        {children}
      </Slider>
    );
  }
);

export default Carousel;
