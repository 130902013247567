import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InputType, inputKey } from "src/components/Form/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import * as Yup from "yup";
import {
  CustomerVehicleDto,
  CustomerVehicleLookupDto,
  VehicleStepDto,
} from "src/store/customervehicles/type";
import { CustomerDetailDto, CustomerDto } from "src/store/customers/type";
import {
  CallLogLevelDto,
  CallLogTopicDto,
} from "src/store/customercalllog/type";
import {
  getCustomerCalllogLevel,
  getCustomerCalllogParentTopics,
  getCustomerCalllogSubTopicsByParentId,
} from "src/store/customercalllog/saga";
import { getCustomerList } from "src/store/customers/saga";
import {
  getCustomerVehicleCategory,
  getCustomerVehicleListByCustomerId,
  getCustomerVehicleSubCategory,
} from "src/store/customervehicles/saga";
import {
  createCustomerCallLogs,
  getCustomerCallLogsTypes,
} from "src/store/customer-calllog/saga";
import { CustomerCallLogTypeDto } from "src/store/customer-calllog/type";
import { Formik, useFormikContext } from "formik";
import { getCustomerAddressListByCustomerId } from "src/store/customeraddress/saga";
import { CustomerAddressDto } from "src/store/customeraddress/type";
import { getCallLogChannels } from "src/store/calllog-channel/saga";
import { CallLogChannelDto } from "src/store/calllog-channel/type";
import CustomModal from "src/components/Modal/modal";
import GetInput from "src/components/Form/GetInput";
import { Button, Col, Row } from "reactstrap";
import VehicleInput from "./vehicle-input";
import TicketCreateInputs from "./createTicket";
import { userSelector } from "src/store/auth";
const SubComponentForCurrentCustomer = ({
  customerData,
  open,
  setVehicles,
  setAddress,
  setTowns,
}: {
  open: boolean;
  setTowns: (towns: TownDto[]) => void;
  setAddress: (address: CustomerAddressDto[]) => void;
  setVehicles: (vehicle: CustomerVehicleDto[]) => void;
  customerData: CustomerDetailDto;
}) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext<any>();
  useEffect(() => {
    if (customerData && open) {
      dispatch(
        getCustomerVehicleListByCustomerId({
          payload: {
            onSuccess: (msg, payload) => {
              setVehicles(payload);
              if (payload.length > 0) {
                setFieldValue("VehicleId", payload[0].Id ?? "");
                setFieldValue("VehiclePlate", payload[0].Plate ?? "");
                setFieldValue("Brand", payload[0].make ?? "");
                setFieldValue("Model", payload[0].model ?? "");
                setFieldValue("ModelYear", payload[0].regdate ?? "");
                setFieldValue("make", payload[0].make ?? "");
                setFieldValue("model", payload[0].model ?? "");
                setFieldValue("regdate", payload[0].regdate ?? "");
                setFieldValue("category", payload[0].category ?? "");
              }
            },
            onError: () => {},
          },
          id: customerData.Id,
        })
      );
      dispatch(
        getCustomerAddressListByCustomerId({
          payload: {
            onSuccess: (msg, payload) => {
              setAddress(payload);
              if (payload.length > 0) {
                setFieldValue("AddressId", payload[0].Id ?? "");
                setFieldValue("TownId", payload[0].TownId ?? "");
                setFieldValue("Address", payload[0].Address ?? "");
                setFieldValue("CityId", payload[0].CityId ?? "");
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => {
                        setTowns(payload);
                      },
                      onError: () => {},
                    },
                    id: payload[0].CityId,
                  })
                );
              }
            },
            onError: () => {},
          },
          id: customerData.Id,
        })
      );
    } // eslint-disable-next-line
  }, [open, dispatch, values.CustomerId]);
  return <></>;
};

const CreateCustomerCalllogModal = ({
  onHide,
  open,
  customerData,
}: {
  customerData?: CustomerDetailDto;
  onHide: () => void;
  open: boolean;
}) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const [types, setTypes] = useState<CustomerCallLogTypeDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  const [address, setAddress] = useState<CustomerAddressDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);
  const [parents, setParents] = useState<CallLogTopicDto[]>([]);
  const [subtopics, setSubTopics] = useState<CallLogTopicDto[]>([]);
  const [channels, setChannels] = useState<CallLogChannelDto[]>([]);
  const [levels, setLevels] = useState<CallLogLevelDto[]>([]);

  const [categories, setCategories] = useState<CustomerVehicleLookupDto>();
  useEffect(() => {
    if (open) {
      dispatch(
        getCustomerVehicleCategory({
          payload: {
            onSuccess: (ms, payload) => {
              setCategories(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerCallLogsTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerCalllogParentTopics({
          payload: {
            onSuccess: (msg, payload) => setParents(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, payload) => setCities(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerList({
          payload: {
            onSuccess: (msg, payload) => setCustomers(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCallLogChannels({
          payload: {
            onSuccess: (msg, payload) => setChannels(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerCalllogLevel({
          payload: {
            onSuccess: (msg, payload) => setLevels(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);

  const inputs: inputKey[] = [
    {
      field: "CustomerCallLogLevelEnumId",
      inputType: InputType.multiselect,
      label: t("Önem Derecesi") + "*",
      lookup: {
        data: levels,
        labelKey: "Name",
        valueKey: "EnumId",
        placeholder: t("Önem Derecesi Seçiniz"),
      },
      col: 4,
    },
    {
      field: "CustomerCallLogTypeId",
      inputType: InputType.multiselect,
      label: t("Tip Seçiniz") + "*",
      lookup: {
        data: types,
        labelKey: "Name",
        valueKey: "EnumId",

        placeholder: t("Tip Seçiniz"),
      },
      col: 4,
    },
    {
      field: "CallLogChannelId",
      inputType: InputType.multiselect,
      label: t("İletişim Kanalı") + "*",
      lookup: {
        data: channels,
        labelKey: "Name",
        searchable: false,
        valueKey: "Id",
        placeholder: t("İletişim Kanalı Seçiniz"),
      },
      col: 4,
    },
    {
      col: 6,
      field: "CustomerCallLogTopicId",
      inputType: InputType.multiselect,
      label: t("Ana Konu Seçiniz") + "*",
      lookup: {
        data: parents,
        labelKey: "Name",
        valueKey: "Id",
        placeholder: t("Konu Seçiniz"),
      },
      onChange(value, item, setFieldValue) {
        setFieldValue("CustomerCallLogSubTopicId", value);
        dispatch(
          getCustomerCalllogSubTopicsByParentId({
            payload: {
              onSuccess: (msg, payload) => setSubTopics(payload),
              onError: () => {},
            },
            id: value,
          })
        );
      },
    },
    {
      col: 6,
      field: "CustomerCallLogSubTopicId",
      inputType: InputType.multiselect,
      label: t("Alt Konu Seçiniz") + "*",
      lookup: {
        data: subtopics,
        labelKey: "Name",
        valueKey: "Id",
        placeholder: t("Alt Konu Seçiniz"),
      },
    },

    {
      disabled: values => (customerData ? true : false),
      field: "CustomerId",
      inputType: InputType.multiselect,
      label: t("Kayıtlı Üye Seçimi (Aday üye ise boş geçiniz)"),
      lookup: {
        data: customers,
        labelKey: "Name",
        valueKey: "Id",
        placeholder: t("Üye Seçiniz"),
        renderMenuItem(label, item) {
          return item ? (
            <>
              {item.Name} {item.Name} / {item.PhoneNumber}`
            </>
          ) : (
            <>{label}</>
          );
        },
        renderValue: (value, item: any, selectedElement) =>
          item ? (
            <>
              {item.Name} {item.Name} / {item.PhoneNumber}`
            </>
          ) : (
            <p>{value}</p>
          ),
      },
      onChange(value, item: CustomerDto, setFieldValue, values) {
        setFieldValue("Name", item.Name);
        setFieldValue("Surname", item.Surname);
        setFieldValue("PhoneNumber", item.PhoneNumber);
        dispatch(
          getCustomerVehicleListByCustomerId({
            payload: {
              onSuccess: (msg, payload) => {
                setVehicles(payload);
                if (payload.length > 0) {
                  setFieldValue("VehicleId", payload[0].Id ?? "");
                  setFieldValue("VehiclePlate", payload[0].Plate ?? "");
                  setFieldValue("Brand", payload[0].make ?? "");
                  setFieldValue("Model", payload[0].model ?? "");
                  setFieldValue("ModelYear", payload[0].regdate ?? "");
                  setFieldValue("make", payload[0].make ?? "");
                  setFieldValue("model", payload[0].model ?? "");
                  setFieldValue("category", payload[0].category ?? "");
                  setFieldValue("regdate", payload[0].regdate ?? "");
                } else {
                  setFieldValue("VehicleId", "");
                  setFieldValue("VehiclePlate", "");
                }
              },
              onError: () => {},
            },
            id: value,
          })
        );
        dispatch(
          getCustomerAddressListByCustomerId({
            payload: {
              onSuccess: (msg, payload) => {
                setAddress(payload);
                if (payload.length > 0) {
                  setFieldValue("AddressId", payload[0].Id ?? "");
                  setFieldValue("TownId", payload[0].TownId ?? "");
                  setFieldValue("Address", payload[0].Address ?? "");
                  setFieldValue("CityId", payload[0].CityId ?? "");
                  dispatch(
                    getTowns({
                      payload: {
                        onSuccess: (msg, payload) => {
                          setTowns(payload);
                        },
                        onError: () => {},
                      },
                      id: payload[0].CityId,
                    })
                  );
                } else {
                  setFieldValue("AddressId", "");
                }
              },
              onError: () => {},
            },
            id: value,
          })
        );
      },
    },
    {
      col: 6,
      field: "Name",
      label: t("Name") + "*",
      disabled: values => (values.CustomerId ? true : false),
    },
    {
      col: 6,
      field: "Surname",
      label: t("Surname") + "*",
      disabled: values => (values.CustomerId ? true : false),
    },
    {
      col: 6,
      field: "PhoneNumber",
      label: t("PhoneNumber") + "*",
    },
    {
      col: 6,
      field: "PhoneNumber2",
      label: t("PhoneNumber") + 2,
    },
    {
      field: "AddressId",
      inputType: InputType.multiselect,
      label: t("Adres") + "*",
      lookup: {
        placement: "topStart",
        data: address,
        labelKey: "Name",
        valueKey: "Id",
        placeholder: t("Adres Seçiniz"),
      },
      onChange(value, item: any, setFieldValue, values) {
        if (item) {
          setFieldValue("TownId", item.TownId ?? "");
          setFieldValue("Address", item.Address ?? "");
          setFieldValue("CityId", item.CityId ?? "");
        }
      },
    },
    {
      col: 6,
      field: "CityId",
      inputType: InputType.multiselect,
      label: t("City") + "*",
      lookup: {
        data: cities,
        labelKey: "CityName",
        placement: "topStart",
        valueKey: "Id",
        placeholder: t("Choose City"),
      },
      onChange(value, item, setFieldValue) {
        value &&
          dispatch(
            getTowns({
              payload: {
                onSuccess: (msg, payload) => setTowns(payload),
                onError: () => {},
              },
              id: value,
            })
          );
      },
    },
    {
      col: 6,
      field: "TownId",
      inputType: InputType.multiselect,
      label: t("Town") + "*",
      lookup: {
        placement: "topStart",
        data: towns,
        labelKey: "TownName",
        valueKey: "Id",
        placeholder: t("Choose Town"),
      },
    },
    {
      field: "Address",
      label: t("Address"),
      inputType: InputType.multilinetext,
    },

    {
      hide(values) {
        return values.CustomerId ? false : true;
      },
      field: "VehicleId",
      inputType: InputType.multiselect,
      label: t("Üye Araç Seçiniz"),
      lookup: {
        data: vehicles,
        labelKey: "Plate",
        valueKey: "Id",
        placeholder: t("Üye Araç Seçiniz"),
        renderValue(label, item: any) {
          return (
            <span>
              {item ? (
                `${item.Plate} / ${item.category} / ${item.make} / ${item.model}`
              ) : label ? (
                label
              ) : (
                <p></p>
              )}
            </span>
          );
        },
        renderMenuItem(label, item: any) {
          return (
            <>
              {item
                ? `${item.Plate} / ${item.category} / ${item.make} / ${item.model}}`
                : label}
            </>
          );
        },
      },
      onChange(value, item: CustomerVehicleDto, setFieldValue, values) {
        if (item) {
          setFieldValue("VehicleId", item.Id ?? "");
          setFieldValue("VehiclePlate", item.Plate ?? "");
          setFieldValue("Brand", item.make ?? "");
          setFieldValue("Model", item.model ?? "");
          setFieldValue("ModelYear", item.regdate ?? "");
          setFieldValue("make", item.make ?? "");
          setFieldValue("model", item.model ?? "");
          setFieldValue("regdate", item.regdate ?? "");
          setFieldValue("regdate", item.regdate ?? "");
          setFieldValue("category", item.category ?? "");
        } else {
          setFieldValue("VehicleId", "");
          setFieldValue("VehiclePlate", "");
          setFieldValue("category", "");
          setFieldValue("Brand", "");
          setFieldValue("Model", "");
          setFieldValue("ModelYear", "");
          setFieldValue("make", "");
          setFieldValue("model", "");
          setFieldValue("regdate", "");
        }
      },

      col: 12,
    },
    {
      disabled: values => (values.VehicleId ? true : false),
      field: "VehiclePlate",
      col: 6,
      label: t("Plaka"),
    },

    {
      field: "KmLimit",
      col: 6,
      label: t("Km Limit"),
    },
  ];
  return (
    <React.Fragment>
      <CustomModal
        size="md"
        open={open}
        onHide={onHide}
        title={"Yeni Çağrı Kaydı Ekle"}
      >
        <Formik
          initialValues={{
            CustomerCallLogLevelEnumId: "",
            CallLogChannelId: "",
            CustomerId: customerData ? customerData.Id : "",
            Name: customerData ? customerData.Name : "",
            Surname: customerData ? customerData.Surname : "",
            PhoneNumber: customerData ? customerData.PhoneNumber : "",
            PhoneNumber2: "",
            VehiclePlate: "",
            KmLimit: "",
            Address: "",
            AddressId: "",
            Not: "",
            CustomerCallLogTopicId: "",
            CustomerCallLogSubTopicId: "",
            CustomerCallLogTypeId: "",
            TownId: "",
            CityId: "",
            VehicleId: "",
            make: "",
            model: "",
            regdate: "",
            category: "",
            DataArray: [],
            vehicleData: null,
            //TICKETTT
            OpenTicket: false,
            TicketTypeId: 1,
            SubjectId: "",
            SupplierId: "",
            ResponsibleUserId: user ? user.Id : "",
            Message: "",
            Documents: [],
            Images: [],
          }}
          onSubmit={values => {
            dispatch(
              createCustomerCallLogs({
                payload: {
                  onSuccess: (message, payload) => {
                    toast.success(message, { theme: "colored" });
                    return onHide();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  ...values,
                  vehicleData: "",
                  Brand: values.make,
                  Model: values.model,
                  ModelYear: values.regdate,
                },
              })
            );
          }}
          validationSchema={Yup.object().shape({
            CustomerId: Yup.string().when("OpenTicket", {
              is: (id: boolean) => id,
              then: Yup.string().required(t("Required")),
            }),
            TicketTypeId: Yup.string().when("OpenTicket", {
              is: (id: boolean) => id,
              then: Yup.string().required(t("Required")),
            }),
            SubjectId: Yup.string().when("OpenTicket", {
              is: (id: boolean) => id,
              then: Yup.string().required(t("Required")),
            }),
            ResponsibleUserId: Yup.string().when("OpenTicket", {
              is: (id: boolean) => id,
              then: Yup.string().required(t("Required")),
            }),
            SupplierId: Yup.string().when("OpenTicket", {
              is: (id: boolean) => id,
              then: Yup.string().required(t("Required")),
            }),
            CustomerCallLogLevelEnumId: Yup.number()
              .typeError(t("Required"))
              .required(t("Required")),
            Name: Yup.string().required(t("Required")),
            CallLogChannelId: Yup.string().required(t("Required")),
            Surname: Yup.string().required(t("Required")),
            PhoneNumber: Yup.string().required(t("Required")),
            CityId: Yup.string().required(t("Required")),
            TownId: Yup.string().required(t("Required")),
            CustomerCallLogSubTopicId: Yup.string().required(t("Required")),
            CustomerCallLogTypeId: Yup.number()
              .typeError(t("Required"))
              .required(t("Required")),
          })}
        >
          {({ touched, handleSubmit, setFieldValue, errors, values }) => (
            <>
              {console.log(errors, "EErrr")}
              {customerData && (
                <SubComponentForCurrentCustomer
                  customerData={customerData}
                  open={open}
                  setAddress={setAddress}
                  setTowns={setTowns}
                  setVehicles={setVehicles}
                />
              )}
              <Row style={{ width: "100%", paddingInline: "10px" }}>
                {inputs.map((key, i) => {
                  const disabledvalue = key.disabled
                    ? key.disabled(values)
                    : false;
                  return key.hide && key.hide(values) ? (
                    <></>
                  ) : (
                    <GetInput
                      inputprop={key}
                      key={i}
                      disabledvalue={disabledvalue}
                    />
                  );
                })}
                {categories && (
                  <>
                    <GetInput
                      inputprop={{
                        col: 4,
                        hide(values) {
                          return !values.VehicleId ? false : true;
                        },
                        inputType: InputType.multiselect,
                        field: "category",
                        label: t("Kategori"),
                        lookup: {
                          placement: "topStart",
                          data: categories.nextStep.map(x => {
                            return {
                              value: x.name ?? x.summary,
                              label: x.name ?? x.summary,
                              href: x.href,
                              rel: x.rel,
                              summary: x.summary,
                              templated: x.templated,
                              type: x.type,
                              description: x.description,
                              name: x.name,
                            };
                          }),
                          cleanable: false,
                        },
                        onChange: (
                          value,
                          item: VehicleStepDto,
                          setFieldValue
                        ) => {
                          setFieldValue("DataArray", []);
                          dispatch(
                            getCustomerVehicleSubCategory({
                              payload: {
                                onSuccess: (ms, payload) => {
                                  setFieldValue("DataArray", [payload]);
                                },
                                onError: () => {},
                              },
                              body: {
                                url: item.href,
                              },
                            })
                          );
                        },
                      }}
                    />
                    {values.DataArray.slice(0, 4).map((x, i) => (
                      <VehicleInput list={x} i={i} />
                    ))}
                  </>
                )}
                <GetInput
                  inputprop={{
                    col: 12,
                    field: "Not",
                    label: t("Not"),
                    inputType: InputType.multilinetext,
                  }}
                />
                <TicketCreateInputs open={open} />{" "}
                <Col md={12}>
                  <div className="w-100 d-flex justify-content-between ">
                    <Button
                      color="danger"
                      style={{ width: 220 }}
                      type="submit"
                      onClick={() => onHide()}
                    >
                      {t("Geri")}
                    </Button>{" "}
                    <Button
                      color="success"
                      type="submit"
                      style={{ width: 220 }}
                      onClick={() => handleSubmit()}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};
export default CreateCustomerCalllogModal;
