import ResponsiveNav, {
  ResponsiveNavProps,
} from "@rsuite/responsive-nav/lib/ResponsiveNav";
import { NavItemProps } from "rsuite";
import MoreIcon from "@rsuite/icons/More";
import "./navStyle.scss";
interface CustomResponsiveNavProps extends ResponsiveNavProps<any> {
  items: CustomNavItemProps[];
}
interface CustomNavItemProps extends NavItemProps {
  label: any;
  eventKey: any;
}
const CustomResponsiveNav = ({ items, ...rest }: CustomResponsiveNavProps) => {
  return (
    <ResponsiveNav
      {...rest}
      className="responsiveNav "
      moreText={<MoreIcon />}
      moreProps={{ noCaret: true, style: { height: 30 } }}
    >
      {items.map(item => (
        <ResponsiveNav.Item key={item.eventKey} eventKey={item.eventKey}>
          {item.label}
        </ResponsiveNav.Item>
      ))}
    </ResponsiveNav>
  );
};
export default CustomResponsiveNav;
