import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { ProductDetailDto } from "src/store/orders/products/type";

import ProductImageUpload from "./imageUpload";
import { useDispatch } from "react-redux";
import {
  deleteProductImage,
  getProductImageListByProductId,
  setMainImage,
} from "src/store/orders/products-images/saga";
import { baseImageUrl } from "src/store/base/types/url";
import { ProductImageDto } from "src/store/orders/products-images/type";
import Carousel from "src/components/Carousel";
import CancelAlert from "src/components/CancelAlert";
import { toast } from "react-toastify";
import ProductImageCard from "./imageCard";
const ProductImageList = ({ data }: { data: ProductDetailDto }) => {
  const [active, setActive] = useState<ProductImageDto>(data.ProductImages[0]);
  const [show, setShow] = useState(false);
  const [images, setImages] = useState(data.ProductImages);
  const dispatch = useDispatch();
  const slider = useRef<any>();
  const [showDelete, setShowDelete] = useState<ProductImageDto>();
  const [showSetMain, setShowSetMain] = useState<ProductImageDto>();

  const refreshData = () => {
    dispatch(
      getProductImageListByProductId({
        payload: {
          onSuccess: (m, p) => {
            setImages(p);
            p && p.length > 0 && !active && setActive(p[0]);
          },
          onError: () => {},
        },
        id: data.Id,
      })
    );
  };
  return (
    <React.Fragment>
      {!(images != null && images.length > 0) ? (
        <>
          <div
            onClick={() => setShow(true)}
            className="cursor-pointer h-100 w-100 border border-dotted d-flex align-items-center justify-content-center flex-column py-5 text-muted fw-bold font-size-20"
          >
            <i className=" dripicons-photo-group" />
            <div> Fotoğraf Ekle</div>
          </div>
        </>
      ) : (
        <Row>
          <Col md={12}>
            {active && (
              <img
                src={baseImageUrl + active.Image}
                alt=""
                width={400}
                height={400}
                className="mx-auto d-block rounded"
              />
            )}
          </Col>
          <Col md={12} className="mt-3">
            <Carousel ref={slider} responsive={false}>
              {images.map(x => (
                <ProductImageCard
                  data={x}
                  clickDelete={() => setShowDelete(x)}
                  setActive={setActive}
                  refreshData={refreshData}
                  active={active}
                  clickMain={() => setShowSetMain(x)}
                />
              ))}
            </Carousel>
          </Col>
          <Col md={12} className="mb-2">
            <div
              onClick={() => setShow(true)}
              className=" my-2 py-2 h-100 cursor-pointer  border border-dotted d-flex align-items-center justify-content-center flex-column text-muted fw-bold font-size-12"
            >
              <i className=" dripicons-photo-group" />
              <div> Fotoğraf Ekle</div>
            </div>
          </Col>
        </Row>
      )}
      <ProductImageUpload
        open={show}
        data={data}
        setOpen={() => setShow(false)}
        refreshData={refreshData}
      />{" "}
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteProductImage({
                payload: {
                  onSuccess: message => {
                    toast.success(message);
                    refreshData();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
      {showSetMain && (
        <CancelAlert
          message={"Ana resim yapmak istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowSetMain(undefined);
            dispatch(
              setMainImage({
                payload: {
                  onSuccess: message => {
                    toast.success(message);
                    refreshData();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: { ProductImageId: showSetMain.Id },
              })
            );
          }}
          onCancel={() => {
            setShowSetMain(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default ProductImageList;
