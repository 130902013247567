import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
import SendNotification from "./sendNotificationModal";
import { toast } from "react-toastify";
import { CheckRole } from "src/permissions/checkPermission";
import { useSelector } from "react-redux";
import { userSelector } from "src/store/auth";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Item } from "devextreme-react/data-grid";
import SendEmailModal from "./sendEmailModal";
import CreateCustomer from "./create";
import { Button } from "devextreme-react";
import PrivilegeFilterComp, { PrivilegeFilterDto } from "./privilegeFilter";
import SendSms from "./sendSms";
import "./style.scss";
import CreateCustomerPrivilegeForMultiple from "./createCampaign";

const CancelledCustomerPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [showPrivilege, setShowPrivilege] = useState(false);
  const [showNotification, setShowNotification] = useState<
    "select" | "active" | "all"
  >();
  const [filterData, setFilterData] = useState<PrivilegeFilterDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [selectedCustomer, setSelectedCustomers] = useState<string[]>([]);
  const [showEmailSend, setShowEmailSend] = useState<
    "select" | "active" | "all"
  >();
  const [showSms, setShowSms] = useState<"select" | "active" | "all">();
  const columns = [
    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Surname"),
      dataField: "Surname",
    },
    {
      caption: t("Email"),
      dataField: "Email",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },

    {
      caption: t("Paket"),
      dataField: "PackageName",
    },
    {
      caption: t("Araç Ekleme Durumu"),
      dataField: "HasVehicle",
      dataType: "boolean",
    },
    {
      caption: t("Last Login Date"),
      dataField: "LastLoginDate",
      dataType: "datetime",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/1/" + e.row.key);
          },
        },
      ],
    },
  ];

  useEffect(() => {
    if (filterData) {
    } else {
    }
  }, [filterData]);
  const user = useSelector(userSelector);
  return (
    <React.Fragment>
      <CreateCustomer open={showCreate} onHide={() => setShowCreate(false)} />
      <MainPage
        devProps={{
          url: "/api/customers/cancel-devexp",
          loadParams: filterData
            ? {
                privilegeId: filterData.PrivilegeId,
                privilegeFilterType: filterData.PrivilegeFilterTypeId,
              }
            : "",
          columns: columns,
          create: () => setShowCreate(true),
          gridItems: (
            <Item>
              <div className="d-flex justify-content-start gap-2 customerGridButtons">
                <Button
                  icon={filterData ? "close" : ""}
                  text={
                    filterData ? "Hizmet Filtre Temizle" : "Hizmet Filtrele"
                  }
                  stylingMode={"contained"}
                  type={filterData ? "danger" : "success"}
                  onClick={() =>
                    !filterData ? setShowFilter(true) : setFilterData(undefined)
                  }
                />
                <Button
                  text={"Hizmet Ata"}
                  stylingMode={"contained"}
                  onClick={() => {
                    if (selectedCustomer.length > 0) {
                      setShowPrivilege(true);
                    } else {
                      toast.error("Lütfen Üye seçiniz");
                    }
                  }}
                />
                {CheckRole(user) && (
                  <>
                    <UncontrolledDropdown>
                      <DropdownToggle color="warning">
                        Sms Gönder
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li
                          onClick={() => {
                            if (selectedCustomer.length > 0) {
                              setShowSms("select");
                            } else {
                              toast.error("Lütfen Üye seçiniz");
                            }
                          }}
                        >
                          <DropdownItem>
                            Seçili olanlara Sms Gönder
                          </DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowSms("active");
                          }}
                        >
                          <DropdownItem>
                            Tüm Aktif Müşterilere Sms Gönder
                          </DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowSms("all");
                          }}
                        >
                          <DropdownItem>
                            Tüm Müşterilere Sms Gönder
                          </DropdownItem>
                        </li>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown>
                      <DropdownToggle color="info">
                        Bildirim Gönder
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li
                          onClick={() => {
                            if (selectedCustomer.length > 0) {
                              setShowNotification("select");
                            } else {
                              toast.error("Lütfen Üye seçiniz");
                            }
                          }}
                        >
                          <DropdownItem>Seçili olanlara Gönder</DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowNotification("active");
                          }}
                        >
                          <DropdownItem>
                            Tüm Aktif Müşterilere Gönder
                          </DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowNotification("all");
                          }}
                        >
                          <DropdownItem>Tüm Müşterilere Gönder</DropdownItem>
                        </li>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown color="primary">
                      <DropdownToggle className="">
                        E-Posta Gönder
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li
                          onClick={() => {
                            if (selectedCustomer.length > 0) {
                              setShowEmailSend("select");
                            } else {
                              toast.error("Lütfen Üye seçiniz");
                            }
                          }}
                        >
                          <DropdownItem>
                            Seçili olanlara E-Posta Gönder
                          </DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowEmailSend("active");
                          }}
                        >
                          <DropdownItem>
                            Tüm Aktif Müşterilere E-Posta Gönder
                          </DropdownItem>
                        </li>
                        <li
                          onClick={() => {
                            setShowEmailSend("all");
                          }}
                        >
                          <DropdownItem>
                            Tüm Müşterilere E-Posta Gönder
                          </DropdownItem>
                        </li>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </div>
            </Item>
          ),
          onSelectionChanged: e => {
            setSelectedCustomers(e.selectedRowKeys);
          },
          selectedRowKeys: selectedCustomer,
          selection: {
            mode: "multiple",
            allowSelectAll: true,
          },
        }}
        useDevGrid={true}
        title={t("Customers")}
      />
      {showEmailSend && (
        <SendEmailModal
          filterData={filterData}
          show={showEmailSend ? true : false}
          isAll={showEmailSend}
          onHide={() => setShowEmailSend(undefined)}
          customers={selectedCustomer}
          onSuccessSubmit={() => {}}
        />
      )}
      {showNotification && (
        <SendNotification
          filterData={filterData}
          show={showNotification ? true : false}
          isAll={showNotification}
          onHide={() => setShowEmailSend(undefined)}
          customers={selectedCustomer}
          onSuccessSubmit={() => {}}
        />
      )}
      {console.log(selectedCustomer, "AA")}
      {showSms && (
        <SendSms
          filterData={filterData}
          show={showSms ? true : false}
          isAll={showSms}
          onHide={() => setShowSms(undefined)}
          customers={selectedCustomer}
          onSuccessSubmit={() => {}}
        />
      )}
      <PrivilegeFilterComp
        open={showFilter}
        setData={setFilterData}
        onHide={() => setShowFilter(false)}
      />
      <CreateCustomerPrivilegeForMultiple
        filterData={filterData}
        open={showPrivilege}
        onHide={() => setShowPrivilege(false)}
        customers={selectedCustomer}
        onSuccessClick={() => {}}
      />
    </React.Fragment>
  );
};

export default CancelledCustomerPage;
