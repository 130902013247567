import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SupplierDocumentDto } from "./type";

export const getSupplierDocumentListBySupplierId = createAction(
  "GET_SUPPLIER_DOCUMENT_LIST_BY_SUPPLIER_ID",
  ({ payload, params, id }: SagaActionParams<SupplierDocumentDto[]>) => ({
    payload,
    url:
      "/api/supplierdocuments/getbysupplierid/" + id + (params ? params : ""),
  })
);
export const getSupplierDocumentList = createAction(
  "GET_SUPPLIER_DOCUMENT_LIST",
  ({ payload, params }: SagaActionParams<SupplierDocumentDto[]>) => ({
    payload,
    url: "/api/supplierdocuments" + (params ? params : ""),
  })
);
export const getSupplierDocumentDetails = createAction(
  "GET_SUPPLIER_DOCUMENT_DETAILS",
  ({ payload, id }: SagaActionParams<SupplierDocumentDto>) => ({
    payload,
    url: "/api/supplierdocuments/" + id,
  })
);
export const createSupplierDocument = createAction(
  "CREATE_SUPPLIER_DOCUMENT_DETAILS",
  ({ payload, body }: SagaActionParams<SupplierDocumentDto>) => ({
    payload,
    url: "/api/supplierdocuments",
    body,
  })
);
export const updateSupplierDocument = createAction(
  "UPDATE_SUPPLIER_DOCUMENT_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierDocumentDto>) => ({
    payload,
    url: "/api/supplierdocuments/" + id,
    body,
  })
);
export const deleteSupplierDocument = createAction(
  "DELETE_SUPPLIER_DOCUMENT_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierDocumentDto>) => ({
    payload,
    url: "/api/supplierdocuments/" + id,
    body,
  })
);
const supplierdocumentSaga = [
  takeLatest(
    getSupplierDocumentListBySupplierId.toString(),
    (payload: SagaGenericParams<SupplierDocumentDto[]>) =>
      getListSaga<SupplierDocumentDto[]>(payload)
  ),
  takeLatest(
    getSupplierDocumentList.toString(),
    (payload: SagaGenericParams<SupplierDocumentDto[]>) =>
      getListSaga<SupplierDocumentDto[]>(payload)
  ),
  takeLatest(
    getSupplierDocumentDetails.toString(),
    (payload: SagaGenericParams<SupplierDocumentDto>) =>
      getListSaga<SupplierDocumentDto>(payload)
  ),
  takeLatest(
    createSupplierDocument.toString(),
    (payload: PostSagaGenericParams<SupplierDocumentDto>) =>
      postSaga<SupplierDocumentDto>(payload)
  ),
  takeLatest(
    updateSupplierDocument.toString(),
    (payload: PostSagaGenericParams<SupplierDocumentDto>) =>
      putSaga<SupplierDocumentDto>(payload)
  ),
  takeLatest(
    deleteSupplierDocument.toString(),
    (payload: SagaGenericParams<SupplierDocumentDto>) =>
      deleteSaga<SupplierDocumentDto>(payload)
  ),
];

export default supplierdocumentSaga;
