import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PackageDto } from "src/store/packages/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CreatePackage from "./create";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import {
  changePackageOrder,
  deletePackage,
  getPackageList,
} from "src/store/packages/saga";
import { useHistory } from "react-router";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";

const PackagePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<PackageDto[]>([]);
  const [showDelete, setShowDelete] = useState<PackageDto>();
  useEffect(() => {
    dispatch(
      getPackageList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  const user = useSelector(userSelector);
  const columns = [
    {
      text: t("Order"),
      dataField: "Order",
    },

    {
      text: t("Package Name"),
      dataField: "PackageName",
    },

    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="details2"
            onClick={() => {
              return history.push("/packages/details/1/" + cellContent);
            }}
          />

          {CheckRole(user) && (
            <ColumnButton
              buttonType="delete"
              onClick={() => {
                return setShowDelete(data);
              }}
            />
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPackageList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      <CreatePackage
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />

      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changePackageOrder}
        title={t("PackageTitle")}
        create={{
          createType: "drawer",
          onCreateClick: () => setShowCreate(true),
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete package`, {
            name: `${showDelete.PackageName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePackage({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PackagePage;
