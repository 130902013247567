import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CorporateCompanyDto, CorporateCompanyLookupDto } from "./type";

export const getCorporateCompanyLookup = createAction(
  "GET_CORPORATE_COMPANYS_LOOKUP",
  ({ payload, id }: SagaActionParams<CorporateCompanyLookupDto[]>) => ({
    payload,
    url: "/api/CorporateCompanies/lookup/" + id,
  })
);
export const getCorporateCompanies = createAction(
  "GET_CORPORATE_COMPANYS",
  ({ payload, params }: SagaActionParams<CorporateCompanyDto[]>) => ({
    payload,
    url: "/api/CorporateCompanies" + (params ? params : ""),
  })
);
export const getCorporateCompaniesDetails = createAction(
  "GET_CORPORATE_COMPANYS_DETAILS",
  ({ payload, id }: SagaActionParams<CorporateCompanyDto>) => ({
    payload,
    url: "/api/CorporateCompanies/" + id,
  })
);
export const createCorporateCompanies = createAction(
  "CREATE_CORPORATE_COMPANYS",
  ({ payload, body }: SagaActionParams<CorporateCompanyDto>) => ({
    payload,
    url: "/api/CorporateCompanies",
    body,
  })
);
export const updateCorporateCompanies = createAction(
  "UPDATE_CORPORATE_COMPANYS",
  ({ payload, id, body }: SagaActionParams<CorporateCompanyDto>) => ({
    payload,
    url: "/api/CorporateCompanies/" + id,
    body,
  })
);
export const deleteCorporateCompanies = createAction(
  "DELETE_CORPORATE_COMPANYS",
  ({ payload, id }: SagaActionParams<CorporateCompanyDto>) => ({
    payload,
    url: "/api/CorporateCompanies/" + id,
  })
);
const CorporateCompaniesSaga = [
  takeLatest(
    getCorporateCompanies.toString(),
    (payload: SagaGenericParams<CorporateCompanyDto[]>) =>
      getListSaga<CorporateCompanyDto[]>(payload)
  ),
  takeLatest(
    getCorporateCompanyLookup.toString(),
    (payload: SagaGenericParams<CorporateCompanyLookupDto[]>) =>
      getListSaga<CorporateCompanyLookupDto[]>(payload)
  ),
  takeLatest(
    getCorporateCompaniesDetails.toString(),
    (payload: SagaGenericParams<CorporateCompanyDto>) =>
      getListSaga<CorporateCompanyDto>(payload)
  ),
  takeLatest(
    createCorporateCompanies.toString(),
    (payload: PostSagaGenericParams<CorporateCompanyDto>) =>
      postSaga<CorporateCompanyDto>(payload)
  ),
  takeLatest(
    updateCorporateCompanies.toString(),
    (payload: PostSagaGenericParams<CorporateCompanyDto>) =>
      putSaga<CorporateCompanyDto>(payload)
  ),
  takeLatest(
    deleteCorporateCompanies.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CorporateCompanyDto>(payload)
  ),
];

export default CorporateCompaniesSaga;
