import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreateManager from "./create";
import { toast } from "react-toastify";
import EditManager from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  deleteUser,
  getUserDetails,
  getUserList,
  getUserRoleList,
} from "src/store/user/saga";
import { UserDto } from "src/store/user/type";
import RoleEditModal from "./roleedit";
import { UserConsts } from "src/store/user/consts";

const Users = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<UserDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<UserDto[]>([]);
  const [showDelete, setShowDelete] = useState<UserDto>();
  const [showRole, setShowRole] = useState<boolean>(false);

  const [roles, setRoles] = useState<string[]>();
  const columns = [
    {
      text: t("Name"),
      dataField: "Name",
    },
    {
      text: t("Surname"),
      dataField: "Surname",
    },
    {
      text: t("Department"),
      dataField: "DepartmentName",
    },
    {
      text: t("Email"),
      dataField: "Email",
    },
    {
      text: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {cellContent !== UserConsts.VodatechUser && (
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                text: "Roller",
              }}
              onClick={() => {
                dispatch(
                  getUserRoleList({
                    payload: {
                      onSuccess: (msg, payload) => {
                        setRoles(payload ? payload : []);
                        setSelected(data);
                        return setShowRole(true);
                      },
                      onError: () => {},
                    },
                    id: data.Id,
                  })
                );
              }}
            />
          )}

          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getUserDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          {cellContent !== UserConsts.VodatechUser && (
            <ColumnButton
              buttonType="delete"
              onClick={() => {
                return setShowDelete(data);
              }}
            />
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getUserList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      {roles && selected && (
        <RoleEditModal
          id={selected.Id}
          roles={roles}
          onHide={() => setShowRole(false)}
          open={showRole}
          onSuccessClick={onSuccessClick}
        />
      )}
      {selected && (
        <EditManager
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateManager
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <MainPage
        title={t("Kullanıcı Listesi")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          },
          setDataList: setData,
          action: getUserList,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete user`, {
            name: `${showDelete.Name} ${showDelete.Surname}`,
          })}
          onConfirm={() => {
            dispatch(
              deleteUser({
                payload: {
                  onSuccess: message => {
                    onSuccessClick(message);
                    return setShowDelete(undefined);
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
