import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
import { CustomerStatusContainer } from "../Customers/Details/detailCard";

const TransactionReportPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = [
    {
      caption: t("Üyelik Durumu"),
      dataField: "CustomerStatusName",
      cellRender: (e: any) => {
        return (
          <>
            {CustomerStatusContainer(
              e.row.data.CustomerStatusId,
              e.row.data.CustomerStatusName
            )}
          </>
        );
      },
    },
    {
      caption: t("Hesap Silme Durumu"),
      dataField: "CustomerIsDeleted",
    },
    {
      caption: t("Müşteri Adı"),
      dataField: "CustomerName",
    },
    {
      caption: t("Müşteri Soyadı"),
      dataField: "CustomerSurname",
    },
    {
      caption: t("Tahsilat Tipi"),
      dataField: "Type",
    },
    {
      caption: t("Tutar"),
      dataField: "Amount",
      dataType: "number",
    },
    {
      caption: t("Tamamlandı"),
      dataField: "Completed",
      dataType: "boolean",
    },
    {
      caption: t("İşlem Tarihi"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
    {
      caption: t("Hata"),
      dataField: "Error",
      dataType: "boolean",
    },
    {
      caption: t("Başarısız Hata Açıklaması"),
      dataField: "ErrorMessage",
    },

    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Müşteri",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/6/" + e.row.data.CustomerId);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/reports/payment-transactions",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Ödeme Hareketleri")}
      />
    </React.Fragment>
  );
};

export default TransactionReportPage;
