import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";
import {
  changeDataBankOrder,
  getDataBankListByCategoryId,
} from "src/store/databank/saga";
import { DataBankDto } from "src/store/databank/type";

const DatabankOrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<DataBankDto[]>([]);

  const columns = [
    {
      text: t("Question"),
      dataField: "Question",
      formatter: (cellContent: string) => (
        <div dangerouslySetInnerHTML={{ __html: cellContent }}></div>
      ),
    },
    {
      text: t("CategoryName"),
      dataField: "DataBankCategoryName",
    },
  ];
  useEffect(() => {
    dispatch(
      getDataBankListByCategoryId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getDataBankListByCategoryId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changeDataBankOrder}
        title={t("DataBankTitle")}
      />
    </React.Fragment>
  );
};

export default DatabankOrderPage;
