import React, { useEffect, useState } from "react";
import { CustomerDetailDto } from "src/store/customers/type";
import { Alert, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { userSelector } from "src/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { CheckRole } from "src/permissions/checkPermission";
import SendNotification from "../sendNotificationModal";
import CreateCustomerPackage from "./createPackage";
import SendEmailModal from "../sendEmailModal";
import SendSms from "../sendSms";
import UpdateToHonorary from "./btn-updateToHonorary";
import CancelMemberShip from "./btn-cancel-membership";
import { MembershipcancelRequestDto } from "src/store/membership-cancel-request/type";
import { getMembershipcancelrequestListByCustomerId } from "src/store/membership-cancel-request/saga";
import ChangeCustomerGroupModal from "./btn-change-group";
import SendEmailConfirmation from "./send-emailconfirm";
import PayCustomerPackage from "./btn-payPackage";

const CustomerDetailCard = ({
  customerData,
  onSuccessSubmit,
}: {
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [cancelRequest, setCancelRequest] = useState<
    MembershipcancelRequestDto[]
  >([]);
  useEffect(() => {
    dispatch(
      getMembershipcancelrequestListByCustomerId({
        payload: {
          onSuccess: (message, payload) => {
            setCancelRequest(payload);
          },
          onError: () => {},
        },
        id: customerData.Id,
      })
    );
  }, [customerData, dispatch]);
  const fields = [
    {
      text: "Durum",
      value: (
        <>
          {customerData.StatusId === 2 && CheckRole(user) ? (
            <PayCustomerPackage
              customerData={customerData}
              onSuccessSubmit={onSuccessSubmit}
            />
          ) : (
            <>
              {CustomerStatusContainer(
                customerData.StatusId,
                customerData.StatusName
              )}
            </>
          )}
        </>
      ),
      type: "element",
      col: 12,
    },
    {
      text: "Üye Grubu",
      value: <>{customerData.CustomerGroupName ?? "-"}</>,
      type: "element",
      col: 12,
    },
    {
      text: "Ad Soyad",
      value: `${customerData.Name} ${customerData.Surname}`,
    },
    {
      text: "Son Giriş Tarihi",
      value: customerData.LastLoginDate
        ? moment(customerData.LastLoginDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      text: "Telefon",
      value: customerData.PhoneNumber ?? "-",
    },
    {
      text: "Email",
      value: customerData.Email ?? "-",
    },
    {
      text: "Doğum Yeri",
      value: customerData.BirthPlace ?? "-",
    },

    {
      text: "Paket Adı",
      value: customerData.ActivePackage
        ? customerData.ActivePackage.PackageName
        : "-",
    },
    {
      text: "Doğum Tarihi",
      value: customerData.BirthDate
        ? moment(customerData.BirthDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },

    {
      text: "Paket Başlangıç",
      value: customerData.ActivePackage
        ? moment(customerData.ActivePackage.StartDate).format(
            "DD/MM/yyyy HH:mm"
          )
        : "-",
    },
    {
      text: "Meslek",
      value: customerData.Job ?? "-",
    },
    {
      text: "Paket Bitiş",
      value: customerData.ActivePackage
        ? moment(customerData.ActivePackage.EndDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      text: "Eğitim Durumu",
      value: customerData.EducationStatus ?? "-",
    },
    {
      text: "Paket Tutar",
      value: customerData.ActivePackage
        ? customerData.ActivePackage.Price
        : "-",
    },
    {
      text: "Cinsiyet",
      value: customerData.GenderName ?? "-",
    },
    {
      text: "Müşteri Tipi",
      value: customerData.CustomerTypeName ?? "-",
    },
    {
      text: "Kimlik Numarası",
      value: customerData.IdentityNumber ?? "-",
    },
  ];
  return (
    <React.Fragment>
      {customerData && (
        <>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Row style={{ minWidth: 750 }}>
                    {customerData.StatusId === 3 &&
                      cancelRequest.length > 0 && (
                        <>{GetMessage(cancelRequest[0])}</>
                      )}
                    {fields.map((x, i) => (
                      <Col md={x.col ? x.col : 6}>
                        <div className="d-flex gap-3 mb-2">
                          <p className="mb-0 fw-bold" style={{ minWidth: 105 }}>
                            {x.text}
                          </p>
                          <div>
                            {x.type === "element" ? (
                              x.value
                            ) : (
                              <input
                                readOnly
                                value={x.value}
                                className="readonlyInput"
                                style={{ minWidth: 250 }}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="py-2">
              <CustomerHeader
                customerData={customerData}
                cancelRequest={cancelRequest}
                onSuccessSubmit={onSuccessSubmit}
              />
            </CardFooter>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};
export default CustomerDetailCard;

export const CustomerHeader = ({
  customerData,
  onSuccessSubmit,
  cancelRequest,
}: {
  cancelRequest: MembershipcancelRequestDto[];
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const [show, setShow] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showsms, setShowsms] = useState(false);
  const [showCreatePackage, setShowCreatePackage] = useState(false);
  return (
    <Row>
      {CheckRole(user) && (
        <div className="d-flex justify-content-start flex-wrap flex-row gap-2">
          <div>
            <CancelMemberShip
              cancelRequest={cancelRequest}
              customerData={customerData}
              onSuccessSubmit={onSuccessSubmit}
            />
          </div>
          <div>
            <UpdateToHonorary
              customerData={customerData}
              onSuccessSubmit={onSuccessSubmit}
            />
          </div>
          <div>
            <button
              style={{ minWidth: 150 }}
              className="btn-sm btn btn-soft-info  mt-2"
              onClick={() => setShow(true)}
            >
              {t("Send Nofitification")}
            </button>
            <SendNotification
              isAll={"select"}
              show={show}
              onHide={() => setShow(false)}
              customers={[customerData.Id]}
            />
          </div>
          <div>
            <button
              style={{ minWidth: 150 }}
              className="btn-sm btn btn-soft-warning text-dark  mt-2"
              onClick={() => setShowsms(true)}
            >
              {t("Sms Gönder")}
            </button>
            <SendSms
              show={showsms}
              onHide={() => setShowsms(false)}
              isAll={"select"}
              customers={[customerData.Id]}
            />
          </div>
          <div>
            <button
              style={{ minWidth: 150 }}
              className="btn-sm btn btn-soft-light  mt-2"
              onClick={() => setShowEmail(true)}
            >
              {t("E-posta Gönder")}
            </button>
            <SendEmailModal
              isAll={"select"}
              show={showEmail}
              onHide={() => setShowEmail(false)}
              customers={[customerData.Id]}
              onSuccessSubmit={() => {}}
            />
          </div>
          <div>
            <button
              style={{ minWidth: 150 }}
              className="btn-sm btn btn-soft-success  mt-2"
              onClick={() => setShowCreatePackage(true)}
            >
              {t("Paket Ata")}
            </button>
            <CreateCustomerPackage
              open={showCreatePackage}
              onHide={() => setShowCreatePackage(false)}
              customer={customerData}
            />
          </div>
          <div>
            <ChangeCustomerGroupModal
              customerData={customerData}
              onSuccessSubmit={onSuccessSubmit}
            />
          </div>
          {!customerData.EmailConfirmed && (
            <div>
              <SendEmailConfirmation
                customerData={customerData}
                onSuccessSubmit={onSuccessSubmit}
              />
            </div>
          )}
        </div>
      )}
    </Row>
  );
};

export const CustomerStatusContainer = (
  statusId: number,
  statusName: string
) => {
  switch (statusId) {
    case 1:
      return <div className="bg-success badge">{statusName}</div>;
    case 2:
      return <div className="bg-warning badge">{statusName}</div>;
    case 3:
      return <div className="bg-danger badge">{statusName}</div>;
    case 4:
      return <div className="bg-secondary badge">{statusName}</div>;
    case 5:
      return <div className="bg-info badge">{statusName}</div>;
    case 6:
      return <div className="bg-danger badge">{statusName}</div>;
  }
};
export const GetMessage = (cancelRequest: MembershipcancelRequestDto) => {
  var message = "";

  switch (cancelRequest.MembershipCancellationRequestStatusId) {
    case 1:
      message = `Üyelik sonlandırma talebinde bulunmuştur.`;
      break;
    case 2:
      message = `Üyelik sonlandırma talebi iptal edilmiştir.`;
      break;
    case 3:
      message = `Üyelik sonlandırma talebi gerçekleşmiştir.`;
      break;
  }

  return (
    <Alert
      color={
        cancelRequest.MembershipCancellationRequestStatusId === 1
          ? "warning"
          : "danger"
      }
    >
      {message}{" "}
      {cancelRequest.MembershipCancellationRequestReasons.map((x, i) => (
        <span>
          {x.MembershipCancelTypeName} {x.Message && `(${x.Message})`}
          {i !==
            cancelRequest.MembershipCancellationRequestReasons.length - 1 &&
            ", "}
        </span>
      ))}
      {cancelRequest.CustomerPurchaseTransaction && (
        <>
          <br />
          <span>
            Üyelik iptali için ödenen tutar:{" "}
            {new Intl.NumberFormat("tr-TR", {
              style: "currency",
              currency: "TRY",
            }).format(cancelRequest.CustomerPurchaseTransaction.Amount)}
          </span>
        </>
      )}
    </Alert>
  );
};
