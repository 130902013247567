import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { MembershipcanceltypeDto } from "./type";

export const getMembershipcanceltypeList = createAction(
  "GET_MEMBERSHIPCANCELTYPE_LIST",
  ({ payload, params }: SagaActionParams<MembershipcanceltypeDto[]>) => ({
    payload,
    url: "/api/membershipcanceltypes" + (params ? params : ""),
  })
);
export const getMembershipcanceltypeDetails = createAction(
  "GET_MEMBERSHIPCANCELTYPE_DETAILS",
  ({ payload, id }: SagaActionParams<MembershipcanceltypeDto>) => ({
    payload,
    url: "/api/membershipcanceltypes/" + id,
  })
);
export const createMembershipcanceltype = createAction(
  "CREATE_MEMBERSHIPCANCELTYPE_DETAILS",
  ({ payload, body }: SagaActionParams<MembershipcanceltypeDto>) => ({
    payload,
    url: "/api/membershipcanceltypes",
    body,
  })
);
export const updateMembershipcanceltype = createAction(
  "UPDATE_MEMBERSHIPCANCELTYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<MembershipcanceltypeDto>) => ({
    payload,
    url: "/api/membershipcanceltypes/" + id,
    body,
  })
);
export const deleteMembershipcanceltype = createAction(
  "DELETE_MEMBERSHIPCANCELTYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<MembershipcanceltypeDto>) => ({
    payload,
    url: "/api/membershipcanceltypes/" + id,
    body,
  })
);

const MembershipcanceltypeSaga = [
  takeLatest(
    getMembershipcanceltypeList.toString(),
    (payload: SagaGenericParams<MembershipcanceltypeDto[]>) =>
      getListSaga<MembershipcanceltypeDto[]>(payload)
  ),
  takeLatest(
    getMembershipcanceltypeDetails.toString(),
    (payload: SagaGenericParams<MembershipcanceltypeDto>) =>
      getListSaga<MembershipcanceltypeDto>(payload)
  ),
  takeLatest(
    createMembershipcanceltype.toString(),
    (payload: PostSagaGenericParams<MembershipcanceltypeDto>) =>
      postSaga<MembershipcanceltypeDto>(payload)
  ),

  takeLatest(
    updateMembershipcanceltype.toString(),
    (payload: PostSagaGenericParams<MembershipcanceltypeDto>) =>
      putSaga<MembershipcanceltypeDto>(payload)
  ),
  takeLatest(
    deleteMembershipcanceltype.toString(),
    (payload: SagaGenericParams<MembershipcanceltypeDto>) =>
      deleteSaga<MembershipcanceltypeDto>(payload)
  ),
];

export default MembershipcanceltypeSaga;
