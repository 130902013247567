import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { TireSupplierDto } from "src/store/tires/suppliers/type";
import * as Yup from "yup";

const DetailTireSupplier = ({
  data,
  onHide,
  open,
}: {
  data: TireSupplierDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Lastik Tedarikçi Detay"),
        }}
        hideButtons={true}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name ?? "",
          Address: data.Address ?? "",
          Latitude: data.Latitude ?? "",
          Longitude: data.Longitude ?? "",
          AuthorizedPersonName: data.AuthorizedPersonName ?? "",
          AuthorizedPersonSurname: data.AuthorizedPersonSurname ?? "",
          PhoneNumber: data.PhoneNumber ?? "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {}}
        inputs={[
          {
            disabled: () => true,
            field: "Name",
            label: t("Name"),
          },
          {
            disabled: () => true,
            field: "AuthorizedPersonName",
            label: t("Authorized PersonName"),
          },
          {
            disabled: () => true,
            field: "AuthorizedPersonSurname",
            label: t("Authorized PersonSurname"),
          },
          {
            disabled: () => true,
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            disabled: () => true,
            field: "Address",
            label: t("Address"),
            inputType: InputType.multilinetext,
          },
          {
            disabled: () => true,
            field: "",
            label: t(""),
            googlemap: {
              latField: "Latitude",
              lonField: "Longitude",
            },
            inputType: InputType.googlemap,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default DetailTireSupplier;
