import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  getPhoneNumberTypeList,
  updatePhoneNumber,
} from "src/store/phoneNumbers/saga";
import {
  PhoneNumberDto,
  PhoneNumberTypeDto,
} from "src/store/phoneNumbers/type";
import * as Yup from "yup";

const EditPhoneNumber = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PhoneNumberDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [types, setTypes] = useState<PhoneNumberTypeDto[]>([]);
  useEffect(() => {
    open &&
      dispatch(
        getPhoneNumberTypeList({
          payload: {
            onSuccess: (msg, py) => setTypes(py),
            onError: () => {},
          },
        })
      );
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Telefon Numarası Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Number: data.Number,
          PhoneNumberTypeId: data.PhoneNumberTypeId,
        }}
        onSubmit={values => {
          dispatch(
            updatePhoneNumber({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PhoneNumberTypeId: Yup.number().required(t("Required")),
          Number: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PhoneNumberTypeId",
            inputType: InputType.multiselect,
            label: t("Telefon Numarası Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Telefon Numarası Tipi Seçiniz"),
            },
          },
          {
            field: "Number",
            label: t("Telefon Numarası"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPhoneNumber;
