import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  TicketAnswerDocumentDto,
  TicketAnswerDto,
  TicketChannelDto,
  TicketDetailDto,
  TicketDto,
  TicketHistoryDto,
  TicketLevelDto,
  TicketStatusDto,
  TicketType,
} from "./type";
import { BodyType } from "../base/request/request";
export const getTicketsByCustomerId = createAction(
  "GET_TICKETS_BY_CUSTOMER_ID",
  ({ payload, id }: SagaActionParams<TicketDto[]>) => ({
    payload,
    url: "/api/tickets/bycustomerid/" + id,
  })
);
export const GetTicketDetailsByEntityId = createAction(
  "GET_DASHBOARD_TICKETS_DETAILS_ENTITY_ID",
  ({ payload, id }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url: "/api/tickets/byentityId/" + id,
  })
);
export const getClosedTickets = createAction(
  "GET_CLOSED_TICKET",
  ({ payload, url }: SagaActionParams<TicketDto[]>) => ({
    payload,
    url: url ? url : "/api/tickets/closed/list",
  })
);

export const getOpenedTickets = createAction(
  "GET_OPENED_TICKET",
  ({ payload, url }: SagaActionParams<TicketDto[]>) => ({
    payload,
    url: url ? url : "/api/tickets/opened/list",
  })
);
export const getTickets = createAction(
  "GET_TICKET",
  ({ payload }: SagaActionParams<TicketDto[]>) => ({
    payload,
    url: "/api/tickets",
  })
);
export const getTicketTypes = createAction(
  "GET_TICKET_TYPES",
  ({ payload }: SagaActionParams<TicketType[]>) => ({
    payload,
    url: "/api/system/tickettypes",
  })
);
export const getTicketStatusType = createAction(
  "GET_TICKET_STATUS_TYPE",
  ({ payload }: SagaActionParams<TicketType[]>) => ({
    payload,
    url: "/api/tickets/get-ticket-status-types",
  })
);
export const getTicketStatusTypeWithCount = createAction(
  "GET_TICKET_STATUS_TYPE_WITH_COUNT",
  ({ payload, id }: SagaActionParams<TicketStatusDto[]>) => ({
    payload,
    url: "/api/tickets/ticket-status-with-count/" + id,
  })
);
export const postTicket = createAction(
  "POST_TICKET",
  ({ payload, body }: SagaActionParams<TicketDto>) => ({
    payload,
    url: "/api/tickets",
    body,
  })
);
export const updateTicketStatus = createAction(
  "UPDATE_TICKET_STATUS",
  ({ payload, body }: SagaActionParams<TicketDto>) => ({
    payload,
    url: "/api/tickets/update-status",
    body,
  })
);
export const sendTicketAnswer = createAction(
  "SEND_TICKET_ANSWER",
  ({ payload, id, body }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url: "/api/tickets/post-ticket-answer/" + id,
    body,
  })
);

export const createTicket = createAction(
  "CREATE_TICKET",
  ({ payload, body }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    body,
    url: "/api/tickets/post-ticket",
  })
);
export const createCallLogTicket = createAction(
  "CREATE_CALLLOG_TICKET",
  ({ payload, body }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    body,
    url: "/api/tickets/calllog",
  })
);
export const createInvalidCallLogTicket = createAction(
  "CREATE_INVALID_CALLLOG_TICKET",
  ({ payload, body }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    body,
    url: "/api/tickets/invalid-calllog",
  })
);
export const getTicketDetails = createAction(
  "GET_TICKET_DETAILS",
  ({ payload, id }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url: "/api/tickets/" + id,
  })
);
export const getTicketDetailsWithCode = createAction(
  "GET_TICKET_DETAILS_WITH_CODE",
  ({ payload, id }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url: "/api/tickets/detailswithcode/" + id,
  })
);
export const deleteTicket = createAction(
  "DELETE_TICKET",
  ({ payload, url }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url,
  })
);
export const getTicketAnswers = createAction(
  "GET_TICKET_ANSWERS",
  ({ payload, id }: SagaActionParams<TicketAnswerDto[]>) => ({
    payload,
    url: "/api/tickets/get-ticket-answers/" + id,
  })
);
export const updatetoSolvedTicket = createAction(
  "UPDATE_TO_SOLVED",
  ({ payload, id }: SagaActionParams<TicketDetailDto>) => ({
    payload,
    url: "/api/tickets/update-to-solved/" + id,
  })
);

export const getFilteredTicketList = createAction(
  "GET_FILTERED_TICKET_LIST",
  ({ payload, params, id }: SagaActionParams<TicketDto[]>) => ({
    payload,

    url: "/api/tickets/filtered-list/" + id + (params ? params : ""),
  })
);

//CLOSED
export const updateStatusToClosedMultiple = createAction(
  "UPDATE_STATUS_TO_CLOSED_MULTIPLE",
  ({ payload, body }: SagaActionParams<TicketDto[]>) => ({
    payload,
    body,
    url: "/api/tickets/update-to-closed-multiple",
    bodyType: BodyType.raw,
  })
);
export const updateAllTicketStatusToClosed = createAction(
  "UPDATE_ALL_STATUS_TO_CLOSED_MULTIPLE",
  ({ payload, body }: SagaActionParams<TicketDto[]>) => ({
    payload,
    url: "/api/tickets/update-all-to-closed",
    body,
    bodyType: BodyType.raw,
  })
);

//PROGRESS
export const updateStatusToProgressMultiple = createAction(
  "UPDATE_STATUS_TO_PROGRESS_MULTIPLE",
  ({ payload, body }: PostSagaGenericParams<TicketDto[]>) => ({
    payload,
    body,
    url: "/api/tickets/update-to-progress-multiple",
    bodyType: BodyType.raw,
  })
);
export const updateAllTicketStatusToProgress = createAction(
  "UPDATE_STATUS_TO_PROGRESS_ALL",
  ({ payload, body }: PostSagaGenericParams<TicketDto[]>) => ({
    payload,
    url: "/api/tickets/update-all-to-progress",
    body,
    bodyType: BodyType.raw,
  })
);

//ASSIGN
export const assignedTicketUser = createAction(
  "ASSIGNED_TICKET_TO_CUSTOMER",
  ({ payload, body }: SagaActionParams<TicketDto[]>) => ({
    payload,
    body,
    url: "/api/tickets/assigned-to-users",
    bodyType: BodyType.raw,
  })
);
export const getTicketFiles = createAction(
  "GET-TICKET_FILES",
  ({ payload, id }: SagaActionParams<TicketAnswerDocumentDto[]>) => ({
    payload,
    url: "/api/tickets/ticket-files/" + id,
  })
);
export const getTicketHistories = createAction(
  "GET-TICKET_HISTORIES",
  ({ payload, id }: SagaActionParams<TicketHistoryDto[]>) => ({
    payload,
    url: "/api/tickets/histories/" + id,
  })
);
export const getTicketLevels = createAction(
  "GET-TICKET_LEVELS",
  ({ payload }: SagaActionParams<TicketLevelDto[]>) => ({
    payload,
    url: "/api/tickets/ticket-levels",
  })
);

export const getTicketChannels = createAction(
  "GET_TICKET_CHANNELS",
  ({ payload }: SagaActionParams<TicketChannelDto[]>) => ({
    payload,
    url: "/api/tickets/ticket-channels",
  })
);
export const createCalllogNote = createAction(
  "CREATE_NOTE",
  ({ payload, body }: SagaActionParams<TicketDto>) => ({
    payload,
    body,
    url: "/api/tickets/note",
    bodyType: BodyType.raw,
  })
);
const ticketSagas = [
  takeLatest(
    createTicket.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      postSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    createCallLogTicket.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      postSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    createInvalidCallLogTicket.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      postSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    updatetoSolvedTicket.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      getListSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    getFilteredTicketList.toString(),
    (payload: PostSagaGenericParams<TicketDto[]>) =>
      getListSaga<TicketDto[]>(payload)
  ),
  takeLatest(
    getTicketsByCustomerId.toString(),
    (payload: PostSagaGenericParams<TicketDto[]>) =>
      getListSaga<TicketDto[]>(payload)
  ),
  takeLatest(
    GetTicketDetailsByEntityId.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      getListSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    getTicketAnswers.toString(),
    (payload: SagaGenericParams<TicketAnswerDto[]>) =>
      getListSaga<TicketAnswerDto[]>(payload)
  ),
  takeLatest(getTickets.toString(), (payload: SagaGenericParams<TicketDto[]>) =>
    getListSaga<TicketDto[]>(payload)
  ),
  takeLatest(
    getTicketTypes.toString(),
    (payload: SagaGenericParams<TicketType[]>) =>
      getListSaga<TicketType[]>(payload)
  ),
  takeLatest(
    getTicketStatusTypeWithCount.toString(),
    (payload: SagaGenericParams<TicketType[]>) =>
      getListSaga<TicketType[]>(payload)
  ),
  takeLatest(
    getTicketStatusType.toString(),
    (payload: SagaGenericParams<TicketType[]>) =>
      getListSaga<TicketType[]>(payload)
  ),
  takeLatest(
    updateTicketStatus.toString(),
    (payload: PostSagaGenericParams<TicketDetailDto>) =>
      postSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    getTicketDetails.toString(),
    (payload: SagaGenericParams<TicketDetailDto>) =>
      getListSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    getClosedTickets.toString(),
    (payload: SagaGenericParams<TicketDto[]>) =>
      getListSaga<TicketDto[]>(payload)
  ),
  takeLatest(
    getOpenedTickets.toString(),
    (payload: SagaGenericParams<TicketDto[]>) =>
      getListSaga<TicketDto[]>(payload)
  ),
  takeLatest(
    getTicketDetailsWithCode.toString(),
    (payload: SagaGenericParams<TicketDetailDto>) =>
      getListSaga<TicketDetailDto>(payload)
  ),
  takeLatest(
    postTicket.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => postSaga<TicketDto>(payload)
  ),
  takeLatest(
    sendTicketAnswer.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => postSaga<TicketDto>(payload)
  ),
  takeLatest(deleteTicket.toString(), (payload: SagaGenericParams<TicketDto>) =>
    deleteSaga<TicketDto>(payload)
  ),

  takeLatest(
    updateStatusToClosedMultiple.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => putSaga<TicketDto>(payload)
  ),
  takeLatest(
    updateAllTicketStatusToClosed.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => putSaga<TicketDto>(payload)
  ),

  takeLatest(
    updateStatusToProgressMultiple.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => putSaga<TicketDto>(payload)
  ),
  takeLatest(
    updateAllTicketStatusToProgress.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => putSaga<TicketDto>(payload)
  ),

  takeLatest(
    assignedTicketUser.toString(),
    (payload: PostSagaGenericParams<TicketDto>) => putSaga<TicketDto>(payload)
  ),
  takeLatest(
    getTicketFiles.toString(),
    (payload: PostSagaGenericParams<TicketAnswerDocumentDto[]>) =>
      getListSaga<TicketAnswerDocumentDto[]>(payload)
  ),
  takeLatest(
    getTicketHistories.toString(),
    (payload: PostSagaGenericParams<TicketHistoryDto[]>) =>
      getListSaga<TicketHistoryDto[]>(payload)
  ),
  takeLatest(
    getTicketLevels.toString(),
    (payload: PostSagaGenericParams<TicketLevelDto[]>) =>
      getListSaga<TicketLevelDto[]>(payload)
  ),
  takeLatest(
    getTicketChannels.toString(),
    (payload: PostSagaGenericParams<TicketChannelDto[]>) =>
      getListSaga<TicketChannelDto[]>(payload)
  ),
  takeLatest(
    createCalllogNote.toString(),
    (payload: PostSagaGenericParams<TicketChannelDto[]>) =>
      postSaga<TicketChannelDto[]>(payload)
  ),
];
export default ticketSagas;
