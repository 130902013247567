import moment from "moment";
import "moment/locale/tr";
import ReactApexChart from "react-apexcharts";
import { DashboardTransactionDto } from "src/store/dashboard/type";

const LineApexChart = ({ data }: { data: DashboardTransactionDto }) => {
  const series = [
    {
      name: "Hatalı Ödemeler",
      data: data.ErrorChart,
    },
    {
      name: "Başarılı Ödemeler",
      data: data.SuccessChart,
    },
  ];
  const options: Object = {
    chart: {
      foreColor: "red",
    },

    colors: ["#fd625e", "#34c38f"],
    dataLabels: { enabled: !0 },
    stroke: { width: [3, 3], curve: "straight" },
    title: { text: "Ödeme Tutarı", align: "left" },
    grid: {
      row: { colors: ["transparent", "transparent"], opacity: 0.2 },
      borderColor: "#f1f1f1",
    },
    markers: { style: "inverted", size: 6 },
    xaxis: {
      categories: data.ChartCategories.map(x => {
        return moment(x).format("Do MMM YY");
      }),
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: !0,
      offsetY: -15,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="450"
    />
  );
};

export default LineApexChart;
