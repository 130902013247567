import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CreateCustomerPrivilege from "./create";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import { useHistory, useParams } from "react-router";
import {
  CustomerPrivilegeDto,
  CustomerPrivilegeUsageTypeEnum,
} from "src/store/customerprivilege/type";
import { setUsedCustomerPrivilege } from "src/store/customerprivilege/saga";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";

const getSentence = (data: CustomerPrivilegeDto) => {
  let text: string = "-";
  switch (data.UsageTypeId) {
    case CustomerPrivilegeUsageTypeEnum.Count:
      text = `${data.Count} Adet`;
      break;
    case CustomerPrivilegeUsageTypeEnum.Day:
      text = `Günde ${data.Count} Adet`;
      break;
    case CustomerPrivilegeUsageTypeEnum.Week:
      text = `Haftada ${data.Count} Adet`;
      break;
    case CustomerPrivilegeUsageTypeEnum.Month:
      text = `Ayda ${data.Count} Adet`;
      break;
    case CustomerPrivilegeUsageTypeEnum.Year:
      text = `Yılda ${data.Count} Adet`;
      break;
    case CustomerPrivilegeUsageTypeEnum.Unlimited:
      text = `Limitsiz`;
      break;
  }
  return text;
};
const PrivilegeTab = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string; tab: string } = useParams();
  // const [showEdit, setShowEdit] = useState(false);
  //const [selected, setSelected] = useState<CustomerPrivilegeDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showUse, setShowUse] = useState<CustomerPrivilegeDto>();
  //const [showDelete, setShowDelete] = useState<CustomerPrivilegeDto>();
  const columns = [
    {
      caption: t("Kaynak"),
      dataField: "CustomerPrivilegeSourceTypeName",
    },
    {
      caption: t("Tanımlanan Hizmet Adı"),
      dataField: "Name",
      calculateCellValue: function (rowData: any) {
        return rowData.Name ?? rowData.PrivilegeName;
      },
    },
    {
      caption: t("Kullanım Şekli"),
      dataField: "UsageTypeName",
      calculateCellValue: function (rowData: any) {
        return getSentence(rowData);
      },
    },
    {
      caption: t("StartDate"),
      dataField: "StartDate",
      dataType: "datetime",
    },
    {
      caption: t("EndDate"),
      dataField: "EndDate",
      dataType: "datetime",
    },
    {
      caption: t("Ayrıcalık Yenileme İptali"),
      dataField: "Cancelled",
      dataType: "boolean",
    },
    {
      caption: t("İptal Sonrası Kullanılabilirlik"),
      dataField: "CanBeUseAfterCancel",
      dataType: "boolean",
    },

    {
      caption: t("Devredilebilirlik"),
      dataField: "Transferable",
      dataType: "boolean",
    },
    {
      caption: t("Ödemeye Dahil"),
      dataField: "TakePayment",
      dataType: "boolean",
    },
    {
      caption: t("Kullanılan Adet"),
      dataField: "UsedCount",
      dataType: "number",
    },
    {
      caption: t("Kalan Adet"),
      dataField: "AvaibleCount",
      dataType: "number",
    },
    {
      caption: t("Ayrıcalık Yenileme İptali"),
      dataField: "Cancelled",
      dataType: "boolean",
    },

    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Kart",
          text: "Kart",
          cssClass: "text-dark bg-soft-info columnUseButton",
          visible: (e: any) => e.row.data.PrivilegeUsageTypeId === 9,
          onClick: async (e: any) => {
            history.push("/using-privilegecards/" + e.row.key);
          },
        },
        {
          hint: "Kullan",
          cssClass: "text-dark bg-soft-success columnUseButton",
          text: "Kullan",
          visible: (e: any) =>
            !(
              e.row.data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.QR ||
              e.row.data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kart ||
              e.row.data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon
            ) && e.row.data.AvaibleCount > 0,
          onClick: async (e: any) => {
            return setShowUse(e.row.data);
          },
        },
        // {
        //   hint: "Sil",
        //   icon: "trash",
        //   visible: () => CheckRole(user),
        //   onClick: async (e: any) => {
        //     return setShowDelete(e.row.data);
        //   },
        // },
      ],
    },
  ];

  return (
    <React.Fragment>
      <CreateCustomerPrivilege
        customerId={id}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={() => {}}
      />

      <CustomDataGrid
        className="detailGrid"
        url={"/api/customerprivileges/GetByCustomerIdDevExp/" + id}
        columns={columns}
        create={() => setShowCreate(true)}
        createText="Hizmet Ekle"
        rowAlternationEnabled={false}
        onRowPrepared={e => {
          if (e.rowType === "data") {
            if (
              e.data.CustomerPrivilegeSourceTypeId === 1 &&
              e.data.Cancelled
            ) {
              e.rowElement.style.cssText = " background-color: #fec0bf";
              // or
              e.rowElement.classList.add("my-class");
            }
          }
        }}
      />
      {showUse && (
        <CancelAlert
          message={`${showUse.Name} 
          ayrıcalığı kullanmak istediğinize emin misiniz?`}
          onConfirm={() => {
            setShowUse(undefined);
            dispatch(
              setUsedCustomerPrivilege({
                payload: {
                  onSuccess: message => toast.success(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  CustomerPrivilegeId: showUse.Id,
                },
              })
            );
          }}
          onCancel={() => {
            setShowUse(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PrivilegeTab;
