import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { InformedRecipientDto } from "./type";

export const getInformedRecipients = createAction(
  "GET_INFORMED_RECIPIENTS",
  ({ payload, params }: SagaActionParams<InformedRecipientDto[]>) => ({
    payload,
    url: "/api/informedRecipients" + (params ? params : ""),
  })
);

export const getInformedRecipientsDetails = createAction(
  "GET_INFORMED_RECIPIENTS_DETAILS",
  ({ payload, id }: SagaActionParams<InformedRecipientDto>) => ({
    payload,
    url: "/api/informedRecipients/" + id,
  })
);
export const createInformedRecipients = createAction(
  "CREATE_INFORMED_RECIPIENTS",
  ({ payload, body }: SagaActionParams<InformedRecipientDto>) => ({
    payload,
    url: "/api/informedRecipients",
    body,
  })
);
export const updateInformedRecipients = createAction(
  "UPDATE_INFORMED_RECIPIENTS",
  ({ payload, id, body }: SagaActionParams<InformedRecipientDto>) => ({
    payload,
    url: "/api/informedRecipients/" + id,
    body,
  })
);

export const deleteInformedRecipients = createAction(
  "DELETE_INFORMED_RECIPIENTS",
  ({ payload, id }: SagaActionParams<InformedRecipientDto>) => ({
    payload,
    url: "/api/informedRecipients/" + id,
  })
);

const informedRecipientsSaga = [
  /*INFORMED_RECIPIENTS*/
  takeLatest(
    getInformedRecipients.toString(),
    (payload: SagaGenericParams<InformedRecipientDto[]>) =>
      getListSaga<InformedRecipientDto[]>(payload)
  ),

  takeLatest(
    getInformedRecipientsDetails.toString(),
    (payload: SagaGenericParams<InformedRecipientDto>) =>
      getListSaga<InformedRecipientDto>(payload)
  ),
  takeLatest(
    createInformedRecipients.toString(),
    (payload: PostSagaGenericParams<InformedRecipientDto>) =>
      postSaga<InformedRecipientDto>(payload)
  ),
  takeLatest(
    updateInformedRecipients.toString(),
    (payload: PostSagaGenericParams<InformedRecipientDto>) =>
      putSaga<InformedRecipientDto>(payload)
  ),

  takeLatest(
    deleteInformedRecipients.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<InformedRecipientDto>(payload)
  ),
];

export default informedRecipientsSaga;
