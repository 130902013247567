import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";
import { CustomerDto } from "src/store/customers/type";

const CustomerCard = ({
  customer,
  data,
}: {
  customer: CustomerDto;
  data: CustomerCallLogsDto;
}) => {
  const { t } = useTranslation();
  const table = [
    {
      label: t("Name"),
      value: data.Name,
    },
    {
      label: t("Surname"),
      value: data.Surname,
    },
    {
      label: t("Paket"),
      value: customer?.PackageName ?? "-",
    },
    {
      label: t("Üyelik Durumu"),
      value: data.CustomerId ? "Kayıtlı Üye" : "Aday Üye",
    },
    {
      label: t("Email"),
      value: customer?.Email ?? "-",
    },
    {
      label: t("Phone"),
      value: data.PhoneNumber ?? "-",
    },
    {
      label: t("Phone") + " - 2",
      value: data.PhoneNumber2 ?? "-",
    },

    {
      label: t("City"),
      value: data.CityName ?? "-",
    },
    {
      label: t("Town"),
      value: data.TownName ?? "-",
    },
    {
      label: t("Adres"),
      value: data.Address ?? "-",
    },
  ];

  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Müşteri Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CustomerCard;
