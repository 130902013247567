import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";
import { CustomerVehicleDto } from "src/store/customervehicles/type";

const VehicleCard = ({
  vehicle,
  data,
}: {
  vehicle?: CustomerVehicleDto;
  data: CustomerCallLogsDto;
}) => {
  const { t } = useTranslation();
  const table = [
    {
      label: t("Plate"),
      value: data.VehiclePlate,
    },
    {
      label: t("Brand"),
      value: data.Brand,
    },
    {
      label: t("Model"),
      value: data.Model,
    },
    {
      label: t("Registration Date"),
      value: vehicle?.RegistrationDate
        ? moment(vehicle?.RegistrationDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      label: t("Color"),
      value: vehicle ? vehicle.VehicleColorName : "-",
    },
    {
      label: t("Body"),
      value: vehicle?.body ?? "-",
    },
    {
      label: t("Chassis Number"),
      value: vehicle?.ChassisNumber ?? "-",
    },
    {
      label: t("Body Type"),
      value: vehicle?.BodyTypeName ?? "-",
    },
    {
      label: t("Fuel Type"),
      value: vehicle?.FuelTypeName ?? "-",
    },
    {
      label: t("Gear Type"),
      value: vehicle?.GearTypeName ?? "-",
    },
    {
      label: t("Engine"),
      value: vehicle?.engine ?? "-",
    },
    {
      label: t("Engine Number"),
      value: vehicle?.EngineNumber ?? "-",
    },
    {
      label: t("KM Limit"),
      value: data.KmLimit ?? "-",
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Araç Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default VehicleCard;
