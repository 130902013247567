import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import Breadcrumbs from "../Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import loader from "src/store/loader";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";

export enum GridButtonType {
  "edit",
  "default",
}

const DrageAndDropTable = ({
  data,
  columns,
  onSuccessClick,
  dragAction,
  create,
  HideBreadcrumb = false,
  title,
  addTitle,
  headerColored = true,
}: {
  headerColored?: boolean;
  addTitle?: string;
  title?: string;
  HideBreadcrumb?: boolean;
  create?: {
    onCreateClick?: () => void;
    createType: "drawer" | "link";
    createLink?: string;
  };
  dragAction: any;
  onSuccessClick: (message: string) => void;
  columns: {
    dataField: string;
    text: string;
    formatter?: (value: any, data?: any) => void;
    lookup?: {
      data: any[];
      key: string;
      labelkey: string;
    };
  }[];
  data: any[];
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;
    if (result.destination.index !== result.source.index) {
      dispatch(loader.actions.showLoader());
      const items = reorder(
        data,
        result.source.index,
        result.destination.index
      ).map((x: any, i) => {
        return {
          Id: x.Id,
          RecordOrder: i + 1,
        };
      });
      dispatch(
        dragAction({
          payload: {
            onSuccess: (message: any) => {
              return onSuccessClick(t("Order Changed"));
            },
            onError: (message: any) => {
              toast.success(message, { theme: "colored" });
            },
          },
          body: items,
        })
      );
    }
    return;
  };
  return (
    <>
      <div
        className={
          !HideBreadcrumb && !title
            ? "d-flex flex-wrap align-items-center flex-wrap mb-2 justify-content-end"
            : "d-flex flex-wrap align-items-center flex-wrap mb-2 justify-content-between"
        }
      >
        {HideBreadcrumb && title && (
          <h5 className="mb-0 font-size-18">{title}</h5>
        )}
        {!HideBreadcrumb && title && (
          <Breadcrumbs title={t("Dashboard")} breadcrumbItem={title} />
        )}
        <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3 ">
          <div style={{ zIndex: 2 }}>
            <button className="btn btn-info me-1">
              <i className="mdi mdi-microsoft-excel "></i>
            </button>

            <button className="btn btn-warning  me-1" onClick={() => {}}>
              <i className="mdi mdi-refresh me-1"></i>
            </button>
            {CheckRole(user) && (
              <>
                <button
                  className="btn btn-success me-1"
                  onClick={() => {
                    dispatch(
                      dragAction({
                        payload: {
                          onSuccess: (message: any) => {
                            return onSuccessClick(t("Order Changed"));
                          },
                          onError: (message: any) => {
                            toast.success(message, { theme: "colored" });
                          },
                        },
                        body: data
                          .sort((a, b) => a.RecordOrder - b.RecordOrder)
                          .map((x: any, i) => {
                            return {
                              Id: x.Id,
                              RecordOrder: i + 1,
                            };
                          }),
                      })
                    );
                  }}
                >
                  Sıralamayı Düzelt
                </button>
                <>
                  {create && (
                    <>
                      {create.createType === "drawer" ? (
                        <button
                          onClick={() => {
                            create &&
                              create.onCreateClick &&
                              create.onCreateClick();
                          }}
                          className="btn btn-success text-light"
                        >
                          <i className="bx bx-plus me-1"></i>{" "}
                          {addTitle ? addTitle : t("New")}
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            history.push(create?.createLink ?? "/")
                          }
                          className="btn btn-success text-light"
                        >
                          <i className="bx bx-plus me-1"></i>
                          {addTitle ? addTitle : t("New")}
                        </button>
                      )}
                    </>
                  )}
                </>
              </>
            )}
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table
          className={
            "table align-middle table-nowrap table-hover responsiveTable" +
            (headerColored ? " table-header-colored" : " ")
          }
        >
          <thead>
            <tr>
              <th></th>
              <th>Sıra</th>
              {columns.map((x, i) => (
                <th key={i}>{x.text}</th>
              ))}
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {provider => (
              <tbody ref={provider.innerRef} {...provider.droppableProps}>
                {data
                  .sort((a, b) => a.RecordOrder - b.RecordOrder)
                  .map((formItem, i) => (
                    <Draggable
                      key={formItem.Id}
                      draggableId={formItem.Id}
                      index={i}
                    >
                      {provider => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                        >
                          <td
                            {...provider.dragHandleProps}
                            className="col-xxl-1"
                          >
                            <i className="mdi mdi-sort" />{" "}
                          </td>
                          <td>{formItem.RecordOrder}</td>
                          {columns.map((y, i) => (
                            <td key={i}>
                              {y.formatter
                                ? y.formatter(formItem[y.dataField], formItem)
                                : formItem[y.dataField]}
                            </td>
                          ))}
                        </tr>
                      )}
                    </Draggable>
                  ))}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </>
  );
};
export default DrageAndDropTable;
