import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { TicketSubjectDto } from "./type";
import { BodyType } from "../base/request/request";

export const getTicketSubjectList = createAction(
  "GET_TICKET_SUBJECT_LIST",
  ({ payload, params }: SagaActionParams<TicketSubjectDto[]>) => ({
    payload,
    url: "/api/ticketsubjects" + (params ? params : ""),
  })
);
export const getParentTicketSubjectList = createAction(
  "GET_TICKET_PARENT_SUBJECT_LIST",
  ({ payload, params }: SagaActionParams<TicketSubjectDto[]>) => ({
    payload,
    url: "/api/ticketsubjects/parents" + (params ? params : ""),
  })
);
export const getTicketSubSubjectList = createAction(
  "GET_TICKET_SUB_SUBJECT_LIST",
  ({ payload, params, id }: SagaActionParams<TicketSubjectDto[]>) => ({
    payload,
    url: "/api/ticketsubjects/subSubjects/" + id + (params ? params : ""),
  })
);

export const getTicketSubjectDetails = createAction(
  "GET_TICKET_SUBJECT_DETAILS",
  ({ payload, id }: SagaActionParams<TicketSubjectDto>) => ({
    payload,
    url: "/api/ticketsubjects/" + id,
  })
);
export const createTicketSubject = createAction(
  "CREATE_TICKET_SUBJECT_DETAILS",
  ({ payload, body }: SagaActionParams<TicketSubjectDto>) => ({
    payload,
    url: "/api/ticketsubjects",
    body,
  })
);
export const updateTicketSubject = createAction(
  "UPDATE_TICKET_SUBJECT_DETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDto>) => ({
    payload,
    url: "/api/ticketsubjects/" + id,
    body,
  })
);
export const deleteTicketSubject = createAction(
  "DELETE_TICKET_SUBJECT_DETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDto>) => ({
    payload,
    url: "/api/ticketsubjects/" + id,
    body,
  })
);

export const changeTicketSubjectOrder = createAction(
  "UPDATE_TICKET_SUBJECT_ORDER",
  ({ payload, body }: SagaActionParams<TicketSubjectDto>) => ({
    payload,
    url: "/api/ticketsubjects/order",

    bodyType: BodyType.raw,
    body,
  })
);
const ticketSubjectSaga = [
  takeLatest(
    getTicketSubjectList.toString(),
    (payload: SagaGenericParams<TicketSubjectDto[]>) =>
      getListSaga<TicketSubjectDto[]>(payload)
  ),
  takeLatest(
    getTicketSubjectDetails.toString(),
    (payload: SagaGenericParams<TicketSubjectDto>) =>
      getListSaga<TicketSubjectDto>(payload)
  ),
  takeLatest(
    createTicketSubject.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDto>) =>
      postSaga<TicketSubjectDto>(payload)
  ),
  takeLatest(
    changeTicketSubjectOrder.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDto>) =>
      putSaga<TicketSubjectDto>(payload)
  ),
  takeLatest(
    updateTicketSubject.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDto>) =>
      putSaga<TicketSubjectDto>(payload)
  ),
  takeLatest(
    deleteTicketSubject.toString(),
    (payload: SagaGenericParams<TicketSubjectDto>) =>
      deleteSaga<TicketSubjectDto>(payload)
  ),
  takeLatest(
    getParentTicketSubjectList.toString(),
    (payload: SagaGenericParams<TicketSubjectDto[]>) =>
      getListSaga<TicketSubjectDto[]>(payload)
  ),
  takeLatest(
    getTicketSubSubjectList.toString(),
    (payload: SagaGenericParams<TicketSubjectDto[]>) =>
      getListSaga<TicketSubjectDto[]>(payload)
  ),
];

export default ticketSubjectSaga;
