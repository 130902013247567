import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import {
  getDiscountTypeLookupList,
  getPrivilegeUsageTypes,
  getprivilegeCouponTypes,
} from "src/store/lookup/saga";
import {
  DiscountTypeDto,
  PrivilegeCouponType,
  UsageTypeDto,
} from "src/store/lookup/type";
import { getPrivilegeDetails, updatePrivilege } from "src/store/privilege/saga";
import { getPrivilegeLookupCategoryList } from "src/store/privilegecategory/saga";
import { PrivilegeCategoryDto } from "src/store/privilegecategory/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import { PrivilegeDto } from "src/store/privilege/type";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import TypeEditModal from "./typeedit";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const EditPrivilegePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [showTypes, setShowTypes] = useState(false);

  const [privilege, setPrivilege] = useState<PrivilegeDto>();
  const [categories, setCategories] = useState<PrivilegeCategoryDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const loader = useSelector(isLoading);
  const [usageTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [couponTypes, setCouponTypes] = useState<PrivilegeCouponType[]>([]);
  useEffect(() => {
    dispatch(
      getprivilegeCouponTypes({
        payload: {
          onSuccess: (msg, payload) => setCouponTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeUsageTypes({
        payload: {
          onSuccess: (msg, payload) => setUsageTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getPrivilegeLookupCategoryList({
        payload: {
          onSuccess: (msg, payload) => setCategories(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getSupplierLookup({
        payload: {
          onSuccess: (msg, payload) => setSuppliers(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getDiscountTypeLookupList({
        payload: {
          onSuccess: (msg, payload) => setDiscountTypes(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Privileges")}
            breadcrumbItem={t("Edit Privilege")}
          />

          <Row className="mt-2">
            <div className="d-flex justify-content-end mb-2 ">
              <button
                onClick={() => setShowTypes(true)}
                className="btn btn-sm btn-success"
              >
                Tipleri Düzenle
              </button>
              {privilege && (
                <TypeEditModal
                  onHide={() => setShowTypes(false)}
                  onSuccessClick={() => {
                    toast.success("Tip düzenlemesi başarılı");
                    dispatch(
                      getPrivilegeDetails({
                        payload: {
                          onSuccess: (msg, payload) => setPrivilege(payload),
                          onError: () => {},
                        },
                        id: id,
                      })
                    );
                  }}
                  open={showTypes}
                  privilege={privilege}
                />
              )}
            </div>
            {loader && <Loader />}
            {privilege && (
              <CustomFormik
                loader
                createButtonClass="w-100"
                initialValues={{
                  PrivilegeUsageTypeId: privilege.PrivilegeUsageTypeId ?? "",
                  UsageValue: privilege.UsageValue ?? "",
                  Id: privilege.Id,
                  PrivilegeCategoryId: privilege.PrivilegeCategoryId,
                  SupplierId: privilege.SupplierId,
                  Title: privilege.Title ?? "",
                  ShortDescription: privilege.ShortDescription ?? "",
                  Description: privilege.Description ?? "",
                  StartDate: privilege.StartDate ?? "",
                  EndDate: privilege.EndDate ?? "",
                  Limited: privilege.Limited ?? "",
                  CouponCount: privilege.CouponCount ?? "",
                  Active: privilege.Active,
                  CurrentImageFile: privilege.Image ?? "",
                  ImageFile: "",
                  CurrentIconFile: privilege.Icon ?? "",
                  IconFile: "",
                  CurrentWebsiteIconFile: privilege.WebsiteIconFile ?? "",
                  WebsiteIconFile: "",
                  TermsOfService: privilege.TermsOfService ?? "",
                  CanBeUsedMoreThanOnce: privilege.CanBeUsedMoreThanOnce ?? "",
                  DiscountTypeId: privilege.DiscountTypeId,
                  DiscountValue: privilege.DiscountValue ?? "",
                  IncludablePackage: privilege.IncludablePackage,
                  Sellable: privilege.Sellable,
                  ShowStore: privilege.ShowStore,
                  ShowWebsite: privilege.ShowWebsite ?? false,
                  ShowOnMobile: privilege.ShowOnMobile,
                  // IconLibrary: privilege.IconLibrary ?? "",
                  // IconName: privilege.IconName ?? "",
                  PrivilegeCouponTypeId: privilege.PrivilegeCouponTypeId ?? 2,
                  CouponLenght: privilege.CouponLenght ?? 10,
                  ShowPhoneNumber: privilege.ShowPhoneNumber ?? false,
                  PhoneNumber: privilege.PhoneNumber ?? "",
                  TaxRate: privilege.TaxRate ?? 0,
                  ShowUsageInformationText: privilege.ShowUsageInformationText,
                  UsageInformationText: privilege.UsageInformationText ?? "",
                  ShowTerms: privilege.ShowTerms,
                  HasContainsPrivilege: privilege.HasContainsPrivilege,

                  SendNotificationMembers: false,
                  SendNotificationAllCustomer: false,
                }}
                onSubmit={values => {
                  dispatch(
                    updatePrivilege({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          return setPrivilege(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                      id: privilege.Id,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  TaxRate: Yup.number().required("Zorunlu"),

                  PrivilegeCategoryId: Yup.string().required("Zorunlu"),
                  SupplierId: Yup.string().required("Zorunlu"),
                  ShortDescription: Yup.string().required("Zorunlu"),
                  Title: Yup.string().required("Zorunlu"),
                  Description: Yup.string().required("Zorunlu"),
                  StartDate: Yup.string().required("Zorunlu"),
                  PrivilegeCouponTypeId: Yup.number().required(t("Required")),
                  EndDate: Yup.string().required("Zorunlu"),
                  TermsOfService: Yup.string().required("Zorunlu"),
                  DiscountTypeId: Yup.number().required("Zorunlu"),
                  DiscountValue: Yup.number().required("Zorunlu"),
                  PhoneNumber: Yup.string().when("ShowPhoneNumber", {
                    is: (id: boolean) => id,
                    then: Yup.string().required(t("Required")),
                  }),
                  CouponLenght: Yup.number()
                    .min(4, "Minimum değeri 4 olabilir")
                    .max(12, "Maximum değeri 12 olabilir")
                    .required("Zorunlu"),
                })}
                inputs={[
                  {
                    field: "PrivilegeCategoryId",
                    inputType: InputType.multiselect,
                    label: t("Kategori"),
                    lookup: {
                      data: categories,
                      labelKey: "CategoryName",
                      valueKey: "Id",
                      placeholder: t("Kategori Seçiniz"),
                      disabledItemValues: categories
                        .filter(
                          x =>
                            x.Id !== privilege.PrivilegeCategoryId &&
                            x.IsSinglePrivilege &&
                            x.PrivilegeCount > 0
                        )
                        .map(y => y.Id),
                    },
                    col: 6,
                  },
                  {
                    col: 6,
                    field: "SupplierId",
                    inputType: InputType.multiselect,
                    label: t("Supplier"),
                    lookup: {
                      data: suppliers,
                      labelKey: "Name",
                      valueKey: "Id",
                      placeholder: t("Choose Supplier"),
                    },
                  },
                  {
                    field: "Title",
                    label: t("Title"),
                  },
                  {
                    field: "ShortDescription",
                    label: t("Short Description"),
                    inputType: InputType.multilinetext,
                  },
                  {
                    field: "Description",
                    label: t("Description"),
                    inputType: InputType.multilinetext,
                  },

                  {
                    field: "TaxRate",
                    label: t("Vergi Oranı"),
                    inputType: InputType.number,
                  },
                  {
                    field: "PrivilegeCouponTypeId",
                    inputType: InputType.multiselect,
                    label: t("Kupon Tipi"),
                    lookup: {
                      data: couponTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                    },
                    col: 6,
                  },
                  {
                    field: "CouponLenght",
                    label: t("Kupon Uzunluğu"),
                    inputType: InputType.number,
                    min: 4,
                    max: 12,
                    col: 6,
                  },
                  {
                    field: "StartDate",
                    label: t("Start Date"),
                    inputType: InputType.datetime,
                    col: 6,
                  },
                  {
                    field: "EndDate",
                    label: t("End Date"),
                    inputType: InputType.datetime,
                    col: 6,
                  },
                  {
                    field: "PrivilegeUsageTypeId",
                    inputType: InputType.multiselect,
                    label: t("Usage Type"),
                    lookup: {
                      data: usageTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Choose Usage Type"),
                    },
                    col: 6,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.Kupon ||
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.QR
                      ),
                    field: "Limited",
                    label: t("Limited"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.Kupon && values.Limited
                      ),
                    field: "CouponCount",
                    label: t("Coupon Count"),
                    inputType: InputType.number,
                    col: 4,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.QR && values.Limited
                      ),
                    field: "CouponCount",
                    label: t("QR Count"),
                    inputType: InputType.number,
                    col: 4,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.Link
                      ),
                    field: "UsageValue",
                    label: t("Link"),
                    col: 6,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.SabitKod
                      ),
                    field: "UsageValue",
                    label: t("Sabit Kod"),
                    col: 6,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.TelefonAramasi
                      ),
                    field: "UsageValue",
                    label: t("Telefon Numarası"),
                    col: 6,
                  },
                  {
                    col: 6,
                    field: "DiscountTypeId",
                    inputType: InputType.multiselect,
                    label: t("Discount Type"),
                    lookup: {
                      data: discountTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Choose Discount Type"),
                    },
                  },
                  {
                    field: "DiscountValue",
                    label: t("Discount Value"),
                    inputType: InputType.number,
                    col: 6,
                  },
                  {
                    field: "ShowPhoneNumber",
                    label: t("Telefon Numarası Göster"),
                    inputType: InputType.checkbox,
                    col: 12,
                  },
                  {
                    field: "PhoneNumber",
                    label: t("PhoneNumber"),
                    col: 12,
                    hide: values => !values.ShowPhoneNumber,
                  },
                  {
                    field: "TermsOfService",
                    label: t("TermsOfService"),
                    inputType: InputType.multilinetext,
                  },
                  //   {
                  //   field: "IconLibrary",
                  //   label: t("İkon Kütüphanesi"),
                  //   col: 6,
                  // },
                  // {
                  //   field: "IconName",
                  //   label: t("İkon Adı"),
                  //   col: 6,
                  // },
                  {
                    field: "CanBeUsedMoreThanOnce",
                    label: t("Can Be Used More Than Once"),

                    col: 3,
                    inputType: InputType.checkbox,
                  },

                  {
                    field: "ShowTerms",
                    label: t("Kullanım Koşullarını Göster"),
                    col: 3,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "Active",
                    label: t("Active"),
                    col: 3,
                    checkedLabel: t("Active"),
                    uncheckedLabel: t("Pasive"),
                    inputType: InputType.checkbox,
                  },

                  // {
                  //   field: "IncludablePackage",
                  //   label: t("Includable Package"),
                  //   col: 2,
                  //   inputType: InputType.checkbox,
                  // },

                  {
                    field: "ShowOnMobile",
                    label: t("Show On Mobile"),
                    col: 3,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowWebsite",
                    label: t("Show On Web"),
                    col: 3,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "Sellable",
                    label: t("Satılabilir"),
                    col: 3,
                    inputType: InputType.checkbox,
                    onChange(value, item, setFieldValue, values) {
                      !value && setFieldValue("ShowStore", false);
                    },
                  },
                  {
                    hide(values) {
                      return !values.Sellable;
                    },
                    field: "ShowStore",
                    label: t("Mağazada Göster"),
                    col: 3,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "HasContainsPrivilege",
                    label: t("Dahil Hizmet İçerir"),
                    col: 3,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowUsageInformationText",
                    label: t("Kullanım Şeklini Göster"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "UsageInformationText",
                    label: t("Kullanım Şekli"),
                    height: "200px",
                    inputType: InputType.editor,
                  },

                  {
                    field: "IconFile",
                    label: t("İkon Dosyası"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentIconFile",
                      multiple: false,
                      title: t("İkon Dosyası Yükleyiniz"),
                    },
                  },
                  {
                    field: "WebsiteIconFile",
                    label: t("Webİkon Dosyası"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentWebsiteIconFile",
                      multiple: false,
                      title: t("Web İkon Dosyası Yükleyiniz"),
                    },
                  },
                  {
                    field: "ImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      multiple: false,
                      title: t("Please Upload Image"),
                    },
                  },
                  {
                    field: "SendNotificationMembers",
                    label: t("Üyelere Bildirim Gönder"),
                    inputType: InputType.checkbox,
                    onChange(value, item, setFieldValue, values) {
                      if (
                        value === true &&
                        values.SendNotificationAllCustomer === true
                      ) {
                        setFieldValue("SendNotificationAllCustomer", false);
                      }
                    },
                  },
                  {
                    field: "SendNotificationAllCustomer",
                    label: t("Herkese Bildirim Gönder"),
                    inputType: InputType.checkbox,
                    onChange(value, item, setFieldValue, values) {
                      if (
                        value === true &&
                        values.SendNotificationMembers === true
                      ) {
                        setFieldValue("SendNotificationMembers", false);
                      }
                    },
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EditPrivilegePage;
