import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { updatePrivilegeContain } from "src/store/privilegecontain/saga";
import { PrivilegeContainDto } from "src/store/privilegecontain/type";
import { getPrivilegePriceListByPrivilegeId } from "src/store/privilegeprices/saga";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";

const EditPrivilegeContain = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PrivilegeContainDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegePriceListByPrivilegeId({
          payload: {
            onSuccess: (msg, py) => setPrices(py),
            onError: () => {},
          },
          id: data.PrivilegeId,
        })
      );
    }
  }, [dispatch, open, data.PrivilegeId]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PrivilegeId: data.PrivilegeId,
          Count: data.Count,
          ContainsPrivilegePriceId: data.ContainsPrivilegePriceId,
          ContainsPrivilegeId: data.ContainsPrivilegeId,
        }}
        onSubmit={values => {
          dispatch(
            updatePrivilegeContain({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          Count: Yup.number().min(1, t("Required")).required(t("Required")),
          ContainsPrivilegeId: Yup.string().required(t("Required")),
          ContainsPrivilegePriceId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "ContainsPrivilegeId",
            inputType: InputType.multiselect,
            label: t("Dahil Hizmet"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Dahil Hizmet Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              value && setFieldValue("ContainsPrivilegePriceId", "");
              value &&
                dispatch(
                  getPrivilegePriceListByPrivilegeId({
                    payload: {
                      onSuccess: (msg, py) => setPrices(py),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            disabled: values => !values.ContainsPrivilegeId,
            field: "ContainsPrivilegePriceId",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
          },
          {
            field: "Count",
            label: t("Adet"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPrivilegeContain;
