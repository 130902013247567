import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import auth from "src/store/auth";

interface LogoutProps {
  history: any;
}

const Logout = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(auth.actions.logoutAction());
    window.location.replace("/login");
  }, [dispatch, history]);

  return <></>;
};

export default withRouter(Logout);
