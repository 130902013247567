import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateMaintenanceDraft } from "src/store/maintenance/maintenance-drafts/saga";
import { MaintenanceDraftDetailDto } from "src/store/maintenance/maintenance-drafts/type";
import { getVehicleBrandLookup } from "src/store/maintenance/vehicle-brands/saga";
import { VehicleBrandDto } from "src/store/maintenance/vehicle-brands/type";
import { getVehicleModelLookup } from "src/store/maintenance/vehicle-models/saga";
import { VehicleModelDto } from "src/store/maintenance/vehicle-models/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierLookupDto } from "src/store/supplier/type";
import * as Yup from "yup";

const EditMaintenanceDraft = ({
  onHide,
  open,
  onSuccessClick,
  data,
}: {
  data: MaintenanceDraftDetailDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState<SupplierLookupDto[]>([]);
  const [brands, setBrands] = useState<VehicleBrandDto[]>([]);
  const [models, setModels] = useState<VehicleModelDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (msg, py) => setSuppliers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getVehicleBrandLookup({
          payload: {
            onSuccess: (msg, py) => setBrands(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getVehicleModelLookup({
          payload: {
            onSuccess: (msg, py) => setModels(py),
            onError: () => {},
          },
          params: "?vehicleBrandId=" + data.VehicleModelVehicleBrandId,
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Bakım Taslağı Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateMaintenanceDraft({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          SupplierId: data.SupplierId,
          VehicleModelId: data.VehicleModelId,
          VehicleBrandId: data.VehicleModelVehicleBrandId,
          KmLimit: data.KmLimit,
          ValidityDate: data.ValidityDate,
        }}
        validationSchema={Yup.object().shape({
          SupplierId: Yup.string().required(t("Required")),
          VehicleModelId: Yup.string().required(t("Required")),
          VehicleBrandId: Yup.string().required(t("Required")),
          KmLimit: Yup.number().required(t("Required")),
          ValidityDate: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "SupplierId",
            inputType: InputType.multiselect,
            label: t("İş Ortağı"),
            lookup: {
              data: suppliers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("İş Ortağı Seçiniz"),
            },
          },
          {
            field: "VehicleBrandId",
            inputType: InputType.multiselect,
            label: t("Marka"),
            lookup: {
              data: brands,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Marka Seçiniz"),
            },
            onChange(value, item, setFieldValue, values) {
              value &&
                dispatch(
                  getVehicleModelLookup({
                    payload: {
                      onSuccess: (msg, py) => setModels(py),
                      onError: () => {},
                    },
                  })
                );
              value &&
                value !== values.VehicleBrandId &&
                setFieldValue("VehicleModelId", "");
            },
          },
          {
            field: "VehicleModelId",
            inputType: InputType.multiselect,
            label: t("Model"),
            lookup: {
              data: models,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Model Seçiniz"),
            },
          },
          {
            field: "KmLimit",
            label: t("Km Limit"),
          },
          {
            field: "ValidityDate",
            label: t("Geçerlilik Tarihi"),
            inputType: InputType.datetime,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditMaintenanceDraft;
