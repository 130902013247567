import { useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import Loader from "src/components/Loader";
import DashboardCustomerComponent from "../customers";
import DashboardTicketComponent from "../tickets";
import FirstPackageList from "../firstpackage";
import MyTickets from "./myTickets";
import MyCalllogs from "./myCalllogs";
import Calllogs from "./calllogs";
const CustomerServiceDashboard = () => {
  const loading = useSelector(isLoading);
  return (
    <React.Fragment>
      <Container fluid>
        {loading && <Loader />}
        <Row>
          <Col md={4}>
            <DashboardCustomerComponent />
          </Col>
          <Col md={4}>
            <DashboardTicketComponent />
          </Col>
          <Col md={4}>
            <Calllogs />
          </Col>
          <Col md={4}>
            <MyTickets />
          </Col>
          <Col md={4}>
            <MyCalllogs />
          </Col>

          <Col md={4}>
            <FirstPackageList />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default CustomerServiceDashboard;
