import TextEditor from "../HtmlEditor";
import { Props } from "../type";

const CustomEditor = ({
  label,
  error,
  type = "text",
  field,
  value,
  className,
  touched,
  onChange,
  onBlur,
  disabled,
  height,
}: Props) => {
  return (
    <div className=" my-2" style={{ paddingBlockEnd: 20 }}>
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
      >
        {label ? label : ""}
      </label>
      <TextEditor
        disabled={disabled}
        value={value}
        setFieldValue={(e: any) => onChange && onChange(e)}
        height={height}
      />

      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomEditor;
