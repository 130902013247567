import CustomModal from "../../Modal/modal";
import CustomDrawer from "../../Modal/drawer";
import CustomFormik from "../Formik";
import { CustomModalFormProps } from "../type";

const FormModal = ({ modalProps, ...rest }: CustomModalFormProps) => {
  return (
    <CustomDrawer {...modalProps}>
      <CustomFormik {...rest} />
    </CustomDrawer>
  );
};
export default FormModal;

export const FormModalComp = ({
  modalProps,
  ...rest
}: CustomModalFormProps) => {
  return (
    <CustomModal {...modalProps}>
      <CustomFormik {...rest} />
    </CustomModal>
  );
};
