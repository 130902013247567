import FileSaver from "file-saver";
import moment from "moment";
import { Card, Col, Row } from "reactstrap";
import images from "src/assets/image";
import { baseImageUrl } from "src/store/base/types/url";
import { CustomerVehicleDocumentDto } from "src/store/customervehicles/type";

const DocumentCard = ({
  document,
}: {
  document: CustomerVehicleDocumentDto;
}) => {
  return (
    <Card
      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
      key={"-file"}
    >
      <div className="p-2">
        <Row className="align-items-center">
          <Col className="col-auto">
            <img
              data-dz-thumbnail=""
              height={40}
              className="avatar-sm rounded bg-light"
              alt={""}
              src={baseImageUrl + document.Document}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = images.doc;
              }}
            />
          </Col>
          <Col>
            <span
              style={{ cursor: "pointer" }}
              className=" font-weight-bold"
              onClick={() => {
                FileSaver.saveAs(baseImageUrl + document.Document, document.Id);
              }}
            >
              {document.VehicleDocumentTypeName}
              <div style={{ fontSize: 10 }} className="text-muted">
                {document.StartDate && document.EndDate ? (
                  <>
                    {moment(document.StartDate).format("DD/MM/yyyy HH:mm")}-
                    {moment(document.EndDate).format("DD/MM/yyyy HH:mm")}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </span>
          </Col>
          <Col md={1}>
            <p
              className="mb-0 text-pr deleteHover cursor-pointer font-size-14"
              onClick={() => {
                FileSaver.saveAs(baseImageUrl + document.Document, document.Id);
              }}
            >
              <strong>
                <i className="bx bxs-download font-size-20" />
              </strong>
            </p>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default DocumentCard;
