import ScrollBar from "react-perfect-scrollbar";
import { Modal } from "rsuite";
import { CustomModalProps } from "../Form/type";

const CustomModal = ({
  open,
  onHide,
  title,
  outsideClick = false,
  children,
  footer,
  ...rest
}: CustomModalProps) => {
  return (
    <Modal
      {...rest}
      onClose={() => {
        outsideClick && onHide();
      }}
      open={open}
    >
      {title && (
        <Modal.Header className="" onClose={onHide}>
          {title}
        </Modal.Header>
      )}
      <Modal.Body>
        <ScrollBar>{children}</ScrollBar>
      </Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};
export default CustomModal;
