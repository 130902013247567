import { TireSupplierDto } from "../suppliers/type";

export interface TireSaleDto {
  Id: string;
  CustomerId: string;
  Code: string;
  CustomerFullName: string;
  CustomerPhoneNumber: string;
  GrandTotal: number;
  CustomerEmail: string;
  VehicleId: string;
  SalesDate: string;
  DeliveryDate: string;
  TireSaleStatusId: number;
  TireSaleStatusName: string;
  FreeShipping: boolean;
  FreeAssembly: boolean;
  CustomerPurchaseTransactionId: string;
}
export interface TireSaleDetailDto {
  Id: string;
  CustomerId: string;
  VehicleId: string;
  Code: string;
  CustomerFullName: string;
  CustomerPhoneNumber: string;
  CustomerEmail: string;
  SalesDate: string;
  DeliveryDate: string;
  TireSaleStatusId: number;
  FreeShipping: boolean;
  FreeAssembly: boolean;
  TotalPrice: number;
  GrandTotal: number;
  ShippingTotalPrice: number;
  ShippingGrandTotal: number;
  TaxAmount: number;
  Address: string;
  InvoiceAddress: string;
  DeliveryNumber?: any;
  CustomerPurchaseTransactionId: string;
  TireSaleItems: TireSaleItem[];
  GrandTotalWithCommission: number;
}

export interface Tire {
  Id: string;
  Name: string;
  TireTypeId: number;
  TireTypeName: string;
  TireBrandId: string;
  TireBrandName: string;
  TireTreadWidthId: string;
  TireTreadWidthValue: number;
  TireSidewallWidthId: string;
  TireSidewallWidthValue: number;
  TireDiameterId: string;
  TireDiameterValue: number;
  Description: string;
  Image: string;
  UnitPrice: number;
  TaxRate: number;
  ShippingPrice: number;
  ShippingTaxRate: number;
  Stock: number;
  FreeShipping: boolean;
  FreeAssembly: boolean;
  TireSupplierInfo: TireSupplierInfo;
}

export interface TireSupplierInfo {
  Id: string;
  TireId: string;
  TireName: string;
  TireSupplierId: string;
  TireSupplierName: string;
  SupplierStockCode: string;
  UnitPrice: number;
  TaxRate: number;
  TotalPrice: number;
  TireSupplier: TireSupplierDto;
}
export interface TireSaleItem {
  Id: string;
  TireSaleId: string;
  TireId: string;
  TireSupplierCode?: any;
  Count: number;
  UnitPrice: number;
  TaxRate: number;
  TaxPrice: number;
  TotalPrice: number;
  ShippingTotalPrice: number;
  ShippingTaxPrice: number;
  ShippingGrandTotal: number;
  GrandTotal: number;
  InterestRate: number;
  CommissionPrice: number;
  GrandTotalWithCommission: number;
  Tire: Tire;
}

export enum TireSaleStatusEnum {
  YeniSatis = 1,
  TedarikciyeIletildi = 2,
  Kargolandi = 3,
  Randevuverildi = 4,
  Tamamlandı = 5,
  Iptal = 6,
  Iade = 7,
}
export enum TireSaleStatusUpdateUrl {
  TedarikciyeIletildi = "/api/tiresales/status-supplier",
  Kargolandı = "/api/tiresales/status-shipping",
  Tamamlandı = "/api/tiresales/status-completed",
  İptal = "/api/tiresales/status-cancel",
}
