import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireSidewallWidthDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireSidewallWidthList = createAction(
  "GET_TIRE_SIDEWALL_WIDTH_LIST",
  ({ payload, params }: SagaActionParams<TireSidewallWidthDto[]>) => ({
    payload,
    url: "/api/tiresidewallwidths" + (params ? params : ""),
  })
);
export const getTireSidewallWidthDetails = createAction(
  "GET_TIRE_SIDEWALL_WIDTH_DETAILS",
  ({ payload, id }: SagaActionParams<TireSidewallWidthDto>) => ({
    payload,
    url: "/api/tiresidewallwidths/" + id,
  })
);
export const createTireSidewallWidth = createAction(
  "CREATE_TIRE_SIDEWALL_WIDTH_DETAILS",
  ({ payload, body }: SagaActionParams<TireSidewallWidthDto>) => ({
    payload,
    url: "/api/tiresidewallwidths",
    body,
  })
);
export const updateTireSidewallWidth = createAction(
  "UPDATE_TIRE_SIDEWALL_WIDTH_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSidewallWidthDto>) => ({
    payload,
    url: "/api/tiresidewallwidths/" + id,
    body,
  })
);
export const deleteTireSidewallWidth = createAction(
  "DELETE_TIRE_SIDEWALL_WIDTH_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSidewallWidthDto>) => ({
    payload,
    url: "/api/tiresidewallwidths/" + id,
    body,
  })
);

const tireSidewallWidthSaga = [
  takeLatest(
    getTireSidewallWidthList.toString(),
    (payload: SagaGenericParams<TireSidewallWidthDto[]>) =>
      getListSaga<TireSidewallWidthDto[]>(payload)
  ),
  takeLatest(
    getTireSidewallWidthDetails.toString(),
    (payload: SagaGenericParams<TireSidewallWidthDto>) =>
      getListSaga<TireSidewallWidthDto>(payload)
  ),
  takeLatest(
    createTireSidewallWidth.toString(),
    (payload: PostSagaGenericParams<TireSidewallWidthDto>) =>
      postSaga<TireSidewallWidthDto>(payload)
  ),

  takeLatest(
    updateTireSidewallWidth.toString(),
    (payload: PostSagaGenericParams<TireSidewallWidthDto>) =>
      putSaga<TireSidewallWidthDto>(payload)
  ),
  takeLatest(
    deleteTireSidewallWidth.toString(),
    (payload: SagaGenericParams<TireSidewallWidthDto>) =>
      deleteSaga<TireSidewallWidthDto>(payload)
  ),
];

export default tireSidewallWidthSaga;
