import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { isLoading } from "src/store/loader";
import MetaTags from "react-meta-tags";
import { Container, Row, TabContent, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import { Nav } from "rsuite";
import { getCouponDetails } from "src/store/coupons/saga";
import { CouponDto } from "src/store/coupons/type";
import CouponUsageHistories from "./UsageHistories";
import CouponDetailCard from "./couponCard";

const CouponDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(isLoading);
  const { t } = useTranslation();
  const { id, tab }: { id: string; tab: string } = useParams();
  const [activeTab, setActiveTab] = useState<string>(tab);
  const [couponData, setCouponData] = useState<CouponDto>();
  useEffect(() => {
    dispatch(
      getCouponDetails({
        payload: {
          onSuccess: (ms, payload) => {
            return setCouponData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {couponData ? `${couponData && couponData.Code}` : ""}| Servis Plan
          </title>
        </MetaTags>
        <Container fluid>
          {loading && <Loader />}
          {couponData && (
            <>
              {/* <Breadcrumbs
                title={t("Coupons")}
                breadcrumbItem={`${couponData.Name} (${couponData.Code})`}
              /> */}
              <CouponDetailCard couponData={couponData} />
              <Row>
                <Nav
                  appearance="subtle"
                  style={{ zIndex: 1 }}
                  onSelect={e => {
                    history.push("/customer/details/" + e + "/" + id);
                    return setActiveTab(e);
                  }}
                  activeKey={activeTab}
                >
                  <Nav.Item eventKey="1">
                    {t("Coupon Usage Histories")}
                  </Nav.Item>
                </Nav>
                <TabContent activeTab={activeTab} className="mt-4">
                  <TabPane tabId="1">
                    <CouponUsageHistories data={couponData} />
                  </TabPane>
                </TabContent>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CouponDetails;
