import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CorporateClientCustomerLookupDto,
  CorporateClientsDto,
  CorporateTypeDto,
} from "./type";

export const getCorporateClients = createAction(
  "GET_CORPORATE_CLIENTS",
  ({ payload, params }: SagaActionParams<CorporateClientsDto[]>) => ({
    payload,
    url: "/api/CorporateClients" + (params ? params : ""),
  })
);
export const getCorporateClientsDetails = createAction(
  "GET_CORPORATE_CLIENTS_DETAILS",
  ({ payload, id }: SagaActionParams<CorporateClientsDto>) => ({
    payload,
    url: "/api/CorporateClients/" + id,
  })
);
export const createCorporateClients = createAction(
  "CREATE_CORPORATE_CLIENTS",
  ({ payload, body }: SagaActionParams<CorporateClientsDto>) => ({
    payload,
    url: "/api/CorporateClients",
    body,
  })
);
export const updateCorporateClients = createAction(
  "UPDATE_CORPORATE_CLIENTS",
  ({ payload, id, body }: SagaActionParams<CorporateClientsDto>) => ({
    payload,
    url: "/api/CorporateClients/" + id,
    body,
  })
);
export const deleteCorporateClients = createAction(
  "DELETE_CORPORATE_CLIENTS",
  ({ payload, id }: SagaActionParams<CorporateClientsDto>) => ({
    payload,
    url: "/api/CorporateClients/" + id,
  })
);
export const getCorporateTypes = createAction(
  "GET_CORPORATE_TYPES",
  ({ payload }: SagaActionParams<CorporateTypeDto[]>) => ({
    payload,
    url: "/api/system/corporate-types",
  })
);
export const getCorporateClientCustomers = createAction(
  "GET_CORPORATE_CLIENT_CUSTOMERS",
  ({ payload, id }: SagaActionParams<CorporateClientCustomerLookupDto[]>) => ({
    payload,
    url: "/api/corporateclients/customer-lookup/" + id,
  })
);
export const importCorporateClientCustomer = createAction(
  "IMPORT_CORPORATE_CLIENT_CUSTOMERS",
  ({ payload, body }: SagaActionParams<string[]>) => ({
    payload,
    body,
    url: "/api/corporateclientcustomers/import",
  })
);
export const createCorporateClientCustomer = createAction(
  "CREATE_CORPORATE_CLIENT_CUSTOMERS",
  ({
    payload,
    body,
  }: SagaActionParams<CorporateClientCustomerLookupDto[]>) => ({
    payload,
    body,
    url: "/api/corporateclientcustomers",
  })
);
const CorporateClientsSaga = [
  takeLatest(
    getCorporateTypes.toString(),
    (payload: SagaGenericParams<CorporateClientsDto[]>) =>
      getListSaga<CorporateClientsDto[]>(payload)
  ),
  takeLatest(
    getCorporateClientCustomers.toString(),
    (payload: SagaGenericParams<CorporateClientCustomerLookupDto[]>) =>
      getListSaga<CorporateClientCustomerLookupDto[]>(payload)
  ),
  takeLatest(
    getCorporateClients.toString(),
    (payload: SagaGenericParams<CorporateClientsDto[]>) =>
      getListSaga<CorporateClientsDto[]>(payload)
  ),
  takeLatest(
    getCorporateClientsDetails.toString(),
    (payload: SagaGenericParams<CorporateClientsDto>) =>
      getListSaga<CorporateClientsDto>(payload)
  ),
  takeLatest(
    createCorporateClients.toString(),
    (payload: PostSagaGenericParams<CorporateClientsDto>) =>
      postSaga<CorporateClientsDto>(payload)
  ),
  takeLatest(
    updateCorporateClients.toString(),
    (payload: PostSagaGenericParams<CorporateClientsDto>) =>
      putSaga<CorporateClientsDto>(payload)
  ),
  takeLatest(
    deleteCorporateClients.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CorporateClientsDto>(payload)
  ),
  takeLatest(
    createCorporateClientCustomer.toString(),
    (payload: PostSagaGenericParams<CorporateClientsDto>) =>
      postSaga<CorporateClientsDto>(payload)
  ),
  takeLatest(
    importCorporateClientCustomer.toString(),
    (payload: PostSagaGenericParams<CorporateClientsDto>) =>
      postSaga<CorporateClientsDto>(payload)
  ),
];

export default CorporateClientsSaga;
