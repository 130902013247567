import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Card } from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  TicketSourceTypeEnum,
  TicketStatusDto,
  TicketStatusEnum,
} from "src/store/ticket/type";
import { useDispatch } from "react-redux";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import { getTicketSubjectList } from "src/store/ticket-subject/saga";
import TicketListHeader from "./comp/listHeader";

import PerfectScrollBar from "react-perfect-scrollbar";
const EmailSideBar = ({
  status,
  refreshList,
  sourceType,
  setSourceType,
}: {
  setSourceType: (sourceType: TicketSourceTypeEnum) => void;
  sourceType: TicketSourceTypeEnum;
  status: TicketStatusDto[];
  refreshList: () => void;
}) => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const statusId = parseInt(url.get("statusId") ?? "1");
  const subjectId = url.get("subjectId");
  const [ticketSubjects, setTicketSubjects] = useState<TicketSubjectDto[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTicketSubjectList({
        payload: {
          onSuccess: (m, p) => setTicketSubjects(p),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      <Card
        className={
          "email-leftbar ticketContainer-left "
          //  +
          // (sourceType === TicketSourceTypeEnum.CallLog
          //   ? "bg-soft-info"
          //   : "bg-soft-primary")
        }
        style={{ padding: 0 }}
      >
        <TicketListHeader
          refreshList={refreshList}
          setSourceType={setSourceType}
          sourceType={sourceType}
        />

        <div className="px-3">
          <div className="mail-list mt-4 ">
            {status
              .filter(x => x.EnumId !== TicketStatusEnum.Cozuldu)
              .map(x => (
                <Link
                  key={`${x.EnumId}${x.Name}`}
                  to={`/ticket/all?statusId=${x.EnumId}`}
                  className={statusId === x.EnumId ? "active" : ""}
                >
                  <i
                    className={
                      GetStatusIcon(x.EnumId) +
                      " font-size-16 align-middle me-2"
                    }
                  />
                  {x.Name} <span className="ml-1 float-end">({x.Count})</span>
                </Link>
              ))}
          </div>

          <h6 className="mt-4">Konular</h6>

          <PerfectScrollBar
            className="d-flex flex-column  mail-list"
            style={{ height: "250px" }}
          >
            {/* <div className="mail-list mt-1"> */}
            {ticketSubjects.map(x => {
              return (
                <Link
                  className={
                    subjectId === x.Id ? " fw-bold text-dark" : " text-dark"
                  }
                  to={`/ticket/all?statusId=${statusId}&subjectId=${x.Id}`}
                  key={`${x.Id}${x.Subject}`}
                >
                  <span
                    className={"mdi mdi-circle-outline  me-2 "}
                    style={{ fontSize: 8 }}
                  />
                  {x.Subject}
                </Link>
              );
            })}
            {/* </div> */}
          </PerfectScrollBar>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default EmailSideBar;

export const GetStatusIcon = (statusId: number) => {
  switch (statusId) {
    case TicketStatusEnum.Yeni:
      return "bx bxs-bookmark";
    case TicketStatusEnum.Islemde:
      return "mdi mdi-clock";
    case TicketStatusEnum.Cozuldu:
      return "bx bx-file";
    case TicketStatusEnum.Kapali:
      return " bx bx-window-close";
    case TicketStatusEnum.Atandi:
      return "bx bx-mail-send";
    case TicketStatusEnum.Gecersiz:
      return "mdi mdi-close-thick";
    default:
      return undefined;
  }
};
