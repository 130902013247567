export interface PrivilegeCardDto {
  Id: string;
  CardNumber: number;
  PrivilegeId: string;
  CustomerId?: string;
}
export enum PrivilegeCardStatusEnum {
  IslemBekliyor = 1,
  KartBasildi = 2,
  Gonderildi = 3,
  Aktif = 4,
  İptal = 5,
}

export interface PrivilegeCardDetailDto {
  Id: string;
  PrivilegeId: string;
  CustomerId: string;
  CardNumber: string;
  CustomerName: string;
  CustomerPhoneNumber: string;
  LastStatusName: string;
  TrackingNumber: string;
  OrderShippingCompanyName: string;
  Using: boolean;
  LastStatusId: number;
  PrivilegeCardHistories: PrivilegeCardHistory[];
}

export interface PrivilegeCardHistory {
  Id: string;
  PrivilegeCardId: string;
  CardNumber?: any;
  CustomerId: string;
  CustomerName?: any;
  CustomerPhoneNumber?: any;
  PrivilegeCardStatusId: number;
  PrivilegeCardStatusName: string;
  Date: string;
}
