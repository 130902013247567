import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  getPrivilegeButtonActionTypes,
  updatePrivilegeButton,
} from "src/store/privilegebuttons/saga";
import {
  ButtonActionTypeDto,
  PrivilegeButtonDto,
} from "src/store/privilegebuttons/type";
import * as Yup from "yup";

const EditPrivilegeButton = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PrivilegeButtonDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionTypes, setActionTypes] = useState<ButtonActionTypeDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeButtonActionTypes({
          payload: {
            onSuccess: (msg, py) => setActionTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PrivilegeId: data.PrivilegeId,
          ButtonName: data.ButtonName ?? "",
          Value: data.Value ?? "",
          ButtonActionTypeId: data.ButtonActionTypeId,
          CurrentIconFile: data.Icon ?? "",
          IconFile: "",
        }}
        onSubmit={values => {
          dispatch(
            updatePrivilegeButton({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          Value: Yup.string().required(t("Required")),
          ButtonActionTypeId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "ButtonName",
            label: t("Buton Adı"),
            inputType: InputType.multilinetext,
          },
          {
            field: "ButtonActionTypeId",
            inputType: InputType.multiselect,
            label: t("Aksiyon Tipi"),
            lookup: {
              data: actionTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Aksiyon Tipi Seçiniz"),
            },
          },
          {
            field: "Value",
            label: t("Değeri"),
          },
          {
            field: "IconFile",
            label: t("İkon Dosyası"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentIconFile",
              multiple: false,
              title: t("İkon Dosyası Yükleyiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPrivilegeButton;
