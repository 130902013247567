import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { ParameterDto } from "src/store/web/type";
import { getParameters, updateParameters } from "src/store/web/saga";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};

const ParameterPages = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<ParameterDto>();
  useEffect(() => {
    dispatch(
      getParameters({
        payload: {
          onSuccess: (msg, r) => setData(r),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Parametreler")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Parametreler")}
            breadcrumbItem={t("Parametreler")}
          />

          <Row className="mt-4">
            {loader && <Loader />}
            {!loader && (
              <CustomFormik
                loader
                initialValues={{
                  IsActivePaymentInstallment: data
                    ? data.IsActivePaymentInstallment
                    : false,
                  EmailNotificationDayValue: data
                    ? data.EmailNotificationDayValue
                    : 0,
                  SiteCacheUrl: data ? data.SiteCacheUrl ?? "" : "",
                  PriceCalculationCommissionValue: data
                    ? data.PriceCalculationCommissionValue
                    : 0,
                  MemberCancellationMonth: data
                    ? data.MemberCancellationMonth
                    : 0,
                  MemberCancellationPercent: data
                    ? data.MemberCancellationPercent
                    : 0,
                  ShowProductStore: data ? data.ShowProductStore : false,
                  ShowPrivilegeStore: data ? data.ShowPrivilegeStore : false,
                  ShowTireStore: data ? data.ShowTireStore : false,
                  ShowStore: data ? data.ShowStore : false,

                  CurrentProductStoreImageFile: data
                    ? data.ProductStoreImage ?? ""
                    : "",
                  ProductStoreImageFile: "",

                  CurrentPrivilegeStoreImageFile: data
                    ? data.ProductStoreImage ?? ""
                    : "",
                  PrivilegeStoreImageFile: "",

                  CurrentTireStoreImageFile: data
                    ? data.TireStoreImage ?? ""
                    : "",
                  TireStoreImageFile: "",
                }}
                onSubmit={values => {
                  dispatch(
                    updateParameters({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          setData(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({})}
                inputs={[
                  {
                    label: "Site Cache Url",
                    field: t("SiteCacheUrl"),
                  },
                  {
                    field: "PriceCalculationCommissionValue",
                    label: t("Fiyat Hesaplam Komisyon Değeri"),
                    inputType: InputType.number,
                  },
                  {
                    field: "EmailNotificationDayValue",
                    label: t(
                      "E-posta doğrulama bildirimi kayıt olduktan kaç gün sonra gönderileceği değer"
                    ),
                    inputType: InputType.number,
                  },
                  {
                    field: "MemberCancellationMonth",
                    label: t(
                      "Üyelik sonlandırılmak istediğinde kaç aydan küçükse tüm fark baz alınacak?"
                    ),
                    inputType: InputType.number,
                  },
                  {
                    field: "MemberCancellationPercent",
                    label: t(
                      "Üyelik sonlandırılmak istediğinde x ay'dan büyükse yüzde kaç oranda ödeme alınacak?"
                    ),
                    inputType: InputType.number,
                  },
                  {
                    field: "IsActivePaymentInstallment",
                    label: t("Yıllık paketlerde taksitli ödemeyi aktif et"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowStore",
                    label: t("Mağazayı Aktif/Pasif Et"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowTireStore",
                    label: t("Lastik Mağazasını Aktif/Pasif Et"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowPrivilegeStore",
                    label: t("Hizmet Mağazasını Aktif/Pasif Et"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowProductStore",
                    label: t("Ürün Mağazasını Aktif Et"),
                    inputType: InputType.checkbox,
                  },

                  {
                    col: 4,
                    field: "PrivilegeStoreImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentPrivilegeStoreImageFile",
                      accept: ["image/jpeg", "image/png"],
                      multiple: false,
                      title: t("Hizmet Mağaza Fotoğrafı Yükle"),
                    },
                  },
                  {
                    col: 4,
                    field: "ProductStoreImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentProductStoreImageFile",
                      accept: ["image/jpeg", "image/png"],
                      multiple: false,
                      title: t("Ürün Mağaza Fotoğrafı Yükle"),
                    },
                  },
                  {
                    col: 4,
                    field: "TireStoreImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentTireStoreImageFile",
                      accept: ["image/jpeg", "image/png"],
                      multiple: false,
                      title: t("Lastik Mağaza Fotoğrafı Yükle"),
                    },
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ParameterPages;
