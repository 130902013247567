import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireSaleDto, TireSaleDetailDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";
import { BodyType } from "src/store/base/request/request";

export const getTireSaleList = createAction(
  "GET_TIRESALE_LIST",
  ({ payload, params }: SagaActionParams<TireSaleDto[]>) => ({
    payload,
    url: "/api/tiresales" + (params ? params : ""),
  })
);
export const getTireSaleDetails = createAction(
  "GET_TIRESALE_DETAILS",
  ({ payload, id }: SagaActionParams<TireSaleDetailDto>) => ({
    payload,
    url: "/api/tiresales/" + id,
  })
);
export const createTireSale = createAction(
  "CREATE_TIRESALE_DETAILS",
  ({ payload, body }: SagaActionParams<TireSaleDto>) => ({
    payload,
    url: "/api/tiresales",
    body,
  })
);
export const updateTireSale = createAction(
  "UPDATE_TIRESALE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSaleDto>) => ({
    payload,
    url: "/api/tiresales/" + id,
    body,
  })
);
export const deleteTireSale = createAction(
  "DELETE_TIRESALE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSaleDto>) => ({
    payload,
    url: "/api/tiresales/" + id,
    body,
  })
);
export const updateTireSaleStatus = createAction(
  "UPDATE_TIRE_SALE_STATUS",
  ({ payload, body, url }: SagaActionParams<TireSaleDto>) => ({
    payload,
    url: url,
    body,
    bodyType: BodyType.raw,
  })
);
const tiresalesSaga = [
  takeLatest(
    getTireSaleList.toString(),
    (payload: SagaGenericParams<TireSaleDto[]>) =>
      getListSaga<TireSaleDto[]>(payload)
  ),

  takeLatest(
    getTireSaleDetails.toString(),
    (payload: SagaGenericParams<TireSaleDetailDto>) =>
      getListSaga<TireSaleDetailDto>(payload)
  ),
  takeLatest(
    createTireSale.toString(),
    (payload: PostSagaGenericParams<TireSaleDto>) =>
      postSaga<TireSaleDto>(payload)
  ),

  takeLatest(
    updateTireSale.toString(),
    (payload: PostSagaGenericParams<TireSaleDto>) =>
      putSaga<TireSaleDto>(payload)
  ),
  takeLatest(
    updateTireSaleStatus.toString(),
    (payload: PostSagaGenericParams<TireSaleDto>) =>
      putSaga<TireSaleDto>(payload)
  ),
  takeLatest(
    deleteTireSale.toString(),
    (payload: SagaGenericParams<TireSaleDto>) =>
      deleteSaga<TireSaleDto>(payload)
  ),
];

export default tiresalesSaga;
