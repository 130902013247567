import moment from "moment";
import React, { useEffect } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { getCurrency } from "src/helpers/getCurrency";
import { getDashboardFirstPackage } from "src/store/dashboard/saga";
import { DashboardFirstPurchasedCustomerDto } from "src/store/dashboard/type";

const FirstPackageList = () => {
  const [data, setData] = React.useState<DashboardFirstPurchasedCustomerDto[]>(
    []
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardFirstPackage({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <React.Fragment>
      <Card style={{ height: "563px" }}>
        <div className="card-header fw-bold text-center">
          İlk Kez Paket Alanlar Listesi
        </div>

        <CardBody className="p-0">
          <ScrollBar style={{ height: "470px" }}>
            <table className="table align-middle table-nowraptable table-hover table-bordered responsiveTable">
              <tbody>
                {data.map(x => (
                  <TransactionCard key={x.Id} data={x} />
                ))}
              </tbody>
            </table>
          </ScrollBar>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default FirstPackageList;
const TransactionCard = ({
  data,
}: {
  data: DashboardFirstPurchasedCustomerDto;
}) => {
  return (
    <tr className="">
      <td>{data.CustomerName}</td>
      <td>{data.PackageName}</td>
      {data.CustomerPurchasedPackages.length > 0 && (
        <>
          <td>
            {moment(data.CustomerPurchasedPackages[0].CreateTime).format(
              "DD/MM/yyyy HH:mm:ss"
            )}
          </td>
          <td>{getCurrency(data.CustomerPurchasedPackages[0].Price)}</td>
        </>
      )}
      <td style={{ fontSize: 16 }}>
        <a
          target="_blank"
          className="text-success"
          rel="noreferrer"
          href={"/customer/details/3/" + data.Id}
        >
          <i className="mdi mdi-account"></i>
        </a>
      </td>
    </tr>
  );
};
