import React, { useCallback, useEffect, useState } from "react";
import { Row, Card, CardBody, Col } from "reactstrap";
import {
  TicketDetailDto,
  TicketHistoryDto,
  TicketSourceTypeEnum,
  TicketStatusDto,
} from "src/store/ticket/type";
import PerfectScrollbar from "react-perfect-scrollbar";
import TicketDetailCard from "./comp/detailCard";
import TicketMessageContainer from "./comp/ticketMessage";
import CreateTicketAnswer from "./comp/ticketAnswer";
import TicketDetailToolbar from "./comp/ticketToolbar";
import TicketFilesModal from "./comp/ticket-files";
import TicketHistories from "./comp/histories";
import NotCustomerTicketDetailCard from "./comp/notCustomerDetailCard";
import TicketLevelComp from "./comp/ticketLevel";
import { useDispatch } from "react-redux";
import { getTicketHistories } from "src/store/ticket/saga";

const EmailRead = ({
  ticket,
  refreshData,
  status,
}: {
  ticket: TicketDetailDto;
  refreshData: () => void;
  status: TicketStatusDto[];
}) => {
  const [histories, setHistories] = useState<TicketHistoryDto[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    ticket.TicketSourceTypeId === TicketSourceTypeEnum.CallLog &&
      dispatch(
        getTicketHistories({
          payload: {
            onSuccess: (msg, py) => setHistories(py),
            onError: () => {},
          },
          id: ticket.Id,
        })
      ); // eslint-disable-next-line
  }, [dispatch]);
  const [messageBox, setMessageBox] = useState<any>(null);
  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);
  useEffect(() => {
    scrollToBottom();
  }, [ticket, scrollToBottom]);
  return (
    <React.Fragment>
      <TicketDetailToolbar
        refreshData={() => {
          dispatch(
            getTicketHistories({
              payload: {
                onSuccess: (msg, py) => setHistories(py),
                onError: () => {},
              },
              id: ticket.Id,
            })
          );
          return refreshData();
        }}
        ticket={ticket}
        status={status}
      />
      <Card>
        <CardBody style={{ padding: "0rem" }}>
          <div className="d-flex justify-content-between align-items-center mb-2 bg-light p-2">
            <div className="flex-1">
              <h5 className="font-size-16 my-1">
                {ticket.TicketLevelId && (
                  <TicketLevelComp levelId={ticket.TicketLevelId} />
                )}{" "}
                #{ticket.Code}
                <span className="ms-2">{ticket.TicketStatusType}</span>
                {" - "} {ticket.TicketSourceTypeName}
              </h5>
            </div>
            <div>
              <TicketFilesModal ticketId={ticket.Id} />
            </div>
          </div>
          <Row className="p-2">
            {ticket.TicketSourceTypeId === TicketSourceTypeEnum.Mobil && (
              <Col md={9}>
                <div className="" style={{ height: "calc(72vh - 182px)" }}>
                  <PerfectScrollbar
                    className="chat-conversation px-4"
                    style={{ height: "100%" }}
                    containerRef={ref => setMessageBox(ref)}
                  >
                    <div>
                      {ticket.TicketAnswers.map(x => (
                        <TicketMessageContainer
                          key={x.Id}
                          ticket={ticket}
                          message={x}
                        />
                      ))}
                    </div>
                  </PerfectScrollbar>
                </div>
                {!(
                  ticket.TicketStatusTypeId === 3 ||
                  ticket.TicketStatusTypeId === 4
                ) && (
                  <>
                    <CreateTicketAnswer
                      refreshData={() => refreshData()}
                      ticket={ticket}
                    />
                  </>
                )}
              </Col>
            )}
            {ticket.TicketSourceTypeId === TicketSourceTypeEnum.CallLog && (
              <Col md={9}>
                <div className="" style={{ height: "calc(72vh - 182px)" }}>
                  <PerfectScrollbar
                    className="chat-conversation"
                    style={{ height: "100%" }}
                  >
                    <div>
                      <TicketHistories histories={histories} />
                    </div>
                  </PerfectScrollbar>
                </div>
              </Col>
            )}
            <Col md={3} className="border-left">
              {ticket.CustomerId ? (
                <TicketDetailCard ticket={ticket} />
              ) : (
                <NotCustomerTicketDetailCard ticket={ticket} />
              )}
            </Col>
          </Row>
          {/* <Row>
            <Col xl="2" xs="6">
              <Card className="border shadow-none">
                <img
                  className="card-img-top img-fluid"
                  src={img3}
                  alt="Samply"
                />
                <div className="py-2 text-center">
                  <Link to="" className="fw-semibold font-size-13 text-reset">
                    Download
                    <i className="bx bxs-download align-middle"></i>
                  </Link>
                </div>
              </Card>
            </Col>
            <Col xl="2" xs="6">
              <Card className="border shadow-none">
                <img
                  className="card-img-top img-fluid"
                  src={img4}
                  alt="Samply"
                />
                <div className="py-2 text-center">
                  <Link to="" className="fw-semibold font-size-13 text-reset">
                    Download
                    <i className="bx bxs-download align-middle"></i>
                  </Link>
                </div>
              </Card>
            </Col>
          </Row> */}{" "}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EmailRead;
