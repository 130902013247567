import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PackageGiftDto } from "./type";
import { BodyType } from "../base/request/request";

export const changePackageGiftOrder = createAction(
  "UPDATE_PACKAGE_GIFT_ORDER",
  ({ payload, body }: SagaActionParams<PackageGiftDto>) => ({
    payload,
    url: "/api/packagegiftprivilages/order",

    bodyType: BodyType.raw,
    body,
  })
);
export const getPackageGiftList = createAction(
  "GET_PACKAGE_GIFT_LIST",
  ({ payload, id }: SagaActionParams<PackageGiftDto[]>) => ({
    payload,
    url: "/api/packagegiftprivilages/getbypackageid/" + id,
  })
);
export const getPackageGiftDetails = createAction(
  "GET_PACKAGE_GIFT_DETAILS",
  ({ payload, id }: SagaActionParams<PackageGiftDto>) => ({
    payload,
    url: "/api/packagegiftprivilages/" + id,
  })
);
export const createPackageGift = createAction(
  "CREATE_PACKAGE_GIFT",
  ({ payload, body }: SagaActionParams<PackageGiftDto>) => ({
    payload,
    url: "/api/packagegiftprivilages",
    body,
  })
);
export const updatePackageGift = createAction(
  "UPDATE_PACKAGE_GIFT",
  ({ payload, body, id }: SagaActionParams<PackageGiftDto>) => ({
    payload,
    url: "/api/packagegiftprivilages/" + id,
    body,
  })
);
export const deletePackageGift = createAction(
  "DELETE_PACKAGE_GIFT",
  ({ payload, body, id }: SagaActionParams<PackageGiftDto>) => ({
    payload,
    url: "/api/packagegiftprivilages/" + id,
    body,
  })
);

const packageGiftSaga = [
  takeLatest(
    changePackageGiftOrder.toString(),
    (payload: PostSagaGenericParams<PackageGiftDto>) =>
      putSaga<PackageGiftDto>(payload)
  ),
  takeLatest(
    getPackageGiftDetails.toString(),
    (payload: SagaGenericParams<PackageGiftDto[]>) =>
      getListSaga<PackageGiftDto[]>(payload)
  ),
  takeLatest(
    getPackageGiftList.toString(),
    (payload: SagaGenericParams<PackageGiftDto[]>) =>
      getListSaga<PackageGiftDto[]>(payload)
  ),
  takeLatest(
    createPackageGift.toString(),
    (payload: PostSagaGenericParams<PackageGiftDto>) =>
      postSaga<PackageGiftDto>(payload)
  ),
  takeLatest(
    updatePackageGift.toString(),
    (payload: PostSagaGenericParams<PackageGiftDto>) =>
      putSaga<PackageGiftDto>(payload)
  ),
  takeLatest(
    deletePackageGift.toString(),
    (payload: SagaGenericParams<PackageGiftDto>) =>
      deleteSaga<PackageGiftDto>(payload)
  ),
];

export default packageGiftSaga;
