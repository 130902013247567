import { RadioGrupProps } from "../type";

const CustomTabGroup = ({
  label,
  data,
  error,
  labelKey,
  valueKey,
  field,
  value,
  touched,
  onChange,
  onBlur,
  disabled,
}: RadioGrupProps) => {
  return (
    <div className=" my-2">
      {label && (
        <label
          className={
            error && touched ? "errorLabel customlabel" : "customlabel"
          }
          htmlFor={field}
        >
          {label}
        </label>
      )}
      {/* <RadioGroup value={value} onChange={value => onChange && onChange(value)}>
        {data.map((x: any) => (
          <Radio value={x[valueKey]}>
            <label className={"customlabel"}>{x[labelKey]}</label>
          </Radio>
        ))}
      </RadioGroup> */}
      <div className="w-100  border d-flex justify-content-between align-items-center">
        {data.map((x: any) => (
          <div
            className={
              `w-50 flex-grow-1 text-center pt-1 cursor-pointer ` +
              (x[valueKey] === value ? "bg-primary" : "")
            }
            onClick={() => !disabled && onChange && onChange(x[valueKey], x)}
          >
            <label className={"customlabel"}>{x[labelKey]}</label>
          </div>
        ))}
      </div>

      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomTabGroup;
