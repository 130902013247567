import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import {
  getCustomerVehicleFullData,
  getCustomerVehicleSubCategory,
} from "src/store/customervehicles/saga";
import {
  CustomerVehicleLookupDto,
  VehicleStepDto,
} from "src/store/customervehicles/type";

const VehicleInput = ({
  list,
  i,
}: {
  list: CustomerVehicleLookupDto;
  i: number;
}) => {
  const { values } = useFormikContext<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <GetInput
        inputprop={{
          col: 4,
          field: list.nextStep[0].rel,
          label: t(list.nextStep[0].rel),
          inputType: InputType.multiselect,

          lookup: {
            placement: "topStart",
            data: list.nextStep.map(x => {
              return {
                value: x.name ?? x.summary,
                label: x.name ?? x.summary,
                href: x.href,
                rel: x.rel,
                summary: x.summary,
                templated: x.templated,
                type: x.type,
                description: x.description,
                name: x.name,
              };
            }),
            cleanable: false,
          },

          onChange: (value, item: VehicleStepDto, setFieldValue) => {
            dispatch(
              getCustomerVehicleSubCategory({
                payload: {
                  onSuccess: (ms, payload) => {
                    if (payload.nextStep.length <= 0) {
                      dispatch(
                        getCustomerVehicleFullData({
                          payload: {
                            onSuccess: (ms, payload) => {
                              setFieldValue("vehicleData", payload);
                            },
                            onError: () => {},
                          },
                          body: {
                            url: item.href,
                          },
                        })
                      );
                    } else {
                      setFieldValue("DataArray", [
                        ...values.DataArray.filter((x: any, y: any) => y <= i),
                        payload,
                      ]);
                    }
                  },
                  onError: () => {},
                },
                body: {
                  url: item.href,
                },
              })
            );
          },
        }}
      />
    </React.Fragment>
  );
};

export default VehicleInput;
