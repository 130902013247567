import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { OrderCancellationReasonDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getOrderCancellationReasonList = createAction(
  "GET_ORDER_CANCELLATION_REASON_LIST",
  ({ payload, params }: SagaActionParams<OrderCancellationReasonDto[]>) => ({
    payload,
    url: "/api/ordercancellationreasons" + (params ? params : ""),
  })
);
export const getOrderCancellationReasonDetails = createAction(
  "GET_ORDER_CANCELLATION_REASON_DETAILS",
  ({ payload, id }: SagaActionParams<OrderCancellationReasonDto>) => ({
    payload,
    url: "/api/ordercancellationreasons/" + id,
  })
);
export const createOrderCancellationReason = createAction(
  "CREATE_ORDER_CANCELLATION_REASON_DETAILS",
  ({ payload, body }: SagaActionParams<OrderCancellationReasonDto>) => ({
    payload,
    url: "/api/ordercancellationreasons",
    body,
  })
);
export const updateOrderCancellationReason = createAction(
  "UPDATE_ORDER_CANCELLATION_REASON_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderCancellationReasonDto>) => ({
    payload,
    url: "/api/ordercancellationreasons/" + id,
    body,
  })
);
export const deleteOrderCancellationReason = createAction(
  "DELETE_ORDER_CANCELLATION_REASON_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderCancellationReasonDto>) => ({
    payload,
    url: "/api/ordercancellationreasons/" + id,
    body,
  })
);
const orderCancellationReasonSaga = [
  takeLatest(
    getOrderCancellationReasonList.toString(),
    (payload: SagaGenericParams<OrderCancellationReasonDto[]>) =>
      getListSaga<OrderCancellationReasonDto[]>(payload)
  ),
  takeLatest(
    getOrderCancellationReasonDetails.toString(),
    (payload: SagaGenericParams<OrderCancellationReasonDto>) =>
      getListSaga<OrderCancellationReasonDto>(payload)
  ),
  takeLatest(
    createOrderCancellationReason.toString(),
    (payload: PostSagaGenericParams<OrderCancellationReasonDto>) =>
      postSaga<OrderCancellationReasonDto>(payload)
  ),
  takeLatest(
    updateOrderCancellationReason.toString(),
    (payload: PostSagaGenericParams<OrderCancellationReasonDto>) =>
      putSaga<OrderCancellationReasonDto>(payload)
  ),
  takeLatest(
    deleteOrderCancellationReason.toString(),
    (payload: SagaGenericParams<OrderCancellationReasonDto>) =>
      deleteSaga<OrderCancellationReasonDto>(payload)
  ),
];

export default orderCancellationReasonSaga;
