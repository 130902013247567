import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  createSystemReminderUsers,
  getUsersLookup,
} from "src/store/system-reminder-users/saga";
import { UserDto } from "src/store/user/type";
import * as Yup from "yup";

const CreateSystemReminderUser = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getUsersLookup({
          payload: {
            onSuccess: (msg, py) => {
              setUsers(py);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [open, dispatch]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Sistem Hatırlatıcısı Kullanıcıları"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            createSystemReminderUsers({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        initialValues={{
          ReminderUserId: "",
          Email: true,
          Sms: true,
        }}
        validationSchema={Yup.object().shape({
          ReminderUserId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "ReminderUserId",
            inputType: InputType.multiselect,
            label: t("Sorumlu Kişi"),
            lookup: {
              data: users,
              labelKey: "FullName",
              valueKey: "Id",
              placeholder: t("Kişi"),
            },
          },
          {
            field: "Email",
            label: t("Email"),
            inputType: InputType.checkbox,
          },
          {
            field: "Sms",
            label: t("Sms"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateSystemReminderUser;
