import { useState } from "react";
import { useSelector } from "react-redux";
import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useFormikContext } from "formik";
import Input from ".";
import { inputKey } from "../type";
import { languagesSelector } from "src/store/languages";

const MultiLanguageInput = ({ inputKeys }: { inputKeys: inputKey[] }) => {
  const languages = useSelector(languagesSelector);
  const [multiple, setMultiple] = useState(false);
  const { setFieldValue, handleBlur, values, errors, touched } =
    useFormikContext<any>();
  const [activeTab, setactiveTab] = useState(languages[0].EnumId);
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  return (
    <>
      {!multiple ? (
        inputKeys.map((key, i) => (
          <Input
            field={key.field}
            key={i}
            label={key.label}
            error={errors[key.field]}
            touched={touched[key.field]}
            value={values[key.field]}
            onBlur={handleBlur(key.field)}
            onChange={e => {
              languages.map((lang, i) => {
                setFieldValue(`${key.field}`, e);
                return setFieldValue(`Translates[${i}].${key.field}`, e);
              });
            }}
          />
        ))
      ) : (
        <>
          <Nav tabs>
            {languages.map(lang => (
              <NavItem key={lang.EnumId}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === lang.EnumId,
                  })}
                  onClick={() => {
                    toggle(lang.EnumId);
                  }}
                >
                  {lang.Name}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab} className="px-3 pt-3 text-muted">
            {languages.map(lang => {
              var currentIndex = values.Translates.findIndex((x: any) => {
                return x.LanguageId === lang.EnumId;
              });
              return (
                <TabPane tabId={lang.EnumId} key={lang.EnumId}>
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        {inputKeys.map(key => (
                          <Input
                            field={key.field}
                            key={currentIndex}
                            error={errors[key.field]}
                            label={key.label}
                            touched={touched[key.field]}
                            value={values.Translates[currentIndex][key.field]}
                            onChange={e => {
                              setFieldValue(`${key.field}`, e);
                              setFieldValue(
                                `Translates[${currentIndex}].${key.field}`,
                                e
                              );
                            }}
                            onBlur={handleBlur(
                              `Translates[${currentIndex}].${key.field}`
                            )}
                          />
                        ))}
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              );
            })}
          </TabContent>
        </>
      )}

      <div className="d-flex justify-content-end">
        <button
          style={{
            backgroundColor: "transparent",
            marginInlineEnd: 10,
            fontSize: 11,
            marginBlockEnd: 5,
          }}
          onClick={() => setMultiple(!multiple)}
        >
          {!multiple ? "Çoklu Dil için Özelleştir" : "Tek Dil için Özelleştir"}
        </button>
      </div>
    </>
  );
};

export default MultiLanguageInput;
