import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as Type from "./type";

export const getDashboardCustomersSaga = createAction(
  "GET_DASHBOARD_CUSTOMER_SAGA",
  ({ payload, params }: SagaActionParams<Type.DashboardCustomerResultDto>) => ({
    payload,
    url: "/api/dashboard/customers" + (params ? params : ""),
  })
);
export const getDashboardHistoryReportSaga = createAction(
  "GET_DASHBOARD_HISTORY_REPORT_SAGA",
  ({ payload, params }: SagaActionParams<Type.DashboardHistoryReport[]>) => ({
    payload,
    url: "/api/dashboard/customer-login-history" + (params ? params : ""),
  })
);

export const getDashboardTicketsSaga = createAction(
  "GET_DASHBOARD_TICKETS_SAGA",
  ({ payload, params }: SagaActionParams<Type.DashboardTicketDto[]>) => ({
    payload,
    url: "/api/dashboard/tickets" + (params ? params : ""),
  })
);

export const getDashboardIsparkCouponSaga = createAction(
  "GET_DASHBOARD_ISPARK_COUPON_SAGA",
  ({ payload, params }: SagaActionParams<Type.DashboardIsparkCouponDto[]>) => ({
    payload,
    url: "/api/dashboard/ispark-coupons" + (params ? params : ""),
  })
);

export const getDashboardPeriods = createAction(
  "GET_DASHBOARD_PERIODS",
  ({ payload }: SagaActionParams<Type.DashboardPeriodTypeDto[]>) => ({
    payload,
    url: "/api/dashboard/periods",
  })
);
export const getDashboardFirstPackage = createAction(
  "GET_DASHBOARD_FIRST_PACKAGE",
  ({
    payload,
    params,
  }: SagaActionParams<Type.DashboardFirstPurchasedCustomerDto[]>) => ({
    payload,
    url: "/api/dashboard/first-purchased-packages",
  })
);
export const getChartTransactions = createAction(
  "GET_CHART_TRANSACTIONS",
  ({ payload, params }: SagaActionParams<Type.DashboardTransactionDto>) => ({
    payload,
    url: "/api/dashboard/chart-transactions" + (params ? params : ""),
  })
);

export const getDashboardDailyReports = createAction(
  "GET_DASHBOARD_DAILY_REPORTS",
  ({ payload, params }: SagaActionParams<any[]>) => ({
    payload,
    url: "/api/dashboard/daily-report",
  })
);

export const getMyTickets = createAction(
  "GET_MY_TICKETS",
  ({ payload, params }: SagaActionParams<Type.DashboardTicketDto[]>) => ({
    payload,
    url: "/api/dashboard/my-tickets" + (params ? params : ""),
  })
);
export const getMyCalllogs = createAction(
  "GET_MY_CALLLOGS",
  ({ payload, params }: SagaActionParams<Type.DashboardTicketDto[]>) => ({
    payload,
    url: "/api/dashboard/my-calllogs" + (params ? params : ""),
  })
);
export const getDashboardCalllogs = createAction(
  "GET_DASHBOARD_CALLLOGS",
  ({ payload, params }: SagaActionParams<Type.DashboardTicketDto[]>) => ({
    payload,
    url: "/api/dashboard/calllogs" + (params ? params : ""),
  })
);
const dashboardSaga = [
  takeLatest(
    getDashboardCustomersSaga.toString(),
    (payload: SagaGenericParams<Type.DashboardCustomerResultDto>) =>
      getListSaga<Type.DashboardCustomerResultDto>(payload)
  ),
  takeLatest(
    getDashboardTicketsSaga.toString(),
    (payload: SagaGenericParams<Type.DashboardTicketDto[]>) =>
      getListSaga<Type.DashboardTicketDto[]>(payload)
  ),
  takeLatest(
    getDashboardIsparkCouponSaga.toString(),
    (payload: SagaGenericParams<Type.DashboardIsparkCouponDto[]>) =>
      getListSaga<Type.DashboardIsparkCouponDto[]>(payload)
  ),
  takeLatest(
    getDashboardHistoryReportSaga.toString(),
    (payload: SagaGenericParams<Type.DashboardHistoryReport[]>) =>
      getListSaga<Type.DashboardHistoryReport[]>(payload)
  ),
  takeLatest(
    getDashboardPeriods.toString(),
    (payload: SagaGenericParams<Type.DashboardPeriodTypeDto[]>) =>
      getListSaga<Type.DashboardPeriodTypeDto[]>(payload)
  ),
  takeLatest(
    getDashboardFirstPackage.toString(),
    (payload: SagaGenericParams<Type.DashboardFirstPurchasedCustomerDto[]>) =>
      getListSaga<Type.DashboardFirstPurchasedCustomerDto[]>(payload)
  ),
  takeLatest(
    getChartTransactions.toString(),
    (payload: SagaGenericParams<Type.DashboardTransactionDto[]>) =>
      getListSaga<Type.DashboardTransactionDto[]>(payload)
  ),
  takeLatest(
    getDashboardDailyReports.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
  takeLatest(getMyTickets.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(getMyCalllogs.toString(), (payload: SagaGenericParams<any[]>) =>
    getListSaga<any[]>(payload)
  ),
  takeLatest(
    getDashboardCalllogs.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
];

export default dashboardSaga;
