import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateReminderSetting } from "src/store/reminder-settings/saga";
import { ReminderSettingDto } from "src/store/reminder-settings/type";
import * as Yup from "yup";

const EditReminderSettings = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: ReminderSettingDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Hatırlatıcı Ayarları Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          RemindText: data.RemindText,
          ReminderDayBefore: data.ReminderDayBefore,
          RemindTitle: data.RemindTitle,
          RemindType: data.ReminderTypeName,
        }}
        onSubmit={values => {
          dispatch(
            updateReminderSetting({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          RemindText: Yup.string().required(t("Required")),
          ReminderDayBefore: Yup.number().required(t("Required")),
          RemindTitle: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "RemindType",
            label: t("Hatırlatıcı Tipi"),
            disabled: () => true,
          },
          {
            field: "ReminderDayBefore",
            label: t("Kaç gün önce Hatırlatılacak"),
            inputType: InputType.number,
          },
          {
            field: "RemindTitle",
            label: t("Bildirim Başlığı"),
          },
          {
            field: "RemindText",
            label: t("Bildirim İçeriği"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditReminderSettings;
