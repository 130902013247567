import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import images from "src/assets/image";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import ImageCard from "src/components/ImageCard";
import { importMaintenanceDraft } from "src/store/maintenance/maintenance-drafts/saga";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierLookupDto } from "src/store/supplier/type";
import * as Yup from "yup";

const ImportMaintenanceDraft = ({
  onHide,
  open,
  refreshData,
}: {
  refreshData: () => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<SupplierLookupDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (msg, py) => setSuppliers(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          ExcelFile: "",
          SupplierId: "",
          ValidityDate: "",
        }}
        onSubmit={values => {
          dispatch(
            importMaintenanceDraft({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  refreshData();
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          ExcelFile: Yup.mixed().required(t("Required")),
          SupplierId: Yup.string().required(t("Required")),
          ValidityDate: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Comp",
            label: t(""),
            inputType: InputType.component,
            component: () => {
              return (
                <div className="w-100 d-flex flex-column aligns-items-center justify-content-center">
                  <ImageCard
                    src={images.maintenanceExcel}
                    title="Yüklenmesi Gereken Örnek Excel Dosyası"
                  />
                </div>
              );
            },
          },
          {
            field: "ExcelFile",
            label: t("Excel Dosyası"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ".xlsx",
              multiple: false,
              title: t("Excel Dosyası Yükleyiniz"),
            },
          },
          {
            field: "SupplierId",
            inputType: InputType.multiselect,
            label: t("İş Ortağı"),
            lookup: {
              data: suppliers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("İş Ortağı Seçiniz"),
            },
          },
          {
            field: "ValidityDate",
            label: t("Geçerlilik Tarihi"),
            inputType: InputType.datetime,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default ImportMaintenanceDraft;
