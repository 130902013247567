import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Container, Row, TabContent, TabPane } from "reactstrap";
import { useHistory, useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { PackageDetailDto } from "src/store/packages/type";
import { getPackageDetails } from "src/store/packages/saga";
import { Nav } from "rsuite";
import EditPackage from "./edit";
import PackagePrivilegeTab from "./privilege/privileges";
import PackageDiscountDefinitionTab from "./Discounts/discounts";
import PackageGiftTab from "./gift/gifts";
import PackageExtraTab from "./extra/extra-privileges";

const EditOpportunityPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, tab }: { id: string; tab: string } = useParams();
  const [active, setActive] = React.useState(tab);
  const { t } = useTranslation();
  const [packageData, setPackageData] = useState<PackageDetailDto>();
  const loader = useSelector(isLoading);
  useEffect(() => {
    dispatch(
      getPackageDetails({
        payload: {
          onSuccess: (msg, payload) => setPackageData(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {packageData ? packageData.PackageName : " "} {t("Package")}{" "}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Packages")}
            breadcrumbItem={packageData ? packageData.PackageName : ""}
          />
          <Nav
            className="mt-2"
            appearance="subtle"
            style={{ zIndex: 1 }}
            onSelect={e => {
              history.push("/packages/details/" + e + "/" + id);
              return setActive(e);
            }}
            activeKey={active}
          >
            <Nav.Item eventKey="1">{t("Package Info")}</Nav.Item>
            <Nav.Item eventKey="2">{t("Opportunities")}</Nav.Item>
            <Nav.Item eventKey="3">{t("Gift Privileges")}</Nav.Item>
            <Nav.Item eventKey="4">{t("Ek Hizmetler")}</Nav.Item>
            <Nav.Item eventKey="5">{t("Discount Definitions")}</Nav.Item>
          </Nav>
          {loader && <Loader />}
          <TabContent activeTab={active} className="mt-2">
            <TabPane tabId="1">
              {packageData && (
                <Row className="mt-4">
                  <Col md="12">
                    <EditPackage data={packageData} setData={setPackageData} />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="2" className="grid-tab-pane">
              {packageData && (
                <Row className="mt-4">
                  <Col md={12}>
                    <PackagePrivilegeTab
                      packageData={packageData}
                      setData={setPackageData}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>{" "}
            <TabPane tabId="3" className="grid-tab-pane">
              {packageData && (
                <Row className="mt-4">
                  <Col md={12}>
                    <PackageGiftTab
                      packageData={packageData}
                      setData={setPackageData}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="4" className="grid-tab-pane">
              {packageData && (
                <Row className="mt-4">
                  <Col md={12}>
                    <PackageExtraTab
                      packageData={packageData}
                      setData={setPackageData}
                    />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="5" className="grid-tab-pane">
              {packageData && (
                <Row className="mt-4">
                  <Col md={12}>
                    <PackageDiscountDefinitionTab packageData={packageData} />
                  </Col>
                </Row>
              )}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EditOpportunityPage;
