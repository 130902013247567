import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import DataTableWithPagination from "src/components/PageContent/DataGrid";
import { getCouponUsageHistoryListById } from "src/store/coupons/saga";
import { CouponDto } from "src/store/coupons/type";
import { CustomerAddressDto } from "src/store/customeraddress/type";

const CouponUsageHistories = ({ data }: { data: CouponDto }) => {
  const { t } = useTranslation();
  const { tab }: { tab: string } = useParams();
  const [address, setAddress] = useState<CustomerAddressDto[]>([]);
  const columns = [
    {
      text: t("Customer"),
      dataField: "CustomerName",
    },
    {
      text: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },

    {
      text: t("Coupon Name"),
      dataField: "CouponName",
    },

    {
      text: t("Total Amount"),
      dataField: "CouponCode",
    },
    {
      text: t("Discount Amount"),
      dataField: "DiscountAmount",
    },
    {
      text: t("Discount Rate"),
      dataField: "DiscountRate",
    },
    {
      text: t("Grand Total"),
      dataField: "GrandTotal",
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {tab === "1" && (
          <DataTableWithPagination
            title=" "
            HideBreadcrumb={true}
            listData={address}
            columns={columns}
            setDataList={setAddress}
            action={getCouponUsageHistoryListById}
            paramsid={data.Id}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default CouponUsageHistories;
