import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { DashboardIsparkCouponDto } from "src/store/dashboard/type";
import { useDispatch } from "react-redux";
import { getDashboardIsparkCouponSaga } from "src/store/dashboard/saga";
import { getLocalDate } from "src/helpers/getLocalDate";
import ScrollBar from "react-perfect-scrollbar";

const DashboardIsparkCouponComponent = () => {
  const [data, setData] = useState<DashboardIsparkCouponDto[]>();
  const [total, setTotal] = useState<{
    TotalCount: number;
    Count: number;
  }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardIsparkCouponSaga({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
            setTotal(r);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      {data && total && (
        <Card className="dashboardCard">
          <CardBody>
            <CardTitle>İSPARK KUPON</CardTitle>
            <div className="text-center">
              <div className="">
                <i className=" mdi mdi-ticket text-warning display-4"></i>
              </div>
              <h3>{total.Count}</h3>
              <p className="">Kullanılan Kupon</p>
            </div>
            <ScrollBar style={{ height: "220px" }}>
              <div className=" table-responsive mt-4">
                <table className="table align-middle table-nowrap">
                  <tbody>
                    {data.map(x => (
                      <tr key={x.Id}>
                        <td>
                          <p className="mb-0">{x.CustomerName}</p>
                          <p
                            className="text-muted my-0 "
                            style={{ fontSize: 10 }}
                          >
                            #{x.QRCode}
                          </p>
                        </td>
                        <td className="" style={{ fontSize: 12 }}>
                          <p className="mb-0 text-primary">
                            {getLocalDate(x.CreateTime)} üretildi
                          </p>
                          <p className="my-0 text-success">
                            {getLocalDate(x.ValidDate)} kullanıldı
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollBar>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DashboardIsparkCouponComponent;
