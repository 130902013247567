import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getVehicleBrandLookup } from "src/store/maintenance/vehicle-brands/saga";
import { VehicleBrandDto } from "src/store/maintenance/vehicle-brands/type";
import { updateVehicleModel } from "src/store/maintenance/vehicle-models/saga";
import { VehicleModelDto } from "src/store/maintenance/vehicle-models/type";
import * as Yup from "yup";

const EditVehicleModel = ({
  data,
  onHide,
  open,
  onSuccessClick,
  id,
}: {
  id?: string;
  data: VehicleModelDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [brands, setBrands] = useState<VehicleBrandDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getVehicleBrandLookup({
          payload: {
            onSuccess: (msg, py) => setBrands(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Araç Markası Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateVehicleModel({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          Name: data.Name,
          VehicleBrandId: data.VehicleBrandId,
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          VehicleBrandId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            disabled: () => (id ? true : false),
            field: "VehicleBrandId",
            inputType: InputType.multiselect,
            label: t("Marka"),
            lookup: {
              data: brands,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Marka Seçiniz"),
            },
          },
          {
            field: "Name",
            label: t("Model Adı"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditVehicleModel;
