import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  AboutUsDto,
  ContactDto,
  EmailSettingDto,
  EuroMessageSettingDto,
  MtvSettingDto,
  ParameterDto,
  PaymentSettingDto,
  SiteConfigDto,
} from "./type";
import { BodyType } from "../base/request/request";

export const getAboutUs = createAction(
  "GET_ABOUT_US",
  ({ payload }: SagaActionParams<AboutUsDto>) => ({
    payload,
    url: "/api/aboutus",
  })
);
export const updateAboutUs = createAction(
  "UPDATE_ABOUT_US",
  ({ payload, body }: SagaActionParams<AboutUsDto>) => ({
    payload,
    url: "/api/aboutus",
    body,
    bodyType: BodyType.raw,
  })
);

export const getContacts = createAction(
  "GET_CONTACTS",
  ({ payload }: SagaActionParams<ContactDto[]>) => ({
    payload,
    url: "/api/contacts",
  })
);
export const updateContacts = createAction(
  "UPDATE_CONTACTS",
  ({ payload, body, id }: SagaActionParams<ContactDto[]>) => ({
    payload,
    url: "/api/contacts/" + id,
    body,
    bodyType: BodyType.raw,
  })
);

export const getSiteConfig = createAction(
  "GET_SITE_CONFIGS",
  ({ payload }: SagaActionParams<SiteConfigDto>) => ({
    payload,
    url: "/api/siteconfig",
  })
);
export const updateSiteConfig = createAction(
  "UPDATE_SITE_CONFIGS",
  ({ payload, body }: SagaActionParams<SiteConfigDto[]>) => ({
    payload,
    url: "/api/siteconfig",
    body,
    bodyType: BodyType.raw,
  })
);
export const getParameters = createAction(
  "GET_PARAMETERS",
  ({ payload }: SagaActionParams<ParameterDto>) => ({
    payload,
    url: "/api/parameters",
  })
);
export const updateParameters = createAction(
  "UPDATE_PARAMETERS",
  ({ payload, body }: SagaActionParams<ParameterDto>) => ({
    payload,
    url: "/api/parameters",
    body,
  })
);
export const getPaymentSettings = createAction(
  "GET_PAYMENT_SETTINGS",
  ({ payload }: SagaActionParams<PaymentSettingDto>) => ({
    payload,
    url: "/api/paymentsettings",
  })
);
export const updatePaymentSettings = createAction(
  "UPDATE_PAYMENT_SETTINGS",
  ({ payload, body }: SagaActionParams<PaymentSettingDto>) => ({
    payload,
    url: "/api/paymentsettings",
    body,
    bodyType: BodyType.raw,
  })
);

export const getMtvSettings = createAction(
  "GET_MTV_SETTINGS",
  ({ payload }: SagaActionParams<MtvSettingDto>) => ({
    payload,
    url: "/api/mtvsettings",
  })
);
export const updateMtvSettings = createAction(
  "UPDATE_MTV_SETTINGS",
  ({ payload, body }: SagaActionParams<MtvSettingDto>) => ({
    payload,
    url: "/api/mtvsettings",
    body,
    bodyType: BodyType.raw,
  })
);
export const clearCache = createAction(
  "CLEAR_CACHE",
  ({ payload }: SagaActionParams<any>) => ({
    payload,
    url: "/api/siteconfig/cacheclear",
  })
);

export const getEmailSettings = createAction(
  "GET_EMAIL_SETTINGS",
  ({ payload }: SagaActionParams<EmailSettingDto>) => ({
    payload,
    url: "/api/setting/email",
  })
);
export const updateEmailSettings = createAction(
  "UPDATE_EMAIL_SETTINGS",
  ({ payload, body }: SagaActionParams<EmailSettingDto>) => ({
    payload,
    url: "/api/setting/email",
    body,
    bodyType: BodyType.raw,
  })
);

export const getEuroMessageEmailSettings = createAction(
  "GET_EURO_MESSAGE_EMAIL_SETTINGS",
  ({ payload }: SagaActionParams<EuroMessageSettingDto>) => ({
    payload,
    url: "/api/setting/euro-message-setting",
  })
);
export const updateEuroMessageEmailSettings = createAction(
  "UPDATE_EURO_MESSAGE_EMAIL_SETTINGS",
  ({ payload, body }: SagaActionParams<EuroMessageSettingDto>) => ({
    payload,
    url: "/api/setting/euro-message-setting",
    body,
    bodyType: BodyType.raw,
  })
);
const webSaga = [
  takeLatest(clearCache.toString(), (payload: SagaGenericParams<any>) =>
    getListSaga<any>(payload)
  ),
  takeLatest(
    getAboutUs.toString(),
    (payload: SagaGenericParams<AboutUsDto[]>) =>
      getListSaga<AboutUsDto[]>(payload)
  ),
  takeLatest(
    updateAboutUs.toString(),
    (payload: PostSagaGenericParams<AboutUsDto[]>) =>
      putSaga<AboutUsDto[]>(payload)
  ),

  takeLatest(
    getContacts.toString(),
    (payload: SagaGenericParams<ContactDto[]>) =>
      getListSaga<ContactDto[]>(payload)
  ),
  takeLatest(
    updateContacts.toString(),
    (payload: PostSagaGenericParams<ContactDto[]>) =>
      putSaga<ContactDto[]>(payload)
  ),
  takeLatest(
    getParameters.toString(),
    (payload: SagaGenericParams<ParameterDto>) =>
      getListSaga<ParameterDto>(payload)
  ),
  takeLatest(
    updateParameters.toString(),
    (payload: PostSagaGenericParams<ParameterDto>) =>
      putSaga<ParameterDto>(payload)
  ),
  takeLatest(
    getPaymentSettings.toString(),
    (payload: SagaGenericParams<PaymentSettingDto>) =>
      getListSaga<PaymentSettingDto>(payload)
  ),
  takeLatest(
    updatePaymentSettings.toString(),
    (payload: PostSagaGenericParams<PaymentSettingDto>) =>
      putSaga<PaymentSettingDto>(payload)
  ),
  takeLatest(
    getSiteConfig.toString(),
    (payload: SagaGenericParams<SiteConfigDto[]>) =>
      getListSaga<SiteConfigDto[]>(payload)
  ),
  takeLatest(
    updateSiteConfig.toString(),
    (payload: PostSagaGenericParams<SiteConfigDto[]>) =>
      putSaga<SiteConfigDto[]>(payload)
  ),
  takeLatest(
    updateMtvSettings.toString(),
    (payload: PostSagaGenericParams<MtvSettingDto>) =>
      putSaga<MtvSettingDto>(payload)
  ),
  takeLatest(
    getMtvSettings.toString(),
    (payload: SagaGenericParams<MtvSettingDto>) =>
      getListSaga<MtvSettingDto>(payload)
  ),
  takeLatest(
    updateEmailSettings.toString(),
    (payload: PostSagaGenericParams<EmailSettingDto>) =>
      putSaga<EmailSettingDto>(payload)
  ),
  takeLatest(
    getEmailSettings.toString(),
    (payload: SagaGenericParams<EmailSettingDto>) =>
      getListSaga<EmailSettingDto>(payload)
  ),
  takeLatest(
    updateEuroMessageEmailSettings.toString(),
    (payload: PostSagaGenericParams<EuroMessageSettingDto>) =>
      putSaga<EuroMessageSettingDto>(payload)
  ),
  takeLatest(
    getEuroMessageEmailSettings.toString(),
    (payload: SagaGenericParams<EuroMessageSettingDto>) =>
      getListSaga<EuroMessageSettingDto>(payload)
  ),
];

export default webSaga;
