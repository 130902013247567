import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import images from "src/assets/image";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import ImageCard from "src/components/ImageCard";
import CustomModal from "src/components/Modal/modal";
import { getCustomerGroupList } from "src/store/customergroup/saga";
import { CustomerGroupDto } from "src/store/customergroup/type";
import { importExcelCustomer } from "src/store/customers/saga";
import { getPackageGiftList } from "src/store/packagegift/saga";
import { PackageGiftDto } from "src/store/packagegift/type";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const ImportCustomerExcel = ({
  onHide,
  open,
}: {
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [groups, setGroups] = useState<CustomerGroupDto[]>([]);
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [packagePrivileges, setPackagePrivileges] = useState<PackageGiftDto[]>(
    []
  );
  const [openResult, setOpenResult] = useState(false);
  const [result, setResult] = useState<string[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCustomerGroupList({
          payload: {
            onSuccess: (message, payload) => {
              setGroups(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (message, payload) => {
              setPackages(payload);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [open, dispatch]);
  return (
    <React.Fragment>
      <CustomModal
        open={openResult}
        onHide={() => setOpenResult(false)}
        title={"Uyarı"}
      >
        {result && result.length > 0 && (
          <>
            <p className="text-center text-success fw-bold font-size-14">
              Üye içeri aktarması başarıyla tamamlandı. Üyelikler oluşturuldu.
            </p>
            <div className="bg-soft-warning p-2">
              <p className="text-danger">
                Fakat Bazı üyeler sisteme kayıtlı olduğu için üyelik
                oluşturulamadı.
                <br />
                Önceden Kayıtlı üye listesi:
              </p>
              {result.map(x => (
                <p>{x}</p>
              ))}
            </div>
          </>
        )}
      </CustomModal>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CustomerGroupId: "",
          Yearly: false,
          PackageId: "",
          StartDate: "",
          ExcelFile: "",
          PackageGiftPrivilegeId: "",
        }}
        onSubmit={values => {
          dispatch(
            importExcelCustomer({
              payload: {
                onSuccess: (message, payload) => {
                  if (payload && payload.length > 0) {
                    setResult(payload);
                    onHide();
                    setOpenResult(true);
                  } else {
                    toast.success("Müşteriler başarıyla kaydedildi.");
                    onHide();
                  }
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          CustomerGroupId: Yup.string().required(t("Required")),
          PackageId: Yup.string().required(t("Required")),
          StartDate: Yup.string().required(t("Required")),
          ExcelFile: Yup.mixed().required(t("Required")),
        })}
        inputs={[
          {
            field: "Comp",
            label: t(""),
            inputType: InputType.component,
            component: () => {
              return (
                <div className="w-100 d-flex flex-column aligns-items-center justify-content-center">
                  <ImageCard
                    src={images.customerexcel}
                    title="Yüklenmesi Gereken Örnek Excel Dosyası"
                  />
                </div>
              );
            },
          },
          {
            field: "ExcelFile",
            label: t("Excel Dosyası"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ".xlsx",
              multiple: false,
              title: t("Excel Dosyası Yükleyiniz"),
            },
          },
          {
            field: "CustomerGroupId",
            inputType: InputType.multiselect,
            label: t("Üye Grubu"),
            lookup: {
              data: groups,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Grup Seçiniz"),
            },
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "PackageId",
            inputType: InputType.multiselect,
            label: t("Paket"),
            lookup: {
              data: packages,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Paket Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getPackageGiftList({
                    payload: {
                      onSuccess: (msg, payload) =>
                        setPackagePrivileges(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            field: "PackageGiftPrivilegeId",
            inputType: InputType.multiselect,
            label: t("Hediye Seçimi"),
            lookup: {
              data: packagePrivileges,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },

          {
            field: "Yearly",
            label: t("Yıllık"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default ImportCustomerExcel;
