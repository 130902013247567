import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import FileUpload from "src/components/Form/FileUpload";
import { uploadProductImage } from "src/store/orders/products-images/saga";
import { ProductDetailDto } from "src/store/orders/products/type";

const ProductImageUpload = ({
  data,
  open,
  setOpen,
  refreshData,
}: {
  data: ProductDetailDto;
  setOpen: (open: boolean) => void;
  open: boolean;
  refreshData: () => void;
}) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Modal isOpen={open} toggle={() => setOpen(false)} centered>
        <Formik
          initialValues={{
            ProductId: data.Id,
            Images: [],
          }}
          onSubmit={values => {
            dispatch(
              uploadProductImage({
                payload: {
                  onSuccess: m => {
                    toast.success(m);
                    refreshData();
                    setOpen(false);
                  },
                  onError: () => {},
                },
                body: values,
              })
            );
          }}
        >
          {({ touched, handleSubmit, setFieldValue, errors, values }) => (
            <>
              <ModalBody>
                <Row className="py-3 px-3">
                  <FileUpload
                    multiple={true}
                    onChange={files => {
                      setFieldValue("Images", files);
                    }}
                    title="Fotoğraf Ekle ya da Sürükle"
                  />
                </Row>
              </ModalBody>
              <ModalFooter>
                <div className={"d-flex mt-2  justify-content-between  w-100"}>
                  <Button
                    color="danger"
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    onClick={() => {
                      return setOpen(false);
                    }}
                  >
                    {"Kapat"}
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {"Kaydet"}
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default ProductImageUpload;
