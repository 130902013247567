import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateTireDiameter } from "src/store/tires/diameters/saga";
import { TireDiameterDto } from "src/store/tires/diameters/type";
import * as Yup from "yup";

const EditTireDiameter = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: TireDiameterDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Lastik Çap Tanımı Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Value: data.Value,
        }}
        onSubmit={values => {
          dispatch(
            updateTireDiameter({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Value: Yup.string().min(1, t("Required")).required(t("Required")),
        })}
        inputs={[
          {
            field: "Value",
            label: t("Value"),
            inputType: InputType.number,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditTireDiameter;
