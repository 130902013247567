import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createProductCategory } from "src/store/orders/product-categories/saga";
import { ProductCategoryDto } from "src/store/orders/product-categories/type";
import * as Yup from "yup";

const CreateProductCategory = ({
  onHide,
  open,
  parent,
  onSuccessClick,
}: {
  parent?: ProductCategoryDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const inputs = [
    {
      field: "Name",
      label: t("Name"),
    },
    {
      field: "ShortDescription",
      label: t("Açıklama"),
    },
    {
      field: "ImageFile",
      label: t("ImageFile Image"),
      inputType: InputType.fileUpload,
      fileUpload: {
        accept: ["image/jpeg", "image/png"],
        multiple: false,
        title: t("Please Upload Image"),
      },
    },
    {
      field: "Active",
      label: t("Active"),
      col: 6,
      checkedLabel: t("Active"),
      uncheckedLabel: t("Pasive"),
      inputType: InputType.toogle,
    },
  ];
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Yeni Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Name: "",
          ParentCategoryId: id ? id : "",
          ParentName: parent
            ? parent.ParentCategories.map(x => x.Name).join(" / ")
            : "",
          ImageFile: "",
          ShortDescription: "",
          Active: false,
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
        })}
        inputs={
          parent
            ? [
                ...inputs,
                {
                  field: "ParentName",
                  label: t("Ana Kategoriler"),
                  disabled(values) {
                    return true;
                  },
                },
              ]
            : inputs
        }
        onSubmit={values => {
          dispatch(
            createProductCategory({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
      />
    </React.Fragment>
  );
};
export default CreateProductCategory;
