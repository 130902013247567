import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { LocalizationDto } from "./type";

export const getLocalizations = createAction(
  "GET_LOCALIZATION",
  ({ payload, params }: SagaActionParams<LocalizationDto[]>) => ({
    payload,
    url: "/api/localizations" + (params ? params : ""),
  })
);
export const getFrontendLocalizations = createAction(
  "GET_LOCALIZATION_FRONTEND",
  ({ payload, params }: SagaActionParams<LocalizationDto[]>) => ({
    payload,
    url: "/api/localizations/frontend" + (params ? params : ""),
  })
);
export const getBackendLocalizations = createAction(
  "GET_LOCALIZATION_BACKEND",
  ({ payload, params }: SagaActionParams<LocalizationDto[]>) => ({
    payload,
    url: "/api/localizations/backend" + (params ? params : ""),
  })
);
export const getLocalizationsDetails = createAction(
  "GET_LOCALIZATION_DETAILS",
  ({ payload, id }: SagaActionParams<LocalizationDto>) => ({
    payload,
    url: "/api/localizations/" + id,
  })
);
export const createLocalizations = createAction(
  "CREATE_LOCALIZATION",
  ({ payload, body }: SagaActionParams<LocalizationDto>) => ({
    payload,
    url: "/api/localizations",
    body,
  })
);
export const updateLocalizations = createAction(
  "UPDATE_LOCALIZATION",
  ({ payload, id, body }: SagaActionParams<LocalizationDto>) => ({
    payload,
    url: "/api/localizations/" + id,
    body,
  })
);

export const deleteLocalizations = createAction(
  "DELETE_LOCALIZATION",
  ({ payload, id }: SagaActionParams<LocalizationDto>) => ({
    payload,
    url: "/api/localizations/" + id,
  })
);

const localizationsSaga = [
  /*LOCALIZATION*/
  takeLatest(
    getLocalizations.toString(),
    (payload: SagaGenericParams<LocalizationDto[]>) =>
      getListSaga<LocalizationDto[]>(payload)
  ),
  takeLatest(
    getBackendLocalizations.toString(),
    (payload: SagaGenericParams<LocalizationDto[]>) =>
      getListSaga<LocalizationDto[]>(payload)
  ),
  takeLatest(
    getFrontendLocalizations.toString(),
    (payload: SagaGenericParams<LocalizationDto[]>) =>
      getListSaga<LocalizationDto[]>(payload)
  ),
  takeLatest(
    getLocalizationsDetails.toString(),
    (payload: SagaGenericParams<LocalizationDto>) =>
      getListSaga<LocalizationDto>(payload)
  ),
  takeLatest(
    createLocalizations.toString(),
    (payload: PostSagaGenericParams<LocalizationDto>) =>
      postSaga<LocalizationDto>(payload)
  ),
  takeLatest(
    updateLocalizations.toString(),
    (payload: PostSagaGenericParams<LocalizationDto>) =>
      putSaga<LocalizationDto>(payload)
  ),

  takeLatest(
    deleteLocalizations.toString(),
    (payload: SagaGenericParams<any>) => deleteSaga<LocalizationDto>(payload)
  ),
];

export default localizationsSaga;
