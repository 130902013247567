import { FormikHelpers } from "formik";
import { ReactDatePickerProps } from "react-datepicker";
import {
  CascaderProps,
  CheckPickerProps,
  DrawerProps,
  SelectPickerProps,
} from "rsuite";

export interface Props {
  label: string;
  field: string;
  min?: any;
  max?: any;
  style?: any;
  className?: any;
  checkedLabel?: string;
  uncheckedLabel?: string;
  error?: any;
  disabled?: boolean;
  type?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  touched?: any;
  height?: string;
}
export interface RadioGrupProps {
  label: string;
  field: string;
  data: any[];
  error?: any;
  disabled?: boolean;
  labelKey: string;
  valueKey: string;
  type?: string;
  onChange?: (e: any, item?: any) => void;
  onBlur?: (e: any) => void;
  value: any;
  touched?: any;
}

export interface CustomFormikProps {
  topcomponent?: any;
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => void;
  inputs: inputKey[];
  multilanguageinputs?: inputKey[];
  validationSchema: any;
  hideButtons?: boolean;
  initialValues: any;
  loader?: boolean;
  component?: any;
  onHide?: () => void;
  createButtonClass?: string;
}

export interface CustomModalFormProps extends CustomFormikProps {
  modalProps: CustomModalProps;
}
export type inputKey = {
  hide?: (values: any) => boolean;
  field: string;
  label: string;
  className?: string;
  disabled?: (values: any) => boolean;
  type?: string;
  col?: any;
  min?: any;
  max?: any;
  height?: string;
  value?: (values: any) => any;
  error?: (errors: any) => any;
  touched?: (touched: any) => any;
  onplaceholder?: (values: any) => string;
  component?: (data?: any, setFieldValue?: any) => any;
  fileUpload?: {
    multiple: boolean;
    title: string;
    accept?: any;
    linkField?: string;
  };
  checkedLabel?: string;
  uncheckedLabel?: string;
  inputType?: InputType;
  lookup?: CustomSelectProps;
  cascader?: CascaderProps<any>;
  radiogrups?: {
    data: any[];
    labelKey: string;
    valueKey: string;
  };
  googlemap?: {
    latField: string;
    lonField: string;
  };
  dependedField?: string;
  onChange?: (
    value?: any,
    item?: any,
    setFieldValue?: any,
    values?: any
  ) => void;
  style?: any;
};
export interface CustomModalProps extends DrawerProps {
  title?: any;
  onHide: () => void;
  outsideClick?: boolean;
  footer?: any;
}
export interface CustomSelectProps extends SelectPickerProps<any> {
  showSelectAll?: boolean;
}
export interface CustomSelectPickerProps extends SelectPickerProps<any> {
  label: string;
  field: string;
  error?: any;
  touched?: any;
}
export interface CustomCascaderProps extends CascaderProps<any> {
  label: string;
  field: string;
  error?: any;
  touched?: any;
}
export interface CustomCheckPickerProps extends CheckPickerProps<any> {
  label: string;
  field: string;
  showSelectAll?: boolean;
  onSelectValue?: (value: any) => void;
  error?: any;
  touched?: any;
}
export interface CustomDatepickerProps extends ReactDatePickerProps<any> {
  label: string;
  field: string;
  error?: any;
  touched?: any;
}

export enum InputType {
  text = 1,
  number = 2,
  multiselect = 3,
  editor = 4,
  checkbox = 5,
  date = 6,
  datetime = 7,
  fileUpload = 8,
  multilinetext = 9,
  currency = 10,
  radiogrup = 11,
  toogle = 12,
  googlemap = 13,
  component = 14,
  checkpicker = 15,
  tabGroup = 16,
  cascader = 17,
}

export const FindInputType = (id: number) => {
  switch (id) {
    case 1:
      return InputType.text;
    case 2:
      return InputType.number;
    case 3:
      return InputType.multiselect;
    case 4:
      return InputType.editor;
    case 5:
      return InputType.checkbox;
    case 6:
      return InputType.date;
    case 7:
      return InputType.datetime;
    case 8:
      return InputType.fileUpload;
    case 9:
      return InputType.multilinetext;
    case 10:
      return InputType.currency;
    case 12:
      return InputType.radiogrup;
  }
};
