import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireServiceDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireServiceList = createAction(
  "GET_TIRE_SERVICE_LIST",
  ({ payload, params }: SagaActionParams<TireServiceDto[]>) => ({
    payload,
    url: "/api/tireservices" + (params ? params : ""),
  })
);
export const getTireServiceDetails = createAction(
  "GET_TIRE_SERVICE_DETAILS",
  ({ payload, id }: SagaActionParams<TireServiceDto>) => ({
    payload,
    url: "/api/tireservices/" + id,
  })
);
export const createTireService = createAction(
  "CREATE_TIRE_SERVICE_DETAILS",
  ({ payload, body }: SagaActionParams<TireServiceDto>) => ({
    payload,
    url: "/api/tireservices",
    body,
  })
);
export const updateTireService = createAction(
  "UPDATE_TIRE_SERVICE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireServiceDto>) => ({
    payload,
    url: "/api/tireservices/" + id,
    body,
  })
);
export const deleteTireService = createAction(
  "DELETE_TIRE_SERVICE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireServiceDto>) => ({
    payload,
    url: "/api/tireservices/" + id,
    body,
  })
);

const tireServiceSaga = [
  takeLatest(
    getTireServiceList.toString(),
    (payload: SagaGenericParams<TireServiceDto[]>) =>
      getListSaga<TireServiceDto[]>(payload)
  ),
  takeLatest(
    getTireServiceDetails.toString(),
    (payload: SagaGenericParams<TireServiceDto>) =>
      getListSaga<TireServiceDto>(payload)
  ),
  takeLatest(
    createTireService.toString(),
    (payload: PostSagaGenericParams<TireServiceDto>) =>
      postSaga<TireServiceDto>(payload)
  ),

  takeLatest(
    updateTireService.toString(),
    (payload: PostSagaGenericParams<TireServiceDto>) =>
      putSaga<TireServiceDto>(payload)
  ),
  takeLatest(
    deleteTireService.toString(),
    (payload: SagaGenericParams<TireServiceDto>) =>
      deleteSaga<TireServiceDto>(payload)
  ),
];

export default tireServiceSaga;
