import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDashboardPeriods } from "src/store/dashboard/saga";
import { DashboardPeriodTypeDto } from "src/store/dashboard/type";

const PeriodComp = ({
  setCurrentPeriod,
  currentPeriod,
}: {
  currentPeriod: number;
  setCurrentPeriod: (id: number) => void;
}) => {
  const dispatch = useDispatch();
  const [periods, setPeriods] = useState<DashboardPeriodTypeDto[]>([]);
  useEffect(() => {
    dispatch(
      getDashboardPeriods({
        payload: {
          onSuccess: (m, p, r) => {
            setPeriods(p);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      <div>
        {periods.map((x, i) => (
          <button
            key={`${i}-${x.Name}`}
            type="button"
            onClick={() => setCurrentPeriod(x.Id)}
            className={
              x.Id === currentPeriod
                ? "btn btn-soft-info btn-sm me-1"
                : "btn btn-soft-secondary btn-sm me-1"
            }
          >
            {x.Name}
          </button>
        ))}
      </div>
    </React.Fragment>
  );
};
export default PeriodComp;
