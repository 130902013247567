import produce from "immer";
import { createAction } from "redux-smart-actions";
import { createSelector } from "reselect";
import { KomutRedux } from "../base/types/KomutRedux";
import { RootState } from "../base/types/RootState";
import { SocketNotificationDto } from "./type";
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
const READ_NOTIFICATION = "READ_NOTIFICATION";
export interface NotificationReducer {
  notifications: SocketNotificationDto[];
}
export interface NotificationActions {
  setNotifications: any;
  getNotifications: any;
  readNotification: any;
}
const notificationRedux = new KomutRedux<
  NotificationReducer,
  NotificationActions
>();
notificationRedux.setInitialState({
  notifications: [],
});
notificationRedux.setActions({
  setNotifications: createAction(
    SET_NOTIFICATIONS,
    (payload: SocketNotificationDto[]) => ({
      payload,
    })
  ),
  getNotifications: createAction(GET_NOTIFICATIONS),
  readNotification: createAction(READ_NOTIFICATION, (payload: string) => ({
    payload,
  })),
});
notificationRedux.setRedusers(
  produce((draft: NotificationReducer, action: any) => {
    switch (action.type) {
      case SET_NOTIFICATIONS:
        draft.notifications = action.payload;
        break;
    }
  }, notificationRedux.initialState)
);
// ** SELECTORS *** \\
const notificationState = (state: RootState) =>
  state.notifications.notifications;
export const notificationSelector = createSelector(
  [notificationState],
  state => state
);
export default notificationRedux;
