import produce from "immer";
import { createAction } from "redux-smart-actions";
import { createSelector } from "reselect";
import { KomutRedux } from "../base/types/KomutRedux";
import { RootState } from "../base/types/RootState";

const SET_GRID_FILTER = "SET_GRID_FILTER";
const CLEAR_GRID_FILTER = "CLEAR_GRID_FILTER";

export interface AppSystemReducer {
  gridFilter?: string;
}

export interface AppSystemActions {
  setGridFilter: any;
  clearGridFilter: any;
}

const appSystemRedux = new KomutRedux<AppSystemReducer, AppSystemActions>();

appSystemRedux.setInitialState({ gridFilter: "" });

appSystemRedux.setActions({
  setGridFilter: createAction(SET_GRID_FILTER, (payload: string) => ({
    payload,
  })),
  clearGridFilter: createAction(CLEAR_GRID_FILTER),
});

appSystemRedux.setRedusers(
  produce((draft: AppSystemReducer, action: any) => {
    switch (action.type) {
      case SET_GRID_FILTER:
        draft.gridFilter = action.payload;
        break;
      case CLEAR_GRID_FILTER:
        draft.gridFilter = "";
        break;
    }
  }, appSystemRedux.initialState)
);

// ** SELECTORS *** \\
const gridState = (state: RootState) => state.appSystem.gridFilter;
export const gridFilterSelector = createSelector([gridState], data => data);

export default appSystemRedux;
