import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CorporateContractDetailDto } from "src/store/corporate-contracts/type";
import { Row, Col, Modal, ModalBody, Card, ModalHeader } from "reactstrap";
import { TextBox } from "devextreme-react";
import { getLocalDate } from "src/helpers/getLocalDate";
import images from "src/assets/image";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { baseImageUrl } from "src/store/base/types/url";
import DataGrid, {
  Column,
  Editing,
  Item,
  Lookup,
  RequiredRule,
  Toolbar,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import { PackageDto } from "src/store/packages/type";
import { getPackageList } from "src/store/packages/saga";
import {
  BodyType,
  createInnerRequestSaga,
} from "src/store/base/request/request";
import { verfiyResponse } from "src/store/base/types/saga";

const DetailCorporateContract = ({
  data,
  onHide,
  open,
}: {
  data: CorporateContractDetailDto;
  onHide: () => void;
  open: boolean;
}) => {
  return (
    <React.Fragment>
      <Modal centered toggle={() => onHide()} isOpen={open}>
        <ModalHeader toggle={() => onHide()}>Sözleşme </ModalHeader>
        <ModalBody>
          <CorporateContractDetailCard data={data} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default DetailCorporateContract;

export const CorporateContractDetailCard = ({
  data,
  showEdit,
  refreshData,
}: {
  showEdit?: boolean;
  data: CorporateContractDetailDto;
  refreshData?: () => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useSelector(getApiParams);

  const dataGridRef = useRef<DataGrid>();
  const [packages, setPackages] = useState<PackageDto[]>([]);
  useEffect(() => {
    showEdit &&
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (msg, payload) => setPackages(payload),
            onError: () => {},
          },
        })
      );
  }, [dispatch, showEdit]);
  const dataFields = [
    {
      label: "Toplam Hesap",
      value: data.TotalAccount,
    },
    {
      label: "Fatura Günü",
      value: data.InvoiceDay,
    },
    {
      label: "Ödeme Günü",
      value: data.PaymentDay,
    },
    {
      label: t("Start Date"),
      value: getLocalDate(data.StartDate),
    },
    {
      label: t("End Date"),
      value: getLocalDate(data.EndDate),
    },
  ];
  const store = new CustomStore({
    key: "Id",
    load: loadOptions => {
      return createInnerRequestSaga({
        url: "/api/corporatecontractpackagedeals/byContractId/" + data.Id,
        method: "GET",
        lang: "en-US",
        token: token,
      });
    },
    insert: async values => {
      const resp = await createInnerRequestSaga({
        url: "/api/corporatecontractpackagedeals",
        body: { ...values, CorporateContractId: data.Id },
        method: "POST",
        bodyType: BodyType.formdata,
        lang: "en-US",
        token: token,
      });
      refreshData && refreshData();
      return resp;
    },
    remove: async key => {
      const response = await createInnerRequestSaga({
        url: "/api/corporatecontractpackagedeals/" + key,
        method: "DELETE",
        lang: "en-US",
        token: token,
      });
      if (!verfiyResponse(response)) {
        toast.error(response.Message);
      }
      refreshData && refreshData();
      return response;
    },
    update: async (key, values) => {
      const items = dataGridRef.current?.instance.getDataSource().items();
      const body = items?.find(x => x.Id === key);
      const response = await createInnerRequestSaga({
        url: "/api/corporatecontractpackagedeals/" + key,
        body: { ...body, ...values, Id: key },
        method: "PUT",
        bodyType: BodyType.formdata,
        lang: "en-US",
        token: token,
      });
      if (!verfiyResponse(response)) {
        toast.error(response.Message);
      }
      refreshData && refreshData();
      return response;
    },
  });
  return (
    <Row className="mb-3">
      {dataFields.map(x => (
        <Col key={x.label} md={6} className="mb-3">
          <TextBox
            style={{ borderWidth: 0 }}
            label={x.label}
            value={x.value ? x.value.toString() : ""}
            readOnly
            stylingMode="filled"
          />
        </Col>
      ))}
      {data.SignedPdf && (
        <Col md={6} className="">
          <DocumentCard
            src={data.SignedPdf}
            name={getLocalDate(data.StartDate) + " Sözleşme"}
          />
        </Col>
      )}
      <Col md={12} className="px-3">
        <DataGrid
          //@ts-ignore
          ref={dataGridRef}
          key="Id"
          dataSource={store}
          showBorders={true}
          allowColumnResizing
        >
          <Column dataField="PackageId" caption="Paket">
            <Lookup
              dataSource={packages}
              valueExpr="Id"
              displayExpr="PackageName"
            />
            <RequiredRule />
          </Column>
          <Column
            dataField="Yearly"
            caption="Yıllık"
            dataType="boolean"
          ></Column>
          <Column dataField="AccountCount" caption="Adet">
            <RequiredRule />
          </Column>
          <Column
            dataField="IsPerAccountBill"
            caption="H.B. Faturalama"
            dataType="boolean"
          ></Column>
          <Column dataField="BillAmount" caption="Tutar" dataType="number">
            <RequiredRule />
          </Column>
          {showEdit && (
            <Editing
              mode="form"
              allowDeleting
              allowAdding
              allowUpdating
              useIcons
            />
          )}
          <Toolbar>
            <Item location="before">
              <div className="informer">
                <span className="">Sözleşme Detay Satırları</span>
              </div>
            </Item>
            <Item name="addRowButton" location="after" />
          </Toolbar>
        </DataGrid>
      </Col>
    </Row>
  );
};
const DocumentCard = ({ src, name }: { src: string; name: string }) => {
  return (
    <Card className="p-1">
      <Row className="align-items-center">
        <Col className="col-auto">
          <img
            data-dz-thumbnail=""
            width={16}
            height={"auto"}
            className=" rounded bg-light"
            alt={"Sözleşme Dosyası"}
            src={src}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = images.pdf;
            }}
          />
        </Col>
        <Col>
          <Link to="#" className="text-muted font-weight-bold">
            {"Sözleşme Dosyası"}
          </Link>
        </Col>
        <Col md={1}>
          <p
            className="mb-0 text-pr deleteHover cursor-pointer font-size-14"
            onClick={() => {
              FileSaver.saveAs(baseImageUrl + src, name);
            }}
          >
            <strong>
              <i className="bx bxs-download font-size-20" />
            </strong>
          </p>
        </Col>
      </Row>
    </Card>
  );
};
