import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Input, Label, Card } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmailSideBar from "./email-sidebar";
import EmailToolbar from "./email-toolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredTicketList,
  getTicketDetails,
  getTicketStatusTypeWithCount,
} from "src/store/ticket/saga";
import {
  TicketDetailDto,
  TicketDto,
  TicketStatusDto,
  TicketSubjectTypeEnum,
} from "src/store/ticket/type";
import moment from "moment";
import { FilterResultDetailDto } from "src/store/base/types/saga";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import EmailRead from "./email-read";
import "./style.scss";
import TicketFilesModal from "./comp/ticket-files";
import TicketLevelComp from "./comp/ticketLevel";
const TicketsPage = () => {
  const [sourceType, setSourceType] = useState(1);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [tickets, setTickets] = useState<TicketDto[]>([]);
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const statusId = parseInt(url.get("statusId") ?? "1");
  const ticketId = url.get("ticketId");
  const subjectId = url.get("subjectId");
  const [page, setPage] = useState(1);
  const [detail, setDetail] = useState<FilterResultDetailDto>();
  const [status, setStatus] = useState<TicketStatusDto[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<TicketDetailDto>();
  const dispatch = useDispatch();
  const take = 10;
  const getTicketDetail = () => {
    ticketId &&
      dispatch(
        getTicketDetails({
          payload: {
            onSuccess: (m, p, d: FilterResultDetailDto) => {
              setSelectedTicket(p);

              setDetail(d);
            },
            onError: () => {},
          },
          id: ticketId,
        })
      );
  };
  const getData = () => {
    setSelectedList([]);
    let params = `?StatusId=${statusId}&Skip=${(page - 1) * take}&Take=${take}`;
    if (subjectId) {
      params = params + `&SubjectId=${subjectId}`;
    }
    if (!ticketId) {
      setSelectedTicket(undefined);
      dispatch(
        getFilteredTicketList({
          payload: {
            onSuccess: (m, p, d: FilterResultDetailDto) => {
              setTickets(p);
              setDetail(d);
            },
            onError: () => {},
          },
          params: params,
          id: sourceType,
        })
      );
    }

    getTicketDetail();
    dispatch(
      getTicketStatusTypeWithCount({
        payload: {
          onSuccess: (m, p) => setStatus(p),
          onError: () => {},
        },
        id: sourceType,
      })
    );
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [sourceType, dispatch, statusId, page, subjectId, ticketId]);
  const loading = useSelector(isLoading);
  useEffect(() => {
    setSelectedList([]);
  }, [statusId, subjectId]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Destek Talepleri</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Destek Talepleri"}
          />
          <Row className="ticketContainer">
            {loading && <Loader />}
            <Col xs="12">
              <EmailSideBar
                status={status}
                refreshList={() => getData()}
                setSourceType={setSourceType}
                sourceType={sourceType}
              />
              <div className="email-rightbar mb-3">
                {ticketId ? (
                  <>
                    {selectedTicket && (
                      <EmailRead
                        status={status}
                        ticket={selectedTicket}
                        refreshData={() => getTicketDetail()}
                      ></EmailRead>
                    )}
                  </>
                ) : (
                  <>
                    <Card>
                      <EmailToolbar
                        sourceType={sourceType}
                        selectedList={selectedList}
                        refreshList={() => getData()}
                      />
                      <ul className="message-list">
                        {tickets.map(x => (
                          <li key={x.Id}>
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={x.Id}
                                  onChange={() => {}}
                                  checked={selectedList.some(y => y === x.Id)}
                                  onClick={(e: any) => {
                                    if (e.target.checked) {
                                      setSelectedList([...selectedList, x.Id]);
                                    } else {
                                      setSelectedList(
                                        selectedList.filter(y => y !== x.Id)
                                      );
                                    }
                                  }}
                                />
                                <Label htmlFor={x.Id} className="toggle" />
                              </div>
                              <Link
                                to={"/ticket/all?ticketId=" + x.Id}
                                className="title"
                              >
                                {x.CustomerId ? (
                                  <>
                                    {x.Name} {x.Surname}
                                  </>
                                ) : (
                                  <>
                                    {x.TicketCustomer?.Name ?? ""}{" "}
                                    {x.TicketCustomer?.Surname ?? ""}
                                  </>
                                )}
                                <br />
                              </Link>
                              <span>
                                {x.HasFile ? (
                                  <span>
                                    <TicketFilesModal
                                      ticketId={x.Id}
                                      type="list"
                                    />
                                  </span>
                                ) : (
                                  <span>
                                    <i
                                      className="mdi mdi-file-multiple"
                                      style={{ visibility: "hidden" }}
                                    ></i>
                                  </span>
                                )}
                                <span
                                  className={
                                    "badge bg-ligth text-dark font-size-12"
                                  }
                                >
                                  {x.TicketLevelId && (
                                    <TicketLevelComp
                                      levelId={x.TicketLevelId}
                                    />
                                  )}{" "}
                                  {x.TicketStatusType}{" "}
                                </span>{" "}
                              </span>
                            </div>
                            <div className="col-mail col-mail-2">
                              <Link
                                to={"/ticket/all?ticketId=" + x.Id}
                                className="subject"
                              >
                                {x.ResponsibleUserFullName && (
                                  <span
                                    className="bg-soft-dark text-dark me-3  p-1 badge"
                                    style={{ fontSize: 10 }}
                                  >
                                    <i className="bx bx-user-circle me-1" />
                                    {x.ResponsibleUserFullName}
                                  </span>
                                )}
                                <span
                                  className={
                                    getSubjectTypeColor(x.SubjectTypeId) +
                                    " badge text-dark me-2 "
                                  }
                                >
                                  {x.Subject}
                                </span>{" "}
                                <span
                                  className={
                                    "bg-soft-light badge text-muted me-1 "
                                  }
                                >
                                  {x.SupplierName ?? "-"}
                                </span>{" "}
                                {x.LastTicketAnswer ?? "-"}
                              </Link>
                              <div className="date font-size-12">
                                {moment(x.CreateTime).format(
                                  "DD/MM/yyyy HH:mm:ss"
                                )}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Card>
                    <Row>
                      {detail && (
                        <>
                          <Col xs="7">
                            Showing {(page - 1) * take}-{page * take} of{" "}
                            {detail.TotalCount}
                          </Col>
                          <Col xs="5">
                            <div className="btn-group float-end">
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="waves-effect"
                                disabled={page === 1}
                                onClick={() => setPage(page - 1)}
                              >
                                <i className="fa fa-chevron-left" />
                              </Button>
                              <Button
                                disabled
                                type="button"
                                color="light"
                                size="sm"
                                className="waves-effect"
                              >
                                {page}
                              </Button>
                              <Button
                                type="button"
                                color="success"
                                size="sm"
                                className="waves-effect"
                                disabled={!detail.NextPage}
                                onClick={() => setPage(page + 1)}
                              >
                                <i className="fa fa-chevron-right" />
                              </Button>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TicketsPage;

const getSubjectTypeColor = (subjectTypeId: number) => {
  switch (subjectTypeId) {
    case TicketSubjectTypeEnum.TeknikDestek:
      return "bg-soft-info";
    case TicketSubjectTypeEnum.Kaza:
      return "bg-soft-danger";
    case TicketSubjectTypeEnum.HukukiDestek:
      return "bg-soft-primary";
    case TicketSubjectTypeEnum.GeriBildirim:
      return "bg-soft-success";
    default:
      return undefined;
  }
};
