import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Col, Row } from "reactstrap";
import TicketCreateInputs from "./createTicket";
import { CustomerDetailDto } from "src/store/customers/type";
import { userSelector } from "src/store/auth";
import { createCustomerCallLogs } from "src/store/customer-calllog/saga";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import GetInput from "src/components/Form/GetInput";
export enum Calllog {
  Channel = "03abbe33-708f-448c-8582-fd1a174a792f",
  ParentTopic = "dc3f39e6-f538-44c9-ac16-6999837ec3eb",
  Topic = "9da5f68e-0bd8-4afe-869c-db9f2d1f4cd0",
}
const CreateInvalidCustomerCalllogModal = ({
  onHide,
  open,
  customerData,
}: {
  customerData?: CustomerDetailDto;
  onHide: () => void;
  open: boolean;
}) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CustomModal
        size="sm"
        open={open}
        onHide={onHide}
        title={"Yeni Çağrı Kaydı Ekle"}
      >
        <Formik
          initialValues={{
            CustomerCallLogLevelEnumId: 1,
            CustomerCallLogTypeId: 4,
            CustomerCallLogTopicId: Calllog.ParentTopic,
            CustomerCallLogSubTopicId: Calllog.Topic,
            CallLogChannelId: Calllog.Channel,
            CustomerId: customerData ? customerData.Id : "",
            Name: customerData ? customerData.Name : "",
            Surname: customerData ? customerData.Surname : "",
            PhoneNumber: customerData ? customerData.PhoneNumber : "",
            PhoneNumber2: "",
            VehiclePlate: "",
            KmLimit: "",
            Address: "",
            AddressId: "",
            Not: "",
            TownId: "",
            CityId: "",
            VehicleId: "",
            make: "",
            model: "",
            regdate: "",
            category: "",
            DataArray: [],
            OpenTicket: false,
            TicketTypeId: 1,
            SubjectId: "",
            SupplierId: "",
            ResponsibleUserId: user ? user.Id : "",
            Message: "",
            Documents: [],
            Images: [],
          }}
          onSubmit={values => {
            dispatch(
              createCustomerCallLogs({
                payload: {
                  onSuccess: (message, payload) => {
                    toast.success(message, { theme: "colored" });
                    return onHide();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: values,
              })
            );
          }}
          validationSchema={Yup.object().shape({
            CustomerCallLogLevelEnumId: Yup.number()
              .typeError(t("Required"))
              .required(t("Required")),

            CallLogChannelId: Yup.string().required(t("Required")),
            CustomerCallLogSubTopicId: Yup.string().required(t("Required")),
            CustomerCallLogTypeId: Yup.number()
              .typeError(t("Required"))
              .required(t("Required")),
          })}
        >
          {({ touched, handleSubmit, setFieldValue, errors, values }) => (
            <>
              <Row style={{ width: "100%", paddingInline: "10px" }}>
                <GetInput
                  inputprop={{
                    col: 12,
                    field: "Not",
                    label: t("Not"),
                    inputType: InputType.multilinetext,
                  }}
                />
                <TicketCreateInputs open={open} />{" "}
                <Col md={12}>
                  <div className="w-100 d-flex justify-content-between ">
                    <Button
                      color="danger"
                      style={{ width: 220 }}
                      type="submit"
                      onClick={() => onHide()}
                    >
                      {t("Geri")}
                    </Button>{" "}
                    <Button
                      color="success"
                      type="submit"
                      style={{ width: 220 }}
                      onClick={() => handleSubmit()}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};
export default CreateInvalidCustomerCalllogModal;
