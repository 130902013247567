import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import classnames from "classnames";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { getTicketFiles, getTicketHistories } from "src/store/ticket/saga";
import {
  TicketAnswerDocumentDto,
  TicketAnswerDocumentTypeEnum,
  TicketHistoryDto,
} from "src/store/ticket/type";
import { Link } from "react-router-dom";
import { baseImageUrl } from "src/store/base/types/url";
import images from "src/assets/image";
import moment from "moment";
import FileSaver from "file-saver";
import Lightbox from "react-image-lightbox";
import CustomModal from "src/components/Modal/modal";
import TicketHistories from "./histories";

const TicketFilesModal = ({
  ticketId,
  type = "detail",
}: {
  ticketId: string;
  type?: "detail" | "list";
}) => {
  const [show, setShow] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [active, setActive] = useState(1);
  const [files, setFiles] = useState<TicketAnswerDocumentDto[]>([]);
  const loading = useSelector(isLoading);
  const dispatch = useDispatch();
  const [histories, setHistories] = useState<TicketHistoryDto[]>([]);
  useEffect(() => {
    showHistory &&
      dispatch(
        getTicketHistories({
          payload: {
            onSuccess: (msg, py) => setHistories(py),
            onError: () => {},
          },
          id: ticketId,
        })
      );
  }, [dispatch, ticketId, showHistory]);
  useEffect(() => {
    show &&
      dispatch(
        getTicketFiles({
          payload: {
            onSuccess: (m, p) => setFiles(p),
            onError: () => {},
          },

          id: ticketId,
        })
      );
    return setFiles([]);
  }, [dispatch, show, ticketId]);
  return (
    <React.Fragment>
      {type === "detail" && (
        <>
          <Button
            type="button"
            color="soft-success"
            className="btn-sm waves-light waves-effect"
            onClick={() => setShow(true)}
          >
            <i className="mdi mdi-file-multiple"></i> Dosyalar
          </Button>
          <Button
            type="button"
            color="soft-info"
            className="ms-2 btn-sm waves-light waves-effect"
            onClick={() => setShowHistory(true)}
          >
            <i className="mdi mdi-file-multiple"></i>
            Geçmiş
          </Button>
          <CustomModal
            onHide={() => setShowHistory(false)}
            open={showHistory}
            title={"Geçmiş Hareketler"}
          >
            <TicketHistories histories={histories} />
          </CustomModal>
        </>
      )}
      {type === "list" && (
        <span
          onClick={() => setShow(true)}
          className="cursor-pointer text-success"
        >
          <i className="mdi mdi-file-multiple"></i>
        </span>
      )}
      <Modal isOpen={show} centered>
        <ModalHeader toggle={() => setShow(false)}>
          TALEP DOSYA LİSTESİ
        </ModalHeader>
        <ModalBody style={{ minHeight: 500 }}>
          {loading && <Loader />}
          <Nav tabs className="" role="tablist">
            <NavItem key={TicketAnswerDocumentTypeEnum.Document}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: active === TicketAnswerDocumentTypeEnum.Document,
                })}
                onClick={() => {
                  setActive(TicketAnswerDocumentTypeEnum.Document);
                }}
              >
                Dosyalar
              </NavLink>
            </NavItem>
            <NavItem key={TicketAnswerDocumentTypeEnum.Resim}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: active === TicketAnswerDocumentTypeEnum.Resim,
                })}
                onClick={() => {
                  setActive(TicketAnswerDocumentTypeEnum.Resim);
                }}
              >
                Fotoğraflar
              </NavLink>
            </NavItem>
          </Nav>
          <div className="mt-3">
            {active === TicketAnswerDocumentTypeEnum.Document && (
              <>
                {files
                  .filter(
                    x =>
                      x.TicketAnswerDocumentTypeId ===
                      TicketAnswerDocumentTypeEnum.Document
                  )
                  .map(x => (
                    <TicketAnswerFileCard document={x} key={x.Id + "-file"} />
                  ))}
              </>
            )}
            {active === TicketAnswerDocumentTypeEnum.Resim && (
              <>
                {files
                  .filter(
                    x =>
                      x.TicketAnswerDocumentTypeId ===
                      TicketAnswerDocumentTypeEnum.Resim
                  )
                  .map(x => (
                    <TicketAnswerFileCard document={x} key={x.Id + "-file"} />
                  ))}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={"d-flex mt-2  justify-content-end  w-100"}>
            <Button
              color="danger"
              type="submit"
              style={{
                width: 150,
              }}
              onClick={() => {
                return setShow(false);
              }}
            >
              {"Kapat"}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default TicketFilesModal;
const TicketAnswerFileCard = ({
  document,
}: {
  document: TicketAnswerDocumentDto;
}) => {
  const [isFits, setisFits] = useState<string>();
  return (
    <>
      {isFits ? (
        <Lightbox
          mainSrc={baseImageUrl + isFits}
          enableZoom={false}
          imageCaption={""}
          onCloseRequest={() => {
            setisFits("");
          }}
        />
      ) : null}
      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
        <div className="p-2">
          <Row className="align-items-center">
            <Col className="col-auto">
              <img
                style={{ cursor: "pointer" }}
                data-dz-thumbnail=""
                onClick={() => {
                  if (
                    document.TicketAnswerDocumentTypeId ===
                    TicketAnswerDocumentTypeEnum.Document
                  ) {
                    return window.open(
                      baseImageUrl + document.DocumentLocation,
                      "_blank"
                    );
                  } else {
                    return setisFits(document.DocumentLocation);
                  }
                }}
                height="80"
                className="avatar-sm rounded bg-light"
                src={
                  document.TicketAnswerDocumentTypeId ===
                  TicketAnswerDocumentTypeEnum.Resim
                    ? baseImageUrl + document.DocumentLocation
                    : images.doc
                }
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = images.noimage;
                }}
              />
            </Col>
            <Col>
              <Link to="#" className="text-muted font-weight-bold">
                {/* {f.name} */}
              </Link>
              <p className="mb-0">
                <strong>
                  {moment(document.CreateTime).format("DD/MM/yyyy HH:mm")}
                </strong>
              </p>
            </Col>
            <Col md={1}>
              <p
                className="mb-0 text-pr deleteHover cursor-pointer font-size-14"
                onClick={() => {
                  FileSaver.saveAs(
                    baseImageUrl + document.DocumentLocation,
                    document.Id
                  );
                }}
              >
                <strong>
                  <i className="bx bxs-download font-size-20" />
                </strong>
              </p>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};
