import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getApiParams } from "src/store/auth";
import { baseUrl } from "src/store/base/types/url";
import { getProductCategoryList } from "src/store/orders/product-categories/saga";
import { ProductCategoryDto } from "src/store/orders/product-categories/type";
import { updateProduct } from "src/store/orders/products/saga";
import { ProductDetailDto } from "src/store/orders/products/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import * as Yup from "yup";

const EditProduct = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: ProductDetailDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const params = useSelector(getApiParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [categories, setCategories] = useState<ProductCategoryDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (m, p) => setSuppliers(p),
            onError: () => {},
          },
        })
      );
      dispatch(
        getProductCategoryList({
          payload: {
            onSuccess: (m, p) => setCategories(p),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ürün Ekle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateProduct({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          Name: data.Name,
          SupplierId: data.SupplierId,
          ShortDescription: data.ShortDescription,
          ProductCategoryId: data.ProductCategoryId,
          ProductCategoryName: data.ProductCategoryFullName,
          Stock: data.Stock,
          CostPrice: data.CostPrice,
          ListPrice: data.ListPrice,
          SalesPrice: data.SalesPrice,
          DiscountSalesPrice: data.DiscountSalesPrice,
          TaxRate: data.TaxRate,
          ShippingPrice: data.ShippingPrice,
          ProfitPercentage: data.ProfitPercentage,
          FreeShipping: data.FreeShipping,
          Active: data.Active,
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "ProductCategoryId",
            inputType: InputType.cascader,
            label: t("Kategori"),
            onplaceholder: values =>
              values.ProductCategoryId ? data.ProductCategoryFullName : "",
            cascader: {
              data: categories.map(x => {
                let children: any = x.HasSubCategory ? [] : undefined;
                return {
                  label: x.Name,
                  value: x.Id,
                  children: children,
                };
              }),

              //@ts-ignore
              getChildren: async node => {
                var res = await axios
                  .get(
                    baseUrl +
                      "/api/productcategories/list?parentId=" +
                      node.value,
                    {
                      headers: {
                        Authorization: `Bearer ${params.token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  )
                  .then(response => {
                    var data = response.data.Result.map(
                      (x: ProductCategoryDto) => {
                        return {
                          label: x.Name,
                          value: x.Id,
                          children: x.HasSubCategory ? [] : undefined,
                        };
                      }
                    );
                    console.log(data, "resss");
                    return data;
                  });

                return res;
              },
            },
          },
          {
            field: "SupplierId",
            inputType: InputType.multiselect,
            label: t("Supplier"),
            lookup: {
              data: suppliers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Supplier"),
            },
          },
          {
            field: "Name",
            label: t("Ürün Adı"),
          },
          {
            field: "ShortDescription",
            label: t("Açıklama"),
            inputType: InputType.multilinetext,
          },

          {
            field: "CostPrice",
            label: t("Maliyet Fiyatı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "ListPrice",
            label: t("Liste Fiyatı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "SalesPrice",
            label: t("Üye Olmayan Fiyatı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "DiscountSalesPrice",
            label: t("Üye Fiyatı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "ShippingPrice",
            label: t("Birim Kargo Fiyatı"),
            inputType: InputType.number,
            col: 6,
          },

          {
            field: "TaxRate",
            label: t("Vergi Oranı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "Stock",
            label: t("Stok"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "ProfitPercentage",
            label: t("Kar Marjı"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "FreeShipping",
            label: t("Ücretsiz Kargo"),
            col: 6,
            checkedLabel: t("Ücretsiz Kargo"),
            uncheckedLabel: t("Ücretsiz Kargo"),
            inputType: InputType.toogle,
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditProduct;
