import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { CustomerPrivilegeDto } from "src/store/customerprivilege/type";
import {
  assignPrivilegeCardtoCustomer,
  getPrivilegeCardListByPrivilegeId,
} from "src/store/privilegecard/saga";
import { PrivilegeCardDto } from "src/store/privilegecard/type";
import * as Yup from "yup";

const CreatePrivilegeCardForm = ({
  customerPrivilege,
  onHide,
  open,
  onSuccessClick,
}: {
  customerPrivilege: CustomerPrivilegeDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cards, setCards] = useState<PrivilegeCardDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeCardListByPrivilegeId({
          payload: {
            onSuccess: (msg, py) =>
              setCards(py.filter(x => x.CustomerId === null)),
            onError: () => {},
          },
          id: customerPrivilege.PrivilegeId,
          params: "?Skip=0&&Take=10000",
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Müşteriye Kart Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CustomerPrivilegeId: customerPrivilege.Id,
          CardId: "",
        }}
        onSubmit={values => {
          dispatch(
            assignPrivilegeCardtoCustomer({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          CardId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "CardId",
            inputType: InputType.multiselect,
            label: t("Kart Seçimi"),
            lookup: {
              data: cards,
              labelKey: "CardNumber",
              valueKey: "Id",
              placeholder: t("Kart Seçiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeCardForm;
