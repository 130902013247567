export interface PrivilegeDto {
  Id: string;
  PrivilegeCategoryId: string;
  PrivilegeCategoryName: string;
  PrivilegeTypeId: number;
  TermsOfService: string;
  SupplierId: string;
  SupplierName: string;
  Title: string;
  ShortDescription: string;
  Description: string;
  StartDate: string;
  Priority: number;
  EndDate: string;
  Limited: boolean;
  CouponCount: number;
  Active: boolean;
  HasContainsPrivilege: boolean;
  Image: string;
  ShowWebsite: boolean;
  CanBeUsedMoreThanOnce: boolean;
  DiscountTypeId: number;
  Icon: any;
  DiscountType: string;
  DiscountValue: number;
  IncludablePackage: boolean;
  Sellable: boolean;
  ShowStore: boolean;
  ShowOnMobile: boolean;
  PrivilegeUsageTypeId: number;
  UsageValue: string;
  IconName?: string;
  IconLibrary?: string;
  WebsiteIconFile?: string;
  ShowPhoneNumber: boolean;
  PhoneNumber: string;
  TaxRate: number;

  PrivilegeCouponTypeId?: number;
  CouponLenght?: number;
  UsageInformationText: string;
  ShowUsageInformationText: boolean;
  ShowTerms: boolean;
  PrivilegeAssignedTypes: PrivilegeAssignedTypeDto[];
}

export enum PrivilegeTypeConsts {
  Campaign = 1,
  Privilege = 2,
}
export interface PrivilegeTypeDto {
  EnumId: number;
  Name: string;
  Code: string;
}
export interface PrivilegeAssignedTypeDto {
  PrivilegeId: number;
  PrivilegeTypeId: string;
}
