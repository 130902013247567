import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createTicketSubject } from "src/store/ticket-subject/saga";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import { getTicketTypes } from "src/store/ticket/saga";
import { TicketType } from "src/store/ticket/type";
import * as Yup from "yup";

const CreateTicketSubject = ({
  onHide,
  open,
  onSuccessClick,
  parent,
}: {
  parent?: TicketSubjectDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  console.log(parent);
  const { t } = useTranslation();
  const [types, setTypes] = useState<TicketType[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getTicketTypes({
          payload: {
            onSuccess: (msg, py) => setTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Ticket Subject"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          TicketTypeId: parent?.TicketTypeId,
          Subject: "",
          AutoMessage: "",
          SendAutoMessage: false,
          AutoNotificationTitle: "",
          AutoNotificationText: "",
          SendAutoNotification: false,
          Active: true,
          ParentTicketSubjectId: parent ? parent.Id : "",
          ParentTicketSubjectName: parent ? parent.Subject : "",
        }}
        onSubmit={values => {
          dispatch(
            createTicketSubject({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          TicketTypeId: Yup.number().required(t("Required")),
          Subject: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            disabled: values => (parent ? true : false),
            field: "TicketTypeId",
            inputType: InputType.multiselect,
            label: t("Talep Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Talep Tipi Seçiniz"),
            },
          },
          {
            hide: values => (parent ? false : true),
            disabled: values => (parent ? true : false),
            label: t("Ana Talep Konusu"),
            field: "ParentTicketSubjectName",
          },
          {
            field: "Subject",
            label: t("Subject"),
          },
          {
            field: "SendAutoMessage",
            label: t("Talep oluşturulurken otomatik Mesaj Gönder"),
            inputType: InputType.checkbox,
          },
          {
            field: t("AutoMessage"),
            label: "Otomatik Mesaj",
          },

          {
            field: "SendAutoNotification",
            label: t("Talep cevap verildiğinde bildirim gönder"),
            inputType: InputType.checkbox,
          },
          {
            field: t("AutoNotificationTitle"),
            label: "Bildirim Başlığı",
          },
          {
            field: t("AutoNotificationText"),
            label: "Bildirim Metni",
          },
          {
            field: "Active",
            label: t("Active") + " (Mobil App Görünürlük)",
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateTicketSubject;
