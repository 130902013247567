import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { createPrivilegeCard } from "src/store/privilegecard/saga";
import * as Yup from "yup";

const CreatePrivilegeCardForm = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          CardNumber: "",
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeCard({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          CardNumber: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "CardNumber",
            label: t("Kart Numarası"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeCardForm;
