import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getAllPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { getSliderTypeList, updateSlider } from "src/store/slider/saga";
import {
  SliderDto,
  SliderTypeConsts,
  SliderTypeDto,
} from "src/store/slider/type";
import * as Yup from "yup";

const EditSlider = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: SliderDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [types, setTypes] = useState<SliderTypeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getAllPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getSliderTypeList({
          payload: {
            onSuccess: (msg, py) => setTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit Slider"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PrivilegeId: data.PrivilegeId ?? "",
          Title: data.Title,
          Description: data.Description,
          ImageFile: "",
          CurrentFile: data.Image && data.Image != null ? data.Image : "",
          Active: data.Active,
          ForCustomer: data.ForCustomer,
          SliderTypeId: data.SliderTypeId ?? "",
          Value: data.Value ?? "",
        }}
        onSubmit={values => {
          dispatch(
            updateSlider({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Title: Yup.string().required(t("Required")),
          Description: Yup.string().required(t("Required")),
          PrivilegeId: Yup.string().when("SliderTypeId", {
            is: (id: number) => id === SliderTypeConsts.Privilege,
            then: Yup.string().required(t("Required")),
          }),
          Value: Yup.string().when("SliderTypeId", {
            is: (id: number) => id === SliderTypeConsts.Link,
            then: Yup.string().required(t("Required")),
          }),
        })}
        inputs={[
          {
            field: "SliderTypeId",
            inputType: InputType.multiselect,
            label: t("Slider Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Slider Tipi Seçiniz"),
            },
          },

          {
            hide(values) {
              return values.SliderTypeId !== SliderTypeConsts.Link;
            },
            field: "Value",
            label: t("Link"),
          },
          {
            hide(values) {
              return values.SliderTypeId !== SliderTypeConsts.Privilege;
            },
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "Title",
            label: t("Title"),
          },
          {
            field: "Description",
            label: t("Description"),
          },
          {
            field: "ForCustomer",
            label: t(""),
            col: 12,
            inputType: InputType.tabGroup,
            radiogrups: {
              data: [
                {
                  Id: true,
                  Label: "Üyeye Özel",
                },
                {
                  Id: false,
                  Label: "Üye Olmayana Özel",
                },
              ],
              labelKey: "Label",
              valueKey: "Id",
            },
          },
          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditSlider;
