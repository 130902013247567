import { ApiResponse, verfiyResponse } from "../base/types/saga";
import { LanguageModel } from "./types";
import { call, put, takeLatest } from "@redux-saga/core/effects";
import languge from ".";
import { createRequestSaga } from "../base/request/request";

function* getLanguagesSaga() {
  try {
    const response: ApiResponse<LanguageModel[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: "/api/System/Languages",
      }
    );
    if (verfiyResponse(response)) {
      yield put(languge.actions.setLanguages(response.Result));
    } else {
    }
  } catch (e) {
    console.log("ERROR languages Saga", e);
  }
}

const languageSaga = [
  takeLatest(languge.actions.getLanguages.toString(), getLanguagesSaga),
];
export default languageSaga;
