import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import {
  updateAllTicketStatusToClosed,
  updateAllTicketStatusToProgress,
  updateStatusToClosedMultiple,
  updateStatusToProgressMultiple,
} from "src/store/ticket/saga";
import { TicketSourceTypeEnum, TicketStatusEnum } from "src/store/ticket/type";
import AssignUserToTicket from "./comp/assign-user";
import CustomTextarea from "src/components/Form/Input/Textarea";
interface StatusDto {
  key: "select" | "all";
  message: string;
  action: any;
}
const EmailToolbar = ({
  selectedList,
  refreshList,
  sourceType,
}: {
  sourceType: TicketSourceTypeEnum;
  refreshList: () => void;
  selectedList: string[];
}) => {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const statusId = parseInt(url.get("statusId") ?? "1");
  const [showStatus, setShowStatus] = useState<StatusDto>();
  const dispatch = useDispatch();
  const setModalStatus = (s: StatusDto) => {
    if (s.key === "select" && selectedList.length <= 0) {
      return toast.error("Talep Seçiniz");
    } else {
      return setShowStatus(s);
    }
  };
  const [description, setDescription] = useState("");
  return (
    <React.Fragment>
      <div className=" btn-toolbar p-3 border-bottom" role="toolbar">
        {(statusId === TicketStatusEnum.Yeni ||
          statusId === TicketStatusEnum.Atandi) && (
          <DropdownComponent
            iconName=" bx bx-loader-circle"
            onClick={key =>
              setModalStatus({
                key: key,
                action:
                  key === "select"
                    ? updateStatusToProgressMultiple
                    : updateAllTicketStatusToProgress,
                message:
                  key === "select"
                    ? "Seçili talepleri işleme almak istediğinize emin misiniz?"
                    : "Tüm talepleri işleme almak istediğinize emin misiniz?",
              })
            }
            title="İşleme Al"
          />
        )}
        {statusId === TicketStatusEnum.Islemde && (
          <DropdownComponent
            onClick={key =>
              setModalStatus({
                key: key,
                action:
                  key === "select"
                    ? updateStatusToClosedMultiple
                    : updateAllTicketStatusToClosed,
                message:
                  key === "select"
                    ? "Seçili talepleri kapatmak istediğinize emin misiniz?"
                    : "Tüm talepleri kapatmak istediğinize emin misiniz?",
              })
            }
            iconName="bx bx-window-close"
            title="Kapatıldı İşaretle"
          />
        )}
        <AssignUserToTicket
          selectedList={selectedList}
          refreshList={refreshList}
        />
      </div>
      {showStatus && (
        <CancelAlert
          children={
            <div>
              <CustomTextarea
                field={"Açıklama"}
                label={"Açıklama"}
                value={description}
                onChange={e => {
                  return setDescription(e);
                }}
              />
            </div>
          }
          message={showStatus.message}
          onConfirm={() => {
            dispatch(
              showStatus.action({
                payload: {
                  onSuccess: (m: string) => {
                    setShowStatus(undefined);
                    toast.success(m);
                    refreshList();
                    setDescription("");
                  },
                  onError: (m: string) => {
                    setShowStatus(undefined);
                    toast.error(m, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  TicketIds: selectedList,
                  Description: description,
                },
              })
            );
          }}
          onCancel={() => {
            setShowStatus(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default EmailToolbar;

const DropdownComponent = ({
  title,
  iconName,
  onClick,
}: {
  title: string;
  iconName: string;
  onClick: (key: "select" | "all") => void;
}) => {
  const [folder_Menu, setfolder_Menu] = useState(false);
  const list = [
    {
      key: "select",
      label: "Seçili Olanlar",
    },
    {
      key: "all",
      label: "Tümünü",
    },
  ];

  return (
    <Dropdown
      isOpen={folder_Menu}
      toggle={() => {
        setfolder_Menu(!folder_Menu);
      }}
      className="btn-group me-2 mb-2 mb-sm-0"
    >
      <DropdownToggle
        className="btn btn-sm btn-secondary waves-light waves-effect dropdown-toggle"
        tag="i"
      >
        <i className={iconName} />
        {title}
        <i className="mdi mdi-chevron-down ms-1" />
      </DropdownToggle>
      <DropdownMenu>
        {list.map(x => (
          //@ts-ignore
          <DropdownItem key={x.key} onClick={() => onClick(x.key)}>
            {x.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
