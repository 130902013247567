import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getNotificationAudiences } from "src/store/notification-actions/saga";
import {
  NotificationActionDto,
  NotificationAudienceDto,
} from "src/store/notification-actions/type";
import * as Yup from "yup";

const CreateNotificationActions = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: NotificationActionDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<NotificationAudienceDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getNotificationAudiences({
          payload: {
            onSuccess: (msg, py) => setCategories(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Düzenle"),
        }}
        onHide={onHide}
        loader
        hideButtons
        initialValues={{
          Id: data.Id,
          Action: data.Action,
          NotificationAudienceId: data.NotificationAudienceId,
        }}
        onSubmit={values => {
          // dispatch(
          //   updateNotificationAction({
          //     payload: {
          //       onSuccess: (message, payload) => {
          //         onHide();
          //         onSuccessClick(message);
          //       },
          //       onError: message => {
          //         toast.error(message, {
          //           theme: "colored",
          //         });
          //       },
          //     },
          //     id: data.Id,
          //     body: values,
          //   })
          // );
        }}
        validationSchema={Yup.object().shape({
          NotificationAudienceId: Yup.number().required(t("Required")),
          Action: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            disabled: () => true,
            field: "NotificationAudienceId",
            inputType: InputType.multiselect,
            label: t("Hedef Kitle"),
            lookup: {
              data: categories,
              labelKey: "Name",
              valueKey: "EnumId",
            },
          },
          {
            disabled: () => true,
            field: "Action",
            label: t("Action"),
            inputType: InputType.multilinetext,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateNotificationActions;
