import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import {
  getSupplierServiceStatusTypeList,
  updateSupplier,
} from "src/store/supplier/saga";
import * as Yup from "yup";
import { CityDto, TownDto } from "src/store/lookup/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { useParams } from "react-router";
import {
  SupplierDto,
  SupplierServiceStatusTypeDto,
} from "src/store/supplier/type";
import { isLoading } from "src/store/loader";
import Loader from "../../../components/Loader";
import { SupplierTypeDto } from "src/store/suppliertypes/type";
import { getSupplierTypeList } from "src/store/suppliertypes/saga";

const EditSupplierPage = ({
  supplier,
  setSupplier,
}: {
  supplier: SupplierDto;
  setSupplier: (upplier: SupplierDto) => void;
}) => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [supplierTypes, setSupplierTypes] = useState<SupplierTypeDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  const loader = useSelector(isLoading);
  const [serviceTypes, setServiceTypes] = useState<
    SupplierServiceStatusTypeDto[]
  >([]);
  useEffect(() => {
    dispatch(
      getSupplierServiceStatusTypeList({
        payload: {
          onSuccess: (msg, payload) => setServiceTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getTowns({
        payload: {
          onSuccess: (msg, payload2) => setTowns(payload2),
          onError: () => {},
        },
        id: supplier.CityId,
      })
    );
    dispatch(
      getSupplierTypeList({
        payload: {
          onSuccess: (msg, payload) => setSupplierTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getCities({
        payload: {
          onSuccess: (msg, payload) => setCities(payload),
          onError: () => {},
        },
      })
    ); // eslint-disable-next-line
  }, [dispatch, id]);
  return (
    <React.Fragment>
      {loader && <Loader />}
      {supplier && (
        <CustomFormik
          initialValues={{
            Id: supplier.Id,
            SupplierTypeId: supplier.SupplierTypeId,
            Name: supplier.Name ?? "",
            TaxNumber: supplier.TaxNumber ?? "",
            TaxOffice: supplier.TaxOffice ?? "",
            Address: supplier.Address ?? "",
            CityId: supplier.CityId ?? "",
            PersonName: supplier.PersonName ?? "",
            PersonSurname: supplier.PersonSurname ?? "",
            TownId: supplier.TownId ?? "",
            Active: supplier.Active,
            BankName: supplier.BankName ?? "",
            Iban: supplier.Iban ?? "",
            Email: supplier.Email ?? "",
            Brand: supplier.Brand ?? "",
            CurrentLogoFile: supplier.Logo ?? "",
            LogoFile: "",
            ContractStartDate: supplier.ContractStartDate ?? "",
            ContractEndDate: supplier.ContractEndDate ?? "",
            ApiVehicleInformation: supplier.ApiVehicleInformation ?? false,
            ApiCustomerInformation: supplier.ApiCustomerInformation ?? false,
            LocationShow: supplier.LocationShow,
            SupplierServiceStatusTypeId:
              supplier.SupplierServiceStatusTypeId ?? "",
          }}
          onSubmit={values => {
            dispatch(
              updateSupplier({
                payload: {
                  onSuccess: (message, payload) => {
                    toast.success(message, { theme: "colored" });
                    return setSupplier(payload);
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: supplier.Id,
                body: values,
              })
            );
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required(t("Required")),
            SupplierServiceStatusTypeId: Yup.number().required(t("Required")),
            SupplierTypeId: Yup.string().required(t("Required")),
            Address: Yup.string().required(t("Required")),
            CityId: Yup.string().required(t("Required")),
            PersonName: Yup.string().required(t("Required")),
            PersonSurname: Yup.string().required(t("Required")),
            TownId: Yup.string().required(t("Required")),
            Email: Yup.string().required(t("Required")),
            Brand: Yup.string().required(t("Required")),
          })}
          inputs={[
            {
              field: "SupplierTypeId",
              inputType: InputType.multiselect,
              label: t("Supplier Type"),
              lookup: {
                data: supplierTypes,
                labelKey: "Name",
                valueKey: "Id",
                placeholder: t("Choose Supplier Type"),
              },
            },
            {
              field: "SupplierServiceStatusTypeId",
              inputType: InputType.multiselect,
              label: t("Tedarikçi Servis Tipi"),
              lookup: {
                data: serviceTypes,
                labelKey: "Name",
                valueKey: "EnumId",
                placeholder: t("Choose Supplier Type"),
              },
            },
            {
              col: 6,
              field: "Name",
              label: t("Name"),
            },
            {
              col: 6,
              field: "Brand",
              label: t("Brand"),
            },
            {
              col: 6,
              field: "Email",
              label: t("Email"),
            },

            {
              field: "PersonName",
              col: 6,
              label: t("Person Name"),
            },
            {
              field: "PersonSurname",
              col: 6,
              label: t("Person Surname"),
            },
            {
              field: "TaxOffice",
              label: t("Tax Office"),
              col: 6,
            },
            {
              field: "TaxNumber",
              label: t("Tax Number"),
              col: 6,
            },
            {
              field: "BankName",
              label: t("Bank Name"),
              col: 6,
            },
            {
              field: "Iban",
              label: t("IBAN"),
              col: 6,
            },
            {
              field: "ContractStartDate",
              label: t("Contract Start Date"),
              col: 6,
              inputType: InputType.datetime,
            },
            {
              field: "ContractEndDate",
              label: t("Contract End Date"),
              col: 6,
              inputType: InputType.datetime,
            },
            {
              col: 6,
              field: "CityId",
              inputType: InputType.multiselect,
              label: t("City"),
              lookup: {
                data: cities,
                labelKey: "CityName",
                valueKey: "Id",
                placeholder: t("Choose City"),
              },
              onChange(value, item, setFieldValue) {
                value &&
                  dispatch(
                    getTowns({
                      payload: {
                        onSuccess: (msg, payload) => setTowns(payload),
                        onError: () => {},
                      },
                      id: value,
                    })
                  );
              },
            },
            {
              col: 6,
              field: "TownId",
              inputType: InputType.multiselect,
              label: t("Town"),
              lookup: {
                data: towns,
                labelKey: "TownName",
                valueKey: "Id",
                placeholder: t("Choose Town"),
              },
            },
            {
              field: "Address",
              label: t("Address"),
              inputType: InputType.multilinetext,
            },
            {
              field: "ApiVehicleInformation",
              label: t("Api Vehicle Information"),
              col: 4,
              inputType: InputType.checkbox,
            },
            {
              field: "ApiCustomerInformation",
              label: t("Api Customer Information"),
              col: 4,
              inputType: InputType.checkbox,
            },
            {
              field: "LocationShow",
              label: t("Lokasyon Göster"),
              col: 4,
              inputType: InputType.checkbox,
            },
            {
              field: "LogoFile",
              label: t("LogoFile Image"),
              inputType: InputType.fileUpload,
              fileUpload: {
                accept: ["image/jpeg", "image/png"],
                multiple: false,
                linkField: "CurrentLogoFile",
                title: t("Please Logo Image"),
              },
            },

            {
              field: "Active",
              label: t("Active"),
              col: 6,
              checkedLabel: t("Active"),
              uncheckedLabel: t("Pasive"),
              inputType: InputType.toogle,
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};
export default EditSupplierPage;
