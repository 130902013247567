import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { MembershipcancelRequestDto } from "./type";

export const getMembershipcancelrequestList = createAction(
  "GET_MEMBERSHIPCANCEL_REQUEST_LIST",
  ({ payload, params }: SagaActionParams<MembershipcancelRequestDto[]>) => ({
    payload,
    url: "/api/membershipcancellationrequests" + (params ? params : ""),
  })
);
export const getMembershipcancelrequestListByCustomerId = createAction(
  "GET_MEMBERSHIPCANCEL_REQUEST_LIST_BY_CUSTOMER_ID",
  ({
    payload,
    params,
    id,
  }: SagaActionParams<MembershipcancelRequestDto[]>) => ({
    payload,
    url:
      "/api/membershipcancellationrequests/bycustomerId/" +
      id +
      (params ? params : ""),
  })
);
const MembershipcancelSaga = [
  takeLatest(
    getMembershipcancelrequestList.toString(),
    (payload: SagaGenericParams<MembershipcancelRequestDto[]>) =>
      getListSaga<MembershipcancelRequestDto[]>(payload)
  ),
  takeLatest(
    getMembershipcancelrequestListByCustomerId.toString(),
    (payload: SagaGenericParams<MembershipcancelRequestDto[]>) =>
      getListSaga<MembershipcancelRequestDto[]>(payload)
  ),
];

export default MembershipcancelSaga;
