import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivilegeLocationDto } from "src/store/privilegelocations/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CreatePrivilegeLocation from "./create";
import { toast } from "react-toastify";
import EditPrivilegeLocation from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  deletePrivilegeLocation,
  getPrivilegeLocationListByPrivilegeId,
} from "src/store/privilegelocations/saga";
import MainPage from "src/components/PageContent/MainPage";
import { useParams } from "react-router";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";
import { getCampaignDetails } from "src/store/campaigns/saga";
import { CampaignDto } from "src/store/campaigns/type";

const PrivilegeLocationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false); // eslint-disable-next-line
  const [selected, setSelected] = useState<PrivilegeLocationDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<PrivilegeLocationDto[]>([]);
  const [showDelete, setShowDelete] = useState<PrivilegeLocationDto>();
  const [privilege, setPrivilege] = useState<CampaignDto>();
  const user = useSelector(userSelector);
  useEffect(() => {
    dispatch(
      getCampaignDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Hizmet"),
      dataField: "PrivilegeTitle",
    },
    {
      text: t("İş Ortağı"),
      dataField: "SupplierLocationSupplierName",
    },
    {
      text: t("İş Ortağı Lokasyonu"),
      dataField: "SupplierLocationName",
    },
    {
      text: t("Telefon"),
      dataField: "SupplierLocationPhoneNumber",
    },
    {
      text: t("Adres"),
      dataField: "SupplierLocationAddress",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {/* <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getPrivilegeLocationDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          /> */}
          {CheckRole(user) && (
            <ColumnButton
              buttonType="delete"
              onClick={() => {
                return setShowDelete(data);
              }}
            />
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPrivilegeLocationListByPrivilegeId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {privilege && (
        <>
          {selected && (
            <EditPrivilegeLocation
              privilege={privilege}
              data={selected}
              onHide={() => setShowEdit(false)}
              open={showEdit}
              onSuccessClick={onSuccessClick}
            />
          )}
          <CreatePrivilegeLocation
            privilege={privilege}
            privilegeId={id}
            onHide={() => setShowCreate(false)}
            open={showCreate}
            onSuccessClick={onSuccessClick}
          />
        </>
      )}

      {privilege && (
        <MainPage
          title={privilege.Title + " " + t("PrivilegeLocationTitle")}
          gridProps={{
            listData: data,
            columns: columns,
            create: {
              createType: "drawer",
              onCreateClick: () => setShowCreate(true),
            },
            setDataList: setData,
            action: getPrivilegeLocationListByPrivilegeId,
            paramsid: id,
          }}
        />
      )}
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete privilege price`, {
            name: `${showDelete.PrivilegeId} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePrivilegeLocation({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PrivilegeLocationPage;
