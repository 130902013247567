import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireSupplierInfoDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireSupplierInfoList = createAction(
  "GET_TIRE_SUPPLIER_INFOLIST",
  ({ payload, params }: SagaActionParams<TireSupplierInfoDto[]>) => ({
    payload,
    url: "/api/tiresupplierInfos" + (params ? params : ""),
  })
);
export const getTireSupplierInfoListByTireId = createAction(
  "GET_TIRE_SUPPLIER_INFOLIST_BY_ID",
  ({ payload, params, id }: SagaActionParams<TireSupplierInfoDto[]>) => ({
    payload,
    url: "/api/tiresupplierInfos/bytireId/" + id + (params ? params : ""),
  })
);
export const getTireSupplierInfoDetails = createAction(
  "GET_TIRE_SUPPLIER_INFODETAILS",
  ({ payload, id }: SagaActionParams<TireSupplierInfoDto>) => ({
    payload,
    url: "/api/tiresupplierInfos/" + id,
  })
);
export const createTireSupplierInfo = createAction(
  "CREATE_TIRE_SUPPLIER_INFODETAILS",
  ({ payload, body }: SagaActionParams<TireSupplierInfoDto>) => ({
    payload,
    url: "/api/tiresupplierInfos",
    body,
  })
);
export const updateTireSupplierInfo = createAction(
  "UPDATE_TIRE_SUPPLIER_INFODETAILS",
  ({ payload, body, id }: SagaActionParams<TireSupplierInfoDto>) => ({
    payload,
    url: "/api/tiresupplierInfos/" + id,
    body,
  })
);
export const deleteTireSupplierInfo = createAction(
  "DELETE_TIRE_SUPPLIER_INFODETAILS",
  ({ payload, body, id }: SagaActionParams<TireSupplierInfoDto>) => ({
    payload,
    url: "/api/tiresupplierInfos/" + id,
    body,
  })
);

const tireSupplierInfoSaga = [
  takeLatest(
    getTireSupplierInfoList.toString(),
    (payload: SagaGenericParams<TireSupplierInfoDto[]>) =>
      getListSaga<TireSupplierInfoDto[]>(payload)
  ),
  takeLatest(
    getTireSupplierInfoListByTireId.toString(),
    (payload: SagaGenericParams<TireSupplierInfoDto[]>) =>
      getListSaga<TireSupplierInfoDto[]>(payload)
  ),
  takeLatest(
    getTireSupplierInfoDetails.toString(),
    (payload: SagaGenericParams<TireSupplierInfoDto>) =>
      getListSaga<TireSupplierInfoDto>(payload)
  ),
  takeLatest(
    createTireSupplierInfo.toString(),
    (payload: PostSagaGenericParams<TireSupplierInfoDto>) =>
      postSaga<TireSupplierInfoDto>(payload)
  ),

  takeLatest(
    updateTireSupplierInfo.toString(),
    (payload: PostSagaGenericParams<TireSupplierInfoDto>) =>
      putSaga<TireSupplierInfoDto>(payload)
  ),
  takeLatest(
    deleteTireSupplierInfo.toString(),
    (payload: SagaGenericParams<TireSupplierInfoDto>) =>
      deleteSaga<TireSupplierInfoDto>(payload)
  ),
];

export default tireSupplierInfoSaga;
