import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { ContactCategoryDto } from "./type";
import { BodyType } from "../base/request/request";

export const getContactCategoryList = createAction(
  "GET_CONTACTCATEGORY_LIST",
  ({ payload, params }: SagaActionParams<ContactCategoryDto[]>) => ({
    payload,
    url: "/api/contactcategories" + (params ? params : ""),
  })
);
export const getContactCategoryDetails = createAction(
  "GET_CONTACTCATEGORY_DETAILS",
  ({ payload, id }: SagaActionParams<ContactCategoryDto>) => ({
    payload,
    url: "/api/contactcategories/" + id,
  })
);
export const createContactCategorySaga = createAction(
  "CREATE_CONTACTCATEGORY_DETAILS",
  ({ payload, body }: SagaActionParams<ContactCategoryDto>) => ({
    payload,
    url: "/api/contactcategories",
    body,
  })
);
export const updateContactCategory = createAction(
  "UPDATE_CONTACTCATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<ContactCategoryDto>) => ({
    payload,
    url: "/api/contactcategories/" + id,
    body,
  })
);
export const deleteContactCategory = createAction(
  "DELETE_CONTACTCATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<ContactCategoryDto>) => ({
    payload,
    url: "/api/contactcategories/" + id,
    body,
  })
);

export const changeContactCategoryOrder = createAction(
  "UPDATE_CONTACTCATEGORY_ORDER",
  ({ payload, body }: SagaActionParams<ContactCategoryDto>) => ({
    payload,
    url: "/api/contactcategories/order",

    bodyType: BodyType.raw,
    body,
  })
);
const contactcategorySaga = [
  takeLatest(
    getContactCategoryList.toString(),
    (payload: SagaGenericParams<ContactCategoryDto[]>) =>
      getListSaga<ContactCategoryDto[]>(payload)
  ),
  takeLatest(
    getContactCategoryDetails.toString(),
    (payload: SagaGenericParams<ContactCategoryDto>) =>
      getListSaga<ContactCategoryDto>(payload)
  ),
  takeLatest(
    createContactCategorySaga.toString(),
    (payload: PostSagaGenericParams<ContactCategoryDto>) =>
      postSaga<ContactCategoryDto>(payload)
  ),
  takeLatest(
    changeContactCategoryOrder.toString(),
    (payload: PostSagaGenericParams<ContactCategoryDto>) =>
      putSaga<ContactCategoryDto>(payload)
  ),
  takeLatest(
    updateContactCategory.toString(),
    (payload: PostSagaGenericParams<ContactCategoryDto>) =>
      putSaga<ContactCategoryDto>(payload)
  ),
  takeLatest(
    deleteContactCategory.toString(),
    (payload: SagaGenericParams<ContactCategoryDto>) =>
      deleteSaga<ContactCategoryDto>(payload)
  ),
];

export default contactcategorySaga;
