import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import { Column, DataGrid } from "devextreme-react/data-grid";
import OrderInfoCard from "./components/orderInfoCard";
import RefundInfoCard from "./components/refundInfo";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { OrderDetailDto, OrderItem } from "src/store/orders/orders/type";
import { getOrderDetail } from "src/store/orders/orders/saga";
import UpdateOrderStatusModal from "./status/update-status";
import { Button } from "devextreme-react";

const OrderDetailPage = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const loader = useSelector(isLoading);
  const [order, setOrder] = useState<OrderDetailDto>();
  const [showOrderStatus, setShowOrderStatus] = useState<{
    orderItem?: OrderItem;
    open: boolean;
  }>();
  useEffect(() => {
    dispatch(
      getOrderDetail({
        payload: {
          onSuccess: (msg, payload) => setOrder(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  const onSuccessClick = () => {
    dispatch(
      getOrderDetail({
        payload: {
          onSuccess: (msg, payload) => setOrder(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  };

  const columns = [
    {
      dataField: "Product.Name",
      caption: "Ürün",
    },
    {
      dataField: "OrderItemStatusName",
      caption: "Durum",
      cellRender: (e: any) => {
        return (
          <>
            {e.row.data.OrderItemStatusName}

            {e.row.data.ShippingInfo && (
              <div className="font-size-10 text-muted">
                {e.row.data.ShippingInfo && (
                  <>
                    Teslimat Kargo No: <br />
                    <p
                      className="ms-2"
                      style={{ fontSize: 10 }}
                    >{` ${e.row.data.ShippingInfo.OrderShippingCompanyName} - ${e.row.data.ShippingInfo.TrackingNumber} `}</p>
                  </>
                )}
                {e.row.data.ShippingInfo &&
                  e.row.data.ShippingInfo.RefundTrackingNumber && (
                    <>
                      İade Kargo No: <br />
                      <p
                        className="ms-2 pb-0"
                        style={{ fontSize: 10 }}
                      >{`  ${e.row.data.ShippingInfo.RefundTrackingNumber} `}</p>
                    </>
                  )}
                {e.row.data.ShippingInfo &&
                  e.row.data.ShippingInfo.RefundRejectTrackingNumber && (
                    <>
                      İade Ret Kargo No: <br />
                      <p
                        className="ms-2"
                        style={{ fontSize: 10 }}
                      >{` ${e.row.data.ShippingInfo.RefundRejectTrackingNumber} `}</p>
                    </>
                  )}
              </div>
            )}
          </>
        );
      },
    },

    {
      dataField: "Count",
      caption: "Adet",
    },
    {
      dataField: "TotalAmount",
      caption: "Fiyat",
    },
    {
      dataField: "ShippingAmount",
      caption: "Kargo Fiyatı",
    },
    {
      dataField: "GrandTotal",
      caption: "Genel Toplam",
    },
    {
      type: "buttons",
      buttons: [
        {
          text: "Ürün Detay",
          cssClass: "columnUseButton",
          onClick: async (e: any) =>
            history.push("/products/1/" + e.row.data.ProductId),
        },
        {
          text: "Durum Güncelle",
          cssClass: "columnUseButton",
          onClick: async (e: any) =>
            setShowOrderStatus({
              open: true,
              orderItem: e.row.data,
            }),
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>Sipariş Detay</MetaTags>
        {loader && <Loader />}
        {order && (
          <Container fluid>
            <Row>
              <Col md={4}>
                <Breadcrumbs
                  title={t("Dashboard")}
                  breadcrumbItem={`Sipariş Detay`}
                />
              </Col>
              <Col md={8}>
                <div className="w-100 d-flex justify-content-end gap-1">
                  <Button
                    onClick={() =>
                      setShowOrderStatus({
                        open: true,
                      })
                    }
                  >
                    Durum Güncelle
                  </Button>
                </div>
              </Col>
            </Row>
            <Card className="py-3">
              <Col md={12} className="px-3 pb-1">
                <OrderInfoCard order={order} />
              </Col>
              <Col md={12}>
                <RefundInfoCard order={order} />
              </Col>
              <DataGrid
                key="Id"
                dataSource={order.OrderItems}
                showBorders={false}
                showRowLines={false}
                showColumnLines={false}
                allowColumnResizing
              >
                {columns.map((column, i) => {
                  //@ts-ignore
                  return <Column key={i} {...column} />;
                })}
              </DataGrid>
            </Card>
          </Container>
        )}
        {showOrderStatus && (
          <UpdateOrderStatusModal
            onSuccessClick={onSuccessClick}
            open={showOrderStatus.open}
            order={order}
            orderItem={showOrderStatus.orderItem}
            setOpen={() => setShowOrderStatus(undefined)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(OrderDetailPage);
