import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ProductCategoryDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getProductCategoryList = createAction(
  "GET_PRODUCT_CATEGORY_LIST",
  ({ payload, params }: SagaActionParams<ProductCategoryDto[]>) => ({
    payload,
    url: "/api/productcategories/list" + (params ? params : ""),
  })
);
export const getAllProductCategoryList = createAction(
  "GET_ALL_PRODUCT_CATEGORY_LIST",
  ({ payload, params }: SagaActionParams<ProductCategoryDto[]>) => ({
    payload,
    url: "/api/productcategories" + (params ? params : ""),
  })
);
export const getProductCategoryDetails = createAction(
  "GET_PRODUCT_CATEGORY_DETAILS",
  ({ payload, id }: SagaActionParams<ProductCategoryDto>) => ({
    payload,
    url: "/api/productcategories/" + id,
  })
);
export const createProductCategory = createAction(
  "CREATE_PRODUCT_CATEGORY_DETAILS",
  ({ payload, body }: SagaActionParams<ProductCategoryDto>) => ({
    payload,
    url: "/api/productcategories",
    body,
  })
);
export const updateProductCategory = createAction(
  "UPDATE_PRODUCT_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCategoryDto>) => ({
    payload,
    url: "/api/productcategories/" + id,
    body,
  })
);
export const deleteProductCategory = createAction(
  "DELETE_PRODUCT_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCategoryDto>) => ({
    payload,
    url: "/api/productcategories/" + id,
    body,
  })
);
const productCategorySagaSaga = [
  takeLatest(
    getProductCategoryList.toString(),
    (payload: SagaGenericParams<ProductCategoryDto[]>) =>
      getListSaga<ProductCategoryDto[]>(payload)
  ),
  takeLatest(
    getAllProductCategoryList.toString(),
    (payload: SagaGenericParams<ProductCategoryDto[]>) =>
      getListSaga<ProductCategoryDto[]>(payload)
  ),
  takeLatest(
    getProductCategoryDetails.toString(),
    (payload: SagaGenericParams<ProductCategoryDto>) =>
      getListSaga<ProductCategoryDto>(payload)
  ),
  takeLatest(
    createProductCategory.toString(),
    (payload: PostSagaGenericParams<ProductCategoryDto>) =>
      postSaga<ProductCategoryDto>(payload)
  ),
  takeLatest(
    updateProductCategory.toString(),
    (payload: PostSagaGenericParams<ProductCategoryDto>) =>
      putSaga<ProductCategoryDto>(payload)
  ),
  takeLatest(
    deleteProductCategory.toString(),
    (payload: SagaGenericParams<ProductCategoryDto>) =>
      deleteSaga<ProductCategoryDto>(payload)
  ),
];

export default productCategorySagaSaga;
