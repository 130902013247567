import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CreateTire from "./create";
import { toast } from "react-toastify";
import EditTire from "./edit";
import CancelAlert from "src/components/CancelAlert";
import MainPage from "src/components/PageContent/MainPage";
import { useParams } from "react-router";
import { TireDto } from "src/store/tires/tires/type";
import { getTireDetails } from "src/store/tires/tires/saga";
import { TireSupplierInfoDto } from "src/store/tires/supplierinfos/type";
import {
  deleteTireSupplierInfo,
  getTireSupplierInfoDetails,
  getTireSupplierInfoList,
  getTireSupplierInfoListByTireId,
} from "src/store/tires/supplierinfos/saga";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";
const TireSupplierInfoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<TireSupplierInfoDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<TireSupplierInfoDto[]>([]);
  const [showDelete, setShowDelete] = useState<TireSupplierInfoDto>();
  const user = useSelector(userSelector);
  const [tire, setTire] = useState<TireDto>();
  const { id }: { id: string } = useParams();
  useEffect(() => {
    dispatch(
      getTireDetails({
        payload: {
          onSuccess: (m, p) => {
            setTire(p);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [id, dispatch]);
  const columns = [
    {
      text: t("Lastik Adı"),
      dataField: "TireName",
    },
    {
      text: t("Tedarikçi"),
      dataField: "TireSupplierName",
    },
    {
      text: t("Tedarikçi Kodu"),
      dataField: "SupplierStockCode",
    },
    {
      text: t("Birim Fiyat"),
      dataField: "UnitPrice",
    },
    {
      text: t("Vergi Oranı"),
      dataField: "TaxRate",
    },
    {
      text: t("Toplam Fiyat"),
      dataField: "TotalPrice",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {CheckRole(user) && (
            <>
              <ColumnButton
                buttonType="edit"
                onClick={() => {
                  dispatch(
                    getTireSupplierInfoDetails({
                      payload: {
                        onSuccess: (message, payload) => {
                          setSelected(payload);
                          return setShowEdit(true);
                        },
                        onError: () => {},
                      },
                      id: cellContent,
                    })
                  );
                }}
              />
              <ColumnButton
                buttonType="delete"
                onClick={() => {
                  return setShowDelete(data);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getTireSupplierInfoList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditTire
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateTire
        tireId={id}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      {tire && (
        <MainPage
          title={tire.Name + t(" Tedarikçi Bilgileri")}
          gridProps={{
            listData: data,
            columns: columns,
            create: {
              createType: "drawer",
              onCreateClick: () => setShowCreate(true),
            },
            setDataList: setData,
            action: getTireSupplierInfoListByTireId,
            paramsid: id,
          }}
        />
      )}
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteTireSupplierInfo({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TireSupplierInfoPage;
