import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { updateBlackList } from "src/store/black-lists/saga";
import { BlackListDto } from "src/store/black-lists/type";
import * as Yup from "yup";

const EditBlackList = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: BlackListDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Kara Liste Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateBlackList({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          PhoneNumber: data.PhoneNumber,
          Email: data.Email,
          IdentityNumber: data.IdentityNumber,
        }}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            field: "PhoneNumber",
            label: t("Telefon Numarası"),
          },
          {
            field: "Email",
            label: t("E-posta"),
          },
          {
            field: "IdentityNumber",
            label: t("Kimlik Numarası"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditBlackList;
