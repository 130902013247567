import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";

const FirstPackageCustomerPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = [
    {
      caption: t("Name") + " " + t("Surname"),
      dataField: "FullName",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      caption: t("İl"),
      dataField: "CityName",
    },
    {
      caption: t("Paket"),
      dataField: "PackageName",
    },
    {
      caption: t("Hediye"),
      dataField: "FirstPurchasedPackage.PackageGiftPrivilege.PackageName",
    },
    {
      caption: t("Ay/Yıl"),
      dataField: "FirstPurchasedPackage.MonthlyYearly",
    },
    {
      caption: t("Paket Başlangıç Tarihi"),
      dataField: "FirstPurchasedPackage.StartDate",
      dataType: "date",
      sortOrder: "desc",
    },
    {
      caption: t("Fiyat"),
      dataField: "FirstPurchasedPackage.Price",
    },
    {
      caption: t("Üye Türü"),
      dataField: "Type",
    },
    {
      caption: t("Kupon Kodu"),
      dataField: "FirstPurchasedPackage.CouponCode",
    },

    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/1/" + e.row.key);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/customers/firstpackage/devexp",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Son Ücretli Üyeler")}
      />
    </React.Fragment>
  );
};

export default FirstPackageCustomerPage;
