import { useRef } from "react";
import { Button } from "reactstrap";

const FileUploader = ({
  onChange,
  value,
  className,
  title,
  style,
  icon = "bx bx-upload font-size-16 mx-1",
}: {
  onChange: (files: any[]) => void;
  value?: any;
  title?: string;
  className?: string;
  icon?: string;
  style?: any;
}) => {
  function handleAcceptedFiles(files: FileList | null) {
    if (files !== null) {
      let values = [];
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);
        values.push(file);
      }

      onChange(values);
    } else {
      onChange([]);
    }
  }

  const hiddenFileInput = useRef(null);
  return (
    <div>
      <Button
        type="button"
        color="light"
        onClick={() => {
          //@ts-ignore
          hiddenFileInput.current.click();
        }}
        style={{
          color: "#666E7D",
          ...style,
        }}
        className={"btn " + className}
      >
        <i className={icon}></i>
        {title && (
          <span className="d-none d-sm-inline-block me-2">{title}</span>
        )}
      </Button>
      {value && <p>{value}</p>}
      <input
        id="image-uploader"
        multiple={true}
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={e => handleAcceptedFiles(e.target.files)}
      />
    </div>
  );
};
export default FileUploader;
