import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ProductDetailDto, ProductDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getProductList = createAction(
  "GET_PRODUCT_LIST",
  ({ payload, params }: SagaActionParams<ProductDto[]>) => ({
    payload,
    url: "/api/products" + (params ? params : ""),
  })
);
export const getProductDetails = createAction(
  "GET_PRODUCT_DETAILS",
  ({ payload, id }: SagaActionParams<ProductDetailDto>) => ({
    payload,
    url: "/api/products/" + id,
  })
);
export const createProduct = createAction(
  "CREATE_PRODUCT_DETAILS",
  ({ payload, body }: SagaActionParams<ProductDto>) => ({
    payload,
    url: "/api/products",
    body,
  })
);
export const updateProduct = createAction(
  "UPDATE_PRODUCT_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductDto>) => ({
    payload,
    url: "/api/products/" + id,
    body,
  })
);
export const deleteProduct = createAction(
  "DELETE_PRODUCT_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductDto>) => ({
    payload,
    url: "/api/products/" + id,
    body,
  })
);
const productSaga = [
  takeLatest(
    getProductList.toString(),
    (payload: SagaGenericParams<ProductDto[]>) =>
      getListSaga<ProductDto[]>(payload)
  ),
  takeLatest(
    getProductDetails.toString(),
    (payload: SagaGenericParams<ProductDto>) => getListSaga<ProductDto>(payload)
  ),
  takeLatest(
    createProduct.toString(),
    (payload: PostSagaGenericParams<ProductDto>) =>
      postSaga<ProductDto>(payload)
  ),
  takeLatest(
    updateProduct.toString(),
    (payload: PostSagaGenericParams<ProductDto>) => putSaga<ProductDto>(payload)
  ),
  takeLatest(
    deleteProduct.toString(),
    (payload: SagaGenericParams<ProductDto>) => deleteSaga<ProductDto>(payload)
  ),
];

export default productSaga;
