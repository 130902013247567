import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import * as Yup from "yup";
import { CustomerVehicleDto } from "src/store/customervehicles/type";
import { CustomerDto } from "src/store/customers/type";
import { CallLogTopicDto } from "src/store/customercalllog/type";
import {
  getCustomerCalllogParentTopics,
  getCustomerCalllogSubTopicsByParentId,
} from "src/store/customercalllog/saga";
import { getCustomerList } from "src/store/customers/saga";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import {
  getCustomerCallLogsTypes,
  updateCustomerCallLogs,
} from "src/store/customer-calllog/saga";
import {
  CustomerCallLogTypeDto,
  CustomerCallLogsDto,
} from "src/store/customer-calllog/type";
import { CallLogChannelDto } from "src/store/calllog-channel/type";
import { getCallLogChannels } from "src/store/calllog-channel/saga";

const EditCustomerCalllogPage = ({
  data,
  active,
  setActive,
}: {
  active: number;
  data: CustomerCallLogsDto;
  setActive: (s: number) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [customers, setCustomers] = useState<CustomerDto[]>([]);
  const [types, setTypes] = useState<CustomerCallLogTypeDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);
  const [channels, setChannels] = useState<CallLogChannelDto[]>([]);
  const [parents, setParents] = useState<CallLogTopicDto[]>([]);
  const [subtopics, setSubTopics] = useState<CallLogTopicDto[]>([]);
  useEffect(() => {
    if (active === 2) {
      dispatch(
        getCustomerCallLogsTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerCalllogParentTopics({
          payload: {
            onSuccess: (msg, payload) => setParents(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, payload) => setCities(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTowns({
          payload: {
            onSuccess: (msg, payload) => setTowns(payload),
            onError: () => {},
          },
          id: data.CityId,
        })
      );
      dispatch(
        getCustomerList({
          payload: {
            onSuccess: (msg, payload) => setCustomers(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerVehicleListByCustomerId({
          payload: {
            onSuccess: (msg, payload) => setVehicles(payload),
            onError: () => {},
          },
          id: data.CustomerId,
        })
      );
      dispatch(
        getCustomerCalllogSubTopicsByParentId({
          payload: {
            onSuccess: (msg, payload) => setSubTopics(payload),
            onError: () => {},
          },
          id: data.CustomerCallLogParentTopicId,
        })
      );
      dispatch(
        getCallLogChannels({
          payload: {
            onSuccess: (msg, payload) => setChannels(payload),
            onError: () => {},
          },
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, active]);
  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <button className="btn btn-sm btn-danger" onClick={() => setActive(1)}>
          Kapat
        </button>
      </div>
      <CustomFormik
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name ?? "",
          Surname: data.Surname ?? "",
          PhoneNumber: data.PhoneNumber ?? "",
          PhoneNumber2: data.PhoneNumber2 ?? "",
          VehiclePlate: data.VehiclePlate ?? "",
          Brand: data.Brand ?? "",
          Model: data.Model ?? "",
          ModelYear: data.ModelYear ?? "",
          KmLimit: data.KmLimit ?? "",
          Address: data.Address ?? "",
          CallLogChannelId: data.CallLogChannelId ?? "",
          Not: data.Not ?? "",
          CustomerCallLogTopicId: data.CustomerCallLogParentTopicId ?? "",
          CustomerCallLogSubTopicId: data.CustomerCallLogSubTopicId ?? "",
          CustomerCallLogTypeId: data.CustomerCallLogTypeId ?? "",
          TownId: data.TownId ?? "",
          CityId: data.CityId ?? "",
          CustomerId: data.CustomerId ?? "",
          VehicleId: data.VehicleId ?? "",
        }}
        onSubmit={values => {
          dispatch(
            updateCustomerCallLogs({
              payload: {
                onSuccess: (message, payload) => {
                  toast.success(message, { theme: "colored" });
                  return setActive(1);
                  //   return history.push("/privilege/edit/" + payload.Id);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          CallLogChannelId: Yup.string().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          PhoneNumber: Yup.string().required(t("Required")),
          VehiclePlate: Yup.string().required(t("Required")),
          CityId: Yup.string().required(t("Required")),
          TownId: Yup.string().required(t("Required")),
          CustomerCallLogSubTopicId: Yup.string().required(t("Required")),
          CustomerCallLogTypeId: Yup.number()
            .typeError(t("Required"))
            .required(t("Required")),
        })}
        inputs={[
          {
            field: data.Name ?? "",
            label: t("Bilgi Başlık"),
            inputType: InputType.component,

            component(data) {
              return (
                <div className=" mb-2 font-size-20 card-title">
                  1. Arama Bilgileri
                </div>
              );
            },
          },
          {
            field: "CustomerCallLogTypeId",
            inputType: InputType.multiselect,
            label: t("Tip Seçiniz") + "*",
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",

              placeholder: t("Tip Seçiniz"),
            },
          },
          {
            field: "CustomerCallLogTopicId",
            inputType: InputType.multiselect,
            label: t("Ana Konu Seçiniz") + "*",
            lookup: {
              data: parents,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Konu Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              setFieldValue("CustomerCallLogSubTopicId", value);
              dispatch(
                getCustomerCalllogSubTopicsByParentId({
                  payload: {
                    onSuccess: (msg, payload) => setSubTopics(payload),
                    onError: () => {},
                  },
                  id: value,
                })
              );
            },
          },
          {
            field: "CustomerCallLogSubTopicId",
            inputType: InputType.multiselect,
            label: t("Alt Konu Seçiniz") + "*",
            lookup: {
              data: subtopics,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Alt Konu Seçiniz"),
            },
          },
          {
            field: "CallLogChannelId",
            inputType: InputType.multiselect,
            label: t("İletişim Kanalı") + "*",
            lookup: {
              data: channels,
              labelKey: "Name",
              searchable: false,
              valueKey: "Id",
              placeholder: t("İletişim Kanalı Seçiniz"),
            },
            col: 3,
          },

          {
            field: "Not",
            label: t("Not"),
            inputType: InputType.multilinetext,
          },
          {
            field: data.Name ?? "",
            label: t("Bilgi Başlık"),
            inputType: InputType.component,
            component(data) {
              return (
                <div className="mt-4 mb-2 font-size-20 card-title">
                  2. Üye Bilgileri
                </div>
              );
            },
          },
          {
            field: "CustomerId",
            inputType: InputType.multiselect,
            label: t("Kayıtlı Üye Seçimi (Aday üye ise boş geçiniz)"),
            lookup: {
              data: customers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Üye Seçiniz"),
              renderMenuItem(label, item) {
                return item ? (
                  <>
                    {item.Name} {item.Name} / {item.PhoneNumber}`
                  </>
                ) : (
                  <>{label}</>
                );
              },
              renderValue: (value, item: any, selectedElement) =>
                item ? (
                  <>
                    {item.Name} {item.Name} / {item.PhoneNumber}`
                  </>
                ) : (
                  <>{value}</>
                ),
            },
            onChange(value, item: CustomerDto, setFieldValue, values) {
              setFieldValue("Name", item.Name);
              setFieldValue("Surname", item.Surname);
              setFieldValue("PhoneNumber", item.PhoneNumber);
              dispatch(
                getCustomerVehicleListByCustomerId({
                  payload: {
                    onSuccess: (msg, payload) => setVehicles(payload),
                    onError: () => {},
                  },
                  id: value,
                })
              );
            },
          },
          {
            field: "Name",
            label: t("Name") + "*",
            disabled: values => (values.CustomerId ? true : false),
          },
          {
            field: "Surname",
            label: t("Surname") + "*",
            disabled: values => (values.CustomerId ? true : false),
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber") + "*",
          },
          {
            field: "PhoneNumber2",
            label: t("PhoneNumber") + 2,
          },
          {
            field: data.Name ?? "",
            label: t("Bilgi Başlık"),
            inputType: InputType.component,
            component(data) {
              return (
                <div className="mt-4 mb-2 font-size-20 card-title">
                  3. Araç Bilgileri
                </div>
              );
            },
          },
          {
            hide(values) {
              return values.CustomerId ? false : true;
            },
            field: "VehicleId",
            inputType: InputType.multiselect,
            label: t("Üye Araç Seçiniz"),
            lookup: {
              data: vehicles,
              labelKey: "Plate",
              valueKey: "Id",
              placeholder: t("Üye Araç Seçiniz"),
            },
            onChange(value, item: CustomerVehicleDto, setFieldValue, values) {
              setFieldValue("VehiclePlate", item.Plate ?? "");
              setFieldValue("Brand", item.make ?? "");
              setFieldValue("Model", item.model ?? "");
              setFieldValue("ModelYear", item.regdate ?? "");
            },
          },
          {
            disabled: values => (values.VehicleId ? true : false),
            field: "VehiclePlate",
            label: t("Plaka") + "*",
          },
          {
            disabled: values => (values.VehicleId ? true : false),
            field: "Brand",
            label: t("Brand"),
          },
          {
            disabled: values => (values.VehicleId ? true : false),
            field: "Model",
            label: t("Model"),
          },
          {
            field: "ModelYear",
            label: t("Model Yılı"),
            disabled: values => (values.VehicleId ? true : false),
          },
          {
            field: "KmLimit",
            label: t("Km Limit"),
          },
          {
            field: data.Name ?? "",
            label: t("Bilgi Başlık"),
            inputType: InputType.component,
            component(data) {
              return (
                <div className="mt-4 mb-2 font-size-20 card-title">
                  4. Adres Bilgileri
                </div>
              );
            },
          },

          {
            col: 6,
            field: "CityId",
            inputType: InputType.multiselect,
            label: t("City") + "*",
            lookup: {
              data: cities,
              labelKey: "CityName",
              placement: "topStart",
              valueKey: "Id",
              placeholder: t("Choose City"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            col: 6,
            field: "TownId",
            inputType: InputType.multiselect,
            label: t("Town") + "*",
            lookup: {
              placement: "topStart",
              data: towns,
              labelKey: "TownName",
              valueKey: "Id",
              placeholder: t("Choose Town"),
            },
          },
          {
            field: "Address",
            label: t("Address"),
            inputType: InputType.multilinetext,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditCustomerCalllogPage;
