import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CreateTireService from "./create";
import { toast } from "react-toastify";
import EditTireService from "./edit";
import CancelAlert from "src/components/CancelAlert";
import MainPage from "src/components/PageContent/MainPage";
import { useParams } from "react-router";
import { TireServiceDto } from "src/store/tires/services/type";
import {
  deleteTireService,
  getTireServiceDetails,
  getTireServiceList,
} from "src/store/tires/services/saga";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";
import DetailTireService from "./details";
const TireServicePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<TireServiceDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<TireServiceDto[]>([]);
  const [showDelete, setShowDelete] = useState<TireServiceDto>();
  const [showDetail, setShowDetail] = useState(false);

  const user = useSelector(userSelector);
  const columns = [
    {
      text: t("Unvan"),
      dataField: "Name",
    },
    {
      text: t("Yetkili"),
      dataField: "AuthorizedPerson",
    },
    {
      text: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      text: t("Address"),
      dataField: "Address",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="details2"
            onClick={() => {
              dispatch(
                getTireServiceDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowDetail(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          {CheckRole(user) && (
            <>
              <ColumnButton
                buttonType="edit"
                onClick={() => {
                  dispatch(
                    getTireServiceDetails({
                      payload: {
                        onSuccess: (message, payload) => {
                          setSelected(payload);
                          return setShowEdit(true);
                        },
                        onError: () => {},
                      },
                      id: cellContent,
                    })
                  );
                }}
              />
              <ColumnButton
                buttonType="delete"
                onClick={() => {
                  return setShowDelete(data);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getTireServiceList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditTireService
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      {selected && (
        <DetailTireService
          data={selected}
          onHide={() => setShowDetail(false)}
          open={showDetail}
        />
      )}
      <CreateTireService
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <MainPage
        title={t("Lastikçiler")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          },
          setDataList: setData,
          action: getTireServiceList,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteTireService({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TireServicePage;
