import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { TicketSubjectDetailInfoDto } from "./type";
import { BodyType } from "../base/request/request";

export const getTicketSubjectDetailInfoListByTicketSubjectDetailId =
  createAction(
    "GET_TICKET_SUBJECT_DETAIL_INFOLIST_BY_ID",
    ({
      payload,
      params,
      id,
    }: SagaActionParams<TicketSubjectDetailInfoDto[]>) => ({
      payload,
      url:
        "/api/ticketsubjectdetailinformations/bysubjectdetailid/" +
        id +
        (params ? params : ""),
    })
  );
export const getTicketSubjectDetailInfoList = createAction(
  "GET_TICKET_SUBJECT_DETAIL_INFOLIST",
  ({ payload, params }: SagaActionParams<TicketSubjectDetailInfoDto[]>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations" + (params ? params : ""),
  })
);
export const getTicketSubjectDetailInfoDetails = createAction(
  "GET_TICKET_SUBJECT_DETAIL_INFODETAILS",
  ({ payload, id }: SagaActionParams<TicketSubjectDetailInfoDto>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations/" + id,
  })
);
export const createTicketSubjectDetailInfo = createAction(
  "CREATE_TICKET_SUBJECT_DETAIL_INFODETAILS",
  ({ payload, body }: SagaActionParams<TicketSubjectDetailInfoDto>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations",
    body,
  })
);
export const updateTicketSubjectDetailInfo = createAction(
  "UPDATE_TICKET_SUBJECT_DETAIL_INFODETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDetailInfoDto>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations/" + id,
    body,
  })
);
export const deleteTicketSubjectDetailInfo = createAction(
  "DELETE_TICKET_SUBJECT_DETAIL_INFODETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDetailInfoDto>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations/" + id,
    body,
  })
);

export const changeTicketSubjectDetailInfoOrder = createAction(
  "UPDATE_TICKET_SUBJECT_DETAIL_INFOORDER",
  ({ payload, body }: SagaActionParams<TicketSubjectDetailInfoDto>) => ({
    payload,
    url: "/api/ticketsubjectdetailinformations/order",

    bodyType: BodyType.raw,
    body,
  })
);
const ticketSubjectDetailInfoSaga = [
  takeLatest(
    getTicketSubjectDetailInfoListByTicketSubjectDetailId.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailInfoDto[]>) =>
      getListSaga<TicketSubjectDetailInfoDto[]>(payload)
  ),
  takeLatest(
    getTicketSubjectDetailInfoList.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailInfoDto[]>) =>
      getListSaga<TicketSubjectDetailInfoDto[]>(payload)
  ),
  takeLatest(
    getTicketSubjectDetailInfoDetails.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailInfoDto>) =>
      getListSaga<TicketSubjectDetailInfoDto>(payload)
  ),
  takeLatest(
    createTicketSubjectDetailInfo.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailInfoDto>) =>
      postSaga<TicketSubjectDetailInfoDto>(payload)
  ),
  takeLatest(
    changeTicketSubjectDetailInfoOrder.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailInfoDto>) =>
      putSaga<TicketSubjectDetailInfoDto>(payload)
  ),
  takeLatest(
    updateTicketSubjectDetailInfo.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailInfoDto>) =>
      putSaga<TicketSubjectDetailInfoDto>(payload)
  ),
  takeLatest(
    deleteTicketSubjectDetailInfo.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailInfoDto>) =>
      deleteSaga<TicketSubjectDetailInfoDto>(payload)
  ),
];

export default ticketSubjectDetailInfoSaga;
