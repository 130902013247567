import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import {
  deleteCustomerDiscount,
  getCustomerDiscountDetails,
  getCustomerDiscountListByCustomerId,
} from "src/store/customerdiscounts/saga";
import { CustomerDiscountDto } from "src/store/customerdiscounts/type";
import CreateCustomerDiscountModal from "./createDiscount";
import EditCustomerDiscountModal from "./editDiscount";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";
import DetailTable from "src/components/PageContent/detailTable";

const CustomerDiscountTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const { id }: { tab: string; id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<CustomerDiscountDto>();
  const [selected, setSelected] = useState<CustomerDiscountDto>();
  const [discounts, setDiscounts] = useState<CustomerDiscountDto[]>([]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getCustomerDiscountListByCustomerId({
        payload: {
          onSuccess: (msg, payload) => setDiscounts(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  };
  const columns = [
    {
      text: t("Module"),
      dataField: "DiscountModuleName",
    },
    {
      text: t("Validity Period"),
      dataField: "DiscountValidityPeriodName",
    },
    {
      text: t("Type"),
      dataField: "DiscountTypeName",
    },
    {
      text: t("Discount Value"),
      dataField: "DiscounValue",
    },
    {
      text: t("Quantity"),
      dataField: "Quantity",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="edit"
              onClick={() => {
                dispatch(
                  getCustomerDiscountDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditCustomerDiscountModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreateCustomerDiscountModal
          id={id}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        <DetailTable
          create={() => setShowCreate(true)}
          listData={discounts}
          columns={columns}
          setDataList={setDiscounts}
          action={getCustomerDiscountListByCustomerId}
          paramsid={id}
        />
      </div>
      {showDelete && (
        <CancelAlert
          message={"Tanımlı indirimi Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCustomerDiscount({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default CustomerDiscountTab;
