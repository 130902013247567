import { useTranslation } from "react-i18next";
import images from "src/assets/image";
import FileUpload from "src/components/Form/FileUpload";
import { CustomModalProps } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";

const ImportExcelModal = ({ ...rest }: CustomModalProps) => {
  const { t } = useTranslation();
  return (
    <CustomModal {...rest}>
      <FileUpload
        multiple={false}
        icon={images.excelicon}
        accept={[".xls", ".xlsx"]}
        title={t("Excel import text")}
        onChange={files => {}}
      />

      <div className="d-flex justify-content-end">
        <button className="btn btn-success">Kaydet</button>
      </div>
    </CustomModal>
  );
};
export default ImportExcelModal;
