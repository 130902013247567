import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getCorporateClientCustomers } from "src/store/corporate-clients/saga";
import { getCorporateCompanyLookup } from "src/store/corporate-companies/saga";
import { CorporateCompanyLookupDto } from "src/store/corporate-companies/type";
import { createCorporateDepartments } from "src/store/corporate-departments/saga";
import { CustomerLookupDto } from "src/store/customers/type";
import * as Yup from "yup";

const CreateCorporateDepartment = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<CorporateCompanyLookupDto[]>([]);
  const [customers, setCustomers] = useState<CustomerLookupDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCorporateClientCustomers({
          payload: {
            onSuccess: (msg, payload) => setCustomers(payload),
            onError: () => {},
          },
          id: id,
        })
      );
      dispatch(
        getCorporateCompanyLookup({
          payload: {
            onSuccess: (msg, payload) => setCompanies(payload),
            onError: () => {},
          },
          id: id,
        })
      );
    }
  }, [dispatch, open, id]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Departman Ekle",
        }}
        onHide={onHide}
        loader
        initialValues={{
          CorporateCompanyId: "",
          DepartmentName: "",
          AuthorizedManagerId: "",
        }}
        validationSchema={Yup.object().shape({
          CorporateCompanyId: Yup.string().required(t("Required")),
          DepartmentName: Yup.string().required(t("Required")),
        })}
        onSubmit={values => {
          dispatch(
            createCorporateDepartments({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        inputs={[
          {
            field: "CorporateCompanyId",
            inputType: InputType.multiselect,
            label: t("Şirket Seçimi"),
            lookup: {
              data: companies,
              labelKey: "CompanyName",
              valueKey: "Id",
              placeholder: t("Şirket Seçiniz"),
            },
          },
          {
            field: "DepartmentName",
            label: "Departman Adı",
          },

          {
            field: "AuthorizedManagerId",
            inputType: InputType.multiselect,
            label: t("Yönetici"),
            lookup: {
              data: customers,
              labelKey: "FullName",
              valueKey: "Id",
              placeholder: t("Yönetici Seçiniz"),
            },
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCorporateDepartment;
