import { ApiCallback } from "./saga";

export class KomutRedux<TReduser, TActions> {
  initialState: TReduser;
  actions: TActions;
  reducers: any;
  sagas: any;

  constructor() {
    // eslint-disable-next-line
    this.initialState = (<any>{}) as any;

    // eslint-disable-next-line
    this.actions = (<any>{}) as any;
  }

  setInitialState(state: TReduser) {
    this.initialState = state;
  }

  setActions(actions: TActions) {
    this.actions = actions;
  }

  setRedusers(reducers: any) {
    this.reducers = reducers;
  }

  setSagas(sagas: any) {
    this.sagas = sagas;
  }
}

export interface IKomutRedux<TReduser, SystemActions> {
  initialState: TReduser;
  actions: SystemActions;
  reducers: any;
  sagas: any;
}

export interface KomutBaseSaga<TParams, TResults>
  extends ApiCallback<TResults> {
  params: TParams;
}

export interface SagaParamType<Type> {
  type: string;
  payload: Type;
}

export interface SagaType<Type> {
  type: string;
  payload: ApiCallback<Type>;
}
