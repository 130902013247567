import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
import { AgreementDto } from "src/store/agreement/type";
import { getAgreementList } from "src/store/agreement/saga";
import ColumnButton from "src/components/Form/Button/ColumnButton";

const AgreementsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<AgreementDto[]>([]);

  const columns = [
    {
      text: t("Sözleşme Tipi"),
      dataField: "AgreementTypeName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="details"
            onClick={() => {
              return history.push("/agreements/details/" + cellContent);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        withoutPag
        title={t("Sözleşmeler")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "link",
            createLink: "/agreements/create",
          },
          setDataList: setData,
          action: getAgreementList,
        }}
      />
      {/* {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete agreement`, {
            name: `${showDelete.Id} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteAgreements({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )} */}
    </React.Fragment>
  );
};

export default AgreementsPage;
