import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import CustomCheckbox from "src/components/Form/Input/CheckBox";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { cancelPrivilegePayment } from "src/store/customers/saga";
import { CustomerPurchasedPrivilegeDto } from "src/store/customers/type";

const PurchasedPrivileges = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string; tab: string } = useParams();
  const [useMoka, setUseMoka] = useState(false);
  const [showCancel, setShowCancel] = useState<CustomerPurchasedPrivilegeDto>();
  const columns = [
    {
      caption: t("Hizmet Adı"),
      dataField: "PrivilegePriceName",
    },
    {
      dataField: "Price",
      caption: "Tutar",
      dataType: "number",
    },
    {
      dataField: "UnitPrice",
      caption: "Birim Fiyat",
      dataType: "number",
    },
    {
      dataField: "TaxPrice",
      caption: "Vergi",
      dataType: "number",
    },
    {
      caption: t("İade"),
      dataField: "IsRefund",
      dataType: "boolean",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "İade",
          text: "İade",
          cssClass: "text-dark bg-soft-danger columnUseButton",
          visible: (e: any) =>
            e.row.data.Price &&
            !e.row.data.IsRefund &&
            e.row.data.CustomerPurchaseTransactionId,
          onClick: async (e: any) => {
            setShowCancel(e.row.data);
          },
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <CustomDataGrid
          url={"/api/customers/purchased-privileges/" + id}
          columns={columns}
          className="detailGrid"
        />
      </Row>{" "}
      {showCancel && (
        <CancelAlert
          children={
            <CustomCheckbox
              field={"UseMoka"}
              label={"Moka'ya bildir"}
              value={useMoka}
              onChange={e => {
                return setUseMoka(e);
              }}
            />
          }
          message={`Hizmet tutarını iade etmek istediğinize emin misiniz?`}
          onConfirm={() => {
            dispatch(
              cancelPrivilegePayment({
                payload: {
                  onSuccess: message => {
                    setShowCancel(undefined);
                    toast.success(message);
                  },
                  onError: message => {
                    setShowCancel(undefined);
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  PurchasedId: showCancel.Id,
                  UseMoka: useMoka,
                },
              })
            );
          }}
          onCancel={() => {
            setShowCancel(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PurchasedPrivileges;
