import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PrivilegeCardDetailDto,
  PrivilegeCardDto,
  PrivilegeCardStatusEnum,
} from "src/store/privilegecard/type";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import {
  getPrivilegeCardDetails,
  getUsingPrivilegeCardListByCustomerPrivilegeId,
  printPrivilegeCard,
  sendPrivilegeCard,
  setActivePrivilegeCard,
  setCancelledPrivilegeCard,
} from "src/store/privilegecard/saga";
import { useParams } from "react-router";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import MainPage from "src/components/PageContent/MainPage";
import { CustomerPrivilegeDto } from "src/store/customerprivilege/type";
import { getCustomerPrivilegeDetails } from "src/store/customerprivilege/saga";
import CreatePrivilegeCardForm from "./create";
import CardHistory from "./details";
import CardSendForm from "./sendForm";

const UsingPrivilegeCardPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<PrivilegeCardDto[]>([]);
  const [privilege, setPrivilege] = useState<CustomerPrivilegeDto>();
  const [showActionButton, setShowActionButton] = useState<any>();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PrivilegeCardDetailDto>();

  useEffect(() => {
    dispatch(
      getCustomerPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Kart Numarası"),
      dataField: "CardNumber",
    },
    {
      text: t("Müşteri Adı"),
      dataField: "CustomerName",
    },
    {
      text: t("Durum"),
      dataField: "LastStatusName",
    },
    {
      text: t("Kargo Firması"),
      dataField: "OrderShippingCompanyName",
    },
    {
      text: t("Takip No."),
      dataField: "TrackingNumber",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => {
        var action = GetActions(data.LastStatusId, cellContent);
        return (
          <div className="d-flex w-30 justify-content-end gap-1">
            {data.LastStatusId === PrivilegeCardStatusEnum.IslemBekliyor && (
              <ColumnButton
                buttonType="customtext"
                custombutton={{
                  text: "İptal Et",
                  color: "#fd625e",
                }}
                onClick={() => {
                  setShowActionButton({
                    buttonTitle: "İptal Et",
                    action: setCancelledPrivilegeCard,
                    qa: "Kartı iptal etmek istediğinize emin misiniz?",
                    id: cellContent,
                  });
                }}
              />
            )}
            {action && (
              <ColumnButton
                buttonType="customtext"
                custombutton={{
                  text: action.buttonTitle,
                }}
                onClick={() => {
                  setShowActionButton(action);
                }}
              />
            )}
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                text: "Detay",
                color: "#5156be",
              }}
              onClick={() => {
                dispatch(
                  getPrivilegeCardDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
          </div>
        );
      },
    },
  ];
  const GetActions = (statusId: number, id: string) => {
    switch (statusId) {
      case PrivilegeCardStatusEnum.IslemBekliyor:
        return {
          buttonTitle: "Kartı Basıldı İşaretle",
          action: printPrivilegeCard,
          qa: "Kartı basıldı olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      case PrivilegeCardStatusEnum.KartBasildi:
        return {
          buttonTitle: "Kartı Gönderildi İşaretle",
          action: sendPrivilegeCard,
          qa: "Kartı gönderildi olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      case PrivilegeCardStatusEnum.Gonderildi:
        return {
          buttonTitle: "Kartı Aktif Edildi İşaretle",
          action: setActivePrivilegeCard,
          qa: "Kartı aktif edildi olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      default:
        return undefined;
    }
  };
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getUsingPrivilegeCardListByCustomerPrivilegeId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {privilege && (
        <CreatePrivilegeCardForm
          customerPrivilege={privilege}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
      )}
      {selected && (
        <CardHistory
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <MainPage
        title={
          privilege ? privilege.PrivilegeName + " " + t("Atanan Kartlar") : ""
        }
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          },
          addTitle: "Müşteriye Kart Ekle",
          setDataList: setData,
          action: getUsingPrivilegeCardListByCustomerPrivilegeId,
          paramsid: id,
        }}
      />
      {showActionButton && (
        <>
          {showActionButton.statusId === PrivilegeCardStatusEnum.KartBasildi ? (
            <>
              <CardSendForm
                cardId={showActionButton.id}
                onHide={() => {}}
                open={true}
                onSuccessClick={m => {
                  onSuccessClick(m);
                  return setShowActionButton(undefined);
                }}
              />
            </>
          ) : (
            <>
              <CancelAlert
                message={showActionButton.qa}
                onConfirm={() => {
                  dispatch(
                    showActionButton.action({
                      payload: {
                        onSuccess: (message: any, payload: any) => {
                          onSuccessClick(message);
                        },
                        onError: (message: any) => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: { CardId: showActionButton.id },
                    })
                  );
                  setShowActionButton(undefined);
                }}
                onCancel={() => {
                  setShowActionButton(undefined);
                }}
              />
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default UsingPrivilegeCardPage;
