import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerAddressDto } from "./type";

export const getCustomerAddressListByCustomerId = createAction(
  "GET_CUSTOMER_ADDRESS_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerAddressDto[]>) => ({
    payload,
    url: "/api/customeraddress/getbycustomerid/" + id + (params ? params : ""),
  })
);
export const getCustomerAddressDetails = createAction(
  "GET_CUSTOMER_ADDRESS_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerAddressDto>) => ({
    payload,
    url: "/api/customeraddress/" + id,
  })
);
export const createCustomerAddress = createAction(
  "CREATE_CUSTOMER_ADDRESS_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerAddressDto>) => ({
    payload,
    url: "/api/customeraddress",
    body,
  })
);
export const updateCustomerAddress = createAction(
  "UPDATE_CUSTOMER_ADDRESS_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerAddressDto>) => ({
    payload,
    url: "/api/customeraddress/" + id,
    body,
  })
);
export const deleteCustomerAddress = createAction(
  "DELETE_CUSTOMER_ADDRESS_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerAddressDto>) => ({
    payload,
    url: "/api/customeraddress/" + id,
    body,
  })
);
const customeraddresssaga = [
  takeLatest(
    getCustomerAddressListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerAddressDto[]>) =>
      getListSaga<CustomerAddressDto[]>(payload)
  ),
  takeLatest(
    getCustomerAddressDetails.toString(),
    (payload: SagaGenericParams<CustomerAddressDto>) =>
      getListSaga<CustomerAddressDto>(payload)
  ),
  takeLatest(
    createCustomerAddress.toString(),
    (payload: PostSagaGenericParams<CustomerAddressDto>) =>
      postSaga<CustomerAddressDto>(payload)
  ),
  takeLatest(
    updateCustomerAddress.toString(),
    (payload: PostSagaGenericParams<CustomerAddressDto>) =>
      putSaga<CustomerAddressDto>(payload)
  ),
  takeLatest(
    deleteCustomerAddress.toString(),
    (payload: SagaGenericParams<CustomerAddressDto>) =>
      deleteSaga<CustomerAddressDto>(payload)
  ),
];

export default customeraddresssaga;
