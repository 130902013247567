import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateSupplierDocument } from "src/store/supplierdocuments/saga";
import { SupplierDocumentDto } from "src/store/supplierdocuments/type";
import * as Yup from "yup";

const EditSupplierDocumentModal = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: SupplierDocumentDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit SupplierDocument"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          SupplierId: data.SupplierId,
          Name: data.Name,
          CurrentDocument: data.Document,
          DocumentFile: "",
          StartDate: data.StartDate ?? "",
          EndDate: data.EndDate ?? "",
          Active: data.Active,
        }}
        onSubmit={values => {
          dispatch(
            updateSupplierDocument({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick("Doküman başarıyla güncellenmiştir");
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "EndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
          {
            field: "Active",
            label: t("Active"),
            inputType: InputType.checkbox,
          },
          {
            field: "DocumentFile",
            label: t("DocumentFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              multiple: false,
              linkField: "CurrentDocument",
              title: t("Please Upload Document"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditSupplierDocumentModal;
