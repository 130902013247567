import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { postSaga, PostSagaGenericParams } from "../base/request/post";
import { BodyType } from "../base/request/request";
import { SagaActionParams } from "../base/types/saga";
import { EmailTemplateDto } from "../emailTemplates/type";
import { getListSaga } from "../base/request/get";

export const getTemplatesForEmailSender = createAction(
  "GET_TEMPLATES_FOR_EMAIL_SENDER",
  ({ payload }: SagaActionParams<EmailTemplateDto[]>) => ({
    payload,
    url: "/api/emails/templates",
  })
);
export const sendEmailSaga = createAction(
  "SEND_EMAIL_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/emails/send-email",
    body,
    bodyType: BodyType.raw,
  })
);
export const sendEmailAllCustomerSaga = createAction(
  "SEND_EMAIL_ALL_CUSTOMER_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/emails/send-email-all-customer",
    body,
    bodyType: BodyType.raw,
  })
);
export const sendEmailAllActiveCustomerSaga = createAction(
  "SEND_EMAIL_ALL_ACTIVE_CUSTOMER_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/emails/send-email-all-active-customer",
    body,
    bodyType: BodyType.raw,
  })
);
const emailsSaga = [
  takeLatest(sendEmailSaga.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),
  takeLatest(
    sendEmailAllCustomerSaga.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    sendEmailAllActiveCustomerSaga.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    getTemplatesForEmailSender.toString(),
    (payload: PostSagaGenericParams<any>) => getListSaga<any>(payload)
  ),
];

export default emailsSaga;
