import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerFavoriteDto } from "./type";

export const getCustomerFavoriteListByCustomerId = createAction(
  "GET_CUSTOMER_FAVORITE_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerFavoriteDto[]>) => ({
    payload,
    url:
      "/api/customerfavorites/getbycustomerid/" + id + (params ? params : ""),
  })
);
export const getCustomerFavoriteDetails = createAction(
  "GET_CUSTOMER_FAVORITE_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerFavoriteDto>) => ({
    payload,
    url: "/api/customerfavorites/" + id,
  })
);

const customerfavoritessaga = [
  takeLatest(
    getCustomerFavoriteListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerFavoriteDto[]>) =>
      getListSaga<CustomerFavoriteDto[]>(payload)
  ),
  takeLatest(
    getCustomerFavoriteDetails.toString(),
    (payload: SagaGenericParams<CustomerFavoriteDto>) =>
      getListSaga<CustomerFavoriteDto>(payload)
  ),
];

export default customerfavoritessaga;
