import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import DrageAndDropTable from "src/components/DraggableTable";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import {
  changePackageGiftOrder,
  deletePackageGift,
  getPackageGiftDetails,
  getPackageGiftList,
} from "src/store/packagegift/saga";
import { PackageGiftDto } from "src/store/packagegift/type";
import { PackageDetailDto } from "src/store/packages/type";
import CreateGiftModal from "./creategiftmodal";
import EditGiftModal from "./editgiftmodal";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";

const PackageGiftTab = ({
  packageData,
  setData,
}: {
  setData: (pack: PackageDetailDto) => void;
  packageData: PackageDetailDto;
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { tab }: { tab: string } = useParams();
  const [showDelete, setShowDelete] = useState<PackageGiftDto>();
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PackageGiftDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [gifts, setGifts] = useState<PackageGiftDto[]>([]);
  useEffect(() => {
    tab === "3" &&
      dispatch(
        getPackageGiftList({
          payload: {
            onSuccess: (msg, payload) => setGifts(payload),
            onError: () => {},
          },
          id: packageData.Id,
        })
      );
  }, [dispatch, packageData.Id, tab]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPackageGiftList({
        payload: {
          onSuccess: (msg, payload) => setGifts(payload),
          onError: () => {},
        },
        id: packageData.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Package Name"),
      dataField: "PackageName",
    },

    {
      text: t("Count"),
      dataField: "Count",
    },
    {
      text: t("Aylık İptal Sonrası Kullanılabilirliği"),
      dataField: "CanBeUseAfterMonthlyCancel",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Yıllık İptal Sonrası Kullanılabilirliği"),
      dataField: "CanBeUseAfterYearlyCancel",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Ödemeye Dahil"),
      dataField: "TakePayment",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="edit"
              onClick={() => {
                dispatch(
                  getPackageGiftDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditGiftModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreateGiftModal
          selectedPackage={packageData}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        <DrageAndDropTable
          columns={columns}
          addTitle={t("New Gift")}
          create={{
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          }}
          data={gifts}
          dragAction={changePackageGiftOrder}
          onSuccessClick={onSuccessClick}
        />
      </div>
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete package gift privilege`, {
            name: `${showDelete.PackageName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePackageGift({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PackageGiftTab;
