import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import * as Yup from "yup";
import { updateCorporateContracts } from "src/store/corporate-contracts/saga";
import { CorporateContractDto } from "src/store/corporate-contracts/type";

const EditCorporateDepartment = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: CorporateContractDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Sözleşme Ekle",
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          InvoiceDay: data.InvoiceDay,
          PaymentDay: data.PaymentDay,
          StartDate: data.StartDate,
          EndDate: data.EndDate,
          SignedPdfFile: "",
          CurrentSignedPdfFile: data.SignedPdf,
        }}
        validationSchema={Yup.object().shape({
          InvoiceDay: Yup.number()
            .min(1, t("Required"))
            .typeError(t("Required"))
            .required(t("Required")),
          PaymentDay: Yup.number()
            .min(1, t("Required"))
            .typeError(t("Required"))
            .required(t("Required")),
          StartDate: Yup.string().required(t("Required")),
          EndDate: Yup.string().required(t("Required")),
        })}
        onSubmit={values => {
          dispatch(
            updateCorporateContracts({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        inputs={[
          {
            label: "Fatura Günü",
            field: "InvoiceDay",
          },
          {
            label: "Ödeme Günü",
            field: "PaymentDay",
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "EndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
          {
            field: "SignedPdfFile",
            label: t("Sözleşme Pdf"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentSignedPdfFile",
              accept: ["application/pdf"],
              multiple: false,
              title: t("Sözleşme Pdf'i Yükle"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditCorporateDepartment;
