import DataGrid, {
  Column,
  Editing,
  Lookup,
  Paging,
  RequiredRule,
} from "devextreme-react/data-grid";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createCorporateContracts } from "src/store/corporate-contracts/saga";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const CreateCorporateContract = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dataGridRef = useRef<DataGrid>();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const [packages, setPackages] = useState<PackageDto[]>([]);
  useEffect(() => {
    open &&
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (msg, payload) => setPackages(payload),
            onError: () => {},
          },
          id: id,
        })
      );
  }, [dispatch, open, id]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Sözleşme Ekle",
        }}
        onHide={onHide}
        loader
        initialValues={{
          CorporateClientId: id,
          InvoiceDay: "",
          PaymentDay: "",
          StartDate: "",
          EndDate: "",
          SignedPdfFile: "",
          CorporateContractPackageDeals: [],
        }}
        validationSchema={Yup.object().shape({
          InvoiceDay: Yup.number()
            .min(1, t("Required"))
            .typeError(t("Required"))
            .required(t("Required")),
          PaymentDay: Yup.number()
            .min(1, t("Required"))
            .typeError(t("Required"))
            .required(t("Required")),
          StartDate: Yup.string().required(t("Required")),
          EndDate: Yup.string().required(t("Required")),
        })}
        onSubmit={values => {
          dispatch(
            createCorporateContracts({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: {
                ...values,
                CorporateContractPackageDeals:
                  dataGridRef.current?.instance.getDataSource().items() ?? [],
              },
            })
          );
        }}
        inputs={[
          {
            label: "Fatura Günü",
            field: "InvoiceDay",
          },
          {
            label: "Ödeme Günü",
            field: "PaymentDay",
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "EndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
          {
            field: "CorporateContractPackageDeals",
            label: t(""),
            inputType: InputType.component,
            component(data, setFieldValue) {
              return (
                <CreateGrid
                  ref={dataGridRef}
                  packages={packages}
                  data={data.CorporateContractPackageDeals}
                />
              );
            },
          },
          {
            field: "SignedPdfFile",
            label: t("Sözleşme Pdf"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["application/pdf"],
              multiple: false,
              title: t("Sözleşme Pdf'i Yükle"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCorporateContract;

const CreateGrid = forwardRef<any, any>(({ packages, data }, ref) => {
  return (
    <>
      <div className="pb-1">Sözleşme Detayları</div>
      <DataGrid
        dataSource={data ?? []}
        keyExpr="ID"
        showBorders={true}
        ref={ref}
      >
        <Paging enabled={false} />
        <Editing
          mode="form"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
        />
        <Column dataField="PackageId" caption="Paket">
          <Lookup
            dataSource={packages}
            valueExpr="Id"
            displayExpr="PackageName"
          />
          <RequiredRule />
        </Column>
        <Column dataField="Yearly" caption="Yıllık" dataType="boolean"></Column>
        <Column dataField="AccountCount" caption="Adet">
          <RequiredRule />
        </Column>
        <Column
          dataField="IsPerAccountBill"
          caption="H.B. Faturalama"
          dataType="boolean"
        ></Column>
        <Column dataField="BillAmount" caption="Tutar" dataType="number">
          <RequiredRule />
        </Column>
      </DataGrid>
    </>
  );
});
