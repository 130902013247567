import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { CustomerRatingDto } from "src/store/reports/type";
import CustomerRatingDetail from "./detail";

const CustomerRatingPage = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<CustomerRatingDto>();
  const columns = [
    {
      caption: t("Ad"),
      dataField: "CustomerName",
    },
    {
      caption: t("Soyad"),
      dataField: "CustomerSurname",
    },
    {
      caption: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },
    {
      caption: t("Hizmet"),
      dataField: "PrivilegeCoupon.PrivilegeName",
    },
    {
      caption: t("Plaka"),
      dataField: "PrivilegeCoupon.Plate",
    },
    {
      caption: t("Kod"),
      dataField: "PrivilegeCoupon.QRCode",
    },
    {
      caption: t("Kupon Kullanma Tarihi"),
      dataField: "PrivilegeCoupon.ValidatedDate",
      dataType: "datetime",
    },
    {
      caption: t("Değerlendirme Notu"),
      dataField: "Message",
    },
    {
      caption: t("Point"),
      dataField: "Point",
    },
    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "datetime",
    },

    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Üye",
          icon: "user",
          onClick: async (e: any) => {
            window.open(
              "/customer/details/1/" + e.row.data.CustomerId,
              "_blank"
            );
          },
        },
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            setOpen(e.row.data);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/customerratings/devexp",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Hizmet Puanlamaları")}
      />
      {open && (
        <CustomerRatingDetail
          onHide={() => setOpen(undefined)}
          open={open ? true : false}
          selected={open}
        />
      )}
    </React.Fragment>
  );
};

export default CustomerRatingPage;
