import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeTermDto } from "./type";
import { BodyType } from "../base/request/request";

export const getPrivilegeTermListByPrivilegeId = createAction(
  "GET_PRIVILEGETERMS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeTermDto[]>) => ({
    payload,
    url:
      "/api/privilegetermsofservices/getbyprivilegeid/" +
      id +
      (params ? params : ""),
  })
);

export const getPrivilegeTermList = createAction(
  "GET_PRIVILEGETERMS_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeTermDto[]>) => ({
    payload,
    url: "/api/privilegetermsofservices" + (params ? params : ""),
  })
);

export const getPrivilegeTermDetails = createAction(
  "GET_PRIVILEGETERMS_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeTermDto>) => ({
    payload,
    url: "/api/privilegetermsofservices/" + id,
  })
);
export const createPrivilegeTerm = createAction(
  "CREATE_PRIVILEGETERMS_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeTermDto>) => ({
    payload,
    url: "/api/privilegetermsofservices",
    body,
  })
);
export const updatePrivilegeTerm = createAction(
  "UPDATE_PRIVILEGETERMS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeTermDto>) => ({
    payload,
    url: "/api/privilegetermsofservices/" + id,
    body,
  })
);
export const deletePrivilegeTerm = createAction(
  "DELETE_PRIVILEGETERMS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeTermDto>) => ({
    payload,
    url: "/api/privilegetermsofservices/" + id,
    body,
  })
);
export const changePrivilegeTermOrder = createAction(
  "UPDATE_PRIVILEGE_TERMS_ORDER",
  ({ payload, body }: SagaActionParams<PrivilegeTermDto>) => ({
    payload,
    url: "/api/privilegetermsofservices/order",

    bodyType: BodyType.raw,
    body,
  })
);
const privilegetermsSaga = [
  takeLatest(
    getPrivilegeTermListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeTermDto[]>) =>
      getListSaga<PrivilegeTermDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeTermList.toString(),
    (payload: SagaGenericParams<PrivilegeTermDto[]>) =>
      getListSaga<PrivilegeTermDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeTermDetails.toString(),
    (payload: SagaGenericParams<PrivilegeTermDto>) =>
      getListSaga<PrivilegeTermDto>(payload)
  ),
  takeLatest(
    createPrivilegeTerm.toString(),
    (payload: PostSagaGenericParams<PrivilegeTermDto>) =>
      postSaga<PrivilegeTermDto>(payload)
  ),
  takeLatest(
    updatePrivilegeTerm.toString(),
    (payload: PostSagaGenericParams<PrivilegeTermDto>) =>
      putSaga<PrivilegeTermDto>(payload)
  ),
  takeLatest(
    changePrivilegeTermOrder.toString(),
    (payload: PostSagaGenericParams<PrivilegeTermDto>) =>
      putSaga<PrivilegeTermDto>(payload)
  ),
  takeLatest(
    deletePrivilegeTerm.toString(),
    (payload: SagaGenericParams<PrivilegeTermDto>) =>
      deleteSaga<PrivilegeTermDto>(payload)
  ),
];

export default privilegetermsSaga;
