import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useDispatch } from "react-redux";
import CancelAlert from "src/components/CancelAlert";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { deleteCustomerCallLogs } from "src/store/customer-calllog/saga";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";
import CreateCustomerCalllogModal from "./create/createModal";
import CreateInvalidCustomerCalllogModal from "./create/createInvalid";

const CustomerCalllogPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState<CustomerCallLogsDto>();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const history = useHistory();
  const columns = [
    {
      caption: t("Code"),
      dataField: "Code",
    },
    {
      caption: t("Önem Derecesi"),
      dataField: "CustomerCallLogLevelName",
    },
    {
      caption: t("Tip"),
      dataField: "CustomerCallLogTypeName",
    },
    {
      caption: t("Konu"),
      dataField: "CustomerCallLogParentTopicName",
    },
    {
      caption: t("Alt Konu"),
      dataField: "CustomerCallLogSubTopicName",
    },
    {
      caption: t("Plate"),
      dataField: "VehiclePlate",
    },
    {
      caption: t("Ad Soyad"),
      dataField: "NameSurname",
    },

    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      caption: t("Email"),
      dataField: "CustomerEmail",
    },
    {
      caption: t("Üyelik Durumu"),
      dataField: "CustomerStatus",
    },
    {
      caption: t("Paket"),
      dataField: "PackageName",
    },

    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
    {
      caption: t("Not"),
      dataField: "Not",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer-calllogs/" + e.row.key);
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/customercalllogs/devexp",
          columns: columns,
          create: () => setShowCreate(true),
          createText: "Çağrı Ekle",
          gridButtons: [
            {
              buttonText: "Geçersiz Çağrı",
              type: "default",
              onclick: () => setShow(true),
              icon: "",
            },
          ],
        }}
        useDevGrid={true}
        title={t("Üye Arama Kayıtları")}
      />
      <CreateInvalidCustomerCalllogModal
        onHide={() => setShow(false)}
        open={show}
      />
      <CreateCustomerCalllogModal
        onHide={() => setShowCreate(false)}
        open={showCreate}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Silmek istediğine emin misiniz?`)}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCustomerCallLogs({
                payload: {
                  onSuccess: message => toast.success(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CustomerCalllogPage;
