import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import DataTableWithPagination from "src/components/PageContent/DataGrid";
import {
  deletePackageDiscountDefinition,
  getPackageDiscountDefinitionDetails,
  getPackageDiscountDefinitionListByPackageId,
} from "src/store/packagediscountdefinitions/saga";
import { PackageDiscountDefinitionDto } from "src/store/packagediscountdefinitions/type";
import CreatePackageDiscountDefinitionModal from "./createDiscount";
import EditPackageDiscountDefinitionModal from "./editDiscount";
import { PackageDetailDto } from "src/store/packages/type";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";

const PackageDiscountDefinitionTab = ({
  packageData,
}: {
  packageData: PackageDetailDto;
}) => {
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tab, id }: { tab: string; id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<PackageDiscountDefinitionDto>();
  const [selected, setSelected] = useState<PackageDiscountDefinitionDto>();
  const [discounts, setDiscounts] = useState<PackageDiscountDefinitionDto[]>(
    []
  );

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPackageDiscountDefinitionListByPackageId({
        payload: {
          onSuccess: (msg, payload) => setDiscounts(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  };
  const columns = [
    {
      text: t("Module"),
      dataField: "DiscountModuleName",
    },
    {
      text: t("Validity Period"),
      dataField: "DiscountValidityPeriodName",
    },
    {
      text: t("Type"),
      dataField: "DiscountTypeName",
    },
    {
      text: t("Discount Value"),
      dataField: "DiscounValue",
    },
    {
      text: t("Quantity"),
      dataField: "Quantity",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="edit"
              onClick={() => {
                dispatch(
                  getPackageDiscountDefinitionDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditPackageDiscountDefinitionModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreatePackageDiscountDefinitionModal
          id={id}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        {tab === "5" && (
          <DataTableWithPagination
            title=" "
            HideBreadcrumb={true}
            listData={discounts}
            addTitle={t("New Discount")}
            columns={columns}
            create={{
              createType: "drawer",
              onCreateClick: () => setShowCreate(true),
            }}
            setDataList={setDiscounts}
            action={getPackageDiscountDefinitionListByPackageId}
            paramsid={packageData.Id}
          />
        )}
      </div>
      {showDelete && (
        <CancelAlert
          message={t(
            `Are you sure you want to delete package discount definition`,
            {
              name: `${packageData.PackageName} `,
            }
          )}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePackageDiscountDefinition({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PackageDiscountDefinitionTab;
