import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { createCustomer } from "src/store/customers/saga";
import * as Yup from "yup";

const CreateCustomer = ({
  onHide,
  open,
}: {
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Yeni Müşteri"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PhoneNumber: "",
          Surname: "",
          Name: "",
          Email: "",
          CampaignSubscribe: false,
        }}
        onSubmit={values => {
          dispatch(
            createCustomer({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  toast.success(message);
                  history.push("/customer/details/1/" + payload.Id);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PhoneNumber: Yup.mixed().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          Name: Yup.string().required(t("Required")),
          Email: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Surname",
            label: t("Surname"),
            col: 6,
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            field: "Email",
            label: t("Email"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCustomer;
