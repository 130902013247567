import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";

const InvoiceMembershipCancellationPage = () => {
  const { t } = useTranslation();

  const gridOptions: any = {
    url: "/api/invoices/invoiced-membership-cancellation-payment/devexp",
    columns: [
      {
        caption: t("Fatura No"),
        dataField: "InvoiceNumber",
      },
      {
        caption: t("Identity Number"),
        dataField: "CustomerIdentityNumber",
      },
      {
        caption: t("Name"),
        dataField: "CustomerName",
      },
      {
        caption: t("Surname"),
        dataField: "CustomerSurname",
      },
      {
        caption: t("PhoneNumber"),
        dataField: "CustomerPhoneNumber",
      },

      {
        caption: t("Ödeme Tarihi"),
        dataField: "Date",
      },
      {
        caption: t("Price"),
        dataField: "TotalPrice",
      },
    ],
  };
  return (
    <React.Fragment>
      <MainPage
        devProps={{ ...gridOptions }}
        useDevGrid={true}
        title="Faturalanmamış Üye İptal Ödemeleri"
      />
    </React.Fragment>
  );
};

export default InvoiceMembershipCancellationPage;
