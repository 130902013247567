import ScrollBar from "react-perfect-scrollbar";
import { Drawer } from "rsuite";
import { CustomModalProps } from "../Form/type";

const CustomModal = ({
  open,
  onHide,
  title,
  outsideClick = false,
  children,
  ...rest
}: CustomModalProps) => {
  return (
    <Drawer
      {...rest}
      onClose={() => {
        outsideClick && onHide();
      }}
      open={open}
    >
      {title && (
        <Drawer.Header
          onClose={onHide}
          style={{ padding: "26px 40px 20px 60px" }}
        >
          {title}{" "}
        </Drawer.Header>
      )}
      <Drawer.Body style={{ padding: "30px 50px" }}>
        <ScrollBar>{children}</ScrollBar>
      </Drawer.Body>
    </Drawer>
  );
};
export default CustomModal;
