import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import FileUploader from "src/components/file-upload";
import { getCustomerCallLogsTicketUsers } from "src/store/customer-calllog/saga";
import { getCustomerLookupList } from "src/store/customers/saga";
import { CustomerLookupDto } from "src/store/customers/type";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import { CustomerVehicleDto } from "src/store/customervehicles/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import { getTicketSubjectList } from "src/store/ticket-subject/saga";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import { createTicket, getTicketTypes } from "src/store/ticket/saga";
import { TicketDetailDto, TicketType } from "src/store/ticket/type";
import { UserDto } from "src/store/user/type";

const OpenTicketModal = ({
  show,
  onHide,
  onSuccessSubmit,
}: {
  show: boolean;
  onHide: () => void;
  onSuccessSubmit?: (ticket: TicketDetailDto) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState<CustomerLookupDto[]>([]);
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const [subjects, setSubjects] = useState<TicketSubjectDto[]>([]);
  const [searchSubjects, setSearchSubjects] = useState<TicketSubjectDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);

  const [users, setUsers] = useState<UserDto[]>([]);
  useEffect(() => {
    if (show) {
      dispatch(
        getCustomerCallLogsTicketUsers({
          payload: {
            onSuccess: (msg, py) => {
              setUsers(py);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerLookupList({
          payload: {
            onSuccess: (msg, py) => setCustomers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketSubjectList({
          payload: {
            onSuccess: (msg, py) => {
              setSubjects(py);
              setSearchSubjects(py);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (msg, py) => setSuppliers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketTypes({
          payload: {
            onSuccess: (msg, py) => setTicketTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, show]);
  return (
    <CustomModal onHide={onHide} open={show} title={"Talep Aç"}>
      <Formik
        onSubmit={values => {
          dispatch(
            createTicket({
              payload: {
                onSuccess: (ms, payload) => {
                  toast.success("Talep başarıyla oluşturuldu");
                  onSuccessSubmit && onSuccessSubmit(payload);
                  onHide();
                },
                onError: () => {
                  toast.error(t("youmustbuy"));
                },
              },
              body: values,
            })
          );
        }}
        initialValues={{
          CustomerId: "",
          VehicleId: "",
          TicketTypeId: 1,
          SubjectId: "",
          SupplierId: "",
          Message: "",
          Documents: [],
          Images: [],
          ResponsibleUserId: "",
        }}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Row className="mx-3">
            <GetInput
              inputprop={{
                col: 12,
                field: "SupplierId",
                label: t("Supplier"),
                inputType: InputType.multiselect,
                lookup: {
                  data: suppliers,
                  labelKey: "Name",
                  valueKey: "Id",
                },
              }}
            />
            <GetInput
              inputprop={{
                col: 6,
                field: "CustomerId",
                label: t("Customer"),
                inputType: InputType.multiselect,
                lookup: {
                  data: customers,
                  labelKey: "Name",
                  valueKey: "Id",
                  renderValue(value, item: any, selectedElement) {
                    return item ? item.Name + " " + item.Surname : value;
                  },
                  renderMenuItem(label, item: any) {
                    return item ? item.Name + " " + item.Surname : label;
                  },
                },
                onChange(value, item, setFieldValue) {
                  dispatch(
                    getCustomerVehicleListByCustomerId({
                      payload: {
                        onSuccess: (msg, py) => setVehicles(py),
                        onError: () => {},
                      },
                      id: value,
                    })
                  );
                },
              }}
            />
            <GetInput
              inputprop={{
                col: 6,
                field: "VehicleId",
                label: t("Vehicle"),
                inputType: InputType.multiselect,
                lookup: {
                  data: vehicles,
                  labelKey: "Plate",
                  valueKey: "Id",
                },
              }}
            />
            <GetInput
              inputprop={{
                col: 6,
                field: "TicketTypeId",
                label: t("Ticket Type"),
                inputType: InputType.multiselect,
                lookup: {
                  data: ticketTypes,
                  labelKey: "Name",
                  valueKey: "Id",
                },
                onChange(value, item, setFieldValue, values) {
                  value &&
                    setSubjects(
                      searchSubjects.filter(x => x.TicketTypeId === value)
                    );
                },
              }}
            />
            <GetInput
              inputprop={{
                col: 6,
                field: "SubjectId",
                label: t("Ticket Subject"),
                inputType: InputType.multiselect,
                lookup: {
                  data: subjects,
                  labelKey: "Subject",
                  valueKey: "Id",
                },
              }}
            />
            <GetInput
              inputprop={{
                col: 12,
                field: "ResponsibleUserId",
                inputType: InputType.multiselect,
                label: t("Sorumlu Kişi"),
                lookup: {
                  data: users,
                  labelKey: "FullName",
                  valueKey: "Id",
                  placeholder: t("Sorumlu Kişi Seçiniz"),
                },
              }}
            />
            <GetInput
              inputprop={{
                field: "Message",
                label: t("Message"),
                inputType: InputType.multilinetext,
              }}
            />

            <Col md={12}>
              <FileUploader
                className=" btn-light ms-1 w-100"
                onChange={files => {
                  setFieldValue("Documents", files);
                }}
                title={t("Tickets.Documents")}
              />
              {values.Documents?.map((x: any) => (
                <div className="font-size-12 badge bg-light">
                  <span
                    className="fw-bold me-2"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setFieldValue(
                        "Documents",
                        values.Documents.filter((y: any) => y.name !== x.name)
                      )
                    }
                  >
                    X
                  </span>
                  {x.name}
                </div>
              ))}
              <FileUploader
                className=" btn-light ms-1 mt-2 w-100"
                onChange={files => {
                  setFieldValue("Images", files);
                }}
                title={t("Tickets.Images")}
              />
              {values.Images?.map((x: any) => (
                <div className="font-size-12 badge bg-light">
                  <span
                    className="fw-bold me-2"
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setFieldValue(
                        "Images",
                        values.Images.filter((y: any) => y.name !== x.name)
                      )
                    }
                  >
                    X
                  </span>
                  {x.name}
                </div>
              ))}
            </Col>
            <div className={"d-flex mt-2  justify-content-between "}>
              <Button
                color="danger"
                type="submit"
                style={{
                  width: 150,
                }}
                onClick={() => {
                  return onHide();
                }}
              >
                {t("Close")}
              </Button>
              <Button
                color="success"
                type="submit"
                style={{
                  width: 150,
                }}
                onClick={() => handleSubmit()}
              >
                {t("Send")}
              </Button>
            </div>
          </Row>
        )}
      </Formik>
    </CustomModal>
  );
};
export default OpenTicketModal;
