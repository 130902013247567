import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import DataTableWithPagination from "src/components/PageContent/DataGrid";
import { SupplierDto } from "src/store/supplier/type";
import {
  deleteSupplierLocation,
  getSupplierLocationDetails,
  getSupplierLocationListBySupplierId,
} from "src/store/supplierlocations/saga";
import { SupplierLocationDto } from "src/store/supplierlocations/type";
import CreatePrivilegeModal from "./createLocation";
import LocationDocumentModal from "./documents/DocumentsModal";
import EditPrivilegeModal from "./editLocation";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";

const SupplierLocationTab = ({ supplier }: { supplier: SupplierDto }) => {
  const dispatch = useDispatch();
  const { tab }: { tab: string } = useParams();
  const [showDelete, setShowDelete] = useState<SupplierLocationDto>();
  const { t } = useTranslation();
  const [active, setActive] = useState<"create" | "edit" | "list">("list");
  const [selected, setSelected] = useState<SupplierLocationDto>();
  const [locations, setLocations] = useState<SupplierLocationDto[]>([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const user = useSelector(userSelector);
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getSupplierLocationListBySupplierId({
        payload: {
          onSuccess: (msg, payload) => setLocations(payload),
          onError: () => {},
        },
        id: supplier.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Name"),
      dataField: "Name",
    },

    {
      text: t("City"),
      dataField: "CityName",
    },
    {
      text: t("Town"),
      dataField: "TownName",
    },
    {
      text: t("Email"),
      dataField: "Email",
    },

    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {CheckRole(user) && (
            <>
              <ColumnButton
                buttonType="customicon"
                custombutton={{
                  icon: "dripicons-document",
                }}
                onClick={() => {
                  setSelected(data);
                  return setShowDocuments(data);
                }}
              />
              <ColumnButton
                buttonType="edit"
                onClick={() => {
                  dispatch(
                    getSupplierLocationDetails({
                      payload: {
                        onSuccess: (message, payload) => {
                          setSelected(payload);
                          return setActive("edit");
                        },
                        onError: () => {},
                      },
                      id: cellContent,
                    })
                  );
                }}
              />
              <ColumnButton
                buttonType="delete"
                onClick={() => setShowDelete(data)}
              />
            </>
          )}
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <LocationDocumentModal
            setShow={setShowDocuments}
            location={selected}
            onHide={() => setShowDocuments(false)}
            show={showDocuments}
          />
        )}
        {tab === "2" && (
          <>
            {selected && active === "edit" && (
              <>
                <div className="w-100 d-flex justify-content-end gap-2">
                  <button
                    onClick={() => setShowDocuments(true)}
                    className="btn btn-info  text-light zIndex-2"
                  >
                    {t("Documents")}
                  </button>
                  <button
                    onClick={() => setActive("list")}
                    className="btn btn-danger  text-light zIndex-2"
                  >
                    {t("Close")}
                  </button>
                </div>
                <div className=" pt-4">
                  <EditPrivilegeModal
                    active={active}
                    location={selected}
                    onSuccessClick={(ms, result) => {
                      dispatch(
                        getSupplierLocationDetails({
                          payload: {
                            onSuccess: (message, payload) => {
                              setSelected(payload);
                              return setActive("edit");
                            },
                            onError: () => {},
                          },
                          id: result.Id,
                        })
                      );
                    }}
                  />
                </div>
              </>
            )}
            {active === "create" && (
              <>
                <div className="w-100 d-flex justify-content-end">
                  <button
                    onClick={() => setActive("list")}
                    className="btn btn-danger  text-light zIndex-2"
                  >
                    {t("Close")}
                  </button>
                </div>
                <div className=" pt-4">
                  <CreatePrivilegeModal
                    supplier={supplier}
                    active={active}
                    onSuccessClick={(message, result) => {
                      dispatch(
                        getSupplierLocationDetails({
                          payload: {
                            onSuccess: (message, payload) => {
                              setSelected(payload);
                              return setActive("edit");
                            },
                            onError: () => {},
                          },
                          id: result.Id,
                        })
                      );
                    }}
                  />
                </div>
              </>
            )}
            {active === "list" && (
              <DataTableWithPagination
                title=" "
                HideBreadcrumb={true}
                listData={locations}
                columns={columns}
                create={{
                  createType: "drawer",
                  onCreateClick: () => setActive("create"),
                }}
                setDataList={setLocations}
                action={getSupplierLocationListBySupplierId}
                paramsid={supplier.Id}
              />
            )}
          </>
        )}
      </div>
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete supplierlocations`, {
            name: `${showDelete.Name} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteSupplierLocation({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default SupplierLocationTab;
