import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getUsageTypes } from "src/store/lookup/saga";
import { UsageTypeDto } from "src/store/lookup/type";
import { createPackagePrivilege } from "src/store/packages/saga";
import { PackageDetailDto } from "src/store/packages/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegePriceListByPrivilegeId } from "src/store/privilegeprices/saga";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";

const CreatePrivilegeModal = ({
  onHide,
  selectedPackage,
  open,
  onSuccessClick,
}: {
  selectedPackage: PackageDetailDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [usagetypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getUsageTypes({
          payload: {
            onSuccess: (msg, py) => setUsageTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Add Privilege to Package"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          WebSite: false,
          PackageId: selectedPackage.Id,
          PrivilegePriceId: "",
          PackageName: "",
          UsageTypeId: "",
          Count: 0,
          WebsiteIconFile: "",
          // IconFile: "", // IconName: "",
          Active: false,
          Transferable: false,
          ShowInformation: false,
          IncludedPackage: false,
          InformationText: "",
          RelatedPrivilegeInfo: undefined,
          ShowRelatedPrivilegeInfo: true,
          CanBeUseAfterMonthlyCancel: false,
          CanBeUseAfterYearlyCancel: false,
          TakePayment: false,
        }}
        onSubmit={values => {
          dispatch(
            createPackagePrivilege({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick("Hizmet pakete başarıyla eklenmiştir");
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            field: "ShowRelatedPrivilegeInfo",
            label: t("Hizmet ile İlişkilendir"),
            col: 12,
            inputType: InputType.checkbox,
          },
          {
            hide(values) {
              return !values.ShowRelatedPrivilegeInfo;
            },
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
            onChange(value, item: PrivilegeDto, setFieldValue) {
              item && item.Title && setFieldValue("PackageName", item.Title);
              value &&
                dispatch(
                  getPrivilegePriceListByPrivilegeId({
                    payload: {
                      onSuccess: (msg, py) => setPrices(py),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            field: "PackageName",
            label: t("Mobilde Görünecek Ad"),
          },

          {
            hide(values) {
              return !values.ShowRelatedPrivilegeInfo;
            },
            field: "PrivilegePriceId",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
            onChange(value, item, setFieldValue) {
              item && setFieldValue("RelatedPrivilegeInfo", item);
              item &&
                item.UsageTypeId &&
                setFieldValue("UsageTypeId", item.UsageTypeId);
            },
          },
          {
            hide(values) {
              return !values.ShowRelatedPrivilegeInfo;
            },
            field: "UsageTypeId",
            inputType: InputType.multiselect,
            label: t("Usage Type"),
            lookup: {
              data: usagetypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Usage Type"),
            },
          },

          {
            hide(values) {
              return !values.ShowRelatedPrivilegeInfo;
            },
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },

          {
            hide(values) {
              return !values.ShowRelatedPrivilegeInfo;
            },
            field: "IncludedPackage",
            label: t("Includable Package"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "ShowInformation",
            col: 6,
            label: t("Show Information"),
            inputType: InputType.checkbox,
          },
          {
            field: "WebSite",
            label: t("Show On Web"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "TakePayment",
            label: t("Üyelik İptal Ödemesine Dahil"),
            inputType: InputType.checkbox,
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
          {
            field: "Transferable",
            col: 12,
            label: t("Devredilebilir mi"),
            inputType: InputType.checkbox,
          },
          {
            field: "InformationText",
            label: t("Information Text"),
            inputType: InputType.multilinetext,
          },
          {
            field: "CanBeUseAfterMonthlyCancel",
            label: t("Aylık İptal Sonrası Kullanılabilir mi?"),
            inputType: InputType.checkbox,
          },
          {
            field: "CanBeUseAfterYearlyCancel",
            label: t("Yıllık İptal Sonrası Kullanılabilir mi?"),
            inputType: InputType.checkbox,
          },
          // {
          //   field: "IconFile",
          //   label: t("İkon Dosyası"),
          //   inputType: InputType.fileUpload,
          //   fileUpload: {
          //     multiple: false,
          //     title: t("İkon Dosyası Yükleyiniz"),
          //   },
          // },
          {
            field: "WebsiteIconFile",
            label: t("Web İkon Dosyası"),
            inputType: InputType.fileUpload,
            fileUpload: {
              multiple: false,
              title: t("Web İkon Dosyası Yükleyiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeModal;
