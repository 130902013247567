import React from "react";
import Switch from "react-switch";

export interface ToggleProps {
  disabled?: boolean;
  handleDiameter?: number;
  height?: number;
  baseColor?: string;
  width?: number;
  borderRadius?: number;
  value: boolean;
  checkField?: string;
  uncheckField?: string;
  onChange: (value: boolean) => void;
}
const CustomToggle = ({
  value,
  onChange,
  disabled,
  checkField,
  baseColor,
  uncheckField,
  handleDiameter = 16,
  height = 23,
  width = 50,
  borderRadius = 6,
}: ToggleProps) => {
  return (
    <React.Fragment>
      <Switch
        disabled={disabled}
        checked={value}
        onChange={onChange}
        handleDiameter={handleDiameter ?? 16}
        offColor="#ced4da"
        onColor={baseColor ? baseColor : "#2ab57d"}
        offHandleColor="#e9e9ef"
        onHandleColor="#e9e9ef"
        height={height ?? 23}
        width={width ?? 46}
        borderRadius={borderRadius ?? 6}
        activeBoxShadow="0px 0px 1px 2px #e9e9ef"
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "#74788d",
              fontWeight: 400,
              fontSize: 10,
            }}
          >
            {uncheckField}
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              color: "white",
              fontWeight: 400,
              fontSize: 10,
            }}
          >
            {checkField}
          </div>
        }
        // uncheckedHandleIcon={
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       height: "100%",
        //       fontSize: 20,
        //     }}
        //   >
        //     ☹
        //   </div>
        // }
        // checkedHandleIcon={
        //   <div
        //     style={{
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //       height: "100%",
        //       color: "red",
        //       fontSize: 18,
        //     }}
        //   >
        //     ♥
        //   </div>
        // }
        className="react-switch"
        id="small-radius-switch"
      />
    </React.Fragment>
  );
};

export default CustomToggle;
