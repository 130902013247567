import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import CustomCheckbox from "src/components/Form/Input/CheckBox";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { cancelPackagePayment } from "src/store/customers/saga";
import { CustomerPurchasedPackageDto } from "src/store/customers/type";

const PurchasedPackages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string; tab: string } = useParams();
  const [showCancel, setShowCancel] = useState<CustomerPurchasedPackageDto>();
  const [useMoka, setUseMoka] = useState(false);
  const [churnedPrivilege, setChurnedPrivilege] = useState(false);
  const columns = [
    {
      caption: t("Paket Adı"),
      dataField: "PackageName",
    },
    {
      caption: t("Start Date"),
      dataField: "StartDate",
      dataType: "datetime",
    },
    {
      caption: t("End Date"),
      dataField: "EndDate",
      dataType: "datetime",
    },
    {
      dataField: "Price",
      caption: "Tutar",
      dataType: "number",
    },
    {
      dataField: "UnitPrice",
      caption: "Birim Fiyat",
      dataType: "number",
    },
    {
      dataField: "TaxPrice",
      caption: "Vergi",
      dataType: "number",
    },
    {
      caption: t("İade"),
      dataField: "IsRefund",
      dataType: "boolean",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "İade",
          text: "İade",
          cssClass: "text-dark bg-soft-danger columnUseButton",
          visible: (e: any) =>
            e.row.data.Price &&
            !e.row.data.IsRefund &&
            e.row.data.CustomerPurchaseTransactionId &&
            e.row.rowIndex === 0,
          onClick: async (e: any) => {
            console.log(e);
            setShowCancel(e.row.data);
          },
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <CustomDataGrid
          className="detailGrid"
          url={"/api/customers/purchased-packages/" + id}
          columns={columns}
        />
      </Row>
      {showCancel && (
        <CancelAlert
          children={
            <>
              <CustomCheckbox
                field={"UseMoka"}
                label={"Moka'ya bildir"}
                value={useMoka}
                onChange={e => {
                  return setUseMoka(e);
                }}
              />
              <CustomCheckbox
                field={"ChurnPrivilege"}
                label={"Haklarını da sil"}
                value={churnedPrivilege}
                onChange={e => {
                  return setChurnedPrivilege(e);
                }}
              />
            </>
          }
          message={`Paket tutarını iade etmek istediğinize emin misiniz?`}
          onConfirm={() => {
            dispatch(
              cancelPackagePayment({
                payload: {
                  onSuccess: message => {
                    setShowCancel(undefined);
                    toast.success(message);
                  },
                  onError: message => {
                    setShowCancel(undefined);
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  PurchasedId: showCancel.Id,
                  UseMoka: useMoka,
                  ChurnPrivilege: churnedPrivilege,
                },
              })
            );
          }}
          onCancel={() => {
            setShowCancel(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PurchasedPackages;
