import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { BlackListDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";
import { BodyType } from "../base/request/request";

export const getBlackListList = createAction(
  "GET_BLACK_LIST_LIST",
  ({ payload, params }: SagaActionParams<BlackListDto[]>) => ({
    payload,
    url: "/api/blacklists" + (params ? params : ""),
  })
);
export const getBlackListDetails = createAction(
  "GET_BLACK_LIST_DETAILS",
  ({ payload, id }: SagaActionParams<BlackListDto>) => ({
    payload,
    url: "/api/blacklists/" + id,
  })
);
export const createBlackList = createAction(
  "CREATE_BLACK_LIST_DETAILS",
  ({ payload, body }: SagaActionParams<BlackListDto>) => ({
    payload,
    url: "/api/blacklists",
    body,
  })
);
export const updateBlackList = createAction(
  "UPDATE_BLACK_LIST_DETAILS",
  ({ payload, body, id }: SagaActionParams<BlackListDto>) => ({
    payload,
    url: "/api/blacklists/" + id,
    body,
  })
);
export const deleteBlackList = createAction(
  "DELETE_BLACK_LIST_DETAILS",
  ({ payload, body, id }: SagaActionParams<BlackListDto>) => ({
    payload,
    url: "/api/blacklists/" + id,
    body,
  })
);
export const addCustomerToBlackList = createAction(
  "ADD_CUSTOMER_TO_BLACK_LIST",
  ({ payload, body }: SagaActionParams<BlackListDto>) => ({
    payload,
    body,
    url: "/api/blacklists/customer",
    bodyType: BodyType.raw,
  })
);
export const checkCustomerBlackList = createAction(
  "CHECK_CUSTOMER_BLACK_LIST",
  ({ payload, id }: SagaActionParams<boolean>) => ({
    payload,
    url: "/api/blacklists/check-customer/" + id,
  })
);

export const payCustomerPackagePayment = createAction(
  "PAY_CUSTOMER_PACKAGE_PAYMENT",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/pay-package",
    bodyType: BodyType.raw,
  })
);
const blacklistsSaga = [
  takeLatest(
    getBlackListList.toString(),
    (payload: SagaGenericParams<BlackListDto[]>) =>
      getListSaga<BlackListDto[]>(payload)
  ),
  takeLatest(
    checkCustomerBlackList.toString(),
    (payload: SagaGenericParams<BlackListDto[]>) =>
      getListSaga<BlackListDto[]>(payload)
  ),
  takeLatest(
    getBlackListDetails.toString(),
    (payload: SagaGenericParams<BlackListDto>) =>
      getListSaga<BlackListDto>(payload)
  ),
  takeLatest(
    createBlackList.toString(),
    (payload: PostSagaGenericParams<BlackListDto>) =>
      postSaga<BlackListDto>(payload)
  ),

  takeLatest(
    updateBlackList.toString(),
    (payload: PostSagaGenericParams<BlackListDto>) =>
      putSaga<BlackListDto>(payload)
  ),
  takeLatest(
    deleteBlackList.toString(),
    (payload: SagaGenericParams<BlackListDto>) =>
      deleteSaga<BlackListDto>(payload)
  ),
  takeLatest(
    addCustomerToBlackList.toString(),
    (payload: PostSagaGenericParams<BlackListDto>) =>
      postSaga<BlackListDto>(payload)
  ),
  takeLatest(
    payCustomerPackagePayment.toString(),
    (payload: PostSagaGenericParams<BlackListDto>) =>
      postSaga<BlackListDto>(payload)
  ),
];

export default blacklistsSaga;
