import MetaTags from "react-meta-tags";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Container } from "reactstrap";
import React from "react";
import DrageAndDropTable from ".";
import { DragDropPageProps } from "./type";

export enum GridButtonType {
  "edit",
  "default",
}

const PageDrageAndDropTable = ({
  data,
  columns,
  onSuccessClick,
  dragAction,
  create,
  HideBreadcrumb = false,
  title,
  addTitle,
  headerColored = true,
}: DragDropPageProps) => {
  return (
    <>
      <React.Fragment>
        <div className={"page-content"}>
          <MetaTags>
            <title>{title}</title>
          </MetaTags>
          <Container fluid>
            <DrageAndDropTable
              headerColored={headerColored}
              columns={columns}
              title={title}
              addTitle={addTitle}
              create={create}
              HideBreadcrumb={HideBreadcrumb}
              data={data}
              dragAction={dragAction}
              onSuccessClick={onSuccessClick}
            />
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};
export default PageDrageAndDropTable;
