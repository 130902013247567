import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updatePaymentInstallments } from "src/store/paymentInstallment/saga";
import { PaymentInstallmentDto } from "src/store/paymentInstallment/type";
import * as Yup from "yup";

const EditPaymentInstallment = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PaymentInstallmentDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Taksit Sayısı Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updatePaymentInstallments({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Number: data.Number,

          InterestRate: data.InterestRate,
          Id: data.Id,
        }}
        validationSchema={Yup.object().shape({
          Number: Yup.number().typeError("*").required(t("Required")),
          InterestRate: Yup.number().typeError("*").required(t("Required")),
        })}
        inputs={[
          {
            field: "Number",
            label: t("Taksit Sayısı"),
            inputType: InputType.number,
          },
          {
            field: "InterestRate",
            label: t("Vade Farkı"),
            inputType: InputType.number,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPaymentInstallment;
