import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDashboardDailyReports } from "src/store/dashboard/saga";
import { Card, CardBody, Table } from "reactstrap";
const DashboardDailyReports = () => {
  const [data, setData] = useState<any[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardDailyReports({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <React.Fragment>
      <Card className="">
        <CardBody>
          <div className=" pb-2 d-flex justify-content-between align-items-center">
            <div className="card-title">Gün Bazlı İstatistik</div>
          </div>
          {data.length === 0 && (
            <div className=" py-5 text-center text-muted">VERİ BULUNAMADI</div>
          )}
          {data.length > 0 && data && (
            <Table className="table mb-0 table-bordered ">
              <thead className="custom-table-header-colored table-light">
                <tr>
                  {Object.keys(data[0])
                    .sort(function (a: any, b: any) {
                      return a === "Konu" ? -1 : 1;
                    })
                    .map((x, i) => {
                      return <th key={x + i}>{x}</th>;
                    })}
                  <th className="fw-bold">Toplam</th>
                </tr>
              </thead>
              <tbody>
                {data.map(y => {
                  let total = 0;
                  return (
                    <tr>
                      {Object.keys(data[0])
                        .sort(function (a: any, b: any) {
                          return a === "Konu" ? -1 : 1;
                        })
                        .map((x, i) => {
                          if (x !== "Konu") {
                            total += y[x];
                          }
                          return <td key={x + i}>{y[x]}</td>;
                        })}
                      <td className="fw-bold">{total}</td>
                    </tr>
                  );
                })}

                {/* {Array.from(new Set(data.map(item => item.Topic))).map(
                (x: any) => (
                  <tr>
                    <th scope="row">{x}</th>
                    {Array.from(new Set(data.map(item => item.Date))).map(
                      (z: any) => {
                        const value = data.find(
                          y => y.Topic === x && y.Date === z
                        );
                        return <td> {value ? value.Count : "-"}</td>;
                      }
                    )}
                  </tr>
                )
              )} */}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DashboardDailyReports;
