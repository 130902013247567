import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerGroupDto } from "./type";
import { BodyType } from "../base/request/request";

export const getCustomerGroupList = createAction(
  "GET_CUSTOMER_GROUP_LIST",
  ({ payload, params }: SagaActionParams<CustomerGroupDto[]>) => ({
    payload,
    url: "/api/customergroups" + (params ? params : ""),
  })
);
export const getCustomerGroupDetails = createAction(
  "GET_CUSTOMER_GROUP_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerGroupDto>) => ({
    payload,
    url: "/api/customergroups/" + id,
  })
);
export const createCustomerGroup = createAction(
  "CREATE_CUSTOMER_GROUP_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerGroupDto>) => ({
    payload,
    url: "/api/customergroups",
    body,
  })
);
export const updateCustomerGroup = createAction(
  "UPDATE_CUSTOMER_GROUP_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerGroupDto>) => ({
    payload,
    url: "/api/customergroups/" + id,
    body,
  })
);
export const deleteCustomerGroup = createAction(
  "DELETE_CUSTOMER_GROUP_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerGroupDto>) => ({
    payload,
    url: "/api/customergroups/" + id,
    body,
  })
);

export const changeCustomerGroup = createAction(
  "CHANGE_CUSTOMER_GROUP",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/update-group",
    bodyType: BodyType.raw,
  })
);
const customergroupsaga = [
  takeLatest(
    getCustomerGroupList.toString(),
    (payload: SagaGenericParams<CustomerGroupDto[]>) =>
      getListSaga<CustomerGroupDto[]>(payload)
  ),
  takeLatest(
    getCustomerGroupDetails.toString(),
    (payload: SagaGenericParams<CustomerGroupDto>) =>
      getListSaga<CustomerGroupDto>(payload)
  ),
  takeLatest(
    createCustomerGroup.toString(),
    (payload: PostSagaGenericParams<CustomerGroupDto>) =>
      postSaga<CustomerGroupDto>(payload)
  ),
  takeLatest(
    updateCustomerGroup.toString(),
    (payload: PostSagaGenericParams<CustomerGroupDto>) =>
      putSaga<CustomerGroupDto>(payload)
  ),
  takeLatest(
    changeCustomerGroup.toString(),
    (payload: PostSagaGenericParams<CustomerGroupDto>) =>
      putSaga<CustomerGroupDto>(payload)
  ),
  takeLatest(
    deleteCustomerGroup.toString(),
    (payload: SagaGenericParams<CustomerGroupDto>) =>
      deleteSaga<CustomerGroupDto>(payload)
  ),
];

export default customergroupsaga;
