import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InputType } from "src/components/Form/type";
import { useFormikContext } from "formik";
import GetInput from "src/components/Form/GetInput";
import { Col } from "reactstrap";
import { TicketType } from "src/store/ticket/type";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import { SupplierDto } from "src/store/supplier/type";
import { getTicketSubjectList } from "src/store/ticket-subject/saga";
import { getSupplierLookup } from "src/store/supplier/saga";
import { getTicketTypes } from "src/store/ticket/saga";
import { useTranslation } from "react-i18next";
import FileUploader from "src/components/file-upload";
import { UserDto } from "src/store/user/type";
import { getCustomerCallLogsTicketUsers } from "src/store/customer-calllog/saga";

const TicketCreateInputs = ({ open }: { open: boolean }) => {
  const dispatch = useDispatch();
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const [subjects, setSubjects] = useState<TicketSubjectDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);

  const { setFieldValue, values } = useFormikContext<any>();
  const { t } = useTranslation();
  useEffect(() => {
    if (open) {
      dispatch(
        getCustomerCallLogsTicketUsers({
          payload: {
            onSuccess: (msg, py) => setUsers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketSubjectList({
          payload: {
            onSuccess: (msg, py) => setSubjects(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getSupplierLookup({
          payload: {
            onSuccess: (msg, py) => setSuppliers(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketTypes({
          payload: {
            onSuccess: (msg, py) => setTicketTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <GetInput
        inputprop={{
          col: 12,
          hide: () => !values.CustomerId,
          field: "OpenTicket",
          label: t("İlişkili Talep Oluştur"),
          inputType: InputType.checkbox,
        }}
      />
      <GetInput
        inputprop={{
          hide: () => !values.OpenTicket,
          col: 12,
          field: "ResponsibleUserId",
          label: t("Talep Sorumlusu"),
          inputType: InputType.multiselect,
          lookup: {
            data: users,
            labelKey: "FullName",
            valueKey: "Id",
          },
        }}
      />
      <GetInput
        inputprop={{
          hide: () => !values.OpenTicket,
          col: 12,
          field: "SupplierId",
          label: t("Supplier"),
          inputType: InputType.multiselect,
          lookup: {
            data: suppliers,
            labelKey: "Name",
            valueKey: "Id",
          },
        }}
      />
      <GetInput
        inputprop={{
          hide: () => !values.OpenTicket,
          col: 6,
          field: "TicketTypeId",
          label: t("Talep Tipi"),
          inputType: InputType.multiselect,
          lookup: {
            data: ticketTypes,
            labelKey: "Name",
            valueKey: "Id",
          },
        }}
      />
      <GetInput
        inputprop={{
          hide: () => !values.OpenTicket,
          col: 6,
          field: "SubjectId",
          label: t("Talep Konusu"),
          inputType: InputType.multiselect,
          lookup: {
            data: values.TicketTypeId
              ? subjects.filter(x => x.TicketTypeId === values.TicketTypeId)
              : [],
            labelKey: "Subject",
            valueKey: "Id",
          },
        }}
      />
      <GetInput
        inputprop={{
          hide: () => !values.OpenTicket,
          field: "Message",
          label: t("Message"),
          inputType: InputType.multilinetext,
        }}
      />
      {values.OpenTicket && (
        <Col md={12} className="mb-3">
          <FileUploader
            className=" btn-light ms-1 w-100"
            onChange={files => {
              setFieldValue("Documents", files);
            }}
            title={t("Tickets.Documents")}
          />
          {values.Documents?.map((x: any) => (
            <div className="font-size-12 badge bg-light">
              <span
                className="fw-bold me-2"
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setFieldValue(
                    "Documents",
                    values.Documents.filter((y: any) => y.name !== x.name)
                  )
                }
              >
                X
              </span>
              {x.name}
            </div>
          ))}
          <FileUploader
            className=" btn-light ms-1 mt-2 w-100"
            onChange={files => {
              setFieldValue("Images", files);
            }}
            title={t("Tickets.Images")}
          />
          {values.Images?.map((x: any) => (
            <div className="font-size-12 badge bg-light">
              <span
                className="fw-bold me-2"
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setFieldValue(
                    "Images",
                    values.Images.filter((y: any) => y.name !== x.name)
                  )
                }
              >
                X
              </span>
              {x.name}
            </div>
          ))}
        </Col>
      )}
    </React.Fragment>
  );
};
export default TicketCreateInputs;
