import { LoginResponseModel } from "src/store/auth/types";

export const CheckRole = (
  user?: LoginResponseModel,
  roles?: string[],
  isNotAdmin?: boolean,
  permission?: string[]
) => {
  if (permission) {
    return user?.Permissions.some(a => permission.some(y => y === a));
  } else {
    if (user && user.IsAdmin) {
      return true;
    } else {
      if (isNotAdmin !== undefined) {
        return true;
      } else return false;
    }
  }
};
// export const CheckPermission = (
//   user?: LoginResponseModel,
//   permission?: string[]
// ) => {
//   if (permission && user) {
//     return user.Permissions.some(a => permission.some(y => y === a));
//   } else return true;
// };
