import CorporateCompaniesPage from "./companies";
import CorporateClientCustomersPage from "./customers";
import CorporateDepartmentsPage from "./departments";
import CorporateLocationsPage from "./locations";
export enum CorporateTypeEnum {
  Filo = 1,
  BlokSatis = 2,
}

interface NavMenuItemProps {
  component: any;
  eventKey: string;
  text: string;
  roles?: any[];
}
export enum EnumTabs {
  ActiveContracts = "1",
  Contracts = "2",
  Customers = "3",
  Companies = "4",
  Locations = "5",
  Departments = "6",
}
export const navMenuList: NavMenuItemProps[] = [
  // {
  //   text: "Aktif Sözleşme",
  //   eventKey: EnumTabs.ActiveContract,
  //   component: <ActiveContract />,
  // },
  {
    text: "Aktif Sözleşme",
    eventKey: EnumTabs.ActiveContracts,
    component: <></>,
    roles: [CorporateTypeEnum.BlokSatis, CorporateTypeEnum.Filo],
  },
  {
    text: "Üyeler",
    eventKey: EnumTabs.Customers,
    component: <CorporateClientCustomersPage />,
    roles: [CorporateTypeEnum.BlokSatis, CorporateTypeEnum.Filo],
  },
  {
    text: "Şirketler",
    eventKey: EnumTabs.Companies,
    component: <CorporateCompaniesPage />,
    roles: [CorporateTypeEnum.Filo],
  },
  {
    text: "Lokasyonlar",
    eventKey: EnumTabs.Locations,
    component: <CorporateLocationsPage />,
    roles: [CorporateTypeEnum.Filo],
  },
  {
    text: "Departmanlar",
    eventKey: EnumTabs.Departments,
    component: <CorporateDepartmentsPage />,
    roles: [CorporateTypeEnum.Filo],
  },
  {
    text: "Sözleşmeler",
    eventKey: EnumTabs.Contracts,
    component: <></>,
    roles: [CorporateTypeEnum.BlokSatis, CorporateTypeEnum.Filo],
  },
];
