import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import {
  getCustomerVehicleCategory,
  getCustomerVehicleSubCategory,
} from "src/store/customervehicles/saga";
import {
  CustomerVehicleLookupDto,
  VehicleStepDto,
} from "src/store/customervehicles/type";
import * as Yup from "yup";
import VehicleInput from "./vehicle-input";
import { ProductDetailDto } from "src/store/orders/products/type";
import { createProductCompatibleBrand } from "src/store/orders/product-compatible-brands/saga";
import { toast } from "react-toastify";
export interface PrivilegeFilterDto {
  PrivilegeId: string;
  PrivilegeFilterTypeId: number;
}
const CreateCompatibleBrand = ({
  product,
  onHide,
  open,
  onSuccess,
}: {
  product: ProductDetailDto;
  onSuccess: () => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<CustomerVehicleLookupDto>();
  useEffect(() => {
    if (open) {
      dispatch(
        getCustomerVehicleCategory({
          payload: {
            onSuccess: (ms, payload) => {
              setCategories(payload);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <CustomModal onHide={onHide} open={open} size="xs" title={" "}>
        <Formik
          onSubmit={values => {
            dispatch(
              createProductCompatibleBrand({
                payload: {
                  onSuccess: p => {
                    return onSuccess();
                  },
                  onError: m => toast.error(m),
                },
                body: values,
              })
            );
            return onHide();
          }}
          initialValues={{
            ProductId: product.Id,
            make: "",
            model: "",
            DataArray: [],
          }}
          validationSchema={Yup.object().shape({
            make: Yup.string().required(t("Required")),
            model: Yup.mixed().required(t("Required")),
          })}
        >
          {({ values, handleSubmit }) => (
            <Row className="pb-3">
              {categories && (
                <>
                  <GetInput
                    inputprop={{
                      col: 12,
                      hide(values) {
                        return !values.VehicleId ? false : true;
                      },
                      inputType: InputType.multiselect,
                      field: "category",
                      label: t("Kategori"),
                      lookup: {
                        data: categories.nextStep.map(x => {
                          return {
                            value: x.name ?? x.summary,
                            label: x.name ?? x.summary,
                            href: x.href,
                            rel: x.rel,
                            summary: x.summary,
                            templated: x.templated,
                            type: x.type,
                            description: x.description,
                            name: x.name,
                          };
                        }),
                        cleanable: false,
                      },
                      onChange: (
                        value,
                        item: VehicleStepDto,
                        setFieldValue
                      ) => {
                        setFieldValue("DataArray", []);
                        dispatch(
                          getCustomerVehicleSubCategory({
                            payload: {
                              onSuccess: (ms, payload) => {
                                setFieldValue("DataArray", [payload]);
                              },
                              onError: () => {},
                            },
                            body: {
                              url: item.href,
                            },
                          })
                        );
                      },
                    }}
                  />
                  {values.DataArray.slice(0, 2).map((x, i) => (
                    <VehicleInput list={x} i={i} />
                  ))}
                </>
              )}
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("Send")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};

export default CreateCompatibleBrand;
