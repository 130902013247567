import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { createPrivilegeTerm } from "src/store/privilegeterms/saga";
import * as Yup from "yup";

const CreatePrivilegeTermForm = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Privilege Terms"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          Description: "",
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeTerm({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Description: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              disabled: true,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "Description",
            label: t("Description"),
            inputType: InputType.multilinetext,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeTermForm;
