import { TextBox } from "devextreme-react";
import React from "react";
import { Col, Row } from "reactstrap";
import { OrderDetailDto } from "src/store/orders/orders/type";
import PaymentInfoRefund from "./paymentRefundInfo";

const OrderInfoCard = ({ order }: { order: OrderDetailDto }) => {
  const fields = [
    {
      label: "Durum",
      value: order.OrderStatusName,
    },
    {
      label: "Müşteri Adı",
      value: order.CustomerFullName,
    },
    {
      label: "Telefon Numarası",
      value: order.CustomerPhoneNumber,
    },
    {
      label: "Email",
      value: order.CustomerEmail,
    },
    {
      label: "Fatura Adresi",
      value: order.CustomerInvoiceAddress,
    },
    {
      label: "Teslimat Adresi",
      value: order.CustomerInvoiceAddress,
    },
  ];
  return (
    <React.Fragment>
      <Row>
        {fields.map((x, i) => {
          return (
            <Col md={12} key={x.label + i} className="mb-3">
              <TextBox
                readOnly
                label={x.label}
                value={x.value}
                stylingMode="filled"
                style={{
                  backgroundColor: "white",
                  padding: 0,
                }}
              />
            </Col>
          );
        })}
        <Col md={12} style={{ paddingInline: 19 }}>
          <PaymentInfoRefund order={order} />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default OrderInfoCard;
