import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  createPrivilegeButton,
  getPrivilegeButtonActionTypes,
} from "src/store/privilegebuttons/saga";
import { ButtonActionTypeDto } from "src/store/privilegebuttons/type";
import * as Yup from "yup";

const CreatePrivilegeButtonForm = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionTypes, setActionTypes] = useState<ButtonActionTypeDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeButtonActionTypes({
          payload: {
            onSuccess: (msg, py) => setActionTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          ButtonName: "",
          Value: "",
          IconFile: "",
          ButtonActionTypeId: "",
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeButton({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          Value: Yup.string().required(t("Required")),
          ButtonActionTypeId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "ButtonName",
            label: t("Buton Adı"),
            inputType: InputType.multilinetext,
          },
          {
            field: "ButtonActionTypeId",
            inputType: InputType.multiselect,
            label: t("Aksiyon Tipi"),
            lookup: {
              data: actionTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Aksiyon Tipi Seçiniz"),
            },
          },
          {
            field: "Value",
            label: t("Değeri"),
          },
          {
            field: "IconFile",
            label: t("IconFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/svg+xml"],
              multiple: false,
              title: t("Please Upload Icon Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeButtonForm;
