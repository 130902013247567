import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PrivilegeDto } from "src/store/privilege/type";
import MainPage from "src/components/PageContent/MainPage";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CancelAlert from "src/components/CancelAlert";
import { deletePrivilege, getPrivilegeList } from "src/store/privilege/saga";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";

const PrivilegePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<PrivilegeDto[]>([]);
  const [showDelete, setShowDelete] = useState<PrivilegeDto>();
  const history = useHistory();
  const columns = [
    {
      text: t("Kategori Adı"),
      dataField: "PrivilegeCategoryName",
    },
    {
      text: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Start Date"),
      dataField: "StartDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: t("End Date"),
      dataField: "EndDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },

    {
      text: t("Coupon Count"),
      dataField: "CouponCount",
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {data.HasContainsPrivilege && (
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                color: "#5156be",
                text: t("Dahil Hizmetler"),
              }}
              onClick={() => {
                history.push("/containsprivileges/" + cellContent);
              }}
            />
          )}
          {data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kart && (
            <>
              <ColumnButton
                buttonType="customtext"
                custombutton={{
                  color: "#5156be",
                  text: t("Kartlar"),
                }}
                onClick={() => {
                  history.push("/privilegecards/" + cellContent);
                }}
              />{" "}
              <ColumnButton
                buttonType="customtext"
                custombutton={{
                  color: "#5156be",
                  text: t("Üye Hakları"),
                }}
                onClick={() => {
                  history.push("/privilege/customer-privileges/" + cellContent);
                }}
              />
            </>
          )}
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#2ab57d",
              text: t("Buttonlar"),
            }}
            onClick={() => {
              history.push("/privilegebuttons/" + cellContent);
            }}
          />
          {(data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon ||
            data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.QR) && (
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                text:
                  data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon
                    ? t("Kuponlar")
                    : t("QR Kodlar"),
              }}
              onClick={() => {
                history.push("/privilege-codes/" + cellContent);
              }}
            />
          )}
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#2ab57d",
              text: t("Fiyat"),
            }}
            onClick={() => {
              history.push("/privilegeprices/" + cellContent);
            }}
          />
          {data.ShowTerms && (
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                color: "#2ab57d",
                text: t("Koşullar"),
              }}
              onClick={() => {
                history.push("/privilegeterms/" + cellContent);
              }}
            />
          )}
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#9e9e9e",
              text: t("Lokasyonlar"),
            }}
            onClick={() => {
              history.push("/privilege-locations/" + cellContent);
            }}
          />
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              history.push("/privilege/edit/" + cellContent);
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPrivilegeList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      <MainPage
        title={t("PrivilegeTitle")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "link",
            createLink: "/privilege/create",
          },
          setDataList: setData,
          action: getPrivilegeList,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete privilege`, {
            name: `${showDelete.Title} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePrivilege({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PrivilegePage;
