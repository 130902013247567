import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { Modal } from "rsuite";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import { baseImageUrl } from "src/store/base/types/url";
import { CustomerFavoriteDto } from "src/store/customerfavorites/type";

const FavoriteDetailModal = ({
  onHide,
  data,
  open,
}: {
  data: CustomerFavoriteDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <CustomModal
        open={open}
        onHide={() => onHide()}
        title={
          <Modal.Title className="text-center mb-2">
            <span className="fw-bold text-center " style={{ fontSize: 18 }}>
              {t("Customer Favorite Details")}
            </span>
          </Modal.Title>
        }
      >
        <Formik
          onSubmit={() => {}}
          initialValues={{
            Title: data.Title,
            ShortDescription: data.ShortDescription,
            Description: data.Description,
            TermsOfService: data.TermsOfService,
            Image: data.Image,
          }}
        >
          {({ values }) => (
            <Row className="mx-3">
              <Col md={12} className="my-2">
                <img
                  alt={values.Title}
                  src={baseImageUrl + values.Image}
                  width="100%"
                  height={"auto"}
                  style={{ maxHeight: "200px" }}
                />
              </Col>
              <Col md={12} className="mb-2">
                <GetInput
                  disabledvalue
                  inputprop={{
                    field: "Title",
                    label: t("Title"),
                  }}
                />
              </Col>

              <Col md={12} className="mb-2">
                <GetInput
                  disabledvalue
                  inputprop={{
                    field: "ShortDescription",
                    label: t("Short Description"),
                    inputType: InputType.multilinetext,
                  }}
                />
              </Col>

              <Col md={12} className="mb-2">
                <GetInput
                  disabledvalue
                  inputprop={{
                    field: "Description",
                    label: t("Description"),
                    inputType: InputType.multilinetext,
                  }}
                />
              </Col>

              <Col md={12} className="mb-2">
                <GetInput
                  disabledvalue
                  inputprop={{
                    field: "TermsOfService",
                    label: t("TermsOfService"),
                    inputType: InputType.multilinetext,
                  }}
                />
              </Col>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};

export default FavoriteDetailModal;
