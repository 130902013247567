import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getCustomerAddressListByCustomerId } from "src/store/customeraddress/saga";
import { CustomerAddressDto } from "src/store/customeraddress/type";
import { createCustomerPackage } from "src/store/customers/saga";
import { CustomerDto } from "src/store/customers/type";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import { CustomerVehicleDto } from "src/store/customervehicles/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import { getPackageGiftList } from "src/store/packagegift/saga";
import { PackageGiftDto } from "src/store/packagegift/type";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const CreateCustomerPackage = ({
  onHide,
  customer,
  open,
}: {
  customer: CustomerDto;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [address, setAddress] = useState<CustomerAddressDto[]>([]);
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [packagePrivileges, setPackagePrivileges] = useState<PackageGiftDto[]>(
    []
  );
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCities({
          payload: {
            onSuccess: (ms, payload) => {
              return setCities(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (ms, payload) => {
              return setPackages(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerVehicleListByCustomerId({
          payload: {
            onSuccess: (ms, payload) => {
              return setVehicles(payload);
            },
            onError: () => {},
          },
          id: customer.Id,
        })
      );
      dispatch(
        getCustomerAddressListByCustomerId({
          payload: {
            onSuccess: (ms, payload) => {
              return setAddress(payload);
            },
            onError: () => {},
          },
          id: customer.Id,
        })
      );
    }
  }, [dispatch, customer.Id, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Yeni Müşteri"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CustomerId: customer.Id,
          VehicleId: "",
          PackageId: "",
          PackageGiftPrivilegeId: "",
          AddressId: "",
          CityId: "",
          TownId: "",
          Yearly: false,
          PhoneNumber: customer.PhoneNumber ?? "",
          Name: customer.Name ?? "",
          Surname: customer.Surname ?? "",
          IdentityNumber: customer.IdentityNumber ?? "",
          Email: customer.Email ?? "",
          Address: "",
          Price: 0,
        }}
        onSubmit={values => {
          dispatch(
            createCustomerPackage({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  toast.success(message);
                  // history.push("/customer/details/10/" + payload.Id);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PhoneNumber: Yup.mixed().required(t("Required")),
          PackageId: Yup.mixed().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          Name: Yup.string().required(t("Required")),
          Email: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "VehicleId",
            inputType: InputType.multiselect,
            label: t("Araç Seçimi"),
            lookup: {
              data: vehicles,
              labelKey: "Plate",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },
          {
            field: "PackageId",
            inputType: InputType.multiselect,
            label: t("Paket"),
            lookup: {
              data: packages,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getPackageGiftList({
                    payload: {
                      onSuccess: (msg, payload) =>
                        setPackagePrivileges(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            field: "PackageGiftPrivilegeId",
            inputType: InputType.multiselect,
            label: t("Hediye Seçimi"),
            lookup: {
              data: packagePrivileges,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Surname",
            label: t("Surname"),
            col: 6,
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            field: "Email",
            label: t("Email"),
          },
          {
            col: 12,
            field: "AddressId",
            inputType: InputType.multiselect,
            label: t("Address"),
            lookup: {
              data: address,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
            onChange(value, item, setFieldValue, values) {
              if (item) {
                setFieldValue("Address", item.Address);
                setFieldValue("TownId", item.TownId);
                setFieldValue("CityId", item.CityId);
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: item.CityId,
                  })
                );
              }
            },
          },
          {
            col: 6,
            field: "CityId",
            inputType: InputType.multiselect,
            label: t("City"),
            lookup: {
              data: cities,
              labelKey: "CityName",
              valueKey: "Id",
              placeholder: t("Choose City"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            col: 6,
            field: "TownId",
            inputType: InputType.multiselect,
            label: t("Town"),
            lookup: {
              data: towns,
              labelKey: "TownName",
              valueKey: "Id",
              placeholder: t("Choose Town"),
            },
          },
          {
            field: "Address",
            label: t("Address"),
          },
          {
            field: "Price",
            label: t("Faturalanacak Tutar"),
            inputType: InputType.number,
          },
          {
            field: "Yearly",
            label: t("Yıllık"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCustomerPackage;
