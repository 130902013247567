import { Rate } from "rsuite";
import { baseImageUrl } from "src/store/base/types/url";
import { ProductImageDto } from "src/store/orders/products-images/type";

const ProductImageCard = ({
  data,
  active,
  setActive,
  refreshData,
  clickDelete,
  clickMain,
}: {
  data: ProductImageDto;
  active?: ProductImageDto;
  setActive: (active: ProductImageDto) => void;
  refreshData: () => void;
  clickDelete: () => void;
  clickMain: () => void;
}) => {
  return (
    <>
      <Rate
        max={1}
        value={data.Main ? 1 : 0}
        onChange={() => clickMain()}
        size="xs"
        style={{
          position: "absolute",
          zIndex: 1,
          marginInlineStart: 20,
          marginBlockStart: 3,
        }}
      />
      <Rate
        max={1}
        value={1}
        character={<i className="mdi mdi-trash-can" />}
        onChange={() => clickDelete()}
        color="red"
        size="xs"
        style={{
          position: "absolute",
          zIndex: 1,
        }}
      />
      <img
        onClick={() => setActive(data)}
        key={data.Id}
        src={baseImageUrl + data.Image}
        alt=""
        style={{ position: "relative" }}
        className={
          "pe-2 cursor-pointer " + (active?.Id === data.Id ? "shadow " : " ")
        }
        width={100}
        height={100}
      />
    </>
  );
};
export default ProductImageCard;
