export interface CustomerPrivilegeDto {
  Id: string;
  Name: string;
  Plate: string;
  PrivilegeName: string;
  Icon?: any;
  Sellable: boolean;
  CustomerId: string;
  VehicleId?: any;
  PrivilegeId: string;
  UsageTypeId: number;
  Count: number;
  Transferable: boolean;
  Cancelled: boolean;
  CustomerPrivilegeSourceTypeId: number;
  CustomerPrivilegeSourceTypeName: string;
  StartDate: string;
  EndDate: string;
  TotalCount: number;
  ChurnedCount: number;
  UsedCount: number;
  AvaibleCount: number;
  PrivilegeUsageTypeId: number;
}

export interface CustomerUsedCouponTransactionDto {
  Id: string;
  Plate: string;
  SupplierName: string;
  CityName: string;
  TownName: string;
  TransactionDate: string;
  Description: string;
  InvoiceDate?: any;
  InvoiceNumber?: any;
  InvoicePrice?: any;
}
export interface CustomerUsedDto {
  Id: string;
  PrivilegeId: string;
  VehicleId: string;
  Plate: string;
  QRCode: string;
  UsedDate: string;
  Validated: boolean;
  ValidatedDate: string;
  CustomerId: string;
  CustomerName: string;
}
export enum CustomerPrivilegeUsageTypeEnum {
  Count = 1,
  Unlimited = 2,
  Day = 3,
  Week = 4,
  Month = 5,
  Year = 6,
}
