import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PlannedNotificationButtonDto } from "./type";
import { BodyType } from "../base/request/request";
export const getPlannedNotificationButtonList = createAction(
  "GET_NOTIFICATION_PLANNED_LIST",
  ({ payload, params }: SagaActionParams<PlannedNotificationButtonDto[]>) => ({
    payload,
    url: "/api/plannednotificationbuttons" + (params ? params : ""),
  })
);
export const getPlannedNotificationButtonListById = createAction(
  "GET_NOTIFICATION_PLANNED_BUTTON_LIST_BY_ID",
  ({
    payload,
    params,
    id,
  }: SagaActionParams<PlannedNotificationButtonDto[]>) => ({
    payload,
    url:
      "/api/plannednotificationbuttons/GetById/" + id + (params ? params : ""),
  })
);
export const getPlannedNotificationButtonDetails = createAction(
  "GET_NOTIFICATION_PLANNED_BUTTON_DETAILS",
  ({ payload, id }: SagaActionParams<PlannedNotificationButtonDto>) => ({
    payload,
    url: "/api/plannednotificationbuttons/" + id,
  })
);
export const createPlannedNotificationButton = createAction(
  "CREATE_NOTIFICATION_PLANNED_BUTTON_DETAILS",
  ({ payload, body }: SagaActionParams<PlannedNotificationButtonDto>) => ({
    payload,
    url: "/api/plannednotificationbuttons",
    body,
  })
);
export const updatePlannedNotificationButton = createAction(
  "UPDATE_NOTIFICATION_PLANNED_BUTTON_DETAILS",
  ({ payload, body, id }: SagaActionParams<PlannedNotificationButtonDto>) => ({
    payload,
    url: "/api/plannednotificationbuttons/" + id,
    body,
  })
);
export const deletePlannedNotificationButton = createAction(
  "DELETE_NOTIFICATION_PLANNED_BUTTON_DETAILS",
  ({ payload, body, id }: SagaActionParams<PlannedNotificationButtonDto>) => ({
    payload,
    url: "/api/plannednotificationbuttons/" + id,
    body,
  })
);
export const changePlannedNotificationButtonOrder = createAction(
  "CHANGE_NOTIFICATION_PLANNED_BUTTON_ORDER",
  ({ payload, body }: SagaActionParams<PlannedNotificationButtonDto>) => ({
    payload,
    url: "/api/plannednotificationbuttons/order",
    bodyType: BodyType.raw,
    body,
  })
);
const plannedNotificationButtonSaga = [
  takeLatest(
    getPlannedNotificationButtonListById.toString(),
    (payload: SagaGenericParams<PlannedNotificationButtonDto[]>) =>
      getListSaga<PlannedNotificationButtonDto[]>(payload)
  ),
  takeLatest(
    getPlannedNotificationButtonList.toString(),
    (payload: SagaGenericParams<PlannedNotificationButtonDto[]>) =>
      getListSaga<PlannedNotificationButtonDto[]>(payload)
  ),
  takeLatest(
    getPlannedNotificationButtonDetails.toString(),
    (payload: SagaGenericParams<PlannedNotificationButtonDto>) =>
      getListSaga<PlannedNotificationButtonDto>(payload)
  ),
  takeLatest(
    createPlannedNotificationButton.toString(),
    (payload: PostSagaGenericParams<PlannedNotificationButtonDto>) =>
      postSaga<PlannedNotificationButtonDto>(payload)
  ),
  takeLatest(
    updatePlannedNotificationButton.toString(),
    (payload: PostSagaGenericParams<PlannedNotificationButtonDto>) =>
      putSaga<PlannedNotificationButtonDto>(payload)
  ),
  takeLatest(
    deletePlannedNotificationButton.toString(),
    (payload: SagaGenericParams<PlannedNotificationButtonDto>) =>
      deleteSaga<PlannedNotificationButtonDto>(payload)
  ),
  takeLatest(
    changePlannedNotificationButtonOrder.toString(),
    (payload: PostSagaGenericParams<PlannedNotificationButtonDto>) =>
      putSaga<PlannedNotificationButtonDto>(payload)
  ),
];

export default plannedNotificationButtonSaga;
