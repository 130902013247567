import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import rootSaga from "./sagas";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["app", "locale"],
};
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export function configureStore(initialState: any) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  let persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
