import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TicketSubjectDetailInfoDto } from "src/store/ticket-subject-details-info/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreateTicketSubjectDetailInfo from "./create";
import { toast } from "react-toastify";
import EditTicketSubjectDetailInfo from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  deleteTicketSubjectDetailInfo,
  getTicketSubjectDetailInfoDetails,
  getTicketSubjectDetailInfoListByTicketSubjectDetailId,
} from "src/store/ticket-subject-details-info/saga";
import MainPage from "src/components/PageContent/MainPage";
import { useParams } from "react-router";
import { getTicketSubjectDetailDetails } from "src/store/ticket-subject-details/saga";
import { TicketSubjectDetailDto } from "src/store/ticket-subject-details/type";

const TicketSubjectDetailInfoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<TicketSubjectDetailInfoDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<TicketSubjectDetailInfoDto[]>([]);
  const [showDelete, setShowDelete] = useState<TicketSubjectDetailInfoDto>();
  const [ticketSubject, setTicketSubject] = useState<TicketSubjectDetailDto>();

  useEffect(() => {
    dispatch(
      getTicketSubjectDetailDetails({
        payload: {
          onSuccess: (msg, py) => setTicketSubject(py),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Talep Konusu"),
      dataField: "TicketSubjectName",
      formatter: () => ticketSubject?.TicketSubjectName,
    },
    {
      text: t("Talep Konusu Açıklaması"),
      dataField: "TicketSubjectName",
      formatter: () => ticketSubject?.Description,
    },
    {
      text: t("Description"),
      dataField: "Description",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getTicketSubjectDetailInfoDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getTicketSubjectDetailInfoListByTicketSubjectDetailId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {ticketSubject && (
        <>
          {selected && (
            <EditTicketSubjectDetailInfo
              data={selected}
              onHide={() => setShowEdit(false)}
              open={showEdit}
              onSuccessClick={onSuccessClick}
            />
          )}
          <CreateTicketSubjectDetailInfo
            id={id}
            onHide={() => setShowCreate(false)}
            open={showCreate}
            onSuccessClick={onSuccessClick}
          />
          <MainPage
            withoutPag
            title={`${ticketSubject.Description} Talep Konusu Açıklaması Bilgileri`}
            gridProps={{
              listData: data,
              columns: columns,
              create: {
                createType: "drawer",
                onCreateClick: () => setShowCreate(true),
              },
              setDataList: setData,
              action: getTicketSubjectDetailInfoListByTicketSubjectDetailId,
              paramsid: id,
            }}
          />
          {showDelete && (
            <CancelAlert
              message={t(
                `Talep konu açıklama bilgisini Silmek istediğinize emin misiniz?`
              )}
              onConfirm={() => {
                setShowDelete(undefined);
                dispatch(
                  deleteTicketSubjectDetailInfo({
                    payload: {
                      onSuccess: message => onSuccessClick(message),
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    id: showDelete.Id,
                  })
                );
              }}
              onCancel={() => {
                setShowDelete(undefined);
              }}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default TicketSubjectDetailInfoPage;
