import { useFormikContext } from "formik";
import { Col } from "reactstrap";
import Input from "../Input";
import { InputType } from "../type";
import { inputKey } from "../type";
import CustomSelectPicker from "../SelectPicker";
import FileUpload from "../FileUpload";
import CustomCheckbox from "../Input/CheckBox";
import CustomRadioGrup from "../Input/RadioGrup";
import moment from "moment";
import CustomDatePicker from "../DatePicker";
import CustomTextarea from "../Input/Textarea";
import CustomEditor from "../Input/Editor";
import CustomToggle from "../Input/Toggle";
import GoogleMaps from "../GoogleMaps";
import CustomCheckPicker from "../CheckPicker";
import CustomTabGroup from "../Input/TabGroup";
import CustomCascader from "../Input/CascadeInput";
export interface GetInputProp {
  error?: any;
  touch?: any;
  value?: any;
  inputprop: inputKey;
  disabledvalue?: boolean;
}
const GetInput = ({
  inputprop,
  disabledvalue = false,
  value,
  error,
  touch,
}: GetInputProp) => {
  const { touched, errors, values, handleBlur, setFieldValue } =
    useFormikContext<any>();

  return (
    <>
      {inputprop.hide && inputprop.hide(values) ? (
        <></>
      ) : (
        <Col md={inputprop.col ?? 12} className="mb-2">
          {!inputprop.inputType && (
            <Input
              disabled={disabledvalue}
              field={inputprop.field}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.text && (
            <Input
              field={inputprop.field}
              disabled={disabledvalue}
              type={inputprop.type}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.multilinetext && (
            <CustomTextarea
              field={inputprop.field}
              disabled={disabledvalue}
              type={inputprop.type}
              label={inputprop.label}
              style={inputprop.style}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.currency && (
            <Input
              field={inputprop.field}
              disabled={disabledvalue}
              min={inputprop.min}
              max={inputprop.max}
              type={"number"}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.number && (
            <Input
              field={inputprop.field}
              disabled={disabledvalue}
              type={"number"}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
            // <InputNumber
            //   disabled={disabledvalue}
            //   label={inputprop.label}
            //   error={error?error:errors[inputprop.field]}
            //   value={value?value:values[inputprop.field]}
            //   onBlur={handleBlur(inputprop.field)}
            //   onChange={e => {
            //     inputprop.onChange && inputprop.onChange(e, "", setFieldValue,values);
            //     return setFieldValue(`${inputprop.field}`, e);
            //   }}
            // />
          )}
          {inputprop.inputType === InputType.editor && (
            <CustomEditor
              field={inputprop.field}
              disabled={disabledvalue}
              className={inputprop.className}
              label={inputprop.label}
              height={inputprop.height}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.date && (
            <CustomDatePicker
              disabled={disabledvalue}
              field={inputprop.field}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              popperPlacement="bottom-start"
              selected={
                value
                  ? new Date(value)
                  : values[inputprop.field]
                  ? new Date(values[inputprop.field])
                  : null
              }
              onChange={date => {
                if (date != null) {
                  inputprop.onChange &&
                    inputprop.onChange(date, "", setFieldValue);
                  setFieldValue(`${inputprop.field}`, moment(date).format());
                } else {
                  inputprop.onChange &&
                    inputprop.onChange(date, "", setFieldValue);
                  setFieldValue(`${inputprop.field}`, "");
                }
              }}
              showTimeSelect={false}
              dateFormat="dd/MM/yyyy"
            />
          )}
          {inputprop.inputType === InputType.datetime && (
            <CustomDatePicker
              disabled={disabledvalue}
              field={inputprop.field}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              popperPlacement="bottom-start"
              selected={
                value
                  ? new Date(value)
                  : values[inputprop.field]
                  ? new Date(values[inputprop.field])
                  : null
              }
              onChange={date => {
                if (date != null) {
                  inputprop.onChange &&
                    inputprop.onChange(date, "", setFieldValue);
                  setFieldValue(
                    `${inputprop.field}`,
                    moment(date).format().replace("+03:00", "")
                  );
                } else {
                  inputprop.onChange &&
                    inputprop.onChange(date, "", setFieldValue);
                  setFieldValue(`${inputprop.field}`, "");
                }
              }}
              showTimeSelect
              timeIntervals={60}
              dateFormat="dd/MM/yyyy HH:mm"
            />
          )}
          {inputprop.inputType === InputType.multiselect &&
            inputprop.lookup && (
              <CustomSelectPicker
                disabled={disabledvalue}
                onClean={() => {
                  inputprop.onChange &&
                    inputprop.onChange("", "", setFieldValue);
                  setFieldValue(`${inputprop.field}`, "");
                }}
                field={inputprop.field}
                label={inputprop.label}
                error={error ? error : errors[inputprop.field]}
                touched={touch ? touch : touched[inputprop.field]}
                value={value ? value : values[inputprop.field]}
                onSelect={(e, item) => {
                  inputprop.onChange &&
                    inputprop.onChange(e, item, setFieldValue, values);
                  return setFieldValue(`${inputprop.field}`, e);
                }}
                {...inputprop.lookup}
              />
            )}
          {inputprop.inputType === InputType.checkpicker &&
            inputprop.lookup && (
              <CustomCheckPicker
                disabled={disabledvalue}
                onClean={() => {
                  inputprop.onChange &&
                    inputprop.onChange("", "", setFieldValue);
                  setFieldValue(`${inputprop.field}`, []);
                }}
                field={inputprop.field}
                label={inputprop.label}
                error={error ? error : errors[inputprop.field]}
                touched={touch ? touch : touched[inputprop.field]}
                value={value ? value : values[inputprop.field]}
                onSelectValue={e => {
                  inputprop.onChange &&
                    inputprop.onChange(e, undefined, setFieldValue, values);
                  return setFieldValue(`${inputprop.field}`, e);
                }}
                {...inputprop.lookup}
              />
            )}
          {inputprop.inputType === InputType.radiogrup &&
            inputprop.radiogrups && (
              <CustomRadioGrup
                disabled={disabledvalue}
                field={inputprop.field}
                label={inputprop.label}
                error={error ? error : errors[inputprop.field]}
                touched={touch ? touch : touched[inputprop.field]}
                value={value ? value : values[inputprop.field]}
                onChange={(e, item) => {
                  inputprop.onChange &&
                    inputprop.onChange(e, item, setFieldValue);
                  return setFieldValue(`${inputprop.field}`, e);
                }}
                {...inputprop.radiogrups}
              />
            )}{" "}
          {inputprop.inputType === InputType.tabGroup &&
            inputprop.radiogrups && (
              <CustomTabGroup
                disabled={disabledvalue}
                field={inputprop.field}
                label={inputprop.label}
                error={error ? error : errors[inputprop.field]}
                touched={touch ? touch : touched[inputprop.field]}
                value={value ? value : values[inputprop.field]}
                onChange={(e, item) => {
                  inputprop.onChange &&
                    inputprop.onChange(e, item, setFieldValue);
                  return setFieldValue(`${inputprop.field}`, e);
                }}
                {...inputprop.radiogrups}
              />
            )}
          {inputprop.inputType === InputType.checkbox && (
            <CustomCheckbox
              field={inputprop.field}
              disabled={disabledvalue}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.toogle && (
            <CustomToggle
              field={inputprop.field}
              disabled={disabledvalue}
              label={inputprop.label}
              checkedLabel={inputprop.checkedLabel}
              uncheckedLabel={inputprop.uncheckedLabel}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              value={value ? value : values[inputprop.field]}
              onBlur={handleBlur(inputprop.field)}
              onChange={e => {
                inputprop.onChange &&
                  inputprop.onChange(e, "", setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
            />
          )}
          {inputprop.inputType === InputType.component && (
            <>{inputprop.component && inputprop.component(values)}</>
          )}
          {inputprop.inputType === InputType.fileUpload &&
            inputprop.fileUpload && (
              <FileUpload
                error={
                  error
                    ? error
                    : inputprop.fileUpload.linkField
                    ? errors[inputprop.fileUpload.linkField]
                    : errors[inputprop.field]
                }
                multiple={inputprop.fileUpload.multiple}
                disabled={disabledvalue}
                accept={inputprop.fileUpload.accept}
                linkvalues={
                  inputprop.fileUpload.linkField &&
                  values[inputprop.fileUpload.linkField] !== ""
                    ? inputprop.fileUpload.multiple
                      ? values[inputprop.fileUpload.linkField]
                      : [values[inputprop.fileUpload.linkField]]
                    : []
                }
                onChange={files => {
                  inputprop.onChange &&
                    inputprop.onChange(files, files, setFieldValue);

                  if (inputprop.fileUpload && inputprop.fileUpload.multiple) {
                    setFieldValue(inputprop.field, files);
                  } else {
                    files.length > 0 &&
                      setFieldValue(inputprop.field, files[0]);
                  }
                }}
                title={inputprop.fileUpload.title}
              />
            )}
          {inputprop.inputType === InputType.googlemap &&
            inputprop.googlemap && (
              <GoogleMaps
                lat={parseFloat(values[inputprop.googlemap.latField])}
                lng={parseFloat(values[inputprop.googlemap.lonField])}
                setLat={lat =>
                  setFieldValue(
                    inputprop.googlemap ? inputprop.googlemap.latField : "",
                    lat
                  )
                }
                setLng={lng =>
                  setFieldValue(
                    inputprop.googlemap ? inputprop.googlemap.lonField : "",
                    lng
                  )
                }
              />
            )}
          {inputprop.inputType === InputType.cascader && inputprop.cascader && (
            <CustomCascader
              disabled={disabledvalue}
              onClean={() => {
                inputprop.onChange && inputprop.onChange("", "", setFieldValue);
                setFieldValue(`${inputprop.field}`, "");
              }}
              field={inputprop.field}
              label={inputprop.label}
              error={error ? error : errors[inputprop.field]}
              touched={touch ? touch : touched[inputprop.field]}
              onChange={(e, item) => {
                inputprop.onChange &&
                  inputprop.onChange(e, item, setFieldValue, values);
                return setFieldValue(`${inputprop.field}`, e);
              }}
              placeholder={
                inputprop.onplaceholder ? inputprop.onplaceholder(values) : ""
              }
              {...inputprop.cascader}
            />
          )}
        </Col>
      )}
    </>
  );
};

export default GetInput;
