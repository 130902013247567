import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { CorporateCompanyDto } from "src/store/corporate-companies/type";
import * as Yup from "yup";

const DetailCorporateCompany = ({
  data,
  onHide,
  open,
}: {
  data: CorporateCompanyDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Şirket Detayları",
        }}
        onHide={onHide}
        hideButtons
        loader
        initialValues={{
          Id: data.Id,
          CompanyName: data.CompanyName ?? "",
          Address: data.Address ?? "",
          CityId: data.CityId ?? "",
          TownId: data.TownId ?? "",
          TaxOffice: data.TaxOffice ?? "",
          TaxId: data.TaxId ?? "",
          AuthorizedPerson: data.AuthorizedPerson ?? "",
          Email: data.Email ?? "",
          Phone: data.Phone ?? "",
          Active: data.Active ?? "",
          CityCityName: data.CityCityName ?? "",
          TownTownName: data.TownTownName ?? "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {}}
        inputs={[
          {
            field: "CompanyName",
            label: "Şirket Adı",
            disabled: () => true,
          },
          {
            field: "TaxOffice",
            label: "Vergi Dairesi",
            disabled: () => true,
          },
          {
            field: "TaxId",
            label: "Vergi No",
            disabled: () => true,
          },
          {
            field: "AuthorizedPerson",
            disabled: () => true,
            label: "Yetkili",
          },
          {
            field: "Email",
            disabled: () => true,
            label: "E-posta",
          },
          {
            field: "Phone",
            disabled: () => true,
            label: "Telefon",
          },
          {
            disabled: () => true,
            field: "Address",
            label: "Adres",
          },
          {
            disabled: () => true,
            field: "CityCityName",
            label: "İl",
          },
          {
            disabled: () => true,
            field: "TownTownName",
            label: "İlçe",
          },
          {
            disabled: () => true,
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default DetailCorporateCompany;
