import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ServiceMaintenanceCardDto } from "./type";
import { BodyType } from "src/store/base/request/request";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getServiceMaintenanceCardList = createAction(
  "GET_SERVICE_MAINTENANCE_CARD_LIST",
  ({ payload, params }: SagaActionParams<ServiceMaintenanceCardDto[]>) => ({
    payload,
    url: "/api/servicemaintenancecards" + (params ? params : ""),
  })
);
export const getServiceMaintenanceCardLookup = createAction(
  "GET_SERVICE_MAINTENANCE_CARD_LOOKUP",
  ({ payload }: SagaActionParams<ServiceMaintenanceCardDto[]>) => ({
    payload,
    url: "/api/servicemaintenancecards/lookup",
  })
);
export const getServiceMaintenanceCardListByBrandId = createAction(
  "GET_SERVICE_MAINTENANCE_CARD_LIST_BY_BRAND_ID",
  ({ payload, id }: SagaActionParams<ServiceMaintenanceCardDto[]>) => ({
    payload,
    url: "/api/servicemaintenancecards/bycategoryId/" + id,
  })
);
export const getServiceMaintenanceCardDetails = createAction(
  "GET_SERVICE_MAINTENANCE_CARD_DETAILS",
  ({ payload, id }: SagaActionParams<ServiceMaintenanceCardDto>) => ({
    payload,
    url: "/api/servicemaintenancecards/" + id,
  })
);
export const createServiceMaintenanceCard = createAction(
  "CREATE_SERVICE_MAINTENANCE_CARD_DETAILS",
  ({ payload, body }: SagaActionParams<ServiceMaintenanceCardDto>) => ({
    payload,
    url: "/api/servicemaintenancecards",
    body,
  })
);
export const updateServiceMaintenanceCard = createAction(
  "UPDATE_SERVICE_MAINTENANCE_CARD_DETAILS",
  ({ payload, body, id }: SagaActionParams<ServiceMaintenanceCardDto>) => ({
    payload,
    url: "/api/servicemaintenancecards/" + id,
    body,
  })
);
export const deleteServiceMaintenanceCard = createAction(
  "DELETE_SERVICE_MAINTENANCE_CARD_DETAILS",
  ({ payload, body, id }: SagaActionParams<ServiceMaintenanceCardDto>) => ({
    payload,
    url: "/api/servicemaintenancecards/" + id,
    body,
  })
);

export const changeServiceMaintenanceCardOrder = createAction(
  "UPDATE_SERVICE_MAINTENANCE_CARD_ORDER",
  ({ payload, body }: SagaActionParams<ServiceMaintenanceCardDto>) => ({
    payload,
    url: "/api/servicemaintenancecards/order",
    body,
    bodyType: BodyType.raw,
  })
);
const serviceMaintenanceCardsSaga = [
  takeLatest(
    getServiceMaintenanceCardListByBrandId.toString(),
    (payload: SagaGenericParams<ServiceMaintenanceCardDto[]>) =>
      getListSaga<ServiceMaintenanceCardDto[]>(payload)
  ),
  takeLatest(
    getServiceMaintenanceCardLookup.toString(),
    (payload: SagaGenericParams<ServiceMaintenanceCardDto[]>) =>
      getListSaga<ServiceMaintenanceCardDto[]>(payload)
  ),
  takeLatest(
    getServiceMaintenanceCardList.toString(),
    (payload: SagaGenericParams<ServiceMaintenanceCardDto[]>) =>
      getListSaga<ServiceMaintenanceCardDto[]>(payload)
  ),
  takeLatest(
    getServiceMaintenanceCardDetails.toString(),
    (payload: SagaGenericParams<ServiceMaintenanceCardDto>) =>
      getListSaga<ServiceMaintenanceCardDto>(payload)
  ),
  takeLatest(
    createServiceMaintenanceCard.toString(),
    (payload: PostSagaGenericParams<ServiceMaintenanceCardDto>) =>
      postSaga<ServiceMaintenanceCardDto>(payload)
  ),
  takeLatest(
    updateServiceMaintenanceCard.toString(),
    (payload: PostSagaGenericParams<ServiceMaintenanceCardDto>) =>
      putSaga<ServiceMaintenanceCardDto>(payload)
  ),
  takeLatest(
    deleteServiceMaintenanceCard.toString(),
    (payload: SagaGenericParams<ServiceMaintenanceCardDto>) =>
      deleteSaga<ServiceMaintenanceCardDto>(payload)
  ),
];

export default serviceMaintenanceCardsSaga;
