import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { OrderShippingCompanyDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getOrderShippingCompanyList = createAction(
  "GET_ORDER_SHIPPING_COMPANY_LIST",
  ({ payload, params }: SagaActionParams<OrderShippingCompanyDto[]>) => ({
    payload,
    url: "/api/ordershippingcompanies" + (params ? params : ""),
  })
);
export const getOrderShippingCompanyDetails = createAction(
  "GET_ORDER_SHIPPING_COMPANY_DETAILS",
  ({ payload, id }: SagaActionParams<OrderShippingCompanyDto>) => ({
    payload,
    url: "/api/ordershippingcompanies/" + id,
  })
);
export const createOrderShippingCompany = createAction(
  "CREATE_ORDER_SHIPPING_COMPANY_DETAILS",
  ({ payload, body }: SagaActionParams<OrderShippingCompanyDto>) => ({
    payload,
    url: "/api/ordershippingcompanies",
    body,
  })
);
export const updateOrderShippingCompany = createAction(
  "UPDATE_ORDER_SHIPPING_COMPANY_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderShippingCompanyDto>) => ({
    payload,
    url: "/api/ordershippingcompanies/" + id,
    body,
  })
);
export const deleteOrderShippingCompany = createAction(
  "DELETE_ORDER_SHIPPING_COMPANY_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderShippingCompanyDto>) => ({
    payload,
    url: "/api/ordershippingcompanies/" + id,
    body,
  })
);
const orderShippingCompanySaga = [
  takeLatest(
    getOrderShippingCompanyList.toString(),
    (payload: SagaGenericParams<OrderShippingCompanyDto[]>) =>
      getListSaga<OrderShippingCompanyDto[]>(payload)
  ),
  takeLatest(
    getOrderShippingCompanyDetails.toString(),
    (payload: SagaGenericParams<OrderShippingCompanyDto>) =>
      getListSaga<OrderShippingCompanyDto>(payload)
  ),
  takeLatest(
    createOrderShippingCompany.toString(),
    (payload: PostSagaGenericParams<OrderShippingCompanyDto>) =>
      postSaga<OrderShippingCompanyDto>(payload)
  ),
  takeLatest(
    updateOrderShippingCompany.toString(),
    (payload: PostSagaGenericParams<OrderShippingCompanyDto>) =>
      putSaga<OrderShippingCompanyDto>(payload)
  ),
  takeLatest(
    deleteOrderShippingCompany.toString(),
    (payload: SagaGenericParams<OrderShippingCompanyDto>) =>
      deleteSaga<OrderShippingCompanyDto>(payload)
  ),
];

export default orderShippingCompanySaga;
