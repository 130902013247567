import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";

const CalllogCard = ({ data }: { data: CustomerCallLogsDto }) => {
  const { t } = useTranslation();
  const table = [
    {
      label: t("Ana Konusu"),
      value: data.CustomerCallLogParentTopicName,
    },
    {
      label: t("Konu"),
      value: data.CustomerCallLogSubTopicName,
    },
    {
      label: t("Arama Tipi"),
      value: data.CustomerCallLogTypeName,
    },

    {
      label: t("İletişim Kanalı"),
      value: data.CallLogChannelName ?? "-",
    },
    {
      label: t("Not"),
      value: data.Not,
    },
  ];
  return (
    <React.Fragment>
      <Card className="">
        <CardHeader className="mb-0 py-2">Arama Bilgileri</CardHeader>
        <CardBody className="mb-0 py-2" style={{ fontSize: 14 }}>
          <Table className="mb-0 table-borderless">
            {table.map((x, i) => (
              <tr key={i}>
                <th style={{ minWidth: 100 }}>{x.label}</th>
                <td>{x.value}</td>
              </tr>
            ))}
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default CalllogCard;
