import { TextBox } from "devextreme-react";
import { Card, Col, Row } from "reactstrap";
import { CorporateClientsDto } from "src/store/corporate-clients/type";

const CorporateClientDetailCard = ({ data }: { data: CorporateClientsDto }) => {
  const dataFields = [
    {
      label: "Firma",
      value: data.CompanyName,
      col: 12,
    },
    {
      label: "Durum",
      value: data.CorporateClientStatusName,
    },
    {
      label: "Tip",
      value: data.CorporateTypeName,
    },

    {
      label: "Yetkili Kişi",
      value: data.AuthorizedPerson,
      col: 12,
    },
    {
      label: "Telefon",
      value: data.Phone,
    },
    {
      label: "E-posta",
      value: data.Email,
    },
    {
      label: "Vergi Dairesi",
      value: data.TaxOffice,
    },
    {
      label: "Vergi No",
      value: data.TaxId,
    },
    {
      label: "Address",
      value: data.Address,
      col: 12,
    },
    {
      label: "İlçe/İl",
      value: data.TownTownName + " / " + data.CityCityName,
    },
  ];

  return (
    <Card className="border-0 mt-3">
      <Row>
        {dataFields.map(x => (
          <Col key={x.label} md={x.col ?? 6} className="mb-3">
            <TextBox
              label={x.label}
              value={x.value ?? "-"}
              readOnly
              stylingMode="filled"
              style={{ borderWidth: 0 }}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
};
export default CorporateClientDetailCard;
