import { Loader as LoaderRs } from "rsuite";
interface Props {
  style?: React.CSSProperties;
  content?: string;
}
const Loader = ({ style, content = "" }: Props) => {
  return (
    <div
      style={{
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        position: "fixed",
        zIndex: 1051,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#3b3b3bCC",
        ...(style ?? {}),
      }}
    >
      <LoaderRs size="md" />
    </div>
  );
};

export default Loader;
