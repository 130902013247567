import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Nav, NavItem, Row, NavLink } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import FileUploader from "src/components/file-upload";
import { getCustomerCallLogsTicketUsers } from "src/store/customer-calllog/saga";
import { getCustomerLookupList } from "src/store/customers/saga";
import { CustomerLookupDto } from "src/store/customers/type";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import { CustomerVehicleDto } from "src/store/customervehicles/type";
import {
  getParentTicketSubjectList,
  getTicketSubSubjectList,
} from "src/store/ticket-subject/saga";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import {
  createCallLogTicket,
  getTicketChannels,
  getTicketLevels,
  getTicketTypes,
} from "src/store/ticket/saga";
import {
  TicketChannelDto,
  TicketDetailDto,
  TicketLevelDto,
  TicketType,
} from "src/store/ticket/type";
import { UserDto } from "src/store/user/type";
import TicketNotCustomerInputs from "./notCustomerInputs";
import { getCustomerAddressListByCustomerId } from "src/store/customeraddress/saga";
import { CustomerAddressDto } from "src/store/customeraddress/type";
import * as Yup from "yup";
import { useHistory } from "react-router";
import Loader from "src/components/Loader";

const SubComponentForCurrentCustomer = ({
  open,
  setVehicles,
  setAddress,
  setLoader,
}: {
  open: boolean;
  setLoader: (loading: boolean) => void;
  setAddress: (address: CustomerAddressDto[]) => void;
  setVehicles: (vehicle: CustomerVehicleDto[]) => void;
}) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext<any>();
  useEffect(() => {
    if (values.CustomerId && open) {
      setLoader(true);
      dispatch(
        getCustomerVehicleListByCustomerId({
          payload: {
            onSuccess: (msg, payload) => {
              setVehicles(payload);
              setLoader(false);
              setFieldValue(
                "VehicleId",
                payload.length > 0 ? payload[0].Id : ""
              );
            },
            onError: () => {},
          },
          id: values.CustomerId,
        })
      );
      dispatch(
        getCustomerAddressListByCustomerId({
          payload: {
            onSuccess: (msg, payload) => {
              setAddress(payload);
              setFieldValue(
                "AddressId",
                payload.length > 0 ? payload[0].Id : ""
              );
            },
            onError: () => {},
          },
          id: values.CustomerId,
        })
      );
    } // eslint-disable-next-line
  }, [open, dispatch, values.CustomerId]);
  return <></>;
};
const OpenCallLogModal = ({
  show,
  onHide,
  onSuccessSubmit,
  customerId,
}: {
  customerId?: string;
  show: boolean;
  onHide: () => void;
  onSuccessSubmit?: (ticket: TicketDetailDto) => void;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const [customers, setCustomers] = useState<CustomerLookupDto[]>([]);
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const [subjects, setSubjects] = useState<TicketSubjectDto[]>([]);
  const [searchSubjects, setSearchSubjects] = useState<TicketSubjectDto[]>([]);

  const [subsubjects, setSubSubjects] = useState<TicketSubjectDto[]>([]);
  const [levels, setLevels] = useState<TicketLevelDto[]>([]);
  const [address, setAddress] = useState<CustomerAddressDto[]>([]);
  const [channels, setChannels] = useState<TicketChannelDto[]>([]);
  const [users, setUsers] = useState<UserDto[]>([]);
  useEffect(() => {
    if (show) {
      setLoader(true);
      dispatch(
        getTicketLevels({
          payload: {
            onSuccess: (msg, py) => {
              setLevels(py);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketChannels({
          payload: {
            onSuccess: (msg, py) => {
              setChannels(py);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCustomerCallLogsTicketUsers({
          payload: {
            onSuccess: (msg, py) => {
              setUsers(py);
            },
            onError: () => {},
          },
          params: "?Skip=0&Take=1000000",
        })
      );
      dispatch(
        getCustomerLookupList({
          payload: {
            onSuccess: (msg, py) => {
              setCustomers(py);
              setLoader(false);
            },
            onError: () => {},
          },
          params: "?Skip=0&Take=1000000",
        })
      );
      dispatch(
        getParentTicketSubjectList({
          payload: {
            onSuccess: (msg, py) => {
              setSubjects(py);
              setSearchSubjects(py);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getTicketTypes({
          payload: {
            onSuccess: (msg, py) => setTicketTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, show]);

  const menu = [
    {
      key: 1,
      title: "Kayıtlı Üye Çağrı Kaydı",
    },
    {
      key: 2,
      title: "Üye Olmayan Çağrı Kaydı",
    },
  ];
  function renderAddress(label: any, item: any) {
    return item ? (
      <>{`${item.Name} - ${item.Address} ${item.TownName}/${item.CityName}`}</>
    ) : (
      label
    );
  }
  function renderCustomer(label: any, item: any) {
    return item ? (
      <>{`${item.Name} ${item.Surname} - ${item.PhoneNumber}`}</>
    ) : (
      label
    );
  }
  function renderVehicle(label: any, item: any) {
    return item ? (
      <>{`${item.Plate} - ${item.make} ${item.model ?? ""} ${
        item.regdate ?? ""
      } ${item.ChassisNumber ?? ""}`}</>
    ) : (
      label
    );
  }

  return (
    <CustomModal onHide={onHide} open={show} title={"ÇAĞRI"} size="lg">
      {loader && <Loader />}
      <Formik
        onSubmit={values => {
          let body: any = {
            ...values,
            vehicleData: "",
          };
          if (!values.CustomerId) {
            body = {
              ...values,
              vehicleData: "",
              TicketCustomer: {
                Name: values.Name ?? "",
                Surname: values.Surname ?? "",
                PhoneNumber: values.PhoneNumber ?? "",
                PhoneNumber2: values.PhoneNumber2 ?? "",
                VehiclePlate: values.VehiclePlate ?? "",
                KmLimit: values.KmLimit ?? "",
                Address: values.Address ?? "",
                TownId: values.TownId ?? "",
                vehicleData: "" ?? "",
                Brand: values.make ?? "",
                Model: values.model ?? "",
                ModelYear: values.regdate ?? "",
              },
            };
          }

          dispatch(
            createCallLogTicket({
              payload: {
                onSuccess: (ms, payload) => {
                  toast.success("Talep başarıyla oluşturuldu");
                  onSuccessSubmit && onSuccessSubmit(payload);
                  history.push("/ticket/all?ticketId=" + payload.Id);
                  onHide();
                },
                onError: m => {
                  toast.error(m);
                },
              },
              body: body,
            })
          );
        }}
        initialValues={{
          Type: 1,
          CustomerId: customerId ?? "",
          VehicleId: "",
          AddressId: "",
          TicketTypeId: 1,
          TicketLevelId: 1,
          TicketChannelId: "",
          SubjectId: "",
          ParentSubjectId: "",
          Message: "",
          Documents: [],
          Images: [],
          ResponsibleUserId: "",
          //üye olmayan
          Name: "",
          Surname: "",
          PhoneNumber: "",
          PhoneNumber2: "",
          VehiclePlate: "",
          KmLimit: "",
          Address: "",
          TownId: "",
          //vehicle
          make: "",
          model: "",
          regdate: "",
          category: "",
          DataArray: [],
          CurrentLocationTownId: "",
          CurrentLocationAddress: "",
          vehicleData: null,
          //TICKETTT
        }}
        validationSchema={Yup.object().shape({
          TicketTypeId: Yup.number().required(t("Required")),
          SubjectId: Yup.string().required(t("Required")),
          TicketChannelId: Yup.string().required(t("Required")),
          Message: Yup.string().required(t("Required")),
          ParentSubjectId: Yup.string().required(t("Required")),
        })}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <>
            <SubComponentForCurrentCustomer
              open={show}
              setAddress={setAddress}
              setVehicles={setVehicles}
              setLoader={setLoader}
            />
            <Nav justified className="mb-4 border-bottom" role="tablist">
              {menu.map(y => {
                return (
                  <NavItem key={y.key}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={
                        values.Type === y.key ? "bg-soft-info" : "bg-white"
                      }
                      onClick={() => setFieldValue("Type", y.key)}
                    >
                      {y.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <Row className="mx-3">
              <GetInput
                inputprop={{
                  col: 6,
                  field: "TicketTypeId",
                  label: t("Talep Tipi"),
                  inputType: InputType.multiselect,
                  lookup: {
                    data: ticketTypes,
                    labelKey: "Name",
                    valueKey: "Id",
                  },
                  onChange(value, item, setFieldValue, values) {
                    value &&
                      setSubjects(
                        searchSubjects.filter(x => x.TicketTypeId === value)
                      );
                  },
                }}
              />
              <GetInput
                inputprop={{
                  col: 6,
                  field: "ParentSubjectId",
                  label: t("Ana Konu"),
                  inputType: InputType.multiselect,
                  lookup: {
                    data: subjects,
                    labelKey: "Subject",
                    valueKey: "Id",
                  },
                  onChange(value, item, setFieldValue, values) {
                    value &&
                      dispatch(
                        getTicketSubSubjectList({
                          payload: {
                            onSuccess: (message, payload) => {
                              setSubSubjects(payload);
                            },
                            onError: () => {},
                          },
                          id: value,
                        })
                      );
                  },
                }}
              />
              <GetInput
                inputprop={{
                  col: 6,
                  field: "SubjectId",
                  label: t("Alt Konu"),
                  inputType: InputType.multiselect,
                  lookup: {
                    data: subsubjects,
                    labelKey: "Subject",
                    valueKey: "Id",
                  },
                }}
              />
              <GetInput
                inputprop={{
                  col: 6,
                  field: "TicketChannelId",
                  label: t("İletişim Kanalı"),
                  inputType: InputType.multiselect,
                  lookup: {
                    data: channels,
                    labelKey: "Name",
                    valueKey: "Id",
                  },
                }}
              />
              <GetInput
                inputprop={{
                  col: 6,
                  field: "TicketLevelId",
                  label: t("Önem Derecesi"),
                  inputType: InputType.multiselect,
                  lookup: {
                    data: levels,
                    labelKey: "Name",
                    valueKey: "EnumId",
                  },
                }}
              />

              {values.Type === 1 && (
                <>
                  <GetInput
                    inputprop={{
                      field: "CustomerId",
                      label: t("Customer"),
                      inputType: InputType.multiselect,
                      lookup: {
                        data: customers,
                        labelKey: "Name",
                        valueKey: "Id",
                        renderValue: renderCustomer,
                        renderMenuItem: renderCustomer,
                      },
                    }}
                  />
                  <GetInput
                    inputprop={{
                      field: "VehicleId",
                      label: t("Vehicle"),
                      inputType: InputType.multiselect,
                      lookup: {
                        data: vehicles,
                        labelKey: "Plate",
                        valueKey: "Id",
                        renderValue: renderVehicle,
                        renderMenuItem: renderVehicle,
                      },
                    }}
                  />
                  <GetInput
                    inputprop={{
                      field: "AddressId",
                      label: t("Address"),
                      inputType: InputType.multiselect,
                      lookup: {
                        data: address,
                        labelKey: "Name",
                        valueKey: "Id",
                        renderValue: renderAddress,
                        renderMenuItem: renderAddress,
                      },
                    }}
                  />
                </>
              )}

              <TicketNotCustomerInputs values={values} show={show} />

              <GetInput
                inputprop={{
                  col: 12,
                  field: "ResponsibleUserId",
                  inputType: InputType.multiselect,
                  label: t("Sorumlu Kişi"),
                  lookup: {
                    data: users,
                    labelKey: "FullName",
                    valueKey: "Id",
                    placeholder: t("Sorumlu Kişi Seçiniz"),
                  },
                }}
              />
              <GetInput
                inputprop={{
                  field: "Message",
                  label: t("Message"),
                  inputType: InputType.multilinetext,
                }}
              />
              <Col md={12}>
                <FileUploader
                  className=" btn-light ms-1 w-100"
                  onChange={files => {
                    setFieldValue("Documents", files);
                  }}
                  title={t("Tickets.Documents")}
                />
                {values.Documents?.map((x: any) => (
                  <div className="font-size-12 badge bg-light">
                    <span
                      className="fw-bold me-2"
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFieldValue(
                          "Documents",
                          values.Documents.filter((y: any) => y.name !== x.name)
                        )
                      }
                    >
                      X
                    </span>
                    {x.name}
                  </div>
                ))}{" "}
                <FileUploader
                  className=" btn-light ms-1 mt-2 w-100"
                  onChange={files => {
                    setFieldValue("Images", files);
                  }}
                  title={t("Tickets.Images")}
                />
                {values.Images?.map((x: any) => (
                  <div className="font-size-12 badge bg-light">
                    <span
                      className="fw-bold me-2"
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setFieldValue(
                          "Images",
                          values.Images.filter((y: any) => y.name !== x.name)
                        )
                      }
                    >
                      X
                    </span>
                    {x.name}
                  </div>
                ))}
              </Col>
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("Save")}
                </Button>
              </div>
            </Row>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};
export default OpenCallLogModal;
