import { call, put, takeLatest } from "redux-saga/effects";
import { ApiResponse, verfiyResponse } from "../base/types/saga";
import { SocketNotificationDto } from "./type";
import { BodyType, createRequestSaga } from "../base/request/request";
import notificationRedux from ".";

function* getNotificationSaga() {
  try {
    const response: ApiResponse<SocketNotificationDto[]> = yield call(
      createRequestSaga,
      {
        method: "GET",
        url: "/api/socketnotifications",
      }
    );
    if (verfiyResponse(response)) {
      yield put(notificationRedux.actions.setNotifications(response.Result));
    } else {
    }
  } catch (e) {
    console.log("ERROR languages Saga", e);
  }
}

function* readNotificationSaga(data: any) {
  try {
    const response: ApiResponse<SocketNotificationDto[]> = yield call(
      createRequestSaga,
      {
        method: "PUT",
        url: "/api/socketnotifications/read",
        body: {
          id: data.payload,
        },
        bodyType: BodyType.raw,
      }
    );
    if (verfiyResponse(response)) {
      yield put(notificationRedux.actions.getNotifications());
    } else {
    }
  } catch (e) {
    console.log("ERROR languages Saga", e);
  }
}

const socketNotificationSaga = [
  takeLatest(
    notificationRedux.actions.getNotifications.toString(),
    getNotificationSaga
  ),
  takeLatest(
    notificationRedux.actions.readNotification.toString(),
    (payload: string) => readNotificationSaga(payload)
  ),
];

export default socketNotificationSaga;
