import { call, put } from "@redux-saga/core/effects";
import auth from "../../auth";
import loader from "../../loader";
import { createRequestSaga } from "./request";
import { ApiCallback, ApiResponse, verfiyResponse } from "../types/saga";

export interface SagaGenericParams<Type> {
  type: string;
  payload: ApiCallback<Type>;
  url?: string;
  id?: string;
}

export function* getListSaga<Type>({ payload, url }: SagaGenericParams<Type>) {
  yield put(loader.actions.showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "GET",
      url: url || "",
    });
    if (verfiyResponse(response))
      payload.onSuccess(
        response.Message,
        response.Result,
        response.ResultDetail,
        response.Translates
      );
    else if (response.Status === 11) {
      window.location.replace("/");
      yield put(auth.actions.logoutAction());
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR getListSaga", e);
  } finally {
    yield put(loader.actions.hideLoader());
  }
}
