import { useState } from "react";
import Lightbox from "react-image-lightbox";
import { Card, Col, Row } from "reactstrap";
import images from "src/assets/image";

const ImageCard = ({ src, title }: { src: any; title: string }) => {
  const [isFits, setisFits] = useState<string>();

  return (
    <>
      {isFits ? (
        <Lightbox
          mainSrc={src}
          enableZoom={false}
          imageCaption={""}
          onCloseRequest={() => {
            setisFits("");
          }}
        />
      ) : null}
      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
        <div className="p-2">
          <Row className="align-items-center">
            <Col className="col-auto">
              <img
                style={{ cursor: "pointer" }}
                data-dz-thumbnail=""
                onClick={() => {
                  return setisFits(src);
                }}
                height="120"
                className="avatar-sm rounded bg-light"
                src={src}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = images.noimage;
                }}
              />
            </Col>
            <Col>
              <p className="mb-0">{<strong>{title}</strong>}</p>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};
export default ImageCard;
