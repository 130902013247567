import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { TireDto } from "src/store/tires/tires/type";
import * as Yup from "yup";
const DetailsTire = ({
  data,
  onHide,
  open,
}: {
  data: TireDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Lastik Detay"),
        }}
        hideButtons
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name ?? "",
          Brand: data.TireBrandName,
          TireTypeName: data.TireTypeName,
          Size: `${data.TireTreadWidthValue} x ${data.TireSidewallWidthValue} x ${data.TireDiameterValue}`,
          Description: data.Description ?? "",
          ImageFile: "",
          CurrentImageFile: data.Image,
          UnitPrice: data.UnitPrice,
          TaxRate: data.TaxRate,
          Stock: data.Stock,
          FreeShipping: data.FreeShipping,
          FreeAssembly: data.FreeAssembly,
        }}
        onSubmit={values => {}}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            disabled: () => true,
            field: "Name",
            label: t("Name"),
          },
          {
            disabled: () => true,
            field: "Brand",
            label: t("Marka"),
          },
          {
            disabled: () => true,
            field: "TireTypeName",
            label: t("Lastik Tipi"),
            col: 6,
          },
          {
            disabled: () => true,
            field: "Size",
            col: 6,
            label: t("Lastik Boyutu"),
          },
          {
            disabled: () => true,
            col: 4,
            field: "UnitPrice",
            inputType: InputType.number,
            label: t("Birim Fiyat"),
          },
          {
            disabled: () => true,
            col: 4,
            field: "TaxRate",
            inputType: InputType.number,
            label: t("Vergi Oranı"),
          },
          {
            disabled: () => true,
            col: 4,
            field: "Stock",
            inputType: InputType.number,
            label: t("Stok"),
          },
          {
            disabled: () => true,
            field: "Description",
            inputType: InputType.multilinetext,
            label: t("Description"),
          },
          {
            disabled: () => true,
            field: "FreeShipping",
            col: 6,
            inputType: InputType.checkbox,
            label: t("Ücretsiz Nakliye"),
          },
          {
            disabled: () => true,
            col: 6,
            field: "FreeAssembly",
            inputType: InputType.checkbox,
            label: t("Ücretsiz Montaj"),
          },
          {
            disabled: () => true,
            field: "ImageFile",
            label: t("Ana Resim"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentImageFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default DetailsTire;
