import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import { getDataBankCategoryList } from "src/store/databank-category/saga";
import { DataBankCategoryDto } from "src/store/databank-category/type";
import { createDataBank } from "src/store/databank/saga";
import * as Yup from "yup";
import { useHistory } from "react-router";

const DatabankCreatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<DataBankCategoryDto[]>([]);
  useEffect(() => {
    dispatch(
      getDataBankCategoryList({
        payload: {
          onSuccess: (msg, py) => setCategories(py),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Bilgi Bankası")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Bilgi Bankası")}
            breadcrumbItem={t("Yeni Bilgi Bankası")}
          />

          <Row className="mt-4">
            <CustomFormik
              loader
              initialValues={{
                DataBankCategoryId: "",
                Question: "",
                Answer: "",
              }}
              onSubmit={values => {
                dispatch(
                  createDataBank({
                    payload: {
                      onSuccess: (message, payload) => {
                        toast.success(message, {
                          theme: "colored",
                        });
                        history.push("/databanks/" + payload.Id);
                      },
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: values,
                  })
                );
              }}
              validationSchema={Yup.object().shape({
                DataBankCategoryId: Yup.string().required(t("Required")),
                Question: Yup.string().required(t("Required")),
                Answer: Yup.string().required(t("Required")),
              })}
              inputs={[
                {
                  field: "DataBankCategoryId",
                  inputType: InputType.multiselect,
                  label: t("Category Name"),
                  lookup: {
                    data: categories,
                    labelKey: "CategoryName",
                    valueKey: "Id",
                    placeholder: t("Choose Category"),
                  },
                },
                {
                  field: "Question",
                  label: t("Question"),
                },
                {
                  field: "Answer",
                  label: t("Answer"),
                  inputType: InputType.editor,
                },
              ]}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DatabankCreatePage;
