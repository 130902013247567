import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormModalComp } from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getOrderShippingCompanyList } from "src/store/orders/order-shipping-companies/saga";
import { OrderShippingCompanyDto } from "src/store/orders/order-shipping-companies/type";
import { sendPrivilegeCard } from "src/store/privilegecard/saga";
import * as Yup from "yup";

const CardSendForm = ({
  cardId,
  onHide,
  open,
  onSuccessClick,
}: {
  cardId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [shippingCompanies, setShippingCompanies] = useState<
    OrderShippingCompanyDto[]
  >([]);
  useEffect(() => {
    dispatch(
      getOrderShippingCompanyList({
        payload: {
          onSuccess: (msg, payload) => setShippingCompanies(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModalComp
        modalProps={{
          size: "xs",
          open: open,
          onHide: onHide,
          title: t("Kartı Gönder"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CardId: cardId,
          OrderShippingCompanyId: "",
          TrackingNumber: "",
        }}
        onSubmit={values => {
          dispatch(
            sendPrivilegeCard({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          CardId: Yup.string().required(t("Required")),
          OrderShippingCompanyId: Yup.string().required(t("Required")),
          TrackingNumber: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "TrackingNumber",
            label: "Takip Numarası*",
          },
          {
            inputType: InputType.multiselect,
            field: "OrderShippingCompanyId",
            label: "Kargo Firması*",
            lookup: {
              data: shippingCompanies,
              labelKey: "Name",
              valueKey: "Id",
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CardSendForm;
