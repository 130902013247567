import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import {
  addCustomerToBlackList,
  checkCustomerBlackList,
} from "src/store/black-lists/saga";
import { CustomerDetailDto } from "src/store/customers/type";

const AddBlackListModal = ({
  customerData,
  onSuccessSubmit,
}: {
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const [showHonorary, setShowHonorary] = useState(false);
  const [isBlackList, setIsBlackList] = useState(false);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(
      checkCustomerBlackList({
        payload: {
          onSuccess: (m, p) => {
            setIsBlackList(p);
          },
          onError: () => {},
        },
        id: customerData.Id,
      })
    );
  }, [dispatch, customerData.Id]);
  return (
    <React.Fragment>
      {isBlackList ? (
        <button className="btn btn-secondary bg-black btn mt-2" disabled>
          KARA LİSTE
        </button>
      ) : (
        <>
          <button
            className="btn btn-danger btn mt-2"
            onClick={() => setShowHonorary(true)}
          >
            Kara Listeye Al
          </button>
          {customerData && showHonorary && (
            <CancelAlert
              message={`Kara listeye almak istediğinize emin misiniz?`}
              onConfirm={() => {
                dispatch(
                  addCustomerToBlackList({
                    payload: {
                      onSuccess: message => {
                        setShowHonorary(false);
                        toast.success(message);
                        setIsBlackList(true);
                        onSuccessSubmit();
                      },
                      onError: message => {
                        setShowHonorary(false);
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: {
                      CustomerId: customerData.Id,
                    },
                  })
                );
              }}
              onCancel={() => {
                setShowHonorary(false);
              }}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
export default AddBlackListModal;
