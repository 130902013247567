import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import { DataBankDto } from "src/store/databank/type";
import { DataBankCategoryDto } from "src/store/databank-category/type";
import { getDataBankCategoryList } from "src/store/databank-category/saga";
import { getDataBankDetails, updateDataBank } from "src/store/databank/saga";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const DatabankEditPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<DataBankCategoryDto[]>([]);
  const [data, setData] = useState<DataBankDto>();
  useEffect(() => {
    dispatch(
      getDataBankCategoryList({
        payload: {
          onSuccess: (msg, py) => setCategories(py),
          onError: () => {},
        },
      })
    );
    dispatch(
      getDataBankDetails({
        payload: {
          onSuccess: (msg, py) => setData(py),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Bilgi Bankası")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        {loading && <Loader />}
        {data && (
          <Container fluid>
            <Breadcrumbs
              title={t("Bilgi Bankası")}
              breadcrumbItem={"Bilgi Bankası Düzenle"}
            />

            <Row className="mt-4">
              <CustomFormik
                loader
                initialValues={{
                  Id: data.Id,
                  DataBankCategoryId: data.DataBankCategoryId,
                  Question: data.Question,
                  Answer: data.Answer,
                }}
                onSubmit={values => {
                  dispatch(
                    updateDataBank({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          return setData(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                      id: data.Id,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  DataBankCategoryId: Yup.string().required(t("Required")),
                  Question: Yup.string().required(t("Required")),
                  Answer: Yup.string().required(t("Required")),
                })}
                inputs={[
                  {
                    field: "DataBankCategoryId",
                    inputType: InputType.multiselect,
                    label: t("Category Name"),
                    lookup: {
                      data: categories,
                      labelKey: "CategoryName",
                      valueKey: "Id",
                      placeholder: t("Choose Category"),
                    },
                  },
                  {
                    field: "Question",
                    label: t("Question"),
                  },
                  {
                    field: "Answer",
                    label: t("Answer"),
                    inputType: InputType.editor,
                  },
                ]}
              />
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};
export default DatabankEditPage;
