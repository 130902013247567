import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { PhoneNumberDto, PhoneNumberTypeDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";
export const getPhoneNumberTypeList = createAction(
  "GET_PHONE_NUMBER_TYPE_LIST",
  ({ payload, params }: SagaActionParams<PhoneNumberTypeDto[]>) => ({
    payload,
    url: "/api/system/phonenumber-types" + (params ? params : ""),
  })
);
export const getPhoneNumberList = createAction(
  "GET_PHONE_NUMBER_LIST",
  ({ payload, params }: SagaActionParams<PhoneNumberDto[]>) => ({
    payload,
    url: "/api/phonenumbers" + (params ? params : ""),
  })
);
export const getPhoneNumberDetails = createAction(
  "GET_PHONE_NUMBER_DETAILS",
  ({ payload, id }: SagaActionParams<PhoneNumberDto>) => ({
    payload,
    url: "/api/phonenumbers/" + id,
  })
);
export const createPhoneNumber = createAction(
  "CREATE_PHONE_NUMBER_DETAILS",
  ({ payload, body }: SagaActionParams<PhoneNumberDto>) => ({
    payload,
    url: "/api/phonenumbers",
    body,
  })
);
export const updatePhoneNumber = createAction(
  "UPDATE_PHONE_NUMBER_DETAILS",
  ({ payload, body, id }: SagaActionParams<PhoneNumberDto>) => ({
    payload,
    url: "/api/phonenumbers/" + id,
    body,
  })
);
export const deletePhoneNumber = createAction(
  "DELETE_PHONE_NUMBER_DETAILS",
  ({ payload, body, id }: SagaActionParams<PhoneNumberDto>) => ({
    payload,
    url: "/api/phonenumbers/" + id,
    body,
  })
);

const phoneNumberSaga = [
  takeLatest(
    getPhoneNumberList.toString(),
    (payload: SagaGenericParams<PhoneNumberDto[]>) =>
      getListSaga<PhoneNumberDto[]>(payload)
  ),
  takeLatest(
    getPhoneNumberTypeList.toString(),
    (payload: SagaGenericParams<PhoneNumberDto[]>) =>
      getListSaga<PhoneNumberDto[]>(payload)
  ),
  takeLatest(
    getPhoneNumberDetails.toString(),
    (payload: SagaGenericParams<PhoneNumberDto>) =>
      getListSaga<PhoneNumberDto>(payload)
  ),
  takeLatest(
    createPhoneNumber.toString(),
    (payload: PostSagaGenericParams<PhoneNumberDto>) =>
      postSaga<PhoneNumberDto>(payload)
  ),

  takeLatest(
    updatePhoneNumber.toString(),
    (payload: PostSagaGenericParams<PhoneNumberDto>) =>
      putSaga<PhoneNumberDto>(payload)
  ),
  takeLatest(
    deletePhoneNumber.toString(),
    (payload: SagaGenericParams<PhoneNumberDto>) =>
      deleteSaga<PhoneNumberDto>(payload)
  ),
];

export default phoneNumberSaga;
