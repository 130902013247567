import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/drawer";
import { getRoles } from "src/store/lookup/saga";
import { RoleDto } from "src/store/lookup/type";
import { updateUserRoleList } from "src/store/user/saga";

import * as Yup from "yup";
const RoleEditModal = ({
  onHide,
  open,
  onSuccessClick,
  roles,
  id,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
  roles: string[];
  id: string;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [assignableRoles, setAssignableRoles] = useState<RoleDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getRoles({
          payload: {
            onSuccess: (msg, payload) => setAssignableRoles(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <CustomModal open={open} onHide={onHide} title={"Kullanıcı Role Düzenle"}>
      <CustomFormik
        onHide={onHide}
        loader
        initialValues={{
          Roles: roles,
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {
          dispatch(
            updateUserRoleList({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: roles
                .map(x => {
                  let assign = true;
                  if (!values.Roles.includes(x)) {
                    assign = false;
                  }
                  return {
                    roleName: x,
                    hasAssign: assign,
                  };
                })
                .concat(
                  values.Roles.filter((y: any) => !roles.includes(y)).map(
                    (x: any) => {
                      return {
                        roleName: x,
                        hasAssign: true,
                      };
                    }
                  )
                ),
              id: id,
            })
          );
        }}
        inputs={[
          {
            field: "Roles",
            inputType: InputType.checkpicker,
            label: t("Roller"),
            lookup: {
              data: assignableRoles,
              labelKey: "Name",
              valueKey: "Name",
              placeholder: "Rol Seçiniz",
            },
          },
        ]}
      />
    </CustomModal>
  );
};

export default RoleEditModal;
