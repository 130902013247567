import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import {
  AgreementTypeDto,
  AgreementTypeKeyDto,
} from "src/store/agreement/type";
import {
  createAgreement,
  getAggrementKeys,
  getAgreementTypeList,
} from "src/store/agreement/saga";
import { useHistory } from "react-router";

const CreateAgreementsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [types, setTypes] = useState<AgreementTypeDto[]>([]);
  const [keys, setKeys] = useState<AgreementTypeKeyDto[]>([]);
  useEffect(() => {
    dispatch(
      getAgreementTypeList({
        payload: {
          onSuccess: (msg, py) => setTypes(py),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  console.log(keys);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("Yeni Sözleşme")}
            breadcrumbItem={t("Yeni Sözleşme")}
          />

          <Row className="mt-4">
            <CustomFormik
              loader
              initialValues={{
                AgreementTypeId: "",
                Title: "",
                Content: "",
                Notes: "",
              }}
              onSubmit={values => {
                dispatch(
                  createAgreement({
                    payload: {
                      onSuccess: (message, payload) => {
                        history.push("/agreements/details/" + payload.Id);
                      },
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: values,
                  })
                );
              }}
              validationSchema={Yup.object().shape({
                Title: Yup.string().required(t("Required")),
                Notes: Yup.string().required(t("Required")),
                AgreementTypeId: Yup.string().required(t("Required")),
                Content: Yup.string()
                  .required(t("Required"))
                  .test(
                    "KeyValidation",
                    "Anahtar sözcüklerin kullandığınızdan emin olunuz.[key] şeklinde içeriğe eklenmelidir!",
                    (text, prop) => {
                      let res = true;
                      if (text) {
                        keys
                          .map(x => x.Key)
                          .forEach((key: string) => {
                            if (!text.includes(`${key}`)) {
                              res = false;
                            }
                          });
                      }
                      return res;
                    }
                  ),
              })}
              inputs={[
                {
                  field: "AgreementTypeId",
                  inputType: InputType.multiselect,
                  label: t("Sözleşme Tipi"),
                  lookup: {
                    data: types,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Sözleşme Tipi Seçiniz"),
                  },
                  onChange: val => {
                    val &&
                      dispatch(
                        getAggrementKeys({
                          payload: {
                            onSuccess: (msg, py) => setKeys(py),
                            onError: () => {},
                          },
                          id: val,
                        })
                      );
                  },
                },

                {
                  field: "Notes",
                  label: t("Notlar"),
                  inputType: InputType.multilinetext,
                },
                {
                  field: "Title",
                  label: t("Title"),
                  inputType: InputType.multilinetext,
                },
                {
                  hide: () => keys.length <= 0,
                  field: "",
                  inputType: InputType.component,
                  label: t(""),
                  component: () => {
                    return (
                      <div>
                        <p>
                          Kullanılacak Anahtar Kelimeler: <br />
                          {keys.map(x => x.Key).toString()}
                        </p>
                      </div>
                    );
                  },
                },
                {
                  field: "Content",
                  label: t("İçerik"),
                  className: "lg-custom-wrapper",
                  inputType: InputType.editor,
                },
              ]}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateAgreementsPage;
