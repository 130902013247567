import React, { useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { baseImageUrl } from "src/store/base/types/url";
import Carousel from "src/components/Carousel";
import {
  OrderRefundRequest,
  OrderRefundRequestImage,
} from "src/store/orders/orders/type";
const OrderRefundRequestImageList = ({
  data,
}: {
  data: OrderRefundRequest;
}) => {
  const slider = useRef<any>();
  const [active, setActive] = useState<OrderRefundRequestImage>(
    data.OrderRefundRequestImages[0]
  );

  return (
    <React.Fragment>
      {!(
        data.OrderRefundRequestImages != null &&
        data.OrderRefundRequestImages.length > 0
      ) ? (
        <>
          <div className="cursor-pointer h-100 w-100 border border-dotted d-flex align-items-center justify-content-center flex-column py-5 text-muted fw-bold font-size-20">
            <i className=" dripicons-photo-group" />
            <div> NO PHOTO</div>
          </div>
        </>
      ) : (
        <Row className="my-2">
          <Col md={12}>
            {active && (
              <img
                src={baseImageUrl + active.Image}
                alt=""
                width={250}
                height={250}
                className="mx-auto d-block rounded"
              />
            )}
          </Col>
          <Col md={12} className="mt-3">
            <Carousel ref={slider} responsive={false}>
              {data.OrderRefundRequestImages.map(x => (
                <ProductImageCard
                  data={x}
                  setActive={setActive}
                  active={active}
                />
              ))}
            </Carousel>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
export default OrderRefundRequestImageList;

const ProductImageCard = ({
  data,
  active,
  setActive,
}: {
  data: OrderRefundRequestImage;
  active: OrderRefundRequestImage;
  setActive: (active: OrderRefundRequestImage) => void;
}) => {
  return (
    <>
      <img
        onClick={() => setActive(data)}
        key={data.Id}
        src={baseImageUrl + data.Image}
        alt="order"
        style={{ position: "relative" }}
        className={
          "pe-2 cursor-pointer " + (active.Id === data.Id ? "shadow " : " ")
        }
        width={50}
        height={50}
      />
    </>
  );
};
