import produce from "immer";
import { createAction } from "redux-smart-actions";
import { createSelector } from "reselect";
import { KomutRedux } from "../base/types/KomutRedux";
import { RootState } from "../base/types/RootState";
import { LanguageModel } from "./types";

const SET_LANGUAGE = "SET_LANGUAGE";
const SET_LANGUAGES = "SET_LANGUAGES";
const GET_LANGUAGES = "GET_LANGUAGES";

export interface LanguageReducer {
  languages: LanguageModel[];
  language: LanguageModel;
}

export interface LanguageActions {
  setLanguage: (payload: LanguageModel) => {};
  setLanguages: any;
  getLanguages: any;
}

const languageRedux = new KomutRedux<LanguageReducer, LanguageActions>();

languageRedux.setInitialState({
  language: {
    Code: "tr-TR",
    EnumId: "71173a68-8b7c-4560-bbb6-d98d7817b5ac",
    Name: "Türkçe",
  },
  languages: [],
});

languageRedux.setActions({
  setLanguages: createAction(SET_LANGUAGES, (payload: LanguageModel[]) => ({
    payload,
  })),
  setLanguage: createAction(SET_LANGUAGE, (payload: LanguageModel) => ({
    payload,
  })),
  getLanguages: createAction(GET_LANGUAGES),
});

languageRedux.setRedusers(
  produce((draft: LanguageReducer, action: any) => {
    switch (action.type) {
      case SET_LANGUAGE:
        draft.language = action.payload;
        break;
      case SET_LANGUAGES:
        draft.languages = action.payload;
        break;
    }
  }, languageRedux.initialState)
);

// ** SELECTORS *** \\
const languageState = (state: RootState) => state.language.language;
export const languageSelector = createSelector([languageState], state => state);

const languagesState = (state: RootState) => state.language.languages;
export const languagesSelector = createSelector(
  [languagesState],
  state => state
);

export default languageRedux;
