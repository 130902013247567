import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { SupplierDto } from "src/store/supplier/type";
import { SupplierDocumentDto } from "src/store/supplierdocuments/type";

const DetailTab = ({
  supplier,
  documents,
  setDocuments,
}: {
  documents: SupplierDocumentDto[];
  setDocuments: (list: SupplierDocumentDto[]) => void;
  supplier: SupplierDto;
}) => {
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const columns = [
    {
      header: t("Supplier Type"),
      content: supplier.SupplierType,
    },
    {
      header: t("Servis Tipi"),
      content: supplier.SupplierServiceStatusTypeName,
    },
    {
      header: t("Name"),
      content: supplier.Name,
    },
    {
      header: t("Brand"),
      content: supplier.Brand,
    },
    {
      header: t("Email"),
      content: supplier.Email,
    },
    {
      header: t("Yetkili"),
      content: supplier.PersonName + " " + supplier.PersonSurname,
    },
    {
      header: t("Tax Office"),
      content: supplier.TaxOffice,
    },
    {
      header: t("Tax Number"),
      content: supplier.TaxNumber,
    },
    {
      header: t("Bank Name"),
      content: supplier.BankName,
    },
    {
      header: t("IBAN"),
      content: supplier.Iban,
    },
    {
      header: t("Contract Start Date"),
      content: supplier.ContractStartDate,
    },
    {
      header: t("Contract End Date"),
      content: supplier.ContractEndDate,
    },
    {
      header: t("İl/İlçe"),
      content: supplier.TownName + "/" + supplier.CityName,
    },
    {
      header: t("Address"),
      content: supplier.Address,
    },
    {
      header: t("Api Vehicle Information"),
      content: supplier.ApiVehicleInformation ? "Evet" : "Hayır",
    },
    {
      header: t("Api Customer Information"),
      content: supplier.ApiCustomerInformation ? "Evet" : "Hayır",
    },
  ];

  return (
    <div className="mt-4 mx-3">
      {loader && <Loader />}
      <table
        className=" table-borderless col-md-12 col-lg-6"
        style={{ fontSize: 14 }}
      >
        {columns.map(x => (
          <tr className="mb-1">
            <th>{x.header}</th>
            <td>{x.content}</td>
          </tr>
        ))}
      </table>
      <hr />
      {/* <div className="mt-4 ">
        <h5>Doküman Listesi</h5>
        <Row>
          {documents.map(x => (
            <Col key={x.Id} md={6}>
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={"-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height={40}
                        className="avatar-sm rounded bg-light"
                        alt={x.Name}
                        src={baseImageUrl + x.Document}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = images.doc;
                        }}
                      />
                    </Col>
                    <Col>
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-muted font-weight-bold"
                        onClick={() => {
                          FileSaver.saveAs(baseImageUrl + x.Document, x.Name);
                        }}
                      >
                        {x.Name}
                      </span>
                    </Col>
                    <Col md={1}>
                      <p
                        className="mb-0 text-pr deleteHover cursor-pointer font-size-14"
                        onClick={() => {
                          FileSaver.saveAs(baseImageUrl + x.Document, x.Name);
                        }}
                      >
                        <strong>
                          <i className="bx bxs-download font-size-20" />
                        </strong>
                      </p>
                    </Col>
                    <Col md={1}>
                      <p
                        className="mb-0 text-danger deleteHover cursor-pointer"
                        onClick={() => {
                          dispatch(
                            deleteSupplierDocument({
                              payload: {
                                onSuccess: message => {
                                  toast.success("Doküman silindi");
                                  dispatch(
                                    getSupplierDocumentListBySupplierId({
                                      payload: {
                                        onSuccess: (msg, payload) =>
                                          setDocuments(payload),
                                        onError: () => {},
                                      },
                                      id: supplier.Id,
                                    })
                                  );
                                },
                                onError: message => {
                                  toast.error(message, {
                                    theme: "colored",
                                  });
                                },
                              },
                              id: x.Id,
                            })
                          );
                        }}
                      >
                        <strong>
                          <i className=" bx bx-trash-alt font-size-20"></i>
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
          {documents.length <= 0 && (
            <div className=" text-muted">Doküman bulunamadı</div>
          )}
        </Row>
      </div> */}
    </div>
  );
};
export default DetailTab;
