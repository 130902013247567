import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { CustomerAddressDto } from "src/store/customeraddress/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import * as Yup from "yup";

const DetailCustomerAddressModal = ({
  onHide,
  data,
  open,
}: {
  data: CustomerAddressDto;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, payload) => setCities(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTowns({
          payload: {
            onSuccess: (msg, payload) => setTowns(payload),
            onError: () => {},
          },
          id: data.CityId,
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Üye Adresi"),
        }}
        hideButtons
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          CustomerId: data.CustomerId,
          Name: data.Name ?? "",
          // CompanyName: data.CompanyName ?? "",
          // TaxNumber: data.TaxNumber ?? "",
          // TaxOffice: data.TaxOffice ?? "",
          PersonName: data.PersonName ?? "",
          PersonSurname: data.PersonSurname ?? "",
          IdentityNumber: data.IdentityNumber ?? "",
          PhoneNumber: data.PhoneNumber ?? "",
          Address: data.Address ?? "",
          CityId: data.CityId ?? "",
          TownId: data.TownId ?? "",
          Default: data.Default,
          Invoice: data.Invoice,
          InvoiceTypeId: data.InvoiceTypeId,
          AddressTypeName: data.CustomerAddressTypeName,
        }}
        onSubmit={values => {}}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            field: "AddressTypeName",
            label: t("Adres Tipi"),
            disabled: () => true,
          },
          {
            field: "Name",
            disabled: () => true,
            label: t("Adres Başlığı"),
          },
          {
            col: 6,
            disabled: () => true,
            field: "PersonName",
            label: t("Ad"),
          },
          {
            col: 6,
            disabled: () => true,
            field: "PersonSurname",
            label: t("Soyad"),
          },
          {
            col: 6,
            disabled: () => true,
            field: "PhoneNumber",
            label: t("Telefon Numarası"),
          },
          {
            col: 6,
            disabled: () => true,
            field: "IdentityNumber",
            label: t("Kimlik No"),
          },
          {
            col: 6,
            field: "CityId",
            disabled: () => true,
            inputType: InputType.multiselect,
            label: t("City"),
            lookup: {
              data: cities,
              labelKey: "CityName",
              valueKey: "Id",
              placeholder: t("Choose City"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            col: 6,
            disabled: () => true,
            field: "TownId",
            inputType: InputType.multiselect,
            label: t("Town"),
            lookup: {
              data: towns,
              labelKey: "TownName",
              valueKey: "Id",
              placeholder: t("Choose Town"),
            },
          },
          {
            field: "Address",
            label: t("Address"),
            disabled: () => true,
            inputType: InputType.multilinetext,
          },
          {
            field: "Default",
            disabled: () => true,
            label: t("Varsayılan Address"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default DetailCustomerAddressModal;
