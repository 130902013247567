import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getAllProductCategoryList } from "src/store/orders/product-categories/saga";
import { ProductCategoryDto } from "src/store/orders/product-categories/type";
import { createProductCategoryBanner } from "src/store/orders/product-category-banners/saga";
import { ProductCategoryBannerDto } from "src/store/orders/product-category-banners/type";
import { getProductList } from "src/store/orders/products/saga";
import { ProductDto } from "src/store/orders/products/type";
import * as Yup from "yup";

const CreateProductCategoryBannerForm = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: ProductCategoryBannerDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [products, setProducts] = useState<ProductDto[]>([]);
  const [categories, setCategories] = useState<ProductCategoryDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getAllProductCategoryList({
          payload: {
            onSuccess: (msg, py) => setCategories(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getProductList({
          payload: {
            onSuccess: (msg, py) => setProducts(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Banner Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          ProductId: data.ProductId ?? "",
          ProductCategoryId: data.ProductCategoryId ?? "",

          ImageFile: "",
          CurrentFile: data.Image && data.Image != null ? data.Image : "",

          Active: data.Active,
          ForProduct: data.ProductId ? true : false,
        }}
        onSubmit={values => {
          dispatch(
            createProductCategoryBanner({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            field: "ForProduct",
            label: t(""),
            col: 12,
            inputType: InputType.tabGroup,
            radiogrups: {
              data: [
                {
                  Id: false,
                  Label: "Kategoriye Yönlendir",
                },
                {
                  Id: true,
                  Label: "Ürüne Yönlendir",
                },
              ],
              labelKey: "Label",
              valueKey: "Id",
            },
          },
          {
            hide: values => values.ForProduct,
            field: "ProductCategoryId",
            inputType: InputType.multiselect,
            label: t("Ürün Kategorisi"),
            lookup: {
              data: categories,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Ürün Kategorisi Seçiniz"),
            },
            onChange(v, i, setFieldValue, values) {
              setFieldValue("ProductId", "");
            },
          },
          {
            hide: values => !values.ForProduct,
            field: "ProductId",
            inputType: InputType.multiselect,
            label: t("Ürün"),
            lookup: {
              data: products,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Ürün Seçiniz"),
            },
            onChange(v, i, setFieldValue, values) {
              setFieldValue("ProductCategoryId", "");
            },
          },
          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateProductCategoryBannerForm;
