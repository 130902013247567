import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireDto, TireTypeDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireList = createAction(
  "GET_TIRE_LIST",
  ({ payload, params }: SagaActionParams<TireDto[]>) => ({
    payload,
    url: "/api/tires" + (params ? params : ""),
  })
);
export const getTireDetails = createAction(
  "GET_TIRE_DETAILS",
  ({ payload, id }: SagaActionParams<TireDto>) => ({
    payload,
    url: "/api/tires/" + id,
  })
);
export const createTire = createAction(
  "CREATE_TIRE_DETAILS",
  ({ payload, body }: SagaActionParams<TireDto>) => ({
    payload,
    url: "/api/tires",
    body,
  })
);
export const updateTire = createAction(
  "UPDATE_TIRE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireDto>) => ({
    payload,
    url: "/api/tires/" + id,
    body,
  })
);
export const deleteTire = createAction(
  "DELETE_TIRE_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireDto>) => ({
    payload,
    url: "/api/tires/" + id,
    body,
  })
);
export const getTireTypeList = createAction(
  "GET_TIRE_TYPE_LIST",
  ({ payload }: SagaActionParams<TireTypeDto[]>) => ({
    payload,
    url: "/api/system/tire-types",
  })
);
const tiresSaga = [
  takeLatest(getTireList.toString(), (payload: SagaGenericParams<TireDto[]>) =>
    getListSaga<TireDto[]>(payload)
  ),
  takeLatest(
    getTireTypeList.toString(),
    (payload: SagaGenericParams<TireTypeDto[]>) =>
      getListSaga<TireTypeDto[]>(payload)
  ),
  takeLatest(getTireDetails.toString(), (payload: SagaGenericParams<TireDto>) =>
    getListSaga<TireDto>(payload)
  ),
  takeLatest(createTire.toString(), (payload: PostSagaGenericParams<TireDto>) =>
    postSaga<TireDto>(payload)
  ),

  takeLatest(updateTire.toString(), (payload: PostSagaGenericParams<TireDto>) =>
    putSaga<TireDto>(payload)
  ),
  takeLatest(deleteTire.toString(), (payload: SagaGenericParams<TireDto>) =>
    deleteSaga<TireDto>(payload)
  ),
];

export default tiresSaga;
