export interface SliderDto {
  Id: string;
  Title: string;
  Description: string;
  Image: string;
  RecordOrder: number;
  PrivilegeId?: string;
  Active: boolean;
  SliderTypeId?: number;
  Value?: string;
  ForCustomer: boolean;
}
export interface SliderTypeDto {
  EnumId: number;
  Name: string;
}
export class SliderTypeConsts {
  static Privilege = 1;
  static Link = 2;
}
