import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PlannedNotificationDto } from "./type";

export const getPlannedNotificationList = createAction(
  "GET_NOTIFICATION_PLANNED_LIST",
  ({ payload, params }: SagaActionParams<PlannedNotificationDto[]>) => ({
    payload,
    url: "/api/plannednotifications" + (params ? params : ""),
  })
);
export const getPlannedNotificationDetails = createAction(
  "GET_NOTIFICATION_PLANNED_DETAILS",
  ({ payload, id }: SagaActionParams<PlannedNotificationDto>) => ({
    payload,
    url: "/api/plannednotifications/" + id,
  })
);
export const createPlannedNotification = createAction(
  "CREATE_NOTIFICATION_PLANNED_DETAILS",
  ({ payload, body }: SagaActionParams<PlannedNotificationDto>) => ({
    payload,
    url: "/api/plannednotifications",
    body,
  })
);
export const updatePlannedNotification = createAction(
  "UPDATE_NOTIFICATION_PLANNED_DETAILS",
  ({ payload, body, id }: SagaActionParams<PlannedNotificationDto>) => ({
    payload,
    url: "/api/plannednotifications/" + id,
    body,
  })
);
export const deletePlannedNotification = createAction(
  "DELETE_NOTIFICATION_PLANNED_DETAILS",
  ({ payload, body, id }: SagaActionParams<PlannedNotificationDto>) => ({
    payload,
    url: "/api/plannednotifications/" + id,
    body,
  })
);

const plannedNotificationSaga = [
  takeLatest(
    getPlannedNotificationList.toString(),
    (payload: SagaGenericParams<PlannedNotificationDto[]>) =>
      getListSaga<PlannedNotificationDto[]>(payload)
  ),
  takeLatest(
    getPlannedNotificationDetails.toString(),
    (payload: SagaGenericParams<PlannedNotificationDto>) =>
      getListSaga<PlannedNotificationDto>(payload)
  ),
  takeLatest(
    createPlannedNotification.toString(),
    (payload: PostSagaGenericParams<PlannedNotificationDto>) =>
      postSaga<PlannedNotificationDto>(payload)
  ),
  takeLatest(
    updatePlannedNotification.toString(),
    (payload: PostSagaGenericParams<PlannedNotificationDto>) =>
      putSaga<PlannedNotificationDto>(payload)
  ),
  takeLatest(
    deletePlannedNotification.toString(),
    (payload: SagaGenericParams<PlannedNotificationDto>) =>
      deleteSaga<PlannedNotificationDto>(payload)
  ),
];

export default plannedNotificationSaga;
