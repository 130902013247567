import { useTranslation } from "react-i18next";

const ColumnButton = ({
  buttonType,
  onClick,
  custombutton,
}: {
  buttonType:
    | "edit"
    | "edit2"
    | "delete"
    | "delete2"
    | "cancel"
    | "details"
    | "approve"
    | "details2"
    | "password"
    | "custom"
    | "customicon"
    | "customtext"
    | "editicon"
    | "deleteicon";
  onClick: () => void;
  custombutton?: {
    color?: string;
    icon?: string;
    text?: string;
  };
}) => {
  const { t } = useTranslation();
  return (
    <>
      {buttonType === "editicon" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-info"
          onClick={onClick}
        >
          <i className=" bx bx-edit-alt"></i>
        </button>
      )}
      {buttonType === "customicon" && custombutton && (
        <button
          color={custombutton.color ? custombutton.color : "rgb(25, 135, 84)"}
          type="button"
          className="btn btn-soft-light  btn-sm "
          onClick={onClick}
        >
          <i className={custombutton.icon}></i>
        </button>
      )}
      {buttonType === "deleteicon" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-danger"
          onClick={onClick}
        >
          <i className=" bx bx-trash-alt label-icon"></i>
        </button>
      )}

      {buttonType === "edit2" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-edit text-info"
          onClick={onClick}
        >
          <i className=" bx bx-edit-alt label-icon"></i> {t("Edit")}
        </button>
      )}
      {buttonType === "delete2" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-delete text-danger"
          onClick={onClick}
        >
          <i className=" bx bx-trash-alt label-icon"></i> {t("Delete")}
        </button>
      )}
      {buttonType === "edit" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-info"
          onClick={onClick}
        >
          <i className=" bx bx-edit-alt "></i>
        </button>
      )}
      {buttonType === "delete" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-danger"
          onClick={onClick}
        >
          <i className=" bx bx-trash-alt label-icon"></i>
        </button>
      )}
      {buttonType === "cancel" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-delete text-danger"
          onClick={onClick}
        >
          <i className=" bx bx-trash-alt label-icon"></i> {t("Cancel")}
        </button>
      )}
      {/* {buttonType === "details" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-success"
          onClick={onClick}
        >
          <i className="mdi mdi-eye label-icon"></i>
        </button>
      )} */}
      {buttonType === "details" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-delete text-success"
          onClick={onClick}
        >
          <i className="mdi mdi-eye label-icon"></i> {t("Details")}
        </button>
      )}
      {buttonType === "details2" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm text-success"
          onClick={onClick}
        >
          <i className="mdi mdi-eye "></i>
        </button>
      )}
      {buttonType === "approve" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-delete text-success"
          onClick={onClick}
        >
          <i className="bx bx-check-square label-icon"></i> {t("Approval")}
        </button>
      )}
      {buttonType === "custom" && custombutton && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light"
          style={{
            color: custombutton.color ? custombutton.color : "rgb(25, 135, 84)",
          }}
          onClick={onClick}
        >
          <i className={"label-icon " + custombutton.icon}></i>{" "}
          {custombutton.text}
        </button>
      )}
      {buttonType === "password" && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm w-xs waves-effect btn-label waves-light btn-delete text-success"
          onClick={onClick}
        >
          <i className=" fas fa-lock label-icon"></i> {t("Password")}
        </button>
      )}
      {buttonType === "customtext" && custombutton && (
        <button
          type="button"
          className="btn btn-soft-light btn-sm waves-light"
          style={{
            color: custombutton.color ? custombutton.color : "rgb(25, 135, 84)",
          }}
          onClick={onClick}
        >
          {custombutton.text}
        </button>
      )}
    </>
  );
};
export default ColumnButton;
