import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { VehicleBrandDto } from "./type";
import { BodyType } from "src/store/base/request/request";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getVehicleBrandList = createAction(
  "GET_VEHICLE_BRAND_LIST",
  ({ payload, params }: SagaActionParams<VehicleBrandDto[]>) => ({
    payload,
    url: "/api/vehiclebrands" + (params ? params : ""),
  })
);
export const getVehicleBrandLookup = createAction(
  "GET_VEHICLE_BRAND_LOOKUP",
  ({ payload }: SagaActionParams<VehicleBrandDto[]>) => ({
    payload,
    url: "/api/vehiclebrands/lookup",
  })
);
export const getVehicleBrandDetails = createAction(
  "GET_VEHICLE_BRAND_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleBrandDto>) => ({
    payload,
    url: "/api/vehiclebrands/" + id,
  })
);
export const createVehicleBrand = createAction(
  "CREATE_VEHICLE_BRAND_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleBrandDto>) => ({
    payload,
    url: "/api/vehiclebrands",
    body,
  })
);
export const updateVehicleBrand = createAction(
  "UPDATE_VEHICLE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleBrandDto>) => ({
    payload,
    url: "/api/vehiclebrands/" + id,
    body,
  })
);
export const deleteVehicleBrand = createAction(
  "DELETE_VEHICLE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleBrandDto>) => ({
    payload,
    url: "/api/vehiclebrands/" + id,
    body,
  })
);

export const changeVehicleBrandOrder = createAction(
  "UPDATE_VEHICLE_BRAND_ORDER",
  ({ payload, body }: SagaActionParams<VehicleBrandDto>) => ({
    payload,
    url: "/api/vehiclebrands/order",
    body,
    bodyType: BodyType.raw,
  })
);
const vehicleBrandsSaga = [
  takeLatest(
    getVehicleBrandList.toString(),
    (payload: SagaGenericParams<VehicleBrandDto[]>) =>
      getListSaga<VehicleBrandDto[]>(payload)
  ),
  takeLatest(
    getVehicleBrandLookup.toString(),
    (payload: SagaGenericParams<VehicleBrandDto[]>) =>
      getListSaga<VehicleBrandDto[]>(payload)
  ),
  takeLatest(
    getVehicleBrandDetails.toString(),
    (payload: SagaGenericParams<VehicleBrandDto>) =>
      getListSaga<VehicleBrandDto>(payload)
  ),
  takeLatest(
    createVehicleBrand.toString(),
    (payload: PostSagaGenericParams<VehicleBrandDto>) =>
      postSaga<VehicleBrandDto>(payload)
  ),
  takeLatest(
    updateVehicleBrand.toString(),
    (payload: PostSagaGenericParams<VehicleBrandDto>) =>
      putSaga<VehicleBrandDto>(payload)
  ),
  takeLatest(
    deleteVehicleBrand.toString(),
    (payload: SagaGenericParams<VehicleBrandDto>) =>
      deleteSaga<VehicleBrandDto>(payload)
  ),
];

export default vehicleBrandsSaga;
