import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Table } from "react-super-responsive-table";
import Loader from "../Loader";
import "./style.scss";
import { useTranslation } from "react-i18next";
const DetailTable = ({
  title,
  columns,
  listData,
  setDataList,
  create,
  createText,
  action,
  paramsid,
}: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    dispatch(
      action({
        payload: {
          //@ts-ignore
          onSuccess: (message, payload, details) => {
            setDataList(payload);
            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: paramsid,
      })
    ); // eslint-disable-next-line
  }, [paramsid]);
  return (
    <React.Fragment>
      {loader && <Loader />}

      {create && (
        <div className="d-flex justify-content-end py-1">
          <button className="btn btn-sm btn-success" onClick={() => create()}>
            <i className="bx bx-plus me-1"></i>{" "}
            {createText ? createText : t("New")}
          </button>
        </div>
      )}

      <Table
        className="detailTable table mb-0 bg-white"
        style={{ background: "white" }}
      >
        <thead className="">
          <tr>
            {Array.from(new Set(columns.map((x: any) => x.text))).map(
              (y: any) => {
                return <th>{y}</th>;
              }
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {listData.map((dataItem: any) => (
            <tr>
              {columns.map((x: any) => {
                return (
                  <td>
                    {x.formatter
                      ? x.formatter(dataItem[x.dataField], dataItem)
                      : dataItem[x.dataField]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default DetailTable;
