import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import CreateCustomerCalllogModal from "src/pages/Calllogs/create/createModal";
import { deleteCustomerCallLogs } from "src/store/customer-calllog/saga";
import { CustomerCallLogsDto } from "src/store/customer-calllog/type";
import { CustomerDetailDto } from "src/store/customers/type";

const CustomerCalllogTab = ({
  customerData,
}: {
  customerData: CustomerDetailDto;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<CustomerCallLogsDto>();
  const history = useHistory();
  const { id }: { id: string; tab: string } = useParams();

  const columns = [
    {
      caption: t("Code"),
      dataField: "Code",
    },
    {
      caption: t("Önem Derecesi"),
      dataField: "CustomerCallLogLevelName",
    },
    {
      caption: t("Tip"),
      dataField: "CustomerCallLogTypeName",
    },
    {
      caption: t("Konu"),
      dataField: "CustomerCallLogParentTopicName",
    },
    {
      caption: t("Alt Konu"),
      dataField: "CustomerCallLogSubTopicName",
    },
    {
      caption: t("Plate"),
      dataField: "VehiclePlate",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer-calllogs/" + e.row.key);
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <CustomDataGrid
          className="detailGrid"
          url={"/api/customercalllogs/devexp/" + id}
          columns={columns}
          create={() => setShowCreate(true)}
          createText="Çağrı Ekle"
        />
      </Row>
      <CreateCustomerCalllogModal
        open={showCreate}
        onHide={() => setShowCreate(false)}
        customerData={customerData}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Silmek istediğine emin misiniz?`)}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCustomerCallLogs({
                payload: {
                  onSuccess: message => toast.success(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default CustomerCalllogTab;
