import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getNotificationPage } from "src/store/lookup/saga";
import { NotificationPageDto } from "src/store/lookup/type";
import { updateNotificationMessages } from "src/store/notification-messages/saga";
import { NotificationMessageDto } from "src/store/notification-messages/type";
import * as Yup from "yup";

const EditSocialMedia = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: NotificationMessageDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pages, setPages] = useState<NotificationPageDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getNotificationPage({
          payload: {
            onSuccess: (msg, payload) => setPages(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Bildirim Mesajı Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateNotificationMessages({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          Title: data.Title,
          Message: data.Message ?? "",
          NotificationPageListId: data.NotificationPageListId ?? "",
        }}
        validationSchema={Yup.object().shape({
          Title: Yup.string().required(t("Required")),
          Message: Yup.string().required(t("Required")),
          NotificationPageListId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "Title",
            label: t("Title"),
          },
          {
            field: "Message",
            label: t("Message"),
            inputType: InputType.multilinetext,
          },
          {
            field: "NotificationPageListId",
            inputType: InputType.multiselect,
            label: t("Yönlendirilecek Sayfa"),
            lookup: {
              data: pages,
              labelKey: "ScreenName",
              valueKey: "EnumId",
              placeholder: t("Yönlendirilecek Sayfa Seçiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditSocialMedia;
