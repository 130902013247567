import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap";
import Dropzone from "react-dropzone";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { baseImageUrl } from "src/store/base/types/url";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import images from "src/assets/image";
const FileUpload = ({
  linkvalues,
  multiple = false,
  title,
  accept,
  icon,
  onChange,
  disabled,
  error,
}: {
  icon?: any;
  error?: any;
  accept?: string | string[];
  linkvalues?: string[];
  title?: string;
  multiple?: boolean;
  disabled?: boolean;
  onChange: (files: any) => void;
}) => {
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const [isFits, setisFits] = useState<string>();
  const { t } = useTranslation();
  function handleAcceptedFiles(files: any) {
    files.map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    onChange(files);
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      {isFits ? (
        <Lightbox
          mainSrc={baseImageUrl + isFits}
          enableZoom={false}
          imageCaption={""}
          onCloseRequest={() => {
            setisFits("");
          }}
        />
      ) : null}
      {!disabled && (
        <Dropzone
          multiple={multiple}
          accept={accept}
          onDrop={acceptedFiles => {
            handleAcceptedFiles(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone mt-1" style={{ minHeight: 100 }}>
              <div
                className="dz-message needsclick "
                {...getRootProps()}
                style={{ padding: 10 }}
              >
                <input {...getInputProps()} />
                <div className="mb-1">
                  <i className="display-5 text-muted bx bx-cloud-upload" />
                </div>
                <h6>
                  {title ? title : t("Drop files here or click to upload")}
                </h6>
              </div>
            </div>
          )}
        </Dropzone>
      )}
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f: any, i: number) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      style={{ cursor: "pointer" }}
                      data-dz-thumbnail=""
                      onClick={() => {
                        return setisFits(f.preview);
                      }}
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={f.name}
                      src={f.preview}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = icon ? icon : images.doc;
                      }}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
        {linkvalues?.map((x, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      style={{ cursor: "pointer" }}
                      data-dz-thumbnail=""
                      onClick={() => {
                        return setisFits(x);
                      }}
                      height="80"
                      className="avatar-sm rounded bg-light"
                      src={baseImageUrl + x}
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = images.noimage;
                      }}
                    />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {/* {f.name} */}
                    </Link>
                    <p className="mb-0">
                      {/* <strong>{f.formattedSize}</strong> */}
                    </p>
                  </Col>
                </Row>
              </div>
            </Card>
          );
        })}
      </div>
      {error && <span className="errorInfo">*{error}</span>}
    </React.Fragment>
  );
};

export default FileUpload;
