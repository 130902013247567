import React from "react";
import { InvoicePackageDto } from "src/store/package-invoices/type";
import InvoiceDetailCard from "./detailCard";
import CustomModal from "src/components/Modal/drawer";

const InvoicePackageDetailModal = ({
  onHide,
  open,
  selectedData,
}: {
  selectedData: InvoicePackageDto;
  onHide: () => void;
  open: boolean;
}) => {
  return (
    <React.Fragment>
      <CustomModal onHide={onHide} open={open} title={"Fatura Bilgileri"}>
        <InvoiceDetailCard selectedData={selectedData} />
      </CustomModal>
    </React.Fragment>
  );
};
export default InvoicePackageDetailModal;
