import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeCategoryDto } from "./type";
import { BodyType } from "../base/request/request";

export const getPrivilegeCategoryList = createAction(
  "GET_PRIVILEGE_CATEGORYLIST",
  ({ payload, params }: SagaActionParams<PrivilegeCategoryDto[]>) => ({
    payload,
    url: "/api/privilegecategories" + (params ? params : ""),
  })
);
export const getPrivilegeLookupCategoryList = createAction(
  "GET_PRIVILEGE_LOOKUP_CATEGORYLIST",
  ({ payload, params }: SagaActionParams<PrivilegeCategoryDto[]>) => ({
    payload,
    url: "/api/privilegecategories/lookup",
  })
);
export const getPrivilegeCategoryDetails = createAction(
  "GET_PRIVILEGE_CATEGORYDETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/privilegecategories/" + id,
  })
);
export const createPrivilegeCategory = createAction(
  "CREATE_PRIVILEGE_CATEGORYDETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/privilegecategories",
    body,
  })
);
export const updatePrivilegeCategory = createAction(
  "UPDATE_PRIVILEGE_CATEGORYDETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/privilegecategories/" + id,
    body,
  })
);
export const deletePrivilegeCategory = createAction(
  "DELETE_PRIVILEGE_CATEGORYDETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/privilegecategories/" + id,
    body,
  })
);

export const changePrivilegeCategoryOrder = createAction(
  "UPDATE_PRIVILEGE_CATEGORY_ORDER",
  ({ payload, body }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/privilegecategories/order",

    bodyType: BodyType.raw,
    body,
  })
);

const privilegecategorySaga = [
  takeLatest(
    getPrivilegeCategoryList.toString(),
    (payload: SagaGenericParams<PrivilegeCategoryDto[]>) =>
      getListSaga<PrivilegeCategoryDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeLookupCategoryList.toString(),
    (payload: SagaGenericParams<PrivilegeCategoryDto[]>) =>
      getListSaga<PrivilegeCategoryDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeCategoryDetails.toString(),
    (payload: SagaGenericParams<PrivilegeCategoryDto>) =>
      getListSaga<PrivilegeCategoryDto>(payload)
  ),
  takeLatest(
    createPrivilegeCategory.toString(),
    (payload: PostSagaGenericParams<PrivilegeCategoryDto>) =>
      postSaga<PrivilegeCategoryDto>(payload)
  ),
  takeLatest(
    updatePrivilegeCategory.toString(),
    (payload: PostSagaGenericParams<PrivilegeCategoryDto>) =>
      putSaga<PrivilegeCategoryDto>(payload)
  ),
  takeLatest(
    deletePrivilegeCategory.toString(),
    (payload: SagaGenericParams<PrivilegeCategoryDto>) =>
      deleteSaga<PrivilegeCategoryDto>(payload)
  ),
  takeLatest(
    changePrivilegeCategoryOrder.toString(),
    (payload: PostSagaGenericParams<PrivilegeCategoryDto>) =>
      putSaga<PrivilegeCategoryDto>(payload)
  ),
];

export default privilegecategorySaga;
