import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireTreadWidthDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireTreadWidthList = createAction(
  "GET_TIRE_TREAD_WIDTH_LIST",
  ({ payload, params }: SagaActionParams<TireTreadWidthDto[]>) => ({
    payload,
    url: "/api/tiretreadwidths" + (params ? params : ""),
  })
);
export const getTireTreadWidthDetails = createAction(
  "GET_TIRE_TREAD_WIDTH_DETAILS",
  ({ payload, id }: SagaActionParams<TireTreadWidthDto>) => ({
    payload,
    url: "/api/tiretreadwidths/" + id,
  })
);
export const createTireTreadWidth = createAction(
  "CREATE_TIRE_TREAD_WIDTH_DETAILS",
  ({ payload, body }: SagaActionParams<TireTreadWidthDto>) => ({
    payload,
    url: "/api/tiretreadwidths",
    body,
  })
);
export const updateTireTreadWidth = createAction(
  "UPDATE_TIRE_TREAD_WIDTH_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireTreadWidthDto>) => ({
    payload,
    url: "/api/tiretreadwidths/" + id,
    body,
  })
);
export const deleteTireTreadWidth = createAction(
  "DELETE_TIRE_TREAD_WIDTH_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireTreadWidthDto>) => ({
    payload,
    url: "/api/tiretreadwidths/" + id,
    body,
  })
);

const tireTreadWidthSaga = [
  takeLatest(
    getTireTreadWidthList.toString(),
    (payload: SagaGenericParams<TireTreadWidthDto[]>) =>
      getListSaga<TireTreadWidthDto[]>(payload)
  ),
  takeLatest(
    getTireTreadWidthDetails.toString(),
    (payload: SagaGenericParams<TireTreadWidthDto>) =>
      getListSaga<TireTreadWidthDto>(payload)
  ),
  takeLatest(
    createTireTreadWidth.toString(),
    (payload: PostSagaGenericParams<TireTreadWidthDto>) =>
      postSaga<TireTreadWidthDto>(payload)
  ),

  takeLatest(
    updateTireTreadWidth.toString(),
    (payload: PostSagaGenericParams<TireTreadWidthDto>) =>
      putSaga<TireTreadWidthDto>(payload)
  ),
  takeLatest(
    deleteTireTreadWidth.toString(),
    (payload: SagaGenericParams<TireTreadWidthDto>) =>
      deleteSaga<TireTreadWidthDto>(payload)
  ),
];

export default tireTreadWidthSaga;
