import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { DashboardTicketDto } from "src/store/dashboard/type";
import { useDispatch } from "react-redux";
import { getDashboardTicketsSaga } from "src/store/dashboard/saga";
import ScrollBar from "react-perfect-scrollbar";

const DashboardTicketComponent = () => {
  const [data, setData] = useState<DashboardTicketDto[]>();
  const [total, setTotal] = useState<{
    TotalCount: number;
    Count: number;
  }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardTicketsSaga({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
            setTotal(r);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      {data && total && (
        <Card className="dashboardCard">
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-2">
                <h5 className="card-title mb-3">TALEPLER</h5>
              </div>
              {/* <div className="ms-auto">Toplam Adet: {total.TotalCount}</div> */}
            </div>
            <div>
              <ScrollBar style={{ height: "320px" }}>
                <table className="table align-middle table-nowrap">
                  <tbody>
                    {data.map(x => (
                      <tr key={x.Id}>
                        <td style={{ whiteSpace: "normal" }}>
                          <p className="mb-0 fw-bold">
                            <span
                              className="badge bg-soft-success text-success me-2"
                              style={{ fontSize: 12 }}
                            >
                              #{x.TicketNumber}
                            </span>
                            {x.CustomerName}
                          </p>
                          <p
                            className="text-muted my-0 "
                            style={{ fontSize: 12 }}
                          >
                            {x.LastMessage}
                          </p>
                        </td>
                        <td style={{ fontSize: 16 }}>
                          <a
                            target="_blank"
                            className="text-success"
                            rel="noreferrer"
                            href={"/ticket/all?ticketId=" + x.Id}
                          >
                            <i className="mdi mdi-reply"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ScrollBar>
              <div
                className="text-end fw-bold text-muted py-1"
                style={{ fontSize: 12 }}
              >
                Toplam Talep: {total.TotalCount} <br />
                Açık Talep: {total.Count}
              </div>
            </div>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DashboardTicketComponent;
