import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createCustomerPrivilege } from "src/store/customerprivilege/saga";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import { CustomerVehicleDto } from "src/store/customervehicles/type";
import { getUsageTypes } from "src/store/lookup/saga";
import { UsageTypeDto } from "src/store/lookup/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegePriceListByPrivilegeId } from "src/store/privilegeprices/saga";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";

const CreateCustomerPrivilege = ({
  onHide,
  open,
  onSuccessClick,
  customerId,
}: {
  customerId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [usegaTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [vehicles, setVehicles] = useState<CustomerVehicleDto[]>([]);
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getCustomerVehicleListByCustomerId({
          payload: {
            onSuccess: (msg, py) => setVehicles(py.filter(x => x.Active)),
            onError: () => {},
          },
          id: customerId,
        })
      );
      dispatch(
        getUsageTypes({
          payload: {
            onSuccess: (msg, py) => setUsageTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open, customerId]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Privilege"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CustomerId: customerId,
          VehicleId: "",
          PrivilegeId: "",
          PrivilegePriceId: "",
          Name: "",
          UsageTypeId: "",
          Count: 0,
          StartDate: "",
          EndDate: "",
          Transferable: false,
          CanBeUseAfterCancel: false,
          TakePayment: false,
        }}
        onSubmit={values => {
          dispatch(
            createCustomerPrivilege({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          UsageTypeId: Yup.string().required(t("Required")),
          PrivilegeId: Yup.string().required(t("Required")),
          PrivilegePriceId: Yup.string().required(t("Required")),
          Count: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
            onChange(value, item, setFieldValue, values) {
              item && item.Title && setFieldValue("Name", item.Title);
              item && item.EndDate && setFieldValue("EndDate", item.EndDate);
              item &&
                item.StartDate &&
                setFieldValue("StartDate", item.StartDate);
              value &&
                dispatch(
                  getPrivilegePriceListByPrivilegeId({
                    payload: {
                      onSuccess: (msg, py) => setPrices(py),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            hide(values) {
              return !values.PrivilegeId;
            },
            field: "PrivilegePriceId",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
          },
          {
            field: "Name",
            label: t("Mobilde Görünecek Ad"),
          },
          {
            field: "VehicleId",
            inputType: InputType.multiselect,
            label: t("Vehicle"),
            lookup: {
              data: vehicles,
              labelKey: "Plate",
              valueKey: "Id",
              placeholder: t("Choose Vehicle"),
            },
          },

          {
            field: "UsageTypeId",
            inputType: InputType.multiselect,
            label: t("Usage Type"),
            lookup: {
              data: usegaTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Usage Type"),
            },
          },

          {
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },
          {
            field: "Transferable",
            col: 12,
            label: t("Devredilebilir mi"),
            inputType: InputType.checkbox,
          },
          {
            field: "CanBeUseAfterCancel",
            col: 12,
            label: t("Üyelik İptali Sonrası Kullanabilir mi"),
            inputType: InputType.checkbox,
          },
          {
            field: "TakePayment",
            label: t("Üyelik İptal Ödemesine Dahil"),
            inputType: InputType.checkbox,
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "EndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCustomerPrivilege;
