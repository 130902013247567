import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import * as types from "./type";

export const getCustomerDashboardChartList = createAction(
  "GET_CUSTOMER_DASHBOARD_CHART_LIST",
  ({ payload, params }: SagaActionParams<types.CustomerDashboardChartDto>) => ({
    payload,
    url: "/api/reports/customer-chart" + (params ? params : ""),
  })
);
const reportSaga = [
  takeLatest(
    getCustomerDashboardChartList.toString(),
    (payload: SagaGenericParams<types.CustomerDashboardChartDto>) =>
      getListSaga<types.CustomerDashboardChartDto>(payload)
  ),
];

export default reportSaga;
