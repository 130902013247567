import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SliderDto, SliderTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getSliderList = createAction(
  "GET_SLIDER_LIST",
  ({ payload, params }: SagaActionParams<SliderDto[]>) => ({
    payload,
    url: "/api/sliders" + (params ? params : ""),
  })
);
export const getSliderDetails = createAction(
  "GET_SLIDER_DETAILS",
  ({ payload, id }: SagaActionParams<SliderDto>) => ({
    payload,
    url: "/api/sliders/" + id,
  })
);
export const createSlider = createAction(
  "CREATE_SLIDER_DETAILS",
  ({ payload, body }: SagaActionParams<SliderDto>) => ({
    payload,
    url: "/api/sliders",
    body,
  })
);
export const updateSlider = createAction(
  "UPDATE_SLIDER_DETAILS",
  ({ payload, body, id }: SagaActionParams<SliderDto>) => ({
    payload,
    url: "/api/sliders/" + id,
    body,
  })
);
export const deleteSlider = createAction(
  "DELETE_SLIDER_DETAILS",
  ({ payload, body, id }: SagaActionParams<SliderDto>) => ({
    payload,
    url: "/api/sliders/" + id,
    body,
  })
);

export const changeSliderOrder = createAction(
  "UPDATE_SLIDER_ORDER",
  ({ payload, body }: SagaActionParams<SliderDto>) => ({
    payload,
    url: "/api/sliders/order",

    bodyType: BodyType.raw,
    body,
  })
);

export const getSliderTypeList = createAction(
  "GET_SLIDER_TYPE_LIST",
  ({ payload }: SagaActionParams<SliderTypeDto[]>) => ({
    payload,
    url: "/api/system/slidertypes",
  })
);
const sliderSaga = [
  takeLatest(
    getSliderList.toString(),
    (payload: SagaGenericParams<SliderDto[]>) =>
      getListSaga<SliderDto[]>(payload)
  ),
  takeLatest(
    getSliderDetails.toString(),
    (payload: SagaGenericParams<SliderDto>) => getListSaga<SliderDto>(payload)
  ),
  takeLatest(
    createSlider.toString(),
    (payload: PostSagaGenericParams<SliderDto>) => postSaga<SliderDto>(payload)
  ),
  takeLatest(
    changeSliderOrder.toString(),
    (payload: PostSagaGenericParams<SliderDto>) => putSaga<SliderDto>(payload)
  ),
  takeLatest(
    updateSlider.toString(),
    (payload: PostSagaGenericParams<SliderDto>) => putSaga<SliderDto>(payload)
  ),
  takeLatest(deleteSlider.toString(), (payload: SagaGenericParams<SliderDto>) =>
    deleteSaga<SliderDto>(payload)
  ),
  takeLatest(
    getSliderTypeList.toString(),
    (payload: SagaGenericParams<SliderTypeDto[]>) =>
      getListSaga<SliderTypeDto[]>(payload)
  ),
];

export default sliderSaga;
