import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import { createCorporateClientCustomer } from "src/store/corporate-clients/saga";
import { getCorporateCompanyLookup } from "src/store/corporate-companies/saga";
import { CorporateCompanyLookupDto } from "src/store/corporate-companies/type";
import { getCorporateContractPackages } from "src/store/corporate-contracts/saga";
import { getCorporateDepartmentLookup } from "src/store/corporate-departments/saga";
import { CorporateDepartmentDto } from "src/store/corporate-departments/type";
import { getCorporateLocationLookup } from "src/store/corporate-locations/saga";
import { CorporateLocationsDto } from "src/store/corporate-locations/type";
import { getCustomerGroupList } from "src/store/customergroup/saga";
import { CustomerGroupDto } from "src/store/customergroup/type";
import { getPackageGiftList } from "src/store/packagegift/saga";
import { PackageGiftDto } from "src/store/packagegift/type";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const CreateCustomer = ({
  onHide,
  open,
}: {
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: any = useParams();
  const [groups, setGroups] = useState<CustomerGroupDto[]>([]);
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [packagePrivileges, setPackagePrivileges] = useState<PackageGiftDto[]>(
    []
  );
  const [openResult, setOpenResult] = useState(false);
  const [companies, setCompanies] = useState<CorporateCompanyLookupDto[]>([]);
  const [departments, setDepartments] = useState<CorporateDepartmentDto[]>([]);
  const [locations, setLocations] = useState<CorporateLocationsDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getCorporateCompanyLookup({
          payload: {
            onSuccess: (msg, payload) => setCompanies(payload),
            onError: () => {},
          },
          id: id,
        })
      );
      dispatch(
        getCorporateLocationLookup({
          payload: {
            onSuccess: (msg, payload) => setLocations(payload),
            onError: () => {},
          },
          id: id,
        })
      );
      dispatch(
        getCustomerGroupList({
          payload: {
            onSuccess: (message, payload) => {
              setGroups(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCorporateContractPackages({
          payload: {
            onSuccess: (message, payload) => {
              setPackages(payload);
            },
            onError: () => {},
          },
          id: id,
        })
      );
    }
  }, [open, dispatch, id]);
  return (
    <React.Fragment>
      <CustomModal
        open={openResult}
        onHide={() => setOpenResult(false)}
        title={"Uyarı"}
      ></CustomModal>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          CustomerGroupId: "",
          CorporateClientId: id,
          CorporateDepartmentId: "",
          CorporateLocationId: "",
          PackageGiftPrivilegeId: "",
          Yearly: false,
          PackageId: "",
          StartDate: "",
          ExcelFile: "",
          PhoneNumber: "",
          Surname: "",
          Name: "",
          Email: "",
        }}
        onSubmit={values => {
          dispatch(
            createCorporateClientCustomer({
              payload: {
                onSuccess: (message, payload) => {
                  toast.success("Müşteriler başarıyla kaydedildi.");
                  onHide();
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PhoneNumber: Yup.mixed().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          Name: Yup.string().required(t("Required")),
          Email: Yup.string().required(t("Required")),
          CustomerGroupId: Yup.string().required(t("Required")),
          CorporateClientId: Yup.string().required(t("Required")),
          PackageId: Yup.string().required(t("Required")),
          StartDate: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Surname",
            label: t("Surname"),
            col: 6,
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            field: "Email",
            label: t("Email"),
          },
          {
            field: "CorporateLocationId",
            inputType: InputType.multiselect,
            label: t("Lokasyon Seçimi"),
            lookup: {
              data: locations,
              labelKey: "Address",
              valueKey: "Id",
              placeholder: t("Lokasyon Seçiniz"),
            },
          },
          {
            field: "CorporateCompanyId",
            inputType: InputType.multiselect,
            label: t("Şirket Seçimi"),
            lookup: {
              data: companies,
              labelKey: "CompanyName",
              valueKey: "Id",
              placeholder: t("Şirket Seçiniz"),
            },
            onChange: value => {
              if (value) {
                dispatch(
                  getCorporateDepartmentLookup({
                    payload: {
                      onSuccess: (msg, payload) => setDepartments(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
              }
            },
          },

          {
            field: "CorporateDepartmentId",
            inputType: InputType.multiselect,
            label: t("Departman Seçimi"),
            lookup: {
              data: departments,
              labelKey: "DepartmentName",
              valueKey: "Id",
              placeholder: t("Departman Seçiniz"),
            },
          },
          {
            field: "CustomerGroupId",
            inputType: InputType.multiselect,
            label: t("Üye Grubu"),
            lookup: {
              data: groups,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Grup Seçiniz"),
            },
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "PackageId",
            inputType: InputType.multiselect,
            label: t("Paket"),
            lookup: {
              data: packages,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Paket Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getPackageGiftList({
                    payload: {
                      onSuccess: (msg, payload) =>
                        setPackagePrivileges(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            field: "PackageGiftPrivilegeId",
            inputType: InputType.multiselect,
            label: t("Hediye Seçimi"),
            lookup: {
              data: packagePrivileges,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },

          {
            field: "Yearly",
            label: t("Yıllık"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCustomer;
