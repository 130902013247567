import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Card, Col, Container, Row } from "reactstrap";
import Loader from "src/components/Loader";
import MetaTags from "react-meta-tags";

import { isLoading } from "src/store/loader";
import { getProductDetails } from "src/store/orders/products/saga";
import { ProductDetailDto } from "src/store/orders/products/type";
import ProductImageList from "./images/imageList";
import { getCurrency } from "src/helpers/getCurrency";
import EditProduct from "./edit";
import ProductCompatibleBrandList from "./compatible-brands";
import { toast } from "react-toastify";

const ProductDetailsPage = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const loader = useSelector(isLoading);
  const [product, setProduct] = useState<ProductDetailDto>();
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
    dispatch(
      getProductDetails({
        payload: {
          onSuccess: (m, p) => {
            setProduct(p);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{product ? product.Name : ""}</title>
        </MetaTags>
        <Container fluid>
          {loader && <Loader />}
          {product && (
            <>
              <Card className="p-2">
                <Row>
                  <Col md={6}>
                    <ProductImageList data={product} />
                  </Col>
                  <Col md={6}>
                    <div className="mt-4 mt-xl-3 my-4">
                      <p className="text-primary">
                        {product.ProductCategoryFullName}
                      </p>
                      <h4 className="mt-1 mb-1">{product.Name}</h4>

                      <div className="p-1 flex-row gap-1 font-size-12 badge bg-light">
                        <span className=" bx bx-user-circle text-warning"></span>{" "}
                        {product.SupplierName}
                      </div>
                      <p className="text-muted mb-4">
                        {product.ShortDescription}
                      </p>
                      <Row>
                        <Col md={6}>
                          <Card className="d-flex w-100 justify-content-center align-items-center py-3">
                            <div className="fw-bold">Maliyet</div>
                            <h5 className="text-primary">
                              {getCurrency(product.CostPrice)}
                            </h5>
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card className="d-flex w-100 justify-content-center align-items-center py-3">
                            <div className="fw-bold">Satış</div>
                            <h5 className="text-primary">
                              {getCurrency(product.ListPrice)}
                            </h5>
                          </Card>
                        </Col>
                        <Col md={12}>
                          <div>
                            <h6>
                              Üyelere Olmayanlara{" "}
                              <span className="text-success">
                                {product.SalesPercent}%{" "}
                              </span>
                              indirim ile{" "}
                              <span
                                className="text-primary"
                                style={{ fontSize: 16 }}
                              >
                                {getCurrency(product.SalesPrice)}
                              </span>
                            </h6>
                            <h6>
                              Üyelere Özel{" "}
                              <span className="text-success">
                                {product.DiscountSalesPercent}%{" "}
                              </span>
                              indirim ile{" "}
                              <span
                                className="text-primary"
                                style={{ fontSize: 16 }}
                              >
                                {getCurrency(product.DiscountSalesPrice)}
                              </span>
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </div>{" "}
                    <div className="w-100 d-flex justify-content-end">
                      <button
                        className="w-100 btn btn-success "
                        onClick={() => setShowEdit(true)}
                      >
                        {" "}
                        Düzenle
                      </button>
                    </div>
                  </Col>
                </Row>

                <EditProduct
                  open={showEdit}
                  onSuccessClick={() => {
                    toast.success("Başarılı");
                    dispatch(
                      getProductDetails({
                        payload: {
                          onSuccess: (m, p) => {
                            setProduct(p);
                          },
                          onError: () => {},
                        },
                        id: id,
                      })
                    );
                  }}
                  data={product}
                  onHide={() => setShowEdit(false)}
                />
              </Card>
              <Row>
                <Col md={12}>
                  <ProductCompatibleBrandList product={product} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductDetailsPage;
