import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { WebSliderDto } from "./type";
import { BodyType } from "../base/request/request";

export const getWebSliderList = createAction(
  "GET_WEB_SLIDER_LIST",
  ({ payload, params }: SagaActionParams<WebSliderDto[]>) => ({
    payload,
    url: "/api/websitesliders" + (params ? params : ""),
  })
);
export const getWebSliderDetails = createAction(
  "GET_WEB_SLIDER_DETAILS",
  ({ payload, id }: SagaActionParams<WebSliderDto>) => ({
    payload,
    url: "/api/websitesliders/" + id,
  })
);
export const createWebSlider = createAction(
  "CREATE_WEB_SLIDER_DETAILS",
  ({ payload, body }: SagaActionParams<WebSliderDto>) => ({
    payload,
    url: "/api/websitesliders",
    body,
  })
);
export const updateWebSlider = createAction(
  "UPDATE_WEB_SLIDER_DETAILS",
  ({ payload, body, id }: SagaActionParams<WebSliderDto>) => ({
    payload,
    url: "/api/websitesliders/" + id,
    body,
  })
);
export const deleteWebSlider = createAction(
  "DELETE_WEB_SLIDER_DETAILS",
  ({ payload, body, id }: SagaActionParams<WebSliderDto>) => ({
    payload,
    url: "/api/websitesliders/" + id,
    body,
  })
);

export const changeWebSliderOrder = createAction(
  "UPDATE_WEB_SLIDER_ORDER",
  ({ payload, body }: SagaActionParams<WebSliderDto>) => ({
    payload,
    url: "/api/websitesliders/order",

    bodyType: BodyType.raw,
    body,
  })
);
const websliderSaga = [
  takeLatest(
    getWebSliderList.toString(),
    (payload: SagaGenericParams<WebSliderDto[]>) =>
      getListSaga<WebSliderDto[]>(payload)
  ),
  takeLatest(
    getWebSliderDetails.toString(),
    (payload: SagaGenericParams<WebSliderDto>) =>
      getListSaga<WebSliderDto>(payload)
  ),
  takeLatest(
    createWebSlider.toString(),
    (payload: PostSagaGenericParams<WebSliderDto>) =>
      postSaga<WebSliderDto>(payload)
  ),
  takeLatest(
    changeWebSliderOrder.toString(),
    (payload: PostSagaGenericParams<WebSliderDto>) =>
      putSaga<WebSliderDto>(payload)
  ),
  takeLatest(
    updateWebSlider.toString(),
    (payload: PostSagaGenericParams<WebSliderDto>) =>
      putSaga<WebSliderDto>(payload)
  ),
  takeLatest(
    deleteWebSlider.toString(),
    (payload: SagaGenericParams<WebSliderDto>) =>
      deleteSaga<WebSliderDto>(payload)
  ),
];

export default websliderSaga;
