import React from "react";
import CustomModal from "src/components/Modal/modal";

const PreviewEmailTemplate = ({
  onHide,
  open,
  content,
}: {
  onHide: () => void;
  open: boolean;
  content: string;
}) => {
  return (
    <React.Fragment>
      <CustomModal onHide={onHide} open={open} title={"Tasarım Şablonu"}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </CustomModal>
    </React.Fragment>
  );
};
export default PreviewEmailTemplate;
