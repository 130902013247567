import { Steps } from "rsuite";
import {
  TireSaleDetailDto,
  TireSaleStatusEnum,
} from "src/store/tires/tireSales/type";

const StatusDetail = ({ data }: { data: TireSaleDetailDto }) => {
  const steps = [
    {
      Id: TireSaleStatusEnum.YeniSatis,
      Text: "Yeni",
    },
    {
      Id: TireSaleStatusEnum.TedarikciyeIletildi,
      Text: "Tedarikçiye İletildi",
    },
    {
      Id: TireSaleStatusEnum.Kargolandi,
      Text: "Kargolandı",
    },
    {
      Id: TireSaleStatusEnum.Tamamlandı,
      Text: "Tamamlandı",
    },
  ];
  const cancelSteps = [
    {
      Id: TireSaleStatusEnum.YeniSatis,
      Text: "Yeni",
    },
    {
      Id: TireSaleStatusEnum.Iptal,
      Text: "Iptal Edildi",
    },
  ];
  console.log();
  return (
    <div>
      {data.TireSaleStatusId === TireSaleStatusEnum.Iptal ? (
        <>
          <Steps
            className=" rejectedStepContainer"
            current={cancelSteps.findIndex(x => x.Id === data.TireSaleStatusId)}
          >
            {cancelSteps.map((x, i) => {
              return <Steps.Item key={i} title={x.Text} />;
            })}
          </Steps>
        </>
      ) : (
        <>
          <Steps
            current={steps.findIndex(x => x.Id === data.TireSaleStatusId)}
            className="ticketStepContainer"
          >
            {steps.map((x, i) => {
              return <Steps.Item key={i} title={x.Text} />;
            })}
          </Steps>
        </>
      )}
    </div>
  );
};
export default StatusDetail;
