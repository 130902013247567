import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, TabContent, TabPane } from "reactstrap";
import { useHistory, useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { Nav } from "rsuite";
import EditSupplierPage from "./edit";
import { getSupplierDetails } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import SupplierLocationTab from "./locations/locations";
import DetailTab from "./details";
import { getSupplierDocumentListBySupplierId } from "src/store/supplierdocuments/saga";
import { SupplierDocumentDto } from "src/store/supplierdocuments/type";
import CreateSupplierDocumentModal from "./documents/createDocument";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";
import SupplierDocumentTab from "./documents/documents";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};

const DetailsSupplierPage = () => {
  const [supplier, setSupplier] = useState<SupplierDto>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, tab }: { id: string; tab: string } = useParams();
  const [active, setActive] = React.useState(tab);
  const [showEdit, setShowEdit] = useState(false);
  const [showCreateDocument, setShowCreateDocument] = useState(false);
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const loader = useSelector(isLoading);
  const [documents, setDocuments] = useState<SupplierDocumentDto[]>([]);
  useEffect(() => {
    tab === "1" &&
      dispatch(
        getSupplierDocumentListBySupplierId({
          payload: {
            onSuccess: (msg, payload) => setDocuments(payload),
            onError: () => {},
          },
          id: id,
        })
      );
  }, [dispatch, tab, id]);
  useEffect(() => {
    dispatch(
      getSupplierDetails({
        payload: {
          onSuccess: (msg, payload) => {
            setSupplier(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      {supplier && (
        <CreateSupplierDocumentModal
          supplier={supplier}
          onHide={() => setShowCreateDocument(false)}
          open={showCreateDocument}
          onSuccessClick={() => {
            dispatch(
              getSupplierDocumentListBySupplierId({
                payload: {
                  onSuccess: (msg, payload) => setDocuments(payload),
                  onError: () => {},
                },
                id: id,
              })
            );
          }}
        />
      )}
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {supplier ? supplier.Name : " "} {t("Supplier")}{" "}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Packages")}
            breadcrumbItem={supplier ? supplier.Name : ""}
          />
          <Nav
            className="mt-2"
            appearance="subtle"
            style={{ zIndex: 1 }}
            onSelect={e => {
              history.push("/supplier/details/" + e + "/" + id);
              return setActive(e);
            }}
            activeKey={active}
          >
            <Nav.Item eventKey="1">{t("Supplier Info")}</Nav.Item>
            <Nav.Item eventKey="2">{t("Locations")}</Nav.Item>
            <Nav.Item eventKey="3">{t("Dokümanlar")}</Nav.Item>
            {/* <Nav.Item eventKey="3">{t("Documents")}</Nav.Item> */}
          </Nav>
          {loader && <Loader />}
          <TabContent activeTab={active} className="mt-4">
            <TabPane tabId="1" className="grid-tab-pane">
              {supplier && (
                <>
                  {CheckRole(user) && (
                    <div className="d-flex justify-content-end gap-2">
                      {!showEdit && (
                        <button
                          onClick={() => setShowCreateDocument(!showEdit)}
                          className="btn btn-success  text-light zIndex-2"
                        >
                          <i className=" dripicons-document "></i>{" "}
                          {t("New Document")}
                        </button>
                      )}
                      {showEdit ? (
                        <button
                          onClick={() => setShowEdit(!showEdit)}
                          className="btn btn-danger text-light zIndex-2"
                        >
                          {t("Close")}
                        </button>
                      ) : (
                        <button
                          onClick={() => setShowEdit(!showEdit)}
                          className="btn   btn-info text-light zIndex-2"
                        >
                          {t("Edit")}
                        </button>
                      )}
                    </div>
                  )}
                  {showEdit ? (
                    <div style={{ paddingBlockStart: 30 }}>
                      <EditSupplierPage
                        supplier={supplier}
                        setSupplier={setSupplier}
                      />
                    </div>
                  ) : (
                    <DetailTab
                      supplier={supplier}
                      documents={documents}
                      setDocuments={setDocuments}
                    />
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="2" className="grid-tab-pane">
              {supplier && <SupplierLocationTab supplier={supplier} />}
            </TabPane>
            <TabPane tabId="3" className="grid-tab-pane">
              {supplier && <SupplierDocumentTab supplier={supplier} />}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default DetailsSupplierPage;
