import React from "react";
const CarouselPage = ({ component }: { component?: React.ReactNode }) => {
  return (
    <React.Fragment>
      <div className="col-xxl-12 col-lg-12 col-md-12">
        <div className="auth-bg  d-flex  justify-content-center align-items-center">
          <div className="bg-overlay bg-light"></div>
          {/* <ul className="bg-bubbles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul> */}
          <div className="row justify-content-center align-items-center">
            {component}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselPage;
