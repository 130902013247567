import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import { useHistory } from "react-router";
import { deleteCoupon, getCouponDetails } from "src/store/coupons/saga";
import MainPage from "src/components/PageContent/MainPage";
import { CouponDto } from "src/store/coupons/type";
import CreateCoupon from "./create";
import EditCoupon from "./edit";
import CreateMultipleCoupon from "./create-multiple";

const CouponPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDelete, setShowDelete] = useState<CouponDto>();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<CouponDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showCreateMultiple, setShowCreateMultiple] = useState(false);
  const columns = [
    {
      caption: t("Grup"),
      dataField: "CouponGroupName",
      groupIndex: 0,
    },
    {
      caption: t("Code"),
      dataField: "Code",
    },

    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Paket"),
      dataField: "Package",
      dataType: "boolean",
    },
    {
      caption: t("Paket İndirim Oranı"),
      dataField: "PackageDiscountRate",
    },
    {
      caption: t("Privilege"),
      dataField: "Privilege",
      dataType: "boolean",
    },
    {
      caption: t("Hizmet İndirim Oranı"),
      dataField: "PrivilegeDiscountRate",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
    {
      caption: t("Dahil Paketler"),
      dataField: "IncludedPackages",
      cellRender: (e: any) => (
        <>
          {e.row.data?.CouponIncludedPackages.map(
            (x: any) => x.PackageName
          )?.toString()}
        </>
      ),
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            return history.push("/coupon/details/1/" + e.row.data.Id);
          },
        },
        {
          hint: "Düzenle",
          icon: "edit",
          onClick: async (e: any) => {
            dispatch(
              getCouponDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowEdit(true);
                  },
                  onError: () => {},
                },
                id: e.row.data.Id,
              })
            );
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
  };
  return (
    <React.Fragment>
      {selected && (
        <EditCoupon
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateCoupon
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <CreateMultipleCoupon
        onHide={() => setShowCreateMultiple(false)}
        open={showCreateMultiple}
        onSuccessClick={onSuccessClick}
      />
      <MainPage
        title={t("CouponListTitle")}
        useDevGrid={true}
        devProps={{
          columns: columns,
          create: () => setShowCreate(true),
          url: "/api/coupons/devext",
          gridButtons: [
            {
              buttonText: "Çoklu Giriş",
              icon: "add",
              onclick: () => setShowCreateMultiple(true),
              type: "normal",
            },
          ],
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete coupon`, {
            name: `${showDelete.Code} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCoupon({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CouponPage;
