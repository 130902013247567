import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import { getPrivilegeListForCustomerFilter } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import * as Yup from "yup";
export interface PrivilegeFilterDto {
  PrivilegeId: string;
  PrivilegeFilterTypeId: number;
}
const PrivilegeFilterComp = ({
  onHide,
  open,
  setData,
}: {
  setData: (filter: PrivilegeFilterDto) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const privilegeFilterTypes = [
    { Id: 1, Text: "Paketinde Olanlar" },
    { Id: 2, Text: "Paketinde Olmayanlar" },
    { Id: 3, Text: "Kullanmış Olanlar" },
    { Id: 4, Text: "Kullanmamış Olanlar" },
  ];

  useEffect(() => {
    open &&
      dispatch(
        getPrivilegeListForCustomerFilter({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <CustomModal onHide={onHide} open={open} size="xs" title={" "}>
        <Formik
          onSubmit={values => {
            setData(values);
            return onHide();
          }}
          initialValues={{
            PrivilegeId: "",
            PrivilegeFilterTypeId: 1,
          }}
          validationSchema={Yup.object().shape({
            PrivilegeId: Yup.string().required(t("Required")),
            PrivilegeFilterTypeId: Yup.number().required(t("Required")),
          })}
        >
          {({ values, handleSubmit }) => (
            <Row className="mx-3 p-3">
              <GetInput
                inputprop={{
                  field: "PrivilegeId",
                  inputType: InputType.multiselect,
                  label: t("Hizmet"),
                  lookup: {
                    data: privileges,
                    labelKey: "Title",
                    valueKey: "Id",
                    placeholder: t("Seçiniz"),
                  },
                }}
              />
              <GetInput
                inputprop={{
                  field: "PrivilegeFilterTypeId",
                  inputType: InputType.radiogrup,
                  label: t(""),
                  radiogrups: {
                    data: privilegeFilterTypes,
                    labelKey: "Text",
                    valueKey: "Id",
                  },
                }}
              />
              <div className={""}>
                <Button
                  color="success"
                  type="submit"
                  className="w-100"
                  onClick={() => handleSubmit()}
                >
                  {t("Filtre Uygula")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};

export default PrivilegeFilterComp;
