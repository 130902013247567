import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SupplierLocationDocumentDto, SupplierLocationDto } from "./type";

export const getSupplierLocationListBySupplierId = createAction(
  "GET_SUPPLIER_LOCATION_LIST_BY_SUPPLIER_ID",
  ({ payload, params, id }: SagaActionParams<SupplierLocationDto[]>) => ({
    payload,
    url:
      "/api/supplierlocations/getbysupplierid/" + id + (params ? params : ""),
  })
);
export const getSupplierLocationDocument = createAction(
  "GET_SUPPLIER_LOCATION_DOCUMENT_LIST",
  ({ payload, id }: SagaActionParams<SupplierLocationDocumentDto[]>) => ({
    payload,
    url: "/api/supplierdocuments/getbysupplierlocationid/" + id,
  })
);
export const getSupplierLocationList = createAction(
  "GET_SUPPLIER_LOCATION_LIST",
  ({ payload, params }: SagaActionParams<SupplierLocationDto[]>) => ({
    payload,
    url: "/api/supplierlocations" + (params ? params : ""),
  })
);
export const getSupplierLocationDetails = createAction(
  "GET_SUPPLIER_LOCATION_DETAILS",
  ({ payload, id }: SagaActionParams<SupplierLocationDto>) => ({
    payload,
    url: "/api/supplierlocations/" + id,
  })
);
export const createSupplierLocation = createAction(
  "CREATE_SUPPLIER_LOCATION_DETAILS",
  ({ payload, body }: SagaActionParams<SupplierLocationDto>) => ({
    payload,
    url: "/api/supplierlocations",
    body,
  })
);
export const updateSupplierLocation = createAction(
  "UPDATE_SUPPLIER_LOCATION_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierLocationDto>) => ({
    payload,
    url: "/api/supplierlocations/" + id,
    body,
  })
);
export const deleteSupplierLocation = createAction(
  "DELETE_SUPPLIER_LOCATION_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierLocationDto>) => ({
    payload,
    url: "/api/supplierlocations/" + id,
    body,
  })
);
const supplierlocationSaga = [
  takeLatest(
    getSupplierLocationDocument.toString(),
    (payload: SagaGenericParams<SupplierLocationDocumentDto[]>) =>
      getListSaga<SupplierLocationDocumentDto[]>(payload)
  ),
  takeLatest(
    getSupplierLocationListBySupplierId.toString(),
    (payload: SagaGenericParams<SupplierLocationDto[]>) =>
      getListSaga<SupplierLocationDto[]>(payload)
  ),
  takeLatest(
    getSupplierLocationList.toString(),
    (payload: SagaGenericParams<SupplierLocationDto[]>) =>
      getListSaga<SupplierLocationDto[]>(payload)
  ),
  takeLatest(
    getSupplierLocationDetails.toString(),
    (payload: SagaGenericParams<SupplierLocationDto>) =>
      getListSaga<SupplierLocationDto>(payload)
  ),
  takeLatest(
    createSupplierLocation.toString(),
    (payload: PostSagaGenericParams<SupplierLocationDto>) =>
      postSaga<SupplierLocationDto>(payload)
  ),
  takeLatest(
    updateSupplierLocation.toString(),
    (payload: PostSagaGenericParams<SupplierLocationDto>) =>
      putSaga<SupplierLocationDto>(payload)
  ),
  takeLatest(
    deleteSupplierLocation.toString(),
    (payload: SagaGenericParams<SupplierLocationDto>) =>
      deleteSaga<SupplierLocationDto>(payload)
  ),
];

export default supplierlocationSaga;
