import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ProductImageDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getProductImageListByProductId = createAction(
  "GET_PRODUCT_IMAGE_LIST_BY_PRODUCT_ID",
  ({ payload, id }: SagaActionParams<ProductImageDto[]>) => ({
    payload,
    url: "/api/productimages/byproductid/" + id,
  })
);
export const uploadProductImage = createAction(
  "UPLOAD_PRODUCT_IMAGE",
  ({ payload, body }: SagaActionParams<ProductImageDto[]>) => ({
    payload,
    body,
    url: "/api/productimages/multiple",
  })
);
export const setMainImage = createAction(
  "SET_MAIN_IMAGE",
  ({ payload, body }: SagaActionParams<ProductImageDto[]>) => ({
    payload,
    body,
    url: "/api/productimages/main-image",
  })
);
export const deleteProductImage = createAction(
  "DELETE_PRODUCT_IMAGE",
  ({ payload, id }: SagaActionParams<ProductImageDto[]>) => ({
    payload,
    url: "/api/productimages/" + id,
  })
);
const productImageSaga = [
  takeLatest(
    getProductImageListByProductId.toString(),
    (payload: SagaGenericParams<ProductImageDto[]>) =>
      getListSaga<ProductImageDto[]>(payload)
  ),
  takeLatest(
    uploadProductImage.toString(),
    (payload: PostSagaGenericParams<ProductImageDto[]>) =>
      postSaga<ProductImageDto[]>(payload)
  ),
  takeLatest(
    setMainImage.toString(),
    (payload: PostSagaGenericParams<ProductImageDto[]>) =>
      putSaga<ProductImageDto[]>(payload)
  ),
  takeLatest(
    deleteProductImage.toString(),
    (payload: PostSagaGenericParams<ProductImageDto[]>) =>
      deleteSaga<ProductImageDto[]>(payload)
  ),
];

export default productImageSaga;
