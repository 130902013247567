import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import DrageAndDropTable from "src/components/DraggableTable";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import {
  changePackageExtraOrder,
  deletePackageExtra,
  getPackageExtraDetails,
  getPackageExtraList,
} from "src/store/packageextra/saga";
import { PackageExtraPrivilegeDto } from "src/store/packageextra/type";
import { PackageDetailDto } from "src/store/packages/type";
import CreateExtraModal from "./create";
import EditExtraModal from "./edit";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";

const PackageExtraTab = ({
  packageData,
  setData,
}: {
  setData: (pack: PackageDetailDto) => void;
  packageData: PackageDetailDto;
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { tab }: { tab: string } = useParams();
  const [showDelete, setShowDelete] = useState<PackageExtraPrivilegeDto>();
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PackageExtraPrivilegeDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [extras, setExtras] = useState<PackageExtraPrivilegeDto[]>([]);
  useEffect(() => {
    tab === "4" &&
      dispatch(
        getPackageExtraList({
          payload: {
            onSuccess: (msg, payload) => setExtras(payload),
            onError: () => {},
          },
          id: packageData.Id,
        })
      );
  }, [dispatch, packageData.Id, tab]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPackageExtraList({
        payload: {
          onSuccess: (msg, payload) => setExtras(payload),
          onError: () => {},
        },
        id: packageData.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Package Name"),
      dataField: "Name",
    },

    {
      text: t("Count"),
      dataField: "Count",
    },
    {
      text: t("Price"),
      dataField: "Price",
    },
    {
      text: t("Ceza Bedeli"),
      dataField: "PenaltyPrice",
    },
    {
      text: t("Yearly"),
      dataField: "Yearly",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="edit"
              onClick={() => {
                dispatch(
                  getPackageExtraDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditExtraModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreateExtraModal
          selectedPackage={packageData}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        <DrageAndDropTable
          columns={columns}
          addTitle={t("Ekle")}
          create={{
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          }}
          data={extras}
          dragAction={changePackageExtraOrder}
          onSuccessClick={onSuccessClick}
        />
      </div>
      {showDelete && (
        <CancelAlert
          message={t("Silmek istediğinize emin misiniz")}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePackageExtra({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PackageExtraTab;
