import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updatePackageExtra } from "src/store/packageextra/saga";
import { PackageExtraPrivilegeDto } from "src/store/packageextra/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import * as Yup from "yup";

const EditPackageExtraModal = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: PackageExtraPrivilegeDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit Extra Privilege to Package"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PackageId: data.PackageId,
          PrivilegeId: data.PrivilegeId ?? "",
          Name: data.Name ?? "",
          Count: data.Count,
          Active: data.Active,
          Yearly: data.Yearly,
          Price: data.Price ?? 0,
          PenaltyPrice: data.PenaltyPrice ?? 0,
        }}
        onSubmit={values => {
          dispatch(
            updatePackageExtra({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick("Ek Hizmet başarıyla güncellenmiştir");
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.mixed().required(t("Required")),
          Price: Yup.number().required(t("Required")),
          Count: Yup.number().required(t("Required")),
          PenaltyPrice: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Mobilde Görünecek Ad"),
          },
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },
          {
            field: "Price",
            label: t("Price"),
            inputType: InputType.number,
          },
          {
            field: "PenaltyPrice",
            label: t("Ceza Bedeli"),
            inputType: InputType.number,
          },
          {
            field: "Yearly",
            label: t("Yıllık"),
            inputType: InputType.checkbox,
          },
          {
            field: "Active",
            label: t("Active"),
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.checkbox,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPackageExtraModal;
