import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCustomerValidatedCodeList } from "src/store/customerprivilege/saga";
import { useParams } from "react-router";
import { CustomerUsedDto } from "src/store/customerprivilege/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import TransactionModal from "./transactions";
import { getLocalDate } from "src/helpers/getLocalDate";
import DetailTable from "src/components/PageContent/detailTable";

const UsedPrivilegeTab = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<CustomerUsedDto[]>([]);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<CustomerUsedDto>();
  const { id }: { id: string; tab: string } = useParams();
  const columns = [
    {
      text: t("Privilege"),
      dataField: "PrivilegeName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },
    {
      text: t("QR Kod"),
      dataField: "QRCode",
    },
    {
      text: t("Tarih"),
      dataField: "ValidatedDate",
      formatter: (e: any) => getLocalDate(e),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              text: "Hareketler",
            }}
            onClick={() => {
              setSelected(data);
              return setOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DetailTable
        title=" "
        listData={data}
        columns={columns}
        setDataList={setData}
        action={getCustomerValidatedCodeList}
        paramsid={id}
      />
      {selected && (
        <TransactionModal
          selected={selected}
          onHide={() => setOpen(false)}
          open={open}
        />
      )}
    </React.Fragment>
  );
};

export default UsedPrivilegeTab;
