import { Checkbox } from "rsuite";
import { Props } from "../type";

const CustomCheckbox = ({
  label,
  error,
  field,
  value,
  touched,
  onChange,
  disabled,
}: Props) => {
  return (
    <div className=" my-2" style={{ left: "-8px" }}>
      <Checkbox
        disabled={disabled}
        checked={value}
        onChange={(value, checked) => onChange && onChange(checked)}
      >
        <label
          className={
            error && touched ? "errorLabel customlabel" : "customlabel"
          }
          htmlFor={field}
        >
          {label ? label : ""}
        </label>
      </Checkbox>
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomCheckbox;
