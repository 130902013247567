const images = {
  ispark: require("./ispark.png").default,

  logo: require("./logo.svg").default,
  logopng: require("./logo.png").default,
  click: require("./click.svg").default,
  doc: require("./doc.png").default,
  avatar: require("./avatar.png").default,
  cardchip: require("./cardchip.png").default,
  yellowcheck: require("./yellowcheck.png").default,
  excelicon: require("./excelicon.png").default,
  customerexcel: require("./customerexcel.png").default,
  noimage: require("./noimage.png").default,
  filedownload: require("./import-file-icon.jpg").default,
  high: require("./priority-high.svg").default,
  highest: require("./priority-highest.svg").default,
  medium: require("./priority-medium.svg").default,
  pdf: require("./pdf.png").default,
  maintenanceExcel: require("./maintenanceExcel.png").default,
};

export default images;
