import { useEffect, useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import DataGrid, {
  Column,
  Editing,
  Item,
  Lookup,
  RequiredRule,
  Toolbar,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import {
  BodyType,
  createInnerRequestSaga,
} from "src/store/base/request/request";
import { verfiyResponse } from "src/store/base/types/saga";
import { MaintenanceDraftDetailDto } from "src/store/maintenance/maintenance-drafts/type";
import { ServiceMaintenanceCardDto } from "src/store/maintenance/service-maintenance-cards/type";
import { getServiceMaintenanceCardLookup } from "src/store/maintenance/service-maintenance-cards/saga";

const MaintenanceDraftItems = ({
  data,
  refreshData,
}: {
  data: MaintenanceDraftDetailDto;
  refreshData?: () => void;
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector(getApiParams);
  const dataGridRef = useRef<DataGrid>();
  const [cards, setCards] = useState<ServiceMaintenanceCardDto[]>([]);
  useEffect(() => {
    dispatch(
      getServiceMaintenanceCardLookup({
        payload: {
          onSuccess: (msg, payload) => setCards(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  const store = new CustomStore({
    key: "Id",
    load: loadOptions => {
      return createInnerRequestSaga({
        url: "/api/maintenancedraftitems/bydraftId/" + data.Id,
        method: "GET",
        lang: "en-US",
        token: token,
      });
    },
    insert: async values => {
      const resp = await createInnerRequestSaga({
        url: "/api/maintenancedraftitems",
        body: { ...values, MaintenanceDraftId: data.Id },
        method: "POST",
        bodyType: BodyType.formdata,
        lang: "en-US",
        token: token,
      });
      refreshData && refreshData();
      return resp;
    },
    remove: async key => {
      const response = await createInnerRequestSaga({
        url: "/api/maintenancedraftitems/" + key,
        method: "DELETE",
        lang: "en-US",
        token: token,
      });
      if (!verfiyResponse(response)) {
        toast.error(response.Message);
      }
      refreshData && refreshData();
      return response;
    },
    update: async (key, values) => {
      const items = dataGridRef.current?.instance.getDataSource().items();
      const body = items?.find(x => x.Id === key);
      const response = await createInnerRequestSaga({
        url: "/api/maintenancedraftitems/" + key,
        body: { ...body, ...values, Id: key },
        method: "PUT",
        bodyType: BodyType.formdata,
        lang: "en-US",
        token: token,
      });
      if (!verfiyResponse(response)) {
        toast.error(response.Message);
      }
      refreshData && refreshData();
      return response;
    },
  });
  return (
    <Row className="mb-3">
      <Col md={12} className="px-3">
        <DataGrid
          //@ts-ignore
          ref={dataGridRef}
          key="Id"
          dataSource={store}
          showBorders={true}
          allowColumnResizing
        >
          <Column dataField="ServiceMaintenanceCardId" caption="Bakım Kartı">
            <Lookup dataSource={cards} valueExpr="Id" displayExpr="FullName" />
            <RequiredRule />
          </Column>
          <Column dataField="Quantity" caption="Adet" dataType="number">
            <RequiredRule />
          </Column>
          <Column dataField="TaxRate" caption="KDV" dataType="number">
            <RequiredRule />
          </Column>
          <Column dataField="Price" caption="Fiyat" dataType="number">
            <RequiredRule />
          </Column>
          <Editing
            mode="form"
            allowDeleting
            allowAdding
            allowUpdating
            useIcons
          />
          <Toolbar>
            {/* <Item location="before">
              <div className="informer">
                <span className="">Bakım Detay Satırları</span>
              </div>
            </Item> */}
            <Item name="addRowButton" location="after" />
          </Toolbar>
        </DataGrid>
      </Col>
    </Row>
  );
};
export default MaintenanceDraftItems;
