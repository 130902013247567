import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { getMembershipcancelrequestList } from "src/store/membership-cancel-request/saga";
import { MembershipcancelRequestDto } from "src/store/membership-cancel-request/type";

const MembershipCancellationRequestPage = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<MembershipcancelRequestDto[]>([]);

  const columns = [
    {
      text: t("Customer"),
      dataField: "CustomerName",
    },
    {
      text: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      text: t("İptal Sebebi"),
      dataField: "MembershipCancellationRequestReasons",
      formatter: (c: any, data: MembershipcancelRequestDto) =>
        data.MembershipCancellationRequestReasons &&
        data.MembershipCancellationRequestReasons.length > 0
          ? data.MembershipCancellationRequestReasons[0]
              .MembershipCancelTypeName
          : "-",
    },
    {
      text: t("Durum"),
      dataField: "MembershipCancellationRequestStatusName",
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        title={t("MembershipCancellationRequestTitle")}
        gridProps={{
          listData: data,
          columns: columns,

          setDataList: setData,
          action: getMembershipcancelrequestList,
        }}
      />
    </React.Fragment>
  );
};

export default MembershipCancellationRequestPage;
