import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SupplierDto } from "src/store/supplier/type";
import MainPage from "src/components/PageContent/MainPage";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import { deleteSupplier, getSupplierList } from "src/store/supplier/saga";
import { useHistory } from "react-router";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";

const SupplierPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch(); // eslint-disable-next-line
  const [data, setData] = useState<SupplierDto[]>([]);
  const [showDelete, setShowDelete] = useState<SupplierDto>();
  const user = useSelector(userSelector);

  const columns = [
    {
      caption: t("Brand"),
      dataField: "Brand",
    },
    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Yetkili Adı"),
      dataField: "PersonName",
    },
    {
      caption: t("Yetkili Soyadı"),
      dataField: "PersonSurname",
      renderAsync: () => "asdfasdf",
    },
    {
      caption: t("Tax Number"),
      dataField: "TaxNumber",
    },
    {
      caption: t("Tax Office"),
      dataField: "TaxOffice",
    },
    {
      caption: t("City"),
      dataField: "CityName",
    },
    {
      caption: t("Town"),
      dataField: "TownName",
    },
    {
      caption: t("Contract Start Date"),
      dataField: "ContractStartDate",
      dataType: "date",
    },
    {
      caption: t("Contract End Date"),
      dataField: "ContractEndDate",
      dataType: "date",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/supplier/details/1/" + e.row.key);
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          visible: CheckRole(user),
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getSupplierList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/suppliers/devexp",
          columns: columns,
          create: () => history.push("/supplier/create"),
        }}
        useDevGrid={true}
        title={t("SupplierTitle")}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete supplier`, {
            name: `${showDelete.Name} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteSupplier({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default SupplierPage;
