import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { AboutUsDto } from "src/store/web/type";
import { getAboutUs, updateAboutUs } from "src/store/web/saga";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const AboutUsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<AboutUsDto>();
  useEffect(() => {
    dispatch(
      getAboutUs({
        payload: {
          onSuccess: (msg, r) => setData(r),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Hakkımızda")}
            breadcrumbItem={t("Hakkımızda")}
          />

          <Row className="mt-4">
            {loader && <Loader />}
            {!loader && (
              <CustomFormik
                loader
                initialValues={{
                  id: data?.Id ?? "",
                  title: data?.Title ?? "",
                  description: data?.Description ?? "",
                }}
                onSubmit={values => {
                  dispatch(
                    updateAboutUs({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          setData(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({})}
                inputs={[
                  {
                    field: "title",
                    label: t("Title"),
                    inputType: InputType.editor,
                  },
                  {
                    field: "description",
                    label: t("Description"),
                    inputType: InputType.editor,
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default AboutUsPage;
