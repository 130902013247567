import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import DataGrid from "devextreme-react/data-grid";
import CreateCorporateContract from "./create";
import CancelAlert from "src/components/CancelAlert";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import EditCorporateContract from "./edit";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { EnumTabs } from "../tabList";
import {
  deleteCorporateContracts,
  getCorporateContractsDetails,
} from "src/store/corporate-contracts/saga";
import {
  CorporateContractDetailDto,
  CorporateContractDto,
} from "src/store/corporate-contracts/type";
import DetailCorporateContract from "./detail";
import "./style.scss";
const CorporateContractsPage = ({
  refreshData,
}: {
  refreshData: () => void;
}) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid>();
  const { id, tab }: any = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<CorporateContractDto>();
  const [showDetail, setShowDetail] = useState<{
    show: boolean;
    selected: CorporateContractDetailDto;
  }>();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<CorporateContractDto>();
  const dispatch = useDispatch();
  const columns = [
    {
      caption: t("Toplam Adet"),
      dataField: "TotalAccount",
    },
    {
      caption: t("Fatura Günü"),
      dataField: "InvoiceDay",
    },
    {
      caption: t("Ödeme Günü"),
      dataField: "PaymentDay",
    },
    {
      caption: t("Start Date"),
      dataField: "StartDate",
      dataType: "datetime",
    },
    {
      caption: t("End Date"),
      dataField: "EndDate",
      dataType: "datetime",
    },

    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            dispatch(
              getCorporateContractsDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    return setShowDetail({
                      show: true,
                      selected: payload,
                    });
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Düzenle",
          icon: "edit",
          onClick: async (e: any) => {
            dispatch(
              getCorporateContractsDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowEdit(true);
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {tab === EnumTabs.Contracts && (
        <>
          <CustomDataGrid
            ref={dataGridRef}
            title={t("Departmanlar")}
            rowAlternationEnabled
            url={"/api/corporatecontracts/devexp/" + id}
            columns={columns}
            create={() => setShowCreate(true)}
          />
          <CreateCorporateContract
            onHide={() => setShowCreate(false)}
            onSuccessClick={m => {
              toast.success(m);
              return refreshData();
            }}
            open={showCreate}
          />
          {selected && (
            <EditCorporateContract
              data={selected}
              onHide={() => setShowEdit(false)}
              onSuccessClick={m => toast.success(m)}
              open={showEdit}
            />
          )}
          {showDetail && (
            <DetailCorporateContract
              data={showDetail.selected}
              onHide={() =>
                setShowDetail({
                  selected: showDetail.selected,
                  show: false,
                })
              }
              open={showDetail.show}
            />
          )}
          {showDelete && (
            <CancelAlert
              message={"Silmek istediğinize emin misiniz?"}
              onConfirm={() => {
                setShowDelete(undefined);
                dispatch(
                  deleteCorporateContracts({
                    payload: {
                      onSuccess: message => toast.success(message),
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    id: showDelete.Id,
                  })
                );
              }}
              onCancel={() => {
                setShowDelete(undefined);
              }}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default CorporateContractsPage;
