import moment from "moment";
import { Table } from "reactstrap";
import { TicketHistoryDto } from "src/store/ticket/type";

const TicketHistories = ({ histories }: { histories: TicketHistoryDto[] }) => {
  const columns = [
    {
      text: "No",
      dataField: "Number",
    },
    {
      text: "Kayıt Tarihi",
      dataField: "Date",
      formatter: (c: any) => moment(c).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: "Kaydeden",
      dataField: "RecorderUserName",
    },
    {
      text: "Statü",
      dataField: "CurrentTicketStatusTypeName",
    },
    {
      text: "Aksiyon",
      dataField: "TicketStatusTypeName",
    },
    {
      text: "Atanan",
      dataField: "AssignedUserName",
    },
    {
      text: "Açıklama",
      dataField: "Description",
    },
  ];
  return (
    <>
      <Table
        className="detailTable table mb-0 bg-white"
        style={{ background: "white", tableLayout: "fixed", width: "100%" }}
      >
        <thead className="">
          <tr>
            {Array.from(new Set(columns.map((x: any) => x.text))).map(
              (y: any) => {
                return <th>{y}</th>;
              }
            )}
          </tr>
        </thead>
        <tbody>
          {histories.map((dataItem: any, i) => (
            <tr key={i}>
              {columns.map((x: any) => {
                return (
                  <td>
                    {x.formatter
                      ? x.formatter(dataItem[x.dataField], dataItem)
                      : dataItem[x.dataField]}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
export default TicketHistories;
