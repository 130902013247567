import { Toggle } from "rsuite";
import { Props } from "../type";

const CustomToggle = ({
  label,
  error,
  field,
  value,
  touched,
  onChange,
  disabled,
  checkedLabel,
  uncheckedLabel,
}: Props) => {
  return (
    <div className=" my-2" style={{ left: "-8px" }}>
      <Toggle
        checked={value}
        checkedChildren={checkedLabel}
        unCheckedChildren={uncheckedLabel}
        onChange={checked => onChange && onChange(checked)}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomToggle;
