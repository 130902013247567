import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SupplierDto, SupplierServiceStatusTypeDto } from "./type";

export const getSupplierList = createAction(
  "GET_SUPPLIER_LIST",
  ({ payload, params }: SagaActionParams<SupplierDto[]>) => ({
    payload,
    url: "/api/suppliers" + (params ? params : ""),
  })
);

export const getSupplierLookup = createAction(
  "GET_SUPPLIER_LOOKUP",
  ({ payload, params }: SagaActionParams<SupplierDto[]>) => ({
    payload,
    url: "/api/suppliers/lookup",
  })
);
export const getSupplierDetails = createAction(
  "GET_SUPPLIER_DETAILS",
  ({ payload, id }: SagaActionParams<SupplierDto>) => ({
    payload,
    url: "/api/suppliers/" + id,
  })
);
export const createSupplier = createAction(
  "CREATE_SUPPLIER_DETAILS",
  ({ payload, body }: SagaActionParams<SupplierDto>) => ({
    payload,
    url: "/api/suppliers",
    body,
  })
);
export const updateSupplier = createAction(
  "UPDATE_SUPPLIER_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierDto>) => ({
    payload,
    url: "/api/suppliers/" + id,
    body,
  })
);
export const deleteSupplier = createAction(
  "DELETE_SUPPLIER_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierDto>) => ({
    payload,
    url: "/api/suppliers/" + id,
    body,
  })
);
export const getSupplierServiceStatusTypeList = createAction(
  "GET_SUPPLIER_SERVICE_STATUS_LIST",
  ({ payload }: SagaActionParams<SupplierServiceStatusTypeDto[]>) => ({
    payload,
    url: "/api/system/supplier-service-status-types",
  })
);

const supplierSaga = [
  takeLatest(
    getSupplierList.toString(),
    (payload: SagaGenericParams<SupplierDto[]>) =>
      getListSaga<SupplierDto[]>(payload)
  ),
  takeLatest(
    getSupplierLookup.toString(),
    (payload: SagaGenericParams<SupplierDto[]>) =>
      getListSaga<SupplierDto[]>(payload)
  ),
  takeLatest(
    getSupplierDetails.toString(),
    (payload: SagaGenericParams<SupplierDto>) =>
      getListSaga<SupplierDto>(payload)
  ),
  takeLatest(
    createSupplier.toString(),
    (payload: PostSagaGenericParams<SupplierDto>) =>
      postSaga<SupplierDto>(payload)
  ),
  takeLatest(
    updateSupplier.toString(),
    (payload: PostSagaGenericParams<SupplierDto>) =>
      putSaga<SupplierDto>(payload)
  ),
  takeLatest(
    deleteSupplier.toString(),
    (payload: SagaGenericParams<SupplierDto>) =>
      deleteSaga<SupplierDto>(payload)
  ),
  takeLatest(
    getSupplierServiceStatusTypeList.toString(),
    (payload: SagaGenericParams<any[]>) => getListSaga<any[]>(payload)
  ),
];

export default supplierSaga;
