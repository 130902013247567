import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireDiameterDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireDiameterList = createAction(
  "GET_TIRE_DIAMETER_LIST",
  ({ payload, params }: SagaActionParams<TireDiameterDto[]>) => ({
    payload,
    url: "/api/tirediameters" + (params ? params : ""),
  })
);
export const getTireDiameterDetails = createAction(
  "GET_TIRE_DIAMETER_DETAILS",
  ({ payload, id }: SagaActionParams<TireDiameterDto>) => ({
    payload,
    url: "/api/tirediameters/" + id,
  })
);
export const createTireDiameter = createAction(
  "CREATE_TIRE_DIAMETER_DETAILS",
  ({ payload, body }: SagaActionParams<TireDiameterDto>) => ({
    payload,
    url: "/api/tirediameters",
    body,
  })
);
export const updateTireDiameter = createAction(
  "UPDATE_TIRE_DIAMETER_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireDiameterDto>) => ({
    payload,
    url: "/api/tirediameters/" + id,
    body,
  })
);
export const deleteTireDiameter = createAction(
  "DELETE_TIRE_DIAMETER_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireDiameterDto>) => ({
    payload,
    url: "/api/tirediameters/" + id,
    body,
  })
);

const tireDiameterSaga = [
  takeLatest(
    getTireDiameterList.toString(),
    (payload: SagaGenericParams<TireDiameterDto[]>) =>
      getListSaga<TireDiameterDto[]>(payload)
  ),
  takeLatest(
    getTireDiameterDetails.toString(),
    (payload: SagaGenericParams<TireDiameterDto>) =>
      getListSaga<TireDiameterDto>(payload)
  ),
  takeLatest(
    createTireDiameter.toString(),
    (payload: PostSagaGenericParams<TireDiameterDto>) =>
      postSaga<TireDiameterDto>(payload)
  ),

  takeLatest(
    updateTireDiameter.toString(),
    (payload: PostSagaGenericParams<TireDiameterDto>) =>
      putSaga<TireDiameterDto>(payload)
  ),
  takeLatest(
    deleteTireDiameter.toString(),
    (payload: SagaGenericParams<TireDiameterDto>) =>
      deleteSaga<TireDiameterDto>(payload)
  ),
];

export default tireDiameterSaga;
