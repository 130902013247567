import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Container } from "reactstrap";
import DataGrid from "devextreme-react/data-grid";
import MetaTags from "react-meta-tags";
import "./style.scss";
import CreateCorporateClient from "./create";
import { CorporateClientsDto } from "src/store/corporate-clients/type";
import CancelAlert from "src/components/CancelAlert";
import {
  deleteCorporateClients,
  getCorporateClientsDetails,
} from "src/store/corporate-clients/saga";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import EditCorporateClient from "./edit";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
const renderTitleHeader = (data: any) => {
  return (
    <div style={{ height: 40, whiteSpace: "pre-wrap", textAlign: "center" }}>
      {data.column.caption}
    </div>
  );
};
const CorporateCustomersPage = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid>();
  const history = useHistory();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<CorporateClientsDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<CorporateClientsDto>();
  const dispatch = useDispatch();
  const columns = [
    {
      caption: t("Durum"),
      dataField: "CorporateClientStatusName",
    },
    {
      caption: t("Tip"),
      dataField: "CorporateTypeName",
    },

    {
      caption: t("Firma Adı"),
      dataField: "CompanyName",
    },
    {
      caption: t("Yetkili"),
      dataField: "AuthorizedPerson",
    },

    {
      caption: t("Vergi No"),
      dataField: "TaxId",
    },
    {
      caption: t("E-posta"),
      dataField: "Email",
    },
    {
      caption: t("Telefon"),
      dataField: "Phone",
    },
    {
      caption: t("City"),
      dataField: "CityCityName",
    },
    {
      caption: t("Town"),
      dataField: "TownTownName",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/corporate-client/details/1/" + e.row.key);
          },
        },
        {
          hint: "Düzenle",
          icon: "edit",
          onClick: async (e: any) => {
            dispatch(
              getCorporateClientsDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowEdit(true);
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{t("Kurumsal Müşteriler")}</title>
        </MetaTags>
        <Container fluid>
          <React.Fragment>
            <div
              className={
                "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              <h5 className="mb-0 font-size-18">{t("Kurumsal Müşteriler")}</h5>
            </div>
            <CustomDataGrid
              ref={dataGridRef}
              title={t("Kurumsal Müşteriler")}
              rowAlternationEnabled
              stateStoring={{
                enabled: true,
                type: "localStorage",
              }}
              url={"/api/corporateclients/devexp"}
              columns={columns.map(column => {
                return { ...column, headerCellRender: renderTitleHeader };
              })}
              create={() => setShowCreate(true)}
            />
            <CreateCorporateClient
              onHide={() => setShowCreate(false)}
              onSuccessClick={m => toast.success(m)}
              open={showCreate}
            />

            {selected && (
              <EditCorporateClient
                data={selected}
                onHide={() => setShowEdit(false)}
                onSuccessClick={m => toast.success(m)}
                open={showEdit}
              />
            )}
          </React.Fragment>
        </Container>
      </div>
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCorporateClients({
                payload: {
                  onSuccess: message => toast.success(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CorporateCustomersPage;
