import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CorporateContractDetailDto, CorporateContractDto } from "./type";
import { PackageDto } from "../packages/type";
export const getCorporateContracts = createAction(
  "GET_CORPORATE_CONTRACTS",
  ({ payload, params }: SagaActionParams<CorporateContractDto[]>) => ({
    payload,
    url: "/api/CorporateContracts" + (params ? params : ""),
  })
);
export const getCorporateContractsDetails = createAction(
  "GET_CORPORATE_CONTRACTS_DETAILS",
  ({ payload, id }: SagaActionParams<CorporateContractDetailDto>) => ({
    payload,
    url: "/api/CorporateContracts/" + id,
  })
);
export const createCorporateContracts = createAction(
  "CREATE_CORPORATE_CONTRACTS",
  ({ payload, body }: SagaActionParams<CorporateContractDto>) => ({
    payload,
    url: "/api/CorporateContracts",
    body,
  })
);
export const updateCorporateContracts = createAction(
  "UPDATE_CORPORATE_CONTRACTS",
  ({ payload, id, body }: SagaActionParams<CorporateContractDto>) => ({
    payload,
    url: "/api/CorporateContracts/" + id,
    body,
  })
);
export const deleteCorporateContracts = createAction(
  "DELETE_CORPORATE_CONTRACTS",
  ({ payload, id }: SagaActionParams<CorporateContractDto>) => ({
    payload,
    url: "/api/CorporateContracts/" + id,
  })
);

export const getActiveCorporateContract = createAction(
  "GET_ACTIVE_CORPORATE_CONTRACT",
  ({ payload, id }: SagaActionParams<CorporateContractDetailDto>) => ({
    payload,
    url: "/api/corporatecontracts/active/" + id,
  })
);

export const getCorporateContractPackages = createAction(
  "GET_CORPORATE_CONTRACT_PACKAGES",
  ({ payload, id }: SagaActionParams<PackageDto[]>) => ({
    payload,
    url: "/api/corporatecontracts/packages/" + id,
  })
);
const CorporateContractsSaga = [
  takeLatest(
    getCorporateContracts.toString(),
    (payload: SagaGenericParams<CorporateContractDto[]>) =>
      getListSaga<CorporateContractDto[]>(payload)
  ),
  takeLatest(
    getCorporateContractPackages.toString(),
    (payload: SagaGenericParams<CorporateContractDto[]>) =>
      getListSaga<CorporateContractDto[]>(payload)
  ),
  takeLatest(
    getActiveCorporateContract.toString(),
    (payload: SagaGenericParams<CorporateContractDto>) =>
      getListSaga<CorporateContractDto>(payload)
  ),
  takeLatest(
    getCorporateContractsDetails.toString(),
    (payload: SagaGenericParams<CorporateContractDto>) =>
      getListSaga<CorporateContractDto>(payload)
  ),
  takeLatest(
    createCorporateContracts.toString(),
    (payload: PostSagaGenericParams<CorporateContractDto>) =>
      postSaga<CorporateContractDto>(payload)
  ),
  takeLatest(
    updateCorporateContracts.toString(),
    (payload: PostSagaGenericParams<CorporateContractDto>) =>
      putSaga<CorporateContractDto>(payload)
  ),
  takeLatest(
    deleteCorporateContracts.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CorporateContractDto>(payload)
  ),
];

export default CorporateContractsSaga;
