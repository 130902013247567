import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { OrderRefundReasonDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getOrderRefundReasonList = createAction(
  "GET_ORDER_REFUND_REASON_LIST",
  ({ payload, params }: SagaActionParams<OrderRefundReasonDto[]>) => ({
    payload,
    url: "/api/orderrefundreasons" + (params ? params : ""),
  })
);
export const getOrderRefundReasonDetails = createAction(
  "GET_ORDER_REFUND_REASON_DETAILS",
  ({ payload, id }: SagaActionParams<OrderRefundReasonDto>) => ({
    payload,
    url: "/api/orderrefundreasons/" + id,
  })
);
export const createOrderRefundReason = createAction(
  "CREATE_ORDER_REFUND_REASON_DETAILS",
  ({ payload, body }: SagaActionParams<OrderRefundReasonDto>) => ({
    payload,
    url: "/api/orderrefundreasons",
    body,
  })
);
export const updateOrderRefundReason = createAction(
  "UPDATE_ORDER_REFUND_REASON_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderRefundReasonDto>) => ({
    payload,
    url: "/api/orderrefundreasons/" + id,
    body,
  })
);
export const deleteOrderRefundReason = createAction(
  "DELETE_ORDER_REFUND_REASON_DETAILS",
  ({ payload, body, id }: SagaActionParams<OrderRefundReasonDto>) => ({
    payload,
    url: "/api/orderrefundreasons/" + id,
    body,
  })
);
const orderRefundReasonSaga = [
  takeLatest(
    getOrderRefundReasonList.toString(),
    (payload: SagaGenericParams<OrderRefundReasonDto[]>) =>
      getListSaga<OrderRefundReasonDto[]>(payload)
  ),
  takeLatest(
    getOrderRefundReasonDetails.toString(),
    (payload: SagaGenericParams<OrderRefundReasonDto>) =>
      getListSaga<OrderRefundReasonDto>(payload)
  ),
  takeLatest(
    createOrderRefundReason.toString(),
    (payload: PostSagaGenericParams<OrderRefundReasonDto>) =>
      postSaga<OrderRefundReasonDto>(payload)
  ),
  takeLatest(
    updateOrderRefundReason.toString(),
    (payload: PostSagaGenericParams<OrderRefundReasonDto>) =>
      putSaga<OrderRefundReasonDto>(payload)
  ),
  takeLatest(
    deleteOrderRefundReason.toString(),
    (payload: SagaGenericParams<OrderRefundReasonDto>) =>
      deleteSaga<OrderRefundReasonDto>(payload)
  ),
];

export default orderRefundReasonSaga;
