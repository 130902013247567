import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { ProductCategoryBannerDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";
import { BodyType } from "src/store/base/request/request";

export const getProductCategoryBannerList = createAction(
  "GET_PRODUCT_CATEGORY_BANNER_LIST",
  ({ payload, params }: SagaActionParams<ProductCategoryBannerDto[]>) => ({
    payload,
    url: "/api/productcategorybanners" + (params ? params : ""),
  })
);
export const getProductCategoryBannerDetails = createAction(
  "GET_PRODUCT_CATEGORY_BANNER_DETAILS",
  ({ payload, id }: SagaActionParams<ProductCategoryBannerDto>) => ({
    payload,
    url: "/api/productcategorybanners/" + id,
  })
);
export const createProductCategoryBanner = createAction(
  "CREATE_PRODUCT_CATEGORY_BANNER_DETAILS",
  ({ payload, body }: SagaActionParams<ProductCategoryBannerDto>) => ({
    payload,
    url: "/api/productcategorybanners",
    body,
  })
);
export const updateProductCategoryBanner = createAction(
  "UPDATE_PRODUCT_CATEGORY_BANNER_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCategoryBannerDto>) => ({
    payload,
    url: "/api/productcategorybanners/" + id,
    body,
  })
);
export const deleteProductCategoryBanner = createAction(
  "DELETE_PRODUCT_CATEGORY_BANNER_DETAILS",
  ({ payload, body, id }: SagaActionParams<ProductCategoryBannerDto>) => ({
    payload,
    url: "/api/productcategorybanners/" + id,
    body,
  })
);
export const changeProductCategoryOrder = createAction(
  "UPDATE_SLIDER_ORDER",
  ({ payload, body }: SagaActionParams<ProductCategoryBannerDto>) => ({
    payload,
    url: "/api/productcategorybanners/order",

    bodyType: BodyType.raw,
    body,
  })
);
const productCategoryBannerSagaSaga = [
  takeLatest(
    getProductCategoryBannerList.toString(),
    (payload: SagaGenericParams<ProductCategoryBannerDto[]>) =>
      getListSaga<ProductCategoryBannerDto[]>(payload)
  ),
  takeLatest(
    getProductCategoryBannerDetails.toString(),
    (payload: SagaGenericParams<ProductCategoryBannerDto>) =>
      getListSaga<ProductCategoryBannerDto>(payload)
  ),
  takeLatest(
    createProductCategoryBanner.toString(),
    (payload: PostSagaGenericParams<ProductCategoryBannerDto>) =>
      postSaga<ProductCategoryBannerDto>(payload)
  ),
  takeLatest(
    updateProductCategoryBanner.toString(),
    (payload: PostSagaGenericParams<ProductCategoryBannerDto>) =>
      putSaga<ProductCategoryBannerDto>(payload)
  ),
  takeLatest(
    deleteProductCategoryBanner.toString(),
    (payload: SagaGenericParams<ProductCategoryBannerDto>) =>
      deleteSaga<ProductCategoryBannerDto>(payload)
  ),
  takeLatest(
    changeProductCategoryOrder.toString(),
    (payload: PostSagaGenericParams<ProductCategoryBannerDto>) =>
      putSaga<ProductCategoryBannerDto>(payload)
  ),
];

export default productCategoryBannerSagaSaga;
