import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { DataBankDto } from "./type";
import { BodyType } from "../base/request/request";

export const getDataBankList = createAction(
  "GET_DATABANK_LIST",
  ({ payload, params }: SagaActionParams<DataBankDto[]>) => ({
    payload,
    url: "/api/databanks" + (params ? params : ""),
  })
);

export const getDataBankListByCategoryId = createAction(
  "GET_DATABANK_LIST_BY_CATEGORY_ID",
  ({ payload, id }: SagaActionParams<DataBankDto[]>) => ({
    payload,
    url: "/api/databanks/bycategoryId/" + id,
  })
);
export const getDataBankDetails = createAction(
  "GET_DATABANK_DETAILS",
  ({ payload, id }: SagaActionParams<DataBankDto>) => ({
    payload,
    url: "/api/databanks/" + id,
  })
);
export const createDataBank = createAction(
  "CREATE_DATABANK_DETAILS",
  ({ payload, body }: SagaActionParams<DataBankDto>) => ({
    payload,
    url: "/api/databanks",
    body,
  })
);
export const updateDataBank = createAction(
  "UPDATE_DATABANK_DETAILS",
  ({ payload, body, id }: SagaActionParams<DataBankDto>) => ({
    payload,
    url: "/api/databanks/" + id,
    body,
  })
);
export const deleteDataBank = createAction(
  "DELETE_DATABANK_DETAILS",
  ({ payload, body, id }: SagaActionParams<DataBankDto>) => ({
    payload,
    url: "/api/databanks/" + id,
    body,
  })
);

export const changeDataBankOrder = createAction(
  "UPDATE_DATABANK_ORDER",
  ({ payload, body }: SagaActionParams<DataBankDto>) => ({
    payload,
    url: "/api/databanks/order",
    body,
    bodyType: BodyType.raw,
  })
);
const databankSaga = [
  takeLatest(
    getDataBankListByCategoryId.toString(),
    (payload: SagaGenericParams<DataBankDto[]>) =>
      getListSaga<DataBankDto[]>(payload)
  ),
  takeLatest(
    getDataBankList.toString(),
    (payload: SagaGenericParams<DataBankDto[]>) =>
      getListSaga<DataBankDto[]>(payload)
  ),
  takeLatest(
    getDataBankDetails.toString(),
    (payload: SagaGenericParams<DataBankDto>) =>
      getListSaga<DataBankDto>(payload)
  ),
  takeLatest(
    createDataBank.toString(),
    (payload: PostSagaGenericParams<DataBankDto>) =>
      postSaga<DataBankDto>(payload)
  ),
  takeLatest(
    changeDataBankOrder.toString(),
    (payload: PostSagaGenericParams<DataBankDto>) =>
      putSaga<DataBankDto>(payload)
  ),
  takeLatest(
    updateDataBank.toString(),
    (payload: PostSagaGenericParams<DataBankDto>) =>
      putSaga<DataBankDto>(payload)
  ),
  takeLatest(
    deleteDataBank.toString(),
    (payload: SagaGenericParams<DataBankDto>) =>
      deleteSaga<DataBankDto>(payload)
  ),
];

export default databankSaga;
