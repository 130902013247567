import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CouponDto,
  CouponGroupDto,
  CouponIncludedPackageDto,
  CouponUsageHistoryDto,
} from "./type";
import { BodyType } from "../base/request/request";
export const getCouponUsageHistoryListById = createAction(
  "GET_COUPON_USAGE_HISTORY_LIST_BY_ID",
  ({ payload, params, id }: SagaActionParams<CouponUsageHistoryDto[]>) => ({
    payload,
    url: "/api/couponusagehistories/byCouponId/" + id + (params ? params : ""),
  })
);
export const getCouponUsageHistoryList = createAction(
  "GET_COUPON_USAGE_HISTORY_LIST",
  ({ payload, params }: SagaActionParams<CouponUsageHistoryDto[]>) => ({
    payload,
    url: "/api/couponusagehistories/getlist" + (params ? params : ""),
  })
);
export const getCouponList = createAction(
  "GET_COUPON_LIST",
  ({ payload, params }: SagaActionParams<CouponDto[]>) => ({
    payload,
    url: "/api/coupons" + (params ? params : ""),
  })
);
export const getCouponIncludedPackageList = createAction(
  "GET_COUPON_INCLUDED_PACKAGE_LIST",
  ({ payload, id }: SagaActionParams<CouponIncludedPackageDto[]>) => ({
    payload,
    url: "/api/coupons/included-packages/" + id,
  })
);
export const getCouponDetails = createAction(
  "GET_COUPON_DETAILS",
  ({ payload, id }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons/" + id,
  })
);
export const createCoupon = createAction(
  "CREATE_COUPON_DETAILS",
  ({ payload, body }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons",
    body,
  })
);
export const createCouponMultiple = createAction(
  "CREATE_COUPON_DETAILS_MULTIPLE",
  ({ payload, body }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons/multiple",
    body,
  })
);
export const updateCoupon = createAction(
  "UPDATE_COUPON_DETAILS",
  ({ payload, body, id }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons/" + id,
    body,
  })
);
export const deleteCoupon = createAction(
  "DELETE_COUPON_DETAILS",
  ({ payload, body, id }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons/" + id,
    body,
  })
);

export const changeCouponOrder = createAction(
  "UPDATE_COUPON_ORDER",
  ({ payload, body }: SagaActionParams<CouponDto>) => ({
    payload,
    url: "/api/coupons/order",
    bodyType: BodyType.raw,
    body,
  })
);

export const getCouponGroups = createAction(
  "GET_COUPON_GROUPS",
  ({ payload, params }: SagaActionParams<CouponGroupDto[]>) => ({
    payload,
    url: "/api/coupongroups" + (params ? params : ""),
  })
);

export const getCouponGroupsDetails = createAction(
  "GET_COUPON_GROUPS_DETAILS",
  ({ payload, id }: SagaActionParams<CouponGroupDto>) => ({
    payload,
    url: "/api/coupongroups/" + id,
  })
);
export const createCouponGroups = createAction(
  "CREATE_COUPON_GROUPS",
  ({ payload, body }: SagaActionParams<CouponGroupDto>) => ({
    payload,
    url: "/api/coupongroups",
    body,
  })
);
export const updateCouponGroups = createAction(
  "UPDATE_COUPON_GROUPS",
  ({ payload, id, body }: SagaActionParams<CouponGroupDto>) => ({
    payload,
    url: "/api/coupongroups/" + id,
    body,
  })
);

export const deleteCouponGroups = createAction(
  "DELETE_COUPON_GROUPS",
  ({ payload, id }: SagaActionParams<CouponGroupDto>) => ({
    payload,
    url: "/api/coupongroups/" + id,
  })
);
const couponSaga = [
  takeLatest(
    getCouponUsageHistoryListById.toString(),
    (payload: SagaGenericParams<CouponUsageHistoryDto[]>) =>
      getListSaga<CouponUsageHistoryDto[]>(payload)
  ),
  takeLatest(
    getCouponUsageHistoryList.toString(),
    (payload: SagaGenericParams<CouponUsageHistoryDto[]>) =>
      getListSaga<CouponUsageHistoryDto[]>(payload)
  ),
  takeLatest(
    getCouponList.toString(),
    (payload: SagaGenericParams<CouponDto[]>) =>
      getListSaga<CouponDto[]>(payload)
  ),
  takeLatest(
    getCouponIncludedPackageList.toString(),
    (payload: SagaGenericParams<CouponIncludedPackageDto[]>) =>
      getListSaga<CouponIncludedPackageDto[]>(payload)
  ),
  takeLatest(
    getCouponDetails.toString(),
    (payload: SagaGenericParams<CouponDto>) => getListSaga<CouponDto>(payload)
  ),
  takeLatest(
    createCoupon.toString(),
    (payload: PostSagaGenericParams<CouponDto>) => postSaga<CouponDto>(payload)
  ),
  takeLatest(
    createCouponMultiple.toString(),
    (payload: PostSagaGenericParams<CouponDto>) => postSaga<CouponDto>(payload)
  ),
  takeLatest(
    changeCouponOrder.toString(),
    (payload: PostSagaGenericParams<CouponDto>) => putSaga<CouponDto>(payload)
  ),
  takeLatest(
    updateCoupon.toString(),
    (payload: PostSagaGenericParams<CouponDto>) => putSaga<CouponDto>(payload)
  ),
  takeLatest(deleteCoupon.toString(), (payload: SagaGenericParams<CouponDto>) =>
    deleteSaga<CouponDto>(payload)
  ),

  /*COUPON_GROUPS*/
  takeLatest(
    getCouponGroups.toString(),
    (payload: SagaGenericParams<CouponGroupDto[]>) =>
      getListSaga<CouponGroupDto[]>(payload)
  ),

  takeLatest(
    getCouponGroupsDetails.toString(),
    (payload: SagaGenericParams<CouponGroupDto>) =>
      getListSaga<CouponGroupDto>(payload)
  ),
  takeLatest(
    createCouponGroups.toString(),
    (payload: PostSagaGenericParams<CouponGroupDto>) =>
      postSaga<CouponGroupDto>(payload)
  ),
  takeLatest(
    updateCouponGroups.toString(),
    (payload: PostSagaGenericParams<CouponGroupDto>) =>
      putSaga<CouponGroupDto>(payload)
  ),

  takeLatest(deleteCouponGroups.toString(), (payload: SagaGenericParams<any>) =>
    deleteSaga<CouponGroupDto>(payload)
  ),
];

export default couponSaga;
