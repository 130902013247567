import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeCouponDto } from "./type";

export const getPrivilegeCouponListByPrivilegeId = createAction(
  "GET_PRIVILEGE_COUPON_LIST",
  ({ payload, params, id }: SagaActionParams<PrivilegeCouponDto[]>) => ({
    payload,
    url:
      "/api/privilegecoupons/getbyprivilegeid/" + id + (params ? params : ""),
  })
);
export const getPrivilegeCouponDetails = createAction(
  "GET_PRIVILEGE_COUPONDETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeCouponDto>) => ({
    payload,
    url: "/api/privilegecoupons/" + id,
  })
);
export const createPrivilegeCoupon = createAction(
  "CREATE_PRIVILEGE_COUPONDETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeCouponDto>) => ({
    payload,
    url: "/api/privilegecoupons",
    body,
  })
);
export const updatePrivilegeCoupon = createAction(
  "UPDATE_PRIVILEGE_COUPONDETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCouponDto>) => ({
    payload,
    url: "/api/privilegecoupons/" + id,
    body,
  })
);
export const deletePrivilegeCoupon = createAction(
  "DELETE_PRIVILEGE_COUPONDETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCouponDto>) => ({
    payload,
    url: "/api/privilegecoupons/" + id,
    body,
  })
);

const privilegecouponSaga = [
  takeLatest(
    getPrivilegeCouponListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeCouponDto[]>) =>
      getListSaga<PrivilegeCouponDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeCouponDetails.toString(),
    (payload: SagaGenericParams<PrivilegeCouponDto>) =>
      getListSaga<PrivilegeCouponDto>(payload)
  ),
  takeLatest(
    createPrivilegeCoupon.toString(),
    (payload: PostSagaGenericParams<PrivilegeCouponDto>) =>
      postSaga<PrivilegeCouponDto>(payload)
  ),
  takeLatest(
    updatePrivilegeCoupon.toString(),
    (payload: PostSagaGenericParams<PrivilegeCouponDto>) =>
      putSaga<PrivilegeCouponDto>(payload)
  ),
  takeLatest(
    deletePrivilegeCoupon.toString(),
    (payload: SagaGenericParams<PrivilegeCouponDto>) =>
      deleteSaga<PrivilegeCouponDto>(payload)
  ),
];

export default privilegecouponSaga;
