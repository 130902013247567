import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import * as T from "./type";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, postSaga } from "../base/request/post";

export const getPermissionListSaga = createAction(
  "GET_PERMISSON_LIST",
  ({ payload }: SagaActionParams<T.PermissionDto[]>) => ({
    payload,
    url: "/api/permission",
  })
);
export const getRolePermissionList = createAction(
  "GET_ROLE_PERMISSON_LIST",
  ({ payload, id }: SagaActionParams<string[]>) => ({
    payload,
    url: "/api/permission/byroleid/" + id,
  })
);

export const addPermissionToRole = createAction(
  "ADD_PERMISSON_TO_ROLE",
  ({ payload, body }: SagaActionParams<string[]>) => ({
    payload,
    url: "/api/permission/add-permission-to-role",
    body,
  })
);
const permissionSaga = [
  takeLatest<any>(
    getPermissionListSaga.toString(),
    (payload: SagaGenericParams<T.PermissionDto[]>) =>
      getListSaga<T.PermissionDto[]>(payload)
  ),
  takeLatest<any>(
    getRolePermissionList.toString(),
    (payload: SagaGenericParams<string[]>) => getListSaga<string[]>(payload)
  ),
  takeLatest<any>(
    addPermissionToRole.toString(),
    (payload: PostSagaGenericParams<string[]>) => postSaga<string[]>(payload)
  ),
];

export default permissionSaga;
