import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getCustomerCreditCardList } from "src/store/customers/saga";
import { CustomerCreditCardDto } from "src/store/customers/type";
import CreditCard from "./card";

const CustomerCreditCardTab = () => {
  const dispatch = useDispatch();
  const { id, tab }: { id: string; tab: string } = useParams();
  const [creditCards, setCreditCards] = useState<CustomerCreditCardDto[]>([]);
  useEffect(() => {
    dispatch(
      getCustomerCreditCardList({
        payload: {
          onSuccess: (m, p) => setCreditCards(p),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, tab, id]);

  return (
    <React.Fragment>
      <div className="p-2" style={{ background: "white" }}>
        <div className="w-100 d-flex justify-content-start gap-3 flex-wrap">
          {creditCards.map(x => (
            <CreditCard key={x.Id} card={x} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CustomerCreditCardTab;
