import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DataTableWithPaginationProps } from "./type";
import Breadcrumbs from "../Common/Breadcrumb";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";

const DataTableWithOutPagination = ({
  columns,
  listData,
  setDataList,
  title,
  takeValue,
  buttons,
  hideExcel = false,
  hideRefresh = false,
  action,
  HideBreadcrumb = false,
  paramQuery,
  create,
  paramsid,
  addTitle,
  selectRow,
}: DataTableWithPaginationProps) => {
  const { t } = useTranslation();
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { Id }: { Id: string } = useParams();
  const user = useSelector(userSelector);
  useEffect(() => {
    setLoader(true);
    dispatch(
      action({
        payload: {
          //@ts-ignore
          onSuccess: (message, payload, details) => {
            setDataList(payload);
            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        },
        id: paramsid,
        params: paramQuery ?? "",
      })
    ); // eslint-disable-next-line
  }, [Id]);

  return (
    <React.Fragment>
      <ToolkitProvider
        keyField="Id"
        data={listData}
        columns={columns}
        bootstrap4
        search
        columnToggle
        exportCSV
      >
        {toolkitProps => (
          <React.Fragment>
            <div
              className={
                "d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              {HideBreadcrumb && title && (
                <h5 className="mb-0 font-size-18">{title}</h5>
              )}
              {!HideBreadcrumb && title && (
                <Breadcrumbs title={t("Dashboard")} breadcrumbItem={title} />
              )}
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div className="search-box ms-2  d-inline-block ">
                  <div className="position-relative">
                    <SearchBar {...toolkitProps.searchProps} />
                    <i className="bx bx-search-alt search-icon-search" />
                  </div>
                </div>

                <div
                  style={{
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {!hideExcel && (
                    <button className="btn btn-info me-1">
                      <i className="mdi mdi-microsoft-excel "></i>
                    </button>
                  )}
                  {!hideRefresh && (
                    <button
                      className="btn btn-warning  me-1"
                      onClick={() => {
                        setLoader(true);
                        dispatch(
                          action({
                            payload: {
                              onSuccess: (message: any, payload: any) => {
                                toast.info("Yenilendi", {
                                  theme: "colored",
                                });
                                setDataList(payload);
                                setLoader(false);
                              },
                              onError: () => {
                                setLoader(false);
                              },
                            },
                            id: paramsid,
                            params: paramQuery ?? "",
                          })
                        );
                      }}
                    >
                      <i className="mdi mdi-refresh me-1"></i>
                    </button>
                  )}
                  {buttons}
                  <>
                    {create && CheckRole(user) && (
                      <>
                        {create.createType === "drawer" ? (
                          <button
                            onClick={() => {
                              create &&
                                create.onCreateClick &&
                                create.onCreateClick();
                            }}
                            className="btn btn-success text-light"
                          >
                            <i className="bx bx-plus me-1"></i>{" "}
                            {addTitle ? addTitle : t("New")}
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              history.push(create?.createLink ?? "/")
                            }
                            className="btn btn-success text-light"
                          >
                            <i className="bx bx-plus me-1"></i>
                            {addTitle ? addTitle : t("New")}
                          </button>
                        )}
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>

            <Row className="pt-4">
              <Col xl="12">
                <div className="table-responsive">
                  {loader && <Loader />}
                  <BootstrapTable
                    {...toolkitProps.baseProps}
                    // {...paginationTableProps}
                    classes={
                      "table align-middle table-nowrap table-hover table-header-colored"
                    }
                    bordered={false}
                    striped={false}
                    selectRow={selectRow}
                  ></BootstrapTable>
                </div>
              </Col>
            </Row>
            <Row className="align-items-md-center mt-30">
              <Col className="inner-custom-pagination d-flex">
                {/* <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div> */}
                {/* <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div> */}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </ToolkitProvider>
    </React.Fragment>
  );
};

export default DataTableWithOutPagination;
