import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";

const OrdersPage = () => {
  const { t } = useTranslation();
  const history = useHistory(); // eslint-disable-next-line

  const columns = [
    {
      caption: t("Durum"),
      dataField: "OrderStatusName",
    },
    {
      caption: t("Üye"),
      dataField: "CustomerFullName",
    },
    {
      caption: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },
    {
      caption: t("E-posta"),
      dataField: "CustomerEmail",
    },
    {
      caption: t("Tarih"),
      dataField: "OrderDate",
      dataType: "datetime",
    },

    {
      caption: t("Toplam Birim Tutar"),
      dataField: "UnitAmount",
    },
    {
      caption: t("Vergi Tutarı"),
      dataField: "TaxAmount",
    },
    {
      caption: t("Kargo Toplam"),
      dataField: "ShippingAmount",
    },
    {
      caption: t("Genel Toplam"),
      dataField: "GrandTotal",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/orders/1/" + e.row.key);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/orders/devexp",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Siparişler")}
      />
    </React.Fragment>
  );
};

export default OrdersPage;
