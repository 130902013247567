import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getCampaignList } from "src/store/campaigns/saga";
import { CampaignDto } from "src/store/campaigns/type";
import { getNotificationPage } from "src/store/lookup/saga";
import { NotificationPageDto } from "src/store/lookup/type";
import { createPlannedNotificationButton } from "src/store/notification-planned-buttons/saga";
import { getPrivilegeButtonActionTypes } from "src/store/privilegebuttons/saga";
import { ButtonActionTypeDto } from "src/store/privilegebuttons/type";
import { getPrivilegeCategoryList } from "src/store/privilegecategory/saga";
import { PrivilegeCategoryDto } from "src/store/privilegecategory/type";
import * as Yup from "yup";

const CreatePlannedNotificationButtonForm = ({
  plannedNotificationId,
  onHide,
  open,
  onSuccessClick,
}: {
  plannedNotificationId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionTypes, setActionTypes] = useState<ButtonActionTypeDto[]>([]);
  const [privilegeCategories, setPrivilegeCategories] = useState<
    PrivilegeCategoryDto[]
  >([]);
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([]);
  const [pages, setPages] = useState<NotificationPageDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeButtonActionTypes({
          payload: {
            onSuccess: (msg, py) => setActionTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getNotificationPage({
          payload: {
            onSuccess: (msg, payload) => setPages(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeCategoryList({
          payload: {
            onSuccess: (msg, payload) => setPrivilegeCategories(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCampaignList({
          payload: {
            onSuccess: (msg, payload) => setCampaigns(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PlannedNotificationId: plannedNotificationId,
          ButtonName: "",
          Value: "",
          IconFile: "",
          ButtonActionTypeId: "",
          NotificationPageListId: "",
          EntityId: "",
        }}
        onSubmit={values => {
          dispatch(
            createPlannedNotificationButton({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          EntityId: Yup.string().when("notificationPageId", {
            is: (id: number) => id === 1 || id === 2,
            then: Yup.string().required(t("Required")),
          }),
          PlannedNotificationId: Yup.string().required(t("Required")),
          ButtonActionTypeId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "ButtonName",
            label: t("Buton Adı"),
            inputType: InputType.multilinetext,
          },
          {
            field: "ButtonActionTypeId",
            inputType: InputType.multiselect,
            label: t("Aksiyon Tipi"),
            lookup: {
              data: actionTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Aksiyon Tipi Seçiniz"),
            },
          },
          {
            hide(values) {
              return values.ButtonActionTypeId !== 3;
            },
            field: "NotificationPageListId",
            inputType: InputType.multiselect,
            label: t("Yönlendirilecek Sayfa"),
            lookup: {
              data: pages,
              labelKey: "Title",
              valueKey: "EnumId",
              placeholder: t("Seçiniz"),
            },
            onChange(value, item, setFieldValue) {
              setFieldValue("EntityId", "");
              setFieldValue("url", "");
            },
          },
          {
            hide(values) {
              return values.NotificationPageListId !== 2;
            },
            field: "EntityId",
            inputType: InputType.multiselect,
            label: t("Kampanya"),
            lookup: {
              data: campaigns,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },
          {
            hide(values) {
              return values.NotificationPageListId !== 20;
            },
            field: "EntityId",
            inputType: InputType.multiselect,
            label: t("Kampanya"),
            lookup: {
              data: campaigns,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },
          {
            hide(values) {
              return values.NotificationPageListId !== 1;
            },
            field: "EntityId",
            inputType: InputType.multiselect,
            label: t("Kategori"),
            lookup: {
              data: privilegeCategories,
              labelKey: "CategoryName",
              valueKey: "Id",
              placeholder: t("Seçiniz"),
            },
          },
          {
            hide(values) {
              return values.NotificationPageListId !== 21;
            },
            field: "Value",
            label: t("Url"),
          },
          {
            hide(values) {
              return values.ButtonActionTypeId === 3;
            },
            field: "Value",
            label: t("Değeri"),
          },
          {
            field: "IconFile",
            label: t("IconFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/svg+xml"],
              multiple: false,
              title: t("Please Upload Icon Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePlannedNotificationButtonForm;
