import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { SiteConfigDto } from "src/store/web/type";
import {
  clearCache,
  getSiteConfig,
  updateSiteConfig,
} from "src/store/web/saga";
import CancelAlert from "src/components/CancelAlert";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const WebSiteConfigPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<SiteConfigDto>();
  const [showCache, setShowCache] = useState<boolean>(false);
  useEffect(() => {
    dispatch(
      getSiteConfig({
        payload: {
          onSuccess: (msg, r) => setData(r),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Site Ayarları")}
            breadcrumbItem={t("Site Ayarları")}
          />
          <Row>
            <Col md={12}>
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowCache(true)}>
                  Cache Temizle
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            {loader && <Loader />}
            {!loader && (
              <CustomFormik
                loader
                initialValues={{
                  id: data?.Id ?? "",
                  phoneNumber: data?.PhoneNumber ?? "",
                  email: data?.Email ?? "",
                  address: data?.Address ?? "",
                  instagram: data?.Instagram ?? "",
                  linkedin: data?.Linkedin ?? "",
                  twitter: data?.Twitter ?? "",
                  facebook: data?.Facebook ?? "",
                  youtube: data?.Youtube ?? "",
                }}
                onSubmit={values => {
                  dispatch(
                    updateSiteConfig({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({})}
                inputs={[
                  {
                    field: "phoneNumber",
                    label: t("PhoneNumber"),
                  },
                  {
                    field: "email",
                    label: t("Email"),
                  },
                  {
                    field: "address",
                    label: t("Address"),
                    inputType: InputType.multilinetext,
                  },

                  {
                    field: "instagram",
                    label: t("Instagram"),
                  },
                  {
                    field: "linkedin",
                    label: t("Linkedin"),
                  },
                  {
                    field: "twitter",
                    label: t("Twitter"),
                  },
                  {
                    field: "facebook",
                    label: t("Facebook"),
                  },
                  {
                    field: "youtube",
                    label: t("Youtube"),
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>

      {showCache && (
        <CancelAlert
          message={"Site cache temizlemek istediğine emin misin?"}
          onConfirm={() => {
            setShowCache(false);
            dispatch(
              clearCache({
                payload: {
                  onSuccess: message =>
                    toast.success("Cache temizliği başarılı"),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
              })
            );
          }}
          onCancel={() => {
            setShowCache(false);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default WebSiteConfigPage;
