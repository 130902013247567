import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireSupplierDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireSupplierList = createAction(
  "GET_TIRE_SUPPLIER_LIST",
  ({ payload, params }: SagaActionParams<TireSupplierDto[]>) => ({
    payload,
    url: "/api/tiresuppliers" + (params ? params : ""),
  })
);
export const getTireSupplierDetails = createAction(
  "GET_TIRE_SUPPLIER_DETAILS",
  ({ payload, id }: SagaActionParams<TireSupplierDto>) => ({
    payload,
    url: "/api/tiresuppliers/" + id,
  })
);
export const createTireSupplier = createAction(
  "CREATE_TIRE_SUPPLIER_DETAILS",
  ({ payload, body }: SagaActionParams<TireSupplierDto>) => ({
    payload,
    url: "/api/tiresuppliers",
    body,
  })
);
export const updateTireSupplier = createAction(
  "UPDATE_TIRE_SUPPLIER_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSupplierDto>) => ({
    payload,
    url: "/api/tiresuppliers/" + id,
    body,
  })
);
export const deleteTireSupplier = createAction(
  "DELETE_TIRE_SUPPLIER_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireSupplierDto>) => ({
    payload,
    url: "/api/tiresuppliers/" + id,
    body,
  })
);

const tireSupplierSaga = [
  takeLatest(
    getTireSupplierList.toString(),
    (payload: SagaGenericParams<TireSupplierDto[]>) =>
      getListSaga<TireSupplierDto[]>(payload)
  ),
  takeLatest(
    getTireSupplierDetails.toString(),
    (payload: SagaGenericParams<TireSupplierDto>) =>
      getListSaga<TireSupplierDto>(payload)
  ),
  takeLatest(
    createTireSupplier.toString(),
    (payload: PostSagaGenericParams<TireSupplierDto>) =>
      postSaga<TireSupplierDto>(payload)
  ),

  takeLatest(
    updateTireSupplier.toString(),
    (payload: PostSagaGenericParams<TireSupplierDto>) =>
      putSaga<TireSupplierDto>(payload)
  ),
  takeLatest(
    deleteTireSupplier.toString(),
    (payload: SagaGenericParams<TireSupplierDto>) =>
      deleteSaga<TireSupplierDto>(payload)
  ),
];

export default tireSupplierSaga;
