import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createMembershipcanceltype } from "src/store/membership-cancel-types/saga";
import * as Yup from "yup";

const CreateMembershipCancellationType = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New MembershipCancellationType"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Description: "",
          InText: false,
        }}
        onSubmit={values => {
          dispatch(
            createMembershipcanceltype({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Description: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Description",
            label: t("Description"),
            inputType: InputType.multilinetext,
          },
          {
            field: "InText",
            label: t("InText"),
            col: 2,
            checkedLabel: t("InText"),
            uncheckedLabel: t("InText"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateMembershipCancellationType;
