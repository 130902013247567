import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreatePlannedNotificationButton from "./create";
import { toast } from "react-toastify";
import EditPlannedNotificationButton from "./edit";
import CancelAlert from "src/components/CancelAlert";
import { useParams } from "react-router";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";
import { PlannedNotificationDto } from "src/store/notification-planned/type";
import { getPlannedNotificationDetails } from "src/store/notification-planned/saga";
import {
  changePlannedNotificationButtonOrder,
  deletePlannedNotificationButton,
  getPlannedNotificationButtonDetails,
  getPlannedNotificationButtonListById,
} from "src/store/notification-planned-buttons/saga";
import { PlannedNotificationButtonDto } from "src/store/notification-planned-buttons/type";

const PlannedNotificationButtonPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PlannedNotificationButtonDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<PlannedNotificationButtonDto[]>([]);
  const [showDelete, setShowDelete] = useState<PlannedNotificationButtonDto>();
  const [plannedNotification, setPlannedNotification] =
    useState<PlannedNotificationDto>();
  useEffect(() => {
    dispatch(
      getPlannedNotificationDetails({
        payload: {
          onSuccess: (msg, payload) => setPlannedNotification(payload),
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getPlannedNotificationButtonListById({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Buton Adı"),
      dataField: "ButtonName",
    },
    {
      text: t("Aksiyon Tipi"),
      dataField: "ButtonActionTypeName",
    },
    {
      text: t("Değer"),
      dataField: "Value",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getPlannedNotificationButtonDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPlannedNotificationButtonListById({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditPlannedNotificationButton
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreatePlannedNotificationButton
        plannedNotificationId={id}
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changePlannedNotificationButtonOrder}
        title={
          (plannedNotification ? plannedNotification.Title : "") +
          t(" Butonlar")
        }
        create={{
          createType: "drawer",
          onCreateClick: () => setShowCreate(true),
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete privilege button`, {
            name: `${showDelete.ButtonName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePlannedNotificationButton({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PlannedNotificationButtonPage;
