import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { TireBrandDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getTireBrandList = createAction(
  "GET_TIRE_BRAND_LIST",
  ({ payload, params }: SagaActionParams<TireBrandDto[]>) => ({
    payload,
    url: "/api/tirebrands" + (params ? params : ""),
  })
);
export const getTireBrandDetails = createAction(
  "GET_TIRE_BRAND_DETAILS",
  ({ payload, id }: SagaActionParams<TireBrandDto>) => ({
    payload,
    url: "/api/tirebrands/" + id,
  })
);
export const createTireBrand = createAction(
  "CREATE_TIRE_BRAND_DETAILS",
  ({ payload, body }: SagaActionParams<TireBrandDto>) => ({
    payload,
    url: "/api/tirebrands",
    body,
  })
);
export const updateTireBrand = createAction(
  "UPDATE_TIRE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireBrandDto>) => ({
    payload,
    url: "/api/tirebrands/" + id,
    body,
  })
);
export const deleteTireBrand = createAction(
  "DELETE_TIRE_BRAND_DETAILS",
  ({ payload, body, id }: SagaActionParams<TireBrandDto>) => ({
    payload,
    url: "/api/tirebrands/" + id,
    body,
  })
);

const tireBrandSaga = [
  takeLatest(
    getTireBrandList.toString(),
    (payload: SagaGenericParams<TireBrandDto[]>) =>
      getListSaga<TireBrandDto[]>(payload)
  ),
  takeLatest(
    getTireBrandDetails.toString(),
    (payload: SagaGenericParams<TireBrandDto>) =>
      getListSaga<TireBrandDto>(payload)
  ),
  takeLatest(
    createTireBrand.toString(),
    (payload: PostSagaGenericParams<TireBrandDto>) =>
      postSaga<TireBrandDto>(payload)
  ),

  takeLatest(
    updateTireBrand.toString(),
    (payload: PostSagaGenericParams<TireBrandDto>) =>
      putSaga<TireBrandDto>(payload)
  ),
  takeLatest(
    deleteTireBrand.toString(),
    (payload: SagaGenericParams<TireBrandDto>) =>
      deleteSaga<TireBrandDto>(payload)
  ),
];

export default tireBrandSaga;
