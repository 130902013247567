import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getAllPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { createWebSlider } from "src/store/web-slider/saga";
import * as Yup from "yup";

const CreateWebSlider = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getAllPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Slider"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: "",
          Title: "",
          Description: "",
          ImageFile: "",
          ResponsiveImageFile: "",
          Active: false,
        }}
        onSubmit={values => {
          dispatch(
            createWebSlider({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          ImageFile: Yup.mixed().required(t("Required")),
          Title: Yup.string().required(t("Required")),
          Description: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "Title",
            label: t("Title"),
          },
          {
            field: "Description",
            label: t("Description"),
          },

          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
          {
            field: "ResponsiveImageFile",
            label: t("ResponsiveImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Responsive Image"),
            },
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateWebSlider;
