import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType, inputKey } from "src/components/Form/type";
import {
  getCustomerVehicleCategory,
  getCustomerVehicleSubCategory,
} from "src/store/customervehicles/saga";
import {
  CustomerVehicleLookupDto,
  VehicleStepDto,
} from "src/store/customervehicles/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import VehicleInput from "./vehicle-input";

const TicketNotCustomerInputs = ({
  show,
  values,
}: {
  show: boolean;
  values: any;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);
  const [categories, setCategories] = useState<CustomerVehicleLookupDto>();

  useEffect(() => {
    if (show) {
      dispatch(
        getCustomerVehicleCategory({
          payload: {
            onSuccess: (ms, payload) => {
              setCategories(payload);
            },
            onError: () => {},
          },
        })
      );
      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, py) => setCities(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, show]);
  const currentLocation: inputKey[] = [
    {
      col: 6,
      field: "CityId",
      inputType: InputType.multiselect,
      label: t("Mevcut Lokasyon İl"),
      lookup: {
        data: cities,
        labelKey: "CityName",
        placement: "topStart",
        valueKey: "Id",
        placeholder: t("Choose City"),
      },
      onChange(value: any, item: any, setFieldValue: any) {
        value &&
          dispatch(
            getTowns({
              payload: {
                onSuccess: (msg, payload) => setTowns(payload),
                onError: () => {},
              },
              id: value,
            })
          );
      },
    },
    {
      col: 6,
      field: "TownId",
      inputType: InputType.multiselect,
      label: t("Mevcut Lokasyon İlçe"),
      lookup: {
        placement: "topStart",
        data: towns,
        labelKey: "TownName",
        valueKey: "Id",
        placeholder: t("Choose Town"),
      },
    },
    {
      field: "CurrentLocationAddress",
      label: t("Mevcut Lokasyon Adresi"),
      inputType: InputType.multilinetext,
    },
  ];
  const inputs: inputKey[] = [
    {
      col: 6,
      field: "Name",
      label: t("Name"),
    },
    {
      col: 6,
      field: "Surname",
      label: t("Surname"),
    },
    {
      col: 6,
      field: "PhoneNumber",
      label: t("PhoneNumber"),
    },
    {
      col: 6,
      field: "PhoneNumber2",
      label: t("PhoneNumber") + 2,
    },

    {
      col: 6,
      field: "CityId",
      inputType: InputType.multiselect,
      label: t("City"),
      lookup: {
        data: cities,
        labelKey: "CityName",
        placement: "topStart",
        valueKey: "Id",
        placeholder: t("Choose City"),
      },
      onChange(value, item, setFieldValue) {
        value &&
          dispatch(
            getTowns({
              payload: {
                onSuccess: (msg, payload) => setTowns(payload),
                onError: () => {},
              },
              id: value,
            })
          );
      },
    },
    {
      col: 6,
      field: "TownId",
      inputType: InputType.multiselect,
      label: t("Town"),
      lookup: {
        placement: "topStart",
        data: towns,
        labelKey: "TownName",
        valueKey: "Id",
        placeholder: t("Choose Town"),
      },
    },
    {
      field: "Address",
      label: t("Address"),
      inputType: InputType.multilinetext,
    },
    ...currentLocation,
    {
      field: "VehiclePlate",
      col: 6,
      label: t("Plaka"),
    },

    {
      field: "KmLimit",
      col: 6,
      label: t("Km Limit"),
    },
  ];

  return (
    <Row>
      {values.Type === 2 ? (
        <>
          {inputs.map((key, i) => {
            const disabledvalue = key.disabled ? key.disabled(values) : false;
            return key.hide && key.hide(values) ? (
              <></>
            ) : (
              <GetInput inputprop={key} key={i} disabledvalue={disabledvalue} />
            );
          })}

          {categories && (
            <>
              <GetInput
                inputprop={{
                  inputType: InputType.multiselect,
                  field: "category",
                  label: t("Kategori"),
                  lookup: {
                    placement: "topStart",
                    data: categories.nextStep.map(x => {
                      return {
                        value: x.name ?? x.summary,
                        label: x.name ?? x.summary,
                        href: x.href,
                        rel: x.rel,
                        summary: x.summary,
                        templated: x.templated,
                        type: x.type,
                        description: x.description,
                        name: x.name,
                      };
                    }),
                    cleanable: false,
                  },
                  onChange: (value, item: VehicleStepDto, setFieldValue) => {
                    setFieldValue("DataArray", []);
                    dispatch(
                      getCustomerVehicleSubCategory({
                        payload: {
                          onSuccess: (ms, payload) => {
                            setFieldValue("DataArray", [payload]);
                          },
                          onError: () => {},
                        },
                        body: {
                          url: item.href,
                        },
                      })
                    );
                  },
                }}
              />
              {values.DataArray.slice(0, 4).map((x: any, i: any) => (
                <VehicleInput list={x} i={i} />
              ))}
            </>
          )}
        </>
      ) : (
        <>
          {currentLocation.map((key, i) => {
            const disabledvalue = key.disabled ? key.disabled(values) : false;
            return key.hide && key.hide(values) ? (
              <></>
            ) : (
              <GetInput inputprop={key} key={i} disabledvalue={disabledvalue} />
            );
          })}
        </>
      )}
    </Row>
  );
};
export default TicketNotCustomerInputs;
