import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { VehicleFuelTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getVehicleFuelTypeList = createAction(
  "GET_VEHICLE_FUEL_TYPE_LIST",
  ({ payload, params }: SagaActionParams<VehicleFuelTypeDto[]>) => ({
    payload,
    url: "/api/vehiclefueltypes" + (params ? params : ""),
  })
);
export const getVehicleFuelTypeDetails = createAction(
  "GET_VEHICLE_FUEL_TYPE_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleFuelTypeDto>) => ({
    payload,
    url: "/api/vehiclefueltypes/" + id,
  })
);
export const createVehicleFuelType = createAction(
  "CREATE_VEHICLE_FUEL_TYPE_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleFuelTypeDto>) => ({
    payload,
    url: "/api/vehiclefueltypes",
    body,
  })
);
export const updateVehicleFuelType = createAction(
  "UPDATE_VEHICLE_FUEL_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleFuelTypeDto>) => ({
    payload,
    url: "/api/vehiclefueltypes/" + id,
    body,
  })
);
export const deleteVehicleFuelType = createAction(
  "DELETE_VEHICLE_FUEL_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleFuelTypeDto>) => ({
    payload,
    url: "/api/vehiclefueltypes/" + id,
    body,
  })
);

export const changeVehicleFuelTypeOrder = createAction(
  "UPDATE_VEHICLE_FUEL_TYPE_ORDER",
  ({ payload, body }: SagaActionParams<VehicleFuelTypeDto>) => ({
    payload,
    url: "/api/vehiclefueltypes/order",

    bodyType: BodyType.raw,
    body,
  })
);
const vehiclefueltypeSaga = [
  takeLatest(
    getVehicleFuelTypeList.toString(),
    (payload: SagaGenericParams<VehicleFuelTypeDto[]>) =>
      getListSaga<VehicleFuelTypeDto[]>(payload)
  ),
  takeLatest(
    getVehicleFuelTypeDetails.toString(),
    (payload: SagaGenericParams<VehicleFuelTypeDto>) =>
      getListSaga<VehicleFuelTypeDto>(payload)
  ),
  takeLatest(
    createVehicleFuelType.toString(),
    (payload: PostSagaGenericParams<VehicleFuelTypeDto>) =>
      postSaga<VehicleFuelTypeDto>(payload)
  ),
  takeLatest(
    changeVehicleFuelTypeOrder.toString(),
    (payload: PostSagaGenericParams<VehicleFuelTypeDto>) =>
      putSaga<VehicleFuelTypeDto>(payload)
  ),
  takeLatest(
    updateVehicleFuelType.toString(),
    (payload: PostSagaGenericParams<VehicleFuelTypeDto>) =>
      putSaga<VehicleFuelTypeDto>(payload)
  ),
  takeLatest(
    deleteVehicleFuelType.toString(),
    (payload: SagaGenericParams<VehicleFuelTypeDto>) =>
      deleteSaga<VehicleFuelTypeDto>(payload)
  ),
];

export default vehiclefueltypeSaga;
