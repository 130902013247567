import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getAllPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { updateWebSlider } from "src/store/web-slider/saga";
import { WebSliderDto } from "src/store/web-slider/type";
import * as Yup from "yup";

const EditWebSlider = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: WebSliderDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getAllPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit WebSlider"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PrivilegeId: data.PrivilegeId ?? "",
          Title: data.Title,
          Description: data.Description,
          CurrentFile: data.Image && data.Image != null ? data.Image : "",
          ImageFile: "",
          CurrentResponsiveFile:
            data.ResponsiveImage && data.ResponsiveImage != null
              ? data.ResponsiveImage
              : "",
          ResponsiveImageFile: "",
          Active: data.Active,
        }}
        onSubmit={values => {
          dispatch(
            updateWebSlider({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Title: Yup.string().required(t("Required")),
          Description: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "Title",
            label: t("Title"),
          },
          {
            field: "Description",
            label: t("Description"),
          },
          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
          {
            field: "ResponsiveImageFile",
            label: t("ResponsiveImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentResponsiveFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Responsive Image"),
            },
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditWebSlider;
