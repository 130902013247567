import { Redirect } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import ForgetPassword from "src/pages/Authentication/ForgetPassword";
import ResetPassword from "src/pages/Authentication/ResetPassword";
import ConfirmMail from "src/pages/Authentication/ConfirmMail";
import Error404 from "src/pages/Authentication/Error404";
import SliderPage from "src/pages/Slider";
import OpportunityCategoryPage from "src/pages/PrivilegeCategories";
import OpportunityPage from "src/pages/Privileges";
import SupplierPage from "src/pages/Suppliers";
import CreateSupplierPage from "src/pages/Suppliers/create";
import CreateOpportunityPage from "src/pages/Privileges/create";
import EditOpportunityPage from "src/pages/Privileges/edit";
import PackagePage from "src/pages/Packages";
import PackageDetailPage from "src/pages/Packages/details";
import PrivilegePricePage from "src/pages/PrivelegePrices";
import SupplierTypePage from "src/pages/SupplierTypes";
import DetailsSupplierPage from "src/pages/Suppliers/details";
import CustomerPage from "src/pages/Customers";
import CreateCampaignPage from "src/pages/Campaigns/create";
import CampaignPage from "src/pages/Campaigns";
import EditCampaignPage from "src/pages/Campaigns/edit";
import CustomerDetails from "src/pages/Customers/Details";
import PrivilegeCouponsPage from "src/pages/PrivilegeCoupons";
import VehicleColorPage from "src/pages/VehicleColor";
import VehicleBodyTypePage from "src/pages/VehicleBodyType";
import VehicleFuelTypePage from "src/pages/VehicleFuelType";
import VehicleGearTypePage from "src/pages/VehicleGearType";
import CustomerJobPage from "src/pages/CustomerJobs";
import CustomerEducationStatusPage from "src/pages/CustomerEducationStatus";
import CustomerVehicleDetails from "src/pages/CustomerVehicle/details";
import CouponPage from "src/pages/Coupons";
import CouponDetails from "src/pages/Coupons/Details";
import EditCustomerVehiclePage from "src/pages/CustomerVehicle/edit";
import VehicleChangeStatusPage from "src/pages/VehicleChangeStatus";
import FaqsPage from "src/pages/Faqs";
import InvoicePackagePage from "src/pages/Packages-Invoiced/invoicePackages";
import UninvoicePackagePage from "src/pages/Packages-Invoiced/uninvoicePackages";
import UninvoicePrivilegePage from "src/pages/Privileges-Invoiced/uninvoicePrivileges";
import InvoicePrivilegePage from "src/pages/Privileges-Invoiced/invoicePrivilege";
import TicketSubjectPage from "src/pages/TicketSubjects";
import DataBankCategoryPage from "src/pages/DatabankCategory";
import DataBankPage from "src/pages/Databank";
import TicketSubjectDetailPage from "src/pages/TicketSubjects-Details";
import TicketSubjectDetailInfoPage from "src/pages/TicketSubjects-Details-info";
import FaqsCategoryPage from "src/pages/Faqs-Category";
import WebSliderPage from "src/pages/Web-Slider";
import ContactCategoryPage from "src/pages/Web-Contact-Category";
import ContactPage from "src/pages/Web-Contact";
import AboutUsPage from "src/pages/Web-Aboutus";
import WebSiteConfigPage from "src/pages/Web-SiteConfig";
import MembershipCancellationRequestPage from "src/pages/Membership-Cancel-Request";
import MembershipCancellationTypePage from "src/pages/Membership-Cancel-Types";
import Users from "src/pages/Users";
import CreateAgreementsPage from "src/pages/Agreements/create";
import AgreementsPage from "src/pages/Agreements";
import DetailsAgreementsPage from "src/pages/Agreements/details";
import PrivilegeTermPage from "src/pages/PrivilegeTerms";
import ParameterPages from "src/pages/Parameter";
import AgreementChangeInfoPage from "src/pages/AgreementChangeInfo";
import CreateAgreementChangeInfosPage from "src/pages/AgreementChangeInfo/create";
import DetailsAgreementChangeInfosPage from "src/pages/AgreementChangeInfo/details";
import PrivacyPage from "src/pages/Privacy";
import AccountDeletionPromptPage from "src/pages/AccountDeletionPrompt";
import PrivilegeLocationPage from "src/pages/PrivilegeLocations";
import DatabankCreatePage from "src/pages/Databank/create";
import DatabankEditPage from "src/pages/Databank/edit";
import PaymentSettingsPage from "src/pages/PaymentSettings";
import DatabankOrderPage from "src/pages/DatabankCategory/databankOrder";
import FaqsOrderPage from "src/pages/Faqs-Category/faqsOrder";
import CampaignOrderPage from "src/pages/PrivilegeCategories/campaignOrder";
import PrivilegeOrderPage from "src/pages/PrivilegeCategories/privilegeOrder";
import EmailSettingsPage from "src/pages/EmailSettings";
import MtvSettingsPage from "src/pages/MtvSettings";
import ReminderSettingsPage from "src/pages/ReminderSettings";
import PrivilegeButtonPage from "src/pages/PrivilegeButtons";
import CustomerCallLogTopicsPage from "src/pages/Calllog-Topics";
import CustomerCalllogPage from "src/pages/Calllogs";
import DetailCustomerCalllogPage from "src/pages/Calllogs/details";
import PrivilegeCardPage from "src/pages/PrivilegeCard";
import UsingPrivilegeCardPage from "src/pages/PrivilegeCardUsing";
import EmailTemplatesPage from "src/pages/EmailTemplates";
import EuroMessageSettingsPage from "src/pages/EuroMessageSettings";
import InformedRecipientsPage from "src/pages/InformedRecipients";
import SocialMediasPage from "src/pages/SocialMediaLinks";
import CancelledCustomerPage from "src/pages/Customers/index-canceled";
import BELocalizationsPage from "src/pages/Localizations/index-be";
import FELocalizationsPage from "src/pages/Localizations/index-fe";
import PaymentInstallmentsPage from "src/pages/PaymentInstallments";
import CallLogChannelsPage from "src/pages/CallLogChannels";
import TireBrandPage from "src/pages/Tires/Brands";
import TireDiameterPage from "src/pages/Tires/Diameters";
import TireTreadWidthPage from "src/pages/Tires/TreadWidths";
import TireSidewallWidthPage from "src/pages/Tires/SidewallWidths";
import TireServicePage from "src/pages/Tires/Services";
import TireSupplierPage from "src/pages/Tires/Suppliers";
import TirePage from "src/pages/Tires/Tires";
import TireSupplierInfoPage from "src/pages/Tires/TireSupplierInfos";
import TireSaleDetailsPage from "src/pages/TireSales/Detail";
import TireSalesPage from "src/pages/TireSales";
import PrivilegeContainPage from "src/pages/PrivilegeContains";
import NotificationMessagesPage from "src/pages/NotificationMessages";
import PhoneNumberPage from "src/pages/PhoneNumbers";
import ProductCategoriesPage from "src/pages/Orders/ProductCategories";
import ProductCategoryBannerPage from "src/pages/Orders/ProductCategoryBanners";
import OrderShippingCompaniesPage from "src/pages/Orders/OrderShippingCompanies";
import OrderRefundReasonsPage from "src/pages/Orders/OrderRefundReasons";
import OrderCancellationReasonsPage from "src/pages/Orders/OrderCancellationReasons";
import ProductsPage from "src/pages/Orders/Products";
import ProductDetailsPage from "src/pages/Orders/Products-Details";
import OrdersPage from "src/pages/Orders/Orders";
import OrderDetailPage from "src/pages/Orders/Orders/Details";
import BlackListPage from "src/pages/BlackList";
import SmsLogsPage from "src/pages/SystemLogs/smsLogs";
import EmailLogsPage from "src/pages/SystemLogs/emailLogs";
import FirstPackageCustomerPage from "src/pages/Reports";
import CustomerGroupPage from "src/pages/CustomerGroups";
import CustomerRatingPage from "src/pages/Reports/CustomerRating";
import TicketsPage from "src/pages/Email";
import TicketChannelsPage from "src/pages/TicketChannels";
import CustomerPrivilegeCardByCustomerIdPage from "src/pages/CustomerPrivilegesByPrivilegeId";
import NotificationActionsPage from "src/pages/NotificationActions";
import PlannedNotificationsPage from "src/pages/NotificationPlanned";
import PlannedNotificationButtonPage from "src/pages/NotificationPlannedButtons";
import TransactionReportPage from "src/pages/Reports/transactionReport";
import IsparkReportPage from "src/pages/Reports/isparkReport";
import CustomerReportsPage from "src/pages/Reports/customers";
import RolesPage from "src/pages/Auth/Roles";
import PermissionPage from "src/pages/Auth/Permissions";
import { PermissionConsts } from "src/permissions/permissionConsts";
import CorporateCustomersPage from "src/pages/CorporateClients";
import CorporateCustomerDetailsPage from "src/pages/CorporateClients-Detail";
import CustomerChartPage from "src/pages/Reports/chart-customers";
import VehicleBrandsPage from "src/pages/Maintenance/VehicleBrands";
import VehicleModelsPage from "src/pages/Maintenance/VehicleModels";
import MaintenanceCategoriesPage from "src/pages/Maintenance/MaintenanceCategories";
import ServiceMaintenanceCardsPage from "src/pages/Maintenance/ServiceMaintenanceCards";
import MaintenanceDraftDetailsPage from "src/pages/Maintenance/MaintenanceDrafts-Details";
import VehicleModelsByBrandIdPage from "src/pages/Maintenance/VehicleModels/byBrandId";
import MaintenanceDraftsPage from "src/pages/Maintenance/MaintenanceDrafts";
import UninvoiceMembershipCancellationPage from "src/pages/InvoiceMembershipCancellation/uninvoicePackages";
import InvoiceMembershipCancellationPage from "src/pages/InvoiceMembershipCancellation/invoiceList";
import CouponGroupsPage from "src/pages/Coupon-Groups";
import SystemReminderUsersPage from "src/pages/SystemReminderUsers";
import CouponGroupUsageHistories from "src/pages/Coupon-Groups/transactions";

interface RouteProps {
  path: string;
  component: any;
  roles?: string[];
  exact?: boolean;
  isNotAdmin?: boolean;
  permission?: string[];
}

const userRoutes: Array<RouteProps> = [
  {
    path: "/dashboard",
    isNotAdmin: true,
    component: Dashboard,
  },
  {
    path: "/customer-ratings",
    isNotAdmin: true,
    component: CustomerRatingPage,
    permission: [PermissionConsts.PrivilegePoint],
  },
  {
    path: "/sliders",
    component: SliderPage,
    permission: [PermissionConsts.Sliders],
  },
  {
    path: "/privilegecategories",
    component: OpportunityCategoryPage,
    permission: [PermissionConsts.Categories],
  },
  {
    path: "/privileges",
    component: OpportunityPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/parameters",
    component: ParameterPages,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/invoiced-privileges",
    component: InvoicePrivilegePage,
    permission: [PermissionConsts.InvoicePrivileges],
  },
  {
    path: "/uninvoiced-privileges",
    component: UninvoicePrivilegePage,
    permission: [PermissionConsts.NotinvoicePrivileges],
  },
  {
    path: "/invoiced-cancellation",
    component: InvoiceMembershipCancellationPage,
    permission: [PermissionConsts.InvoicePrivileges],
  },
  {
    path: "/uninvoiced-cancellation",
    component: UninvoiceMembershipCancellationPage,
    permission: [PermissionConsts.NotinvoicePrivileges],
  },
  {
    path: "/privilege/create",
    component: CreateOpportunityPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilege/edit/:id",
    component: EditOpportunityPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilegeprices/:id",
    component: PrivilegePricePage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilege-locations/:id",
    component: PrivilegeLocationPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilegeterms/:id",
    component: PrivilegeTermPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilege-codes/:id",
    component: PrivilegeCouponsPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/suppliers",
    component: SupplierPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Suppliers],
  },
  {
    path: "/supplier/create",
    component: CreateSupplierPage,
    permission: [PermissionConsts.Suppliers],
  },
  {
    path: "/supplier/details/:tab/:id",
    component: DetailsSupplierPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Suppliers],
  },
  {
    path: "/packages",
    component: PackagePage,
    isNotAdmin: true,
    permission: [PermissionConsts.Packages],
  },
  {
    path: "/invoiced-packages",
    component: InvoicePackagePage,
    permission: [PermissionConsts.InvoicePackages],
  },
  {
    path: "/uninvoiced-packages",
    component: UninvoicePackagePage,
    permission: [PermissionConsts.NotinvoicePackages],
  },
  {
    path: "/suppliertypes",
    component: SupplierTypePage,
    permission: [PermissionConsts.SupplierTypes],
  },
  // {
  //   path: "/supplierservicetypes",
  //   component: SupplierServiceTypePage,
  // },
  {
    path: "/packages/details/:tab/:id",
    component: PackageDetailPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Packages],
  },
  {
    path: "/customers",
    component: CustomerPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Customers],
  },

  {
    path: "/cancelled-customers",
    component: CancelledCustomerPage,
    isNotAdmin: true,
    permission: [PermissionConsts.CancelCustomer],
  },
  {
    path: "/customer/details/:tab/:id",
    component: CustomerDetails,
    isNotAdmin: true,
    permission: [
      PermissionConsts.Customers,
      PermissionConsts.CustomersForCustomerService,
    ],
  },
  {
    path: "/campaigns",
    component: CampaignPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Campaigns],
  },
  {
    path: "/campaign/create",
    component: CreateCampaignPage,
    permission: [PermissionConsts.Campaigns],
  },
  {
    path: "/campaign-order/:id",
    component: CampaignOrderPage,
    permission: [PermissionConsts.Campaigns],
  },
  {
    path: "/privilege-order/:id",
    component: PrivilegeOrderPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/campaign/edit/:id",
    component: EditCampaignPage,
    permission: [PermissionConsts.Campaigns],
  },
  {
    path: "/vehicle-change-statuses",
    component: VehicleChangeStatusPage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/vehicle-color",
    component: VehicleColorPage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/vehicle-body-type",
    component: VehicleBodyTypePage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/vehicle-gear-type",
    component: VehicleGearTypePage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/vehicle-fuel-type",
    component: VehicleFuelTypePage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/customer-education-status",
    component: CustomerEducationStatusPage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/customer-job",
    component: CustomerJobPage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/customer-vehicle/:id",
    component: CustomerVehicleDetails,
    isNotAdmin: false,
    permission: [PermissionConsts.Customers],
  },
  {
    path: "/customer-vehicle-edit/:id",
    component: EditCustomerVehiclePage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/coupons",
    component: CouponPage,
    permission: [PermissionConsts.Coupons],
  },
  {
    path: "/coupon/details/:tab/:id",
    component: CouponDetails,
    permission: [PermissionConsts.Coupons],
  },

  {
    path: "/ticket/all",
    component: TicketsPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Tickets],
  },
  {
    path: "/blacklists",
    component: BlackListPage,
    permission: [PermissionConsts.Blacklists],
  },
  {
    path: "/faqs",
    component: FaqsPage,
    permission: [PermissionConsts.FaqsMain],
  },
  {
    path: "/faqs/categories",
    component: FaqsCategoryPage,
    permission: [PermissionConsts.FaqsMain],
  },
  {
    path: "/ticket-channels",
    component: TicketChannelsPage,
    permission: [PermissionConsts.TicketChannels],
  },
  {
    path: "/ticket-subjects",
    component: TicketSubjectPage,
    permission: [PermissionConsts.TicketSubjects],
  },
  {
    path: "/ticket-subjects/:id",
    component: TicketSubjectPage,
    permission: [PermissionConsts.TicketSubjects],
  },
  {
    path: "/ticket-subjects/details/:id",
    component: TicketSubjectDetailPage,
    permission: [PermissionConsts.TicketSubjects],
  },
  {
    path: "/ticket-subjects/details/info/:id",
    component: TicketSubjectDetailInfoPage,
    permission: [PermissionConsts.TicketSubjects],
  },
  {
    path: "/databanks",
    component: DataBankPage,
    isNotAdmin: true,
    permission: [PermissionConsts.Databank],
  },
  {
    path: "/databank-order/:id",
    component: DatabankOrderPage,
    permission: [PermissionConsts.Databank],
  },
  {
    path: "/faqs-order/:id",
    component: FaqsOrderPage,
    permission: [PermissionConsts.FaqsMain],
  },
  {
    path: "/databanks-create",
    component: DatabankCreatePage,
    permission: [PermissionConsts.Databank],
  },
  {
    path: "/databanks/:id",
    component: DatabankEditPage,
    permission: [PermissionConsts.Databank],
  },
  {
    path: "/databanks-categories",
    component: DataBankCategoryPage,
    permission: [PermissionConsts.Databank],
  },
  {
    path: "/web-sliders",
    component: WebSliderPage,
    permission: [PermissionConsts.Sliders],
  },
  {
    path: "/about-us",
    component: AboutUsPage,
    permission: [PermissionConsts.WebsiteMain],
  },
  {
    path: "/web-settings",
    component: WebSiteConfigPage,
    permission: [PermissionConsts.WebsiteMain],
  },
  {
    path: "/web-contacts",
    component: ContactPage,
    permission: [PermissionConsts.WebsiteMain],
  },
  {
    path: "/web-contacts-categories",
    component: ContactCategoryPage,
    permission: [PermissionConsts.WebsiteMain],
  },

  {
    path: "/membership-cancel-requests",
    component: MembershipCancellationRequestPage,
    permission: [PermissionConsts.CancelRequest],
  },
  {
    path: "/membership-cancel-types",
    component: MembershipCancellationTypePage,
    permission: [PermissionConsts.CustomerDefinitions],
  },
  {
    path: "/users",
    component: Users,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements",
    component: AgreementsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements/change-infos",
    component: AgreementChangeInfoPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements/change-infos/:id",
    component: CreateAgreementChangeInfosPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements/change-infos/details/:id",
    component: DetailsAgreementChangeInfosPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements/create",
    component: CreateAgreementsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/agreements/details/:id",
    component: DetailsAgreementsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/account-deletion-prompt",
    component: AccountDeletionPromptPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/privacy",
    component: PrivacyPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/payment-settings",
    component: PaymentSettingsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/payment-installment",
    component: PaymentInstallmentsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/email-settings",
    component: EmailSettingsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/mtv-settings",
    component: MtvSettingsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/reminder-settings",
    component: ReminderSettingsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/privilegebuttons/:id",
    component: PrivilegeButtonPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilegecards/:id",
    component: PrivilegeCardPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/privilege/customer-privileges/:id",
    component: CustomerPrivilegeCardByCustomerIdPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/containsprivileges/:id",
    component: PrivilegeContainPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/using-privilegecards/:id",
    component: UsingPrivilegeCardPage,
    permission: [PermissionConsts.Privileges],
  },
  {
    path: "/customer-calllog-topics",
    component: CustomerCallLogTopicsPage,
    permission: [PermissionConsts.Privileges],
    isNotAdmin: true,
  },
  {
    path: "/calllog-channel",
    component: CallLogChannelsPage,
    permission: [PermissionConsts.CustomerCallLog],
    isNotAdmin: true,
  },
  {
    path: "/customer-calllog-topics/:id",
    component: CustomerCallLogTopicsPage,
    permission: [PermissionConsts.CustomerCallLog],
    isNotAdmin: true,
  },
  {
    path: "/customer-calllogs",
    component: CustomerCalllogPage,
    permission: [PermissionConsts.CustomerCallLog],
    isNotAdmin: true,
  },
  {
    path: "/informed-recipients",
    permission: [PermissionConsts.Settings],
    component: InformedRecipientsPage,
  },
  {
    path: "/backend-localizations",
    permission: [PermissionConsts.DefinitionMain],
    component: BELocalizationsPage,
  },
  {
    path: "/frontend-localizations",
    permission: [PermissionConsts.DefinitionMain],
    component: FELocalizationsPage,
  },
  {
    path: "/notification-messages",
    component: NotificationMessagesPage,
    permission: [PermissionConsts.DefinitionMain],
  },
  {
    path: "/customer-calllogs/:id",
    component: DetailCustomerCalllogPage,
    permission: [PermissionConsts.CustomerCallLog],
    isNotAdmin: true,
  },
  {
    path: "/customer-calllogs/:id",
    component: DetailCustomerCalllogPage,
    isNotAdmin: true,
    permission: [PermissionConsts.CustomerCallLog],
  },
  {
    path: "/email-templates",
    component: EmailTemplatesPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/euro-message-settings",
    component: EuroMessageSettingsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/social-media-links",
    component: SocialMediasPage,
    permission: [PermissionConsts.Settings],
  },

  {
    path: "/tires",
    component: TirePage,
    isNotAdmin: true,
    permission: [PermissionConsts.Tires],
  },
  {
    path: "/tire-brands",
    component: TireBrandPage,
    permission: [PermissionConsts.TireDefinitions],
  },

  {
    path: "/tire-diameters",
    component: TireDiameterPage,
    permission: [PermissionConsts.TireDefinitions],
  },
  {
    path: "/tire-sidewallwidths",
    component: TireSidewallWidthPage,
    permission: [PermissionConsts.TireDefinitions],
  },
  {
    path: "/tire-treadwidths",
    component: TireTreadWidthPage,
    permission: [PermissionConsts.TireDefinitions],
  },
  {
    path: "/tire-suppliers",
    component: TireSupplierPage,
    permission: [PermissionConsts.TireSuppliers],
    isNotAdmin: true,
  },
  {
    path: "/tire-services",
    component: TireServicePage,
    permission: [PermissionConsts.TireServices],
    isNotAdmin: true,
  },
  {
    path: "/tire-supplier-infos/:id",
    component: TireSupplierInfoPage,
    permission: [PermissionConsts.TireSuppliers],
    isNotAdmin: true,
  },
  {
    path: "/tire-sales",
    component: TireSalesPage,
    permission: [PermissionConsts.TireSales],
    isNotAdmin: true,
  },
  {
    path: "/tire-sales/:id",
    component: TireSaleDetailsPage,
    permission: [PermissionConsts.TireSales],
    isNotAdmin: true,
  },
  {
    path: "/products",
    component: ProductsPage,
    permission: [PermissionConsts.Products],
  },
  {
    path: "/products/:tab/:id",
    component: ProductDetailsPage,
    permission: [PermissionConsts.Products],
  },
  {
    path: "/product-categories",
    component: ProductCategoriesPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/product-categories/:id",
    component: ProductCategoriesPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/product-banners",
    component: ProductCategoryBannerPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/order-cancellation-reasons",
    component: OrderCancellationReasonsPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/order-refund-reasons",
    component: OrderRefundReasonsPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/order-shipping-companies",
    component: OrderShippingCompaniesPage,
    permission: [PermissionConsts.ProductDefinitions],
  },
  {
    path: "/orders",
    component: OrdersPage,
    permission: [PermissionConsts.ProductSales],
  },
  {
    path: "/orders/:tab/:id",
    component: OrderDetailPage,
    permission: [PermissionConsts.ProductSales],
  },
  {
    path: "/phone-numbers",
    component: PhoneNumberPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/email-logs",
    component: EmailLogsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/sms-logs",
    component: SmsLogsPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/purchased-customers",
    component: FirstPackageCustomerPage,
    permission: [PermissionConsts.PurchasedCustomers],
  },
  {
    path: "/customer-groups",
    component: CustomerGroupPage,
    permission: [PermissionConsts.CustomerDefinitions],
  },
  {
    path: "/planned-notifications",
    component: PlannedNotificationsPage,
    permission: [PermissionConsts.Notifications],
  },
  {
    path: "/planned-notification-buttons/:id",
    component: PlannedNotificationButtonPage,
    permission: [PermissionConsts.Notifications],
  },
  {
    path: "/notification-actions",
    component: NotificationActionsPage,
    permission: [PermissionConsts.Notifications],
  },
  {
    path: "/ispark-reports",
    permission: [PermissionConsts.ReportMain],
    component: IsparkReportPage,
  },
  {
    path: "/transaction-reports",
    component: TransactionReportPage,
    permission: [PermissionConsts.ReportMain],
  },
  {
    path: "/reports/customer",
    component: CustomerReportsPage,
    permission: [PermissionConsts.ReportMain],
  },
  {
    path: "/reports/customer/chart",
    component: CustomerChartPage,
    permission: [PermissionConsts.ReportMain],
  },
  {
    path: "/users/roles",
    component: RolesPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/users/roles/permissions/:roleId",
    component: PermissionPage,
    permission: [PermissionConsts.Settings],
  },
  {
    path: "/corporate-clients",
    component: CorporateCustomersPage,
  },
  {
    path: "/corporate-client/details/:tab/:id",
    component: CorporateCustomerDetailsPage,
  },
  {
    path: "/vehicle-brands",
    component: VehicleBrandsPage,
    permission: [PermissionConsts.MaintenanceDefinitions],
  },
  {
    path: "/vehicle-models",
    component: VehicleModelsPage,
    permission: [PermissionConsts.MaintenanceDefinitions],
  },
  {
    path: "/vehicle-brands/models/:id",
    component: VehicleModelsByBrandIdPage,
    permission: [PermissionConsts.MaintenanceDefinitions],
  },
  {
    path: "/maintenance-categories",
    component: MaintenanceCategoriesPage,
    permission: [PermissionConsts.MaintenanceDefinitions],
  },
  {
    path: "/service-maintenance-cards",
    component: ServiceMaintenanceCardsPage,
    permission: [PermissionConsts.MaintenanceDefinitions],
  },
  {
    path: "/maintenance-drafts",
    component: MaintenanceDraftsPage,
    permission: [PermissionConsts.Maintenances],
  },
  {
    path: "/maintenance-drafts/:id",
    component: MaintenanceDraftDetailsPage,
    permission: [PermissionConsts.Maintenances],
  },
  {
    path: "/coupon-groups",
    component: CouponGroupsPage,
    permission: [PermissionConsts.Coupons],
  },
  {
    path: "/coupon-groups/transactions/:id",
    component: CouponGroupUsageHistories,
    permission: [PermissionConsts.Coupons],
  },
  {
    path: "/system-reminder-users",
    component: SystemReminderUsersPage,
    permission: [PermissionConsts.Coupons],
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    isNotAdmin: true,
  },
];

const authRoutes: Array<RouteProps> = [
  { path: "/login", component: Login },
  { path: "/404", component: Error404 },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/confirmemail/:userId", component: ConfirmMail },
];

export { userRoutes, authRoutes };
