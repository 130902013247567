import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { EmailTemplateDto, EmailTemplateTypeDto } from "./type";

export const getEmailTemplateCustomerKeys = createAction(
  "GET_EMAIL_TEMPLATE_CUSTOMER_KEYS",
  ({ payload, params }: SagaActionParams<string[]>) => ({
    payload,
    url: "/api/admin/emailtemplates/customerKeys" + (params ? params : ""),
  })
);
export const getEmailTemplateTypes = createAction(
  "GET_EMAIL_TEMPLATE_TYPES",
  ({ payload, params }: SagaActionParams<EmailTemplateTypeDto[]>) => ({
    payload,
    url: "/api/admin/emailtemplates/templateTypes" + (params ? params : ""),
  })
);
export const getEmailTemplates = createAction(
  "GET_EMAIL_TEMPLATES",
  ({ payload, params }: SagaActionParams<EmailTemplateDto[]>) => ({
    payload,
    url: "/api/admin/emailtemplates" + (params ? params : ""),
  })
);
export const getEmailTemplatesByTypeId = createAction(
  "GET_EMAIL_TEMPLATES_TYPE_ID",
  ({ payload, id }: SagaActionParams<EmailTemplateDto[]>) => ({
    payload,
    url: "/api/admin/emailtemplates/byTemplateId/" + id,
  })
);
export const getEmailTemplatesDetails = createAction(
  "GET_EMAIL_TEMPLATES_DETAILS",
  ({ payload, id }: SagaActionParams<EmailTemplateDto>) => ({
    payload,
    url: "/api/admin/emailtemplates/" + id,
  })
);
export const createEmailTemplates = createAction(
  "CREATE_EMAIL_TEMPLATES",
  ({ payload, body }: SagaActionParams<EmailTemplateDto>) => ({
    payload,
    url: "/api/admin/emailtemplates",
    body,
  })
);
export const updateEmailTemplates = createAction(
  "UPDATE_EMAIL_TEMPLATES",
  ({ payload, id, body }: SagaActionParams<EmailTemplateDto>) => ({
    payload,
    url: "/api/admin/emailtemplates/" + id,
    body,
  })
);

export const deleteEmailTemplates = createAction(
  "DELETE_EMAIL_TEMPLATES",
  ({ payload, id }: SagaActionParams<EmailTemplateDto>) => ({
    payload,
    url: "/api/admin/emailtemplates/" + id,
  })
);
export const getPreviewTemplate = createAction(
  "GET_PREVIEW_EMAIL_TEMPLATE",
  ({ payload, id }: SagaActionParams<string>) => ({
    payload,
    url: "/api/admin/emailtemplates/preview/" + id,
  })
);
const emailTemplatesSaga = [
  /*EMAIL_TEMPLATES*/
  takeLatest(
    getPreviewTemplate.toString(),
    (payload: SagaGenericParams<string>) => getListSaga<string>(payload)
  ),
  takeLatest(
    getEmailTemplateTypes.toString(),
    (payload: SagaGenericParams<EmailTemplateTypeDto[]>) =>
      getListSaga<EmailTemplateTypeDto[]>(payload)
  ),
  takeLatest(
    getEmailTemplateCustomerKeys.toString(),
    (payload: SagaGenericParams<string[]>) => getListSaga<string[]>(payload)
  ),
  takeLatest(
    getEmailTemplates.toString(),
    (payload: SagaGenericParams<EmailTemplateDto[]>) =>
      getListSaga<EmailTemplateDto[]>(payload)
  ),
  takeLatest(
    getEmailTemplatesByTypeId.toString(),
    (payload: SagaGenericParams<EmailTemplateDto[]>) =>
      getListSaga<EmailTemplateDto[]>(payload)
  ),
  takeLatest(
    getEmailTemplatesDetails.toString(),
    (payload: SagaGenericParams<EmailTemplateDto>) =>
      getListSaga<EmailTemplateDto>(payload)
  ),
  takeLatest(
    createEmailTemplates.toString(),
    (payload: PostSagaGenericParams<EmailTemplateDto>) =>
      postSaga<EmailTemplateDto>(payload)
  ),
  takeLatest(
    updateEmailTemplates.toString(),
    (payload: PostSagaGenericParams<EmailTemplateDto>) =>
      putSaga<EmailTemplateDto>(payload)
  ),
  takeLatest(
    deleteEmailTemplates.toString(),
    (payload: SagaGenericParams<any>) => deleteSaga<EmailTemplateDto>(payload)
  ),
];

export default emailTemplatesSaga;
