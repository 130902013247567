import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CustomerCreditCardDto,
  CustomerDetailDto,
  CustomerDto,
  CustomerLookupDto,
} from "./type";
import { PostSagaGenericParams, postSaga, putSaga } from "../base/request/post";
import { BodyType } from "../base/request/request";

export const getCustomerList = createAction(
  "GET_CUSTOMER_LIST",
  ({ payload, params }: SagaActionParams<CustomerDto[]>) => ({
    payload,
    url: "/api/customers" + (params ? params : ""),
  })
);

export const getCustomerLookupList = createAction(
  "GET_CUSTOMER_LOOKUP_LIST",
  ({ payload, params }: SagaActionParams<CustomerLookupDto[]>) => ({
    payload,
    url: "/api/customers/lookup",
  })
);
export const createCustomer = createAction(
  "CREATE_CUSTOMER",
  ({ payload, body }: SagaActionParams<CustomerDto>) => ({
    payload,
    body,
    url: "/api/customers",
    bodyType: BodyType.raw,
  })
);
export const createCustomerPackage = createAction(
  "CREATE_CUSTOMER_PACKAGE",
  ({ payload, body }: SagaActionParams<CustomerDto>) => ({
    payload,
    body,
    url: "/api/customers/package",
    bodyType: BodyType.raw,
  })
);
export const getCustomerDetails = createAction(
  "GET_CUSTOMER_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerDetailDto>) => ({
    payload,
    url: "/api/customers/" + id,
  })
);
export const getCustomerCreditCardList = createAction(
  "GET_CUSTOMER_CREDIT_CARD_LIST",
  ({ payload, params, id }: SagaActionParams<CustomerCreditCardDto[]>) => ({
    payload,
    url:
      "/api/customercreditcards/getbycustomerid/" + id + (params ? params : ""),
  })
);
export const sendSmsToCustomer = createAction(
  "SEND_SMS_TO_CUSTOMER",
  ({ payload, body }: SagaActionParams<CustomerCreditCardDto[]>) => ({
    payload,
    body,
    url: "/api/sms/send-sms",
    bodyType: BodyType.raw,
  })
);

export const sendSmsToAllCustomer = createAction(
  "SEND_SMS_TO_ALL_CUSTOMER",
  ({ payload, body }: SagaActionParams<CustomerCreditCardDto[]>) => ({
    payload,
    body,
    url: "/api/sms/send-sms-all-customer",
    bodyType: BodyType.raw,
  })
);
export const cancelPackagePayment = createAction(
  "CANCEL_PACKAGE_PAYMENT",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/customers/refund-package",
    body,
    bodyType: BodyType.raw,
  })
);
export const cancelPrivilegePayment = createAction(
  "CANCEL_PRIVILEGE_PAYMENT",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/refund-privilege",
    bodyType: BodyType.raw,
  })
);
export const setCustomerToHonorary = createAction(
  "SET_CUSTOMER_TO_HONORARY",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/honorary-customer",
    bodyType: BodyType.raw,
  })
);
export const sendEmailConfirmation = createAction(
  "SEND_EMAIL_CONFIRM_MAIL_CUSTOMER",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/send-email-confirmation",
    bodyType: BodyType.raw,
  })
);

export const cancelCustomerMembership = createAction(
  "CANCEL_CUSTOMER_MEMBERSHIP",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customers/MembershipCancellationRequest",
    bodyType: BodyType.raw,
  })
);

export const importExcelCustomer = createAction(
  "IMPORT_EXCEL_CUSTOMERS",
  ({ payload, body }: SagaActionParams<string[]>) => ({
    payload,
    body,
    url: "/api/customers/import",
  })
);
export const resendRabbitMqMessage = createAction(
  "RESEND_RABBIT_MQ_MESSAGE",
  ({ payload, body }: SagaActionParams<string[]>) => ({
    payload,
    body,
    url: "/api/customers/resend-packageconsumer",
    bodyType: BodyType.raw,
  })
);
const customerSaga = [
  takeLatest(
    getCustomerList.toString(),
    (payload: SagaGenericParams<CustomerDto[]>) =>
      getListSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    getCustomerLookupList.toString(),
    (payload: SagaGenericParams<CustomerDto[]>) =>
      getListSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    getCustomerDetails.toString(),
    (payload: SagaGenericParams<CustomerDetailDto>) =>
      getListSaga<CustomerDetailDto>(payload)
  ),
  takeLatest(
    getCustomerCreditCardList.toString(),
    (payload: SagaGenericParams<CustomerCreditCardDto[]>) =>
      getListSaga<CustomerCreditCardDto[]>(payload)
  ),
  takeLatest(
    sendSmsToCustomer.toString(),
    (payload: PostSagaGenericParams<CustomerDto[]>) =>
      postSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    sendSmsToAllCustomer.toString(),
    (payload: PostSagaGenericParams<CustomerDto[]>) =>
      postSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    createCustomer.toString(),
    (payload: PostSagaGenericParams<CustomerDto[]>) =>
      postSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    cancelCustomerMembership.toString(),
    (payload: PostSagaGenericParams<CustomerDto[]>) =>
      postSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    setCustomerToHonorary.toString(),
    (payload: PostSagaGenericParams<CustomerDto[]>) =>
      putSaga<CustomerDto[]>(payload)
  ),
  takeLatest(
    cancelPrivilegePayment.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    cancelPackagePayment.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    sendEmailConfirmation.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    importExcelCustomer.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    resendRabbitMqMessage.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    createCustomerPackage.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
];

export default customerSaga;
