import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SupplierTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getSupplierTypeList = createAction(
  "GET_SUPPLIERTYPE_LIST",
  ({ payload, params }: SagaActionParams<SupplierTypeDto[]>) => ({
    payload,
    url: "/api/suppliertypes" + (params ? params : ""),
  })
);
export const getSupplierTypeDetails = createAction(
  "GET_SUPPLIERTYPE_DETAILS",
  ({ payload, id }: SagaActionParams<SupplierTypeDto>) => ({
    payload,
    url: "/api/suppliertypes/" + id,
  })
);
export const createSupplierType = createAction(
  "CREATE_SUPPLIERTYPE_DETAILS",
  ({ payload, body }: SagaActionParams<SupplierTypeDto>) => ({
    payload,
    url: "/api/suppliertypes",
    body,
  })
);
export const updateSupplierType = createAction(
  "UPDATE_SUPPLIERTYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierTypeDto>) => ({
    payload,
    url: "/api/suppliertypes/" + id,
    body,
  })
);
export const deleteSupplierType = createAction(
  "DELETE_SUPPLIERTYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<SupplierTypeDto>) => ({
    payload,
    url: "/api/suppliertypes/" + id,
    body,
  })
);

export const changeSupplierTypeOrder = createAction(
  "UPDATE_SUPPLIERTYPE_ORDER",
  ({ payload, body }: SagaActionParams<SupplierTypeDto>) => ({
    payload,
    url: "/api/suppliertypes/order",

    bodyType: BodyType.raw,
    body,
  })
);
const suppliertypeSaga = [
  takeLatest(
    getSupplierTypeList.toString(),
    (payload: SagaGenericParams<SupplierTypeDto[]>) =>
      getListSaga<SupplierTypeDto[]>(payload)
  ),
  takeLatest(
    getSupplierTypeDetails.toString(),
    (payload: SagaGenericParams<SupplierTypeDto>) =>
      getListSaga<SupplierTypeDto>(payload)
  ),
  takeLatest(
    createSupplierType.toString(),
    (payload: PostSagaGenericParams<SupplierTypeDto>) =>
      postSaga<SupplierTypeDto>(payload)
  ),
  takeLatest(
    changeSupplierTypeOrder.toString(),
    (payload: PostSagaGenericParams<SupplierTypeDto>) =>
      putSaga<SupplierTypeDto>(payload)
  ),
  takeLatest(
    updateSupplierType.toString(),
    (payload: PostSagaGenericParams<SupplierTypeDto>) =>
      putSaga<SupplierTypeDto>(payload)
  ),
  takeLatest(
    deleteSupplierType.toString(),
    (payload: SagaGenericParams<SupplierTypeDto>) =>
      deleteSaga<SupplierTypeDto>(payload)
  ),
];

export default suppliertypeSaga;
