import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "src/components/Loader";
import CustomModal from "src/components/Modal/modal";
import DataTableWithPagination from "src/components/PageContent/DataGrid";
import { getLocalDate } from "src/helpers/getLocalDate";
import { getCustomerValidatedCodeTransactionList } from "src/store/customerprivilege/saga";
import {
  CustomerUsedCouponTransactionDto,
  CustomerUsedDto,
} from "src/store/customerprivilege/type";
import { isLoading } from "src/store/loader";

const TransactionModal = ({
  onHide,
  selected,
  open,
}: {
  selected: CustomerUsedDto;
  onHide: () => void;
  open: boolean;
}) => {
  const loading = useSelector(isLoading);
  const { t } = useTranslation();
  const [data, setData] = useState<CustomerUsedCouponTransactionDto[]>([]);
  const columns = [
    {
      text: t("Supplier"),
      dataField: "SupplierName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },

    {
      text: t("Town") + "/" + t("City"),
      dataField: "CityName",
      formatter: (e: any, data: any) => `${data.TownName}/${e}`,
    },
    {
      text: t("Description"),
      dataField: "Description",
    },

    {
      text: t("Tarih"),
      dataField: "TransactionDate",
      formatter: (e: any) => getLocalDate(e),
    },
  ];

  return (
    <CustomModal
      onHide={onHide}
      outsideClick={true}
      size="lg"
      open={open}
      title={selected.QRCode + " Hareketler"}
    >
      {loading && <Loader />}
      {open && (
        <DataTableWithPagination
          title=" "
          HideBreadcrumb={true}
          listData={data}
          columns={columns}
          setDataList={setData}
          action={getCustomerValidatedCodeTransactionList}
          paramsid={selected.Id}
        />
      )}
    </CustomModal>
  );
};

export default TransactionModal;
