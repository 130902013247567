import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getUsageTypes } from "src/store/lookup/saga";
import { UsageTypeDto } from "src/store/lookup/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";
import { CalculateProfitPercentage, CalculateTotalPrice } from "./create";

const EditPrivilegePrice = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PrivilegePriceDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [usegaTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getUsageTypes({
          payload: {
            onSuccess: (msg, py) => setUsageTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit PrivilegePrice"),
        }}
        onHide={onHide}
        loader
        hideButtons
        initialValues={{
          Id: data.Id,
          PrivilegeId: data.PrivilegeId,
          Name: data.Name,
          UsageTypeId: data.UsageTypeId,
          Count: data.Count,
          Price: data.Price,
          CostPrice: data.CostPrice ?? 0,
          ProfitPercentage: data.ProfitPercentage ?? 0,
          NonMemberPrice: data.NonMemberPrice ?? 0,
          ShowStore: data.ShowStore,
          ForCustomer: data.ForCustomer,
          ImageFile: "",
          CurrentImageFile: data.Image,
          UsageInformationText: data.UsageInformationText ?? "",
          ShowUsageInformationText: data.ShowUsageInformationText ?? "",
        }}
        onSubmit={values => {}}
        validationSchema={Yup.object().shape({
          Count: Yup.number().required(t("Required")),
          NonMemberPrice: Yup.number().required(t("Required")),

          Price: Yup.number().required(t("Required")),
          PrivilegeId: Yup.string().required(t("Required")),
          UsageTypeId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            disabled: () => true,
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              disabled: true,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            disabled: () => true,
            field: "UsageTypeId",
            inputType: InputType.multiselect,
            label: t("Usage Type"),
            lookup: {
              data: usegaTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Usage Type"),
            },
          },
          {
            disabled: () => true,
            field: "Name",
            label: t("Name"),
          },
          {
            disabled: () => true,
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },
          {
            disabled: () => true,
            field: "CostPrice",
            label: t("Maliyet Fiyatı"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "Price",
                  CalculateTotalPrice(value, values.ProfitPercentage)
                );
            },
          },
          {
            disabled: () => true,
            field: "ProfitPercentage",
            label: t("Yüzdelik Kar"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "Price",
                  CalculateTotalPrice(values.CostPrice, value)
                );
            },
          },
          {
            disabled: () => true,
            field: "Price",
            label: t("Price"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "ProfitPercentage",
                  CalculateProfitPercentage(value, values.CostPrice)
                );
            },
          },
          {
            disabled: () => true,
            field: "NonMemberPrice",
            label: t("Üye Olmayan Fiyatı"),
            inputType: InputType.number,
          },
          {
            col: 6,
            disabled: () => true,
            field: "ShowStore",
            inputType: InputType.checkbox,
            label: t("Mağaza'da Göster"),
          },
          {
            disabled: () => true,
            field: "ShowUsageInformationText",
            label: t("Kullanım Şeklini Göster"),
            inputType: InputType.checkbox,
          },
          {
            disabled: () => true,
            field: "UsageInformationText",
            label: t("Kullanım Şekli"),
            height: "200px",
            inputType: InputType.editor,
          },
          {
            field: "ImageFile",
            disabled: () => true,
            label: t("Ana Resim"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentImageFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPrivilegePrice;
