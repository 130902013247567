import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CorporateLocationLookupDto, CorporateLocationsDto } from "./type";
export const getCorporateLocations = createAction(
  "GET_CORPORATE_LOCATIONS",
  ({ payload, params }: SagaActionParams<CorporateLocationsDto[]>) => ({
    payload,
    url: "/api/CorporateLocations" + (params ? params : ""),
  })
);
export const getCorporateLocationsDetails = createAction(
  "GET_CORPORATE_LOCATIONS_DETAILS",
  ({ payload, id }: SagaActionParams<CorporateLocationsDto>) => ({
    payload,
    url: "/api/CorporateLocations/" + id,
  })
);
export const createCorporateLocations = createAction(
  "CREATE_CORPORATE_LOCATIONS",
  ({ payload, body }: SagaActionParams<CorporateLocationsDto>) => ({
    payload,
    url: "/api/CorporateLocations",
    body,
  })
);
export const updateCorporateLocations = createAction(
  "UPDATE_CORPORATE_LOCATIONS",
  ({ payload, id, body }: SagaActionParams<CorporateLocationsDto>) => ({
    payload,
    url: "/api/CorporateLocations/" + id,
    body,
  })
);
export const deleteCorporateLocations = createAction(
  "DELETE_CORPORATE_LOCATIONS",
  ({ payload, id }: SagaActionParams<CorporateLocationsDto>) => ({
    payload,
    url: "/api/CorporateLocations/" + id,
  })
);
export const getCorporateLocationLookup = createAction(
  "GET_CORPORATE_LOCATION_LOOKUP",
  ({ payload, id }: SagaActionParams<CorporateLocationLookupDto[]>) => ({
    payload,
    url: "/api/CorporateLocations/lookup/" + id,
  })
);
const CorporateLocationsSaga = [
  takeLatest(
    getCorporateLocations.toString(),
    (payload: SagaGenericParams<CorporateLocationsDto[]>) =>
      getListSaga<CorporateLocationsDto[]>(payload)
  ),
  takeLatest(
    getCorporateLocationLookup.toString(),
    (payload: SagaGenericParams<CorporateLocationsDto[]>) =>
      getListSaga<CorporateLocationsDto[]>(payload)
  ),
  takeLatest(
    getCorporateLocationsDetails.toString(),
    (payload: SagaGenericParams<CorporateLocationsDto>) =>
      getListSaga<CorporateLocationsDto>(payload)
  ),
  takeLatest(
    createCorporateLocations.toString(),
    (payload: PostSagaGenericParams<CorporateLocationsDto>) =>
      postSaga<CorporateLocationsDto>(payload)
  ),
  takeLatest(
    updateCorporateLocations.toString(),
    (payload: PostSagaGenericParams<CorporateLocationsDto>) =>
      putSaga<CorporateLocationsDto>(payload)
  ),
  takeLatest(
    deleteCorporateLocations.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<CorporateLocationsDto>(payload)
  ),
];

export default CorporateLocationsSaga;
