import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getCampaignList } from "src/store/campaigns/saga";
import { CampaignDto } from "src/store/campaigns/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto, PrivilegeTypeConsts } from "src/store/privilege/type";
import {
  createMultiplePrivilegeLocation,
  getSupplierLocationsForPrivilege,
} from "src/store/privilegelocations/saga";
import { SupplierLocationDto } from "src/store/supplierlocations/type";
import * as Yup from "yup";

const CreatePrivilegeLocation = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
  privilege,
}: {
  privilege: CampaignDto;
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [locations, setLocations] = useState<SupplierLocationDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getSupplierLocationsForPrivilege({
          payload: {
            onSuccess: (msg, py) => setLocations(py),
            onError: () => {},
          },
          params: `?SupplierId=${privilege.SupplierId}&PrivilegeId=${privilege.Id}`,
        })
      );
      privilege.PrivilegeTypeId === PrivilegeTypeConsts.Privilege
        ? dispatch(
            getPrivilegeList({
              payload: {
                onSuccess: (msg, py) => setPrivileges(py),
                onError: () => {},
              },
            })
          )
        : dispatch(
            getCampaignList({
              payload: {
                onSuccess: (msg, py) => setCampaigns(py),
                onError: () => {},
              },
            })
          );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Privilege Locations"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          SupplierLocationIds: [],
        }}
        onSubmit={values => {
          dispatch(
            createMultiplePrivilegeLocation({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          SupplierLocationIds: Yup.array()
            .min(1, t("Required"))
            .required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data:
                privilege.PrivilegeTypeId === PrivilegeTypeConsts.Privilege
                  ? privileges
                  : campaigns,
              disabled: true,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "SupplierLocationIds",
            inputType: InputType.checkpicker,
            label: t("Supplier Location"),
            lookup: {
              data: locations,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Supplier Location"),
              showSelectAll: true,
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeLocation;
