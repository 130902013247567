import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import Loader from "src/components/Loader";
import {
  addPermissionToRole,
  getPermissionListSaga,
  getRolePermissionList,
} from "src/store/permissions/saga";
import { PermissionDto } from "src/store/permissions/type";
import { getRoleDetailSaga } from "src/store/roles/saga";
import { RoleDto } from "src/store/roles/type";
import { v4 as uuidv4 } from "uuid";
import Breadcrumbs from "src/components/Common/Breadcrumb";
import PermissionToggle from "./toggle";
import { isLoading } from "src/store/loader";

export interface PermissionFormikParams {
  RoleId: string;
  Permissions: PermissionGrantParams[];
}
export interface PermissionGrantParams {
  index: number;
  Name: string;
  uniqId: string;
  IsGranted: boolean;
  Code: string;
  EnumId: number;
}
const FormReset = ({
  roleId,
  permissions,
  rolePermissions,
}: {
  roleId: string;
  permissions: PermissionDto[];
  rolePermissions: string[];
}) => {
  const { resetForm } = useFormikContext<PermissionFormikParams>();
  const checkForRole = (permission: PermissionDto) => {
    return rolePermissions.some(a => a === permission.Code);
  };
  useEffect(() => {
    resetForm({
      values: {
        RoleId: roleId,
        Permissions: permissions.map((x, i) => {
          return {
            index: i,
            uniqId: uuidv4(),
            Name: x.Name,
            Code: x.Code,
            EnumId: x.EnumId,
            IsGranted: checkForRole(x),
          };
        }),
      },
    }); // eslint-disable-next-line
  }, [rolePermissions]);
  return <React.Fragment></React.Fragment>;
};
const PermissionPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { roleId } = useParams<{ roleId: string }>();
  const [role, setRole] = useState<RoleDto>();
  const [permissions, setPermissions] = useState<PermissionDto[]>([]);
  const [rolePermissions, setRolePermissions] = useState<string[]>([]);
  const loading = useSelector(isLoading);
  const [rolePermissionLoader, setRolePermissionLoader] = useState(true);
  const [permissionLoader, setPermissionLoader] = useState(true);

  useEffect(() => {
    dispatch(
      getPermissionListSaga({
        payload: {
          onSuccess: (m, r) => {
            setPermissions(r);
            return setPermissionLoader(false);
          },
          onError: () => {
            return setPermissionLoader(false);
          },
        },
      })
    );
    dispatch(
      getRoleDetailSaga({
        payload: {
          onSuccess: (m, r) => {
            setRole(r);
            return setRolePermissionLoader(false);
          },
          onError: () => setRolePermissionLoader(false),
        },
        id: roleId,
      })
    );
    dispatch(
      getRolePermissionList({
        payload: {
          onSuccess: (m, r) => setRolePermissions(r),
          onError: () => {},
        },
        id: roleId,
      })
    ); // eslint-disable-next-line
  }, [dispatch]);
  const checkForRole = (permission: PermissionDto) => {
    return rolePermissions.some(a => a === permission.Code);
  };
  document.title = (role ? role.Name : "") + t(" Rol Yetkilendirme");
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        {!permissionLoader && !rolePermissionLoader && (
          <Formik
            onSubmit={(values, { resetForm }) => {
              dispatch(
                addPermissionToRole({
                  payload: {
                    onSuccess: (m, r) => {
                      toast.success(m);
                      return setRolePermissions(r);
                    },
                    onError: () => {},
                  },
                  body: values,
                })
              );
            }}
            initialValues={{
              RoleId: roleId,
              Permissions: permissions.map((x, i) => {
                return {
                  index: i,
                  uniqId: uuidv4(),
                  Name: x.Name,
                  Code: x.Code,
                  EnumId: i,
                  IsGranted: checkForRole(x),
                };
              }),
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <FormReset
                  roleId={roleId ?? ""}
                  permissions={permissions}
                  rolePermissions={rolePermissions}
                />
                {role && (
                  <Container fluid>
                    <div className="d-flex justify-content-between align-items-end">
                      <Breadcrumbs
                        title={t("Dashboard")}
                        breadcrumbItem={
                          role.Name +
                          t(" Rol Yetkilendirme", {
                            roleName: role.Name.toLocaleUpperCase(),
                          })
                        }
                      />
                      <div>
                        <Button
                          style={{ width: 130 }}
                          color="info"
                          type="submit"
                          onClick={() => handleSubmit()}
                        >
                          {t("Kaydet")}
                        </Button>
                      </div>
                    </div>

                    <div className="d-flex justify-content-start mt-3 ">
                      <PermissionToggle
                        uncheckField={"Tümü"}
                        checkField={"Tümü"}
                        field={"all"}
                        value={
                          !values.Permissions.some(x => x.IsGranted === false)
                        }
                        onChange={e => {
                          setFieldValue(
                            "Permissions",
                            values.Permissions.map(a => {
                              return { ...a, IsGranted: e };
                            })
                          );
                        }}
                      />
                    </div>
                    <Row className="px-2 mt-4">
                      {values.Permissions.map((y, i) => {
                        return (
                          <>
                            <Col key={i} md={12}>
                              <PermissionToggle
                                borderRadius={4}
                                handleDiameter={11}
                                height={14}
                                width={28}
                                label={y.Name}
                                field={values.Permissions[y.index].uniqId}
                                value={values.Permissions[y.index].IsGranted}
                                onChange={e => {
                                  return setFieldValue(
                                    `Permissions[${y.index}.IsGranted]`,
                                    e
                                  );
                                }}
                              />
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </Container>
                )}
              </>
            )}
          </Formik>
        )}
      </div>
    </React.Fragment>
  );
};

export default PermissionPage;
