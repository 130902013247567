import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { TicketSubjectDetailDto } from "./type";
import { BodyType } from "../base/request/request";

export const getTicketSubjectDetailListByTicketSubjectId = createAction(
  "GET_TICKET_SUBJECT_DETAILLIST_BY_ID",
  ({ payload, id, params }: SagaActionParams<TicketSubjectDetailDto[]>) => ({
    payload,
    url: "/api/ticketsubjectdetails/bysubjectid/" + id + (params ? params : ""),
  })
);
export const getTicketSubjectDetailList = createAction(
  "GET_TICKET_SUBJECT_DETAILLIST",
  ({ payload, params }: SagaActionParams<TicketSubjectDetailDto[]>) => ({
    payload,
    url: "/api/ticketsubjectdetails" + (params ? params : ""),
  })
);
export const getTicketSubjectDetailDetails = createAction(
  "GET_TICKET_SUBJECT_DETAILDETAILS",
  ({ payload, id }: SagaActionParams<TicketSubjectDetailDto>) => ({
    payload,
    url: "/api/ticketsubjectdetails/" + id,
  })
);
export const createTicketSubjectDetail = createAction(
  "CREATE_TICKET_SUBJECT_DETAILDETAILS",
  ({ payload, body }: SagaActionParams<TicketSubjectDetailDto>) => ({
    payload,
    url: "/api/ticketsubjectdetails",
    body,
  })
);
export const updateTicketSubjectDetail = createAction(
  "UPDATE_TICKET_SUBJECT_DETAILDETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDetailDto>) => ({
    payload,
    url: "/api/ticketsubjectdetails/" + id,
    body,
  })
);
export const deleteTicketSubjectDetail = createAction(
  "DELETE_TICKET_SUBJECT_DETAILDETAILS",
  ({ payload, body, id }: SagaActionParams<TicketSubjectDetailDto>) => ({
    payload,
    url: "/api/ticketsubjectdetails/" + id,
    body,
  })
);

export const changeTicketSubjectDetailOrder = createAction(
  "UPDATE_TICKET_SUBJECT_DETAILORDER",
  ({ payload, body }: SagaActionParams<TicketSubjectDetailDto>) => ({
    payload,
    url: "/api/ticketsubjectdetails/order",

    bodyType: BodyType.raw,
    body,
  })
);
const ticketSubjectDetailSaga = [
  takeLatest(
    getTicketSubjectDetailListByTicketSubjectId.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailDto[]>) =>
      getListSaga<TicketSubjectDetailDto[]>(payload)
  ),
  takeLatest(
    getTicketSubjectDetailList.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailDto[]>) =>
      getListSaga<TicketSubjectDetailDto[]>(payload)
  ),
  takeLatest(
    getTicketSubjectDetailDetails.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailDto>) =>
      getListSaga<TicketSubjectDetailDto>(payload)
  ),
  takeLatest(
    createTicketSubjectDetail.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailDto>) =>
      postSaga<TicketSubjectDetailDto>(payload)
  ),
  takeLatest(
    changeTicketSubjectDetailOrder.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailDto>) =>
      putSaga<TicketSubjectDetailDto>(payload)
  ),
  takeLatest(
    updateTicketSubjectDetail.toString(),
    (payload: PostSagaGenericParams<TicketSubjectDetailDto>) =>
      putSaga<TicketSubjectDetailDto>(payload)
  ),
  takeLatest(
    deleteTicketSubjectDetail.toString(),
    (payload: SagaGenericParams<TicketSubjectDetailDto>) =>
      deleteSaga<TicketSubjectDetailDto>(payload)
  ),
];

export default ticketSubjectDetailSaga;
