import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { createPrivacy, getPrivacy } from "src/store/agreement/saga";
import { PrivacyDto } from "src/store/agreement/type";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const PrivacyPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(isLoading);
  const [loader, setLoader] = useState(false);
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<PrivacyDto>();
  useEffect(() => {
    setLoader(true);
    dispatch(
      getPrivacy({
        payload: {
          onSuccess: (msg, r) => {
            setData(r);
            setLoader(false);
          },
          onError: () => {
            setLoader(false);
          },
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={t("Dashboard")} breadcrumbItem={t("Gizlilik")} />

          <Row className="mt-4">
            {(loading || loader) && <Loader />}
            {!loader && (
              <CustomFormik
                loader
                initialValues={{
                  title: data?.Title ?? "",
                  description: data?.Description ?? "",
                }}
                onSubmit={(values, { resetForm }) => {
                  dispatch(
                    createPrivacy({
                      payload: {
                        onSuccess: (message, payload) => {
                          resetForm({
                            values: {
                              title: payload?.Title ?? "",
                              description: payload?.Description ?? "",
                            },
                          });
                          toast.success(message, { theme: "colored" });
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({})}
                inputs={[
                  {
                    field: "title",
                    label: t("Title"),
                    inputType: InputType.editor,
                  },
                  {
                    field: "description",
                    inputType: InputType.editor,
                    label: t("Description"),
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default PrivacyPage;
