import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CampaignDto } from "./type";
import { BodyType } from "../base/request/request";

export const getCampaignList = createAction(
  "GET_CAMPAIGN_LIST",
  ({ payload, params }: SagaActionParams<CampaignDto[]>) => ({
    payload,
    url: "/api/campaigns" + (params ? params : ""),
  })
);
export const getCampaignListByCategoryId = createAction(
  "GET_CAMPAIGN_LIST_BY_CATEGORY_ID",
  ({ payload, params, id }: SagaActionParams<CampaignDto[]>) => ({
    payload,
    url: "/api/campaigns/bycategoryId/" + id + (params ? params : ""),
  })
);
export const changeCampaignOrder = createAction(
  "CHANGE_CAMPAIGN_ORDER",
  ({ payload, body }: SagaActionParams<CampaignDto>) => ({
    payload,
    body,
    url: "/api/campaigns/order",

    bodyType: BodyType.raw,
  })
);
export const getCampaignDetails = createAction(
  "GET_CAMPAIGN_DETAILS",
  ({ payload, id }: SagaActionParams<CampaignDto>) => ({
    payload,
    url: "/api/campaigns/" + id,
  })
);
export const createCampaign = createAction(
  "CREATE_CAMPAIGN_DETAILS",
  ({ payload, body }: SagaActionParams<CampaignDto>) => ({
    payload,
    url: "/api/campaigns",
    body,
  })
);
export const updateCampaign = createAction(
  "UPDATE_CAMPAIGN_DETAILS",
  ({ payload, body, id }: SagaActionParams<CampaignDto>) => ({
    payload,
    url: "/api/campaigns/" + id,
    body,
  })
);
export const updateCampaignPriority = createAction(
  "UPDATE_PRIORITY",
  ({ payload, body }: SagaActionParams<CampaignDto>) => ({
    payload,
    url: "/api/campaigns/priority",
    body,
  })
);
export const deleteCampaign = createAction(
  "DELETE_CAMPAIGN_DETAILS",
  ({ payload, body, id }: SagaActionParams<CampaignDto>) => ({
    payload,
    url: "/api/campaigns/" + id,
    body,
  })
);
const campaignsSaga = [
  takeLatest(
    getCampaignList.toString(),
    (payload: SagaGenericParams<CampaignDto[]>) =>
      getListSaga<CampaignDto[]>(payload)
  ),
  takeLatest(
    getCampaignListByCategoryId.toString(),
    (payload: SagaGenericParams<CampaignDto>) =>
      getListSaga<CampaignDto>(payload)
  ),
  takeLatest(
    getCampaignDetails.toString(),
    (payload: SagaGenericParams<CampaignDto>) =>
      getListSaga<CampaignDto>(payload)
  ),
  takeLatest(
    createCampaign.toString(),
    (payload: PostSagaGenericParams<CampaignDto>) =>
      postSaga<CampaignDto>(payload)
  ),
  takeLatest(
    updateCampaignPriority.toString(),
    (payload: PostSagaGenericParams<CampaignDto>) =>
      putSaga<CampaignDto>(payload)
  ),
  takeLatest(
    updateCampaign.toString(),
    (payload: PostSagaGenericParams<CampaignDto>) =>
      putSaga<CampaignDto>(payload)
  ),
  takeLatest(
    changeCampaignOrder.toString(),
    (payload: PostSagaGenericParams<CampaignDto>) =>
      putSaga<CampaignDto>(payload)
  ),
  takeLatest(
    deleteCampaign.toString(),
    (payload: SagaGenericParams<CampaignDto>) =>
      deleteSaga<CampaignDto>(payload)
  ),
];

export default campaignsSaga;
