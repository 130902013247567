import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { VehicleBodyTypeDto } from "./type";
import { BodyType } from "../base/request/request";

export const getVehicleBodyTypeList = createAction(
  "GET_VEHICLE_BODY_TYPE_LIST",
  ({ payload, params }: SagaActionParams<VehicleBodyTypeDto[]>) => ({
    payload,
    url: "/api/vehiclebodytypes" + (params ? params : ""),
  })
);
export const getVehicleBodyTypeDetails = createAction(
  "GET_VEHICLE_BODY_TYPE_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleBodyTypeDto>) => ({
    payload,
    url: "/api/vehiclebodytypes/" + id,
  })
);
export const createVehicleBodyType = createAction(
  "CREATE_VEHICLE_BODY_TYPE_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleBodyTypeDto>) => ({
    payload,
    url: "/api/vehiclebodytypes",
    body,
  })
);
export const updateVehicleBodyType = createAction(
  "UPDATE_VEHICLE_BODY_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleBodyTypeDto>) => ({
    payload,
    url: "/api/vehiclebodytypes/" + id,
    body,
  })
);
export const deleteVehicleBodyType = createAction(
  "DELETE_VEHICLE_BODY_TYPE_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleBodyTypeDto>) => ({
    payload,
    url: "/api/vehiclebodytypes/" + id,
    body,
  })
);

export const changeVehicleBodyTypeOrder = createAction(
  "UPDATE_VEHICLE_BODY_TYPE_ORDER",
  ({ payload, body }: SagaActionParams<VehicleBodyTypeDto>) => ({
    payload,
    url: "/api/vehiclebodytypes/order",

    bodyType: BodyType.raw,
    body,
  })
);
const vehiclebodytypeSaga = [
  takeLatest(
    getVehicleBodyTypeList.toString(),
    (payload: SagaGenericParams<VehicleBodyTypeDto[]>) =>
      getListSaga<VehicleBodyTypeDto[]>(payload)
  ),
  takeLatest(
    getVehicleBodyTypeDetails.toString(),
    (payload: SagaGenericParams<VehicleBodyTypeDto>) =>
      getListSaga<VehicleBodyTypeDto>(payload)
  ),
  takeLatest(
    createVehicleBodyType.toString(),
    (payload: PostSagaGenericParams<VehicleBodyTypeDto>) =>
      postSaga<VehicleBodyTypeDto>(payload)
  ),
  takeLatest(
    changeVehicleBodyTypeOrder.toString(),
    (payload: PostSagaGenericParams<VehicleBodyTypeDto>) =>
      putSaga<VehicleBodyTypeDto>(payload)
  ),
  takeLatest(
    updateVehicleBodyType.toString(),
    (payload: PostSagaGenericParams<VehicleBodyTypeDto>) =>
      putSaga<VehicleBodyTypeDto>(payload)
  ),
  takeLatest(
    deleteVehicleBodyType.toString(),
    (payload: SagaGenericParams<VehicleBodyTypeDto>) =>
      deleteSaga<VehicleBodyTypeDto>(payload)
  ),
];

export default vehiclebodytypeSaga;
