//@ts-nocheck
import { useEffect, useRef } from "react";
import * as ko from "knockout";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import { baseUrl } from "src/store/base/types/url";

const ReportViewer = () => {
  const { token } = useSelector(getApiParams);
  const viewer = useRef<any>();
  const reportUrl = ko.observable(
    "Contract* 0107848c-451a-46a2-ba95-2e6840a915ff"
  );
  ajaxSetup.ajaxSettings = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const requestOptions = {
    host: baseUrl + "/api/",

    // Use this line for the ASP.NET MVC backend.
    //invokeAction: "/WebDocumentViewer/Invoke"
    // Use this line for the ASP.NET Core backend
    invokeAction: "DXXRDV",
  };
  useEffect(() => {
    ko.applyBindings(
      {
        reportUrl: reportUrl,
        requestOptions: requestOptions,
      },
      viewer.current
    );
    // eslint-disable-next-line
  }, []);
  return <div ref={viewer} data-bind="dxReportViewer: $data"></div>;
};
export default ReportViewer;
