import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import TicketLevelComp from "src/pages/Email/comp/ticketLevel";
import OpenCallLogModal from "src/pages/Email/calllog/openCalllog";
import { toast } from "react-toastify";

const TicketsTab = () => {
  const { id }: { id: string; tab: string } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [customerShow, setCustomerShow] = useState(false);
  const columns = [
    {
      caption: t("Öncelik"),
      dataField: "TicketLevelName",
      cellRender: (e: any) => {
        return <TicketLevelComp levelId={e.row.data.TicketLevelId} />;
      },
    },
    {
      caption: t("Kaynak"),
      dataField: "TicketSourceTypeName",
      cellRender: (e: any) => {
        return e.row.data.TicketSourceTypeId === 2 ? "Çağrı" : "Talep";
      },
    },
    {
      caption: t("Code"),
      dataField: "Code",
    },

    {
      caption: t("Durum"),
      dataField: "TicketStatusType",
    },
    {
      caption: t("Tip"),
      dataField: "TicketType",
    },
    {
      caption: t("Plaka"),
      dataField: "Plate",
    },
    {
      caption: t("Marka"),
      dataField: "Make",
    },
    {
      caption: t("Model"),
      dataField: "Model",
    },
    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "date",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          text: "Detay",
          cssClass: "text-dark bg-soft-success columnUseButton",
          onClick: async (e: any) => {
            history.push("/ticket/all?ticketId=" + e.row.data.Id);
          },
        },
      ],
    },
  ];
  return (
    <React.Fragment>
      <CustomDataGrid
        className="detailGrid"
        url={"/api/tickets/bycustomerid/devexp/" + id}
        columns={columns}
        create={() => setCustomerShow(true)}
      />
      <div style={{ marginBlockStart: 50 }}></div>

      <OpenCallLogModal
        show={customerShow}
        customerId={id}
        onHide={() => setCustomerShow(false)}
        onSuccessSubmit={payload => {
          toast.success("Çağrı başarıyla oluşturuldu");
          window.open("/ticket/all?ticketId=" + payload?.Id, "_blank");
        }}
      />
    </React.Fragment>
  );
};

export default TicketsTab;
