import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getUsageTypes } from "src/store/lookup/saga";
import { UsageTypeDto } from "src/store/lookup/type";
import { updatePackageGift } from "src/store/packagegift/saga";
import { PackageGiftDto } from "src/store/packagegift/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegePriceListByPrivilegeId } from "src/store/privilegeprices/saga";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";

const EditPackageGiftModal = ({
  onHide,
  data,
  open,
  onSuccessClick,
}: {
  data: PackageGiftDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [usagetypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getUsageTypes({
          payload: {
            onSuccess: (msg, py) => setUsageTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegePriceListByPrivilegeId({
          payload: {
            onSuccess: (msg, py) => setPrices(py),
            onError: () => {},
          },
          id: data.PrivilegeId,
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Edit Gift Privilege to Package"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          PackageId: data.PackageId,
          PrivilegePriceId: data.PrivilegePriceId ?? "",
          PrivilegeId: data.PrivilegeId ?? "",
          PackageName: data.PackageName ?? "",
          UsageTypeId: data.UsageTypeId,
          Count: data.Count,
          Active: data.Active,
          ShowInformation: data.ShowInformation,
          InformationText: data.InformationText ?? "",
          // CurrentFile: data.Icon ?? "",
          // IconFile: "",
          Include: data.Include,
          Yearly: data.Yearly,
          CanBeUseAfterMonthlyCancel: data.CanBeUseAfterMonthlyCancel,
          CanBeUseAfterYearlyCancel: data.CanBeUseAfterYearlyCancel,
          TakePayment: data.TakePayment,
        }}
        onSubmit={values => {
          dispatch(
            updatePackageGift({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick("Hediye Hizmet başarıyla güncellenmiştir");
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({})}
        inputs={[
          {
            field: "PackageName",
            label: t("Mobilde Görünecek Ad"),
          },
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getPrivilegePriceListByPrivilegeId({
                    payload: {
                      onSuccess: (msg, py) => setPrices(py),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            field: "PrivilegePriceId",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
            onChange(value, item, setFieldValue) {
              item &&
                item.UsageTypeId &&
                setFieldValue("UsageTypeId", item.UsageTypeId);
            },
          },
          {
            field: "UsageTypeId",
            inputType: InputType.multiselect,
            label: t("Usage Type"),
            lookup: {
              data: usagetypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Usage Type"),
            },
          },

          {
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },

          {
            field: "ShowInformation",
            label: t("Show Information"),
            inputType: InputType.checkbox,
          },
          {
            field: "InformationText",
            label: t("Information Text"),
            inputType: InputType.multilinetext,
          },

          {
            field: "Include",
            label: t("Include Question"),
            inputType: InputType.radiogrup,
            radiogrups: {
              valueKey: "value",
              labelKey: "label",
              data: [
                {
                  value: true,
                  label: t("Yes"),
                },
                {
                  value: false,
                  label: t("No"),
                },
              ],
            },
          },

          {
            field: "Yearly",
            label: t("Yearly"),
            col: 3,
            checkedLabel: t("Yearly"),
            uncheckedLabel: t("Monthly"),
            inputType: InputType.toogle,
          },
          {
            field: "CanBeUseAfterMonthlyCancel",
            label: t("Aylık İptal Sonrası Kullanılabilir mi?"),
            inputType: InputType.checkbox,
          },
          {
            field: "CanBeUseAfterYearlyCancel",
            label: t("Yıllık İptal Sonrası Kullanılabilir mi?"),
            inputType: InputType.checkbox,
          },
          {
            field: "TakePayment",
            label: t("Üyelik İptal Ödemesine Dahil"),
            inputType: InputType.checkbox,
          },
          {
            field: "Active",
            label: t("Active"),
            col: 3,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
          // {
          //   field: "IconFile",
          //   label: t("IconFile Image"),
          //   inputType: InputType.fileUpload,
          //   fileUpload: {
          //     linkField: "CurrentFile",
          //     multiple: false,
          //     accept: ["image/svg+xml"],
          //     title: t("Please Upload Icon Image"),
          //   },
          // },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPackageGiftModal;
