import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { MaintenanceDraftDetailDto, MaintenanceDraftDto } from "./type";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getMaintenanceDraftList = createAction(
  "GET_MAINTENANCE_DRAFT_LIST",
  ({ payload, params }: SagaActionParams<MaintenanceDraftDto[]>) => ({
    payload,
    url: "/api/maintenancedrafts" + (params ? params : ""),
  })
);

export const getMaintenanceDraftDetails = createAction(
  "GET_MAINTENANCE_DRAFT_DETAILS",
  ({ payload, id }: SagaActionParams<MaintenanceDraftDetailDto>) => ({
    payload,
    url: "/api/maintenancedrafts/" + id,
  })
);
export const createMaintenanceDraft = createAction(
  "CREATE_MAINTENANCE_DRAFT",
  ({ payload, body }: SagaActionParams<MaintenanceDraftDto>) => ({
    payload,
    url: "/api/maintenancedrafts",
    body,
  })
);
export const updateMaintenanceDraft = createAction(
  "UPDATE_MAINTENANCE_DRAFT",
  ({ payload, body, id }: SagaActionParams<MaintenanceDraftDto>) => ({
    payload,
    url: "/api/maintenancedrafts/" + id,
    body,
  })
);
export const deleteMaintenanceDraft = createAction(
  "DELETE_MAINTENANCE_DRAFT",
  ({ payload, body, id }: SagaActionParams<MaintenanceDraftDto>) => ({
    payload,
    url: "/api/maintenancedrafts/" + id,
    body,
  })
);
export const importMaintenanceDraft = createAction(
  "IMPORT_MAINTENANCE_DRAFT",
  ({ payload, body }: SagaActionParams<MaintenanceDraftDto>) => ({
    payload,
    url: "/api/maintenancedrafts/import",
    body,
  })
);
const maintenanceDraftsSaga = [
  takeLatest(
    getMaintenanceDraftList.toString(),
    (payload: SagaGenericParams<MaintenanceDraftDto[]>) =>
      getListSaga<MaintenanceDraftDto[]>(payload)
  ),
  takeLatest(
    getMaintenanceDraftDetails.toString(),
    (payload: SagaGenericParams<MaintenanceDraftDto>) =>
      getListSaga<MaintenanceDraftDto>(payload)
  ),
  takeLatest(
    createMaintenanceDraft.toString(),
    (payload: PostSagaGenericParams<MaintenanceDraftDto>) =>
      postSaga<MaintenanceDraftDto>(payload)
  ),
  takeLatest(
    importMaintenanceDraft.toString(),
    (payload: PostSagaGenericParams<MaintenanceDraftDto>) =>
      postSaga<MaintenanceDraftDto>(payload)
  ),
  takeLatest(
    updateMaintenanceDraft.toString(),
    (payload: PostSagaGenericParams<MaintenanceDraftDto>) =>
      putSaga<MaintenanceDraftDto>(payload)
  ),
  takeLatest(
    deleteMaintenanceDraft.toString(),
    (payload: SagaGenericParams<MaintenanceDraftDto>) =>
      deleteSaga<MaintenanceDraftDto>(payload)
  ),
];

export default maintenanceDraftsSaga;
