import { TextBox } from "devextreme-react";
import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { Modal } from "rsuite";
import CustomModal from "src/components/Modal/modal";
import OrderRefundRequestImageList from "./refundImageList";
import moment from "moment";
import { OrderDetailDto } from "src/store/orders/orders/type";

const RefundInfoCard = ({ order }: { order: OrderDetailDto }) => {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      {order.OrderItems.some(x => x.OrderItemStatusId > 5) && (
        <>
          <Col md={4} className="mx-4 my-2">
            <button
              className="btn btn-sm btn-soft-info"
              onClick={() => setShow(true)}
            >
              İade Formunu Görüntüle
            </button>
          </Col>
          <CustomModal
            open={show}
            size="md"
            onHide={() => setShow(false)}
            title={
              <Modal.Title className="text-center mb-2">
                <span className="fw-bold text-center " style={{ fontSize: 18 }}>
                  İade Bilgileri
                </span>
              </Modal.Title>
            }
          >
            {order.OrderRefundRequests?.map(x => (
              <Card>
                <Row>
                  <Col md={6}>
                    <OrderRefundRequestImageList data={x} />
                  </Col>
                  <Col md={6}>
                    <div className="mt-4 mt-xl-3 my-4">
                      <TextBox
                        readOnly
                        label={"Tarih"}
                        value={moment(x.RequestDate).format("DD/MM/yyyy HH:mm")}
                        stylingMode="filled"
                        style={{
                          backgroundColor: "white",
                          padding: 0,
                        }}
                      />
                      <TextBox
                        readOnly
                        label={"İade Nedeni"}
                        value={x.OrderRefundReasonName}
                        stylingMode="filled"
                        style={{
                          backgroundColor: "white",
                          padding: 0,
                        }}
                      />
                      <TextBox
                        readOnly
                        label={"Not"}
                        value={x.Note}
                        stylingMode="filled"
                        style={{
                          backgroundColor: "white",
                          padding: 0,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
          </CustomModal>
        </>
      )}
    </React.Fragment>
  );
};
export default RefundInfoCard;
