import produce from "immer";
import { createAction } from "redux-smart-actions";
import { createSelector } from "reselect";
import { KomutRedux } from "../base/types/KomutRedux";
import { RootState } from "../base/types/RootState";

const SHOW_LOADER = "SHOW_LOADER";
const HIDE_LOADER = "HIDE_LOADER";

export interface LoaderReducer {
  loading: boolean;
}

export interface LoaderActions {
  showLoader: any;
  hideLoader: any;
}

const loader = new KomutRedux<LoaderReducer, LoaderActions>();

loader.setInitialState({ loading: false });

loader.setActions({
  showLoader: createAction(SHOW_LOADER),
  hideLoader: createAction(HIDE_LOADER),
});

loader.setRedusers(
  produce((draft: LoaderReducer, action: any) => {
    switch (action.type) {
      case SHOW_LOADER:
        draft.loading = true;
        break;
      case HIDE_LOADER:
        draft.loading = false;
        break;
    }
  }, loader.initialState)
);

// ** SELECTORS *** \\
const loadingState = (state: RootState) => state.loader.loading;
export const isLoading = createSelector([loadingState], (loading) => loading);

export default loader;
