import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import languageRedux, { languagesSelector } from "src/store/languages";
import { getRoles } from "src/store/lookup/saga";
import { RoleDto } from "src/store/lookup/type";
import { createUser } from "src/store/user/saga";
import * as Yup from "yup";

const CreateUser = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const langs = useSelector(languagesSelector);
  const [roles, setRoles] = useState<RoleDto[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    if (open) {
      dispatch(languageRedux.actions.getLanguages());
      dispatch(
        getRoles({
          payload: {
            onSuccess: (msg, payload) => setRoles(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Kullanıcı Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Name: "",
          Surname: "",
          RoleId: "",
          LanguageId: "",
          Email: "",
          Password: "",
          ConfirmPassword: "",
          PhoneNumber: "",
        }}
        onSubmit={values => {
          dispatch(
            createUser({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          RoleId: Yup.string().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          Email: Yup.string().required(t("Required")),
          Password: Yup.string().required(t("Required")),
          ConfirmPassword: Yup.string()
            .required(t("Required"))
            .oneOf([Yup.ref("Password")], t("Password no matched")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Surname",
            label: t("Surname"),
            col: 6,
          },
          {
            field: "RoleId",
            inputType: InputType.multiselect,
            label: t("Rol"),
            lookup: {
              data: roles,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: "Rol Seçiniz",
            },
          },
          {
            field: "LanguageId",
            inputType: InputType.multiselect,
            label: t("Dil"),
            lookup: {
              data: langs,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: "Dil Seçiniz",
            },
          },
          {
            field: "Email",
            label: t("Email"),
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            field: "Password",
            label: t("Password"),
            col: 6,
          },
          {
            field: "ConfirmPassword",
            label: t("Confirm Password"),
            col: 6,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateUser;
