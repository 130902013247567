import React from "react";
import CustomToggle, { ToggleProps } from "src/components/Toggle";
interface ToggleInputProps extends ToggleProps {
  field?: string;
  label?: string;
}
const PermissionToggle = ({ label, field, ...rest }: ToggleInputProps) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-start align-items-center gap-1">
        <CustomToggle {...rest} />
        <div>
          <span style={{ fontWeight: 400, fontSize: 13 }}>{label}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PermissionToggle;
