import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { VehicleColorDto } from "./type";
import { BodyType } from "../base/request/request";

export const getVehicleColorList = createAction(
  "GET_VEHICLE_COLOR_LIST",
  ({ payload, params }: SagaActionParams<VehicleColorDto[]>) => ({
    payload,
    url: "/api/vehiclecolors" + (params ? params : ""),
  })
);
export const getVehicleColorDetails = createAction(
  "GET_VEHICLE_COLOR_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleColorDto>) => ({
    payload,
    url: "/api/vehiclecolors/" + id,
  })
);
export const createVehicleColor = createAction(
  "CREATE_VEHICLE_COLOR_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleColorDto>) => ({
    payload,
    url: "/api/vehiclecolors",
    body,
  })
);
export const updateVehicleColor = createAction(
  "UPDATE_VEHICLE_COLOR_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleColorDto>) => ({
    payload,
    url: "/api/vehiclecolors/" + id,
    body,
  })
);
export const deleteVehicleColor = createAction(
  "DELETE_VEHICLE_COLOR_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleColorDto>) => ({
    payload,
    url: "/api/vehiclecolors/" + id,
    body,
  })
);

export const changeVehicleColorOrder = createAction(
  "UPDATE_VEHICLE_COLOR_ORDER",
  ({ payload, body }: SagaActionParams<VehicleColorDto>) => ({
    payload,
    url: "/api/vehiclecolors/order",

    bodyType: BodyType.raw,
    body,
  })
);
const vehiclecolorSaga = [
  takeLatest(
    getVehicleColorList.toString(),
    (payload: SagaGenericParams<VehicleColorDto[]>) =>
      getListSaga<VehicleColorDto[]>(payload)
  ),
  takeLatest(
    getVehicleColorDetails.toString(),
    (payload: SagaGenericParams<VehicleColorDto>) =>
      getListSaga<VehicleColorDto>(payload)
  ),
  takeLatest(
    createVehicleColor.toString(),
    (payload: PostSagaGenericParams<VehicleColorDto>) =>
      postSaga<VehicleColorDto>(payload)
  ),
  takeLatest(
    changeVehicleColorOrder.toString(),
    (payload: PostSagaGenericParams<VehicleColorDto>) =>
      putSaga<VehicleColorDto>(payload)
  ),
  takeLatest(
    updateVehicleColor.toString(),
    (payload: PostSagaGenericParams<VehicleColorDto>) =>
      putSaga<VehicleColorDto>(payload)
  ),
  takeLatest(
    deleteVehicleColor.toString(),
    (payload: SagaGenericParams<VehicleColorDto>) =>
      deleteSaga<VehicleColorDto>(payload)
  ),
];

export default vehiclecolorSaga;
