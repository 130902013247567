import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useHistory, useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import {
  CustomerVehicleLookupDto,
  CustomerVehicleDto,
  VehicleStepDto,
} from "src/store/customervehicles/type";
import {
  getCustomerVehicleCategory,
  getCustomerVehicleDetails,
  getCustomerVehicleSubCategory,
  updateCustomerVehicle,
} from "src/store/customervehicles/saga";
import { Formik } from "formik";
import GetInput from "src/components/Form/GetInput";
import VehicleInput from "./vehicle-input";
import { InputType } from "src/components/Form/type";
import { VehicleColorDto } from "src/store/vehiclecolor/type";
import { getVehicleColorList } from "src/store/vehiclecolor/saga";
import VehicleCard from "./card-vehicle";
import { Toggle } from "rsuite";
import { getCustomerMapData } from "./getVehicleMapData";
import { toast } from "react-toastify";
// const AlwaysScrollToBottom = () => {
//   const elementRef = useRef(null);
//   useEffect(() =>
//     //@ts-ignore
//     elementRef.current.scrollIntoView()
//   );
//   return <div ref={elementRef} />;
// };

const EditCustomerVehiclePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<CustomerVehicleDto>();
  const [categories, setCategories] = useState<CustomerVehicleLookupDto>();
  const [colors, setColors] = useState<VehicleColorDto[]>([]);
  const history = useHistory();
  const loader = useSelector(isLoading);
  useEffect(() => {
    dispatch(
      getVehicleColorList({
        payload: {
          onSuccess: (ms, payload) => {
            setColors(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getCustomerVehicleDetails({
        payload: {
          onSuccess: (ms, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getCustomerVehicleCategory({
        payload: {
          onSuccess: (ms, payload) => {
            setCategories(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Araç Düzenle")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Üye Araçları")}
            breadcrumbItem={t("Araç Düzenle")}
          />
          <Row className="mt-2 pb-5">
            {loader && <Loader />}
            {data && <VehicleCard vehicle={data} />}
            {data && categories && (
              <Formik
                initialValues={{
                  Id: data.Id,
                  Plate: data.Plate,
                  VehicleColorId: data.VehicleColorId ?? "",
                  RegistrationDate: data.RegistrationDate ?? "",
                  Warranty: data.Warranty,
                  WarrantyEndOdometer: data.WarrantyEndOdometer ?? "",
                  WarrantyStartDate: data.WarrantyStartDate ?? "",
                  WarrantyEndDate: data.WarrantyEndDate ?? "",
                  ChassisNumber: data.ChassisNumber,
                  EngineNumber: data.EngineNumber,
                  BodyHeight: data.BodyHeight ?? "",
                  BodyLength: data.BodyLength ?? "",
                  Weight: data.Weight ?? "",
                  category: "",
                  make: "",
                  model: "",
                  regdate: "",
                  body: "",
                  facelift: "",
                  seats: "",
                  engine: "",
                  fueltype: "",
                  wheeldrive: "",
                  transmission: "",
                  trim: "",
                  GearTypeName: "",
                  BodyTypeName: "",
                  FuelTypeName: "",
                  trimid: "",
                  variantid: "",
                  DataArray: [],
                  vehicleData: null,
                  ChangeVehicleDetails: false,
                }}
                onSubmit={values => {
                  dispatch(
                    updateCustomerVehicle({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          return window.location.reload();
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: getCustomerMapData(
                        data,
                        values,
                        values.vehicleData
                      ),
                      id: data.Id,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  vehicleData: Yup.mixed().when("ChangeVehicleDetails", {
                    is: (bool: boolean) => bool,
                    then: Yup.mixed().required(
                      t("Araç özelliklerini doldurmak zorundasın!")
                    ),
                  }),
                })}
              >
                {({ touched, handleSubmit, setFieldValue, errors, values }) => (
                  <>
                    <GetInput
                      inputprop={{ field: "Plate", label: t("Plate"), col: 4 }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        field: "VehicleColorId",
                        label: t("Color"),
                        inputType: InputType.multiselect,
                        lookup: {
                          data: colors,
                          labelKey: "Name",
                          valueKey: "Id",
                        },
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        field: "RegistrationDate",
                        label: t("Registration Date"),
                        inputType: InputType.datetime,
                      }}
                    />
                    <GetInput
                      inputprop={{
                        field: "Warranty",
                        label: t("Warranty"),
                        inputType: InputType.checkbox,
                      }}
                    />
                    <GetInput
                      inputprop={{
                        hide(values) {
                          return !values.Warranty;
                        },
                        col: 4,
                        field: "WarrantyEndOdometer",
                        label: t("Warranty End Odometer"),
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        hide(values) {
                          return !values.Warranty;
                        },
                        field: "WarrantyStartDate",
                        label: t("Warranty Start Date"),
                        inputType: InputType.datetime,
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        hide(values) {
                          return !values.Warranty;
                        },
                        field: "WarrantyEndDate",
                        label: t("Warranty End Date"),
                        inputType: InputType.datetime,
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 6,
                        field: "ChassisNumber",
                        label: t("Chassis Number"),
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 6,
                        field: "EngineNumber",
                        label: t("Engine Number"),
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        field: "BodyLength",
                        label: t("Body Length"),
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        field: "BodyHeight",
                        label: t("Body Height"),
                      }}
                    />
                    <GetInput
                      inputprop={{
                        col: 4,
                        field: "Weight",
                        label: t("Weight"),
                      }}
                    />
                    <Col md={12} className="mt-3">
                      <Card
                        className={errors.vehicleData ? " border-danger" : " "}
                      >
                        <CardBody>
                          <span className="fw-bold ">
                            Araç Özelliklerini Değiştirmek İstiyorum{" "}
                          </span>
                          <Toggle
                            checked={values.ChangeVehicleDetails}
                            onChange={(d, checked) =>
                              setFieldValue("ChangeVehicleDetails", d)
                            }
                          />
                          {errors.vehicleData && (
                            <div className="text-danger mb-3 fw-bold">
                              {errors.vehicleData}
                            </div>
                          )}
                          <Row>
                            {values.ChangeVehicleDetails && (
                              <>
                                <GetInput
                                  inputprop={{
                                    col: 4,
                                    inputType: InputType.multiselect,
                                    field: "category",
                                    label: t("Kategori"),
                                    lookup: {
                                      placement: "topStart",
                                      data: categories.nextStep.map(x => {
                                        return {
                                          value: x.name ?? x.summary,
                                          label: x.name ?? x.summary,
                                          href: x.href,
                                          rel: x.rel,
                                          summary: x.summary,
                                          templated: x.templated,
                                          type: x.type,
                                          description: x.description,
                                          name: x.name,
                                        };
                                      }),
                                      cleanable: false,
                                    },
                                    onChange: (
                                      value,
                                      item: VehicleStepDto,
                                      setFieldValue
                                    ) => {
                                      setFieldValue("DataArray", []);
                                      dispatch(
                                        getCustomerVehicleSubCategory({
                                          payload: {
                                            onSuccess: (ms, payload) => {
                                              setFieldValue("DataArray", [
                                                payload,
                                              ]);
                                            },
                                            onError: () => {},
                                          },
                                          body: {
                                            url: item.href,
                                          },
                                        })
                                      );
                                    },
                                  }}
                                />
                                {values.DataArray.map((x, i) => (
                                  <VehicleInput list={x} i={i} />
                                ))}
                              </>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <div className="w-100 d-flex justify-content-between ">
                        <Button
                          color="danger"
                          style={{ width: 220 }}
                          type="submit"
                          onClick={() => history.goBack()}
                        >
                          {t("Geri")}
                        </Button>{" "}
                        <Button
                          color="success"
                          type="submit"
                          style={{ width: 220 }}
                          onClick={() => handleSubmit()}
                        >
                          {t("Save")}
                        </Button>
                      </div>
                    </Col>
                  </>
                )}
              </Formik>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EditCustomerVehiclePage;
