import { Radio, RadioGroup } from "rsuite";
import { RadioGrupProps } from "../type";

const CustomRadioGrup = ({
  label,
  data,
  error,
  labelKey,
  valueKey,
  field,
  value,
  touched,
  onChange,
  onBlur,
  disabled,
}: RadioGrupProps) => {
  return (
    <div className=" my-2">
      {label && (
        <label
          className={
            error && touched ? "errorLabel customlabel" : "customlabel"
          }
          htmlFor={field}
        >
          {label}
        </label>
      )}
      <RadioGroup value={value} onChange={value => onChange && onChange(value)}>
        {data.map((x: any) => (
          <Radio value={x[valueKey]}>
            <label className={"customlabel"}>{x[labelKey]}</label>
          </Radio>
        ))}
      </RadioGroup>
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomRadioGrup;
