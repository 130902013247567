import moment from "moment";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import CustomModal from "src/components/Modal/modal";
import { CustomerRatingDto } from "src/store/reports/type";

const CustomerRatingDetail = ({
  onHide,
  selected,
  open,
}: {
  selected: CustomerRatingDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      caption: t("Ad"),
      value: selected.CustomerName,
    },
    {
      caption: t("Soyad"),
      value: selected.CustomerSurname,
    },
    {
      caption: t("Telefon"),
      value: selected.CustomerPhoneNumber,
    },
    {
      caption: t("Hizmet"),
      value: selected.PrivilegeCoupon.PrivilegeName,
    },
    {
      caption: t("Plaka"),
      value: selected.PrivilegeCoupon.Plate,
    },
    {
      caption: t("Kod"),
      value: selected.PrivilegeCoupon.QRCode,
    },
    {
      caption: t("Kupon Kullanma Tarihi"),
      value: moment(selected.PrivilegeCoupon.ValidatedDate).format(
        "DD/MM/yyyy HH:mm:ss"
      ),
    },
    {
      caption: t("Değerlendirme Notu"),
      dataField: "Message",
      value: selected.Message,
    },
    {
      caption: t("Point"),
      value: selected.Point,
    },
    {
      caption: t("Tarih"),
      value: moment(selected.CreateTime).format("DD/MM/yyyy HH:mm:ss"),
    },
  ];
  return (
    <CustomModal
      onHide={onHide}
      outsideClick={true}
      size="lg"
      open={open}
      title={"Değerlendirme Detayı"}
    >
      <Table className="mb-0 table-borderless">
        {columns.map((x, i) => (
          <tr key={i}>
            <th style={{ minWidth: 100 }}>{x.caption}</th>
            <td>{x.value}</td>
          </tr>
        ))}
      </Table>
    </CustomModal>
  );
};

export default CustomerRatingDetail;
