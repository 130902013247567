import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import DataGrid, { Item } from "devextreme-react/data-grid";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { EnumTabs } from "../tabList";
import { Button } from "devextreme-react";
import ImportCustomerExcel from "./import";
import CreateCustomer from "./create";

const CorporateClientCustomersPage = () => {
  const { t } = useTranslation();
  const [showExcelImport, setShowExcelImport] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const dataGridRef = useRef<DataGrid>();
  const { id, tab }: any = useParams();
  const history = useHistory();
  const columns = [
    {
      caption: t("Üye Grubu"),
      dataField: "CustomerGroupName",
    },
    {
      caption: t("Üye Durumu"),
      dataField: "StatusName",
    },
    {
      caption: t("Name"),
      dataField: "Name",
    },

    {
      caption: t("Surname"),
      dataField: "Surname",
    },
    {
      caption: t("PhoneNumber"),
      dataField: "PhoneNumber",
    },
    {
      caption: t("Son Paket"),
      dataField: "LastPackage.PackagePackageName",
    },
    {
      caption: t("Yıllık"),
      dataField: "LastPackage.Yearly",
      dataType: "boolean",
    },
    {
      caption: t("Aktif Paket Başlangıç"),
      dataField: "LastPackage.StartDate",
      dataType: "date",
    },
    {
      caption: t("Aktif Paket Bitiş"),
      dataField: "LastPackage.EndDate",
      dataType: "date",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/1/" + e.row.key);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {tab === EnumTabs.Customers && (
        <>
          <CustomDataGrid
            ref={dataGridRef}
            title={t("Şirketler")}
            rowAlternationEnabled
            create={() => setShowCreate(true)}
            url={"/api/corporateclientcustomers/devexp/" + id}
            columns={columns}
            gridItems={
              <Item>
                <Button
                  text={"İçe Aktar"}
                  stylingMode={"contained"}
                  type={"default"}
                  onClick={() => setShowExcelImport(true)}
                />
              </Item>
            }
          />
          <ImportCustomerExcel
            open={showExcelImport}
            onHide={() => setShowExcelImport(false)}
          />
          <CreateCustomer
            open={showCreate}
            onHide={() => setShowCreate(false)}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default CorporateClientCustomersPage;
