import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CreateTire from "./create";
import { toast } from "react-toastify";
import EditTire from "./edit";
import CancelAlert from "src/components/CancelAlert";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory, useParams } from "react-router";
import { TireDto } from "src/store/tires/tires/type";
import {
  deleteTire,
  getTireDetails,
  getTireList,
} from "src/store/tires/tires/saga";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";
import DetailsTire from "./details";
const TirePage = () => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<TireDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<TireDto[]>([]);
  const [showDelete, setShowDelete] = useState<TireDto>();
  const [showDetail, setShowDetail] = useState(false);
  const history = useHistory();
  const columns = [
    {
      text: t("Lastik Adı"),
      dataField: "Name",
    },
    {
      text: t("Lastik Tipi"),
      dataField: "TireTypeName",
    },
    {
      text: t("Lastik Marka"),
      dataField: "TireBrandName",
    },
    {
      text: t("Taban x Yan x Çap"),
      dataField: "Id",
      formatter: (e: any, data: TireDto) =>
        `${data.TireTreadWidthValue} x ${data.TireSidewallWidthValue} x ${data.TireDiameterValue}`,
    },
    {
      text: t("Birim Fiyat"),
      dataField: "UnitPrice",
    },
    {
      text: t("Vergi Oranı"),
      dataField: "TaxRate",
    },
    {
      text: t("Stok"),
      dataField: "Stock",
    },
    {
      text: "Ücretsiz Nakliye",
      dataField: t("FreeShipping"),
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "Ücretsiz Montaj",
      dataField: t("FreeAssembly"),
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              text: "Tedarikçi Bilgileri",
            }}
            onClick={() => {
              history.push("/tire-supplier-infos/" + cellContent);
            }}
          />{" "}
          <ColumnButton
            buttonType="details2"
            onClick={() => {
              dispatch(
                getTireDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowDetail(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          {CheckRole(user) && (
            <>
              <ColumnButton
                buttonType="edit"
                onClick={() => {
                  dispatch(
                    getTireDetails({
                      payload: {
                        onSuccess: (message, payload) => {
                          setSelected(payload);
                          return setShowEdit(true);
                        },
                        onError: () => {},
                      },
                      id: cellContent,
                    })
                  );
                }}
              />
              <ColumnButton
                buttonType="delete"
                onClick={() => {
                  return setShowDelete(data);
                }}
              />
            </>
          )}{" "}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getTireList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditTire
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      {selected && (
        <DetailsTire
          data={selected}
          onHide={() => setShowDetail(false)}
          open={showDetail}
        />
      )}
      <CreateTire
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <MainPage
        title={t("Lastikler")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          },
          setDataList: setData,
          action: getTireList,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteTire({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TirePage;
