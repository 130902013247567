import { CustomerVehicleDto } from "src/store/customervehicles/type";
import { VehicleAllDataDto } from "src/store/customervehicles/vehiclealltype";

export const getCustomerMapData = (
  currentData: CustomerVehicleDto,
  values: any,
  vehicleData: VehicleAllDataDto | null
) => {
  if (values.ChangeVehicleDetails && vehicleData != null) {
    return {
      Id: currentData.Id,
      Plate: values.Plate,
      VehicleColorId: values.VehicleColorId,
      RegistrationDate: values.RegistrationDate ?? "",
      Warranty: values.Warranty,
      WarrantyEndOdometer: values.WarrantyEndOdometer ?? "",
      WarrantyStartDate: values.WarrantyStartDate ?? "",
      WarrantyEndDate: values.WarrantyEndDate ?? "",
      ChassisNumber: values.ChassisNumber ?? "",
      EngineNumber: values.EngineNumber ?? "",
      Weight: values.Weight ?? "",
      BodyLength: values.BodyLength ?? "",
      BodyHeight: values.BodyHeight ?? "",
      category: vehicleData.category.name
        ? vehicleData.category.name
        : vehicleData.category.summary
        ? vehicleData.category.summary
        : "",
      make: vehicleData.make
        ? vehicleData.make.name
          ? vehicleData.make.name
          : vehicleData.make.summary
          ? vehicleData.make.summary
          : ""
        : "",
      model: vehicleData.model
        ? vehicleData.model.name
          ? vehicleData.model.name
          : vehicleData.model.summary
          ? vehicleData.model.summary
          : ""
        : "",
      regdate: vehicleData.regDate ?? "",
      body: vehicleData.body
        ? vehicleData.body.name
          ? vehicleData.body.name
          : vehicleData.body.summary
          ? vehicleData.body.summary
          : ""
        : "",
      facelift: vehicleData.facelift
        ? vehicleData.facelift.name
          ? vehicleData.facelift.name
          : vehicleData.facelift.summary
          ? vehicleData.facelift.summary
          : ""
        : "",
      seats: vehicleData.seats
        ? vehicleData.seats.name
          ? vehicleData.seats.name
          : vehicleData.seats.summary
          ? vehicleData.seats.summary
          : ""
        : "",
      engine: vehicleData.engine
        ? vehicleData.engine.name
          ? vehicleData.engine.name
          : vehicleData.engine.summary
          ? vehicleData.engine.summary
          : ""
        : "",
      fueltype: vehicleData.FuelType ?? "",
      wheeldrive: vehicleData.wheelDrive
        ? vehicleData.wheelDrive.summary ?? ""
        : "",
      transmission: vehicleData.transmission
        ? vehicleData.transmission.name
          ? vehicleData.transmission.name
          : vehicleData.transmission.summary
          ? vehicleData.transmission.summary
          : ""
        : "",
      trim: vehicleData.trim
        ? vehicleData.trim.name
          ? vehicleData.trim.name
          : vehicleData.trim.summary
          ? vehicleData.trim.summary
          : ""
        : "",
      GearTypeName: vehicleData.GearType ?? "",
      BodyTypeName: vehicleData.BodyType ?? "",
      FuelTypeName: vehicleData.FuelType ?? "",
      trimid: vehicleData.trimid ?? "",
      seatid: vehicleData.seatid ?? "",
      variantid: vehicleData.variantid ?? "",
    };
  } else {
    return {
      Id: currentData.Id,
      Plate: values.Plate,
      VehicleColorId: values.VehicleColorId,
      RegistrationDate: values.RegistrationDate ?? "",
      Warranty: values.Warranty,
      WarrantyEndOdometer: values.WarrantyEndOdometer ?? "",
      WarrantyStartDate: values.WarrantyStartDate ?? "",
      WarrantyEndDate: values.WarrantyEndDate ?? "",
      ChassisNumber: values.ChassisNumber ?? "",
      EngineNumber: values.EngineNumber ?? "",
      category: currentData.category ?? "",
      make: currentData.make ?? "",
      model: currentData.model ?? "",
      regdate: currentData.regdate ?? "",
      body: currentData.body ?? "",
      facelift: currentData.facelift ?? "",
      seats: currentData.seats ?? "",
      engine: currentData.engine ?? "",
      fueltype: currentData.fueltype ?? "",
      wheeldrive: currentData.wheeldrive ?? "",
      transmission: currentData.transmission ?? "",
      trim: currentData.trim ?? "",
      GearTypeName: currentData.GearTypeName ?? "",
      BodyTypeName: currentData.BodyTypeName ?? "",
      FuelTypeName: currentData.FuelTypeName ?? "",
      variantid: currentData.variantid ?? "",
      seatid: currentData.seatid ?? "",
      trimid: currentData.trimid ?? "",
      Weight: values.Weight ?? "",
      BodyLength: values.BodyLength ?? "",
      BodyHeight: values.BodyHeight ?? "",
    };
  }
};
