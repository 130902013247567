import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PackageDiscountDefinitionDto } from "./type";

export const getPackageDiscountDefinitionListByPackageId = createAction(
  "GET_PACKAGE_DISCOUNT_DEFINITIONS_LIST_PACKAGE_ID",
  ({ payload, id }: SagaActionParams<PackageDiscountDefinitionDto[]>) => ({
    payload,
    url: "/api/packagediscountdefinitions/getbypackageid/" + id,
  })
);
export const getPackageDiscountDefinitionDetails = createAction(
  "GET_PACKAGE_DISCOUNT_DEFINITIONS_DETAILS",
  ({ payload, id }: SagaActionParams<PackageDiscountDefinitionDto>) => ({
    payload,
    url: "/api/packagediscountdefinitions/" + id,
  })
);

export const createPackageDiscountDefinition = createAction(
  "CREATE_PACKAGE_DISCOUNT_DEFINITIONS",
  ({ payload, body }: SagaActionParams<PackageDiscountDefinitionDto>) => ({
    payload,
    url: "/api/packagediscountdefinitions",
    body,
  })
);
export const deletePackageDiscountDefinition = createAction(
  "DELETE_PACKAGE_DISCOUNT_DEFINITIONS",
  ({ payload, body, id }: SagaActionParams<PackageDiscountDefinitionDto>) => ({
    payload,
    url: "/api/packagediscountdefinitions/" + id,
    body,
  })
);
export const updatePackageDiscountDefinition = createAction(
  "UPDATE_PACKAGE_DISCOUNT_DEFINITIONS",
  ({ payload, body, id }: SagaActionParams<PackageDiscountDefinitionDto>) => ({
    payload,
    url: "/api/packagediscountdefinitions/" + id,
    body,
  })
);

const packagediscountdefinitionsSaga = [
  takeLatest(
    getPackageDiscountDefinitionListByPackageId.toString(),
    (payload: SagaGenericParams<PackageDiscountDefinitionDto[]>) =>
      getListSaga<PackageDiscountDefinitionDto[]>(payload)
  ),
  takeLatest(
    getPackageDiscountDefinitionDetails.toString(),
    (payload: SagaGenericParams<PackageDiscountDefinitionDto[]>) =>
      getListSaga<PackageDiscountDefinitionDto[]>(payload)
  ),

  takeLatest(
    createPackageDiscountDefinition.toString(),
    (payload: PostSagaGenericParams<PackageDiscountDefinitionDto>) =>
      postSaga<PackageDiscountDefinitionDto>(payload)
  ),
  takeLatest(
    updatePackageDiscountDefinition.toString(),
    (payload: PostSagaGenericParams<PackageDiscountDefinitionDto>) =>
      putSaga<PackageDiscountDefinitionDto>(payload)
  ),
  takeLatest(
    deletePackageDiscountDefinition.toString(),
    (payload: SagaGenericParams<PackageDiscountDefinitionDto>) =>
      deleteSaga<PackageDiscountDefinitionDto>(payload)
  ),
];

export default packagediscountdefinitionsSaga;
