import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeCardDetailDto, PrivilegeCardDto } from "./type";
import { BodyType } from "../base/request/request";

export const getPrivilegeCardListByPrivilegeId = createAction(
  "GET_PRIVILEGECARDS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeCardDto[]>) => ({
    payload,
    url: "/api/privilegecards/getbyprivilegeid/" + id + (params ? params : ""),
  })
);

export const getUsingPrivilegeCardListByCustomerPrivilegeId = createAction(
  "GET_USING_PRIVILEGECARDS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeCardDto[]>) => ({
    payload,
    url:
      "/api/privilegecards/GetUsingCardByCustomerPrivilegeId/" +
      id +
      (params ? params : ""),
  })
);

export const getPrivilegeCardList = createAction(
  "GET_PRIVILEGECARDS_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeCardDto[]>) => ({
    payload,
    url: "/api/privilegecards" + (params ? params : ""),
  })
);

export const getPrivilegeCardDetails = createAction(
  "GET_PRIVILEGECARDS_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeCardDetailDto>) => ({
    payload,
    url: "/api/privilegecards/" + id,
  })
);
export const createPrivilegeCard = createAction(
  "CREATE_PRIVILEGECARDS_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards",
    body,
  })
);
export const updatePrivilegeCard = createAction(
  "UPDATE_PRIVILEGECARDS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/" + id,
    body,
  })
);
export const deletePrivilegeCard = createAction(
  "DELETE_PRIVILEGECARDS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/" + id,
    body,
  })
);
export const assignPrivilegeCardtoCustomer = createAction(
  "ASSING_PRIVILEGE_CARD_TO_CUSTOMER",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/assign-to-customer",
    body,
    bodyType: BodyType.raw,
  })
);
export const assignPrivilegeCardtoAllCustomer = createAction(
  "ASSING_PRIVILEGE_CARD_TO_ALL_CUSTOMER",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/assign-all-customer",
    body,
    bodyType: BodyType.raw,
  })
);
export const printPrivilegeCard = createAction(
  "PRINT_PRIVILEGE_CARD",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/print-card",
    body,
    bodyType: BodyType.raw,
  })
);
export const sendPrivilegeCard = createAction(
  "SEND_PRIVILEGE_CARD",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/send-card",
    body,
    bodyType: BodyType.raw,
  })
);
export const setActivePrivilegeCard = createAction(
  "SET_ACTIVE_PRIVILEGE_CARD",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/set-active-card",
    body,
    bodyType: BodyType.raw,
  })
);
export const setCancelledPrivilegeCard = createAction(
  "SET_CANCELLED_PRIVILEGE_CARD",
  ({ payload, body }: SagaActionParams<PrivilegeCardDto>) => ({
    payload,
    url: "/api/privilegecards/set-cancelled",
    body,
    bodyType: BodyType.raw,
  })
);
export const importExcelToPrivilegeCard = createAction(
  "IMPORT_EXCEL_TO_PRIVILEGE_CARD",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/privilegecards/import-excel",
    body,
  })
);

export const updatePrivilegeCardStatus = createAction(
  "UPDATE_PRIVILEGE_CARD_STATUS",
  ({ payload, url, body }: SagaActionParams<any>) => ({
    payload,
    url,
    body,
    bodyType: BodyType.raw,
  })
);
const privilegecardsSaga = [
  takeLatest(
    getPrivilegeCardListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeCardDto[]>) =>
      getListSaga<PrivilegeCardDto[]>(payload)
  ),
  takeLatest(
    getUsingPrivilegeCardListByCustomerPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeCardDto[]>) =>
      getListSaga<PrivilegeCardDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeCardList.toString(),
    (payload: SagaGenericParams<PrivilegeCardDto[]>) =>
      getListSaga<PrivilegeCardDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeCardDetails.toString(),
    (payload: SagaGenericParams<PrivilegeCardDto>) =>
      getListSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    createPrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      postSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    updatePrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    assignPrivilegeCardtoCustomer.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    deletePrivilegeCard.toString(),
    (payload: SagaGenericParams<PrivilegeCardDto>) =>
      deleteSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    printPrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    setActivePrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    sendPrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    setCancelledPrivilegeCard.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    updatePrivilegeCardStatus.toString(),
    (payload: PostSagaGenericParams<PrivilegeCardDto>) =>
      putSaga<PrivilegeCardDto>(payload)
  ),
  takeLatest(
    importExcelToPrivilegeCard.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    assignPrivilegeCardtoAllCustomer.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
];

export default privilegecardsSaga;
