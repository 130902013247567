import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";

const IsparkReportPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = [
    {
      caption: t("Plate"),
      dataField: "VehiclePlate",
    },
    {
      caption: t("Trans Id"),
      dataField: "TransId",
    },
    {
      caption: t("Kupon"),
      dataField: "QRCode",
    },
    {
      caption: t("Doğrulanma"),
      dataField: "Validated",
      dataType: "boolean",
    },
    {
      caption: t("Doğrulanma Tarihi"),
      dataField: "ValidatedDate",
      dataType: "datetime",
    },
    {
      caption: t("Oluşturulma Tarihi"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/customer/details/3/" + e.row.data.CustomerId);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/reports/ispark-coupons",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Ispark Raporu")}
      />
    </React.Fragment>
  );
};

export default IsparkReportPage;
