import React, { forwardRef } from "react";

import {
  Column,
  DataGrid,
  IDataGridOptions,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { Button } from "devextreme-react";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { baseUrl } from "src/store/base/types/url";
import { useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import { exportDataGrid } from "devextreme/excel_exporter";
import "./style.scss";
import "devextreme/data/odata/store";

export interface DataGridProps extends IDataGridOptions {
  url: any;
  createText?: string;
  columns: any[];
  loadParams?: any;
  create?: () => void;
  gridButtons?: GridButtonType[];
  gridItems?: any;
  title?: string;
  children?: React.ReactNode;
  rowSelection?: boolean;
  noExpand?: boolean;
  height?: number;
  onOverrideClear?: () => void;
  hideFilter?: boolean;
}

export type GridButtonType = {
  buttonText: string;
  icon: string;
  type: "danger" | "default" | "normal" | "success" | undefined;
  onclick: () => void;
};

const CustomDataGrid = forwardRef<any, DataGridProps>(
  (
    {
      url,
      loadParams,
      columns,
      gridButtons,
      create,
      createText,
      height,
      title,
      gridItems,
      children,
      rowSelection = false,
      noExpand = false,
      rowAlternationEnabled = true,
      onOverrideClear,
      hideFilter = false,
      ...rest
    },
    ref
  ) => {
    const { lang, token } = useSelector(getApiParams);
    const dataSource = createStore({
      key: "Id",
      loadUrl: `${baseUrl}${url}`,
      loadParams: loadParams,
      onBeforeSend: (method, ajaxOptions) => {
        ajaxOptions.headers = {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token || ""}`,
          "Accept-Language": lang,
        };
      },
    });
    function onExporting(e: any) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = { name: "Arial", size: 12 };
          options.excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            title ? title + ".xlsx" : "DataGrid.xlsx"
          );
        });
      });
    }

    return (
      <>
        <DataGrid
          //@ts-ignore
          ref={ref}
          columnHidingEnabled={false}
          dataSource={dataSource}
          onExporting={onExporting}
          rowAlternationEnabled={rowAlternationEnabled}
          showRowLines={false}
          showColumnLines={false}
          remoteOperations={true}
          paging={{ enabled: true, pageSize: 15 }}
          pager={{ visible: true, showInfo: true }}
          headerFilter={{ visible: !hideFilter }}
          filterRow={{ visible: !hideFilter }}
          grouping={{
            autoExpandAll: false,
          }}
          export={{ enabled: true }}
          groupPanel={{ visible: true }}
          searchPanel={{ visible: !hideFilter }}
          columnChooser={{ enabled: true }}
          // showBorders={true}
          allowColumnResizing
          {...rest}
        >
          <Toolbar>
            <Item name="groupPanel" />
            <Item name="searchPanel" />
            <Item>
              <Button
                className="datagrid-action-button"
                hint={"Filtre Temizle"}
                icon="clear"
                type="danger"
                stylingMode="contained"
                onClick={() => {
                  onOverrideClear && onOverrideClear();
                  //@ts-ignore
                  return ref?.current?._instance?.state(null);
                }}
              />
            </Item>
            {gridButtons?.map(button => (
              <Item>
                <Button
                  className="datagrid-action-button"
                  text={button.buttonText}
                  icon={button.icon}
                  type={button.type}
                  stylingMode="contained"
                  onClick={button.onclick}
                />
              </Item>
            ))}
            {gridItems ?? gridItems}
            <Item>
              <Button
                className="datagrid-action-button"
                hint={"Yenile"}
                icon="refresh"
                type="normal"
                stylingMode="outlined"
                //@ts-ignore
                onClick={() => ref?.current?._instance?.refresh()}
              />
            </Item>
            <Item name="columnChooserButton" locateInMenu="auto" />
            <Item name="exportButton" />
            {create && (
              <Item>
                <Button
                  className="datagrid-action-button"
                  hint={"Ekle"}
                  icon="add"
                  type="default"
                  text={createText}
                  stylingMode="contained"
                  onClick={() => create()}
                />
              </Item>
            )}
          </Toolbar>
          {columns.map((column, i) => {
            return <Column key={i} {...column} />;
          })}

          {children && children}
        </DataGrid>
      </>
    );
  }
);
export default CustomDataGrid;
