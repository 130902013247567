import React, { useEffect, useState } from "react";
import { DashboardHistoryReport } from "src/store/dashboard/type";
import { useDispatch } from "react-redux";
import { getDashboardHistoryReportSaga } from "src/store/dashboard/saga";
import { Card, CardBody, Table } from "reactstrap";
import moment from "moment";
import { DateRangeBox } from "devextreme-react";

const now = new Date();
const msInDay = 1000 * 60 * 60 * 24;
const initialValue = [new Date(now.getTime() - msInDay * 7), now];
const DashboardHistoryReportComponent = () => {
  const [data, setData] = useState<DashboardHistoryReport[]>([]);
  const [selectedDays, setSelectedDays] = React.useState(initialValue);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardHistoryReportSaga({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
          },
          onError: () => {},
        },
        params: `?startDate=${moment(selectedDays[0]).format(
          "yyyy-MM-DD"
        )}&endDate=${moment(selectedDays[1]).format("yyyy-MM-DD")}`,
      })
    );
  }, [dispatch, selectedDays]);

  return (
    <React.Fragment>
      <Card className="">
        <CardBody>
          <div className=" pb-2 d-flex justify-content-between align-items-center">
            <div className="card-title">Hareketler</div>
            <DateRangeBox
              startDateLabel="Başlangıç"
              endDateLabel="Bitiş"
              displayFormat="dd/MM/yyyy"
              defaultValue={selectedDays}
              onValueChanged={e => {
                setSelectedDays(e.value);
              }}
            />
          </div>
          <Table className="table mb-0 table-bordered ">
            <thead className="custom-table-header-colored table-light">
              <tr>
                <th></th>
                {Array.from(new Set(data.map(item => item.Date))).map(
                  (x: any) => (
                    <th key={x.Id}> {moment(x).format("DD/MM/yyyy")}</th>
                  )
                )}
                {/* {data.map(x => (
              <th> {moment(x.Date).format("DD/MM/yyyy")}</th>
            ))} */}
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <div className=" py-5 text-center text-muted">
                  VERİ BULUNAMADI
                </div>
              )}
              {Array.from(new Set(data.map(item => item.Topic))).map(
                (x: any) => (
                  <tr>
                    <th scope="row">{x}</th>
                    {Array.from(new Set(data.map(item => item.Date))).map(
                      (z: any) => {
                        const value = data.find(
                          y => y.Topic === x && y.Date === z
                        );
                        return <td key={x.Id}> {value ? value.Count : "-"}</td>;
                      }
                    )}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default DashboardHistoryReportComponent;
