import React, { useState } from "react";
import { Modal } from "rsuite";
import CustomModal from "src/components/Modal/modal";
import { getCurrency } from "src/helpers/getCurrency";
import { TireSupplierInfo } from "src/store/tires/tireSales/type";

const TireSupplierInfoModal = ({ data }: { data: TireSupplierInfo }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-soft-light btn-sm text-success"
        onClick={() => setOpen(true)}
      >
        <i className="mdi mdi-eye "></i>
      </button>
      <CustomModal
        open={open}
        onHide={() => setOpen(false)}
        title={
          <Modal.Title className="text-center mb-2">
            <span className="fw-bold text-center " style={{ fontSize: 18 }}>
              Tedarikçi Bilgileri
            </span>
          </Modal.Title>
        }
      >
        <p className="font-size-15 ">
          {
            <div className="text-center">
              {data.TireSupplier.Name}
              <br /> {data.TireSupplier.AuthorizedPerson} <br />
              {data.TireSupplier.Address} <br /> {data.TireSupplier.PhoneNumber}{" "}
              <br />
              Birim Fiyat = {getCurrency(data.UnitPrice)} <br />
              Vergi Oranı = % {data.TaxRate} <br />
              <span className="fw-bold">
                Toplam Fiyat = {getCurrency(data.TotalPrice)}
              </span>
              <br />
            </div>
          }
        </p>
      </CustomModal>
    </React.Fragment>
  );
};

export default TireSupplierInfoModal;
