import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FilterBuilder } from "devextreme-react";
import { FilterBuilderTypes } from "devextreme-react/filter-builder";
import appSystemRedux, { gridFilterSelector } from "src/store/app-system";

const CustomFilterBuilder = ({ columns }: { columns: any[] }) => {
  const gridFilterValue = useSelector(gridFilterSelector);
  const [value, setValue] = useState(gridFilterValue);
  const dispatch = useDispatch();

  const buttonClick = useCallback(() => {
    dispatch(appSystemRedux.actions.setGridFilter(value));
  }, [value, dispatch]);

  const clearButtonClick = useCallback(() => {
    dispatch(appSystemRedux.actions.clearGridFilter());
    setValue("");
  }, [dispatch]);

  const onValueChanged = useCallback(
    (e: FilterBuilderTypes.ValueChangedEvent) => {
      setValue(e.value);
    },
    [setValue]
  );
  return (
    <React.Fragment>
      <div className="filter-container">
        <FilterBuilder
          //@ts-ignore
          fields={columns}
          value={value}
          onValueChanged={onValueChanged}
        />
        <div className="d-flex justify-content-end gap-3">
          <Button
            text="Filtreyi Temizle"
            type="danger"
            stylingMode="outlined"
            onClick={clearButtonClick}
          />
          <Button
            text="Filtreyi Uygula"
            type="default"
            stylingMode="outlined"
            onClick={buttonClick}
          />
        </div>
        <div className="dx-clearfix"></div>
      </div>
    </React.Fragment>
  );
};

export default CustomFilterBuilder;
