import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { getCustomerFavoriteListByCustomerId } from "src/store/customerfavorites/saga";
import { CustomerFavoriteDto } from "src/store/customerfavorites/type";
import FavoriteDetailModal from "./detail";
import DetailTable from "src/components/PageContent/detailTable";

const CustomerFavoritesTab = () => {
  const { t } = useTranslation();
  const { id }: { id: string; tab: string } = useParams();
  const [data, setData] = useState<CustomerFavoriteDto[]>([]);
  const [openDetail, setOpenDetail] = useState<CustomerFavoriteDto>();
  const columns = [
    {
      text: t("Hizmet"),
      dataField: "Title",
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="details2"
            onClick={() => {
              setOpenDetail(data);
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {openDetail && (
          <FavoriteDetailModal
            data={openDetail}
            onHide={() => setOpenDetail(undefined)}
            open={openDetail ? true : false}
          />
        )}

        <DetailTable
          title=" "
          HideBreadcrumb={true}
          listData={data}
          columns={columns}
          setDataList={setData}
          action={getCustomerFavoriteListByCustomerId}
          paramsid={id}
        />
      </div>
    </React.Fragment>
  );
};
export default CustomerFavoritesTab;
