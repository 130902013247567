import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  getCorporateTypes,
  updateCorporateClients,
} from "src/store/corporate-clients/saga";
import {
  CorporateClientsDto,
  CorporateTypeDto,
} from "src/store/corporate-clients/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import * as Yup from "yup";

const EditCorporateClient = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: CorporateClientsDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [types, setTypes] = useState<CorporateTypeDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);

  useEffect(() => {
    dispatch(
      getCorporateTypes({
        payload: {
          onSuccess: (m, p) => setTypes(p),
          onError: () => {},
        },
      })
    );
    dispatch(
      getTowns({
        payload: {
          onSuccess: (msg, payload) => setTowns(payload),
          onError: () => {},
        },
        id: data.CityId,
      })
    );
    dispatch(
      getCities({
        payload: {
          onSuccess: (msg, payload) => setCities(payload),
          onError: () => {},
        },
      })
    ); // eslint-disable-next-line
  }, [dispatch]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Kurumsal Müşteri Düzenle",
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          CorporateTypeId: data.CorporateTypeId,
          CompanyName: data.CompanyName ?? "",
          Address: data.Address ?? "",
          CityId: data.CityId ?? "",
          TownId: data.TownId ?? "",
          TaxOffice: data.TaxOffice ?? "",
          TaxId: data.TaxId ?? "",
          AuthorizedPerson: data.AuthorizedPerson ?? "",
          Email: data.Email ?? "",
          Phone: data.Phone ?? "",
        }}
        validationSchema={Yup.object().shape({
          CorporateTypeId: Yup.number().required(t("Required")),
          CompanyName: Yup.string().required(t("Required")),
        })}
        onSubmit={values => {
          dispatch(
            updateCorporateClients({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        inputs={[
          {
            field: "CorporateTypeId",
            inputType: InputType.multiselect,
            label: t("Kurumsal Müşteri Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Kurumsal Müşteri Tipi Seçiniz"),
            },
          },
          {
            field: "CompanyName",
            label: "Firma Adı",
          },
          {
            field: "TaxOffice",
            label: "Vergi Dairesi",
          },
          {
            field: "TaxId",
            label: "Vergi No",
          },
          {
            field: "AuthorizedPerson",
            label: "Yetkili",
          },
          {
            field: "Email",
            label: "E-posta",
          },
          {
            field: "Phone",
            label: "Telefon",
          },
          {
            field: "Address",
            label: "Adres",
          },
          {
            col: 6,
            field: "CityId",
            inputType: InputType.multiselect,
            label: t("City"),
            lookup: {
              data: cities,
              labelKey: "CityName",
              placement: "topStart",
              valueKey: "Id",
              placeholder: t("Choose City"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            col: 6,
            field: "TownId",
            inputType: InputType.multiselect,
            label: t("Town"),
            lookup: {
              placement: "topStart",
              data: towns,
              labelKey: "TownName",
              valueKey: "Id",
              placeholder: t("Choose Town"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditCorporateClient;
