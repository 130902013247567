import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import ColumnButton from "src/components/Form/Button/ColumnButton";

import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";
import DetailTable from "src/components/PageContent/detailTable";
import { ProductDetailDto } from "src/store/orders/products/type";
import { ProductCompatibleBrandDto } from "src/store/orders/product-compatible-brands/type";
import {
  deleteProductCompatibleBrand,
  getProductCompatibleBrandListByProductId,
} from "src/store/orders/product-compatible-brands/saga";
import CreateCompatibleBrand from "./create";

const ProductCompatibleBrandList = ({
  product,
}: {
  product: ProductDetailDto;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const { id }: { tab: string; id: string } = useParams();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<ProductCompatibleBrandDto>();
  const [data, setData] = useState<ProductCompatibleBrandDto[]>([]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getProductCompatibleBrandListByProductId({
        payload: {
          onSuccess: (msg, payload) => setData(payload),
          onError: () => {},
        },
        id: product.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Uyumlu Marka"),
      dataField: "make",
    },
    {
      text: t("Uyumlu Model"),
      dataField: "model",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        <CreateCompatibleBrand
          product={product}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccess={() => onSuccessClick("Başarılı")}
        />
        <DetailTable
          title={"Uyumlu Markalar"}
          create={() => setShowCreate(true)}
          listData={data}
          columns={columns}
          setDataList={setData}
          action={getProductCompatibleBrandListByProductId}
          paramsid={id}
          createText={"Uyumlu Marka Ekle"}
        />
      </div>
      {showDelete && (
        <CancelAlert
          message={"Silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteProductCompatibleBrand({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default ProductCompatibleBrandList;
