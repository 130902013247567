import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import * as Yup from "yup";
import {
  getCampaignListByCategoryId,
  updateCampaignPriority,
} from "src/store/campaigns/saga";
import MainPage from "src/components/PageContent/MainPage";
import FormModal from "src/components/Form/FormModal";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { InputType } from "src/components/Form/type";
import { getPrivilegeListByCategoryId } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";

const PrivilegeOrderPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<PrivilegeDto[]>([]);
  const [selected, setSelected] = useState<PrivilegeDto>();
  const [showPriority, setShowPriority] = useState(false);

  const columns = [
    {
      text: t("Öncelik"),
      dataField: "Priority",
    },
    {
      text: t("Category Name"),
      dataField: "PrivilegeCategoryName",
    },
    {
      text: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              setSelected(data);
              return setShowPriority(true);
            }}
          />
        </div>
      ),
    },
  ];

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPrivilegeListByCategoryId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      <MainPage
        title={t("Privileges")}
        gridProps={{
          listData: data,
          columns: columns,
          setDataList: setData,
          action: getCampaignListByCategoryId,
          paramsid: id,
        }}
      />

      {selected && (
        <>
          <FormModal
            modalProps={{
              open: showPriority,
              onHide: () => setShowPriority(false),
              title: t("Düzenle"),
            }}
            onHide={() => setShowPriority(false)}
            loader
            initialValues={{
              Id: selected.Id,
              Priority: selected.Priority,
            }}
            onSubmit={values => {
              dispatch(
                updateCampaignPriority({
                  payload: {
                    onSuccess: (message, payload) => {
                      setShowPriority(false);
                      onSuccessClick(message);
                    },
                    onError: message => {
                      toast.error(message, {
                        theme: "colored",
                      });
                    },
                  },
                  body: values,
                  id: selected.Id,
                })
              );
            }}
            validationSchema={Yup.object().shape({
              Priority: Yup.number().required(t("Required")),
            })}
            inputs={[
              {
                label: t("Öncelik"),
                field: "Priority",
                inputType: InputType.number,
              },
            ]}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default PrivilegeOrderPage;
