import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CustomerPrivilegeDto,
  CustomerUsedCouponTransactionDto,
  CustomerUsedDto,
} from "./type";
export const getCustomerUsedPrivilegeListByCustomerId = createAction(
  "GET_CUSTOMER_USED_PRIVILEGE_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerPrivilegeDto[]>) => ({
    payload,
    url:
      "/api/customerprivileges/getbycustomerid/" + id + (params ? params : ""),
  })
);

export const getCustomerValidatedCodeList = createAction(
  "GET_CUSTOMER_VALIDATED_CODE_LIST",
  ({ payload, params, id }: SagaActionParams<CustomerUsedDto[]>) => ({
    payload,
    url:
      "/api/customerprivileges/getvalidatedcodelists/" +
      id +
      (params ? params : ""),
  })
);

export const getCustomerValidatedCodeTransactionList = createAction(
  "GET_CUSTOMER_VALIDATED_CODE_TRANSACTION_LIST",
  ({
    payload,
    params,
    id,
  }: SagaActionParams<CustomerUsedCouponTransactionDto[]>) => ({
    payload,
    url:
      "/api/customerprivileges/getcoupontransactions/" +
      id +
      (params ? params : ""),
  })
);
export const getCustomerPrivilegeListByCustomerId = createAction(
  "GET_CUSTOMER_PRIVILEGE_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerPrivilegeDto[]>) => ({
    payload,
    url:
      "/api/customerprivileges/getbycustomerid/" + id + (params ? params : ""),
  })
);
export const getCustomerPrivilegeDetails = createAction(
  "GET_CUSTOMER_PRIVILEGE_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges/" + id,
  })
);
export const getCustomerGiftPrivilegeListByCustomerId = createAction(
  "GET_CUSTOMER_GIFT_PRIVILEGE_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerPrivilegeDto[]>) => ({
    payload,
    url:
      "/api/customerprivileges/giftprivileges/" + id + (params ? params : ""),
  })
);
export const createCustomerPrivilege = createAction(
  "CREATE_CUSTOMER_PRIVILEGE",
  ({ payload, body }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges",
    body,
  })
);

export const createCustomerPrivilegeMultiple = createAction(
  "CREATE_CUSTOMER_PRIVILEGE_MULTIPLE",
  ({ payload, body }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges/multiple",
    body,
  })
);
export const updateCustomerPrivilege = createAction(
  "UPDATE_CUSTOMER_PRIVILEGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges/" + id,
    body,
  })
);
export const deleteCustomerPrivilege = createAction(
  "DELETE_CUSTOMER_PRIVILEGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges/" + id,
    body,
  })
);
export const setUsedCustomerPrivilege = createAction(
  "SET_USED_CUSTOMER_PRIVILEGE_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerPrivilegeDto>) => ({
    payload,
    url: "/api/customerprivileges/use-privilege",
    body,
  })
);

const customerprivilegesaga = [
  takeLatest(
    getCustomerValidatedCodeTransactionList.toString(),
    (payload: SagaGenericParams<CustomerUsedCouponTransactionDto[]>) =>
      getListSaga<CustomerUsedCouponTransactionDto[]>(payload)
  ),
  takeLatest(
    getCustomerValidatedCodeList.toString(),
    (payload: SagaGenericParams<CustomerUsedDto[]>) =>
      getListSaga<CustomerUsedDto[]>(payload)
  ),
  takeLatest(
    getCustomerUsedPrivilegeListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerPrivilegeDto[]>) =>
      getListSaga<CustomerPrivilegeDto[]>(payload)
  ),
  takeLatest(
    getCustomerPrivilegeListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerPrivilegeDto[]>) =>
      getListSaga<CustomerPrivilegeDto[]>(payload)
  ),
  takeLatest(
    getCustomerPrivilegeDetails.toString(),
    (payload: SagaGenericParams<CustomerPrivilegeDto[]>) =>
      getListSaga<CustomerPrivilegeDto[]>(payload)
  ),
  takeLatest(
    getCustomerGiftPrivilegeListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerPrivilegeDto[]>) =>
      getListSaga<CustomerPrivilegeDto[]>(payload)
  ),
  takeLatest(
    createCustomerPrivilege.toString(),
    (payload: PostSagaGenericParams<CustomerPrivilegeDto>) =>
      postSaga<CustomerPrivilegeDto>(payload)
  ),
  takeLatest(
    createCustomerPrivilegeMultiple.toString(),
    (payload: PostSagaGenericParams<CustomerPrivilegeDto>) =>
      postSaga<CustomerPrivilegeDto>(payload)
  ),
  takeLatest(
    setUsedCustomerPrivilege.toString(),
    (payload: PostSagaGenericParams<CustomerPrivilegeDto>) =>
      putSaga<CustomerPrivilegeDto>(payload)
  ),
  takeLatest(
    updateCustomerPrivilege.toString(),
    (payload: PostSagaGenericParams<CustomerPrivilegeDto>) =>
      putSaga<CustomerPrivilegeDto>(payload)
  ),
  takeLatest(
    deleteCustomerPrivilege.toString(),
    (payload: SagaGenericParams<CustomerPrivilegeDto>) =>
      deleteSaga<CustomerPrivilegeDto>(payload)
  ),
];

export default customerprivilegesaga;
