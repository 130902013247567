import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/drawer";
import { updateEmailTemplates } from "src/store/emailTemplates/saga";
import { EmailTemplateDto } from "src/store/emailTemplates/type";
import * as Yup from "yup";

const EditEmailTemplatePage = ({
  onHide,
  open,
  onSuccessClick,
  data,
}: {
  onHide: () => void;
  open: boolean;
  data: EmailTemplateDto;
  onSuccessClick: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <CustomModal
        onHide={onHide}
        open={open}
        title={"Tasarım Şablonu Düzenle"}
      >
        <Formik
          onSubmit={values => {
            dispatch(
              updateEmailTemplates({
                payload: {
                  onSuccess: (message, payload) => {
                    onHide();
                    toast.success(message);
                    onSuccessClick();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: values,
                id: data.Id,
              })
            );
          }}
          initialValues={{
            Id: data.Id,
            Name: data.Name ?? "",
            Subject: data.Subject ?? "",
            Template: data.Template ?? "",
            EmailTemplateTypeId: data.EmailTemplateTypeId,
            Active: data.Active,
            UseLayout: data.UseLayout,
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({
            touched,
            errors,
            values,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Row>
              <GetInput
                inputprop={{ field: "Name", label: "Tasarım Şablonu Adı" }}
              />
              <GetInput inputprop={{ field: "Subject", label: "Konu" }} />
              <GetInput
                inputprop={{
                  field: "Template",
                  label: "Tasarım Şablonu ",
                  inputType: InputType.multilinetext,
                }}
              />
              <GetInput
                inputprop={{
                  field: "UseLayout",
                  label: "Ana Tasarım Şablonunu Kullan",
                  inputType: InputType.checkbox,
                }}
              />
              <GetInput
                inputprop={{
                  field: "Active",
                  label: "Aktif",
                  inputType: InputType.checkbox,
                }}
              />
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  {t("Save")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};
export default EditEmailTemplatePage;
