import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { Checkbox, CheckboxGroup } from "rsuite";
import Loader from "src/components/Loader";
import CustomModal from "src/components/Modal/modal";
import { cancelCustomerMembership } from "src/store/customers/saga";
import { CustomerDetailDto } from "src/store/customers/type";
import { getMembershipcanceltypeList } from "src/store/membership-cancel-types/saga";
import { MembershipcanceltypeDto } from "src/store/membership-cancel-types/type";
import * as Yup from "yup";
import "./style.scss";
import { MembershipcancelRequestDto } from "src/store/membership-cancel-request/type";

const CancelMemberShip = ({
  customerData,
  onSuccessSubmit,
  cancelRequest,
}: {
  cancelRequest: MembershipcancelRequestDto[];
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const [loader, setLoader] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reasons, setReasons] = useState<MembershipcanceltypeDto[]>([]);
  useEffect(() => {
    if (showCancel) {
      setLoader(true);
      dispatch(
        getMembershipcanceltypeList({
          payload: {
            onSuccess: (message, payload) => {
              setReasons(payload);
              setLoader(false);
            },
            onError: () => {},
          },
        })
      );
    }
  }, [showCancel, dispatch]);
  return (
    <React.Fragment>
      {customerData.StatusId === 1 && (
        <button
          className="btn btn-danger btn-sm  mt-2"
          onClick={() => setShowCancel(true)}
        >
          Üyeliği Sonlandır
        </button>
      )}
      <CustomModal
        open={showCancel}
        onHide={() => setShowCancel(false)}
        title={"Üyelik Sonlandırma"}
      >
        {loader && <Loader />}
        {!loader && (
          <Formik
            initialValues={{
              CustomerId: customerData.Id,
              CreateMembershipCancellationRequestReasons: reasons.map(x => {
                var req = cancelRequest.find(a =>
                  a.MembershipCancellationRequestReasons.some(
                    b => b.MembershipCancelTypeId === x.Id
                  )
                );
                let message = "";
                if (req) {
                  var current = req.MembershipCancellationRequestReasons.find(
                    b => b.MembershipCancelTypeId === x.Id
                  );
                  message = current ? current.Message : "";
                }
                return {
                  Id: x.Id,
                  Text: x.Description,
                  InText: x.InText,
                  Check: req ? true : false,
                  Message: message,
                };
              }),
            }}
            validationSchema={Yup.object().shape({
              CustomerId: Yup.string().required(t("Required")),
            })}
            onSubmit={values => {
              dispatch(
                cancelCustomerMembership({
                  payload: {
                    onSuccess: message => {
                      setShowCancel(false);
                      toast.success(message);
                      onSuccessSubmit();
                    },
                    onError: message => {
                      setShowCancel(false);
                      toast.error(message, {
                        theme: "colored",
                      });
                    },
                  },
                  body: {
                    ...values,
                    CreateMembershipCancellationRequestReasons:
                      values.CreateMembershipCancellationRequestReasons.filter(
                        x => x.Check
                      ).map(x => {
                        return {
                          MembershipCancelTypeId: x.Id,
                          Message: x.Message,
                        };
                      }),
                  },
                })
              );
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Row className="mx-3 p-3">
                <div className="mb-2 fw-bold font-size-14">
                  Üyelik sonlandırma işlemine devam etmek istediğinize emin
                  misiniz?
                </div>
                <Col md={12}>
                  <CheckboxGroup
                    name="checkboxList"
                    value={values.CreateMembershipCancellationRequestReasons.filter(
                      x => x.Check
                    ).map(x => x.Id)}
                  >
                    {values.CreateMembershipCancellationRequestReasons.map(
                      (x, i) => (
                        <Checkbox
                          value={x.Id}
                          onChange={e => {
                            setFieldValue(
                              `CreateMembershipCancellationRequestReasons[${i}].Check`,
                              !values
                                .CreateMembershipCancellationRequestReasons[i]
                                .Check
                            );
                          }}
                        >
                          {x.Text}{" "}
                          {x.InText && (
                            <input
                              className="line-input"
                              value={
                                values
                                  .CreateMembershipCancellationRequestReasons[i]
                                  .Message
                              }
                              onChange={e => {
                                setFieldValue(
                                  `CreateMembershipCancellationRequestReasons[${i}].Message`,
                                  e.target.value ? e.target.value : ""
                                );
                              }}
                            />
                          )}
                        </Checkbox>
                      )
                    )}
                  </CheckboxGroup>
                </Col>
                <div className={"d-flex justify-content-between mt-3"}>
                  <Button
                    color="danger"
                    type="submit"
                    style={{ width: 200 }}
                    onClick={() => setShowCancel(false)}
                  >
                    {t("Kapat")}
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    style={{ width: 200 }}
                    onClick={() => handleSubmit()}
                  >
                    {t("Sonlandır")}
                  </Button>
                </div>
              </Row>
            )}
          </Formik>
        )}
      </CustomModal>
    </React.Fragment>
  );
};
export default CancelMemberShip;
