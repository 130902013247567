import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PackageExtraPrivilegeDto } from "./type";
import { BodyType } from "../base/request/request";
import { createAction } from "redux-smart-actions";
import { takeLatest } from "redux-saga/effects";

export const changePackageExtraOrder = createAction(
  "UPDATE_PACKAGE_EXTRA_ORDER",
  ({ payload, body }: SagaActionParams<PackageExtraPrivilegeDto>) => ({
    payload,
    url: "/api/packageextraprivileges/order",

    bodyType: BodyType.raw,
    body,
  })
);
export const getPackageExtraList = createAction(
  "GET_PACKAGE_EXTRA_LIST",
  ({ payload, id }: SagaActionParams<PackageExtraPrivilegeDto[]>) => ({
    payload,
    url: "/api/packageextraprivileges/getbypackageid/" + id,
  })
);
export const getPackageExtraDetails = createAction(
  "GET_PACKAGE_EXTRA_DETAILS",
  ({ payload, id }: SagaActionParams<PackageExtraPrivilegeDto>) => ({
    payload,
    url: "/api/packageextraprivileges/" + id,
  })
);
export const createPackageExtra = createAction(
  "CREATE_PACKAGE_EXTRA",
  ({ payload, body }: SagaActionParams<PackageExtraPrivilegeDto>) => ({
    payload,
    url: "/api/packageextraprivileges",
    body,
  })
);
export const updatePackageExtra = createAction(
  "UPDATE_PACKAGE_EXTRA",
  ({ payload, body, id }: SagaActionParams<PackageExtraPrivilegeDto>) => ({
    payload,
    url: "/api/packageextraprivileges/" + id,
    body,
  })
);
export const deletePackageExtra = createAction(
  "DELETE_PACKAGE_EXTRA",
  ({ payload, body, id }: SagaActionParams<PackageExtraPrivilegeDto>) => ({
    payload,
    url: "/api/packageextraprivileges/" + id,
    body,
  })
);

const packageExtraSaga = [
  takeLatest(
    changePackageExtraOrder.toString(),
    (payload: PostSagaGenericParams<PackageExtraPrivilegeDto>) =>
      putSaga<PackageExtraPrivilegeDto>(payload)
  ),
  takeLatest(
    getPackageExtraDetails.toString(),
    (payload: SagaGenericParams<PackageExtraPrivilegeDto[]>) =>
      getListSaga<PackageExtraPrivilegeDto[]>(payload)
  ),
  takeLatest(
    getPackageExtraList.toString(),
    (payload: SagaGenericParams<PackageExtraPrivilegeDto[]>) =>
      getListSaga<PackageExtraPrivilegeDto[]>(payload)
  ),
  takeLatest(
    createPackageExtra.toString(),
    (payload: PostSagaGenericParams<PackageExtraPrivilegeDto>) =>
      postSaga<PackageExtraPrivilegeDto>(payload)
  ),
  takeLatest(
    updatePackageExtra.toString(),
    (payload: PostSagaGenericParams<PackageExtraPrivilegeDto>) =>
      putSaga<PackageExtraPrivilegeDto>(payload)
  ),
  takeLatest(
    deletePackageExtra.toString(),
    (payload: SagaGenericParams<PackageExtraPrivilegeDto>) =>
      deleteSaga<PackageExtraPrivilegeDto>(payload)
  ),
];

export default packageExtraSaga;
