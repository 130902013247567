import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { VehicleChangeStatusDto } from "./type";

export const getVehicleChangeStatusList = createAction(
  "GET_VEHICLE_CHANGE_STATUS_LIST",
  ({ payload, params }: SagaActionParams<VehicleChangeStatusDto[]>) => ({
    payload,
    url: "/api/vehiclechangestatuses" + (params ? params : ""),
  })
);
export const getVehicleChangeStatusDetails = createAction(
  "GET_VEHICLE_CHANGE_STATUS_DETAILS",
  ({ payload, id }: SagaActionParams<VehicleChangeStatusDto>) => ({
    payload,
    url: "/api/vehiclechangestatuses/" + id,
  })
);
export const createVehicleChangeStatus = createAction(
  "CREATE_VEHICLE_CHANGE_STATUS_DETAILS",
  ({ payload, body }: SagaActionParams<VehicleChangeStatusDto>) => ({
    payload,
    url: "/api/vehiclechangestatuses",
    body,
  })
);
export const updateVehicleChangeStatus = createAction(
  "UPDATE_VEHICLE_CHANGE_STATUS_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleChangeStatusDto>) => ({
    payload,
    url: "/api/vehiclechangestatuses/" + id,
    body,
  })
);
export const deleteVehicleChangeStatus = createAction(
  "DELETE_VEHICLE_CHANGE_STATUS_DETAILS",
  ({ payload, body, id }: SagaActionParams<VehicleChangeStatusDto>) => ({
    payload,
    url: "/api/vehiclechangestatuses/" + id,
    body,
  })
);

const vehiclechangeStatusSaga = [
  takeLatest(
    getVehicleChangeStatusList.toString(),
    (payload: SagaGenericParams<VehicleChangeStatusDto[]>) =>
      getListSaga<VehicleChangeStatusDto[]>(payload)
  ),
  takeLatest(
    getVehicleChangeStatusDetails.toString(),
    (payload: SagaGenericParams<VehicleChangeStatusDto>) =>
      getListSaga<VehicleChangeStatusDto>(payload)
  ),
  takeLatest(
    createVehicleChangeStatus.toString(),
    (payload: PostSagaGenericParams<VehicleChangeStatusDto>) =>
      postSaga<VehicleChangeStatusDto>(payload)
  ),

  takeLatest(
    updateVehicleChangeStatus.toString(),
    (payload: PostSagaGenericParams<VehicleChangeStatusDto>) =>
      putSaga<VehicleChangeStatusDto>(payload)
  ),
  takeLatest(
    deleteVehicleChangeStatus.toString(),
    (payload: SagaGenericParams<VehicleChangeStatusDto>) =>
      deleteSaga<VehicleChangeStatusDto>(payload)
  ),
];

export default vehiclechangeStatusSaga;
