import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  NotificationActionDto,
  NotificationAudienceDto,
  NotificationPeriodDto,
} from "./type";
export const getNotificationActionList = createAction(
  "GET_NOTIFICATION_ACTION_LIST",
  ({ payload, params }: SagaActionParams<NotificationActionDto[]>) => ({
    payload,
    url: "/api/notificationactions" + (params ? params : ""),
  })
);
export const getNotificationActionListByAudienceId = createAction(
  "GET_NOTIFICATION_ACTION_LIST_BY_AUDIENCEID",
  ({ payload, params, id }: SagaActionParams<NotificationActionDto[]>) => ({
    payload,
    url:
      "/api/notificationactions/getbyaudienceid/" + id + (params ? params : ""),
  })
);
export const getNotificationActionDetails = createAction(
  "GET_NOTIFICATION_ACTION_DETAILS",
  ({ payload, id }: SagaActionParams<NotificationActionDto>) => ({
    payload,
    url: "/api/notificationactions/" + id,
  })
);
export const createNotificationAction = createAction(
  "CREATE_NOTIFICATION_ACTION_DETAILS",
  ({ payload, body }: SagaActionParams<NotificationActionDto>) => ({
    payload,
    url: "/api/notificationactions",
    body,
  })
);
export const updateNotificationAction = createAction(
  "UPDATE_NOTIFICATION_ACTION_DETAILS",
  ({ payload, body, id }: SagaActionParams<NotificationActionDto>) => ({
    payload,
    url: "/api/notificationactions/" + id,
    body,
  })
);
export const deleteNotificationAction = createAction(
  "DELETE_NOTIFICATION_ACTION_DETAILS",
  ({ payload, body, id }: SagaActionParams<NotificationActionDto>) => ({
    payload,
    url: "/api/notificationactions/" + id,
    body,
  })
);

export const getNotificationPeriods = createAction(
  "GET_NOTIFICATON_PERIODS",
  ({ payload }: SagaActionParams<NotificationPeriodDto[]>) => ({
    payload,
    url: "/api/system/notification-periods",
  })
);
export const getNotificationAudiences = createAction(
  "GET_NOTIFICATON_AUDIENCES",
  ({ payload }: SagaActionParams<NotificationAudienceDto[]>) => ({
    payload,
    url: "/api/system/notification-audiences",
  })
);
const notificationActionSaga = [
  takeLatest(
    getNotificationActionList.toString(),
    (payload: SagaGenericParams<NotificationActionDto[]>) =>
      getListSaga<NotificationActionDto[]>(payload)
  ),
  takeLatest(
    getNotificationActionListByAudienceId.toString(),
    (payload: SagaGenericParams<NotificationActionDto[]>) =>
      getListSaga<NotificationActionDto[]>(payload)
  ),
  takeLatest(
    getNotificationActionDetails.toString(),
    (payload: SagaGenericParams<NotificationActionDto>) =>
      getListSaga<NotificationActionDto>(payload)
  ),
  takeLatest(
    createNotificationAction.toString(),
    (payload: PostSagaGenericParams<NotificationActionDto>) =>
      postSaga<NotificationActionDto>(payload)
  ),
  takeLatest(
    updateNotificationAction.toString(),
    (payload: PostSagaGenericParams<NotificationActionDto>) =>
      putSaga<NotificationActionDto>(payload)
  ),
  takeLatest(
    deleteNotificationAction.toString(),
    (payload: SagaGenericParams<NotificationActionDto>) =>
      deleteSaga<NotificationActionDto>(payload)
  ),
  takeLatest(
    getNotificationAudiences.toString(),
    (payload: SagaGenericParams<NotificationActionDto>) =>
      getListSaga<NotificationActionDto>(payload)
  ),
  takeLatest(
    getNotificationPeriods.toString(),
    (payload: SagaGenericParams<NotificationActionDto>) =>
      getListSaga<NotificationActionDto>(payload)
  ),
];

export default notificationActionSaga;
