import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { invoicePrivilege } from "src/store/privilege-invoices/saga";
import { InvoicePrivilegeDto } from "src/store/privilege-invoices/type";
import * as Yup from "yup";
import InvoiceDetailCard from "./detailCard";

const InvoicePrivilegeForm = ({
  onHide,
  open,
  onSuccessClick,
  selectedData,
}: {
  selectedData: InvoicePrivilegeDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        topcomponent={<InvoiceDetailCard selectedData={selectedData} />}
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Invoice Privilege"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: selectedData.Id,
          Invoiced: selectedData.Invoiced,
          InvoiceNumber: selectedData.InvoiceNumber ?? "",
          Invoice: "",
          InvoiceFile: selectedData.Invoice ?? "",
        }}
        onSubmit={values => {
          dispatch(
            invoicePrivilege({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: selectedData.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          InvoiceNumber: Yup.string().when("Invoiced", {
            is: (invoice: boolean) => invoice,
            then: Yup.string().required(t("Required")),
          }),
        })}
        inputs={[
          {
            field: "Invoiced",
            label: t("Faturalandı"),
            inputType: InputType.checkbox,
          },
          {
            field: "InvoiceNumber",
            label: t("Invoice Number"),
          },
          {
            field: "Invoice",
            label: t("InvoiceFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "InvoiceFile",
              multiple: false,
              title: t("Fatura Eki Yükleyiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default InvoicePrivilegeForm;
