import "./style.scss";
import { ITextEditorProps } from ".";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

//<link href="https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css" rel="stylesheet">

const CustomSunEditor = ({
  value,
  error,
  disabled,
  className,
  height = "60vh",
  touched,
  setFieldValue,
}: ITextEditorProps) => {
  //   const { quill, quillRef, Quill } = useQuill({
  //     modules: { blotFormatter: {}, htmlEditButton: {} },
  //   });

  //   if (Quill && !quill) {
  //     Quill.register({
  //       "modules/blotFormatter": BlotFormatter,
  //       "modules/htmlEditButton": htmlEditButton,
  //     });
  //     // const BlotFormatter = require('quill-blot-formatter');
  //     //  Quill.register(, BlotFormatter);
  //     // Quill.register("modules/htmlEditButton", );
  //   }

  //   useEffect(() => {
  //     if (quill) {
  //       value && quill.clipboard.dangerouslyPasteHTML(value);
  //       quill.on("text-change", (delta, oldContents) => {
  //         console.log(quill.root.innerHTML, "QQ");
  //         let value =
  //           quill.root.innerHTML === "<p><br></p>" ? "" : quill.root.innerHTML;
  //         if (value.includes("ql-editor")) {
  //           setFieldValue(value);
  //         } else {
  //           let mainClass = `<div class="ql-editor">{0}</div>`;
  //           setFieldValue(mainClass.replace("{0}", value));
  //         }

  //         // console.log("Text change!");
  //         // console.log(quill.getText()); // Get text only
  //         // console.log(quill.getContents()); // Get delta contents
  //         //  console.log(quill.root.innerHTML, "AAAAAAAAAAA"); // Get innerHTML using quill
  //         // console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
  //       });
  //     } // eslint-disable-next-line
  //   }, [quill, Quill]);

  return (
    <>
      <SunEditor
        disable={disabled}
        defaultValue={value}
        height={height}
        onChange={content => {
          let contentNew = content === "<p><br></p>" ? "" : content;
          setFieldValue(contentNew);
          // if (contentNew.includes("sun-editor-editable")) {
          //   setFieldValue(contentNew);
          // } else {
          //   let mainClass = `<div class="sun-editor-editable">{0}</div>`;
          //   setFieldValue(mainClass.replace("{0}", contentNew));
          // }
        }}
        setOptions={{
          buttonList: [
            ["font", "fontSize", "formatBlock"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["align", "horizontalRule", "list", "table"],
            ["fontColor", "hiliteColor"],
            ["outdent", "indent"],
            ["undo", "redo"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["link", "image"],
            ["preview", "print"],
            ["fullScreen", "showBlocks", "codeView"],
          ],
        }}
      />
    </>
  );
};

export default CustomSunEditor;
