import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getLocalDate } from "src/helpers/getLocalDate";
import { CouponDto } from "src/store/coupons/type";
import "./style.scss";
const CouponDetailCard = ({ couponData }: { couponData: CouponDto }) => {
  const getIcon = (condition: boolean) => {
    return condition ? (
      <i className="text-success bx  bx-check-square"></i>
    ) : (
      <i className="text-danger mdi mdi-close-box-outline"></i>
    );
  };

  const labels = [
    {
      label: "Kupon Kodu",
      value: couponData.Code,
    },
    {
      label: "Kupon Adı",
      value: couponData.Name,
    },
    {
      label: "Açıklama",
      value: couponData.Description,
    },
    {
      label: "Aktif",
      value: couponData.Active,
      type: "boolean",
    },
    {
      label: "Başlangıç Tarihi",
      value: getLocalDate(couponData.StartDate),
    },
    {
      label: "Bitiş Tarihi",
      value: getLocalDate(couponData.EndDate),
    },
    {
      label: "Sınırlı",
      value: couponData.Limited,
      type: "boolean",
    },
    {
      label: "Sınırlı Adet",
      value: couponData.LimitCount,
    },
    {
      label: "Min-Max Miktar",
      value: couponData.MaxAmount + " - " + couponData.MinAmount,
    },
    {
      label: "Hizmet Geçerliliği",
      value: couponData.Privilege,
      type: "boolean",
    },
    {
      label: "Hizmet İndirim Oranı",
      value:
        couponData.PackageDiscountRate && couponData.DiscountTypeId === 1
          ? "%" + couponData.PackageDiscountRate
          : couponData.PackageDiscountRate,
    },
    {
      label: "Paket Geçerliliği",
      value: couponData.Package,
      type: "boolean",
    },
    {
      label: "Geçerli Olduğu Paketler",
      value: couponData?.CouponIncludedPackages.map(
        (x: any) => x.PackageName
      )?.toString(),
    },
    {
      label: "Paket Aylık / Yıllık",
      value: couponData.CouponValidityTypeName,
    },
    {
      label: "Paket İndirim Oranı",
      value:
        couponData.PackageDiscountRate && couponData.DiscountTypeId === 1
          ? "%" + couponData.PackageDiscountRate
          : couponData.PackageDiscountRate,
    },
    {
      label: "Hediye Dahil",
      value: couponData.IncludedGift,
      type: "boolean",
    },
  ];
  return (
    <React.Fragment>
      <Row>
        <Card className="">
          <CardHeader className="mb-0 py-3">
            {couponData.Name} ({couponData.Code})
          </CardHeader>
          <CardBody style={{ fontSize: 12 }}>
            <Row>
              {labels.map(x => (
                <Col md={12} className="mb-1">
                  <div className="couponCard d-flex flex-row flex-wrap">
                    <div className="couponCardLabel">{x.label} : </div>
                    <div className="couponCardValue">
                      {x.type ? (
                        <>{x.type === "boolean" && getIcon(x.value)}</>
                      ) : (
                        x.value
                      )}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
};
export default CouponDetailCard;
