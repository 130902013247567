import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CallLogLevelDto, CallLogTopicDto } from "./type";

export const getCustomerCalllogParentTopics = createAction(
  "GET_CUSTOMER_CALL_LOG_PARENT_TOPICS",
  ({ payload }: SagaActionParams<CallLogTopicDto[]>) => ({
    payload,
    url: "/api/customercalllogtopics/parents",
  })
);
export const getCustomerCalllogSubTopicsByParentId = createAction(
  "GET_CUSTOMER_CALL_LOG_SUB_TOPICS_BY_PARENT_ID",
  ({ payload, id }: SagaActionParams<CallLogTopicDto[]>) => ({
    payload,
    url: "/api/customercalllogtopics/subtopics/" + id,
  })
);
export const getCustomerCalllogTopics = createAction(
  "GET_CUSTOMER_CALL_LOG_TOPICS",
  ({ payload }: SagaActionParams<CallLogTopicDto[]>) => ({
    payload,
    url: "/api/customercalllogtopics",
  })
);
export const getCustomerCalllogLevel = createAction(
  "GET_CUSTOMER_CALL_LOG_LEVELS",
  ({ payload }: SagaActionParams<CallLogLevelDto[]>) => ({
    payload,
    url: "/api/system/customer-callloglevels",
  })
);
export const getCustomerCalllogTopicDetails = createAction(
  "GET_CUSTOMER_CALL_LOG_TOPIC_DETAILS",
  ({ payload, id }: SagaActionParams<CallLogTopicDto>) => ({
    payload,
    url: "/api/customercalllogtopics/" + id,
  })
);
export const createCustomerCalllogTopic = createAction(
  "CREATE_CUSTOMER_CALL_LOG_TOPIC_DETAILS",
  ({ payload, body }: SagaActionParams<CallLogTopicDto>) => ({
    payload,
    url: "/api/customercalllogtopics",
    body,
  })
);
export const updateCustomerCalllogTopic = createAction(
  "UPDATE_CUSTOMER_CALL_LOG_TOPIC_DETAILS",
  ({ payload, body, id }: SagaActionParams<CallLogTopicDto>) => ({
    payload,
    url: "/api/customercalllogtopics/" + id,
    body,
  })
);
export const deleteCustomerCalllogTopic = createAction(
  "DELETE_CUSTOMER_CALL_LOG_TOPIC_DETAILS",
  ({ payload, body, id }: SagaActionParams<CallLogTopicDto>) => ({
    payload,
    url: "/api/customercalllogtopics/" + id,
    body,
  })
);
const customercalllogsaga = [
  takeLatest(
    getCustomerCalllogParentTopics.toString(),
    (payload: SagaGenericParams<CallLogTopicDto[]>) =>
      getListSaga<CallLogTopicDto[]>(payload)
  ),
  takeLatest(
    getCustomerCalllogSubTopicsByParentId.toString(),
    (payload: SagaGenericParams<CallLogTopicDto[]>) =>
      getListSaga<CallLogTopicDto[]>(payload)
  ),
  takeLatest(
    getCustomerCalllogTopics.toString(),
    (payload: SagaGenericParams<CallLogTopicDto[]>) =>
      getListSaga<CallLogTopicDto[]>(payload)
  ),
  takeLatest(
    getCustomerCalllogTopicDetails.toString(),
    (payload: SagaGenericParams<CallLogTopicDto>) =>
      getListSaga<CallLogTopicDto>(payload)
  ),
  takeLatest(
    getCustomerCalllogLevel.toString(),
    (payload: SagaGenericParams<CallLogTopicDto>) =>
      getListSaga<CallLogTopicDto>(payload)
  ),
  takeLatest(
    createCustomerCalllogTopic.toString(),
    (payload: PostSagaGenericParams<CallLogTopicDto>) =>
      postSaga<CallLogTopicDto>(payload)
  ),
  takeLatest(
    updateCustomerCalllogTopic.toString(),
    (payload: PostSagaGenericParams<CallLogTopicDto>) =>
      putSaga<CallLogTopicDto>(payload)
  ),
  takeLatest(
    deleteCustomerCalllogTopic.toString(),
    (payload: SagaGenericParams<CallLogTopicDto>) =>
      deleteSaga<CallLogTopicDto>(payload)
  ),
];

export default customercalllogsaga;
