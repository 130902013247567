import { TextBox } from "devextreme-react";
import moment from "moment";
import { Card, Col, Row } from "reactstrap";
import { OrderDetailDto } from "src/store/orders/orders/type";

const PaymentInfoRefund = ({ order }: { order: OrderDetailDto }) => {
  const allRefundInfos = order.OrderItems.flatMap(
    item => item.OrderRefundInfos
  );
  const array = order.OrderRefundInfos.concat(allRefundInfos);
  return (
    <div>
      {array && array.length > 0 && (
        <Row>
          <div className="fw-bold text-danger">
            *Üyeye İade Edilen Tutarlar Listesi
          </div>
          {array.map((x, i) => (
            <Col md={3} key={i}>
              <Card className="py-2">
                <TextBox
                  className="mb-2"
                  readOnly
                  label={"İade Edilen Tutar"}
                  value={x.Amount?.toString()}
                  stylingMode="filled"
                  style={{
                    backgroundColor: "white",
                    padding: 0,
                  }}
                />
                <TextBox
                  className="mb-2"
                  readOnly
                  label={"İade Tarihi"}
                  value={moment(x.RefundDate).format("DD/MM/yyyy HH:mm")}
                  stylingMode="filled"
                  style={{
                    backgroundColor: "white",
                    padding: 0,
                  }}
                />
                <TextBox
                  className="mb-2"
                  readOnly
                  label={"İade Id"}
                  value={x.RefundRequestId ?? "-"}
                  stylingMode="filled"
                  style={{
                    backgroundColor: "white",
                    padding: 0,
                  }}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};
export default PaymentInfoRefund;
