import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getFaqsCategoryList } from "src/store/faqs-category/saga";
import { FaqsCategoryDto } from "src/store/faqs-category/type";
import { createFaqs } from "src/store/faqs/saga";
import * as Yup from "yup";

const CreateFaqs = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<FaqsCategoryDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getFaqsCategoryList({
          payload: {
            onSuccess: (msg, py) => setCategories(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Faqs"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Question: "",
          Answer: "",
          Mobile: false,
          Web: false,
          FaqCategoryId: "",
        }}
        onSubmit={values => {
          dispatch(
            createFaqs({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Question: Yup.string().required(t("Required")),
          Answer: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "FaqCategoryId",
            inputType: InputType.multiselect,
            label: t("Category Name"),
            lookup: {
              data: categories,
              labelKey: "CategoryName",
              valueKey: "Id",
              placeholder: t("Choose Category"),
            },
          },
          {
            field: "Question",
            label: t("Question"),
            inputType: InputType.multilinetext,
          },
          {
            field: "Answer",
            label: t("Answer"),
            inputType: InputType.multilinetext,
          },
          {
            field: "Mobile",
            label: t("Mobile"),
            col: 2,
            checkedLabel: t("Mobil"),
            uncheckedLabel: t("Mobil"),
            inputType: InputType.toogle,
          },
          {
            field: "Web",
            label: t("Web"),
            col: 2,
            checkedLabel: t("Web"),
            uncheckedLabel: t("Web"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateFaqs;
