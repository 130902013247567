import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegeDetails } from "src/store/privilege/saga";
import DevextGrid from "src/components/PageContent/DevextGrid";
import { Button } from "devextreme-react";
import { Container } from "reactstrap";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import DataGrid, { Item } from "devextreme-react/data-grid";
import { assignPrivilegeCardtoAllCustomer } from "src/store/privilegecard/saga";
const CustomerPrivilegeCardByCustomerIdPage = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState<{ show: boolean; customers: string[] }>();
  const { id }: { id: string } = useParams();
  const [privilege, setPrivilege] = useState<PrivilegeDto>();
  useEffect(() => {
    dispatch(
      getPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      caption: t("Müşteri Adı"),
      dataField: "Customer.Name",
    },
    {
      caption: t("Müşteri Soyadı"),
      dataField: "Customer.Surname",
    },
    {
      caption: t("Telefon"),
      dataField: "Customer.PhoneNumber",
    },
  ];

  const onOpenModalFunc = () => {
    let customers: any[] =
      dataGridRef.current?.instance.getSelectedRowKeys() ?? [];
    if (customers.length <= 0) {
      toast.error("Üye seçiniz");
    } else {
      setShow({
        show: true,
        customers: customers,
      });
    }
  };
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <Container fluid>
          <React.Fragment>
            <div
              className={
                "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              <h5 className="mb-0 font-size-18">
                {(privilege ? privilege.Title : "") + " " + t("Üye Hakları")}
              </h5>
            </div>
            <DevextGrid
              ref={dataGridRef}
              title={t("Customers")}
              url={"/api/privilegecards/getcustomerprivilege/" + id}
              columns={columns}
              selection={{ mode: "multiple", allowSelectAll: true }}
              gridItems={
                <Item>
                  <div className="d-flex gap-2">
                    <Button
                      text={"Kart Sayfasını Aç"}
                      stylingMode={"outlined"}
                      type={"normal"}
                      onClick={() => history.push("/privilegecards/" + id)}
                    />
                    <Button
                      text={"Kart Ataması"}
                      stylingMode={"contained"}
                      type={"success"}
                      onClick={() => onOpenModalFunc()}
                    />
                  </div>
                </Item>
              }
            />
            {show && (
              <CancelAlert
                message={
                  "Otomatik kart ataması yapmak istediğinize emin misiniz?"
                }
                onConfirm={() => {
                  setShow(undefined);
                  dispatch(
                    assignPrivilegeCardtoAllCustomer({
                      payload: {
                        onSuccess: message => {
                          toast.success("Başarılı");
                          window.open("/privilegecards/" + id, "_blank");
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: {
                        CustomerPrivilegeIds: show.customers,
                        PrivilegeId: id,
                      },
                    })
                  );
                }}
                onCancel={() => {
                  setShow(undefined);
                }}
              />
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CustomerPrivilegeCardByCustomerIdPage;
