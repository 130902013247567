import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import SmsStatusModal from "./smsStatus";
import { getSmsStatus } from "src/store/system-logs/saga";
import { useDispatch } from "react-redux";
import { SmsStatusResponse } from "src/store/system-logs/type";

const SmsLogsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<SmsStatusResponse>();
  const [show, setShow] = useState(false);
  const columns = [
    {
      caption: t("Error"),
      dataField: "Error",
      dataType: "boolean",
    },
    {
      caption: t("Numara Listesi"),
      dataField: "GsmList",
    },
    {
      caption: t("Message"),
      dataField: "Message",
    },
    {
      caption: t("Response Data"),
      dataField: "ResponseData",
    },
    {
      caption: t("Response MessageId"),
      dataField: "ResponseMessageId",
    },
    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            dispatch(
              getSmsStatus({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShow(true);
                  },
                  onError: () => {},
                },
                id: e.row.data.ResponseMessageId,
              })
            );
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {selected && (
        <SmsStatusModal
          data={selected}
          onHide={() => setShow(false)}
          open={show}
        />
      )}
      <MainPage
        devProps={{
          showColumnLines: true,
          url: "/api/systemlogs/sms",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Sms Gönderim Logları")}
      />
    </React.Fragment>
  );
};

export default SmsLogsPage;
