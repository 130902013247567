import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import {
  createSupplier,
  getSupplierServiceStatusTypeList,
} from "src/store/supplier/saga";
import * as Yup from "yup";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import { CityDto, TownDto } from "src/store/lookup/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { useHistory } from "react-router";
import { SupplierTypeDto } from "src/store/suppliertypes/type";
import { getSupplierTypeList } from "src/store/suppliertypes/saga";
import { SupplierServiceStatusTypeDto } from "src/store/supplier/type";

const CreateSupplierPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [supplierTypes, setSupplierTypes] = useState<SupplierTypeDto[]>([]);
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);

  const [serviceTypes, setServiceTypes] = useState<
    SupplierServiceStatusTypeDto[]
  >([]);
  useEffect(() => {
    dispatch(
      getSupplierServiceStatusTypeList({
        payload: {
          onSuccess: (msg, payload) => setServiceTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getSupplierTypeList({
        payload: {
          onSuccess: (msg, payload) => setSupplierTypes(payload),
          onError: () => {},
        },
      })
    );

    dispatch(
      getCities({
        payload: {
          onSuccess: (msg, payload) => setCities(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("SupplierTitle")}
            breadcrumbItem={t("New Supplier")}
          />

          <Row className="mt-4">
            <CustomFormik
              initialValues={{
                SupplierTypeId: "",
                Name: "",
                TaxNumber: "",
                TaxOffice: "",
                Address: "",
                CityId: "",
                PersonName: "",
                PersonSurname: "",
                TownId: "",
                Active: true,
                BankName: "",
                Iban: "",
                Email: "",
                Password: "",
                Brand: "",
                LogoFile: "",
                ContractStartDate: "",
                ContractEndDate: "",
                ApiVehicleInformation: false,
                ApiCustomerInformation: false,
                LocationShow: false,
                SupplierServiceStatusTypeId: "",
              }}
              onSubmit={values => {
                dispatch(
                  createSupplier({
                    payload: {
                      onSuccess: (message, payload) => {
                        toast.success(message, { theme: "colored" });
                        return history.push(
                          "/supplier/details/1/" + payload.Id
                        );
                      },
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: values,
                  })
                );
              }}
              validationSchema={Yup.object().shape({
                Name: Yup.string().required(t("Required")),
                SupplierTypeId: Yup.string().required(t("Required")),
                Address: Yup.string().required(t("Required")),
                CityId: Yup.string().required(t("Required")),
                PersonName: Yup.string().required(t("Required")),
                PersonSurname: Yup.string().required(t("Required")),
                TownId: Yup.string().required(t("Required")),
                Email: Yup.string().required(t("Required")),
                Password: Yup.string().required(t("Required")),
                Brand: Yup.string().required(t("Required")),
              })}
              inputs={[
                {
                  field: "SupplierTypeId",
                  inputType: InputType.multiselect,
                  label: t("Supplier Type"),
                  lookup: {
                    data: supplierTypes,
                    labelKey: "Name",
                    valueKey: "Id",
                    placeholder: t("Choose Supplier Type"),
                  },
                },
                {
                  field: "SupplierServiceStatusTypeId",
                  inputType: InputType.multiselect,
                  label: t("Tedarikçi Servis Tipi"),
                  lookup: {
                    data: serviceTypes,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Choose Supplier Type"),
                  },
                },
                {
                  col: 6,
                  field: "Name",
                  label: t("Name"),
                },
                {
                  col: 6,
                  field: "Brand",
                  label: t("Brand"),
                },
                {
                  col: 6,
                  field: "Email",
                  label: t("Email"),
                },
                {
                  col: 6,
                  field: "Password",
                  label: t("Password"),
                },
                {
                  field: "PersonName",
                  col: 6,
                  label: t("Person Name"),
                },
                {
                  field: "PersonSurname",
                  col: 6,
                  label: t("Person Surname"),
                },
                {
                  field: "TaxOffice",
                  label: t("Tax Office"),
                  col: 6,
                },
                {
                  field: "TaxNumber",
                  label: t("Tax Number"),
                  col: 6,
                },
                {
                  field: "BankName",
                  label: t("Bank Name"),
                  col: 6,
                },
                {
                  field: "Iban",
                  label: t("IBAN"),
                  col: 6,
                },
                {
                  field: "ContractStartDate",
                  label: t("Contract Start Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  field: "ContractEndDate",
                  label: t("Contract End Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  col: 6,
                  field: "CityId",
                  inputType: InputType.multiselect,
                  label: t("City"),
                  lookup: {
                    data: cities,
                    labelKey: "CityName",
                    valueKey: "Id",
                    placeholder: t("Choose City"),
                  },
                  onChange(value, item, setFieldValue) {
                    value &&
                      dispatch(
                        getTowns({
                          payload: {
                            onSuccess: (msg, payload) => setTowns(payload),
                            onError: () => {},
                          },
                          id: value,
                        })
                      );
                  },
                },
                {
                  col: 6,
                  field: "TownId",
                  inputType: InputType.multiselect,
                  label: t("Town"),
                  lookup: {
                    data: towns,
                    labelKey: "TownName",
                    valueKey: "Id",
                    placeholder: t("Choose Town"),
                  },
                },
                {
                  field: "Address",
                  label: t("Address"),
                  inputType: InputType.multilinetext,
                },
                {
                  field: "ApiVehicleInformation",
                  label: t("Api Vehicle Information"),
                  col: 4,
                  inputType: InputType.checkbox,
                },

                {
                  field: "ApiCustomerInformation",
                  label: t("Api Customer Information"),
                  col: 4,
                  inputType: InputType.checkbox,
                },
                {
                  field: "LocationShow",
                  label: t("Lokasyon Göster"),
                  col: 4,
                  inputType: InputType.checkbox,
                },
                {
                  field: "LogoFile",
                  label: t("LogoFile Image"),
                  inputType: InputType.fileUpload,
                  fileUpload: {
                    accept: ["image/jpeg", "image/png"],
                    multiple: false,
                    title: t("Please Logo Image"),
                  },
                },

                {
                  field: "Active",
                  label: t("Active"),
                  col: 6,
                  checkedLabel: t("Active"),
                  uncheckedLabel: t("Pasive"),
                  inputType: InputType.toogle,
                },
              ]}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateSupplierPage;
