import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import { getCampaignList } from "src/store/campaigns/saga";
import { CampaignDto } from "src/store/campaigns/type";
import { getNotificationPage } from "src/store/lookup/saga";
import { NotificationPageDto } from "src/store/lookup/type";
import {
  sendNotificationAllActiveCustomerSaga,
  sendNotificationAllCustomerSaga,
  sendNotificationSaga,
} from "src/store/notifications/saga";
import { getPrivilegeCategoryList } from "src/store/privilegecategory/saga";
import * as Yup from "yup";
import { PrivilegeCategoryDto } from "src/store/privilegecategory/type";
import { PrivilegeFilterDto } from "./privilegeFilter";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";

const SendNotification = ({
  show,
  onHide,
  customers,
  onSuccessSubmit,
  isAll,
  filterData,
}: {
  filterData?: PrivilegeFilterDto;
  isAll: "select" | "active" | "all";
  show: boolean;
  onHide: () => void;
  customers: string[];
  onSuccessSubmit?: () => void;
}) => {
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const dispatch = useDispatch();
  const [pages, setPages] = useState<NotificationPageDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [privilegeCategories, setPrivilegeCategories] = useState<
    PrivilegeCategoryDto[]
  >([]);
  const [campaigns, setCampaigns] = useState<CampaignDto[]>([]);
  useEffect(() => {
    if (show) {
      dispatch(
        getNotificationPage({
          payload: {
            onSuccess: (msg, payload) => setPages(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeCategoryList({
          payload: {
            onSuccess: (msg, payload) => setPrivilegeCategories(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCampaignList({
          payload: {
            onSuccess: (msg, payload) => setCampaigns(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, payload) => setPrivileges(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, show]);
  return (
    <React.Fragment>
      <CustomModal
        onHide={() => onHide()}
        open={show}
        title={t("Send Nofitification")}
      >
        {loader ? (
          <Loader />
        ) : (
          <Formik
            onSubmit={values => {
              if (isAll === "all") {
                dispatch(
                  sendNotificationAllCustomerSaga({
                    payload: {
                      onSuccess: (ms, payload) => {
                        toast.success(
                          "Bildirim gönderimi başarılı bir şekilde başlatıldı"
                        );
                        onSuccessSubmit && onSuccessSubmit();
                        onHide();
                      },
                      onError: () => {
                        toast.error("Hata oluştu");
                      },
                    },
                    body: values,
                  })
                );
              } else if (isAll === "active") {
                dispatch(
                  sendNotificationAllActiveCustomerSaga({
                    payload: {
                      onSuccess: (ms, payload) => {
                        toast.success(
                          "Bildirim gönderimi başarılı bir şekilde başlatıldı"
                        );
                        onSuccessSubmit && onSuccessSubmit();
                        onHide();
                      },
                      onError: () => {
                        toast.error("Hata oluştu");
                      },
                    },
                    body: values,
                  })
                );
              } else {
                dispatch(
                  sendNotificationSaga({
                    payload: {
                      onSuccess: (ms, payload) => {
                        toast.success("Bildirim gönderimi başarılı");
                        onSuccessSubmit && onSuccessSubmit();
                        onHide();
                      },
                      onError: () => {
                        toast.error("Hata oluştu");
                      },
                    },
                    body: values,
                  })
                );
              }
            }}
            initialValues={{
              customerIds: customers,
              title: "",
              message: "",
              payload: "",
              image: "",
              notificationPageId: "",
              entityId: "",
              url: "",
              privilegeId: filterData ? filterData.PrivilegeId : "",
              privilegeFilterTypeId: filterData
                ? filterData.PrivilegeFilterTypeId
                : "",
            }}
            validationSchema={Yup.object().shape({
              entityId: Yup.string().when("notificationPageId", {
                is: (id: number) =>
                  id === 1 || id === 2 || id === 3 || id === 23,
                then: Yup.string().required(t("Required")),
              }),
              url: Yup.string().when("notificationPageId", {
                is: (id: number) => id === 21,
                then: Yup.string().required(t("Required")),
              }),
            })}
          >
            {({ values, handleSubmit }) => (
              <Row className="mx-3">
                <GetInput
                  inputprop={{
                    field: "title",
                    label: t("Title"),
                  }}
                />
                <GetInput
                  inputprop={{
                    field: "message",
                    label: t("Message"),
                    inputType: InputType.multilinetext,
                  }}
                />
                <GetInput
                  inputprop={{
                    field: "Image",
                    label: t("Fotoğraf"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      multiple: false,
                      title: t("Please Upload Image"),
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    field: "notificationPageId",
                    inputType: InputType.multiselect,
                    label: t("Bildirim Sayfası"),
                    lookup: {
                      data: pages,
                      labelKey: "Title",
                      valueKey: "EnumId",
                      placeholder: t("Seçiniz"),
                    },
                    onChange(value, item, setFieldValue) {
                      setFieldValue("entityId", "");
                      setFieldValue("url", "");
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    hide(values) {
                      return !(
                        values.notificationPageId === 2 ||
                        values.notificationPageId === 3
                      );
                    },
                    field: "entityId",
                    inputType: InputType.multiselect,
                    label: t("Kampanya"),
                    lookup: {
                      data: campaigns,
                      labelKey: "Title",
                      valueKey: "Id",
                      placeholder: t("Seçiniz"),
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    hide(values) {
                      return values.notificationPageId !== 20;
                    },
                    field: "entityId",
                    inputType: InputType.multiselect,
                    label: t("Kampanya"),
                    lookup: {
                      data: campaigns,
                      labelKey: "Title",
                      valueKey: "Id",
                      placeholder: t("Seçiniz"),
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    hide(values) {
                      return values.notificationPageId !== 23;
                    },
                    field: "entityId",
                    inputType: InputType.multiselect,
                    label: t("Ayrıcalık"),
                    lookup: {
                      data: privileges,
                      labelKey: "Title",
                      valueKey: "Id",
                      placeholder: t("Seçiniz"),
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    hide(values) {
                      return values.notificationPageId !== 1;
                    },
                    field: "entityId",
                    inputType: InputType.multiselect,
                    label: t("Kategori"),
                    lookup: {
                      data: privilegeCategories,
                      labelKey: "CategoryName",
                      valueKey: "Id",
                      placeholder: t("Seçiniz"),
                    },
                  }}
                />
                <GetInput
                  inputprop={{
                    hide(values) {
                      return values.notificationPageId !== 21;
                    },
                    field: "url",
                    label: t("URL"),
                  }}
                />
                <div className={"d-flex mt-2  justify-content-between "}>
                  <Button
                    color="danger"
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    onClick={() => {
                      return onHide();
                    }}
                  >
                    {t("Close")}
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    style={{
                      width: 150,
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {t("Send")}
                  </Button>
                </div>
              </Row>
            )}
          </Formik>
        )}
      </CustomModal>
    </React.Fragment>
  );
};

export default SendNotification;
