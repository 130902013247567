import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getCustomerDetails } from "src/store/customers/saga";
import { CustomerDetailDto } from "src/store/customers/type";
import { isLoading } from "src/store/loader";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "src/components/Loader";
import { navMenuList } from "./menuList";
import "./style.scss";
import CustomResponsiveNav from "src/components/navbar/responsiveNav";
import CustomerCalllogTab from "./Calllogs";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomerDetailCard from "./detailCard";
import AddBlackListModal from "./btn-blacklist";

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(isLoading);
  const { t } = useTranslation();
  const { id, tab }: { id: string; tab: string } = useParams();
  const [activeTab, setActiveTab] = useState<string>(tab);
  const [customerData, setCustomerData] = useState<CustomerDetailDto>();
  useEffect(() => {
    dispatch(
      getCustomerDetails({
        payload: {
          onSuccess: (ms, payload) => {
            return setCustomerData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const FindActiveMenuComp = () => {
    const activeComp = navMenuList.find(x => x.eventKey === activeTab);
    return activeComp ? activeComp.component : <></>;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {customerData
              ? `${customerData && customerData.Name} ${
                  customerData && customerData.Surname
                } `
              : ""}
            | Servis Plan
          </title>
        </MetaTags>
        <Container fluid>
          {loading && <Loader />}
          {customerData && (
            <>
              <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                <div>
                  <Breadcrumbs
                    breadcrumbItem={t("Müşteri Bilgileri")}
                    title={"Dashboard"}
                  />
                </div>
                <div>
                  <AddBlackListModal
                    customerData={customerData}
                    onSuccessSubmit={() => {}}
                  />
                </div>
              </div>
              <CustomerDetailCard
                customerData={customerData}
                onSuccessSubmit={() =>
                  dispatch(
                    getCustomerDetails({
                      payload: {
                        onSuccess: (ms, payload) => {
                          return setCustomerData(payload);
                        },
                        onError: () => {},
                      },
                      id: id,
                    })
                  )
                }
              />
              <div className="d-flex flex-column">
                <CustomResponsiveNav
                  className="mainNav"
                  appearance="tabs"
                  activeKey={activeTab}
                  onSelect={e => {
                    history.push("/customer/details/" + e + "/" + id);
                    return setActiveTab(e);
                  }}
                  items={navMenuList.map(x => {
                    return {
                      eventKey: x.eventKey,
                      label: x.text,
                    };
                  })}
                />
                <div className="tabComponent p-2 ">
                  <div className="border">
                    {FindActiveMenuComp()}
                    {activeTab === "8" && (
                      <CustomerCalllogTab customerData={customerData} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CustomerDetails;
