//@ts-nocheck
import { useEffect, useRef } from "react";
import * as ko from "knockout";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import { baseUrl } from "src/store/base/types/url";

const ReportDesigner = () => {
  const { token } = useSelector(getApiParams);
  const ref = useRef<any>();
  const reportUrl = ko.observable("Contract");
  ajaxSetup.ajaxSettings = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const requestOptions = {
    host: baseUrl + "/api/",
    getDesignerModelAction: "DXXRD/GetReportDesignerModel",
  };
  useEffect(
    () => {
      ko.applyBindings(
        {
          reportUrl: reportUrl,
          requestOptions: requestOptions,
        },
        ref.current
      );
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div id="designer" ref={ref} data-bind="dxReportDesigner: $data"></div>
  );
};
export default ReportDesigner;
