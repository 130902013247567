import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import {
  getDiscountTypeLookupList,
  getPrivilegeUsageTypes,
  getprivilegeCouponTypes,
} from "src/store/lookup/saga";
import {
  DiscountTypeDto,
  PrivilegeCouponType,
  UsageTypeDto,
} from "src/store/lookup/type";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useHistory } from "react-router";
import { PrivilegeCategoryDto } from "src/store/privilegecategory/type";
import { getPrivilegeCategoryList } from "src/store/privilegecategory/saga";
import { createCampaign } from "src/store/campaigns/saga";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";

const CreateCampaignPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<PrivilegeCategoryDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const history = useHistory();
  const [usageTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const [couponTypes, setCouponTypes] = useState<PrivilegeCouponType[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);

  useEffect(() => {
    dispatch(
      getPrivilegeList({
        payload: {
          onSuccess: (msg, payload) => setPrivileges(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getprivilegeCouponTypes({
        payload: {
          onSuccess: (msg, payload) => setCouponTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getDiscountTypeLookupList({
        payload: {
          onSuccess: (msg, payload) => setDiscountTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeUsageTypes({
        payload: {
          onSuccess: (msg, payload) => setUsageTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeCategoryList({
        payload: {
          onSuccess: (msg, payload) => setCategories(payload),
          onError: () => {},
        },
      })
    );

    dispatch(
      getSupplierLookup({
        payload: {
          onSuccess: (msg, payload) => setSuppliers(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("Campaigns")}
            breadcrumbItem={t("New Campaign")}
          />

          <Row className="mt-4">
            <CustomFormik
              loader
              createButtonClass="w-100"
              initialValues={{
                PrivilegeUsageTypeId: "",
                UsageValue: "",
                PrivilegeCategoryId: "",
                SupplierId: "",
                Title: "",
                ShortDescription: "",
                Description: "",
                UsageInformationText: "",
                ShowUsageInformationText: false,
                StartDate: "",
                EndDate: "",
                Limited: false,
                CouponCount: 0,
                Active: false,
                ImageFile: "",
                ResponsiveImageFile: "",
                ShowWebsite: false,
                TermsOfService: "",
                CanBeUsedMoreThanOnce: false,
                DiscountTypeId: "",
                DiscountValue: "",
                IncludablePackage: false,
                Sellable: false,
                ShowOnMobile: false,
                PrivilegeCouponTypeId: 1,
                CouponLenght: 10,
                ShowPhoneNumber: false,
                ShowTerms: false,
                PhoneNumber: "",
                SendNotificationMembers: false,
                SendNotificationAllCustomer: false,
                ConnectedPrivilegeId: "",
                AddToSlider: false,
              }}
              onSubmit={values => {
                dispatch(
                  createCampaign({
                    payload: {
                      onSuccess: (message, payload) => {
                        toast.success(message, { theme: "colored" });
                        return history.push("/campaign/edit/" + payload.Id);
                      },
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: values,
                  })
                );
              }}
              validationSchema={Yup.object().shape({
                ImageFile: Yup.mixed().required(t("Required")),
                PrivilegeCouponTypeId: Yup.number().required(t("Required")),
                PrivilegeUsageTypeId: Yup.number().required(t("Required")),
                PrivilegeCategoryId: Yup.string().required("Zorunlu"),
                SupplierId: Yup.string().required("Zorunlu"),
                ShortDescription: Yup.string().required("Zorunlu"),
                Title: Yup.string().required("Zorunlu"),
                Description: Yup.string().required("Zorunlu"),
                StartDate: Yup.string().required("Zorunlu"),
                EndDate: Yup.string().required("Zorunlu"),
                DiscountTypeId: Yup.number().required("Zorunlu"),
                DiscountValue: Yup.number().required("Zorunlu"),
                CouponLenght: Yup.number()
                  .min(4, "Minimum değeri 4 olabilir")
                  .max(12, "Maximum değeri 12 olabilir")
                  .required("Zorunlu"),
                PhoneNumber: Yup.string().when("ShowPhoneNumber", {
                  is: (id: boolean) => id,
                  then: Yup.string().required(t("Required")),
                }),
              })}
              inputs={[
                {
                  field: "PrivilegeCategoryId",
                  inputType: InputType.multiselect,
                  label: t("Kategori"),
                  lookup: {
                    data: categories,
                    labelKey: "CategoryName",
                    valueKey: "Id",
                    placeholder: t("Kategori Seçiniz"),
                  },
                  col: 4,
                },
                {
                  col: 4,
                  field: "SupplierId",
                  inputType: InputType.multiselect,
                  label: t("Supplier"),
                  lookup: {
                    data: suppliers,
                    labelKey: "Name",
                    valueKey: "Id",
                    placeholder: t("Choose Supplier"),
                  },
                },
                {
                  col: 4,
                  field: "ConnectedPrivilegeId",
                  inputType: InputType.multiselect,
                  label: t("Bağlı Hizmet"),
                  lookup: {
                    data: privileges,
                    labelKey: "Title",
                    valueKey: "Id",
                    placeholder: t("Bağlı Hizmet Seçiniz"),
                  },
                },
                {
                  field: "Title",
                  label: t("Title"),
                },
                {
                  field: "ShortDescription",
                  label: t("Short Description"),
                  inputType: InputType.multilinetext,
                },
                {
                  field: "Description",
                  label: t("Description"),
                  inputType: InputType.multilinetext,
                },

                {
                  field: "PrivilegeCouponTypeId",
                  inputType: InputType.multiselect,
                  label: t("Kupon Tipi"),
                  lookup: {
                    data: couponTypes,
                    labelKey: "Name",
                    valueKey: "EnumId",
                  },
                  col: 6,
                },
                {
                  field: "CouponLenght",
                  label: t("Kupon Uzunluğu"),
                  inputType: InputType.number,
                  min: 4,
                  max: 12,
                  col: 6,
                },
                {
                  field: "StartDate",
                  label: t("Start Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  field: "EndDate",
                  label: t("End Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  field: "PrivilegeUsageTypeId",
                  inputType: InputType.multiselect,
                  label: t("Usage Type"),
                  lookup: {
                    data: usageTypes,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Choose Usage Type"),
                  },
                  col: 6,
                  onChange(value, item, setFieldValue, values) {
                    if (
                      !(
                        value === PrivilegeUsageTypeEnum.Kupon ||
                        value === PrivilegeUsageTypeEnum.QR
                      )
                    ) {
                      setFieldValue("Limited", false);
                      setFieldValue("CouponCount", 0);
                    }
                  },
                },
                {
                  hide: values =>
                    !(
                      values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.Kupon ||
                      values.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.QR
                    ),
                  field: "Limited",
                  label: t("Limited"),
                  col: 2,
                  inputType: InputType.checkbox,
                },
                {
                  hide: values =>
                    !(
                      values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.Kupon && values.Limited
                    ),
                  field: "CouponCount",
                  label: t("Coupon Count"),
                  inputType: InputType.number,
                  col: 4,
                },
                {
                  hide: values =>
                    !(
                      values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.QR && values.Limited
                    ),
                  field: "CouponCount",
                  label: t("QR Count"),
                  inputType: InputType.number,
                  col: 4,
                },
                {
                  hide: values =>
                    !(
                      values.PrivilegeUsageTypeId ===
                      PrivilegeUsageTypeEnum.Link
                    ),
                  field: "UsageValue",
                  label: t("Link"),
                  col: 6,
                },
                {
                  hide: values =>
                    !(
                      values.PrivilegeUsageTypeId ===
                      PrivilegeUsageTypeEnum.SabitKod
                    ),
                  field: "UsageValue",
                  label: t("Sabit Kod"),
                  col: 6,
                },

                {
                  col: 6,
                  field: "DiscountTypeId",
                  inputType: InputType.multiselect,
                  label: t("Discount Type"),
                  lookup: {
                    data: discountTypes,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Choose Discount Type"),
                  },
                },
                {
                  field: "DiscountValue",
                  label: t("Discount Value"),
                  inputType: InputType.number,
                  col: 12,
                },
                {
                  field: "ShowPhoneNumber",
                  label: t("Telefon Numarası Göster"),
                  inputType: InputType.checkbox,
                  col: 12,
                },
                {
                  field: "PhoneNumber",
                  label: t("PhoneNumber"),
                  col: 12,
                  hide: values => !values.ShowPhoneNumber,
                },
                {
                  field: "TermsOfService",
                  label: t("TermsOfService"),
                  inputType: InputType.multilinetext,
                },
                {
                  field: "CanBeUsedMoreThanOnce",
                  label: t("Can Be Used More Than Once"),

                  col: 3,
                  inputType: InputType.checkbox,
                },

                {
                  field: "Active",
                  label: t("Active"),
                  col: 2,
                  checkedLabel: t("Active"),
                  uncheckedLabel: t("Pasive"),
                  inputType: InputType.checkbox,
                },

                {
                  field: "IncludablePackage",
                  label: t("Includable Package"),
                  col: 2,
                  inputType: InputType.checkbox,
                },

                {
                  field: "ShowOnMobile",
                  label: t("Show On Mobile"),
                  col: 2,
                  inputType: InputType.checkbox,
                },
                {
                  field: "ShowWebsite",
                  label: t("Show On Web"),
                  col: 2,
                  inputType: InputType.checkbox,
                },
                {
                  field: "AddToSlider",
                  label: t("Slider olarak ekle"),
                  col: 2,
                  inputType: InputType.checkbox,
                },

                {
                  field: "ShowUsageInformationText",
                  label: t("Kullanım Şeklini Göster"),
                  inputType: InputType.checkbox,
                },
                {
                  field: "ShowTerms",
                  label: t("Kullanım Koşullarını Göster"),
                  col: 2,
                  inputType: InputType.checkbox,
                },
                {
                  field: "UsageInformationText",
                  label: t("Kullanım Şekli"),
                  height: "200px",
                  inputType: InputType.editor,
                },

                {
                  field: "ImageFile",
                  label: t("ImageFile Image"),
                  inputType: InputType.fileUpload,
                  fileUpload: {
                    accept: ["image/jpeg", "image/png"],
                    multiple: false,
                    title: t("Please Upload Image"),
                  },
                },
                {
                  field: "ResponsiveImageFile",
                  label: t("Responsive Fotoğraf"),
                  inputType: InputType.fileUpload,
                  fileUpload: {
                    accept: ["image/jpeg", "image/png"],
                    multiple: false,
                    title: t("Responsive Fotoğraf Yükleyiniz"),
                  },
                },
                {
                  field: "SendNotificationMembers",
                  label: t("Üyelere Bildirim Gönder"),
                  inputType: InputType.checkbox,
                  onChange(value, item, setFieldValue, values) {
                    if (
                      value === true &&
                      values.SendNotificationAllCustomer === true
                    ) {
                      setFieldValue("SendNotificationAllCustomer", false);
                    }
                  },
                },
                {
                  field: "SendNotificationAllCustomer",
                  label: t("Herkese Bildirim Gönder"),
                  inputType: InputType.checkbox,
                  onChange(value, item, setFieldValue, values) {
                    if (
                      value === true &&
                      values.SendNotificationMembers === true
                    ) {
                      setFieldValue("SendNotificationMembers", false);
                    }
                  },
                },
              ]}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateCampaignPage;
