import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createPackage } from "src/store/packages/saga";
import * as Yup from "yup";

const CreatePackage = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Package"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PackageName: "",
          Description: "",
          MonthlyPrice: "",
          YearlyPrice: "",
          CampaignMonthlyPrice: 0,
          CampaignYearlyPrice: 0,
          CampaignEndDate: "",
          CampaignStartDate: "",
          TaxRate: 20,
          UseDate: false,
          DontUseCoupon: false,
          UsageCount: 0,
          IsMobile: false,
          System: false,
          Active: false,
          ImageFile: "",
        }}
        onSubmit={values => {
          dispatch(
            createPackage({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PackageName: Yup.mixed().required(t("Required")),
          MonthlyPrice: Yup.number().required(t("Required")),
          YearlyPrice: Yup.number().required(t("Required")),
          Description: Yup.string().required(t("Required")),
          TaxRate: Yup.number().required(t("Required")),
          CampaignStartDate: Yup.string().when(
            ["CampaignMonthlyPrice", "CampaignYearlyPrice"],
            {
              is: (m: any, y: any) =>
                (y !== "" && y > 0) || (m !== "" && m > 0),
              then: Yup.string().required(t("Required")),
            }
          ),
          CampaignEndDate: Yup.string().when(
            ["CampaignMonthlyPrice", "CampaignYearlyPrice"],
            {
              is: (m: any, y: any) =>
                (y !== "" && y > 0) || (m !== "" && m > 0),
              then: Yup.string().required(t("Required")),
            }
          ),
        })}
        inputs={[
          {
            field: "PackageName",
            label: t("Package Name"),
          },
          {
            field: "Description",
            label: t("Description"),
            inputType: InputType.multilinetext,
          },

          {
            field: "MonthlyPrice",
            label: t("Monthly Price"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "CampaignMonthlyPrice",
            label: t("Kampanyalı Aylık Fiyat"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "YearlyPrice",
            label: t("Yearly Price"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "CampaignYearlyPrice",
            label: t("Kampanyalı Yıllık Fiyat"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "CampaignStartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "CampaignEndDate",
            label: t("End Date"),
            inputType: InputType.datetime,
          },
          {
            field: "TaxRate",
            label: t("Vergi Oranı"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "UsageCount",
            label: t("Kullanım Sayısı"),
            col: 6,
            inputType: InputType.number,
          },

          {
            field: "DontUseCoupon",
            label: t("Kupon Kullanılamaz"),
            uncheckedLabel: t("Pasive"),
            col: 6,
            inputType: InputType.checkbox,
          },

          {
            field: "IsMobile",
            label: t("IsMobile"),
            col: 3,
            inputType: InputType.checkbox,
          },
          {
            field: "System",
            label: t("System"),
            col: 3,
            inputType: InputType.checkbox,
          },
          {
            field: "UseDate",
            label: t("Kampanya için Tarihi Baz Al"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "Active",
            label: t("Active"),
            checkedLabel: t("Active"),
            uncheckedLabel: t("Active"),
            inputType: InputType.toogle,
          },
          {
            field: "ImageFile",
            label: t("ImageFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePackage;
