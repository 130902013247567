export class PermissionConsts {
  static DefaultField = ".Default";
  static EditField = ".Edit";
  static DeleteField = ".Delete";
  static CreateField = ".Create";

  static Sliders = "PR.P.Sli";
  static SuppliersMain = "PR.P.SuppMain";
  static Suppliers = "PR.P.Sup";
  static SupplierTypes = "PR.P.SupType";
  static Campaigns = "PR.P.Cmp";
  static PrivilegesMain = "PR.P.PrivMain";
  static Privileges = "PR.P.Pri";
  static InvoicePrivileges = "PR.P.InvPriv";
  static NotinvoicePrivileges = "PR.P.NotInvPriv";
  static Categories = "PR.P.Cat";
  static TiresMain = "PR.P.TirMain";
  static TireSales = "PR.P.TirSales";
  static TireSubMain = "PR.P.TirDefMain";
  static Tires = "PR.P.Tir";
  static TireSuppliers = "PR.P.TirSup";
  static TireServices = "PR.P.TirServ";
  static TireDefinitions = "PR.P.TirDefs";
  static ProductMain = "PR.P.ProdMain";
  static ProductSales = "PR.P.ProdSales";
  static Products = "PR.P.Prod";
  static ProductBanners = "PR.P.ProdBan";
  static ProductDefinitions = "PR.P.ProdDefs";
  static Coupons = "PR.P.Cou";
  static Packages = "PR.P.Pack";
  static PackageMain = "PR.P.PackMain";
  static InvoicePackages = "PR.P.InvPacks";
  static NotinvoicePackages = "PR.P.NotInvPacks";
  static CustomersMain = "PR.P.CustMain";
  static Customers = "PR.P.Cus";
  static CustomersForCustomerService = "PR.P.CustServ";
  static PurchasedCustomers = "PR.P.PurCust";
  static CancelRequest = "PR.P.CanReq";
  static CancelCustomer = "PR.P.CanCust";
  static CustomerCallLog = "PR.P.CustCallLog";
  static CustomerDefinitions = "PR.P.CustDefs";
  static FinanceMain = "PR.P.FinMain";
  static ReportMain = "PR.P.RepMain";
  static PrivilegePoint = "PR.P.PrivPoint";
  static Blacklists = "PR.P.Blck";
  static SmsLogs = "PR.P.SLog";
  static EmailLogs = "PR.P.ELog";
  static WebsiteMain = "PR.P.WebMain";
  static FaqsMain = "PR.P.FaqsMain";
  static Databank = "PR.P.Data";
  static TicketsMain = "PR.P.TickMain";
  static Tickets = "PR.P.Tickets";
  static TicketSubjects = "PR.P.TickSubs";
  static DefinitionMain = "PR.P.DefMain";
  static TicketChannels = "PR.P.TickChans";
  static Notifications = "PR.P.Notifs";
  static Settings = "PR.P.Set";
  static Maintenances = "PR.P.Main";
  static MaintenanceDefinitions = "PR.P.MaintDefs";
}
