import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { InvoicePrivilegeDto } from "src/store/privilege-invoices/type";

const InvoiceDetailCard = ({
  selectedData,
}: {
  selectedData: InvoicePrivilegeDto;
}) => {
  const { t } = useTranslation();
  const table: { text: string; value: any }[] = [
    {
      text: t("Invoice Number"),
      value: selectedData.InvoiceNumber ?? "-",
    },
    {
      text: t("Identity Number"),
      value: selectedData.IdentityNumber,
    },
    {
      text: t("Name"),
      value: selectedData.Name,
    },
    {
      text: t("Surname"),
      value: selectedData.Surname,
    },
    {
      text: t("Address"),
      value: selectedData.Address,
    },
    {
      text: t("Privilege Name"),
      value: selectedData.PrivilegeName,
    },
    {
      text: t("BasePrice"),
      value: selectedData.BasePrice,
    },
    {
      text: t("Tax"),
      value: selectedData.Tax,
    },
    {
      text: t("Price"),
      value: selectedData.Price,
    },
  ];
  return (
    <Row style={{ marginInline: "10px" }}>
      <Col md={12}>
        <Card>
          <CardHeader>Fatura Bilgileri</CardHeader>
          <CardBody>
            <Table className="mb-0 table-borderless">
              {table.map((x, i) => (
                <tr key={i}>
                  <th>{x.text}</th>
                  <td>{x.value}</td>
                </tr>
              ))}
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
export default InvoiceDetailCard;
