import React, { useRef } from "react";
import { DataTableWithPaginationProps } from "./type";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import DataTableWithPagination from "./DataGrid";
import DataTableWithOutPagination from "./DataGridDefaultPage";
import DevextGrid, { DataGridProps } from "./DevextGrid";

const MainPage = ({
  gridProps,
  title,
  hidebreadcrumb,
  withoutPag,
  useDevGrid = false,
  devProps,
}: {
  devProps?: DataGridProps;
  useDevGrid?: boolean;
  withoutPag?: boolean;
  hidebreadcrumb?: boolean;
  title?: string;
  gridProps?: DataTableWithPaginationProps;
}) => {
  const tableRef = useRef<any>(null);
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{title}</title>
        </MetaTags>
        <Container fluid>
          {useDevGrid && devProps ? (
            <React.Fragment>
              <div
                className={
                  "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
                }
              >
                {title && <h5 className="mb-0 font-size-18">{title}</h5>}
              </div>
              <DevextGrid ref={tableRef} {...devProps} title={title} />
            </React.Fragment>
          ) : (
            <>
              {gridProps && (
                <>
                  {withoutPag ? (
                    <DataTableWithOutPagination {...gridProps} title={title} />
                  ) : (
                    <DataTableWithPagination {...gridProps} title={title} />
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};
export default MainPage;
