import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updatePlannedNotification } from "src/store/notification-planned/saga";
import {
  NotificationActionDto,
  NotificationAudienceDto,
  NotificationPeriodDto,
} from "src/store/notification-actions/type";
import * as Yup from "yup";
import {
  getNotificationActionListByAudienceId,
  getNotificationAudiences,
  getNotificationPeriods,
} from "src/store/notification-actions/saga";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { PlannedNotificationDto } from "src/store/notification-planned/type";

const EditPlannedNotifications = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: PlannedNotificationDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actions, setActions] = useState<NotificationActionDto[]>([]);
  const [audiences, setAudiences] = useState<NotificationAudienceDto[]>([]);
  const [periods, setPeriods] = useState<NotificationPeriodDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getNotificationAudiences({
          payload: {
            onSuccess: (msg, py) => setAudiences(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getNotificationPeriods({
          payload: {
            onSuccess: (msg, py) => setPeriods(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getNotificationActionListByAudienceId({
          payload: {
            onSuccess: (msg, py) => setActions(py),
            onError: () => {},
          },
          id: data.NotificationActionAudienceId,
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Yeni Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          NotificationActionId: data.NotificationActionId,
          NotificationAudienceId: data.NotificationActionAudienceId,
          PeriodId: data.PeriodId ?? "",
          PeriodValue: data.PeriodValue ?? "",
          IsAfter: data.IsAfter,
          PrivilegeId: data.PrivilegeId ?? "",
          ImageFile: "",
          Title: data.Title ?? "",
          Context: data.Context ?? "",
          Description: data.Description ?? "",
          Sms: data.Sms,
          Push: data.Push,
          Status: data.Status,
          CurrentImageFile: data.Image ?? "",
          Email: data.Email ?? false,
          EmailContent: data.EmailContent ?? "",
          SmsContent: data.SmsContent ?? "",
        }}
        onSubmit={values => {
          dispatch(
            updatePlannedNotification({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          NotificationAudienceId: Yup.number()
            .typeError(t("Required"))
            .required(t("Required")),
          Title: Yup.string().required(t("Required")),
          NotificationActionId: Yup.string().required(t("Required")),
          PeriodId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "NotificationAudienceId",
            inputType: InputType.multiselect,
            label: t("Hedef Kitle"),
            lookup: {
              data: audiences,
              labelKey: "Name",
              valueKey: "EnumId",
            },
            onChange(value, item, setFieldValue, values) {
              if (value !== values.NotificationAudienceId) {
                setFieldValue("NotificationActionId", "");
              }
              dispatch(
                getNotificationActionListByAudienceId({
                  payload: {
                    onSuccess: (msg, py) => setActions(py),
                    onError: () => {},
                  },
                  id: value,
                })
              );
            },
          },
          {
            field: "NotificationActionId",
            inputType: InputType.multiselect,
            label: t("Hedef Kitle Aksiyon"),
            lookup: {
              data: actions,
              labelKey: "Action",
              valueKey: "Id",
            },
          },
          {
            col: 6,
            field: "PeriodId",
            inputType: InputType.multiselect,
            label: t("Periyot"),
            lookup: {
              data: periods,
              labelKey: "Name",
              valueKey: "EnumId",
            },
          },
          {
            col: 6,
            label: "Periyot Değeri",
            field: t("PeriodValue"),
            inputType: InputType.number,
          },
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Hizmet"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
            },
          },
          {
            field: "Title",
            label: t("Title"),
          },
          {
            field: "Context",
            label: t("Context"),
          },
          {
            field: "IsAfter",
            label: t("Is After"),
            inputType: InputType.checkbox,
          },
          {
            field: "Push",
            label: t("Push"),
            inputType: InputType.checkbox,
          },
          {
            field: "Sms",
            label: t("Sms"),
            inputType: InputType.checkbox,
          },
          {
            field: "SmsContent",
            label: t("Sms İçeriği"),
            inputType: InputType.multilinetext,
          },
          {
            field: "Email",
            label: t("Email"),
            inputType: InputType.checkbox,
          },
          {
            field: "EmailContent",
            label: t("E-posta İçeriği"),
            inputType: InputType.editor,
          },
          {
            field: "ImageFile",
            label: t("Resim"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentImageFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditPlannedNotifications;
