import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import DrageAndDropTable from "src/components/DraggableTable";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import {
  deletePackagePrivilege,
  getPackagePrivilegeDetails,
  getPackagePrivilegeList,
} from "src/store/packages/saga";
import { PackageDetailDto, PackagePrivilegeDto } from "src/store/packages/type";
import { changePackagePrivilegeOrder } from "src/store/privilege/saga";
import CreatePrivilegeModal from "./createprivilegemodal";
import EditPrivilegeModal from "./editprivilegemodal";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";

const PackagePrivilegeTab = ({
  packageData,
  setData,
}: {
  setData: (pack: PackageDetailDto) => void;
  packageData: PackageDetailDto;
}) => {
  const dispatch = useDispatch();
  const { tab }: { tab: string } = useParams();
  const [showDelete, setShowDelete] = useState<PackagePrivilegeDto>();
  const { t } = useTranslation();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<PackagePrivilegeDto>();
  const [showCreate, setShowCreate] = useState(false);
  const user = useSelector(userSelector);
  const [privileges, setPrivileges] = useState<PackagePrivilegeDto[]>([]);
  useEffect(() => {
    tab === "2" &&
      dispatch(
        getPackagePrivilegeList({
          payload: {
            onSuccess: (msg, payload) => setPrivileges(payload),
            onError: () => {},
          },
          id: packageData.Id,
        })
      );
  }, [dispatch, packageData.Id, tab]);

  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getPackagePrivilegeList({
        payload: {
          onSuccess: (msg, payload) => setPrivileges(payload),
          onError: () => {},
        },
        id: packageData.Id,
      })
    );
  };
  const columns = [
    {
      text: t("Order"),
      dataField: "Order",
    },
    {
      text: t("Mobilde Görünecek Ad"),
      dataField: "PackageName",
    },

    {
      text: t("Count"),
      dataField: "Count",
    },
    {
      text: t("Aylık İptal Sonrası Kullanılabilirliği"),
      dataField: "CanBeUseAfterMonthlyCancel",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Yıllık İptal Sonrası Kullanılabilirliği"),
      dataField: "CanBeUseAfterYearlyCancel",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: t("Ödemeye Dahil"),
      dataField: "TakePayment",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) =>
        CheckRole(user) ? (
          <div className="d-flex w-30 justify-content-end gap-1">
            <ColumnButton
              buttonType="edit"
              onClick={() => {
                dispatch(
                  getPackagePrivilegeDetails({
                    payload: {
                      onSuccess: (message, payload) => {
                        setSelected(payload);
                        return setShowEdit(true);
                      },
                      onError: () => {},
                    },
                    id: cellContent,
                  })
                );
              }}
            />
            <ColumnButton
              buttonType="delete"
              onClick={() => setShowDelete(data)}
            />
          </div>
        ) : (
          <></>
        ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        {selected && (
          <EditPrivilegeModal
            data={selected}
            onHide={() => setShowEdit(false)}
            open={showEdit}
            onSuccessClick={onSuccessClick}
          />
        )}
        <CreatePrivilegeModal
          selectedPackage={packageData}
          onHide={() => setShowCreate(false)}
          open={showCreate}
          onSuccessClick={onSuccessClick}
        />
        <DrageAndDropTable
          columns={columns}
          addTitle={t("New Privilege")}
          create={{
            createType: "drawer",
            onCreateClick: () => setShowCreate(true),
          }}
          data={privileges}
          dragAction={changePackagePrivilegeOrder}
          onSuccessClick={onSuccessClick}
        />
      </div>
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete package privilege`, {
            name: `${showDelete.PackageName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deletePackagePrivilege({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};
export default PackagePrivilegeTab;
