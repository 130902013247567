import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { FaqsDto } from "./type";
import { BodyType } from "../base/request/request";

export const getFaqs = createAction(
  "GET_FAQS",
  ({ payload, params }: SagaActionParams<FaqsDto[]>) => ({
    payload,
    url: "/api/faqs" + (params ? params : ""),
  })
);
export const getFaqsByCategoryId = createAction(
  "GET_FAQS_CATEGORY_ID",
  ({ payload, id }: SagaActionParams<FaqsDto[]>) => ({
    payload,
    url: "/api/faqs/bycategoryId/" + id,
  })
);
export const getFaqsDetails = createAction(
  "GET_FAQS_DETAILS",
  ({ payload, id }: SagaActionParams<FaqsDto>) => ({
    payload,
    url: "/api/faqs/" + id,
  })
);
export const createFaqs = createAction(
  "CREATE_FAQS",
  ({ payload, body }: SagaActionParams<FaqsDto>) => ({
    payload,
    url: "/api/faqs",
    body,
  })
);
export const updateFaqs = createAction(
  "UPDATE_FAQS",
  ({ payload, id, body }: SagaActionParams<FaqsDto>) => ({
    payload,
    url: "/api/faqs/" + id,
    body,
  })
);
export const changeOrderFaqs = createAction(
  "CHANGE_ORDER_FAQS",
  ({ payload, body }: SagaActionParams<FaqsDto>) => ({
    payload,
    url: "/api/faqs/order",

    bodyType: BodyType.raw,
    body,
  })
);
export const deleteFaqs = createAction(
  "DELETE_FAQS",
  ({ payload, id }: SagaActionParams<FaqsDto>) => ({
    payload,
    url: "/api/faqs/" + id,
  })
);

const faqsSaga = [
  /*FAQS*/
  takeLatest(getFaqs.toString(), (payload: SagaGenericParams<FaqsDto[]>) =>
    getListSaga<FaqsDto[]>(payload)
  ),
  takeLatest(
    getFaqsByCategoryId.toString(),
    (payload: SagaGenericParams<FaqsDto[]>) => getListSaga<FaqsDto[]>(payload)
  ),
  takeLatest(getFaqsDetails.toString(), (payload: SagaGenericParams<FaqsDto>) =>
    getListSaga<FaqsDto>(payload)
  ),
  takeLatest(createFaqs.toString(), (payload: PostSagaGenericParams<FaqsDto>) =>
    postSaga<FaqsDto>(payload)
  ),
  takeLatest(updateFaqs.toString(), (payload: PostSagaGenericParams<FaqsDto>) =>
    putSaga<FaqsDto>(payload)
  ),
  takeLatest(
    changeOrderFaqs.toString(),
    (payload: PostSagaGenericParams<FaqsDto>) => putSaga<FaqsDto>(payload)
  ),
  takeLatest(deleteFaqs.toString(), (payload: SagaGenericParams<any>) =>
    deleteSaga<FaqsDto>(payload)
  ),
];

export default faqsSaga;
