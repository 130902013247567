import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alert, Button, Col, Row } from "reactstrap";
import Loader from "src/components/Loader";
import CustomModal from "src/components/Modal/modal";
import {
  CustomerCreditCardDto,
  CustomerDetailDto,
} from "src/store/customers/type";
import * as Yup from "yup";
import "./style.scss";
import { payCustomerPackagePayment } from "src/store/black-lists/saga";
import { getCustomerCreditCardList } from "src/store/customers/saga";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";

const PayCustomerPackage = ({
  customerData,
  onSuccessSubmit,
}: {
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const [loader, setLoader] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [creditCards, setCreditCards] = useState<CustomerCreditCardDto[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (showCancel) {
      setLoader(true);
      dispatch(
        getCustomerCreditCardList({
          payload: {
            onSuccess: (message, payload) => {
              setCreditCards(payload);
              setLoader(false);
            },
            onError: () => {},
          },
          id: customerData.Id,
        })
      );
    }
  }, [showCancel, dispatch, customerData.Id]);

  const _renderMenuItem = (
    label: string,
    item: CustomerCreditCardDto,
    selected?: any
  ) => {
    if (item) {
      return `**** **** **** ${label} / ${item.CardName}`;
    } else return `**** **** **** ${label}`;
  };
  const _renderValue = (label: any, item: any, s: CustomerCreditCardDto) => {
    if (item) {
      return `**** ${item.CardNumberLastFour} / ${item.CardName}`;
    } else return label;
  };
  return (
    <React.Fragment>
      {customerData.StatusId === 2 && (
        <button
          className="btn btn-soft-danger btn-sm  "
          onClick={() => setShowCancel(true)}
        >
          ÜYELİK ASKIDA ÖDEMEYİ TAHSİL ET
        </button>
      )}
      <CustomModal
        size="xs"
        open={showCancel}
        onHide={() => setShowCancel(false)}
        title={"Askıda Üye Paket Ödemesi"}
      >
        {loader && <Loader />}
        {!loader && (
          <Formik
            initialValues={{
              CustomerId: customerData.Id,
              CustomerCreditCardId: "",
            }}
            validationSchema={Yup.object().shape({
              CustomerId: Yup.string().required(t("Required")),
              CustomerCreditCardId: Yup.string().required(t("Required")),
            })}
            onSubmit={values => {
              dispatch(
                payCustomerPackagePayment({
                  payload: {
                    onSuccess: message => {
                      setShowCancel(false);
                      toast.success(message);
                      onSuccessSubmit();
                    },
                    onError: message => {
                      setErrorMessage(message);
                    },
                  },
                  body: values,
                })
              );
            }}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Row className="py-2">
                {errorMessage && (
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {errorMessage}
                  </Alert>
                )}
                <Col md={12}>
                  <GetInput
                    inputprop={{
                      field: "CustomerCreditCardId",
                      inputType: InputType.multiselect,
                      label: t("Kredi Kartı"),
                      lookup: {
                        data: creditCards,
                        labelKey: "CardNumberLastFour",
                        valueKey: "Id",
                        placeholder: t("Kredi Kartı Seçiniz"),
                        renderValue: (label: any, item: any, s: any) =>
                          _renderValue(label, item, s),
                        renderMenuItem: (label: any, item: any) =>
                          _renderMenuItem(label, item),
                      },
                      onChange: () => setErrorMessage(""),
                    }}
                  />
                </Col>
                <div
                  className={
                    "d-flex justify-content-between mt-3 gap-2 flex-wrap"
                  }
                >
                  <Button
                    color="danger"
                    type="submit"
                    style={{ width: 150 }}
                    onClick={() => setShowCancel(false)}
                  >
                    {t("Kapat")}
                  </Button>
                  <Button
                    color="success"
                    type="submit"
                    style={{ width: 150 }}
                    onClick={() => handleSubmit()}
                  >
                    {t("Tahsil Et")}
                  </Button>
                </div>
              </Row>
            )}
          </Formik>
        )}
      </CustomModal>
    </React.Fragment>
  );
};
export default PayCustomerPackage;
