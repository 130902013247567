export interface TicketDto {
  CustomerId: string;
  PhoneNumber: string;
  Name: string;
  Surname: string;
  Code: string;
  TicketNumber: string;
  Subject: string;
  SubjectTypeName: string;
  SubjectTypeId: number;
  SupplierName: string;
  IsActive: boolean;
  HasFile: boolean;
  EntityId?: any;
  TicketTypeId: number;
  TicketStatusTypeId: number;
  TicketCustomer: TicketCustomerListDto;
  TicketStatusType: string;
  TicketType: string;
  TicketStatusTypeName: string;
  TicketTypeName: string;
  Id: string;
  UserName?: any;
  CreateTime: string;
  LastTicketAnswer?: string;
  TicketLevelId?: number;
  ResponsibleUserFullName?: string;
  CurrentLocationTownId?: string;
  CurrentLocationAddress?: string;
}

export interface TicketType {
  Id: number;
  Code: string;
  Name: string;
}
export interface TicketStatusDto {
  EnumId: number;
  Code: string;
  Name: string;
  Count: number;
}
export interface TicketDetailDto {
  VehicleId?: any;
  ResponsibleUserId: string;
  ResponsibleUserFullName: string;
  ResponsibleUserEmail: string;
  ResponsibleUserPhoneNumber: string;
  Plate?: any;
  Make?: any;
  Model?: any;
  CustomerId: string;
  TicketSourceTypeId: number;
  TicketSourceTypeName: string;
  PhoneNumber?: any;
  Name?: any;
  Surname?: any;
  Code: string;
  SupplierId?: any;
  SupplierName?: any;
  SupplierPersonName: string;
  SupplierPhoneNumber?: any;
  Point: number;
  Id: string;
  TicketTypeId: number;
  TicketNumber: string;
  SubjectId: string;
  Subject: string;
  EntityId?: any;
  TicketStatusTypeId: number;
  IsActive: boolean;
  UserName: string;
  CreateTime: string;
  TicketLevelId?: any;
  TicketLevelName?: any;
  TicketChannelId?: any;
  TicketChannelName?: any;
  TicketStatusType: string;
  TicketType: string;
  TicketAnswers: TicketAnswerDto[];
  Vehicle?: any;
  TicketCustomer: TicketCustomerDto;
  CurrentLocationTownId?: string;
  CurrentLocationAddress?: string;
  CurrentLocationCityTown?: string;
}

export interface TicketAnswerDto {
  Message: string;
  CustomerId?: string;
  Documents: string[];
  Images: string[];
  CreateTime: string;
  Id: string;
  CreatedUserId: string;
  CreatedUserName: string;
}
export enum TicketStatusEnum {
  Yeni = 1,
  Islemde = 2,
  Cozuldu = 3,
  Kapali = 4,
  Atandi = 5,
  Gecersiz = 6,
}
export enum TicketSubjectTypeEnum {
  TeknikDestek = 1,
  Kaza = 2,
  HukukiDestek = 3,
  GeriBildirim = 4,
}
export interface TicketAnswerDocumentDto {
  Id: string;
  DocumentLocation: string;
  CreateTime: string;
  TicketAnswerDocumentTypeId: number;
}
export interface TicketHistoryDto {
  Id: string;
  TicketId: string;
  RecordedUserId: string;
  RecorderUserName: string;
  AssignedUserId: string;
  AssignedUserName: string;
  CurrentTicketStatusTypeId: number;
  CurrentTicketStatusTypeName: string;
  TicketStatusTypeId: number;
  TicketStatusTypeName: string;
  Number: number;
  Date: string;
  Description: string;
}
export enum TicketAnswerDocumentTypeEnum {
  Document = 1,
  Resim = 2,
}
export enum TicketSourceTypeEnum {
  Mobil = 1,
  CallLog = 2,
}
export interface TicketLevelDto {
  EnumId: number;
  Code: string;
  Name: string;
  Count: number;
}
export interface TicketChannelDto {
  Id: string;
  Name: string;
}
interface TicketCustomerDto {
  Name: string;
  Surname: string;
  PhoneNumber: string;
  PhoneNumber2?: any;
  VehiclePlate?: any;
  Brand?: any;
  Model?: any;
  ModelYear?: any;
  KmLimit?: any;
  Address: string;
  TownId: string;
  CityId?: any;
  TownName: string;
  CityName: string;
}
interface TicketCustomerListDto {
  Name: string;
  Surname: string;
  PhoneNumber: string;
}

export enum TicketLevelEnum {
  Normal = 1,
  Onemli = 2,
  CokOnemli = 3,
}
