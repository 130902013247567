import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { RoleDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, postSaga, putSaga } from "../base/request/post";
import { deleteSaga } from "../base/request/delete";

export const getRoleListSaga = createAction(
  "GET_ROLE_LIST",
  ({ payload, params }: SagaActionParams<RoleDto[]>) => ({
    payload,
    url: "/api/adminroles" + (params ?? ""),
  })
);
export const getRoleDetailSaga = createAction(
  "GET_ROLE_DETAILS",
  ({ payload, id }: SagaActionParams<RoleDto>) => ({
    payload,
    url: "/api/adminroles/" + id,
  })
);

export const createRoleSaga = createAction(
  "CREATE_ROLE_SAGA",
  ({ payload, body }: SagaActionParams<RoleDto>) => ({
    payload,
    url: "/api/adminroles",
    body,
  })
);
export const updateRoleSaga = createAction(
  "UPDATE_ROLE_SAGA",
  ({ payload, body, id }: SagaActionParams<RoleDto>) => ({
    payload,
    url: "/api/adminroles/" + id,
    body,
  })
);
export const deleteRoleSaga = createAction(
  "DELETE_ROLE_SAGA",
  ({ payload, id }: SagaActionParams<RoleDto>) => ({
    payload,
    url: "/api/adminroles/" + id,
  })
);
const roleSaga = [
  takeLatest<any>(
    getRoleListSaga.toString(),
    (payload: SagaGenericParams<RoleDto[]>) => getListSaga<RoleDto[]>(payload)
  ),
  takeLatest<any>(
    getRoleDetailSaga.toString(),
    (payload: SagaGenericParams<RoleDto>) => getListSaga<RoleDto>(payload)
  ),
  takeLatest<any>(
    createRoleSaga.toString(),
    (payload: PostSagaGenericParams<RoleDto>) => postSaga<RoleDto>(payload)
  ),
  takeLatest<any>(
    updateRoleSaga.toString(),
    (payload: PostSagaGenericParams<RoleDto>) => putSaga<RoleDto>(payload)
  ),
  takeLatest<any>(
    deleteRoleSaga.toString(),
    (payload: SagaGenericParams<RoleDto>) => deleteSaga<RoleDto>(payload)
  ),
];

export default roleSaga;
