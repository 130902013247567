import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { importExcelToPrivilegeCard } from "src/store/privilegecard/saga";
import * as Yup from "yup";

const ImportExcel = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          File: "",
        }}
        onSubmit={values => {
          dispatch(
            importExcelToPrivilegeCard({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          File: Yup.mixed().required(t("Required")),
        })}
        inputs={[
          {
            field: "File",
            label: t("Excel Dosyası"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ".xls,.xlsx",
              multiple: false,
              title: t("Excel Dosyası Yükleyiniz"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default ImportExcel;
