import React from "react";
import { Table } from "reactstrap";
import CustomModal from "src/components/Modal/modal";
import { getLocalDate } from "src/helpers/getLocalDate";
import { SmsStatusResponse } from "src/store/system-logs/type";

const SmsStatusModal = ({
  onHide,
  open,
  data,
}: {
  data: SmsStatusResponse;
  onHide: () => void;
  open: boolean;
}) => {
  return (
    <React.Fragment>
      <CustomModal size="md" open={open} onHide={onHide} title={" "}>
        <div className="fw-bold mb-1">
          Sms Gönderim Durumu : {data.Response.Status.Code}(
          {data.Response.Status.Description})
        </div>
        <Table className="table mb-0 table-bordered ">
          <thead className="custom-table-header-colored table-light">
            <tr>
              <th>Id</th>
              <th>Telefon</th>
              <th>Hata Kodu</th>
              <th>Payload</th>
              <th>Durum</th>
              <th>Gönderilme Zamanı</th>
            </tr>
          </thead>
          <tbody>
            {data.Response.ReportDetail.List.map((x, i) => (
              <tr key={x.MSISDN + i}>
                <td>{x.Id}</td>
                <td>{x.MSISDN}</td>
                <td>{x.ErrorCode}</td>
                <td>{x.Payload}</td>
                <td>{x.State}</td>
                <td>{getLocalDate(x.Submitted)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CustomModal>
    </React.Fragment>
  );
};
export default SmsStatusModal;
