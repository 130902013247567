import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createCoupon, getCouponGroups } from "src/store/coupons/saga";
import { CouponGroupDto } from "src/store/coupons/type";
import {
  getCouponValidityTypes,
  getDiscountTypeLookupList,
} from "src/store/lookup/saga";
import { DiscountTypeDto, EnumType } from "src/store/lookup/type";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";

const CreateCoupon = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState<PackageDto[]>([]);

  const [groups, setGroups] = useState<CouponGroupDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const [types, setTypes] = useState<EnumType[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getDiscountTypeLookupList({
          payload: {
            onSuccess: (msg, payload) => setDiscountTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCouponGroups({
          payload: {
            onSuccess: (msg, payload) => setGroups(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getCouponValidityTypes({
          payload: {
            onSuccess: (msg, payload) => setTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPackageList({
          payload: {
            onSuccess: (msg, payload) =>
              setPackages(payload.filter(x => !x.System)),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Coupon"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Name: "",
          Description: "",
          Code: "",
          MinAmount: "",
          MaxAmount: "",
          StartDate: "",
          EndDate: "",
          Package: false,
          PackageDiscountRate: "",
          Privilege: false,
          PrivilegeDiscountRate: "",
          Limited: false,
          LimitCount: "",
          Active: false,
          CouponIncludedPackages: [],
          IncludedGift: false,
          CouponValidityDuration: "",
          CouponGroupId: "",
        }}
        onSubmit={values => {
          dispatch(
            createCoupon({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: {
                ...values,
                CouponIncludedPackages: values.CouponIncludedPackages.map(
                  (x: string) => {
                    return { PackageId: x };
                  }
                ),
              },
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          Description: Yup.string().required(t("Required")),
          Code: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "CouponGroupId",
            inputType: InputType.multiselect,
            label: t("Grup"),
            lookup: {
              data: groups,
              labelKey: "Name",
              valueKey: "Id",
            },
          },
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Code",
            label: t("Code"),
            col: 6,
          },

          {
            field: "Description",
            label: t("Description"),
            inputType: InputType.multilinetext,
          },
          {
            field: "StartDate",
            label: t("Start Date"),
            inputType: InputType.datetime,
          },
          {
            field: "EndDate",
            label: t("End Date"),

            inputType: InputType.datetime,
          },
          {
            field: "MinAmount",
            label: t("Min Amount"),
            inputType: InputType.number,
            col: 6,
          },
          {
            field: "MaxAmount",
            label: t("Max Amount"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "DiscountTypeId",
            inputType: InputType.multiselect,
            label: t("Discount Type"),
            lookup: {
              data: discountTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Discount Type"),
            },
          },
          {
            field: "Package",
            label: t("Package"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "PackageDiscountRate",
            label: t("Package Discount Rate"),
            col: 6,
            inputType: InputType.number,
          },
          {
            hide: values => !values.Package,
            field: "CouponIncludedPackages",
            inputType: InputType.checkpicker,
            label: t("Dahili Paketler"),
            lookup: {
              data: packages,
              labelKey: "PackageName",
              valueKey: "Id",
              placeholder: "Paket Seçiniz",
            },
          },
          {
            field: "CouponValidityDuration",
            label: t("Kupon Kaç Ay Geçerli?"),
            col: 12,
            inputType: InputType.number,
          },
          {
            field: "CouponValidityTypeId",
            inputType: InputType.multiselect,
            label: t("Aylık/Yıllık Seçimi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Discount Type"),
            },
          },
          {
            field: "Privilege",
            label: t("Privilege"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "PrivilegeDiscountRate",
            label: t("Privilege Discount Rate"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "Limited",
            label: t("Limited"),
            col: 6,
            inputType: InputType.checkbox,
          },
          {
            field: "LimitCount",
            label: t("Limit Count"),
            col: 6,
            inputType: InputType.number,
          },
          {
            field: "IncludedGift",
            label: t("Hediyeleri Dahil Et"),
            inputType: InputType.checkbox,
          },
          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCoupon;
