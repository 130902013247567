export enum PrivilegeUsageTypeEnum {
  Kupon = 1,
  SabitKod = 2,
  QR = 3,
  Link = 4,
  TeknikDestek = 5,
  HukukiDestek = 6,
  TelefonAramasi = 7,
  Eylemsiz = 8,
  Kart = 9,
}
export enum DiscountValidityPeriodEnum {
  Süresiz = 1,
  Süreli = 2,
}
