import CustomerAddressTab from "./Address/address";
import CustomerVehiclesTab from "./Vehicles/vehicles";
import PrivilegeTab from "./Privileges";
import UsedPrivilegeTab from "./UsedPrivileges";
import TicketsTab from "./Tickets";
import PurchasedPackages from "./PurchasedPackages";
import PurchasedPrivileges from "./PurchasedPrivileges";
import CustomerDiscountTab from "./Discounts/discounts";
import CustomerFavoritesTab from "./Favorites/favorites";
import CustomerCreditCardTab from "./CreditCards/creditCards";

interface NavMenuItemProps {
  component: any;
  eventKey: string;
  text: string;
}
export const navMenuList: NavMenuItemProps[] = [
  {
    text: "Çağrı-Talep",
    eventKey: "1",
    component: <TicketsTab />,
  },
  {
    text: "Araçlar",
    eventKey: "2",
    component: <CustomerVehiclesTab />,
  },
  {
    text: "Satın Alınan Paketler",
    eventKey: "3",
    component: <PurchasedPackages />,
  },
  {
    text: "Satın Alınan Hizmetler",
    eventKey: "4",
    component: <PurchasedPrivileges />,
  },
  {
    text: "Hizmet",
    eventKey: "5",
    component: <PrivilegeTab />,
  },
  {
    text: "Kullanılan Hizmetler",
    eventKey: "6",
    component: <UsedPrivilegeTab />,
  },

  {
    text: "Çağrılar-2",
    eventKey: "8",
    component: <></>,
  },
  {
    text: "Tanımlı İndirimler",
    eventKey: "9",
    component: <CustomerDiscountTab />,
  },
  {
    text: "Adresler",
    eventKey: "10",
    component: <CustomerAddressTab />,
  },
  {
    text: "Kartlar",
    eventKey: "11",
    component: <CustomerCreditCardTab />,
  },
  {
    text: "Favoriler",
    eventKey: "12",
    component: <CustomerFavoritesTab />,
  },
];
