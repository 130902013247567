import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InvoicePackageForm from "./edit";
import { toast } from "react-toastify";
import InvoicePackageDetailModal from "./detail";
import { useDispatch, useSelector } from "react-redux";
import { InvoicePrivilegeDto } from "src/store/privilege-invoices/type";
import { invoicePrivilegeWithAccountment } from "src/store/privilege-invoices/saga";
import DevextGrid from "src/components/PageContent/DevextGrid";
import { Button, DataGrid } from "devextreme-react";
import MetaTags from "react-meta-tags";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Item } from "devextreme-react/data-grid";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import CustomDatePicker from "src/components/Form/DatePicker";
import moment from "moment";
import ErrorDetailDialog from "src/components/ErrorDetailDialog";
export interface ModalVisible {
  customers: string[];
  show: boolean;
  key: "select" | "single";
}
const UnInvoicePrivilegePage = () => {
  const loading = useSelector(isLoading);
  const [date, setDate] = useState(new Date());

  const { t } = useTranslation();
  const [selected, setSelected] = useState<InvoicePrivilegeDto>();
  const [showManuel, setShowManuel] = useState(false);
  const [show, setShow] = useState<ModalVisible>();
  const [showDetails, setShowDetails] = useState(false);
  const [detailData, setDetailData] = useState<InvoicePrivilegeDto>();
  const dispatch = useDispatch();
  const dataGridRef = useRef<DataGrid>();
  const gridOptions: any = {
    url: "/api/invoices/GetWillBeInvoicedPrivilegeList/devexp",
    loadPanel: {
      enabled: false,
    },
    columns: [
      {
        caption: t("Identity Number"),
        dataField: "IdentityNumber",
      },
      {
        caption: t("Name"),
        dataField: "Name",
      },
      {
        caption: t("Surname"),
        dataField: "Surname",
      },
      {
        caption: t("Email"),
        dataField: "Email",
      },
      {
        caption: t("Tarih"),
        dataField: "CreateTime",
        dataType: "datetime",
      },
      {
        text: t("Privilege"),
        dataField: "PrivilegeName",
      },
      {
        caption: t("BasePrice"),
        dataField: "BasePrice",
      },
      {
        caption: t("Tax"),
        dataField: "Tax",
      },
      {
        caption: t("Price"),
        dataField: "Price",
      },
      {
        caption: t("Error"),
        dataField: "LastAccountmentLog.Error",
      },
      {
        caption: t("Hata Mesajı"),
        dataField: "LastAccountmentLog.ErrorJson",
        cellRender: (e: any) => {
          return e.data.LastAccountmentLog ? (
            <ErrorDetailDialog error={e.data.LastAccountmentLog.ErrorJson} />
          ) : (
            <></>
          );
        },
      },
      {
        caption: t("İşlem Sürecinde Mi?"),
        dataField: "IsProcess",
        dataType: "boolean",
      },
      {
        type: "buttons",
        width: 110,
        buttons: [
          {
            hint: "Detay",
            icon: "info",
            onClick: async (e: any) => {
              setDetailData(e.row.data);
              return setShowDetails(true);
            },
          },
          {
            hint: "Faturalandır",
            icon: "file",
            visible: (e: any) => !e.row.data.IsProcess,
            onClick: async (e: any) => {
              setSelected(e.row.data);
              return setShow({
                show: true,
                customers: [e.row.data.Id],
                key: "single",
              });
            },
          },
        ],
      },
    ],
  };
  const onOpenModalFunc = (setShow: any) => {
    let customers: any[] =
      dataGridRef.current?.instance.getSelectedRowKeys() ?? [];
    if (customers.length <= 0) {
      toast.error(
        "Faturalandırma için en az bir adet seçim yapmanız gerekiyor"
      );
    } else {
      setShow({
        show: true,
        customers: customers,
        key: "select",
      });
    }
  };
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
  };
  return (
    <React.Fragment>
      {selected && (
        <InvoicePackageForm
          selectedData={selected}
          onHide={() => setShowManuel(false)}
          open={showManuel}
          onSuccessClick={m => onSuccessClick(m)}
        />
      )}
      {detailData && (
        <InvoicePackageDetailModal
          selectedData={detailData}
          onHide={() => setShowDetails(false)}
          open={showDetails}
        />
      )}

      <React.Fragment>
        <div className={"page-content"}>
          <MetaTags>
            <title>{t("Uninvoiced Privileges")}</title>
          </MetaTags>
          <Container fluid>
            <React.Fragment>
              <div
                className={
                  "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
                }
              >
                <h5 className="mb-0 font-size-18">
                  {t("Uninvoiced Privileges")}
                </h5>
              </div>
              <DevextGrid
                ref={dataGridRef}
                title={t("Uninvoiced Privileges")}
                rowAlternationEnabled
                {...gridOptions}
                key={"Id"}
                gridItems={
                  <Item>
                    <div className="d-flex justify-content-start gap-2 customerGridButtons">
                      <Button
                        icon={""}
                        text={"Faturalandır"}
                        stylingMode={"contained"}
                        onClick={() => onOpenModalFunc(setShow)}
                      />
                    </div>
                  </Item>
                }
                onEditorPreparing={(e: any) => {
                  if (e.command === "select" && e.row) {
                    if (e.row.data.IsProcess) {
                      e.cancel = false;
                      e.editorOptions.disabled = true;
                    }
                  }
                }}
                onSelectionChanged={(e: any) => {
                  if (e.selectedRowsData.some((x: any) => x.IsProcess)) {
                    const keys: any[] = e.selectedRowsData
                      .filter((x: any) => x.IsProcess)
                      .map((x: any) => x.Id);
                    e.component.deselectRows(keys);
                  }
                }}
                selection={{
                  mode: "multiple",
                  allowSelectAll: true,
                  selectAllMode: "page",
                }}
              />
            </React.Fragment>
          </Container>
        </div>
      </React.Fragment>

      {show && (
        <Modal
          isOpen={show ? true : false}
          centered
          toggle={() => setShow(undefined)}
        >
          <ModalHeader toggle={() => setShow(undefined)}>
            Faturalandırma
          </ModalHeader>
          <ModalBody>
            <Row>
              {loading && <Loader />}{" "}
              <Col md={12}>
                <span> Otomatik Faturalandırma Tarih Seçimi </span>
                <CustomDatePicker
                  label={""}
                  field="date"
                  popperPlacement="bottom-start"
                  selected={date}
                  onChange={date => {
                    date && date != null && setDate(date);
                  }}
                  showTimeSelect
                  timeIntervals={60}
                  dateFormat="dd/MM/yyyy HH:mm"
                />
              </Col>
              <Col md={6}>
                {selected && show.key === "single" && (
                  <Button
                    className="w-100"
                    onClick={() => {
                      setShowManuel(true);
                      return setShow(undefined);
                    }}
                  >
                    Manuel Giriş
                  </Button>
                )}
              </Col>
              <Col md={6}>
                <Button
                  stylingMode={"contained"}
                  type={"success"}
                  className="w-100"
                  onClick={() => {
                    dispatch(
                      invoicePrivilegeWithAccountment({
                        payload: {
                          onSuccess: (message, payload) => {
                            toast.success(
                              "Faturalandırma işlemi başlatılmıştır"
                            );
                            return setShow(undefined);
                          },
                          onError: message => {
                            toast.error(message, {
                              theme: "colored",
                            });
                          },
                        },
                        body: {
                          Date: moment(date).format(),
                          CustomerPurchasedPrivileges: show.customers,
                        },
                      })
                    );
                  }}
                >
                  Otomatik
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default UnInvoicePrivilegePage;
