import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SmsStatusResponse } from "./type";

export const getSmsStatus = createAction(
  "GET_SMS_STATUS",
  ({ payload, id }: SagaActionParams<SmsStatusResponse>) => ({
    payload,
    url: "/api/systemlogs/sms-status/" + id,
  })
);

const systemLogSaga = [
  takeLatest(
    getSmsStatus.toString(),
    (payload: SagaGenericParams<SmsStatusResponse>) =>
      getListSaga<SmsStatusResponse>(payload)
  ),
];

export default systemLogSaga;
