import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerJobDto } from "./type";

export const getCustomerJobList = createAction(
  "GET_CUSTOMER_JOB_LIST",
  ({ payload, params }: SagaActionParams<CustomerJobDto[]>) => ({
    payload,
    url: "/api/customerjobs" + (params ? params : ""),
  })
);
export const getCustomerJobDetails = createAction(
  "GET_CUSTOMER_JOB_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerJobDto>) => ({
    payload,
    url: "/api/customerjobs/" + id,
  })
);
export const createCustomerJob = createAction(
  "CREATE_CUSTOMER_JOB_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerJobDto>) => ({
    payload,
    url: "/api/customerjobs",
    body,
  })
);
export const updateCustomerJob = createAction(
  "UPDATE_CUSTOMER_JOB_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerJobDto>) => ({
    payload,
    url: "/api/customerjobs/" + id,
    body,
  })
);
export const deleteCustomerJob = createAction(
  "DELETE_CUSTOMER_JOB_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerJobDto>) => ({
    payload,
    url: "/api/customerjobs/" + id,
    body,
  })
);
const customerjobsaga = [
  takeLatest(
    getCustomerJobList.toString(),
    (payload: SagaGenericParams<CustomerJobDto[]>) =>
      getListSaga<CustomerJobDto[]>(payload)
  ),
  takeLatest(
    getCustomerJobDetails.toString(),
    (payload: SagaGenericParams<CustomerJobDto>) =>
      getListSaga<CustomerJobDto>(payload)
  ),
  takeLatest(
    createCustomerJob.toString(),
    (payload: PostSagaGenericParams<CustomerJobDto>) =>
      postSaga<CustomerJobDto>(payload)
  ),
  takeLatest(
    updateCustomerJob.toString(),
    (payload: PostSagaGenericParams<CustomerJobDto>) =>
      putSaga<CustomerJobDto>(payload)
  ),
  takeLatest(
    deleteCustomerJob.toString(),
    (payload: SagaGenericParams<CustomerJobDto>) =>
      deleteSaga<CustomerJobDto>(payload)
  ),
];

export default customerjobsaga;
