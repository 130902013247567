import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import CustomCheckbox from "src/components/Form/Input/CheckBox";
import { setCustomerToHonorary } from "src/store/customers/saga";
import { CustomerDetailDto } from "src/store/customers/type";

const UpdateToHonorary = ({
  customerData,
  onSuccessSubmit,
}: {
  onSuccessSubmit: () => void;
  customerData: CustomerDetailDto;
}) => {
  const [showHonorary, setShowHonorary] = useState(false);
  const dispatch = useDispatch();
  const [useMoka, setUseMoka] = useState(false);

  return (
    <React.Fragment>
      {customerData.CustomerTypeId !== 2 && (
        <button
          className="btn btn-info btn-sm mt-2"
          onClick={() => setShowHonorary(true)}
        >
          Fahri Üye Yap
        </button>
      )}

      {customerData && showHonorary && (
        <CancelAlert
          message={`'${customerData.Name} ${customerData.Surname}' müşteriyi Fahri Üye yapmak istediğinize emin misiniz?`}
          onConfirm={() => {
            dispatch(
              setCustomerToHonorary({
                payload: {
                  onSuccess: message => {
                    setShowHonorary(false);
                    toast.success(message);
                    onSuccessSubmit();
                  },
                  onError: message => {
                    setShowHonorary(false);
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  CustomerId: customerData.Id,
                },
              })
            );
          }}
          onCancel={() => {
            setShowHonorary(false);
          }}
          children={
            <CustomCheckbox
              field={"UseMoka"}
              label={"Moka'ya bildir"}
              value={useMoka}
              onChange={e => {
                return setUseMoka(e);
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
};
export default UpdateToHonorary;
