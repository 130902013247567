import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { NotificationMessageDto } from "./type";

export const getNotificationMessages = createAction(
  "GET_NOTIFICATION_MESSAGE",
  ({ payload, params }: SagaActionParams<NotificationMessageDto[]>) => ({
    payload,
    url: "/api/notificationmessages" + (params ? params : ""),
  })
);

export const getNotificationMessagesDetails = createAction(
  "GET_NOTIFICATION_MESSAGE_DETAILS",
  ({ payload, id }: SagaActionParams<NotificationMessageDto>) => ({
    payload,
    url: "/api/notificationmessages/" + id,
  })
);

export const updateNotificationMessages = createAction(
  "UPDATE_NOTIFICATION_MESSAGE",
  ({ payload, id, body }: SagaActionParams<NotificationMessageDto>) => ({
    payload,
    url: "/api/notificationmessages/" + id,
    body,
  })
);

const notificationmessagesSaga = [
  /*NOTIFICATION_MESSAGE*/
  takeLatest(
    getNotificationMessages.toString(),
    (payload: SagaGenericParams<NotificationMessageDto[]>) =>
      getListSaga<NotificationMessageDto[]>(payload)
  ),

  takeLatest(
    getNotificationMessagesDetails.toString(),
    (payload: SagaGenericParams<NotificationMessageDto>) =>
      getListSaga<NotificationMessageDto>(payload)
  ),

  takeLatest(
    updateNotificationMessages.toString(),
    (payload: PostSagaGenericParams<NotificationMessageDto>) =>
      putSaga<NotificationMessageDto>(payload)
  ),
];

export default notificationmessagesSaga;
