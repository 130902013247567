import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import CancelAlert from "src/components/CancelAlert";
import {
  updateStatusToClosedMultiple,
  updateStatusToProgressMultiple,
} from "src/store/ticket/saga";
import {
  TicketDetailDto,
  TicketStatusDto,
  TicketStatusEnum,
} from "src/store/ticket/type";
import AssignUserToTicket from "./assign-user";
import CustomTextarea from "src/components/Form/Input/Textarea";
import CreateNoteToCalllog from "./create-note";
interface StatusDto {
  key: "select" | "all";
  message: string;
  action: any;
}
const TicketDetailToolbar = ({
  ticket,
  refreshData,
  status,
}: {
  ticket: TicketDetailDto;
  refreshData: () => void;
  status: TicketStatusDto[];
}) => {
  const [description, setDescription] = useState("");
  const [showStatus, setShowStatus] = useState<StatusDto>();
  const dispatch = useDispatch();
  const setModalStatus = (s: StatusDto) => {
    return setShowStatus(s);
  };
  return (
    <React.Fragment>
      <div className=" btn-toolbar mb-2 gap-2" role="toolbar">
        {(ticket.TicketStatusTypeId === TicketStatusEnum.Yeni ||
          ticket.TicketStatusTypeId === TicketStatusEnum.Atandi) && (
          <Button
            type="button"
            color="info"
            className="btn-sm waves-light waves-effect"
            onClick={() => {
              setModalStatus({
                key: "select",
                action: updateStatusToProgressMultiple,
                message: "Talebi işleme almak istediğinize emin misiniz?",
              });
            }}
          >
            İşleme Al
          </Button>
        )}
        {ticket.TicketStatusTypeId === TicketStatusEnum.Islemde && (
          <Button
            type="button"
            color="secondary"
            className="btn-sm waves-light waves-effect"
            onClick={() => {
              setModalStatus({
                key: "select",
                action: updateStatusToClosedMultiple,
                message: "Talebi kapatmak istediğinize emin misiniz?",
              });
            }}
          >
            Talebi Kapat
          </Button>
        )}
        <AssignUserToTicket
          selectedList={[ticket.Id]}
          refreshList={refreshData}
          selectedUserId={ticket.ResponsibleUserId}
        />
        <CreateNoteToCalllog
          selectedId={ticket.Id}
          currentStatus={ticket.TicketStatusTypeId}
          refreshList={refreshData}
          status={status}
          selectedUserId={ticket.ResponsibleUserId}
        />
      </div>
      {showStatus && (
        <CancelAlert
          children={
            <div>
              <CustomTextarea
                field={"Açıklama"}
                label={"Açıklama"}
                value={description}
                onChange={e => {
                  return setDescription(e);
                }}
              />
            </div>
          }
          message={showStatus.message}
          onConfirm={() => {
            dispatch(
              showStatus.action({
                payload: {
                  onSuccess: (m: string) => {
                    setShowStatus(undefined);
                    toast.success(m);
                    setDescription("");
                    refreshData();
                  },
                  onError: (m: string) => {
                    setShowStatus(undefined);
                    toast.error(m, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  TicketIds: [ticket.Id],
                  Description: description,
                },
              })
            );
          }}
          onCancel={() => {
            setShowStatus(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default TicketDetailToolbar;
