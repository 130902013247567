import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createCorporateLocations } from "src/store/corporate-locations/saga";
import { getCities, getTowns } from "src/store/lookup/saga";
import { CityDto, TownDto } from "src/store/lookup/type";
import * as Yup from "yup";

const CreateCorporateLocation = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const { t } = useTranslation();
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, payload) => setCities(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Lokasyon Ekle",
        }}
        onHide={onHide}
        loader
        initialValues={{
          CorporateClientId: id,
          Address: "",
          CityId: "",
          TownId: "",
          TaxOffice: "",
          TaxId: "",
          AuthorizedPerson: "",
          Email: "",
          Phone: "",
        }}
        validationSchema={Yup.object().shape({
          Address: Yup.string().required(t("Required")),
        })}
        onSubmit={values => {
          dispatch(
            createCorporateLocations({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        inputs={[
          {
            field: "Address",
            label: "Adres",
          },
          {
            col: 6,
            field: "CityId",
            inputType: InputType.multiselect,
            label: t("City"),
            lookup: {
              data: cities,
              labelKey: "CityName",
              valueKey: "Id",
              placeholder: t("Choose City"),
            },
            onChange(value, item, setFieldValue) {
              value &&
                dispatch(
                  getTowns({
                    payload: {
                      onSuccess: (msg, payload) => setTowns(payload),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },
          {
            col: 6,
            field: "TownId",
            inputType: InputType.multiselect,
            label: t("Town"),
            lookup: {
              data: towns,
              labelKey: "TownName",
              valueKey: "Id",
              placeholder: t("Choose Town"),
            },
          },
          {
            field: "TaxOffice",
            label: "Vergi Dairesi",
          },
          {
            field: "TaxId",
            label: "Vergi No",
          },
          {
            field: "AuthorizedPerson",
            label: "Yetkili",
          },
          {
            field: "Email",
            label: "E-posta",
          },
          {
            field: "Phone",
            label: "Telefon",
          },

          {
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCorporateLocation;
