import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DataBankDto } from "src/store/databank/type";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import { deleteDataBank } from "src/store/databank/saga";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
import { userSelector } from "src/store/auth";
import { CheckRole } from "src/permissions/checkPermission";
import DatabankDetail from "./details";

const DataBankPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDelete, setShowDelete] = useState<DataBankDto>();
  const [show, setShow] = useState<boolean>(false);
  const [selected, setSelected] = useState<DataBankDto>();
  const user = useSelector(userSelector);
  const columns = [
    {
      caption: t("CategoryName"),
      dataField: "DataBankCategoryName",
    },
    {
      caption: t("Question"),
      dataField: "Question",
      // formatter: (cellContent: string) => (
      //   <div dangerouslySetInnerHTML={{ __html: cellContent }}></div>
      // ),
    },
    {
      caption: t("Answer"),
      dataField: "Answer",
      cellRender: (e: any) => {
        return e.row.data.Answer.replace(/<[^>]*>/g, "");
      },
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            setSelected(e.row.data);
            return setShow(true);
          },
        },
        {
          hint: "Düzenle",
          icon: "edit",

          visible: (e: any) => CheckRole(user),
          onClick: async (e: any) => {
            history.push("/databanks/" + e.row.key);
          },
        },
        {
          hint: "Sil",
          icon: "trash",

          visible: (e: any) => CheckRole(user),
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
  };
  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/databanks/devexp",
          columns: columns,
          create() {
            return history.push("/databanks-create");
          },
        }}
        useDevGrid={true}
        title={t("DataBankTitle")}
      />
      {selected && (
        <DatabankDetail data={selected} show={show} setShow={setShow} />
      )}
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete databank`, {
            name: `${showDelete.Question} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteDataBank({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DataBankPage;
