import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { CustomerDiscountDto } from "./type";

export const getCustomerDiscountListByCustomerId = createAction(
  "GET_CUSTOMER_DISCOUNT_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerDiscountDto[]>) => ({
    payload,
    url:
      "/api/customerdiscountdefinitions/getbycustomerid/" +
      id +
      (params ? params : ""),
  })
);
export const getCustomerDiscountDetails = createAction(
  "GET_CUSTOMER_DISCOUNT_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerDiscountDto>) => ({
    payload,
    url: "/api/customerdiscountdefinitions/" + id,
  })
);
export const createCustomerDiscount = createAction(
  "CREATE_CUSTOMER_DISCOUNT_DETAILS",
  ({ payload, body }: SagaActionParams<CustomerDiscountDto>) => ({
    payload,
    url: "/api/customerdiscountdefinitions",
    body,
  })
);
export const updateCustomerDiscount = createAction(
  "UPDATE_CUSTOMER_DISCOUNT_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerDiscountDto>) => ({
    payload,
    url: "/api/customerdiscountdefinitions/" + id,
    body,
  })
);
export const deleteCustomerDiscount = createAction(
  "DELETE_CUSTOMER_DISCOUNT_DETAILS",
  ({ payload, body, id }: SagaActionParams<CustomerDiscountDto>) => ({
    payload,
    url: "/api/customerdiscountdefinitions/" + id,
    body,
  })
);
const customerdiscountsaga = [
  takeLatest(
    getCustomerDiscountListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerDiscountDto[]>) =>
      getListSaga<CustomerDiscountDto[]>(payload)
  ),
  takeLatest(
    getCustomerDiscountDetails.toString(),
    (payload: SagaGenericParams<CustomerDiscountDto>) =>
      getListSaga<CustomerDiscountDto>(payload)
  ),
  takeLatest(
    createCustomerDiscount.toString(),
    (payload: PostSagaGenericParams<CustomerDiscountDto>) =>
      postSaga<CustomerDiscountDto>(payload)
  ),
  takeLatest(
    updateCustomerDiscount.toString(),
    (payload: PostSagaGenericParams<CustomerDiscountDto>) =>
      putSaga<CustomerDiscountDto>(payload)
  ),
  takeLatest(
    deleteCustomerDiscount.toString(),
    (payload: SagaGenericParams<CustomerDiscountDto>) =>
      deleteSaga<CustomerDiscountDto>(payload)
  ),
];

export default customerdiscountsaga;
