import images from "src/assets/image";
import { TicketLevelEnum } from "src/store/ticket/type";

const TicketLevelComp = ({ levelId }: { levelId: number }) => {
  let icon = "";
  switch (levelId) {
    case TicketLevelEnum.Normal:
      icon = images.medium;
      break;
    case TicketLevelEnum.Onemli:
      icon = images.high;
      break;
    case TicketLevelEnum.CokOnemli:
      icon = images.highest;
      break;
  }
  return icon ? <img src={icon} width={15} alt=" " /> : <></>;
};
export default TicketLevelComp;
