import { Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import CustomTextarea from "src/components/Form/Input/Textarea";
import FileUploader from "src/components/file-upload";
import { sendTicketAnswer } from "src/store/ticket/saga";
import { TicketDetailDto } from "src/store/ticket/type";
import * as Yup from "yup";

const CreateTicketAnswer = ({
  ticket,
  refreshData,
}: {
  ticket: TicketDetailDto;
  refreshData: () => void;
}) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="d-flex justify-content-end">
        <div
          onClick={() => setShow(true)}
          className="btn btn-light waves-effect "
        >
          <i className="mdi mdi-reply"></i> Yanıtla
        </div>
      </div>

      <Modal isOpen={show} centered>
        <Formik
          initialValues={{
            Message: "",
            Documents: [],
            Images: [],
          }}
          validationSchema={Yup.object().shape({
            Message: Yup.string().required(),
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(
              sendTicketAnswer({
                payload: {
                  onSuccess: () => {
                    refreshData();
                    resetForm({
                      values: {
                        Message: "",
                        Documents: [],
                        Images: [],
                      },
                    });
                    setShow(false);
                  },
                  onError: () => {},
                },
                id: ticket.Id,
                body: values,
              })
            );
          }}
        >
          {({
            errors,
            values,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
          }) => (
            <>
              <ModalHeader toggle={() => setShow(false)}>
                Talebi Yanıtla
              </ModalHeader>
              <ModalBody>
                <div className=" w-100 px-4">
                  <CustomTextarea
                    style={{
                      height: "100px",
                      borderColor: errors.Message ? "red" : "#e9e9ef",
                    }}
                    field={"Message"}
                    label={""}
                    error={errors.Message}
                    value={values.Message}
                    onChange={e => {
                      return setFieldValue(`Message`, e);
                    }}
                  />
                  <FileUploader
                    className=" btn-light ms-1 w-100 mb-2"
                    onChange={files => {
                      setFieldValue("Documents", files);
                    }}
                    title={t("Tickets.Documents")}
                  />
                  {values.Documents?.map((x: any) => (
                    <div className="font-size-12 badge bg-light p-1">
                      <span
                        className="fw-bold me-2"
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setFieldValue(
                            "Documents",
                            values.Documents.filter(
                              (y: any) => y.name !== x.name
                            )
                          )
                        }
                      >
                        X
                      </span>
                      {x.name}
                    </div>
                  ))}
                  <FileUploader
                    className=" btn-light ms-1 mt-2 w-100 mb-2"
                    onChange={files => {
                      setFieldValue("Images", files);
                    }}
                    title={t("Tickets.Images")}
                  />
                  {values.Images?.map((x: any) => (
                    <div className="font-size-12 badge bg-light p-1">
                      <span
                        className="fw-bold me-2"
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          setFieldValue(
                            "Images",
                            values.Images.filter((y: any) => y.name !== x.name)
                          )
                        }
                      >
                        X
                      </span>
                      {x.name}
                    </div>
                  ))}
                  <Row className="pt-4">
                    <Col md={6}>
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => setShow(false)}
                        className="w-100 btn btn-danger bg-pr border-pr chat-send "
                      >
                        <span className="d-none d-sm-inline-block me-2">
                          Kapat
                        </span>
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => handleSubmit()}
                        className=" w-100 btn btn-success bg-pr border-pr chat-send"
                      >
                        <span className="d-none d-sm-inline-block me-2">
                          {t("Tickets.Reply")}
                        </span>
                        <i className="mdi mdi-send float-end" />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};
export default CreateTicketAnswer;
