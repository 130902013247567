import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { TicketDetailDto } from "src/store/ticket/type";
import moment from "moment";
import "../style.scss";
import PerfectScrollbar from "react-perfect-scrollbar";

const TicketDetailCard = ({ ticket }: { ticket: TicketDetailDto }) => {
  const customerInputs = [
    {
      label: "Ad-Soyad",
      value: `${ticket.Name} ${ticket.Surname ?? ""}`,
    },
    {
      label: "Telefon",
      value: ticket.PhoneNumber,
    },
    {
      label: "Lokasyon",
      value: ticket.CurrentLocationAddress ?? "-",
    },
    {
      label: "Lokasyon",
      value: ticket.CurrentLocationCityTown ?? "-",
    },
  ];
  const supplierInfo = [
    {
      label: "Tedarikçi",
      value: `${ticket.SupplierName ?? "-"}`,
    },
    {
      label: "Telefon",
      value: `${ticket.SupplierPhoneNumber ?? ""}`,
    },
    {
      label: "Yetkili Kişi",
      value: `${ticket.SupplierPersonName ?? ""}`,
    },
  ];
  const responsibleUserInputs = [
    {
      label: "Sorumlu Kişi",
      value: `${ticket.ResponsibleUserFullName ?? ""}`,
    },
    {
      label: "Telefon",
      value: `${ticket.ResponsibleUserPhoneNumber ?? ""}`,
    },
    {
      label: "E-Posta",
      value: `${ticket.ResponsibleUserEmail ?? ""}`,
    },
  ];
  const ticketDetailInput = [
    {
      label: "Talep Konusu",
      value: `${ticket.Subject}`,
    },
    {
      label: "Durum",
      value: `${ticket.TicketStatusType}`,
    },
    {
      label: "Açılış Tarihi",
      value: moment(ticket.CreateTime).format("DD/MM/yyyy HH:mm:ss"),
    },
  ];
  const vehicle = ticket.Vehicle;
  const vehicleInputs = [
    {
      label: "Plaka",
      value: vehicle?.Plate,
    },
    {
      label: "Marka",
      value: vehicle?.make,
    },
    {
      label: "Model",
      value: vehicle?.model,
    },
    {
      label: "Kayıt Tarihi",
      value: vehicle?.RegistrationDate
        ? moment(vehicle?.RegistrationDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
  ];
  const padding = "0.25rem";
  return (
    <PerfectScrollbar
      className="ticketDetailCardContainer border-left"
      options={{ suppressScrollX: true }}
      style={{}}
    >
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-soft-success py-1 fw-bold px-2">
              Üye Bilgileri{" "}
              <a
                target="_blank"
                className="text-success"
                rel="noreferrer"
                href={"/customer/details/8/" + ticket.CustomerId}
              >
                <i className="bx bx-right-arrow-circle"></i>
              </a>
            </CardHeader>
            <CardBody style={{ padding: padding }}>
              {customerInputs.map((x, i) => (
                <GetInput label={x.label} value={x.value} />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-soft-info py-1 fw-bold px-2">
              Talep Bilgileri
            </CardHeader>
            <CardBody style={{ padding: padding }}>
              {ticketDetailInput.map((x, i) => (
                <GetInput label={x.label} value={x.value} />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-soft-warning py-1 fw-bold px-2">
              Tedarikçi Bilgileri
            </CardHeader>
            <CardBody style={{ padding: padding }}>
              {supplierInfo.map((x, i) => (
                <GetInput label={x.label} value={x.value} />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-soft-secondary py-1 fw-bold px-2">
              Talep Sorumlu Kişi Bilgileri
            </CardHeader>
            <CardBody style={{ padding: padding }}>
              {responsibleUserInputs.map((x, i) => (
                <GetInput label={x.label} value={x.value} />
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-soft-warning py-1 fw-bold px-2">
              Araç Bilgileri
            </CardHeader>
            <CardBody style={{ padding: padding }}>
              {vehicleInputs.map((x, i) => (
                <GetInput label={x.label} value={x.value} />
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PerfectScrollbar>
  );
};

export default TicketDetailCard;
const GetInput = ({ label, value }: { label: string; value: any }) => {
  return (
    <Col md={12}>
      <div className="ticketDetailInfoInput d-flex gap-3 mb-2 flex-wrap">
        <p className="mb-0 fw-bold">{label}</p>
        <div className="inputDiv">
          <input
            readOnly
            value={value !== null && value ? value : ""}
            className="readonlyInput"
          />
        </div>
      </div>
    </Col>
  );
};
