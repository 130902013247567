import { Popover, Whisper as WhisperRS } from "rsuite";

type WhisperProps = {
  speaker: string | React.ReactNode;
  children: any;
  placement?: any;
};

const Whisper = ({ speaker, children, placement = "top" }: WhisperProps) => {
  return (
    <WhisperRS
      delay={500}
      placement={placement}
      trigger="hover"
      speaker={
        <Popover>
          {typeof speaker === "string" ? <p>{speaker}</p> : speaker}
        </Popover>
      }
      enterable
    >
      {children}
    </WhisperRS>
  );
};

export default Whisper;
