import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import * as Yup from "yup";
import { CityDto, TownDto } from "src/store/lookup/type";
import { getCities, getTowns } from "src/store/lookup/saga";
import { useParams } from "react-router";
import { SupplierLocationDto } from "src/store/supplierlocations/type";
import { updateSupplierLocation } from "src/store/supplierlocations/saga";

const EditSupplierLocation = ({
  location,
  active,
  onSuccessClick,
}: {
  active: string;
  onSuccessClick: (message: string, payload: SupplierLocationDto) => void;
  location: SupplierLocationDto;
}) => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cities, setCities] = useState<CityDto[]>([]);
  const [towns, setTowns] = useState<TownDto[]>([]);

  useEffect(() => {
    if (active === "edit") {
      dispatch(
        getTowns({
          payload: {
            onSuccess: (msg, payload2) => setTowns(payload2),
            onError: () => {},
          },
          id: location.CityId,
        })
      );

      dispatch(
        getCities({
          payload: {
            onSuccess: (msg, payload) => setCities(payload),
            onError: () => {},
          },
        })
      );
    } // eslint-disable-next-line
  }, [dispatch, id, active]);
  return (
    <React.Fragment>
      {location && (
        <CustomFormik
          initialValues={{
            Id: location.Id,
            SupplierId: location.SupplierId,
            Name: location.Name ?? "",
            TaxNumber: location.TaxNumber ?? "",
            TaxOffice: location.TaxOffice ?? "",
            Address: location.Address ?? "",
            CityId: location.CityId ?? "",
            PersonName: location.PersonName ?? "",
            PersonSurname: location.PersonSurname ?? "",
            TownId: location.TownId ?? "",
            Active: location.Active ?? false,
            Password: "",
            BankName: location.BankName ?? "",
            Iban: location.Iban ?? "",
            Email: location.Email ?? "",
            Brand: location.Brand ?? "",
            Ispark: location.Ispark ?? false,
            CurrentLogoFile: location.Logo ?? "",
            LogoFile: "",
            ContractStartDate: location.ContractStartDate ?? "",
            ContractEndDate: location.ContractEndDate ?? "",
            PhoneNumber: location.PhoneNumber ?? "",
            Latitude: location.Latitude ?? "",
            Longitude: location.Longitude ?? "",
          }}
          onSubmit={values => {
            dispatch(
              updateSupplierLocation({
                payload: {
                  onSuccess: (message, payload) => {
                    toast.success("Lokasyon başarıyla güncellendi", {
                      theme: "colored",
                    });
                    return onSuccessClick(message, payload);
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: location.Id,
                body: values,
              })
            );
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string().required(t("Required")),
            SupplierId: Yup.string().required(t("Required")),
            Address: Yup.string().required(t("Required")),
            CityId: Yup.string().required(t("Required")),
            PersonName: Yup.string().required(t("Required")),
            PersonSurname: Yup.string().required(t("Required")),
            TownId: Yup.string().required(t("Required")),
            Email: Yup.string().required(t("Required")),
            Brand: Yup.string().required(t("Required")),
          })}
          inputs={[
            {
              col: 12,
              field: "Name",
              label: t("Name"),
            },
            {
              col: 6,
              field: "Brand",
              label: t("Brand"),
            },

            {
              col: 6,
              field: "Password",
              label: t("Password"),
            },
            {
              col: 6,
              field: "Email",
              label: t("Email"),
            },
            {
              col: 6,
              field: "PhoneNumber",
              label: t("PhoneNumber"),
            },
            {
              field: "PersonName",
              col: 6,
              label: t("Person Name"),
            },
            {
              field: "PersonSurname",
              col: 6,
              label: t("Person Surname"),
            },
            {
              field: "TaxOffice",
              label: t("Tax Office"),
              col: 6,
            },
            {
              field: "TaxNumber",
              label: t("Tax Number"),
              col: 6,
            },
            {
              field: "BankName",
              label: t("Bank Name"),
              col: 6,
            },
            {
              field: "Iban",
              label: t("IBAN"),
              col: 6,
            },
            {
              field: "ContractStartDate",
              label: t("Contract Start Date"),
              col: 6,
              inputType: InputType.datetime,
            },
            {
              field: "ContractEndDate",
              label: t("Contract End Date"),
              col: 6,
              inputType: InputType.datetime,
            },
            {
              col: 6,
              field: "CityId",
              inputType: InputType.multiselect,
              label: t("City"),
              lookup: {
                data: cities,
                labelKey: "CityName",
                valueKey: "Id",
                placeholder: t("Choose City"),
              },
            },
            {
              col: 6,
              field: "TownId",
              inputType: InputType.multiselect,
              label: t("Town"),
              lookup: {
                data: towns,
                labelKey: "TownName",
                valueKey: "Id",
                placeholder: t("Choose Town"),
              },
            },
            {
              field: "Address",
              label: t("Address"),
              inputType: InputType.multilinetext,
            },

            {
              field: "LogoFile",
              label: t("LogoFile Image"),
              inputType: InputType.fileUpload,
              fileUpload: {
                accept: ["image/jpeg", "image/png"],
                multiple: false,
                linkField: "CurrentLogoFile",
                title: t("Please Logo Image"),
              },
              onChange(value, item, setFieldValue) {
                setFieldValue && setFieldValue("CurrentLogoFile", "");
              },
            },
            {
              field: "Ispark",
              label: t("İspark"),
              col: 1,
              checkedLabel: t("Ispark"),
              uncheckedLabel: t("Ispark"),
              inputType: InputType.toogle,
            },
            {
              field: "Active",
              label: t("Active"),
              col: 1,
              checkedLabel: t("Active"),
              uncheckedLabel: t("Pasive"),
              inputType: InputType.toogle,
            },

            {
              field: "",
              label: t(""),
              googlemap: {
                latField: "Latitude",
                lonField: "Longitude",
              },
              inputType: InputType.googlemap,
            },
            {
              col: 6,
              field: "Latitude",
              label: t("Latitude"),
            },
            {
              col: 6,
              field: "Longitude",
              label: t("Longitude"),
            },
          ]}
        />
      )}
    </React.Fragment>
  );
};
export default EditSupplierLocation;
