import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Container, Row } from "reactstrap";
import CustomDataGrid from "src/components/PageContent/DevextGrid";

const CouponGroupUsageHistories = () => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const columns = [
    {
      caption: t("Customer"),
      dataField: "CustomerName",
    },
    {
      caption: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },

    {
      caption: t("Coupon Name"),
      dataField: "CouponName",
    },

    {
      caption: t("Total Amount"),
      dataField: "CouponCode",
    },
    {
      caption: t("Discount Amount"),
      dataField: "DiscountAmount",
    },
    {
      caption: t("Discount Rate"),
      dataField: "DiscountRate",
    },
    {
      caption: t("Grand Total"),
      dataField: "GrandTotal",
    },
  ];
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <CustomDataGrid
                className="detailGrid"
                url={"/api/couponusagehistories/bycoupongroupid/" + id}
                columns={columns}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
export default CouponGroupUsageHistories;
