import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { PaymentSettingDto } from "src/store/web/type";
import { getPaymentSettings, updatePaymentSettings } from "src/store/web/saga";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const PaymentSettingsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loader = useSelector(isLoading);
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<PaymentSettingDto>();
  useEffect(() => {
    dispatch(
      getPaymentSettings({
        payload: {
          onSuccess: (msg, r) => setData(r),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Ödeme Ayarları")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Ödeme Ayarları")}
            breadcrumbItem={t("Ödeme Ayarları")}
          />
          <Row className="mt-4">
            {loader && <Loader />}
            {!loader && data && (
              <CustomFormik
                loader
                onSubmit={(values, { resetForm }) => {
                  dispatch(
                    updatePaymentSettings({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          setData(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  testBaseUrl: Yup.string().required(t("Required")),
                  testDealerCode: Yup.string().required(t("Required")),
                  testUsername: Yup.string().required(t("Required")),
                  testPassword: Yup.string().required(t("Required")),
                  BaseUrl: Yup.string().required(t("Required")),
                  DealerCode: Yup.string().required(t("Required")),
                  Username: Yup.string().required(t("Required")),
                  Password: Yup.string().required(t("Required")),
                })}
                initialValues={{
                  id: data.Id,
                  testBaseUrl: data.TestBaseUrl,
                  testDealerCode: data.TestDealerCode,
                  testUsername: data.TestUsername,
                  testPassword: data.TestPassword,
                  UseTest: data.UseTest,
                  BaseUrl: data.BaseUrl,
                  DealerCode: data.DealerCode,
                  Username: data.Username,
                  Password: data.Password,
                }}
                inputs={[
                  {
                    label: "Test Base Url",
                    field: "testBaseUrl",
                  },
                  {
                    label: "Test Dealer Kod",
                    field: "testDealerCode",
                  },
                  {
                    label: "Test Kullanıcı Adı",
                    field: "testUsername",
                  },
                  {
                    label: "Test Şifre",
                    field: "testPassword",
                  },

                  {
                    label: "Base Url",
                    field: "BaseUrl",
                  },
                  {
                    label: "Dealer Kod",
                    field: "DealerCode",
                  },
                  {
                    label: "Kullanıcı Adı",
                    field: "Username",
                  },
                  {
                    label: "Şifre",
                    field: "Password",
                  },

                  {
                    label: "Test Ortamını Kullan",
                    field: t("UseTest"),
                    checkedLabel: t("Test"),
                    uncheckedLabel: t("Canlı"),
                    inputType: InputType.toogle,
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default PaymentSettingsPage;
