import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeCategoryDto } from "../privilegecategory/type";
import {
  PackageDetailDto,
  PackageDto,
  PackagePrivilegeDto,
  PackagePropertyDto,
} from "./type";
import { BodyType } from "../base/request/request";

export const changePackageOrder = createAction(
  "UPDATE_PACKAGE_ORDER",
  ({ payload, body }: SagaActionParams<PrivilegeCategoryDto>) => ({
    payload,
    url: "/api/packages/order",

    bodyType: BodyType.raw,
    body,
  })
);
export const getPackageList = createAction(
  "GET_PACKAGE_LIST",
  ({ payload, params }: SagaActionParams<PackageDto[]>) => ({
    payload,
    url: "/api/packages" + (params ? params : ""),
  })
);
export const getPackageDetails = createAction(
  "GET_PACKAGE_DETAILS",
  ({ payload, id }: SagaActionParams<PackageDetailDto>) => ({
    payload,
    url: "/api/packages/" + id,
  })
);
export const createPackage = createAction(
  "CREATE_PACKAGE_DETAILS",
  ({ payload, body }: SagaActionParams<PackageDto>) => ({
    payload,
    url: "/api/packages",
    body,
    lng: "en-US",
  })
);
export const updatePackage = createAction(
  "UPDATE_PACKAGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<PackageDto>) => ({
    payload,
    url: "/api/packages/" + id,
    body,
  })
);
export const deletePackage = createAction(
  "DELETE_PACKAGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<PackageDto>) => ({
    payload,
    url: "/api/packages/" + id,
    body,
  })
);
export const getPackagePrivilegeDetails = createAction(
  "GET_PACKAGE_PRIVILAGE_DETAILS",
  ({ payload, id }: SagaActionParams<PackagePrivilegeDto>) => ({
    payload,
    url: "/api/packageincludeprivilages/" + id,
  })
);
export const updatePackageProperty = createAction(
  "UPDATE_PACKAGE_PROPERTY",
  ({ payload, body, id }: SagaActionParams<PackagePropertyDto>) => ({
    payload,
    url: "/api/packageproperties/" + id,
    body,
  })
);

export const createPackagePrivilege = createAction(
  "CREATE_PACKAGE_PRIVILEGE",
  ({ payload, body }: SagaActionParams<PackagePrivilegeDto>) => ({
    payload,
    url: "/api/packageincludeprivilages",
    body,
  })
);
export const deletePackagePrivilege = createAction(
  "DELETE_PACKAGE_PRIVILEGE",
  ({ payload, body, id }: SagaActionParams<PackagePrivilegeDto>) => ({
    payload,
    url: "/api/packageincludeprivilages/" + id,
    body,
  })
);
export const updatePackagePrivilege = createAction(
  "UPDATE_PACKAGE_PRIVILEGE",
  ({ payload, body, id }: SagaActionParams<PackagePrivilegeDto>) => ({
    payload,
    url: "/api/packageincludeprivilages/" + id,
    body,
  })
);
export const getPackagePrivilegeList = createAction(
  "GET_PACKAGE_PRIvilege_LIST",
  ({ payload, id }: SagaActionParams<PackagePrivilegeDto[]>) => ({
    payload,
    url: "/api/packageincludeprivilages/getbypackageid/" + id,
  })
);

const packageSaga = [
  takeLatest(
    getPackagePrivilegeDetails.toString(),
    (payload: SagaGenericParams<PackagePrivilegeDto[]>) =>
      getListSaga<PackagePrivilegeDto[]>(payload)
  ),
  takeLatest(
    getPackagePrivilegeList.toString(),
    (payload: SagaGenericParams<PackagePrivilegeDto[]>) =>
      getListSaga<PackagePrivilegeDto[]>(payload)
  ),
  takeLatest(
    changePackageOrder.toString(),
    (payload: PostSagaGenericParams<PrivilegeCategoryDto>) =>
      putSaga<PrivilegeCategoryDto>(payload)
  ),
  takeLatest(
    getPackageList.toString(),
    (payload: SagaGenericParams<PackageDto[]>) =>
      getListSaga<PackageDto[]>(payload)
  ),
  takeLatest(
    getPackageDetails.toString(),
    (payload: SagaGenericParams<PackageDetailDto>) =>
      getListSaga<PackageDetailDto>(payload)
  ),
  takeLatest(
    createPackage.toString(),
    (payload: PostSagaGenericParams<PackageDto>) =>
      postSaga<PackageDto>(payload)
  ),
  takeLatest(
    updatePackage.toString(),
    (payload: PostSagaGenericParams<PackageDto>) => putSaga<PackageDto>(payload)
  ),
  takeLatest(
    deletePackage.toString(),
    (payload: SagaGenericParams<PackageDto>) => deleteSaga<PackageDto>(payload)
  ),
  takeLatest(
    updatePackageProperty.toString(),
    (payload: PostSagaGenericParams<PackageDto>) => putSaga<PackageDto>(payload)
  ),

  takeLatest(
    createPackagePrivilege.toString(),
    (payload: PostSagaGenericParams<PackagePrivilegeDto>) =>
      postSaga<PackagePrivilegeDto>(payload)
  ),
  takeLatest(
    updatePackagePrivilege.toString(),
    (payload: PostSagaGenericParams<PackagePrivilegeDto>) =>
      putSaga<PackagePrivilegeDto>(payload)
  ),
  takeLatest(
    deletePackagePrivilege.toString(),
    (payload: SagaGenericParams<PackagePrivilegeDto>) =>
      deleteSaga<PackagePrivilegeDto>(payload)
  ),
];

export default packageSaga;
