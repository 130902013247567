import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import { MaintenanceCategoryDto } from "./type";
import { BodyType } from "src/store/base/request/request";
import { getListSaga } from "src/store/base/request/get";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { deleteSaga } from "src/store/base/request/delete";

export const getMaintenanceCategoryList = createAction(
  "GET_MAINTENANCE_CATEGORY_LIST",
  ({ payload, params }: SagaActionParams<MaintenanceCategoryDto[]>) => ({
    payload,
    url: "/api/maintenancecategories" + (params ? params : ""),
  })
);

export const getMaintenanceCategoryLookup = createAction(
  "GET_MAINTENANCE_CATEGORY_LOOKUP",
  ({ payload }: SagaActionParams<MaintenanceCategoryDto[]>) => ({
    payload,
    url: "/api/maintenancecategories/lookup",
  })
);
export const getMaintenanceCategoryListByBrandId = createAction(
  "GET_MAINTENANCE_CATEGORY_LIST_BY_BRAND_ID",
  ({ payload, id }: SagaActionParams<MaintenanceCategoryDto[]>) => ({
    payload,
    url: "/api/maintenancecategories/bycategoryId/" + id,
  })
);
export const getMaintenanceCategoryDetails = createAction(
  "GET_MAINTENANCE_CATEGORY_DETAILS",
  ({ payload, id }: SagaActionParams<MaintenanceCategoryDto>) => ({
    payload,
    url: "/api/maintenancecategories/" + id,
  })
);
export const createMaintenanceCategory = createAction(
  "CREATE_MAINTENANCE_CATEGORY_DETAILS",
  ({ payload, body }: SagaActionParams<MaintenanceCategoryDto>) => ({
    payload,
    url: "/api/maintenancecategories",
    body,
  })
);
export const updateMaintenanceCategory = createAction(
  "UPDATE_MAINTENANCE_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<MaintenanceCategoryDto>) => ({
    payload,
    url: "/api/maintenancecategories/" + id,
    body,
  })
);
export const deleteMaintenanceCategory = createAction(
  "DELETE_MAINTENANCE_CATEGORY_DETAILS",
  ({ payload, body, id }: SagaActionParams<MaintenanceCategoryDto>) => ({
    payload,
    url: "/api/maintenancecategories/" + id,
    body,
  })
);

export const changeMaintenanceCategoryOrder = createAction(
  "UPDATE_MAINTENANCE_CATEGORY_ORDER",
  ({ payload, body }: SagaActionParams<MaintenanceCategoryDto>) => ({
    payload,
    url: "/api/maintenancecategories/order",
    body,
    bodyType: BodyType.raw,
  })
);
const maintenanceCategorySaga = [
  takeLatest(
    getMaintenanceCategoryListByBrandId.toString(),
    (payload: SagaGenericParams<MaintenanceCategoryDto[]>) =>
      getListSaga<MaintenanceCategoryDto[]>(payload)
  ),
  takeLatest(
    getMaintenanceCategoryLookup.toString(),
    (payload: SagaGenericParams<MaintenanceCategoryDto[]>) =>
      getListSaga<MaintenanceCategoryDto[]>(payload)
  ),
  takeLatest(
    getMaintenanceCategoryList.toString(),
    (payload: SagaGenericParams<MaintenanceCategoryDto[]>) =>
      getListSaga<MaintenanceCategoryDto[]>(payload)
  ),
  takeLatest(
    getMaintenanceCategoryDetails.toString(),
    (payload: SagaGenericParams<MaintenanceCategoryDto>) =>
      getListSaga<MaintenanceCategoryDto>(payload)
  ),
  takeLatest(
    createMaintenanceCategory.toString(),
    (payload: PostSagaGenericParams<MaintenanceCategoryDto>) =>
      postSaga<MaintenanceCategoryDto>(payload)
  ),
  takeLatest(
    updateMaintenanceCategory.toString(),
    (payload: PostSagaGenericParams<MaintenanceCategoryDto>) =>
      putSaga<MaintenanceCategoryDto>(payload)
  ),
  takeLatest(
    deleteMaintenanceCategory.toString(),
    (payload: SagaGenericParams<MaintenanceCategoryDto>) =>
      deleteSaga<MaintenanceCategoryDto>(payload)
  ),
];

export default maintenanceCategorySaga;
