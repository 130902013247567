import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import DetailTable from "src/components/PageContent/detailTable";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";
import { getCustomerVehicleListByCustomerId } from "src/store/customervehicles/saga";
import { CustomerVehicleDto } from "src/store/customervehicles/type";

const CustomerVehiclesTab = () => {
  const { t } = useTranslation();
  const { id }: { id: string; tab: string } = useParams();
  const [data, setData] = useState<CustomerVehicleDto[]>([]);
  const history = useHistory();
  const user = useSelector(userSelector);
  const columns = [
    {
      text: t("Brand"),
      dataField: "make",
    },
    {
      text: t("Model"),
      dataField: "model",
    },
    {
      text: t("Fuel"),
      dataField: "FuelTypeName",
    },
    {
      text: t("Gear"),
      dataField: "GearTypeName",
    },
    {
      text: t("Plate"),
      dataField: "Plate",
    },
    {
      text: t("Active"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },
    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-2 align-items-center">
          <i
            className="mdi mdi-eye cursor-pointer font-size-12"
            onClick={() => {
              return history.push("/customer-vehicle/" + cellContent);
            }}
          />
          {CheckRole(user) && (
            <i
              className="bx bx-edit cursor-pointer font-size-12"
              onClick={() => {
                return history.push("/customer-vehicle-edit/" + cellContent);
              }}
            />
          )}
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="">
        <DetailTable
          HideBreadcrumb={true}
          listData={data}
          columns={columns}
          setDataList={setData}
          action={getCustomerVehicleListByCustomerId}
          paramsid={id}
        />
      </div>
    </React.Fragment>
  );
};
export default CustomerVehiclesTab;
