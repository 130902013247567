import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { createCustomerJob } from "src/store/customerjob/saga";
import * as Yup from "yup";

const CreateCustomerJob = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Customer Job"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Name: "",
        }}
        onSubmit={values => {
          dispatch(
            createCustomerJob({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateCustomerJob;
