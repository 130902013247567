import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getUsageTypes } from "src/store/lookup/saga";
import { UsageTypeDto } from "src/store/lookup/type";
import { getPrivilegeList } from "src/store/privilege/saga";
import { PrivilegeDto } from "src/store/privilege/type";
import { createPrivilegePrice } from "src/store/privilegeprices/saga";
import * as Yup from "yup";

export const CalculateTotalPrice = (cost: number, profitPercentage: number) => {
  const karOrani = profitPercentage / 100;
  const satisFiyati = cost * (1 + karOrani);
  return satisFiyati.toFixed(2);
};
export const CalculateProfitPercentage = (totalPrice: number, cost: number) => {
  const kar = totalPrice - cost;
  const yuzdelikKarOrani = (kar / cost) * 100;
  return yuzdelikKarOrani.toFixed(2);
};
export const CalculateCost = (totalPrice: number, profitPercentage: number) => {
  const satis = parseFloat(totalPrice.toString());
  // Kâr yüzdesi
  const karYuzde = parseFloat(profitPercentage.toString());
  // Kâr miktarı hesaplama
  const karMiktari = (satis * karYuzde) / 100;
  // Maliyet hesaplama
  const maliyet = satis - karMiktari;

  return maliyet.toFixed(2);
};
const CreatePrivilegePrice = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [usegaTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getUsageTypes({
          payload: {
            onSuccess: (msg, py) => setUsageTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("New Privilege Prices"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          Name: "",
          UsageTypeId: "",
          Count: 0,
          Price: 0,
          CostPrice: 0,
          ProfitPercentage: 0,
          NonMemberPrice: 0,
          ShowStore: false,
          ImageFile: "",
          ForCustomer: false,
          UsageInformationText: "",
          ShowUsageInformationText: false,
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegePrice({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Count: Yup.number().required(t("Required")),
          ProfitPercentage: Yup.number().required(t("Required")),
          CostPrice: Yup.number().required(t("Required")),
          Price: Yup.number().required(t("Required")),
          NonMemberPrice: Yup.number().required(t("Required")),
          PrivilegeId: Yup.string().required(t("Required")),
          UsageTypeId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              disabled: true,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
          },
          {
            field: "UsageTypeId",
            inputType: InputType.multiselect,
            label: t("Usage Type"),
            lookup: {
              data: usegaTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Choose Usage Type"),
            },
          },
          {
            field: "Name",
            label: t("Name"),
          },
          {
            field: "Count",
            label: t("Count"),
            inputType: InputType.number,
          },
          {
            field: "CostPrice",
            label: t("Maliyet Fiyatı"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "Price",
                  CalculateTotalPrice(value, values.ProfitPercentage)
                );

              value &&
                setFieldValue(
                  "ProfitPercentage",
                  CalculateProfitPercentage(values.Price, value)
                );
            },
          },
          {
            field: "ProfitPercentage",
            label: t("Yüzdelik Kar"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "Price",
                  CalculateTotalPrice(values.CostPrice, value)
                );
            },
          },
          {
            field: "Price",
            label: t("Price"),
            inputType: InputType.number,
            onChange(value, item, setFieldValue, values) {
              value &&
                setFieldValue(
                  "ProfitPercentage",
                  CalculateProfitPercentage(value, values.CostPrice)
                );
            },
          },
          {
            field: "NonMemberPrice",
            label: t("Üye Olmayan Fiyatı"),
            inputType: InputType.number,
          },
          {
            col: 6,
            field: "ShowStore",
            inputType: InputType.checkbox,
            label: t("Mağaza'da Göster"),
          },
          {
            field: "ShowUsageInformationText",
            label: t("Kullanım Şeklini Göster"),
            inputType: InputType.checkbox,
          },
          {
            field: "UsageInformationText",
            label: t("Kullanım Şekli"),
            height: "200px",
            inputType: InputType.editor,
          },
          {
            field: "ImageFile",
            label: t("Ana Resim"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegePrice;
