import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getMaintenanceDraftDetails } from "src/store/maintenance/maintenance-drafts/saga";
import { MaintenanceDraftDetailDto } from "src/store/maintenance/maintenance-drafts/type";
import MaintenanceDraftDetailCard from "./detailCard";
import MaintenanceDraftItems from "./items";
const MaintenanceDraftDetailsPage = () => {
  const { t } = useTranslation();
  const { id }: any = useParams();
  const loading = useSelector(isLoading);
  const [data, setData] = useState<MaintenanceDraftDetailDto>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMaintenanceDraftDetails({
        payload: {
          onSuccess: (m, p) => {
            return setData(p);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{t("Kurumsal Müşteriler")}</title>
        </MetaTags>
        <Container fluid>
          <React.Fragment>
            <Breadcrumbs
              title={t("Dashboard")}
              breadcrumbItem={
                (data
                  ? `${data.SupplierName} - ${data.VehicleModelVehicleBrandName} ${data.VehicleModelName} - ${data.KmLimit}`
                  : "") + "Bakım Taslağı"
              }
            />
            {!data && loading && <Loader />}
            {data && (
              <>
                <Row>
                  <Col md={4}>
                    <MaintenanceDraftDetailCard data={data} />
                  </Col>
                  <Col md={8}>
                    <MaintenanceDraftItems
                      data={data}
                      refreshData={() => {
                        dispatch(
                          getMaintenanceDraftDetails({
                            payload: {
                              onSuccess: (m, p) => {
                                return setData(p);
                              },
                              onError: () => {},
                            },
                            id: id,
                          })
                        );
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MaintenanceDraftDetailsPage;
