import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { DashboardCustomerResultDto } from "src/store/dashboard/type";
import { useDispatch } from "react-redux";
import { getDashboardCustomersSaga } from "src/store/dashboard/saga";
import ScrollBar from "react-perfect-scrollbar";
import ApexRadial from "./RadialChart";
import { getLocalDate } from "src/helpers/getLocalDate";

const DashboardCustomerComponent = () => {
  const [data, setData] = useState<DashboardCustomerResultDto>();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDashboardCustomersSaga({
        payload: {
          onSuccess: (m, p, r) => {
            setData(p);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  return (
    <React.Fragment>
      {data && (
        <Card className="dashboardCard">
          <CardBody>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="me-2">
                <h5 className="card-title mb-3">ÜYELER</h5>
              </div>
              <div
                className=" fw-bold text-muted py-1"
                style={{ fontSize: 12 }}
              >
                Toplam Üye: {data.TotalCount}
              </div>
            </div>
            <Row>
              <Col md={6}>
                <ApexRadial
                  percentage={data.PaidCustomerPercentage}
                  color="#2ab57d"
                  label="Üye Olan"
                  total={data.PaidCustomerCount}
                />
              </Col>
              <Col md={6}>
                <ApexRadial
                  percentage={data.UnpaidCustomerPercentage}
                  color="#fd625e"
                  label="Üye Olmayan"
                  total={data.UnpaidCustomerCount}
                />
              </Col>
            </Row>
            <ScrollBar style={{ height: "200px" }}>
              <table className="table align-middle table-nowrap">
                <tbody>
                  {data.LastPurchasedCustomers.map(x => (
                    <tr key={x.Id}>
                      <td>
                        <p className="mb-0">{x.CustomerName}</p>
                      </td>
                      <td className="text-end pe-3" style={{ fontSize: 12 }}>
                        <p className="mb-0">{x.PackageName}</p>
                        <div className="">{getLocalDate(x.CreateTime)}</div>
                      </td>
                      <td style={{ fontSize: 16 }}>
                        <a
                          target="_blank"
                          className="text-success"
                          rel="noreferrer"
                          href={"/customer/details/1/" + x.CustomerId}
                        >
                          <i className="bx bx-right-arrow-circle"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ScrollBar>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default DashboardCustomerComponent;
