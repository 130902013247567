import { useParams } from "react-router";
import { CorporateContractDetailCard } from "../contracts/detail";
import { useDispatch, useSelector } from "react-redux";
import { getActiveCorporateContract } from "src/store/corporate-contracts/saga";
import { CorporateContractDetailDto } from "src/store/corporate-contracts/type";
import { Row } from "reactstrap";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";

const ActiveContract = ({
  data,
  setData,
}: {
  data: CorporateContractDetailDto;
  setData: (data: CorporateContractDetailDto) => void;
}) => {
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);

  return (
    <>
      <Row className="p-2">
        {data ? (
          <CorporateContractDetailCard
            refreshData={() => {
              dispatch(
                getActiveCorporateContract({
                  payload: {
                    onSuccess: (m, p) => {
                      return setData(p);
                    },
                    onError: () => {},
                  },
                  id: id,
                })
              );
            }}
            showEdit
            data={data}
          />
        ) : loading ? (
          <Loader />
        ) : (
          <p className="text-center py-4 text-muted">
            Aktif Sözleşme Bulunmamaktadır
          </p>
        )}
      </Row>
    </>
  );
};

export default ActiveContract;
