import { call, put } from "@redux-saga/core/effects";
import auth from "../../auth";
import loader from "../../loader";
import { SagaGenericParams } from "./get";
import { BodyType, createRequestSaga } from "./request";
import { ApiResponse, verfiyResponse } from "../types/saga";

export function* deleteSaga<Type>({ payload, url }: SagaGenericParams<Type>) {
  yield put(loader.actions.showLoader());
  try {
    const response: ApiResponse<Type> = yield call(createRequestSaga, {
      method: "DELETE",
      url: url || "",
      bodyType: BodyType.formdata,
    });
    if (verfiyResponse(response))
      payload.onSuccess(response.Message, response.Result);
    else if (response.Status === 11) {
      window.location.replace("/");
      yield put(auth.actions.logoutAction());
    } else {
      payload.onError(response.Message);
    }
  } catch (e) {
    console.log("ERROR postSaga", e);
  } finally {
    yield put(loader.actions.hideLoader());
  }
}
