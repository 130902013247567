import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import * as Yup from "yup";
import { EmailTemplateDto } from "src/store/emailTemplates/type";
import { getEmailTemplateCustomerKeys } from "src/store/emailTemplates/saga";
import {
  getTemplatesForEmailSender,
  sendEmailAllActiveCustomerSaga,
  sendEmailAllCustomerSaga,
  sendEmailSaga,
} from "src/store/emails/saga";
import { PrivilegeFilterDto } from "./privilegeFilter";

const SendEmailModal = ({
  show,
  onHide,
  customers,
  isAll,
  onSuccessSubmit,
  filterData,
}: {
  filterData?: PrivilegeFilterDto;
  isAll: "select" | "active" | "all";
  show: boolean;
  onHide: () => void;
  customers: string[];
  onSuccessSubmit?: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<EmailTemplateDto[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  useEffect(() => {
    if (show) {
      dispatch(
        getTemplatesForEmailSender({
          payload: {
            onSuccess: (msg, payload) => setTemplates(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getEmailTemplateCustomerKeys({
          payload: {
            onSuccess: (msg, payload) => setKeys(payload),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, show]);
  var message = "E-posta gönderimi başarılı bir şekilde başlatıldı";
  return (
    <React.Fragment>
      <CustomModal
        onHide={() => onHide()}
        open={show}
        title={t("Send Nofitification")}
      >
        <Formik
          onSubmit={values => {
            if (isAll === "all") {
              dispatch(
                sendEmailAllCustomerSaga({
                  payload: {
                    onSuccess: (ms, payload) => {
                      toast.success(message);
                      onSuccessSubmit && onSuccessSubmit();
                      onHide();
                    },
                    onError: () => {
                      toast.error("Hata oluştu");
                    },
                  },
                  body: values,
                })
              );
            } else if (isAll === "active") {
              dispatch(
                sendEmailAllActiveCustomerSaga({
                  payload: {
                    onSuccess: (ms, payload) => {
                      toast.success(message);
                      onSuccessSubmit && onSuccessSubmit();
                      onHide();
                    },
                    onError: () => {
                      toast.error("Hata oluştu");
                    },
                  },
                  body: values,
                })
              );
            } else {
              dispatch(
                sendEmailSaga({
                  payload: {
                    onSuccess: (ms, payload) => {
                      toast.success(message);
                      onSuccessSubmit && onSuccessSubmit();
                      onHide();
                    },
                    onError: () => {
                      toast.error("Hata oluştu");
                    },
                  },
                  body: values,
                })
              );
            }
          }}
          initialValues={{
            customerIds: customers,
            emailTemplateId: "",
            emailTemplateTypeId: "",
            subject: "",
            content: "",
            keys: [],
            usingLayout: false,
            privilegeId: filterData ? filterData.PrivilegeId : "",
            privilegeFilterTypeId: filterData
              ? filterData.PrivilegeFilterTypeId
              : "",
          }}
          validationSchema={Yup.object().shape({
            subject: Yup.string().required(t("Required")),
            content: Yup.string()
              .required(t("Required"))
              .test(
                "KeyValidation",
                "Anahtar sözcüklerin kullandığınızdan emin olunuz.[[key]] şeklinde içeriğe eklenmelidir!",
                (text, prop) => {
                  let res = true;
                  if (text) {
                    prop.parent.keys.forEach((key: string) => {
                      if (!text.includes(`[[${key}]]`)) {
                        res = false;
                      }
                    });
                  }
                  return res;
                }
              ),
            url: Yup.string().when("notificationPageId", {
              is: (id: number) => id === 21,
              then: Yup.string().required(t("Required")),
            }),
          })}
        >
          {({ values, handleSubmit }) => (
            <Row className="mx-3">
              <GetInput
                inputprop={{
                  field: "emailTemplateId",
                  inputType: InputType.multiselect,
                  label: t("Taslak Tasarım Şablon Seçimi"),
                  lookup: {
                    data: templates,
                    labelKey: "Name",
                    valueKey: "Id",
                    placeholder: t("Seçiniz"),
                  },
                  onChange(value, item: EmailTemplateDto, setFieldValue) {
                    !value && setFieldValue("emailTemplateTypeId", "");
                    item && setFieldValue("subject", item.Subject);
                    item &&
                      setFieldValue(
                        "emailTemplateTypeId",
                        item.EmailTemplateTypeId
                      );
                    item && setFieldValue("usingLayout", item.UseLayout);
                    item && setFieldValue("content", item.Template);
                  },
                }}
              />
              <GetInput
                inputprop={{
                  field: "keys",
                  inputType: InputType.checkpicker,
                  label: t("Anahtar Kelime Seçimi"),
                  lookup: {
                    data: keys.map(x => {
                      return { Id: x, Name: x };
                    }),
                    labelKey: "Name",
                    valueKey: "Id",
                    placeholder: t("Seçiniz"),
                  },
                }}
              />
              {values.keys.length > 0 && (
                <div className="text-muted mb-2">
                  {"*Anahtar sözcükler [[key]] şeklinde içeriğe eklenmelidir!"}
                </div>
              )}
              <GetInput
                inputprop={{
                  field: "subject",
                  label: t("Konu"),
                }}
              />
              {values.usingLayout && (
                <div className="text-muted">
                  {"*Ana Tasarım Şablonu kullanılacaktır"}
                </div>
              )}
              <GetInput
                inputprop={{
                  field: "content",
                  label: t("İçerik"),
                  inputType: InputType.editor,
                }}
              />
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("Send")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};

export default SendEmailModal;
