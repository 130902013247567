import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";

const EmailLogsPage = () => {
  const { t } = useTranslation();
  const columns = [
    {
      caption: t("Error"),
      dataField: "Error",
      dataType: "boolean",
    },
    {
      caption: t("E-posta"),
      dataField: "Email",
    },
    {
      caption: t("E-posta Konusu"),
      dataField: "Subject",
    },

    {
      caption: t("Response Data"),
      dataField: "ResponseData",
    },
    {
      caption: t("System"),
      dataField: "SystemMail",
      dataType: "boolean",
    },

    {
      caption: t("Tarih"),
      dataField: "CreateTime",
      dataType: "datetime",
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/systemlogs/email",
          columns: columns,
          showColumnLines: true,
        }}
        useDevGrid={true}
        title={t("E-Posta Gönderim Logları")}
      />
    </React.Fragment>
  );
};

export default EmailLogsPage;
