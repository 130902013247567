import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { postSaga, PostSagaGenericParams } from "../base/request/post";
import { SagaActionParams } from "../base/types/saga";

export const sendNotificationSaga = createAction(
  "SEND_NOTIFICATION_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/notifications/send-notification",
    body,
  })
);
export const sendNotificationAllCustomerSaga = createAction(
  "SEND_NOTIFICATION_ALL_CUSTOMER_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/notifications/send-notification-all-customer",
    body,
  })
);
export const sendNotificationAllActiveCustomerSaga = createAction(
  "SEND_NOTIFICATION_ALL_ACTIVE_CUSTOMER_SAGA",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/notifications/send-notification-all-active-customer",
    body,
  })
);
const notificationSaga = [
  takeLatest(
    sendNotificationSaga.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    sendNotificationAllCustomerSaga.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
  takeLatest(
    sendNotificationAllActiveCustomerSaga.toString(),
    (payload: PostSagaGenericParams<any>) => postSaga<any>(payload)
  ),
];

export default notificationSaga;
