import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import {
  postSagaForVehicle,
  PostSagaGenericParams,
  putSaga,
} from "../base/request/post";
import { BodyType } from "../base/request/request";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  CustomerVehicleLookupDto,
  CustomerVehicleDocumentDto,
  CustomerVehicleDto,
} from "./type";
import { VehicleAllDataDto } from "./vehiclealltype";

export const getCustomerVehicleListByCustomerId = createAction(
  "GET_CUSTOMER_VEHICLE_LIST_BY_CUSTOMER_ID",
  ({ payload, params, id }: SagaActionParams<CustomerVehicleDto[]>) => ({
    payload,
    url: "/api/customervehicles/getbycustomerid/" + id + (params ? params : ""),
  })
);
export const getCustomerVehicleDetails = createAction(
  "GET_CUSTOMER_VEHICLE_DETAILS",
  ({ payload, id }: SagaActionParams<CustomerVehicleDto>) => ({
    payload,
    url: "/api/customervehicles/getbyid/" + id,
  })
);
export const getCustomerVehicleCategory = createAction(
  "GET_CUSTOMER_VEHICLE_CATEGORIES",
  ({ payload, body }: SagaActionParams<CustomerVehicleLookupDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/customervehicles/vehiclecategory",
  })
);
export const updateCustomerVehicle = createAction(
  "UPDATE_CUSTOMER_VEHICLE",
  ({ payload, body, id }: SagaActionParams<any>) => ({
    payload,
    body,
    url: "/api/customervehicles/" + id,
  })
);
export const getCustomerVehicleFullData = createAction(
  "GET_CUSTOMER_VEHICLE_FULL_DATA",
  ({ payload, body }: SagaActionParams<VehicleAllDataDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/customervehicles/vehiclealldata",
  })
);
export const getCustomerVehicleSubCategory = createAction(
  "GET_CUSTOMER_VEHICLE_SUBCATEGORIES",
  ({ payload, body }: SagaActionParams<CustomerVehicleLookupDto>) => ({
    payload,
    body,
    bodyType: BodyType.raw,
    url: "/api/customervehicles/vehicledetail",
  })
);
export const getCustomerVehicleDocumentList = createAction(
  "GET_CUSTOMER_VEHICLE_DOCUMENT_LIST",
  ({ payload, id }: SagaActionParams<CustomerVehicleDocumentDto[]>) => ({
    payload,
    url: "/api/customervehicles/getdocumentsbyvehicleid/" + id,
  })
);
const customervehiclessaga = [
  takeLatest(
    getCustomerVehicleListByCustomerId.toString(),
    (payload: SagaGenericParams<CustomerVehicleDto[]>) =>
      getListSaga<CustomerVehicleDto[]>(payload)
  ),
  takeLatest(
    getCustomerVehicleDocumentList.toString(),
    (payload: SagaGenericParams<CustomerVehicleDocumentDto[]>) =>
      getListSaga<CustomerVehicleDocumentDto[]>(payload)
  ),
  takeLatest(
    getCustomerVehicleDetails.toString(),
    (payload: SagaGenericParams<CustomerVehicleDto>) =>
      getListSaga<CustomerVehicleDto>(payload)
  ),
  takeLatest(
    getCustomerVehicleCategory.toString(),
    (payload: PostSagaGenericParams<CustomerVehicleLookupDto>) =>
      postSagaForVehicle<CustomerVehicleLookupDto>(payload)
  ),
  takeLatest(
    getCustomerVehicleSubCategory.toString(),
    (payload: PostSagaGenericParams<CustomerVehicleLookupDto>) =>
      postSagaForVehicle<CustomerVehicleLookupDto>(payload)
  ),
  takeLatest(
    getCustomerVehicleFullData.toString(),
    (payload: PostSagaGenericParams<VehicleAllDataDto>) =>
      postSagaForVehicle<VehicleAllDataDto>(payload)
  ),
  takeLatest(
    updateCustomerVehicle.toString(),
    (payload: PostSagaGenericParams<VehicleAllDataDto>) =>
      putSaga<VehicleAllDataDto>(payload)
  ),
];

export default customervehiclessaga;
