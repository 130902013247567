import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { ReminderSettingDto } from "./type";
import { BodyType } from "../base/request/request";

export const getReminderSettingList = createAction(
  "GET_REMINDER_SETTINGS_LIST",
  ({ payload, params }: SagaActionParams<ReminderSettingDto[]>) => ({
    payload,
    url: "/api/remindersettings" + (params ? params : ""),
  })
);

export const getReminderSettingDetails = createAction(
  "GET_REMINDER_SETTINGS_DETAILS",
  ({ payload, id }: SagaActionParams<ReminderSettingDto>) => ({
    payload,
    url: "/api/remindersettings/" + id,
  })
);
export const createReminderSetting = createAction(
  "CREATE_REMINDER_SETTINGS_DETAILS",
  ({ payload, body }: SagaActionParams<ReminderSettingDto>) => ({
    payload,
    url: "/api/remindersettings",
    body,
  })
);
export const updateReminderSetting = createAction(
  "UPDATE_REMINDER_SETTINGS_DETAILS",
  ({ payload, body, id }: SagaActionParams<ReminderSettingDto>) => ({
    payload,
    url: "/api/remindersettings/" + id,
    body,
  })
);
export const deleteReminderSetting = createAction(
  "DELETE_REMINDER_SETTINGS_DETAILS",
  ({ payload, body, id }: SagaActionParams<ReminderSettingDto>) => ({
    payload,
    url: "/api/remindersettings/" + id,
    body,
  })
);

export const changeReminderSettingOrder = createAction(
  "UPDATE_REMINDER_SETTINGS_ORDER",
  ({ payload, body }: SagaActionParams<ReminderSettingDto>) => ({
    payload,
    url: "/api/remindersettings/order",
    body,
    bodyType: BodyType.raw,
  })
);
const reminderSettingsSaga = [
  takeLatest(
    getReminderSettingList.toString(),
    (payload: SagaGenericParams<ReminderSettingDto[]>) =>
      getListSaga<ReminderSettingDto[]>(payload)
  ),
  takeLatest(
    getReminderSettingDetails.toString(),
    (payload: SagaGenericParams<ReminderSettingDto>) =>
      getListSaga<ReminderSettingDto>(payload)
  ),
  takeLatest(
    updateReminderSetting.toString(),
    (payload: PostSagaGenericParams<ReminderSettingDto>) =>
      putSaga<ReminderSettingDto>(payload)
  ),
];

export default reminderSettingsSaga;
