import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignDto } from "src/store/campaigns/type";
import MainPage from "src/components/PageContent/MainPage";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch, useSelector } from "react-redux";
import CancelAlert from "src/components/CancelAlert";
import { deleteCampaign, getCampaignList } from "src/store/campaigns/saga";
import moment from "moment";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import { CheckRole } from "src/permissions/checkPermission";
import { userSelector } from "src/store/auth";

const CampaignPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState<CampaignDto[]>([]);
  const [showDelete, setShowDelete] = useState<CampaignDto>();
  const history = useHistory();
  const user = useSelector(userSelector);
  const columns = [
    {
      text: t("Category Name"),
      dataField: "PrivilegeCategoryName",
    },
    {
      text: t("Supplier Name"),
      dataField: "SupplierName",
    },
    {
      text: t("Title"),
      dataField: "Title",
    },
    {
      text: t("Start Date"),
      dataField: "StartDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },
    {
      text: t("End Date"),
      dataField: "EndDate",
      formatter: (cellContent: any, data: any) =>
        moment(cellContent).format("DD/MM/yyyy HH:mm"),
    },

    {
      text: t("Coupon Count"),
      dataField: "CouponCount",
    },
    {
      text: t("Aktif"),
      dataField: "Active",
      formatter: (cellContent: boolean) =>
        cellContent ? (
          <i className="text-success bx  bx-check-square"></i>
        ) : (
          <i className="text-danger mdi mdi-close-box-outline"></i>
        ),
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kart && (
            <ColumnButton
              buttonType="customtext"
              custombutton={{
                color: "#5156be",
                text: t("Kartlar"),
              }}
              onClick={() => {
                history.push("/privilegecards/" + cellContent);
              }}
            />
          )}
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#2ab57d",
              text: t("Buttonlar"),
            }}
            onClick={() => {
              history.push("/privilegebuttons/" + cellContent);
            }}
          />
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              color: "#9e9e9e",
              text: t("Lokasyonlar"),
            }}
            onClick={() => {
              history.push("/privilege-locations/" + cellContent);
            }}
          />
          {CheckRole(user) && (
            <>
              {(data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon ||
                data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.QR) && (
                <ColumnButton
                  buttonType="customtext"
                  custombutton={{
                    text:
                      data.PrivilegeUsageTypeId === PrivilegeUsageTypeEnum.Kupon
                        ? t("Coupons")
                        : t("QR Codes"),
                  }}
                  onClick={() => {
                    history.push("/privilege-codes/" + cellContent);
                  }}
                />
              )}
              {data.ShowTerms && (
                <ColumnButton
                  buttonType="customtext"
                  custombutton={{
                    color: "#2ab57d",
                    text: t("Koşullar"),
                  }}
                  onClick={() => {
                    history.push("/privilegeterms/" + cellContent);
                  }}
                />
              )}
              <ColumnButton
                buttonType="edit"
                onClick={() => {
                  history.push("/campaign/edit/" + cellContent);
                }}
              />
              <ColumnButton
                buttonType="delete"
                onClick={() => {
                  return setShowDelete(data);
                }}
              />
            </>
          )}
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getCampaignList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  };
  return (
    <React.Fragment>
      <MainPage
        title={t("CampaignTitle")}
        gridProps={{
          listData: data,
          columns: columns,
          create: {
            createType: "link",
            createLink: "/campaign/create",
          },
          setDataList: setData,
          action: getCampaignList,
        }}
      />
      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete campaign`, {
            name: `${showDelete.Title} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteCampaign({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CampaignPage;
