import React, { useState } from "react";
import { TicketAnswerDto, TicketDetailDto } from "src/store/ticket/type";
import { baseImageUrl } from "src/store/base/types/url";
import Lightbox from "react-image-lightbox";
import images from "src/assets/image";
import moment from "moment";
const TicketMessageContainer = ({
  ticket,
  message,
}: {
  ticket: TicketDetailDto;
  message: TicketAnswerDto;
}) => {
  const [isEffects, setisEffects] = useState<boolean>(false);
  return (
    <React.Fragment>
      <div
        className={
          "comment leftMessage " +
          (message.CustomerId === null ? " rightMessage" : " leftMessage")
        }
      >
        <div className=" messageBox ">
          {message.CustomerId ? (
            <div className="text-muted d-flex justify-content-start pe-1 font-size-10 mb-1 ps-2">
              {ticket.Name} {ticket.Surname}{" "}
              {moment(message.CreateTime).format("DD/MM/yyyy HH:mm")}
            </div>
          ) : (
            <div className="text-muted d-flex justify-content-start pe-1 font-size-10 mb-1 ps-2">
              {message.CreatedUserName}{" "}
              {moment(message.CreateTime).format("DD/MM/yyyy HH:mm")}
            </div>
          )}
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: message.Message }}
          />
          <div className="d-flex flex-row flex-wrap justify-content-start gap-2 mx-2">
            {message.Documents &&
              message.Documents.map(x => (
                <img
                  className="cursor-pointer"
                  style={{
                    cursor: "pointer",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                  src={images.filedownload}
                  width={30}
                  alt=""
                  onClick={() => {
                    window.open(baseImageUrl + x, "_blank");
                  }}
                />
              ))}
            {message.Images &&
              message.Images.map(x => (
                <>
                  <img
                    className="cursor-pointer"
                    style={{
                      cursor: "pointer",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                    src={baseImageUrl + x}
                    width={50}
                    alt=""
                    onClick={() => {
                      setisEffects(!isEffects);
                    }}
                  />
                  {isEffects ? (
                    <Lightbox
                      mainSrc={baseImageUrl + x}
                      enableZoom={true}
                      onCloseRequest={() => {
                        setisEffects(!isEffects);
                      }}
                    />
                  ) : null}
                </>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TicketMessageContainer;
