import React from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory } from "react-router";
const TireSalesPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = [
    {
      caption: t("Code"),
      dataField: "Code",
    },
    {
      caption: t("Müşteri"),
      dataField: "CustomerFullName",
    },
    {
      caption: t("Telefon"),
      dataField: "CustomerPhoneNumber",
    },

    {
      caption: t("Durum"),
      dataField: "TireSaleStatusName",
    },
    {
      caption: t("Tarih"),
      dataField: "SalesDate",
      dataType: "datetime",
    },

    {
      caption: t("Toplam"),
      dataField: "TotalPrice",
    },
    {
      caption: t("Kargo"),
      dataField: "ShippingTotal",
    },
    {
      caption: t("Genel Toplam"),
      dataField: "GrandTotal",
    },
    {
      caption: t("G.Toplam Komisyon"),
      dataField: "GrandTotalWithCommission",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            history.push("/tire-sales/" + e.row.key);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      <MainPage
        devProps={{
          url: "/api/tiresales/devexp",
          columns: columns,
        }}
        useDevGrid={true}
        title={t("Lastik Satışları")}
      />
    </React.Fragment>
  );
};

export default TireSalesPage;
