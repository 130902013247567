import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import {
  postSaga,
  PostSagaGenericParams,
  postwithApiResponseSaga,
  putSaga,
  putwithApiResponseSaga,
} from "../base/request/post";
import { SagaActionParams } from "../base/types/saga";
import { LoginResponseModel } from "./types";

export const registerAction = createAction(
  "REGISTER",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/register",
    body,
  })
);

export const loginAction = createAction(
  "LOGIN_ACTION",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/admin/login",
    body,
  })
);

export const forgotPassword = createAction(
  "FORGOT_PASSWORD",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/send-password-reset-token",
    body,
  })
);

export const resetPassword = createAction(
  "RESET_PASSWORD",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/reset-password",
    body,
  })
);
export const updateClientProfile = createAction(
  "UPDATE_ACCOUNT_PROFILE",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/myclient/updateclientdetail",
    body,
  })
);
export const emailConfirm = createAction(
  "CONFIRM_EMAIL",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/email-confirm",
    body,
  })
);
export const updateUserDepartment = createAction(
  "UPDATE_USER_DEPARTMENT",
  ({ payload, body }: SagaActionParams<any>) => ({
    payload,
    url: "/api/appuser/updateforfirstlogin",
    body,
  })
);
export const getActiveUser = createAction(
  "GET_ACTIVE_USER",
  ({ payload }: SagaActionParams<LoginResponseModel>) => ({
    payload,
    url: "/api/appuser/activeuser",
  })
);
const authSaga = [
  takeLatest(registerAction.toString(), (payload: PostSagaGenericParams<any>) =>
    postwithApiResponseSaga<any>(payload)
  ),
  takeLatest(loginAction.toString(), (payload: PostSagaGenericParams<any>) =>
    postwithApiResponseSaga<any>(payload)
  ),
  takeLatest(forgotPassword.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),
  takeLatest(resetPassword.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),
  takeLatest(emailConfirm.toString(), (payload: PostSagaGenericParams<any>) =>
    postSaga<any>(payload)
  ),
  takeLatest(
    updateClientProfile.toString(),
    (payload: PostSagaGenericParams<any>) =>
      putwithApiResponseSaga<any>(payload)
  ),
  takeLatest(
    updateUserDepartment.toString(),
    (payload: PostSagaGenericParams<any>) => putSaga<any>(payload)
  ),
  takeLatest(getActiveUser.toString(), (payload: PostSagaGenericParams<any>) =>
    getListSaga<any>(payload)
  ),
];

export default authSaga;
