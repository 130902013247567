import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createTireSupplierInfo } from "src/store/tires/supplierinfos/saga";
import { getTireSupplierList } from "src/store/tires/suppliers/saga";
import { TireSupplierDto } from "src/store/tires/suppliers/type";
import * as Yup from "yup";

const CreateTireSupplierInfo = ({
  onHide,
  tireId,
  open,
  onSuccessClick,
}: {
  tireId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [suppliers, setSupplier] = useState<TireSupplierDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getTireSupplierList({
          payload: {
            onSuccess: (msg, py) => setSupplier(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Yeni Lastik Tedarikçi Bilgileri"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          TireId: tireId,
          TireSupplierId: "",
          SupplierStockCode: "",
          UnitPrice: 0,
          TaxRate: 0,
          TotalPrice: 0,
        }}
        onSubmit={values => {
          dispatch(
            createTireSupplierInfo({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          TireId: Yup.string().required(t("Required")),
          TireSupplierId: Yup.string().required(t("Required")),
          SupplierStockCode: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "TireSupplierId",
            inputType: InputType.multiselect,
            label: t("Tedarikçi"),
            lookup: {
              data: suppliers,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Tedarikçi Seçiniz"),
            },
          },
          {
            field: "SupplierStockCode",
            label: t("Tedarikçi Stok Kodu"),
          },
          {
            col: 6,
            field: "UnitPrice",
            inputType: InputType.number,
            label: t("Birim Fiyat"),
          },
          {
            col: 6,
            field: "TaxRate",
            inputType: InputType.number,
            label: t("Vergi Oranı"),
          },
          {
            col: 6,
            field: "TotalPrice",
            inputType: InputType.number,
            label: t("Toplam Fiyat"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreateTireSupplierInfo;
