import { custom } from "devextreme/ui/dialog";

const ErrorDetailDialog = ({ error }: { error: string }) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        let confirmResult = custom({
          title: "Hata Detayı",
          messageHtml: error,
          buttons: [
            {
              text: "Tamam",
              onClick: function () {
                return true;
              },
            },
          ],
        });
        confirmResult.show().then(async (dialogResult: any) => {
          if (dialogResult) {
          }
        });
      }}
    >
      {error.slice(0, 4)}... <i className="dx dx-icon-info" />
    </div>
  );
};
export default ErrorDetailDialog;
