import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataBankCategoryDto } from "src/store/databank-category/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreateDataBankCategory from "./create";
import { toast } from "react-toastify";
import EditDataBankCategory from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  changeDataBankCategoryOrder,
  deleteDataBankCategory,
  getDataBankCategoryDetails,
  getDataBankCategoryList,
} from "src/store/databank-category/saga";
import { useHistory, useParams } from "react-router";
import PageDrageAndDropTable from "src/components/DraggableTable/PageDraggableTable";

const DataBankCategoryPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<DataBankCategoryDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<DataBankCategoryDto[]>([]);
  const [showDelete, setShowDelete] = useState<DataBankCategoryDto>();
  useEffect(() => {
    dispatch(
      getDataBankCategoryList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  const columns = [
    {
      text: t("Order"),
      dataField: "Order",
    },
    {
      text: t("Category Name"),
      dataField: "CategoryName",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          {" "}
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              text: "Bilgi Bankası",
            }}
            onClick={() => {
              return history.push("/databank-order/" + cellContent);
            }}
          />
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getDataBankCategoryDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getDataBankCategoryList({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {selected && (
        <EditDataBankCategory
          data={selected}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={onSuccessClick}
        />
      )}
      <CreateDataBankCategory
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={onSuccessClick}
      />
      <PageDrageAndDropTable
        columns={columns}
        data={data}
        onSuccessClick={onSuccessClick}
        dragAction={changeDataBankCategoryOrder}
        title={t("DataBankCategoryTitle")}
        create={{
          createType: "drawer",
          onCreateClick: () => setShowCreate(true),
        }}
      />

      {showDelete && (
        <CancelAlert
          message={t(`Are you sure you want to delete databank category`, {
            name: `${showDelete.CategoryName} `,
          })}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteDataBankCategory({
                payload: {
                  onSuccess: message => onSuccessClick(message),
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DataBankCategoryPage;
