import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PrivilegeCardDetailDto,
  PrivilegeCardDto,
  PrivilegeCardStatusEnum,
} from "src/store/privilegecard/type";
import { useDispatch } from "react-redux";
import DataGrid, { Item } from "devextreme-react/data-grid";
import CreatePrivilegeCard from "./create";
import { toast } from "react-toastify";
import EditPrivilegeCard from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  deletePrivilegeCard,
  getPrivilegeCardDetails,
  printPrivilegeCard,
  sendPrivilegeCard,
  setActivePrivilegeCard,
  updatePrivilegeCardStatus,
} from "src/store/privilegecard/saga";
import { useHistory, useParams } from "react-router";
import { PrivilegeDto } from "src/store/privilege/type";
import { getPrivilegeDetails } from "src/store/privilege/saga";
import ImportExcel from "./importExcel";
import DevextGrid from "src/components/PageContent/DevextGrid";
import { Container } from "reactstrap";
import { DropDownButton } from "devextreme-react";
import CardSendForm from "../PrivilegeCardUsing/sendForm";
import CardHistory from "../PrivilegeCardUsing/details";

const PrivilegeCardPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataGridRef = useRef<DataGrid>();
  const history = useHistory();
  const [showActionButton, setShowActionButton] = useState<any>();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
  const [selected, setSelected] = useState<PrivilegeCardDetailDto>();
  const [showCreate, setShowCreate] = useState(false); // eslint-disable-next-line
  const [data, setData] = useState<PrivilegeCardDto[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showDelete, setShowDelete] = useState<PrivilegeCardDto>();
  const [showUpdateStatus, setShowUpdateStatus] = useState<{
    show: boolean;
    url: string;
    label: string;
    cards: string[];
  }>();
  const [privilege, setPrivilege] = useState<PrivilegeDto>();
  useEffect(() => {
    dispatch(
      getPrivilegeDetails({
        payload: {
          onSuccess: (msg, payload) => setPrivilege(payload),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const onOpenModalFunc = (url: string, label: string) => {
    let cards: any[] = dataGridRef.current?.instance.getSelectedRowKeys() ?? [];
    if (cards.length <= 0) {
      toast.error("Kart seçiniz");
    } else {
      setShowUpdateStatus({
        show: true,
        cards: cards,
        label: label,
        url: url,
      });
    }
  };
  const columns = [
    {
      caption: t("Kart Numarası"),
      dataField: "CardNumber",
    },
    {
      caption: t("Müşteri Adı"),
      dataField: "CustomerName",
    },
    {
      caption: t("Durum"),
      dataField: "LastStatusName",
    },
    {
      type: "buttons",
      buttons: [
        {
          hint: "Detay",
          text: "Gönder",
          visible: (e: any) =>
            e.row.data.LastStatusId === PrivilegeCardStatusEnum.KartBasildi,
          cssClass: "text-dark bg-soft-success columnUseButton font-size-10",
          onClick: async (e: any) => {
            var action = GetActions(e.row.data.LastStatusId, e.row.key);
            setShowActionButton(action);
          },
        },
        {
          hint: "Detay",
          text: "Aktif Et",
          visible: (e: any) =>
            e.row.data.LastStatusId === PrivilegeCardStatusEnum.Gonderildi,
          cssClass: "text-dark bg-soft-success columnUseButton font-size-10",
          onClick: async (e: any) => {
            var action = GetActions(e.row.data.LastStatusId, e.row.key);
            setShowActionButton(action);
          },
        },
        {
          hint: "Detay",
          text: "Kart Basıldı",
          visible: (e: any) =>
            e.row.data.LastStatusId === PrivilegeCardStatusEnum.IslemBekliyor,
          cssClass: "text-dark bg-soft-success columnUseButton font-size-10",
          onClick: async (e: any) => {
            var action = GetActions(e.row.data.LastStatusId, e.row.key);
            setShowActionButton(action);
          },
        },
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            dispatch(
              getPrivilegeCardDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowDetails(true);
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },

        {
          hint: "Düzenle",
          icon: "edit",
          onClick: async (e: any) => {
            dispatch(
              getPrivilegeCardDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowEdit(true);
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
  };
  const GetActions = (statusId: number, id: string) => {
    switch (statusId) {
      case PrivilegeCardStatusEnum.IslemBekliyor:
        return {
          buttonTitle: "Kartı Basıldı İşaretle",
          action: printPrivilegeCard,
          qa: "Kartı basıldı olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      case PrivilegeCardStatusEnum.KartBasildi:
        return {
          buttonTitle: "Kartı Gönderildi İşaretle",
          action: sendPrivilegeCard,
          qa: "Kartı gönderildi olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      case PrivilegeCardStatusEnum.Gonderildi:
        return {
          buttonTitle: "Kartı Aktif Edildi İşaretle",
          action: setActivePrivilegeCard,
          qa: "Kartı aktif edildi olarak işaretlemek istediğinize emin misiniz?",
          id: id,
          statusId: statusId,
        };
      default:
        return undefined;
    }
  };
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <Container fluid>
          <React.Fragment>
            <div
              className={
                "mb-3 d-flex flex-wrap align-items-center flex-wrap justify-content-between"
              }
            >
              <h5 className="mb-0 font-size-18">
                {(privilege ? privilege.Title : "") + " " + t("Kartlar")}
              </h5>
            </div>
            <DevextGrid
              ref={dataGridRef}
              title={t("Customers")}
              url={"/api/privilegecards/devexp/" + id}
              columns={columns}
              selection={{ mode: "multiple", allowSelectAll: true }}
              gridItems={
                <Item>
                  <button
                    className="btn btn-info me-1"
                    onClick={() => setShowExcel(true)}
                  >
                    <i className="mdi mdi-microsoft-excel "></i>İçe Aktar
                  </button>
                  <button
                    className="btn btn-secondary me-1"
                    onClick={() => history.push("/report-viewer")}
                  >
                    Barkod
                  </button>
                  <button
                    className="btn btn-light me-1"
                    onClick={() =>
                      history.push("/privilege/customer-privileges/" + id)
                    }
                  >
                    Üye Hakları Sayfasını Aç
                  </button>

                  <DropDownBox
                    title={"Seçili Kart Durum Değiştir"}
                    setShow={(k, l) => {
                      return onOpenModalFunc(k, l);
                    }}
                  />
                </Item>
              }
              create={() => setShowCreate(true)}
            />
            {showDetails && selected && (
              <CardHistory
                data={selected}
                onHide={() => setShowDetails(false)}
                open={showDetails}
                onSuccessClick={onSuccessClick}
              />
            )}
            {selected && (
              <EditPrivilegeCard
                data={selected}
                onHide={() => setShowEdit(false)}
                open={showEdit}
                onSuccessClick={onSuccessClick}
              />
            )}
            <CreatePrivilegeCard
              privilegeId={id}
              onHide={() => setShowCreate(false)}
              open={showCreate}
              onSuccessClick={onSuccessClick}
            />
            <ImportExcel
              privilegeId={id}
              onHide={() => setShowExcel(false)}
              open={showExcel}
              onSuccessClick={onSuccessClick}
            />
            {showUpdateStatus && (
              <CancelAlert
                message={showUpdateStatus.label}
                onConfirm={() => {
                  setShowUpdateStatus(undefined);
                  dispatch(
                    updatePrivilegeCardStatus({
                      payload: {
                        onSuccess: (message, p: PrivilegeCardDto[]) => {
                          //  window.open("/privilegecards/" + id, "_blank");
                          if (p && p.length > 0) {
                            toast.warning(
                              "Gönderildi durumunda yer alan kartlar aktif edildi. Aktif edilemeyen kartlar:" +
                                p.map(x => `${x.CardNumber}`).toString(),
                              {
                                autoClose: false,
                              }
                            );
                          } else {
                            toast.success("Başarılı");
                          }
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: {
                        CardIds: showUpdateStatus.cards,
                      },
                      url: showUpdateStatus.url,
                    })
                  );
                }}
                onCancel={() => {
                  setShowUpdateStatus(undefined);
                }}
              />
            )}
            {showDelete && (
              <CancelAlert
                message={t(`Silmek istediğine emin misiniz?`, {
                  name: `${showDelete.CardNumber} `,
                })}
                onConfirm={() => {
                  setShowDelete(undefined);
                  dispatch(
                    deletePrivilegeCard({
                      payload: {
                        onSuccess: message => onSuccessClick(message),
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      id: showDelete.Id,
                    })
                  );
                }}
                onCancel={() => {
                  setShowDelete(undefined);
                }}
              />
            )}
            {showActionButton && (
              <>
                {showActionButton.statusId ===
                PrivilegeCardStatusEnum.KartBasildi ? (
                  <>
                    <CardSendForm
                      cardId={showActionButton.id}
                      onHide={() => {}}
                      open={true}
                      onSuccessClick={m => {
                        onSuccessClick(m);
                        return setShowActionButton(undefined);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <CancelAlert
                      message={showActionButton.qa}
                      onConfirm={() => {
                        dispatch(
                          showActionButton.action({
                            payload: {
                              onSuccess: (message: any, payload: any) => {
                                onSuccessClick(message);
                              },
                              onError: (message: any) => {
                                toast.error(message, {
                                  theme: "colored",
                                });
                              },
                            },
                            body: { CardId: showActionButton.id },
                          })
                        );
                        setShowActionButton(undefined);
                      }}
                      onCancel={() => {
                        setShowActionButton(undefined);
                      }}
                    />
                  </>
                )}
              </>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivilegeCardPage;
const DropDownBox = ({
  title,
  setShow,
}: {
  title: string;
  setShow: (url: string, label: string) => void;
}) => {
  const list = [
    {
      url: "/api/privilegecards/print-all-card",
      label: "Kart Basıldı İşaretle",
      msg: "Seçili kartları basıldı işaretlemek istediğine emin misiniz?",
    },
    {
      url: "/api/privilegecards/set-active-all-card",
      label: "Kartları Aktif Et",
      msg: "Seçili kartları aktif etmek istediğine emin misiniz?",
    },
    {
      url: "/api/privilegecards/set-all-cancelled",
      label: "Kartları İptal Et",
      msg: "Seçili kartları iptal etmek istediğine emin misiniz?",
    },
  ];

  return (
    <DropDownButton
      text={title}
      items={list}
      displayExpr="label"
      keyExpr="url"
      onItemClick={e => {
        setShow(e.itemData.url, e.itemData.msg);
      }}
    />
  );
};
