import FileSaver from "file-saver";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Card, Col, Row } from "reactstrap";
import images from "src/assets/image";
import CustomModal from "src/components/Modal/modal";
import { baseImageUrl } from "src/store/base/types/url";
import { deleteSupplierDocument } from "src/store/supplierdocuments/saga";
import { getSupplierLocationDocument } from "src/store/supplierlocations/saga";
import {
  SupplierLocationDocumentDto,
  SupplierLocationDto,
} from "src/store/supplierlocations/type";
import CreateSupplierLocationDocumentModal from "./createDocument";

const LocationDocumentModal = ({
  show,
  onHide,
  location,
  setShow,
}: {
  setShow: (show: boolean) => void;
  location: SupplierLocationDto;
  show: boolean;
  onHide: () => void;
}) => {
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const [documents, setDocuments] = useState<SupplierLocationDocumentDto[]>([]);
  useEffect(() => {
    show &&
      dispatch(
        getSupplierLocationDocument({
          payload: {
            onSuccess: (msg, payload) => setDocuments(payload),
            onError: () => {},
          },
          id: location.Id,
        })
      );
  }, [dispatch, location.Id, show]);
  return (
    <>
      <CreateSupplierLocationDocumentModal
        onHide={() => {
          setShowCreate(false);
          return setShow(true);
        }}
        supplierLocation={location}
        onSuccessClick={() => {
          return setShowCreate(false);
        }}
        open={showCreate}
      />
      <CustomModal
        onHide={onHide}
        open={show}
        size={"sm"}
        title={
          <div className="d-flex justify-content-between ">
            <div>{location.Name + " Lokasyon Dokümanları"}</div>
            <div>
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  onHide();
                  return setShowCreate(true);
                }}
              >
                Yeni Doküman
              </button>
            </div>
          </div>
        }
      >
        <div className="pt-3 pb-5 px-3  w-100">
          {documents.map(x => (
            <Col key={x.Id} md={12}>
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={"-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height={40}
                        className="avatar-sm rounded bg-light"
                        alt={x.Name}
                        src={baseImageUrl + x.Document}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = images.doc;
                        }}
                      />
                    </Col>
                    <Col>
                      <span
                        style={{ cursor: "pointer" }}
                        className="text-muted font-weight-bold"
                        onClick={() => {
                          FileSaver.saveAs(baseImageUrl + x.Document, x.Name);
                        }}
                      >
                        {x.Name}
                      </span>
                    </Col>
                    <Col xs={1}>
                      <p
                        className="mb-0 text-pr deleteHover cursor-pointer font-size-14"
                        onClick={() => {
                          FileSaver.saveAs(baseImageUrl + x.Document, x.Name);
                        }}
                      >
                        <strong>
                          <i className="bx bxs-download font-size-20" />
                        </strong>
                      </p>
                    </Col>
                    <Col xs={1}>
                      <p
                        className="mb-0 text-danger deleteHover cursor-pointer"
                        onClick={() => {
                          dispatch(
                            deleteSupplierDocument({
                              payload: {
                                onSuccess: message => {
                                  toast.success("Doküman silindi");
                                  dispatch(
                                    getSupplierLocationDocument({
                                      payload: {
                                        onSuccess: (msg, payload) =>
                                          setDocuments(payload),
                                        onError: () => {},
                                      },
                                      id: location.Id,
                                    })
                                  );
                                },
                                onError: message => {
                                  toast.error(message, {
                                    theme: "colored",
                                  });
                                },
                              },
                              id: x.Id,
                            })
                          );
                        }}
                      >
                        <strong>
                          <i className=" bx bx-trash-alt font-size-20"></i>
                        </strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
          {documents.length <= 0 && (
            <div className=" text-muted w-100 text-center">
              Doküman bulunamadı{" "}
              <span
                className="text-info text-underline"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onHide();
                  return setShowCreate(true);
                }}
              >
                Eklemek için tıklayınız.
              </span>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
};
export default LocationDocumentModal;
