import React from "react";
import { useTranslation } from "react-i18next";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { CorporateDepartmentDto } from "src/store/corporate-departments/type";
import * as Yup from "yup";

const DetailCorporateDepartment = ({
  data,
  onHide,
  open,
}: {
  data: CorporateDepartmentDto;
  onHide: () => void;
  open: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: "Departman Detayları",
        }}
        onHide={onHide}
        hideButtons
        loader
        initialValues={{
          Id: data.Id,
          CorporateCompanyId: "",
          CorporateCompanyCompanyName: data.CorporateCompanyCompanyName,
          DepartmentName: data.DepartmentName ?? "",
          AuthorizedManagerId: data.AuthorizedManagerId ?? "",
          AuthorizedManager: data.AuthorizedManagerId
            ? data.AuthorizedManagerName ??
              "" + data.AuthorizedManagerSurname ??
              ""
            : "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={values => {}}
        inputs={[
          {
            disabled(values) {
              return true;
            },
            field: "DepartmentName",
            label: "Departman Adı",
          },
          {
            disabled(values) {
              return true;
            },
            field: "CorporateCompanyCompanyName",
            label: "Şirket Adı",
          },
          {
            disabled(values) {
              return true;
            },
            field: "AuthorizedManager",
            label: "Yönetici",
          },
          {
            disabled(values) {
              return true;
            },
            field: "Active",
            label: t("Active"),
            col: 6,
            checkedLabel: t("Active"),
            uncheckedLabel: t("Pasive"),
            inputType: InputType.toogle,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default DetailCorporateDepartment;
