import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { AgreementDto, AgreementTypeDto } from "src/store/agreement/type";
import {
  createAgreementChangeInfo,
  getAgreementChangeInfoDetail,
  getAgreementTypeList,
} from "src/store/agreement/saga";
import { useHistory, useParams } from "react-router";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";

const CreateAgreementChangeInfosPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(isLoading);
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const [data, setData] = useState<AgreementDto>();
  const [types, setTypes] = useState<AgreementTypeDto[]>([]);
  useEffect(() => {
    dispatch(
      getAgreementTypeList({
        payload: {
          onSuccess: (msg, py) => setTypes(py),
          onError: () => {},
        },
      })
    );
    dispatch(
      getAgreementChangeInfoDetail({
        payload: {
          onSuccess: (msg, py) => setData(py),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={t("Yeni Sözleşme")}
            breadcrumbItem={t("Yeni Sözleşme")}
          />
          {loading && <Loader />}
          <Row className="mt-4">
            {data && (
              <CustomFormik
                component={
                  <div>
                    Anahtar Kelimeler: [namesurname] [name] [surname] [date]
                  </div>
                }
                loader
                initialValues={{
                  AgreementTypeId: data.AgreementTypeId,
                  Title: data.Title,
                  Content: data.Content,
                }}
                onSubmit={values => {
                  dispatch(
                    createAgreementChangeInfo({
                      payload: {
                        onSuccess: (message, payload) => {
                          history.push(
                            "/agreements/change-infos/details/" + payload.Id
                          );
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  Content: Yup.string().required(t("Required")),
                  Title: Yup.string().required(t("Required")),
                  AgreementTypeId: Yup.string().required(t("Required")),
                })}
                inputs={[
                  {
                    field: "AgreementTypeId",
                    inputType: InputType.multiselect,
                    label: t("Sözleşme Tipi"),
                    lookup: {
                      data: types,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Sözleşme Tipi Seçiniz"),
                    },
                  },

                  {
                    field: "Title",
                    label: t("Title"),
                    inputType: InputType.multilinetext,
                  },
                  {
                    field: "Content",
                    label: t("İçerik"),
                    className: "lg-custom-wrapper",
                    inputType: InputType.editor,
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateAgreementChangeInfosPage;
