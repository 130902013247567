import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { Alert, Card, Col, Container, Row, Table } from "reactstrap";
import { useParams } from "react-router";
import Loader from "src/components/Loader";
import {
  getCustomerVehicleDetails,
  getCustomerVehicleDocumentList,
} from "src/store/customervehicles/saga";
import {
  CustomerVehicleDocumentDto,
  CustomerVehicleDto,
} from "src/store/customervehicles/type";
import { isLoading } from "src/store/loader";
import DocumentCard from "./documentCard";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CustomerDetailDto } from "src/store/customers/type";
import { getCustomerDetails } from "src/store/customers/saga";

const CustomerVehicleDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [data, setData] = useState<CustomerVehicleDto>();
  const [customer, setCustomer] = useState<CustomerDetailDto>();
  const [documents, setDocuments] = useState<CustomerVehicleDocumentDto[]>([]);
  const loading = useSelector(isLoading);
  useEffect(() => {
    dispatch(
      getCustomerVehicleDetails({
        payload: {
          onSuccess: (ms, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getCustomerVehicleDocumentList({
        payload: {
          onSuccess: (ms, payload) => {
            setDocuments(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  useEffect(() => {
    data &&
      dispatch(
        getCustomerDetails({
          payload: {
            onSuccess: (ms, payload) => {
              setCustomer(payload);
            },
            onError: () => {},
          },
          id: data.CustomerId,
        })
      );
  }, [dispatch, data]);
  const table = [
    {
      label: t("Plate"),
      value: data?.Plate,
    },
    {
      label: t("Brand"),
      value: data?.make,
    },
    {
      label: t("Model"),
      value: data?.model,
    },
    {
      label: t("Model Yılı"),
      value: data?.regdate,
    },
    {
      label: t("Registration Date"),
      value: data?.RegistrationDate
        ? moment(data?.RegistrationDate).format("DD/MM/yyyy HH:mm")
        : "-",
    },
    {
      label: t("Color"),
      value: data?.VehicleColorName,
    },
    {
      label: t("Body"),
      value: data?.body,
    },
    {
      label: t("Chassis Number"),
      value: data?.ChassisNumber,
    },
    {
      label: t("Body Type"),
      value: data?.BodyTypeName,
    },
    {
      label: t("Fuel Type"),
      value: data?.FuelTypeName,
    },
    {
      label: t("Gear Type"),
      value: data?.GearTypeName,
    },
    {
      label: t("Engine"),
      value: data?.engine,
    },
    {
      label: t("Engine Number"),
      value: data?.EngineNumber,
    },
  ];
  return (
    <React.Fragment>
      {loading && <Loader />}
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Üye Araç Detay | Servis Plan</title>
          </MetaTags>
          {data && (
            <Container fluid>
              <Breadcrumbs
                breadcrumbItem={`${data.make} ${data.model}`}
                title={t("Dashboard")}
              />
              <Row>
                <Card className="px-4 py-2">
                  <Table className="table-borderless">
                    <tr>
                      <th>{"Aktif"}</th>
                      <td>
                        {data.Active ? (
                          <i className="text-success bx  bx-check-square"></i>
                        ) : (
                          <i className="text-danger mdi mdi-close-box-outline"></i>
                        )}
                      </td>
                    </tr>

                    {customer && (
                      <tr>
                        <th>Üye</th>
                        <td>
                          {customer.Name} {customer.Surname}
                        </td>
                      </tr>
                    )}
                    {table.map((x, i) => (
                      <tr key={i}>
                        <th>{x.label}</th>
                        <td>{x.value}</td>
                      </tr>
                    ))}
                  </Table>
                  {data.VehicleChangeStatusName && (
                    <Alert color="info" style={{ marginTop: "13px" }}>
                      Araç Değişim Durumu : {data.VehicleChangeStatusName}
                    </Alert>
                  )}
                </Card>
              </Row>
              <div className="mt-4">
                <h5>Doküman Listesi</h5>
                <Row>
                  {documents.map(x => (
                    <Col key={x.Id} md={6}>
                      <DocumentCard document={x} />
                    </Col>
                  ))}
                  {documents.length <= 0 && (
                    <div className=" text-muted">Doküman bulunamadı</div>
                  )}
                </Row>
              </div>
            </Container>
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default CustomerVehicleDetails;
