import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { SocialMediaDto } from "./type";

export const getSocialMedias = createAction(
  "GET_SOCIAL_MEDIAS",
  ({ payload, params }: SagaActionParams<SocialMediaDto[]>) => ({
    payload,
    url: "/api/socialmedialinks" + (params ? params : ""),
  })
);

export const getSocialMediasDetails = createAction(
  "GET_SOCIAL_MEDIAS_DETAILS",
  ({ payload, id }: SagaActionParams<SocialMediaDto>) => ({
    payload,
    url: "/api/socialmedialinks/" + id,
  })
);
export const createSocialMedias = createAction(
  "CREATE_SOCIAL_MEDIAS",
  ({ payload, body }: SagaActionParams<SocialMediaDto>) => ({
    payload,
    url: "/api/socialmedialinks",
    body,
  })
);
export const updateSocialMedias = createAction(
  "UPDATE_SOCIAL_MEDIAS",
  ({ payload, id, body }: SagaActionParams<SocialMediaDto>) => ({
    payload,
    url: "/api/socialmedialinks/" + id,
    body,
  })
);

export const deleteSocialMedias = createAction(
  "DELETE_SOCIAL_MEDIAS",
  ({ payload, id }: SagaActionParams<SocialMediaDto>) => ({
    payload,
    url: "/api/socialmedialinks/" + id,
  })
);

const socialmedialinksSaga = [
  /*SOCIAL_MEDIAS*/
  takeLatest(
    getSocialMedias.toString(),
    (payload: SagaGenericParams<SocialMediaDto[]>) =>
      getListSaga<SocialMediaDto[]>(payload)
  ),

  takeLatest(
    getSocialMediasDetails.toString(),
    (payload: SagaGenericParams<SocialMediaDto>) =>
      getListSaga<SocialMediaDto>(payload)
  ),
  takeLatest(
    createSocialMedias.toString(),
    (payload: PostSagaGenericParams<SocialMediaDto>) =>
      postSaga<SocialMediaDto>(payload)
  ),
  takeLatest(
    updateSocialMedias.toString(),
    (payload: PostSagaGenericParams<SocialMediaDto>) =>
      putSaga<SocialMediaDto>(payload)
  ),

  takeLatest(deleteSocialMedias.toString(), (payload: SagaGenericParams<any>) =>
    deleteSaga<SocialMediaDto>(payload)
  ),
];

export default socialmedialinksSaga;
