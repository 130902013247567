import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PrivilegeLocationDto } from "./type";
import { SupplierLocationDto } from "../supplierlocations/type";
import { BodyType } from "../base/request/request";

export const getPrivilegeLocationListByPrivilegeId = createAction(
  "GET_PRIVILEGELOCATIONS_LIST_BY_PRIVILIGE_ID",
  ({ payload, params, id }: SagaActionParams<PrivilegeLocationDto[]>) => ({
    payload,
    url:
      "/api/privilegesupplierlocations/getbyprivilegeid/" +
      id +
      (params ? params : ""),
  })
);
export const getSupplierLocationsForPrivilege = createAction(
  "GET_SUPPLIER_LOCATION_FOR_PRIVILEGE",
  ({ payload, params }: SagaActionParams<SupplierLocationDto[]>) => ({
    payload,
    url: "/api/supplierlocations/getbysupplieridforprivilage" + params,
  })
);
export const getPrivilegeLocationList = createAction(
  "GET_PRIVILEGELOCATIONS_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeLocationDto[]>) => ({
    payload,
    url: "/api/privilegesupplierlocations" + (params ? params : ""),
  })
);

export const getPrivilegeLocationDetails = createAction(
  "GET_PRIVILEGELOCATIONS_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeLocationDto>) => ({
    payload,
    url: "/api/privilegesupplierlocations/" + id,
  })
);
export const createPrivilegeLocation = createAction(
  "CREATE_PRIVILEGELOCATIONS_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeLocationDto>) => ({
    payload,
    url: "/api/privilegesupplierlocations",
    body,
  })
);
export const createMultiplePrivilegeLocation = createAction(
  "CREATE_MULTIPLE_PRIVILEGELOCATIONS",
  ({ payload, body }: SagaActionParams<PrivilegeLocationDto>) => ({
    payload,
    url: "/api/privilegesupplierlocations/createwithmultiplelocation",
    body,
    bodyType: BodyType.raw,
  })
);
export const updatePrivilegeLocation = createAction(
  "UPDATE_PRIVILEGELOCATIONS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeLocationDto>) => ({
    payload,
    url: "/api/privilegesupplierlocations/" + id,
    body,
  })
);
export const deletePrivilegeLocation = createAction(
  "DELETE_PRIVILEGELOCATIONS_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeLocationDto>) => ({
    payload,
    url: "/api/privilegesupplierlocations/" + id,
    body,
  })
);
const privilegesupplierlocationsSaga = [
  takeLatest(
    getPrivilegeLocationListByPrivilegeId.toString(),
    (payload: SagaGenericParams<PrivilegeLocationDto[]>) =>
      getListSaga<PrivilegeLocationDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeLocationList.toString(),
    (payload: SagaGenericParams<PrivilegeLocationDto[]>) =>
      getListSaga<PrivilegeLocationDto[]>(payload)
  ),
  takeLatest(
    getSupplierLocationsForPrivilege.toString(),
    (payload: SagaGenericParams<SupplierLocationDto>) =>
      getListSaga<SupplierLocationDto>(payload)
  ),
  takeLatest(
    getPrivilegeLocationDetails.toString(),
    (payload: SagaGenericParams<PrivilegeLocationDto>) =>
      getListSaga<PrivilegeLocationDto>(payload)
  ),
  takeLatest(
    createMultiplePrivilegeLocation.toString(),
    (payload: PostSagaGenericParams<PrivilegeLocationDto>) =>
      postSaga<PrivilegeLocationDto>(payload)
  ),
  takeLatest(
    createPrivilegeLocation.toString(),
    (payload: PostSagaGenericParams<PrivilegeLocationDto>) =>
      postSaga<PrivilegeLocationDto>(payload)
  ),
  takeLatest(
    updatePrivilegeLocation.toString(),
    (payload: PostSagaGenericParams<PrivilegeLocationDto>) =>
      putSaga<PrivilegeLocationDto>(payload)
  ),

  takeLatest(
    deletePrivilegeLocation.toString(),
    (payload: SagaGenericParams<PrivilegeLocationDto>) =>
      deleteSaga<PrivilegeLocationDto>(payload)
  ),
];

export default privilegesupplierlocationsSaga;
