import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateServiceMaintenanceCard } from "src/store/maintenance/service-maintenance-cards/saga";
import { ServiceMaintenanceCardDto } from "src/store/maintenance/service-maintenance-cards/type";
import * as Yup from "yup";
import { MaintenanceCategoryDto } from "src/store/maintenance/maintenance-categories/type";
import { getMaintenanceCategoryLookup } from "src/store/maintenance/maintenance-categories/saga";

const EditServiceMaintenanceCard = ({
  data,
  onHide,
  open,
  onSuccessClick,
  id,
}: {
  id?: string;
  data: ServiceMaintenanceCardDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<MaintenanceCategoryDto[]>([]);
  useEffect(() => {
    if (open) {
      dispatch(
        getMaintenanceCategoryLookup({
          payload: {
            onSuccess: (msg, py) => setCategories(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);

  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Servis Bakım Kartı Düzenle"),
        }}
        onHide={onHide}
        loader
        onSubmit={values => {
          dispatch(
            updateServiceMaintenanceCard({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        initialValues={{
          Id: data.Id,
          CardName: data.CardName,
          MaintenanceCategoryId: data.MaintenanceCategoryId,
        }}
        validationSchema={Yup.object().shape({
          CardName: Yup.string().required(t("Required")),
          MaintenanceCategoryId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "MaintenanceCategoryId",
            inputType: InputType.multiselect,
            label: t("Kategori"),
            lookup: {
              data: categories,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Kategori Seçiniz"),
            },
          },
          {
            field: "CardName",
            label: t("Ürün/Tip"),
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditServiceMaintenanceCard;
