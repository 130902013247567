import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import CustomModal from "src/components/Modal/modal";
import { updateTireSaleStatus } from "src/store/tires/tireSales/saga";
import {
  TireSaleDetailDto,
  TireSaleStatusUpdateUrl,
} from "src/store/tires/tireSales/type";

const CancelModal = ({
  data,
  onSuccessClick,
}: {
  data: TireSaleDetailDto;
  onSuccessClick: () => void;
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <button className="btn btn-soft-danger" onClick={() => setOpen(true)}>
        İptal
      </button>
      <CustomModal
        open={open}
        onHide={() => setOpen(false)}
        title={
          <Modal.Title className="text-center mb-2">
            <span
              className="fw-bold text-center "
              style={{ fontSize: 18 }}
            ></span>
          </Modal.Title>
        }
        footer={
          <div className="d-flex justify-content-between">
            <button
              style={{ width: 100 }}
              className="btn btn-danger"
              onClick={() => setOpen(false)}
            >
              Kapat
            </button>
            <button
              style={{ width: 100 }}
              className="btn btn-success"
              onClick={() => {
                dispatch(
                  updateTireSaleStatus({
                    payload: {
                      onSuccess: (message, payload) => {
                        setOpen(false);
                        toast.success(message);
                        onSuccessClick();
                      },
                      onError: message => {
                        toast.error(message);
                      },
                    },
                    body: {
                      TireSaleId: data.Id,
                    },
                    url: TireSaleStatusUpdateUrl.İptal,
                  })
                );
              }}
            >
              İptal Et
            </button>
          </div>
        }
      >
        <p className="font-size-15 text-center fw-bold">
          {"Siparişi iptal etmek istediğinize emin misiniz?"}
        </p>
      </CustomModal>
    </React.Fragment>
  );
};

export default CancelModal;
