import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainPage from "src/components/PageContent/MainPage";
import InvoicePackageDetailModal from "./detail";
import { InvoicePrivilegeDto } from "src/store/privilege-invoices/type";

const UnInvoicePrivilegePage = () => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [detailData, setDetailData] = useState<InvoicePrivilegeDto>();
  const gridOptions: any = {
    url: "/api/invoices/getinvoicedprivilegelist/devexp",
    loadPanel: {
      enabled: false,
    },
    columns: [
      {
        caption: t("Fatura No"),
        dataField: "InvoiceNumber",
      },
      {
        caption: t("Identity Number"),
        dataField: "IdentityNumber",
      },
      {
        caption: t("Name"),
        dataField: "Name",
      },
      {
        caption: t("Surname"),
        dataField: "Surname",
      },
      {
        caption: t("Email"),
        dataField: "Email",
      },
      {
        caption: t("Tarih"),
        dataField: "CreateTime",
        dataType: "datetime",
      },
      {
        text: t("Privilege Name"),
        dataField: "PrivilegeName",
      },
      {
        caption: t("BasePrice"),
        dataField: "BasePrice",
      },
      {
        caption: t("Tax"),
        dataField: "Tax",
      },
      {
        caption: t("Price"),
        dataField: "Price",
      },

      {
        type: "buttons",
        width: 110,
        buttons: [
          {
            hint: "Detay",
            icon: "info",
            onClick: async (e: any) => {
              setDetailData(e.row.data);
              return setShowDetails(true);
            },
          },
        ],
      },
    ],
  };
  return (
    <React.Fragment>
      {detailData && (
        <InvoicePackageDetailModal
          selectedData={detailData}
          onHide={() => setShowDetails(false)}
          open={showDetails}
        />
      )}

      <MainPage
        devProps={{ ...gridOptions }}
        useDevGrid={true}
        title={t("Invoiced Privileges")}
      />
    </React.Fragment>
  );
};

export default UnInvoicePrivilegePage;
