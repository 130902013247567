import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/drawer";
import {
  createEmailTemplates,
  getEmailTemplateTypes,
} from "src/store/emailTemplates/saga";
import { EmailTemplateTypeDto } from "src/store/emailTemplates/type";
import * as Yup from "yup";

const CreateEmailTemplatePage = ({
  onHide,
  open,
  onSuccessClick,
}: {
  onHide: () => void;
  open: boolean;
  onSuccessClick: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [types, setTypes] = useState<EmailTemplateTypeDto[]>([]);
  useEffect(() => {
    open &&
      dispatch(
        getEmailTemplateTypes({
          payload: {
            onSuccess: (m, p, r) => {
              setTypes(p);
              p && p.length > 0 && setTypes(p);
            },
            onError: () => {},
          },
        })
      );
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <CustomModal onHide={onHide} open={open} title={"Tasarım Şablonu Ekle"}>
        <Formik
          onSubmit={values => {
            dispatch(
              createEmailTemplates({
                payload: {
                  onSuccess: (message, payload) => {
                    onHide();
                    toast.success(message);
                    onSuccessClick();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: values,
              })
            );
          }}
          initialValues={{
            Name: "",
            Subject: "",
            Template: "",
            EmailTemplateTypeId: "",
            Active: false,
            UseLayout: false,
          }}
          validationSchema={Yup.object().shape({})}
        >
          {({
            touched,
            errors,
            values,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <Row>
              <GetInput
                inputprop={{
                  field: "EmailTemplateTypeId",
                  inputType: InputType.multiselect,
                  label: t("Tasarım Şablonu Tip Seçimi"),
                  lookup: {
                    data: types,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Seçiniz"),
                  },
                }}
              />
              <GetInput
                inputprop={{ field: "Name", label: "Tasarım Şablonu Adı" }}
              />
              <GetInput inputprop={{ field: "Subject", label: "Konu" }} />
              <GetInput
                inputprop={{
                  field: "Template",
                  label: "Tasarım Şablonu",
                  inputType: InputType.multilinetext,
                }}
              />
              <GetInput
                inputprop={{
                  field: "UseLayout",
                  label: "Ana Tasarım Şablonunu Kullan",
                  inputType: InputType.checkbox,
                }}
              />
              <GetInput
                inputprop={{
                  field: "Active",
                  label: "Aktif",
                  inputType: InputType.checkbox,
                }}
              />
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  {t("Save")}
                </Button>
              </div>
            </Row>
          )}
        </Formik>
      </CustomModal>
    </React.Fragment>
  );
};
export default CreateEmailTemplatePage;
