import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TicketSubjectDetailDto } from "src/store/ticket-subject-details/type";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import { useDispatch } from "react-redux";
import CreateTicketSubjectDetail from "./create";
import { toast } from "react-toastify";
import EditTicketSubjectDetail from "./edit";
import CancelAlert from "src/components/CancelAlert";
import {
  deleteTicketSubjectDetail,
  getTicketSubjectDetailDetails,
  getTicketSubjectDetailListByTicketSubjectId,
} from "src/store/ticket-subject-details/saga";
import MainPage from "src/components/PageContent/MainPage";
import { useHistory, useParams } from "react-router";
import { TicketSubjectDto } from "src/store/ticket-subject/type";
import { getTicketSubjectDetails } from "src/store/ticket-subject/saga";

const TicketSubjectDetailPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<TicketSubjectDetailDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [data, setData] = useState<TicketSubjectDetailDto[]>([]);
  const [showDelete, setShowDelete] = useState<TicketSubjectDetailDto>();
  const [ticketSubject, setTicketSubject] = useState<TicketSubjectDto>();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      getTicketSubjectDetails({
        payload: {
          onSuccess: (msg, py) => setTicketSubject(py),
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const columns = [
    {
      text: t("Talep Konusu"),
      dataField: "TicketSubjectName",
    },
    {
      text: t("Description"),
      dataField: "Description",
    },

    {
      text: "",
      dataField: "Id",
      formatter: (cellContent: any, data: any) => (
        <div className="d-flex w-30 justify-content-end gap-1">
          <ColumnButton
            buttonType="customtext"
            custombutton={{
              text: "Bilgiler",
            }}
            onClick={() => {
              return history.push(
                "/ticket-subjects/details/info/" + cellContent
              );
            }}
          />
          <ColumnButton
            buttonType="edit"
            onClick={() => {
              dispatch(
                getTicketSubjectDetailDetails({
                  payload: {
                    onSuccess: (message, payload) => {
                      setSelected(payload);
                      return setShowEdit(true);
                    },
                    onError: () => {},
                  },
                  id: cellContent,
                })
              );
            }}
          />
          <ColumnButton
            buttonType="delete"
            onClick={() => {
              return setShowDelete(data);
            }}
          />
        </div>
      ),
    },
  ];
  const onSuccessClick = (message: string) => {
    toast.success(message, {
      theme: "colored",
    });
    dispatch(
      getTicketSubjectDetailListByTicketSubjectId({
        payload: {
          onSuccess: (message, payload) => {
            setData(payload);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  };
  return (
    <React.Fragment>
      {ticketSubject && (
        <>
          {selected && (
            <EditTicketSubjectDetail
              data={selected}
              onHide={() => setShowEdit(false)}
              open={showEdit}
              onSuccessClick={onSuccessClick}
            />
          )}
          <CreateTicketSubjectDetail
            id={id}
            onHide={() => setShowCreate(false)}
            open={showCreate}
            onSuccessClick={onSuccessClick}
          />
          <MainPage
            withoutPag
            title={`${ticketSubject.Subject} Talep Konusu Açıklamaları`}
            gridProps={{
              listData: data,
              columns: columns,
              create: {
                createType: "drawer",
                onCreateClick: () => setShowCreate(true),
              },
              setDataList: setData,
              action: getTicketSubjectDetailListByTicketSubjectId,
              paramsid: id,
            }}
          />
          {showDelete && (
            <CancelAlert
              message={t(
                `Talep konu detayını Silmek istediğinize emin misiniz?`
              )}
              onConfirm={() => {
                setShowDelete(undefined);
                dispatch(
                  deleteTicketSubjectDetail({
                    payload: {
                      onSuccess: message => onSuccessClick(message),
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    id: showDelete.Id,
                  })
                );
              }}
              onCancel={() => {
                setShowDelete(undefined);
              }}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default TicketSubjectDetailPage;
