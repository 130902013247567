import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  AccountDeletionPromptDto,
  AgreementDto,
  AgreementTypeDto,
  AgreementTypeKeyDto,
  PrivacyDto,
} from "./type";
import { BodyType } from "../base/request/request";

export const getAccountDeletionPrompt = createAction(
  "GET_ACCOUNT_DELETION_PROMPT",
  ({ payload }: SagaActionParams<AccountDeletionPromptDto>) => ({
    payload,
    url: "/api/accountdeletionprompt",
  })
);
export const getAggrementKeys = createAction(
  "GET_AGGREEMENT_KEYS",
  ({ payload, id }: SagaActionParams<AgreementTypeKeyDto[]>) => ({
    payload,
    url: "/api/system/aggreement-keys/" + id,
  })
);
export const createAccountDeletionPrompt = createAction(
  "CREATE_ACCOUNT_DELETION_PROMPT",
  ({ payload, body }: SagaActionParams<AccountDeletionPromptDto>) => ({
    payload,
    body,
    url: "/api/accountdeletionprompt",
    bodyType: BodyType.raw,
  })
);
export const getPrivacy = createAction(
  "GET_PRIVACY",
  ({ payload }: SagaActionParams<PrivacyDto>) => ({
    payload,
    url: "/api/privacy",
  })
);
export const createPrivacy = createAction(
  "CREATE_PRIVACY",
  ({ payload, body }: SagaActionParams<PrivacyDto>) => ({
    payload,
    url: "/api/privacy",
    bodyType: BodyType.raw,
    body,
  })
);
export const getAgreementList = createAction(
  "GET_AGREEMENT_LIST",
  ({ payload, params }: SagaActionParams<AgreementDto[]>) => ({
    payload,
    url: "/api/agreements" + (params ? params : ""),
  })
);
export const getAgreementDetails = createAction(
  "GET_AGREEMENT_DETAILS",
  ({ payload, id }: SagaActionParams<AgreementDto>) => ({
    payload,
    url: "/api/agreements/" + id,
  })
);
export const createAgreement = createAction(
  "CREATE_AGREEMENT",
  ({ payload, body }: SagaActionParams<AgreementDto>) => ({
    payload,
    url: "/api/agreements",
    body,
  })
);
export const getAgreementChangeInfoList = createAction(
  "GET_AGREEMENT_CHANGE_INFO_LIST",
  ({ payload }: SagaActionParams<AgreementDto[]>) => ({
    payload,
    url: "/api/agreementchangeinfos",
  })
);
export const getAgreementChangeInfoDetail = createAction(
  "GET_AGREEMENT_CHANGE_INFO_DETAILS",
  ({ payload, id }: SagaActionParams<AgreementDto>) => ({
    payload,
    url: "/api/agreementchangeinfos/" + id,
  })
);
export const createAgreementChangeInfo = createAction(
  "CREATE_AGREEMENT_CHANGE_INFO",
  ({ payload, body }: SagaActionParams<AgreementDto>) => ({
    payload,
    url: "/api/agreementchangeinfos",
    body,
  })
);
export const getAgreementTypeList = createAction(
  "GET_AGREEMENT_TYPE_LIST",
  ({ payload }: SagaActionParams<AgreementTypeDto[]>) => ({
    payload,
    url: "/api/system/agreementtypes",
  })
);
const agreementsSaga = [
  takeLatest(
    getAgreementTypeList.toString(),
    (payload: SagaGenericParams<AgreementTypeDto[]>) =>
      getListSaga<AgreementTypeDto[]>(payload)
  ),
  takeLatest(
    getAgreementList.toString(),
    (payload: SagaGenericParams<AgreementDto[]>) =>
      getListSaga<AgreementDto[]>(payload)
  ),
  takeLatest(
    getAggrementKeys.toString(),
    (payload: SagaGenericParams<AgreementDto[]>) =>
      getListSaga<AgreementDto[]>(payload)
  ),
  takeLatest(
    getAgreementDetails.toString(),
    (payload: SagaGenericParams<AgreementDto>) =>
      getListSaga<AgreementDto>(payload)
  ),
  takeLatest(
    createAgreement.toString(),
    (payload: PostSagaGenericParams<AgreementDto>) =>
      postSaga<AgreementDto>(payload)
  ),
  takeLatest(
    getAgreementChangeInfoDetail.toString(),
    (payload: SagaGenericParams<AgreementDto>) =>
      getListSaga<AgreementDto>(payload)
  ),
  takeLatest(
    getAgreementChangeInfoList.toString(),
    (payload: SagaGenericParams<AgreementDto>) =>
      getListSaga<AgreementDto>(payload)
  ),
  takeLatest(
    createAgreementChangeInfo.toString(),
    (payload: PostSagaGenericParams<AgreementDto>) =>
      postSaga<AgreementDto>(payload)
  ),
  takeLatest(
    getAccountDeletionPrompt.toString(),
    (payload: SagaGenericParams<AccountDeletionPromptDto>) =>
      getListSaga<AccountDeletionPromptDto>(payload)
  ),
  takeLatest(
    createAccountDeletionPrompt.toString(),
    (payload: PostSagaGenericParams<AccountDeletionPromptDto>) =>
      postSaga<AccountDeletionPromptDto>(payload)
  ),
  takeLatest(getPrivacy.toString(), (payload: SagaGenericParams<PrivacyDto>) =>
    getListSaga<PrivacyDto>(payload)
  ),
  takeLatest(
    createPrivacy.toString(),
    (payload: PostSagaGenericParams<PrivacyDto>) =>
      postSaga<PrivacyDto>(payload)
  ),
];

export default agreementsSaga;
