import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import GetInput from "src/components/Form/GetInput";
import { InputType } from "src/components/Form/type";
import CustomModal from "src/components/Modal/modal";
import { createInvalidCallLogTicket } from "src/store/ticket/saga";
import { TicketDetailDto } from "src/store/ticket/type";
import * as Yup from "yup";
const CreateInvalidCallLog = ({
  show,
  onHide,
  onSuccessSubmit,
}: {
  show: boolean;
  onHide: () => void;
  onSuccessSubmit?: (ticket: TicketDetailDto) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <CustomModal onHide={onHide} open={show} title={"GEÇERSİZ ÇAĞRI"} size="sm">
      <Formik
        onSubmit={values => {
          dispatch(
            createInvalidCallLogTicket({
              payload: {
                onSuccess: (ms, payload) => {
                  toast.success("Geçersiz Çağrı başarıyla oluşturuldu");
                  onSuccessSubmit && onSuccessSubmit(payload);
                  history.push("/ticket/all?ticketId=" + payload.Id);
                  onHide();
                },
                onError: m => {
                  toast.error(m);
                },
              },
              body: values,
            })
          );
        }}
        initialValues={{
          Message: "",
        }}
        validationSchema={Yup.object().shape({
          Message: Yup.string().required(t("Required")),
        })}
      >
        {({ handleSubmit }) => (
          <>
            <Row className="mx-3">
              <GetInput
                inputprop={{
                  field: "Message",
                  label: t("Message"),
                  inputType: InputType.multilinetext,
                }}
              />
              <div className={"d-flex mt-2  justify-content-between "}>
                <Button
                  color="danger"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => {
                    return onHide();
                  }}
                >
                  {t("Close")}
                </Button>
                <Button
                  color="success"
                  type="submit"
                  style={{
                    width: 150,
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("Save")}
                </Button>
              </div>
            </Row>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};
export default CreateInvalidCallLog;
