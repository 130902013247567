import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { getTireBrandList } from "src/store/tires/brands/saga";
import { TireBrandDto } from "src/store/tires/brands/type";
import { getTireDiameterList } from "src/store/tires/diameters/saga";
import { TireDiameterDto } from "src/store/tires/diameters/type";
import { getTireSidewallWidthList } from "src/store/tires/sidewallwidths/saga";
import { TireSidewallWidthDto } from "src/store/tires/sidewallwidths/type";
import { getTireTypeList, updateTire } from "src/store/tires/tires/saga";
import { TireDto, TireTypeDto } from "src/store/tires/tires/type";
import { getTireTreadWidthList } from "src/store/tires/treadwidths/saga";
import { TireTreadWidthDto } from "src/store/tires/treadwidths/type";
import * as Yup from "yup";

const EditTire = ({
  data,
  onHide,
  open,
  onSuccessClick,
}: {
  data: TireDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [diameters, setDiameters] = useState<TireDiameterDto[]>([]);
  const [types, setTypes] = useState<TireTypeDto[]>([]);
  const [brands, setBrands] = useState<TireBrandDto[]>([]);
  const [sidewallWidths, setSidewallWidths] = useState<TireSidewallWidthDto[]>(
    []
  );
  const [treadWidths, setTreadWidths] = useState<TireTreadWidthDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getTireTypeList({
          payload: {
            onSuccess: (msg, py) => setTypes(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTireBrandList({
          payload: {
            onSuccess: (msg, py) => setBrands(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTireDiameterList({
          payload: {
            onSuccess: (msg, py) => setDiameters(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTireSidewallWidthList({
          payload: {
            onSuccess: (msg, py) => setSidewallWidths(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getTireTreadWidthList({
          payload: {
            onSuccess: (msg, py) => setTreadWidths(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Lastik i Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name ?? "",
          TireTypeId: data.TireTypeId,
          TireBrandId: data.TireBrandId,
          TireTreadWidthId: data.TireTreadWidthId,
          TireSidewallWidthId: data.TireSidewallWidthId,
          TireDiameterId: data.TireDiameterId,
          Description: data.Description ?? "",
          ImageFile: "",
          CurrentImageFile: data.Image,
          UnitPrice: data.UnitPrice,
          TaxRate: data.TaxRate,
          Stock: data.Stock,
          FreeShipping: data.FreeShipping,
          FreeAssembly: data.FreeAssembly,
          ShippingPrice: data.ShippingPrice,
          ShippingTaxRate: data.ShippingTaxRate,
        }}
        onSubmit={values => {
          dispatch(
            updateTire({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          TireTypeId: Yup.number().required(t("Required")),
          TireBrandId: Yup.string().required(t("Required")),
          TireTreadWidthId: Yup.string().required(t("Required")),
          TireSidewallWidthId: Yup.string().required(t("Required")),
          TireDiameterId: Yup.string().required(t("Required")),
          Description: Yup.string().required(t("Required")),
          UnitPrice: Yup.number().required(t("Required")),
          TaxRate: Yup.number().required(t("Required")),
          Stock: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
          },
          {
            col: 6,
            field: "TireTypeId",
            inputType: InputType.multiselect,
            label: t("Lastik Tipi"),
            lookup: {
              data: types,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Marka Seçiniz"),
            },
          },
          {
            col: 6,
            field: "TireBrandId",
            inputType: InputType.multiselect,
            label: t("Lastik Markası"),
            lookup: {
              data: brands,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Marka Seçiniz"),
            },
          },
          {
            col: 4,
            field: "TireTreadWidthId",
            inputType: InputType.multiselect,
            label: t("Taban Genişliği"),
            lookup: {
              data: treadWidths,
              labelKey: "Value",
              valueKey: "Id",
              placeholder: t("Taban Genişliği Seçiniz"),
            },
          },
          {
            col: 4,
            field: "TireSidewallWidthId",
            inputType: InputType.multiselect,
            label: t("Yan Genişlik"),
            lookup: {
              data: sidewallWidths,
              labelKey: "Value",
              valueKey: "Id",
              placeholder: t("Yan Genişlik Seçiniz"),
            },
          },
          {
            col: 4,
            field: "TireDiameterId",
            inputType: InputType.multiselect,
            label: t("Çap"),
            lookup: {
              data: diameters,
              labelKey: "Value",
              valueKey: "Id",
              placeholder: t("Çap Seçiniz"),
            },
          },
          {
            col: 4,
            field: "UnitPrice",
            inputType: InputType.number,
            label: t("Birim Fiyat"),
          },
          {
            col: 4,
            field: "TaxRate",
            inputType: InputType.number,
            label: t("Vergi Oranı"),
          },
          {
            col: 4,
            field: "Stock",
            inputType: InputType.number,
            label: t("Stok"),
          },
          {
            field: "Description",
            inputType: InputType.multilinetext,
            label: t("Description"),
          },
          {
            field: "FreeShipping",
            inputType: InputType.checkbox,
            label: t("Ücretsiz Nakliye"),
          },
          {
            field: "FreeAssembly",
            inputType: InputType.checkbox,
            label: t("Ücretsiz Montaj"),
          },
          {
            field: "ImageFile",
            label: t("Ana Resim"),
            inputType: InputType.fileUpload,
            fileUpload: {
              linkField: "CurrentImageFile",
              accept: ["image/jpeg", "image/png"],
              multiple: false,
              title: t("Please Upload Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditTire;
