import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CancelAlert from "src/components/CancelAlert";
import CustomModal from "src/components/Modal/modal";
import { resendRabbitMqMessage } from "src/store/customers/saga";
const SendPrivilegeConsumer = ({
  onHide,
  open,
  customers,
}: {
  onHide: () => void;
  open: boolean;
  customers: string[];
}) => {
  const dispatch = useDispatch();
  const [openResult, setOpenResult] = useState(false);
  const [result, setResult] = useState<string[]>([]);

  return (
    <React.Fragment>
      <CustomModal
        open={openResult}
        onHide={() => {
          setOpenResult(false);
          onHide();
        }}
        title={"Uyarı"}
      >
        {result && result.length > 0 && (
          <>
            <p className="text-center text-success fw-bold font-size-14">
              Rabbitmq üzerinden paket hizmet atamasına mesaj başarıyla
              gönderildi.
            </p>
            <div className="bg-soft-warning p-2">
              <p className="text-danger">
                Fakat Bazı üyeler daha önceden gönderilmiştir.
                <br />
                Hata Olan Üye listesi:
              </p>
              {result.map(x => (
                <p>{x}</p>
              ))}
            </div>
          </>
        )}
      </CustomModal>

      {!openResult && (
        <CancelAlert
          message={
            "Tekrar rabbitmq üzerinden paket hizmet atamasına mesaj bırakmak istediğine emin misin?"
          }
          onConfirm={() => {
            dispatch(
              resendRabbitMqMessage({
                payload: {
                  onSuccess: (message, payload) => {
                    if (payload && payload.length > 0) {
                      setResult(payload);
                      //onHide();
                      setOpenResult(true);
                    } else {
                      toast.success("Müşteriler başarıyla kaydedildi.");
                      onHide();
                    }
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                body: {
                  customerIds: customers,
                },
              })
            );
          }}
          onCancel={() => {
            onHide();
          }}
        />
      )}
    </React.Fragment>
  );
};
export default SendPrivilegeConsumer;
