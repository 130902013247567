import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import DataGrid from "devextreme-react/data-grid";
import CreateCorporateClient from "./create";
import CancelAlert from "src/components/CancelAlert";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import EditCorporateClient from "./edit";
import CustomDataGrid from "src/components/PageContent/DevextGrid";
import { EnumTabs } from "../tabList";
import { CorporateCompanyDto } from "src/store/corporate-companies/type";
import {
  deleteCorporateCompanies,
  getCorporateCompaniesDetails,
} from "src/store/corporate-companies/saga";
import DetailCorporateCompany from "./detail";

const CorporateCompaniesPage = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid>();
  const { id, tab }: any = useParams();
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState<CorporateCompanyDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<CorporateCompanyDto>();
  const dispatch = useDispatch();
  const [showDetail, setShowDetail] = useState<{
    show: boolean;
    selected: CorporateCompanyDto;
  }>();
  const columns = [
    {
      caption: t("Firma Adı"),
      dataField: "CompanyName",
    },
    {
      caption: t("Yetkili"),
      dataField: "AuthorizedPerson",
    },

    {
      caption: t("Vergi No"),
      dataField: "TaxId",
    },
    {
      caption: t("E-posta"),
      dataField: "Email",
    },
    {
      caption: t("Telefon"),
      dataField: "Phone",
    },
    {
      caption: t("City"),
      dataField: "CityCityName",
    },
    {
      caption: t("Town"),
      dataField: "TownTownName",
    },
    {
      caption: t("Active"),
      dataField: "Active",
      dataType: "boolean",
    },
    {
      type: "buttons",
      width: 110,
      buttons: [
        {
          hint: "Detay",
          icon: "info",
          onClick: async (e: any) => {
            dispatch(
              getCorporateCompaniesDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    return setShowDetail({
                      show: true,
                      selected: payload,
                    });
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Düzenle",
          icon: "edit",
          onClick: async (e: any) => {
            dispatch(
              getCorporateCompaniesDetails({
                payload: {
                  onSuccess: (message, payload) => {
                    setSelected(payload);
                    return setShowEdit(true);
                  },
                  onError: () => {},
                },
                id: e.row.key,
              })
            );
          },
        },
        {
          hint: "Sil",
          icon: "trash",
          onClick: async (e: any) => {
            return setShowDelete(e.row.data);
          },
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {tab === EnumTabs.Companies && (
        <>
          <CustomDataGrid
            ref={dataGridRef}
            title={t("Şirketler")}
            rowAlternationEnabled
            url={"/api/corporatecompanies/devexp/" + id}
            columns={columns}
            create={() => setShowCreate(true)}
          />
          <CreateCorporateClient
            onHide={() => setShowCreate(false)}
            onSuccessClick={m => toast.success(m)}
            open={showCreate}
          />
          {selected && (
            <EditCorporateClient
              data={selected}
              onHide={() => setShowEdit(false)}
              onSuccessClick={m => toast.success(m)}
              open={showEdit}
            />
          )}
          {showDetail && (
            <DetailCorporateCompany
              data={showDetail.selected}
              onHide={() =>
                setShowDetail({
                  selected: showDetail.selected,
                  show: false,
                })
              }
              open={showDetail.show}
            />
          )}
          {showDelete && (
            <CancelAlert
              message={"Silmek istediğinize emin misiniz?"}
              onConfirm={() => {
                setShowDelete(undefined);
                dispatch(
                  deleteCorporateCompanies({
                    payload: {
                      onSuccess: message => toast.success(message),
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    id: showDelete.Id,
                  })
                );
              }}
              onCancel={() => {
                setShowDelete(undefined);
              }}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default CorporateCompaniesPage;
