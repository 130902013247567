import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container, Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import CarouselPage from "./CarouselPage";
import { resetPassword } from "src/store/auth/saga";
import images from "src/assets/image";
import { useTranslation } from "react-i18next";
import auth from "src/store/auth";

interface ForgetPasswordProps {
  history: object;
}

const ResetPassword = ({ history }: ForgetPasswordProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [result, setResult] = useState<number>();
  const navigate = useHistory();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  useEffect(() => {
    dispatch(auth.actions.logoutAction());
  }, [dispatch]);
  function handleValidSubmit(event: any, values: any) {
    setLoader(true);
    const params = location.search
      .replace("?", "")
      .replace("userId=", "")
      .replace("token=", "")
      .split("&");
    dispatch(
      resetPassword({
        payload: {
          onSuccess: (message, payload) => {
            setResult(1);
            setTimeout(() => {
              setLoader(false);
              navigate.push("/login");
            }, 2000);
          },
          onError: () => {
            setResult(2);
            setLoader(false);
          },
        },
        body: {
          Password: values.Password,
          Token: params[1],
          UserId: params[0],
        },
      })
    );
  }
  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("Reset | Servis Plan")}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <CarouselPage
            component={
              <Row className="g-0 bg-light">
                <Col lg={12} md={12} className="col-xxl-12">
                  <div className="auth-full-page-content d-flex p-sm-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="text-center">
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img src={images.logo} alt="" height="140" />{" "}
                          </Link>
                        </div>
                        <div className="auth-content my-auto">
                          <div className="text-center">
                            <h5 className="mb-0">{t("Reset Password")}</h5>
                            {/* <p className="text-muted mt-2">
                          Reset Password with Minia.
                        </p> */}
                          </div>
                          {result && result === 2 && (
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              {t("Please try again later")}
                            </Alert>
                          )}
                          {result && result === 1 && (
                            <Alert
                              color="success"
                              style={{ marginTop: "13px" }}
                            >
                              {t("Reset Password succesfull")}
                            </Alert>
                          )}
                          <Modal isOpen={loader} centered={true} size="xs">
                            <ModalBody>
                              <div className="text-center">
                                <i className="bx bx-loader bx-spin font-size-30 align-middle me-2"></i>
                                {t("Reseting")}
                              </div>
                            </ModalBody>
                          </Modal>
                          <AvForm
                            className="custom-form mt-4"
                            onValidSubmit={(e: any, v: any) =>
                              handleValidSubmit(e, v)
                            }
                          >
                            <div className="mb-3">
                              <AvField
                                type="password"
                                name="Password"
                                className="form-control"
                                label={t("Password")}
                                placeholder={t("Enter Password")}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <AvField
                                type="password"
                                name="Confirm Password"
                                label={t("Confirm Password")}
                                placeholder={t("Enter Confirm Password")}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="mb-3 mt-4">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                {t("Reset")}
                              </button>
                            </div>
                          </AvForm>
                          <div className="mt-5 text-center">
                            <p className="text-muted mb-0">
                              {t("Remember It")}{" "}
                              <Link
                                to="/login"
                                className="text-primary fw-semibold"
                              >
                                {" "}
                                {t("Login")}
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            }
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
