import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { UserDto, UserRoleDto } from "./type";
import { BodyType } from "../base/request/request";

export const getUserList = createAction(
  "GET_USER_LIST",
  ({ payload, params }: SagaActionParams<UserDto[]>) => ({
    payload,
    url: "/api/appuser/admin" + (params ? params : ""),
  })
);
export const getUserDetails = createAction(
  "GET_USER_DETAILS",
  ({ payload, id }: SagaActionParams<UserDto>) => ({
    payload,
    url: "/api/appuser/admin/" + id,
  })
);
export const createUser = createAction(
  "CREATE_USER_DETAILS",
  ({ payload, body }: SagaActionParams<UserDto>) => ({
    payload,
    url: "/api/appuser/admin",
    body,
  })
);
export const updateUser = createAction(
  "UPDATE_USER_DETAILS",
  ({ payload, body, id }: SagaActionParams<UserDto>) => ({
    payload,
    url: "/api/appuser/admin/" + id,
    body,
  })
);
export const deleteUser = createAction(
  "DELETE_USER_DETAILS",
  ({ payload, body, id }: SagaActionParams<UserDto>) => ({
    payload,
    url: "/api/appuser/admin/" + id,
    body,
  })
);
export const updateUserRoleList = createAction(
  "UPDATE_USER-ROLE_LIST",
  ({ payload, id, body }: SagaActionParams<UserRoleDto[]>) => ({
    payload,
    url: "/api/appuser/assign-role/" + id,
    body: body,
    bodyType: BodyType.raw,
  })
);
export const getUserRoleList = createAction(
  "GET_USER_ROLE_LIST",
  ({ payload, id }: SagaActionParams<string[]>) => ({
    payload,
    url: "/api/appuser/assign-role-list/" + id,
  })
);
const userSaga = [
  takeLatest(getUserList.toString(), (payload: SagaGenericParams<UserDto[]>) =>
    getListSaga<UserDto[]>(payload)
  ),
  takeLatest(getUserDetails.toString(), (payload: SagaGenericParams<UserDto>) =>
    getListSaga<UserDto>(payload)
  ),
  takeLatest(
    getUserRoleList.toString(),
    (payload: SagaGenericParams<string[]>) => getListSaga<string[]>(payload)
  ),
  takeLatest(createUser.toString(), (payload: PostSagaGenericParams<UserDto>) =>
    postSaga<UserDto>(payload)
  ),
  takeLatest(
    updateUserRoleList.toString(),
    (payload: PostSagaGenericParams<UserDto>) => putSaga<UserDto>(payload)
  ),
  takeLatest(updateUser.toString(), (payload: PostSagaGenericParams<UserDto>) =>
    putSaga<UserDto>(payload)
  ),
  takeLatest(deleteUser.toString(), (payload: SagaGenericParams<UserDto>) =>
    deleteSaga<UserDto>(payload)
  ),
];

export default userSaga;
