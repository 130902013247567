import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { updateUser } from "src/store/user/saga";
import languageRedux, { languagesSelector } from "src/store/languages";
import * as Yup from "yup";
import { UserDto } from "src/store/user/type";

const EditManager = ({
  onHide,
  open,
  data,
  onSuccessClick,
}: {
  data: UserDto;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const langs = useSelector(languagesSelector);
  useEffect(() => {
    if (open) {
      dispatch(languageRedux.actions.getLanguages());
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Kullanıcı Düzenle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          Id: data.Id,
          Name: data.Name ?? "",
          Surname: data.Surname ?? "",
          LanguageId: data.LanguageId ?? "",
          Password: "",
          Email: data.Email ?? "",
          PhoneNumber: data.PhoneNumber ?? "",
        }}
        onSubmit={values => {
          dispatch(
            updateUser({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
              id: data.Id,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          Name: Yup.string().required(t("Required")),
          Surname: Yup.string().required(t("Required")),
          Email: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "Name",
            label: t("Name"),
            col: 6,
          },
          {
            field: "Surname",
            label: t("Surname"),
            col: 6,
          },

          {
            field: "LanguageId",
            inputType: InputType.multiselect,
            label: t("Dil"),
            lookup: {
              data: langs,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: "Dil Seçiniz",
            },
          },

          {
            field: "Email",
            label: t("Email"),
          },
          {
            field: "PhoneNumber",
            label: t("PhoneNumber"),
          },
          {
            field: "Password",
            label: t("Password"),
            col: 6,
          },
          {
            field: "ConfirmPassword",
            label: t("Confirm Password"),
            col: 6,
          },
        ]}
      />
    </React.Fragment>
  );
};
export default EditManager;
