import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import { PaymentInstallmentDto } from "./type";

export const getPaymentInstallments = createAction(
  "GET_PAYMENT_INSTALLMENTSS",
  ({ payload, params }: SagaActionParams<PaymentInstallmentDto[]>) => ({
    payload,
    url: "/api/numberofinstallments" + (params ? params : ""),
  })
);

export const getPaymentInstallmentsDetails = createAction(
  "GET_PAYMENT_INSTALLMENTSS_DETAILS",
  ({ payload, id }: SagaActionParams<PaymentInstallmentDto>) => ({
    payload,
    url: "/api/numberofinstallments/" + id,
  })
);
export const createPaymentInstallments = createAction(
  "CREATE_PAYMENT_INSTALLMENTSS",
  ({ payload, body }: SagaActionParams<PaymentInstallmentDto>) => ({
    payload,
    url: "/api/numberofinstallments",
    body,
  })
);
export const updatePaymentInstallments = createAction(
  "UPDATE_PAYMENT_INSTALLMENTSS",
  ({ payload, id, body }: SagaActionParams<PaymentInstallmentDto>) => ({
    payload,
    url: "/api/numberofinstallments/" + id,
    body,
  })
);

export const deletePaymentInstallments = createAction(
  "DELETE_PAYMENT_INSTALLMENTSS",
  ({ payload, id }: SagaActionParams<PaymentInstallmentDto>) => ({
    payload,
    url: "/api/numberofinstallments/" + id,
  })
);

const numberofinstallmentsSaga = [
  /*PAYMENT_INSTALLMENTSS*/
  takeLatest(
    getPaymentInstallments.toString(),
    (payload: SagaGenericParams<PaymentInstallmentDto[]>) =>
      getListSaga<PaymentInstallmentDto[]>(payload)
  ),

  takeLatest(
    getPaymentInstallmentsDetails.toString(),
    (payload: SagaGenericParams<PaymentInstallmentDto>) =>
      getListSaga<PaymentInstallmentDto>(payload)
  ),
  takeLatest(
    createPaymentInstallments.toString(),
    (payload: PostSagaGenericParams<PaymentInstallmentDto>) =>
      postSaga<PaymentInstallmentDto>(payload)
  ),
  takeLatest(
    updatePaymentInstallments.toString(),
    (payload: PostSagaGenericParams<PaymentInstallmentDto>) =>
      putSaga<PaymentInstallmentDto>(payload)
  ),

  takeLatest(
    deletePaymentInstallments.toString(),
    (payload: SagaGenericParams<any>) =>
      deleteSaga<PaymentInstallmentDto>(payload)
  ),
];

export default numberofinstallmentsSaga;
