import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import MetaTags from "react-meta-tags";
import { CorporateClientsDto } from "src/store/corporate-clients/type";
import { useDispatch, useSelector } from "react-redux";
import { getCorporateClientsDetails } from "src/store/corporate-clients/saga";
import CorporateClientDetailCard from "./detailCard";
import CustomResponsiveNav from "src/components/navbar/responsiveNav";
import { EnumTabs, navMenuList } from "./tabList";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import ActiveContract from "./active-contract";
import { CorporateContractDetailDto } from "src/store/corporate-contracts/type";
import { getActiveCorporateContract } from "src/store/corporate-contracts/saga";
import CorporateContractsPage from "./contracts";
const CorporateCustomerDetailsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id, tab }: any = useParams();
  const loading = useSelector(isLoading);
  const [data, setData] = useState<CorporateClientsDto>();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<string>(tab);
  const [activeContract, setActiveContract] =
    useState<CorporateContractDetailDto>();
  useEffect(() => {}, [dispatch, id]);
  useEffect(() => {
    dispatch(
      getCorporateClientsDetails({
        payload: {
          onSuccess: (m, p) => {
            return setData(p);
          },
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getActiveCorporateContract({
        payload: {
          onSuccess: (m, p) => {
            return setActiveContract(p);
          },
          onError: () => {},
        },
        id: id,
      })
    );
  }, [dispatch, id]);
  const FindActiveMenuComp = () => {
    const activeComp = navMenuList.find(x => x.eventKey === activeTab);
    return activeComp ? activeComp.component : <></>;
  };
  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>{t("Kurumsal Müşteriler")}</title>
        </MetaTags>
        <Container fluid>
          <React.Fragment>
            <Breadcrumbs
              title={t("Dashboard")}
              breadcrumbItem={data?.CompanyName ?? "-"}
            />
            {!data && loading && <Loader />}
            {data && (
              <>
                <Row>
                  <Col md={4}>
                    <CorporateClientDetailCard data={data} />
                  </Col>
                  <Col md={8} style={{ marginTop: 3 }}>
                    <CustomResponsiveNav
                      className="mainNav"
                      appearance="tabs"
                      activeKey={activeTab}
                      onSelect={e => {
                        history.push(
                          "/corporate-client/details/" + e + "/" + id
                        );
                        return setActiveTab(e);
                      }}
                      items={navMenuList
                        .filter(x =>
                          x.roles?.some(y => y === data.CorporateTypeId)
                        )
                        .map(x => {
                          return {
                            eventKey: x.eventKey,
                            label: x.text,
                          };
                        })}
                    />
                    <div className="tabComponent p-2 ">
                      <div
                        className="border bg-white"
                        style={{ minHeight: 280 }}
                      >
                        {FindActiveMenuComp()}
                        {activeContract &&
                          activeTab === EnumTabs.ActiveContracts && (
                            <ActiveContract
                              data={activeContract}
                              setData={setActiveContract}
                            />
                          )}
                        {activeTab === EnumTabs.Contracts && (
                          <CorporateContractsPage
                            refreshData={() => {
                              dispatch(
                                getActiveCorporateContract({
                                  payload: {
                                    onSuccess: (m, p) => {
                                      return setActiveContract(p);
                                    },
                                    onError: () => {},
                                  },
                                  id: id,
                                })
                              );
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </React.Fragment>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CorporateCustomerDetailsPage;
